// 지급, 차감
export const MoneyIncDecTypeEnums = {
  INC: '지급',
  DEC: '차감',
}

export const MoneyIncDecTypeEnumsSelectOptions = () => {
  return Object.entries(MoneyIncDecTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

// 머니, 콤프
export const MemberMoneyTypeEnums = {
  MONEY: '머니',
  HOLDEM_MONEY: '홀덤머니',
  ROLLINGPOINT: '포인트',
  LOSINGPOINT: '루징포인트',
}

// 회원 용 option
export const MemberMoneyTypeEnumsSelectOptions = () => {
  return Object.entries(MemberMoneyTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

// 머니, 콤프
export const PartnerMoneyTypeEnums = {
  MONEY: '머니',
  ROLLINGPOINT: '롤링 포인트',
  LOSINGPOINT: '루징 포인트',
}

// 회원 용 option
export const PartnerMoneyTypeEnumsSelectOptions = () => {
  return Object.entries(PartnerMoneyTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

// 머니 증차감 타입
export const AssetIncDecMethodTypeEnums = {
  INC: '지급',
  DEC: '차감',
}

export const AssetIncDecMethodTypeEnumsSelectOptions = () => {
  return Object.entries(AssetIncDecMethodTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

// 머니, 콤프
export const UserMoneyApplyTypeEnums = {
  DEPOSIT: '입금',
  WITHDRAWAL: '출금',
}

export const AssetMoneyTypeEnumsSelectOptions = () => {
  return Object.entries(UserMoneyApplyTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
