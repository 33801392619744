import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import {
  BonusDepositTypeKr,
  BonusShowTypeKr,
  DepositBounsTypeKrV2,
  RollingDepositTypeKr,
} from '../../../enums/MemberHistory/MemberDepositEnums'
import Loading from '../../../shared/components/Loading'
import {
  deleteDepositBonusV2,
  searchDepositBonusV2,
  updateDepositBonus,
} from '../../../utils/api/systemManage/depositWithdrawalRuleManageApi'
import { decodeAccessToken } from '../../../utils/token'
import DepositBonusAddModalV2 from './DepositBonusAddModalV2'
import DepositBonusMoneySettingUpdateModalV2 from './DepositBonusMoneySettingUpdateModalV2'
import DepositBonusUpdateModalV2 from './DepositBonusUpdateModalV2'

const DepositBonusManagementV2 = ({ selectedLevel }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [contentList, setContentList] = useState()
  const [loading, setLoading] = useState(false)

  const [depositBonusAddModalShow, setDepositBonusAddModalShow] = useState(false)

  const onCloseDepositBonusAddModalHnadler = () => {
    setDepositBonusAddModalShow(false)
  }

  // 수정, 금액설정에서 같이 사용
  const [depositBonusModel, setDepositBonusModel] = useState({})

  const [depositBonusUpdateModalShow, setDepositBonusUpdateModalShow] = useState(false)

  const onCloseDepositBonusUpdateModalHnadler = () => {
    setDepositBonusModel({})
    setDepositBonusUpdateModalShow(false)
  }

  // 충전 보너스 수정 핸들러
  const onClickUpdateMoneyDepositBonus = model => {
    setDepositBonusModel(model)

    setDepositBonusUpdateModalShow(true)
  }

  const [depositBonusMoneySettingUpdateModalShow, setDepositBonusMoneySettingUpdateModalShow] = useState(false)

  const onCloseDepositBonusMoneySettingUpdateModalHnadler = () => {
    setDepositBonusModel({})
    setDepositBonusMoneySettingUpdateModalShow(false)
  }

  // 충전 보너스 금액 설정 핸들러
  const onClickUpdateMoneyDepositBonusMoneySetting = model => {
    setDepositBonusModel(model)

    setDepositBonusMoneySettingUpdateModalShow(true)
  }

  const fetchSearchDepositBonus = async () => {
    if (loading) return

    setLoading(true)

    await searchDepositBonusV2({
      depositLevel: selectedLevel,
    })
      .then(res => {
        setContentList(res.data.contentList)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchDepositBonus()
  }, [selectedLevel])

  const [depositBonusApiLoading, setDepositBonusApiLoading] = useState(false)

  const onSubmit = e => {
    if (depositBonusApiLoading) return

    if (window.confirm('충전 보너스를 수정하시겠습니까?')) {
      setDepositBonusApiLoading(true)

      const body = {
        contentList: contentList.map(item => ({
          moneyDepositBonusKey: item.moneyDepositBonusKey,
          title: item.title,
          content: item.content,
          isShow: item.isShow,
          orderNum: item.orderNum,
          bonusType: item.bonusType,
          bonusTypeKr: item.bonusTypeKr,
        })),
      }

      updateDepositBonus(body)
        .then(res => {
          alert('수정되었습니다.')
          fetchSearchDepositBonus()
          setDepositBonusApiLoading(false)
        })
        .catch(error => {
          alert('수정실패했습니다.')
          setDepositBonusApiLoading(false)
        })
    }
  }

  const onDeleteMoneyDepositBonus = key => {
    if (depositBonusApiLoading) return

    if (window.confirm('충전 보너스를 삭제 하시겠습니까?')) {
      setDepositBonusApiLoading(true)

      deleteDepositBonusV2(key)
        .then(res => {
          alert('삭제되었습니다.')
          fetchSearchDepositBonus()
        })
        .catch(error => {
          alert('삭제실패했습니다.')
        })
        .finally(() => {
          setDepositBonusApiLoading(false)
        })
    }
  }

  const onClickAddMoneyDepositBonus = () => {
    setDepositBonusAddModalShow(true)
  }

  return (
    <>
      {depositBonusAddModalShow && (
        <DepositBonusAddModalV2
          depositLevel={selectedLevel}
          depositBonusAddModalShow={depositBonusAddModalShow}
          onCloseDepositBonusAddModalHnadler={onCloseDepositBonusAddModalHnadler}
          fetchSearchDepositBonus={fetchSearchDepositBonus}
        />
      )}
      {depositBonusUpdateModalShow && (
        <DepositBonusUpdateModalV2
          depositBonusModel={depositBonusModel}
          depositBonusUpdateModalShow={depositBonusUpdateModalShow}
          onCloseDepositBonusUpdateModalHnadler={onCloseDepositBonusUpdateModalHnadler}
          fetchSearchDepositBonus={fetchSearchDepositBonus}
        />
      )}

      {depositBonusMoneySettingUpdateModalShow && (
        <DepositBonusMoneySettingUpdateModalV2
          depositBonusModel={depositBonusModel}
          depositBonusMoneySettingUpdateModalShow={depositBonusMoneySettingUpdateModalShow}
          onCloseDepositBonusMoneySettingUpdateModalHnadler={onCloseDepositBonusMoneySettingUpdateModalHnadler}
          fetchSearchDepositBonus={fetchSearchDepositBonus}
        />
      )}

      <DepositBonusManagementWrap>
        <TableWrap>
          <HeaderBox>충전보너스</HeaderBox>

          {(depositBonusApiLoading || loading) && <Loading />}
          <Form
            onSubmit={onSubmit}
            initialValues={{
              contentList,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer onSubmit={handleSubmit}>
                <table style={{ width: '100%' }}>
                  <colgroup>
                    <col width="80px" />
                    <col width="200px" />
                    <col width="100%" />
                    <col width="300px" />
                    <col width="90px" />
                    <col width="80px" />
                    <col width="80px" />
                    <col width="80px" />
                    <col width="80px" />
                    <col width="50px" />
                    <col width="200px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th style={{ borderLeft: '1px solid black' }}>게임</th>
                      <th>보너스명</th>
                      <th>내용</th>
                      <th>노출조건</th>
                      <th>출금롤링타입</th>
                      <th>지급 방식</th>
                      <th>지급 비율(%)</th>
                      <th>충전 회차</th>
                      <th>1회 최대액</th>
                      <th>순서</th>
                      <th>기능</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contentList?.map((item, index) => (
                      <tr key={item.moneyDepositBonusKey}>
                        <td style={{ borderLeft: '1px solid black' }}>
                          <Field name={`title-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input {...input} type="text" value={DepositBounsTypeKrV2[item.bonusType]} disabled />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`title-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input {...input} type="text" value={item.bonusTitle} disabled />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`content-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input {...input} type="text" value={item.bonusContent} disabled />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`showType-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={`${item.alwaysVisible ? '[ 상시노출 ]' : ''}${' '}${
                                    item.includeHoldemType ? '[ 홀덤 포함 O ]' : ''
                                  }${' '}${item.bonusShowType.map(type => BonusShowTypeKr[type]).join(', ')}`}
                                  disabled
                                />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`rollingType-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={RollingDepositTypeKr[item.withdrawalRollingType]}
                                  disabled
                                />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`bonusDepositType-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={BonusDepositTypeKr[item.bonusDepositType]}
                                  disabled
                                />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`bonusPer-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input {...input} type="text" value={item.bonusPer} disabled />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`depositCount-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input {...input} type="text" value={item.depositCount} disabled />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <Field name={`bonusOneMaxAmount-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input {...input} type="text" value={item.bonusOneMaxAmount} disabled />
                              </InputBox>
                            )}
                          </Field>
                        </td>

                        <td>
                          <Field name={`rollingType-${item.moneyDepositBonusKey}`}>
                            {({ input, meta }) => (
                              <InputBox style={{ display: 'flex' }}>
                                <input {...input} type="text" value={item.orderNum} disabled />
                              </InputBox>
                            )}
                          </Field>
                        </td>
                        <td>
                          <ButtonBox>
                            <button
                              type="button"
                              onClick={() => {
                                onClickUpdateMoneyDepositBonus(item)
                              }}
                              style={{ background: 'green' }}
                            >
                              수정
                            </button>
                            {item.bonusDepositType === 'MONEY_PAYMENT' && (
                              <button
                                type="button"
                                onClick={() => {
                                  onClickUpdateMoneyDepositBonusMoneySetting(item)
                                }}
                                style={{ background: 'blue' }}
                              >
                                금액설정
                              </button>
                            )}

                            <button
                              type="button"
                              onClick={() => {
                                onDeleteMoneyDepositBonus(item.moneyDepositBonusKey)
                              }}
                              style={{ background: 'red' }}
                            >
                              삭제
                            </button>
                          </ButtonBox>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {
                  {
                    SUPER: (
                      <ButtonBox
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <button
                          type="button"
                          onClick={onClickAddMoneyDepositBonus}
                          style={{ background: 'grey', width: '120px', padding: '10px' }}
                        >
                          추가하기
                        </button>
                      </ButtonBox>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </TableWrap>
      </DepositBonusManagementWrap>
    </>
  )
}

export default DepositBonusManagementV2

DepositBonusManagementV2.propTypes = {
  selectedLevel: PropTypes.string.isRequired,
}

const DepositBonusManagementWrap = styled.div`
  width: 100%;
  padding-top: 10px;
  display: block;
`

const TableWrap = styled.div`
  width: 100%;
  // overflow-x: auto;
  // height: 630px;
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`

const InputBox = styled.div`
  flex: 1 1 0%;

  input {
    font-weight: 700;
    color: #000;
    width: 100%;
    height: 40px;
    border: 1px solid #738087;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: left;
    padding-left: 10px;
    border-radius: 8px;
  }
`

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  button {
    // width: 70px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    padding: 0 10px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
`

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`
