import { Card, CardBody } from '@/shared/components/Card'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { GoPickTypeEnums } from '../../../../enums/Log/miniGameBettingEnums'
import Loading from '../../../../shared/components/Loading'
import MiniGameManagement from '../MiniGameManagement'
import GoPickEvoPowerLadderFive from './GoPickEvoPowerLadderFive'
import GoPickEvoPowerLadderFour from './GoPickEvoPowerLadderFour'
import GoPickEvoPowerLadderThree from './GoPickEvoPowerLadderThree'
import GoPickEvoPowerLadderTwo from './GoPickEvoPowerLadderTwo'
import GoPickEvoPowerLadderOne from './GoPickEvoPowerLadderOne'
import GoPickEvoPowerBallOne from './GoPickEvoPowerBallOne'
import GoPickEvoPowerBallFive from './GoPickEvoPowerBallFive'
import GoPickEvoPowerBallFour from './GoPickEvoPowerBallFour'
import GoPickEvoPowerBallThree from './GoPickEvoPowerBallThree'
import GoPickEvoPowerBallTwo from './GoPickEvoPowerBallTwo'
import GoPickNuguri from './GoPickNuguri'
import GoPickEvoDragonTiger from './GoPickEvoDragonTiger'
import GoPickEvoBaseballStudio from './GoPickEvoBaseballStudio'
import GoPickSquidGame from './GoPickSquidGame'
import GoPickBaseball from './GoPickBaseball'
import GoPickSoccer from './GoPickSoccer'

const GoPickManagement = () => {
  const [apiLoading, setApiLoading] = useState(false)

  const [type, setType] = useState('GO_PICK_NUGURI')
  const [name, setName] = useState('EVO 파워볼 1턴')

  const onClickTypeHandler = (type, name) => {
    setType(type)
    setName(name)
  }

  const getNtrySettingCompo = type => {
    switch (type) {
      case 'GO_PICK_EVO_POWER_BALL_1':
        return <GoPickEvoPowerBallOne miniGameType="GO_PICK_EVO_POWER_BALL_1" />
      case 'GO_PICK_EVO_POWER_BALL_2':
        return <GoPickEvoPowerBallTwo miniGameType="GO_PICK_EVO_POWER_BALL_2" />
      case 'GO_PICK_EVO_POWER_BALL_3':
        return <GoPickEvoPowerBallThree miniGameType="GO_PICK_EVO_POWER_BALL_3" />
      case 'GO_PICK_EVO_POWER_BALL_4':
        return <GoPickEvoPowerBallFour miniGameType="GO_PICK_EVO_POWER_BALL_4" />
      case 'GO_PICK_EVO_POWER_BALL_5':
        return <GoPickEvoPowerBallFive miniGameType="GO_PICK_EVO_POWER_BALL_5" />
      case 'GO_PICK_EVO_POWER_LADDER_1':
        return <GoPickEvoPowerLadderOne miniGameType="GO_PICK_EVO_POWER_LADDER_1" />
      case 'GO_PICK_EVO_POWER_LADDER_2':
        return <GoPickEvoPowerLadderTwo miniGameType="GO_PICK_EVO_POWER_LADDER_2" />
      case 'GO_PICK_EVO_POWER_LADDER_3':
        return <GoPickEvoPowerLadderThree miniGameType="GO_PICK_EVO_POWER_LADDER_3" />
      case 'GO_PICK_EVO_POWER_LADDER_4':
        return <GoPickEvoPowerLadderFour miniGameType="GO_PICK_EVO_POWER_LADDER_4" />
      case 'GO_PICK_EVO_POWER_LADDER_5':
        return <GoPickEvoPowerLadderFive miniGameType="GO_PICK_EVO_POWER_LADDER_5" />
      case 'GO_PICK_EVO_DRAGON_TIGER':
        return <GoPickEvoDragonTiger miniGameType="GO_PICK_EVO_DRAGON_TIGER" />
      case 'GO_PICK_EVO_BASEBALL_STUDIO':
        return <GoPickEvoBaseballStudio miniGameType="GO_PICK_EVO_BASEBALL_STUDIO" />
      case 'GO_PICK_NUGURI':
        return <GoPickNuguri miniGameType="GO_PICK_NUGURI" />
      case 'GO_PICK_SOCCER':
        return <GoPickSoccer miniGameType="GO_PICK_SOCCER" />
      case 'GO_PICK_BASEBALL':
        return <GoPickBaseball miniGameType="GO_PICK_BASEBALL" />
      case 'GO_PICK_SQUIDGAME':
        return <GoPickSquidGame miniGameType="GO_PICK_SQUIDGAME" />
      default:
        return null
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <Card>
        <CardBody>
          <MiniGameManagement activeKey="5" />

          <InfoBoxWrap>
            <p style={{ background: '#fff79e' }}>
              * <span style={{ color: 'red' }}>상태</span>를 점검으로 바꿀 시, 해당 게임은 배팅할 수 없습니다.
              <br />* <span style={{ color: 'red' }}>동일라운드</span> 배팅허용 시, 해당 게임은 같은 라운드내 2회 배팅이
              가능합니다.
              <br />* <span style={{ color: 'red' }}>마감시간</span> x초 설정 시, 해당 게임은 라운드 마감 x초 전에
              마감됩니다.
            </p>
          </InfoBoxWrap>

          <MiniGameTypeWrapper>
            {Object.entries(GoPickTypeEnums).map(([key, value]) => {
              return (
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active={key === type}
                  onClick={() => {
                    onClickTypeHandler(key, value)
                  }}
                >
                  {value}
                </Button>
              )
            })}
          </MiniGameTypeWrapper>

          <LevelBoxWrap>
            <LevelBoxHeader>{GoPickTypeEnums[type]}</LevelBoxHeader>
            {getNtrySettingCompo(type)}
          </LevelBoxWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default GoPickManagement

const LevelBoxWrap = styled.div`
  width: 100%;
  min-height: 300px;

  padding: 10px;

  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    margin-top: 15px;
  }

  p {
    margin-bottom: 10px;
  }
`

const LevelBoxHeader = styled.div`
  width: 100%;
  font-size: 23px;
  color: blue;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 10px;
`

const InfoBoxWrap = styled.div`
  width: 100%;
  padding: 5px 0;

  p {
    margin-bottom: 10px;
  }
`

const MiniGameTypeWrapper = styled.div`
  width: 100%;
  padding: 5px 0;
  display: flex;
  gap: 5px;

  button {
    font-size: 12px !important;
  }
`
