import { Card, CardBody, SearchCardSubhead, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
// import ReactTableBase from '@/shared/components/table/ReactTableBase'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { useSelector } from 'react-redux'
import { GameTypeEnumsSelectOptions } from '../../../enums/SystemManagement/gameEnums'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { useSearchMemberZeroBettingLog } from '../../../utils/api/memberManage/memberLogApi'
import { convertToKst } from '../../../utils/dateTime'
import BettingManagementTabs from '../BettingManagementTabs'
import ZeroBettingListData from './ZeroBettingListData'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'

const ZeroBettingList = () => {
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)

  /**
   공배팅로그 조회
   */
  const [params, setParams] = useState({
    memberId: null,
    edgeName: null,
    authority: null,
    vendorType: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const { totalElement, content, fetchSearchMemberZeroBettingLog } = useSearchMemberZeroBettingLog(params)

  const [zeroBettingListData, setZeroBettingListData] = useState(
    ZeroBettingListData(content, fetchSearchMemberZeroBettingLog),
  )
  useEffect(() => {
    setZeroBettingListData(ZeroBettingListData(content, fetchSearchMemberZeroBettingLog))
  }, [content])

  const [rows, setData] = useState(zeroBettingListData.tableRowsData)
  useEffect(() => {
    setData(zeroBettingListData.tableRowsData)
  }, [zeroBettingListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 회원ID
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 입출금
  const [searchVendorType, setSearchVendorType] = useState('')

  const [searchVendorTypeOptionValue, setSearchVendorTypeOptionValue] = useState(null)
  const searchVendorTypeOption = GameTypeEnumsSelectOptions()

  const onSelectVendorTypeOptionHandler = option => {
    if (option === null) {
      setSearchVendorTypeOptionValue(null)
      setSearchVendorType(null)
      return
    }
    setSearchVendorTypeOptionValue(option)
    setSearchVendorType(option.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      vendorType: !searchVendorType ? null : searchVendorType,
      memberId: !searchUserId ? null : searchUserId.trim(),
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  // Tab Key
  const [activeKey, setActiveKey] = useState('5')

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <BettingManagementTabs activeKey={activeKey} />
          {/* 검색 기능 */}
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
              <p>* 해당 페이지에서는 회원의 공배팅 내역을 확인 할 수 있습니다.</p>
            </div>
          </SearchCardTitleWrap>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>사이트</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchEdgeName"
                  onChange={option => {
                    onSelectEdgeNameOptionHandler(option)
                  }}
                  placeholder="전체"
                  value={searchEdgeNameOptionValue}
                  options={searchEdgeNameOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>유저 ID</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchUserId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchUserId}
                    onChange={e => onSearchUserIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종류</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchVendorType"
                  onChange={option => {
                    onSelectVendorTypeOptionHandler(option)
                  }}
                  placeholder="종류"
                  value={searchVendorTypeOptionValue}
                  options={searchVendorTypeOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>
          <ReactTableBase columns={zeroBettingListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default ZeroBettingList
