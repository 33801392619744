const BankListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '은행명',
      accessor: 'bankName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '순서',
      accessor: 'bankOrderNum',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '상태',
      accessor: 'bankStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.bankStatus === 'SHOW') {
          return '노출'
        }
        return '숨김'
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default BankListData
