import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import SidebarCategory from './SidebarCategory'
import SidebarLink, { SidebarLinkTitle } from './SidebarLink'

const SidebarContent = ({ onClick, collapse }) => {
  return (
    <SidebarContentWrap collapse={collapse}>
      <SidebarBlock collapse={collapse}>
        <SidebarLink title="대시보드" icon="star" route="/statistics" onClick={onClick} />
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="유저 관리" icon="users" collapse={collapse}>
          <SidebarLink title="유저 조회(간략)" route="/user/management/member" onClick={onClick} />
          <SidebarLink title="유저 조회(세부)" route="/user/management/partner" onClick={onClick} />
          <SidebarLink title="유저 등록" route="/user/management/registration" onClick={onClick} />
          {/* <SidebarCategory title="유저 등록" collapse={collapse}>
            <SidebarLink title="유저 등록(개별)" route="/user/management/registration" onClick={onClick} />
            <SidebarLink title="유저 등록(엑셀)" route="/user/management/registration-excel" onClick={onClick} />
          </SidebarCategory> */}

          <SidebarLink title="파트너 신청" route="/partner/management/apply" onClick={onClick} />
          <SidebarLink title="블랙리스트" route="/user/management/black" onClick={onClick} />
          <SidebarLink title="IP 차단리스트" route="/user/management/black/ip" onClick={onClick} />

          <SidebarLink title="악성유저 조회" route="/user/management/bad" onClick={onClick} />
        </SidebarCategory>
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="머니 내역" icon="history" collapse={collapse}>
          <SidebarLink title="머니 입금내역" route="/user/history/moneydepositV2" onClick={onClick} />
          <SidebarLink title="머니 출금내역" route="/user/history/moneywithdrawalV2" onClick={onClick} />
          <SidebarLink title="환수율 내역" route="/user/history/moneyexchange-rate" onClick={onClick} />
          <SidebarLink title="머니 이동내역" route="/user/history/moneyexchange" onClick={onClick} />
          <SidebarLink title="출석 내역" route="/user/history/attendance" onClick={onClick} />
        </SidebarCategory>
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="배팅 내역" icon="pie-chart" collapse={collapse}>
          <SidebarLink
            title="실시간 현황판"
            route="/management/log/real-time-betting"
            onClick={onClick}
            target="_blank"
          />
          <SidebarLink title="스포츠 배팅내역" route="/management/log/sport-bettingv2" onClick={onClick} />
          <SidebarLink title="카지노 배팅내역" route="/management/log/betting" onClick={onClick} />

          <SidebarLink title="미니게임 배팅내역" route="/management/log/minigame-betting" onClick={onClick} />
          <SidebarLink title="가상게임 배팅내역" route="/management/log/virtualgame-betting" onClick={onClick} />
          <SidebarLink title="토큰게임 배팅내역" route="/management/log/tokengame-betting" onClick={onClick} />
          <SidebarCategory title="레볼루션홀덤 내역" collapse={collapse}>
            <SidebarLink title="배팅 내역" route="/management/log/revolution-betting" onClick={onClick} />
            <SidebarLink title="배팅 세부내역" route="/management/log/revolution-betting-detail" onClick={onClick} />
            <SidebarLink title="바이인/아웃 내역" route="/management/log/revolution-buyinout" onClick={onClick} />
          </SidebarCategory>
          <SidebarCategory title="와일드게임즈 내역" collapse={collapse}>
            <SidebarLink title="배팅 내역" route="/management/log/wild-games/betting" onClick={onClick} />
            <SidebarLink title="배팅 세부내역" route="/management/log/wild-games/betting-detail" onClick={onClick} />
            <SidebarLink title="게임 이벤트내역" route="/management/log/wild-games/game-event" onClick={onClick} />
          </SidebarCategory>

          <SidebarLink title="공배팅 내역" route="/management/log/zero-betting" onClick={onClick} />
          {/* <SidebarLink title="시스템 로그" route="/management/log/system" onClick={onClick} /> */}
        </SidebarCategory>
      </SidebarBlock>

      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="롤링&루징 통계" icon="chart-bars" collapse={collapse}>
          <SidebarLink title="정산 통계(V1)" route="/statistics/rolling" onClick={onClick} />
          <SidebarLink title="정산 통계(V2)" route="/statistics/rolling2" onClick={onClick} />
          <SidebarLink title="롤링 적립내역" route="/user/history/pointdeposit" onClick={onClick} />
          <SidebarLink title="루징 적립내역" route="/user/history/losing-pointdeposit" onClick={onClick} />
          <SidebarLink title="롤링/루징 전환내역" route="/user/history/pointexchange" onClick={onClick} />
        </SidebarCategory>
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarLink title="페이백 정산내역" icon="diamond" route="/user/manage/payback" onClick={onClick} />
        <SidebarLink title="추천인 정산내역" icon="diamond" route="/user/manage/friend-comp" onClick={onClick} />
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="카지노 관리" icon="dice" collapse={collapse}>
          <SidebarLink title="카지노 게임사 관리" route="/management/system/gamecompany" onClick={onClick} />
          <SidebarLink
            title="골드링크 슬롯 콜 관리"
            route="/management/system/callmanage/gold-link"
            onClick={onClick}
          />
          <SidebarLink title="슬롯시티 콜 관리" route="/management/system/callmanage/slot-city" onClick={onClick} />
        </SidebarCategory>
        <SidebarCategory title="스포츠 관리" icon="bicycle" collapse={collapse}>
          <SidebarLink title="프리매치 관리(자동)" route="/sport/fixture" onClick={onClick} />
          <SidebarLink title="인플레이 관리(자동)" route="/sport/live-fixture" onClick={onClick} />
          <SidebarLink title="프리매치 관리(수동)" route="/sport/fixture/manual" onClick={onClick} />
          <SidebarLink title="인플레이 관리(수동)" route="/sport/live-fixture/manual" onClick={onClick} />
          {/* <SidebarLink title="미처리 경기" route="/sport/unprocessed-fixture" onClick={onClick} /> */}
          <SidebarLink title="스포츠 정산" route="/management/sport/settlements" onClick={onClick} />
          <SidebarLink title="수동경기 설정" route="/sport/manual" onClick={onClick} />
          <SidebarLink title="스포츠 배당 설정" route="/management/sport/betting/manage" onClick={onClick} />
          {/* <SidebarLink title="스포츠 기본 설정" route="/sport/basic" onClick={onClick} /> */}
          <SidebarLink title="종목&국가 관리" route="/sport/sport-location" onClick={onClick} />
          <SidebarLink title="마켓&조합 관리" route="/management/sport/setting" onClick={onClick} />
          {/* <SidebarLink title="스포츠 정산(V1)" route="/management/sport/settlement" onClick={onClick} /> */}
        </SidebarCategory>
        <SidebarCategory title="미니게임 관리" icon="smile" collapse={collapse}>
          <SidebarLink title="미니게임 결과로그" route="/management/log/minigame-result" onClick={onClick} />

          <SidebarCategory title="게임사 관리" icon="" collapse={collapse}>
            <SidebarLink title="엔트리" route="/management/system/minigame-ntry" onClick={onClick} />
            <SidebarLink title="보스코어" route="/management/system/minigame-boscore" onClick={onClick} />
            <SidebarLink title="네임드" route="/management/system/minigame-named" onClick={onClick} />
            <SidebarLink title="슈어맨" route="/management/system/minigame-sureman" onClick={onClick} />
            <SidebarLink title="고픽" route="/management/system/minigame-gopick" onClick={onClick} />
            <SidebarLink title="토큰게임" route="/management/system/minigame-token" onClick={onClick} />
            <SidebarLink title="크라운" route="/management/system/minigame-crown" onClick={onClick} />
            <SidebarLink title="MGM" route="/management/system/minigame-mgm" onClick={onClick} />
            <SidebarLink title="로투스" route="/management/system/minigame-lotus" onClick={onClick} />
            <SidebarLink title="PBG" route="/management/system/minigame-pbg" onClick={onClick} />
            <SidebarLink title="넥스트" route="/management/system/minigame-next" onClick={onClick} />
            <SidebarLink title="스포럽" route="/management/system/minigame-spoluv" onClick={onClick} />
            <SidebarLink title="스플릿" route="/management/system/minigame-split" onClick={onClick} />
            <SidebarLink title="플레이스코어" route="/management/system/minigame-playscore" onClick={onClick} />
            <SidebarLink title="동행" route="/management/system/minigame-donghang" onClick={onClick} />
            <SidebarLink title="베픽" route="/management/system/minigame-bepick" onClick={onClick} />
          </SidebarCategory>
        </SidebarCategory>
        <SidebarCategory title="가상게임 관리" icon="smile" collapse={collapse}>
          <SidebarLink title="가상게임 관리" route="/management/system/virtual-game-list" onClick={onClick} />
          <SidebarLink title="가상게임 설정" route="/management/system/virtual-game" onClick={onClick} />
          <SidebarLink title="가상축구 팀" route="/management/system/virtual-game/soccer" onClick={onClick} />
          <SidebarLink title="가상농구 팀" route="/management/system/virtual-game/basketball" onClick={onClick} />
          <SidebarLink title="가상야구 팀" route="/management/system/virtual-game/baseball" onClick={onClick} />
        </SidebarCategory>

        <SidebarLink title="레볼루션 홀덤 관리" icon="dice" route="/holdem/revolution" onClick={onClick} />
        <SidebarLink title="와일드게임즈 관리" icon="dice" route="/holdem/wild-games" onClick={onClick} />
      </SidebarBlock>
      <SidebarBlock collapse={collapse}>
        <SidebarCategory title="고객센터 관리" icon="cog" collapse={collapse}>
          <SidebarLink title="게시판 관리" route="/management/operation/board" onClick={onClick} />
          <SidebarLink title="팝업 관리" route="/management/operation/popup" onClick={onClick} />
          <SidebarLink title="1:1 문의" route="/management/operation/onetoone" onClick={onClick} />
          <SidebarLink title="쪽지 관리" route="/management/operation/note" onClick={onClick} />
          <SidebarLink title="매크로 관리" route="/management/system/macro" onClick={onClick} />
          {/* <SidebarLink title="쿠폰 관리" route="/management/operation/coupon" onClick={onClick} /> */}
        </SidebarCategory>
        <SidebarCategory title="로그 관리" icon="magnifier" collapse={collapse}>
          {/* <SidebarLink title="스포츠 결과로그" route="/management/log/minigame-result" onClick={onClick} /> */}
          <SidebarLink title="통합머니 로그" route="/management/log/money-change-log" onClick={onClick} />
          <SidebarLink title="입출금로그" route="/management/log/moneydepowithdraw" onClick={onClick} />
          <SidebarLink title="머니증감 로그" route="/management/log/moneyinc" onClick={onClick} />
          <SidebarLink title="쿠폰지급 로그" route="/management/log/couponpay" onClick={onClick} />
          <SidebarLink title="로그인 로그" route="/management/log/login" onClick={onClick} />
          <SidebarLink title="시스템 로그" route="/management/log/admin" onClick={onClick} />
        </SidebarCategory>

        <SidebarCategory title="시스템 관리" icon="lock" collapse={collapse}>
          <SidebarLink title="URL 관리" route="/management/system/clienturl" onClick={onClick} />
          <SidebarLink title="배너 관리" route="/management/system/banner" onClick={onClick} />
          <SidebarLink title="은행 관리" route="/management/system/bank" onClick={onClick} />
          <SidebarLink title="사이트 관리" route="/management/system/site" onClick={onClick} />
          <SidebarLink title="레벨 관리" route="/management/system/level" onClick={onClick} />
          <SidebarLink title="입출금 규정 관리" route="/management/system/deposit-bonus" onClick={onClick} />
          {/* <SidebarLink title="이미지(메인) 관리" route="/management/system/mainimg" onClick={onClick} /> */}
        </SidebarCategory>
      </SidebarBlock>
    </SidebarContentWrap>
  )
}

SidebarContent.propTypes = {
  onClick: PropTypes.func.isRequired,
  collapse: PropTypes.bool,
}

SidebarContent.defaultProps = {
  collapse: false,
}

export default SidebarContent

// region STYLES

const SidebarContentWrap = styled.div`
  height: 100%;
  overflow: auto;
  padding-top: 0;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }

  @media screen and (min-width: 576px) {
    // padding-top: 15px;

    ${props =>
      props.collapse &&
      `
      width: 55px;
      overflow: visible !important;
      transition: width 0.3s;
    `}
  }
`

const SidebarBlock = styled.ul`
  padding: 15px 0;
  border-bottom: 1px solid #e1e1e1;
  list-style-type: none;

  &:last-child {
    border: none;
  }

  // 사이드바 호버
  li a {
    &:hover {
      background-color: red;
    }
  }

  @media screen and (min-width: 576px) {
    ${props =>
      props.collapse &&
      `
      & > li > a,
      & > li > button {
        overflow: hidden;
        width: 55px;
        background: white;
        
        span:last-of-type {
          opacity: 0;
          transition: 0.3s;
        }
  
        ${SidebarLinkTitle} {
          position: absolute;
          width: 160px;
          left: 70px;
        }
  
        &:hover {
          background: #fafbfe;
        }
      }
      
      & > li:hover > a,
      & > li:hover > button {
        width: 275px;
        
        span {
          opacity: 1;
        }
      }
    `}
  }
`

// endregion
