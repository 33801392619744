import { postSiginOut } from '@/utils/api/authManage/authManageApi'
import { left, marginLeft, right } from '@/utils/directions'
import { colorAccent, colorBackground, colorBorder, colorHover, colorText } from '@/utils/palette'
import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { decodeAccessToken, deleteAccessToken, getAccessToken } from '../../../utils/token'
import TopbarMenuLink, { TopbarLink } from './AdminTopbarMenuLink'
import { TopbarBack, TopbarDownIcon, TopbarLinkIcon, TopbarLinkTitle } from './BasicTopbarComponents'
import { handleDeleteAuthentication } from '../../../utils/socketio/userAuthSocketIOHandler'

const AdminTopbarProfile = () => {
  const history = useHistory()
  const [isCollapsed, setIsCollapsed] = useState(false)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const onClickSingOutHandler = () => {
    handleDeleteAuthentication({
      accessToken: getAccessToken(),
    }).then(res => {
      deleteAccessToken()
      toggleCollapse()
      window.location.href = '/login'
    })
  }

  return (
    <TopbarProfileWrap>
      <TopbarAvatarButton type="button" onClick={toggleCollapse}>
        <TopbarAvatarName>
          <span style={{ fontWeight: '800', fontSize: '16px' }}>{decodeAccessToken()?.adminId} 관리자 </span>
        </TopbarAvatarName>
        <TopbarDownIcon />
      </TopbarAvatarButton>
      {isCollapsed && <TopbarBack type="button" aria-label="button collapse" onClick={toggleCollapse} />}
      <Collapse in={isCollapsed}>
        <TopbarMenuWrap>
          <TopbarMenu>
            <TopbarMenuLink title="내정보" icon="list" path="/myinfo" onClick={toggleCollapse} />
            <TopbarMenuDivider />
            <TopbarLinkWrap onClick={onClickSingOutHandler}>
              <>
                <TopbarLinkIcon className="lnr lnr-exit" />
                <TopbarLinkTitle>로그아웃</TopbarLinkTitle>
              </>
            </TopbarLinkWrap>
          </TopbarMenu>
        </TopbarMenuWrap>
      </Collapse>
    </TopbarProfileWrap>
  )
}

export default AdminTopbarProfile

// region STYLES
export const TopbarLinkWrap = styled.div`
  display: flex;
  justify-content: ${props => (props.iconRight ? 'space-between' : 'left')};
  padding: 9px 0px 9px 20px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  color: ${colorText};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: ${colorAccent};
    opacity: 0;
    transition: all 0.3s;
    ${left}: 0;
  }

  &:hover {
    background-color: ${colorHover};

    &:before {
      opacity: 1;
    }
  }
`

export const TopbarProfileWrap = styled.div`
  // position: absolute;
  // right: 0;
  // margin-bottom: 0;
  margin-left: auto;

  height: 100%;
`

const TopbarAvatarButton = styled.button`
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 15px;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active,
  &:focus:active {
    background-color: ${colorHover};
  }

  &:focus {
    outline: none;
  }

  &:before {
    display: none;
  }
`

const TopbarAvatarImage = styled.img`
  margin: auto 0;
  border-radius: 50%;
  height: 36px;
  width: 36px;
`

export const TopbarAvatarName = styled.p`
  margin: auto 0;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  display: block;
  ${marginLeft}: 10px;
  color: ${colorText};
  min-width: max-content;
`

const TopbarMenuWrap = styled.div`
  z-index: 102;
  position: absolute;
  // width: 100%;
  min-width: 210px;
  ${right}: 0;
`

const TopbarMenu = styled.div`
  width: 200px;
  border-radius: 0;
  border: none;
  padding: 8px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  background: ${colorBackground};

  button {
    padding: 0;

    &:hover {
      background-color: ${colorHover};
    }

    &${TopbarLink} {
      background-color: transparent;
      border: none;
      padding: 9px 20px;
    }
  }

  *:focus {
    outline: none;
  }

  @media screen and (min-width: 480px) {
    width: 100%;
    ${left}: 0 !important;
  }
`

const TopbarMenuDivider = styled.div`
  margin: 15px 0;
  border-top: 1px solid ${colorBorder};
`

// endregion
