import { Card, CardBody } from '@/shared/components/Card'
import { borderLeft } from '@/utils/directions'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '@/utils/palette'
import { ko } from 'date-fns/esm/locale'
import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import Switch from 'react-switch'
import styled from 'styled-components'
import { getFixtureStatusSelectCompo, getFixtureStatusSelectOption } from '../../../enums/Sport/SportFixtureEnums'
import {
  fetchSportFixtureList,
  fetchSportsFixtureBettingMarketList,
  updatedSportBettingInfoListShow,
  updatedSportFixtureBettingMarketListShow,
} from '../../../redux/SportFixtureSlice'
import Loading from '../../../shared/components/Loading'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import {
  updateSportFixtureIgnoreApiSignal,
  updateSportFixturePopularVisible,
  updateSportFixtureVisible,
} from '../../../utils/api/sport/sportFixtureApi'
import SportManagementTabsV2 from '../SportManagementTabsV2'
import SportFixtureMarketOffcanvas from './SportFixtureMarketOffcanvas'
import SportFixtureTableItem from './SportFixtureTabeItem'
import { convertToKst } from '../../../utils/dateTime'

const SportFixtureManagePage = () => {
  const dispatch = useDispatch()

  const { sportMenu } = useSelector(state => {
    const { sportInfo } = state

    return {
      sportMenu: sportInfo.sportMenu,
    }
  })

  const { bookMakerMenu } = useSelector(state => {
    const { bookMakerInfo } = state

    return {
      bookMakerMenu: bookMakerInfo.bookMakerMenu,
    }
  })

  const { loading, fixtureMarketSearchParams, sportFixtures, totalElements } = useSelector(state => {
    const { sportFixture } = state

    return {
      loading: sportFixture.loading,
      totalElements: sportFixture.totalElements,
      fixtureMarketSearchParams: sportFixture.fixtureMarketSearchParams,
      sportFixtures: sportFixture.sportFixtures,
    }
  })

  const [apiLoading, setApiLoading] = useState(false)

  const [fixtureSearchParams, setFixtureSearchParams] = useState(null)
  const [onlyBettingFixture, setOnlyBettingFixture] = useState(false) // Initial state is false
  const [onlyNotSettlementFixture, setOnlyNotSettlementFixture] = useState(false) // Initial state is false
  const [exposeFixture, setExposeFixture] = useState(false) // Initial state is false

  const [size, setSize] = useState(100)

  const [activeSportMenu, setActiveSportMenu] = useState(null)

  /**
   * 검색
   */
  const [searchFixtureId, setSearchFixtureId] = useState('')
  const [searchKeyword, setSearchKeyword] = useState('')
  const [searchFixtureStatus, setSearchFixtureStatus] = useState(null)
  const onChangeSearchFixtureStatusHandler = fixtureStatus => {
    if (fixtureStatus) {
      setSearchFixtureStatus(fixtureStatus)
    }
  }

  /**
   배팅로그 조회
   */

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(new Date(new Date().getTime() - 6 * 60 * 60 * 1000)) // 현재 시간에서 6시간 전
  const [searchEndDate, setSearchEndDate] = useState(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)) // 현재 시간에서 24시간 후

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }

  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [fixtureStatusOptionValue, setFixtureStatusOptionValue] = useState(null)
  const fixtureStatusSelectOption = getFixtureStatusSelectOption()

  const onSelectFixtureStatusHandler = option => {
    setFixtureStatusOptionValue(option)
  }

  const onClickSearchHandler = () => {
    setFixtureSearchParams(prev => ({
      ...prev,
      fixtureId: searchFixtureId || null,
      fixtureStatus: searchFixtureStatus || null,
      searchKeyword: searchKeyword || null,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  const onClickOnlyBettingFixtureHandler = () => {
    setOnlyBettingFixture(prev => !prev) // Toggle the state
    setFixtureSearchParams(prev => ({
      ...prev,
      onlyBettingFixture: !prev.onlyBettingFixture,
    }))
  }

  const onClickExposeFixtureHandler = () => {
    setExposeFixture(prev => !prev) // Toggle the state
    setFixtureSearchParams(prev => ({
      ...prev,
      exposeFixture: !prev.exposeFixture,
    }))
  }

  const onClickOnlyNotSettlementFixtureHandler = () => {
    setOnlyNotSettlementFixture(prev => !prev) // Toggle the state
    setFixtureSearchParams(prev => ({
      ...prev,
      onlyNotSettlementFixture: !prev.onlyNotSettlementFixture,
    }))
  }

  useEffect(() => {
    if (sportMenu.length === 0) return
    setActiveSportMenu(sportMenu[0]?.sportId)
    setFixtureSearchParams({
      sportId: sportMenu[0]?.sportId,
      fixtureId: null,
      fixtureStatus: null,
      searchKeyword: null,
      marketType: null,
      onlyManualBetType: false,
      onlyBettingFixture: false,
      onlyNotSettlementFixture: false,
      exposeFixture: false,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
      size,
    })
    setSearchFixtureId('')
    setSearchKeyword('')
    setFixtureStatusOptionValue(null)
  }, [sportMenu])

  const onClickSportMenuHandler = sportId => {
    if (loading) return
    setActiveSportMenu(sportId)
    setSearchFixtureId('')
    setSearchKeyword('')
    setFixtureStatusOptionValue(null)
    setOnlyBettingFixture(false) // Toggle the state
    setExposeFixture(false) // Toggle the state
    setSearchStartDate(new Date(new Date().getTime() - 6 * 60 * 60 * 1000))
    setSearchEndDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
    setFixtureSearchParams(prev => ({
      sportId,
      fixtureId: null,
      fixtureStatus: null,
      searchKeyword: null,
      marketType: null,
      onlyManualBetType: false,
      onlyBettingFixture: false,
      onlyNotSettlementFixture: false,
      exposeFixture: false,
      startDate: convertToKst(new Date(new Date().getTime() - 6 * 60 * 60 * 1000)),
      endDate: convertToKst(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
      page: 0,
      size,
    }))
  }

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (!fixtureSearchParams) return
    dispatch(fetchSportFixtureList(fixtureSearchParams))
  }, [fixtureSearchParams])

  useEffect(() => {
    if (totalElements === 0) {
      setTotalPages(0)
    } else {
      setTotalPages(Math.ceil(totalElements / size))
    }
  }, [totalElements])

  const onPageChange = pageNumber => {
    window.scrollTo(0, 0)
    setFixtureSearchParams(prev => ({
      ...prev,
      page: pageNumber,
    }))
  }

  const [selectedFixtureId, setSelectedFixtureId] = useState(null)
  const [statusId, setStatusId] = useState(null)
  const [statusKr, setStatusKr] = useState(null)
  const [usingBookmakerId, setUsingBookmakerId] = useState(null)
  const [marketPanelShow, setMarketPanelShow] = useState(false)

  const marketPanelOpenHandler = useCallback((fixtureId, usingBookmakerId, statusId, statusKr) => {
    setSelectedFixtureId(fixtureId)
    setUsingBookmakerId(usingBookmakerId)
    setMarketPanelShow(true)
    setStatusId(statusId)
    setStatusKr(statusKr)
  }, [])

  const marketPanelCloseHandler = () => {
    setMarketPanelShow(false)
    setSelectedFixtureId(null)
    setUsingBookmakerId(null)
  }

  // 경기 노출 여부
  const onClickFixtureVisibleHandler = useCallback(
    (fixtureId, visible) => {
      if (apiLoading) return

      if (window.confirm(`해당 경기를 ${visible ? '노출' : '비노출'}로 변경하시겠습니까?`)) {
        setApiLoading(true)

        const body = {
          visible,
        }

        updateSportFixtureVisible(fixtureId, body)
          .then(res => {
            dispatch(fetchSportFixtureList(fixtureSearchParams))
          })
          .catch(error => {
            alert('해당 경기 노출여부 수정에 실패했습니다.')
          })
          .finally(() => {
            setApiLoading(false)
          })
      }
    },
    [apiLoading, dispatch, fixtureSearchParams],
  )

  // 경기 노출 여부
  const onClickFixturePopularHandler = useCallback(
    (fixtureId, visible) => {
      if (apiLoading) return

      if (window.confirm(`해당 경기를 ${visible ? '인기게임' : '비 인기게임'}으로 변경하시겠습니까?`)) {
        setApiLoading(true)

        const body = {
          visible,
        }

        updateSportFixturePopularVisible(fixtureId, body)
          .then(res => {
            dispatch(fetchSportFixtureList(fixtureSearchParams))
          })
          .catch(error => {
            alert('해당 경기 인기게임 여부 수정에 실패했습니다.')
          })
          .finally(() => {
            setApiLoading(false)
          })
      }
    },
    [apiLoading, dispatch, fixtureSearchParams],
  )

  // api 신호 무시
  const onClickFixtureIgnoreApiSignalSwitchHandler = useCallback(
    (fixtureId, ignoreApiSignal) => {
      if (apiLoading) return

      if (window.confirm(`해당 경기의 상태를 ${ignoreApiSignal ? '고정' : '해제'}로 변경하시겠습니까?`)) {
        setApiLoading(true)

        const body = {
          ignoreApiSignal,
        }

        updateSportFixtureIgnoreApiSignal(fixtureId, body)
          .then(res => {
            dispatch(fetchSportFixtureList(fixtureSearchParams))
          })
          .catch(error => {
            alert('해당 경기 상태고정 설정에 실패했습니다.')
          })
          .finally(() => {
            setApiLoading(false)
          })
      }
    },
    [apiLoading, dispatch, fixtureSearchParams],
  )
  const [bettingFixtureId, setBettingFixtureId] = useState(null)

  const [params, setParams] = useState({
    fixtureId: null,
  })
  const bettingMarketTrOpenHandler = (key, fixtureId, bettingMarketTrShow) => {
    dispatch(updatedSportFixtureBettingMarketListShow({ key, fixtureId, bettingMarketTrShow }))
    if (bettingMarketTrShow) {
      setParams({ fixtureId })
    }
  }
  useEffect(() => {
    if (!params.fixtureId) return
    dispatch(fetchSportsFixtureBettingMarketList(params))
  }, [params])

  const bettingInfoTrOpenHandler = (key, fixtureId, bettingInfoTrShow, marketId) => {
    dispatch(updatedSportBettingInfoListShow({ key, fixtureId, bettingInfoTrShow, marketId }))
  }

  return (
    <Card>
      <CardBody>
        <SportManagementTabsV2 activeKey="1" />
        {(loading || apiLoading) && <Loading />}

        <SportMenuWrap>
          {sportMenu.map(sportMenu => {
            return (
              <SportMenuTitleBox
                key={sportMenu.sportId}
                active={activeSportMenu === sportMenu.sportId}
                onClick={() => {
                  onClickSportMenuHandler(sportMenu.sportId)
                }}
              >
                {sportMenu.sportName}
              </SportMenuTitleBox>
            )
          })}
        </SportMenuWrap>

        <SearchWrap>
          <SearchWrapItem>
            <span>경기 번호:</span>
            <input
              style={{ width: '100px' }}
              type="text"
              name="searchFixtureId"
              value={searchFixtureId}
              placeholder="경기번호"
              onChange={e => {
                setSearchFixtureId(e.target.value)
              }}
            />
            <span>국가, 팀, 리그명:</span>
            <input
              type="text"
              name="searchKeyword"
              value={searchKeyword}
              placeholder="국가, 팀, 리그명"
              onChange={e => {
                setSearchKeyword(e.target.value)
              }}
            />

            <span>경기 상태: </span>
            {getFixtureStatusSelectCompo(searchFixtureStatus, onChangeSearchFixtureStatusHandler)}
            <span>시작일: </span>
            <SearchDatePickerWrap style={{ paddingTop: '2px' }}>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
            <span>종료일: </span>
            <SearchDatePickerWrap style={{ paddingTop: '2px' }}>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
            <SearchButton
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onClickSearchHandler()
              }}
            >
              검색하기
            </SearchButton>
            <SearchButton2
              size="sm"
              variant="outline-secondary"
              onClick={onClickExposeFixtureHandler}
              isActive={exposeFixture} // Pass the state as a prop
            >
              노출 경기만
            </SearchButton2>
            <SearchButton2
              size="sm"
              variant="outline-secondary"
              onClick={onClickOnlyBettingFixtureHandler}
              isActive={onlyBettingFixture} // Pass the state as a prop
            >
              배팅한 경기만
            </SearchButton2>
            <SearchButton2
              size="sm"
              variant="outline-secondary"
              onClick={onClickOnlyNotSettlementFixtureHandler}
              isActive={onlyNotSettlementFixture} // Pass the state as a prop
            >
              미처리 경기만
            </SearchButton2>
          </SearchWrapItem>
          {/* <SearchWrapItem>
            
          </SearchWrapItem> */}
        </SearchWrap>

        <SportFixtureTableWrap>
          {Object.entries(sportFixtures).map(([key, sportFixture]) => {
            return (
              <SportFixtureTableItem
                key={`${key}-tableItem`}
                tableItemKey={key}
                sportFixture={sportFixture}
                onClickFixturePopularHandler={onClickFixturePopularHandler}
                onClickFixtureVisibleHandler={onClickFixtureVisibleHandler}
                onClickFixtureIgnoreApiSignalSwitchHandler={onClickFixtureIgnoreApiSignalSwitchHandler}
                marketPanelOpenHandler={marketPanelOpenHandler}
                bettingMarketTrOpenHandler={bettingMarketTrOpenHandler}
                bettingInfoTrOpenHandler={bettingInfoTrOpenHandler}
              />
            )
          })}
        </SportFixtureTableWrap>

        {totalElements > 0 && fixtureSearchParams && (
          <PaginationWrap>
            <CustomPagination
              currentPage={fixtureSearchParams?.page || 0}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </PaginationWrap>
        )}
        {totalElements === 0 && fixtureSearchParams && (
          <PaginationWrap>
            <span style={{ color: 'red', fontWeight: '600' }}>해당 날짜에 조회된 경기가 없습니다.</span>
          </PaginationWrap>
        )}

        {marketPanelShow && (
          <SportFixtureMarketOffcanvas
            fixtureId={selectedFixtureId}
            usingBookmakerId={usingBookmakerId}
            isShow={marketPanelShow}
            closeHandler={marketPanelCloseHandler}
            statusId={statusId}
            statusKr={statusKr}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default SportFixtureManagePage

const SearchWrap = styled.div`
  width: 100%;
  border: 1px solid #222733;
  border-radius: 3px;
  padding: 8px 8px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
`

const SearchWrapItem = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  align-items: center;

  input {
    border: 1px solid #222733;
    height: 30px;
    line-height: 28px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 7px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }

  select {
    min-width: 70px;
    display: inline-block;
    height: 30px;
    line-height: 28px;
    padding: 0 7px;
    border: 1px solid #222733;
    vertical-align: middle;
    border-radius: 3px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }
`
const SearchButton2 = styled(Button)`
  background-color: ${props => (props.isActive ? 'lightgrey' : 'transparent')}; // 진한 회색 when active
  color: ${props => props.isActive && '#000'};
  font-weight: ${props => props.isActive && '600'};
`
const SearchButton = styled(Button)`
  margin-left: 10px;
  margin-right: 20px;
  background: #dedede;
  color: #000;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportMenuWrap = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
`

const SportMenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 3px solid black;
  `};
`

const SportFixtureTableWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin-top: 15px;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  thead {
    tr {
      width: 100%;
      background: #f0f0f0;

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: 600;
        font-size: 12px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }

        svg {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`

const SportFixtureLeagueWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const SportsEventResultBtn = styled(Button)`
  background: #f59a0a;
  color: white;
  border: none;
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`

export const SearchDatePickerWrap = styled.div`
  border-radius: 4px;
  color: #000;
  outline: none;
  font-size: 12px;
  height: 35px;
  transition: border 0.3s;
  background: transparent;

  input {
    width: 150px;
    height: 30px;
    border: 1px solid black;
    color: red;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
    // height: 38px;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: ${colorAccent};
    border-color: ${colorAccent};
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    right: 2px;

    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    ${borderLeft}: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent} !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`
