import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  MemberBettingLogStyledModal,
} from '@/shared/components/CustomModal'

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CustomBtnTableWrap } from '../../../../../shared/components/TableElements'
import MemberHistoryBetting from './MemberHistoryBetting'

function MemberBettingLogModal({ memberId }) {
  const [isMemberCouponModalShow, setMemberCouponModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberCouponModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <CustomButton onClick={() => onClickHandler()}>배팅로그</CustomButton>
      <MemberBettingLogStyledModal show={isMemberCouponModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>{memberId} 배팅 내역</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <MemberHistoryBetting memberId={memberId} />
        </CustomModalBody>
      </MemberBettingLogStyledModal>
    </CustomBtnTableWrap>
  )
}

export default MemberBettingLogModal

MemberBettingLogModal.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const CustomButton = styled.button`
  padding: 2px 10px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  border: none;
  background-color: #fa2a57;
`
