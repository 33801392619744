import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa'
import Switch from 'react-switch' // Import the Switch component
import styled from 'styled-components'
import {
  SportBetLineShowTypeEnums,
  SportBetLineShowTypeEnumsV2,
  SportBettingPerStatusEnums,
  SportIdTypeEnums,
  SportMaxLineTypeEnums,
  SportMaxLineTypeEnumsV2,
  SportSumBettingTypeEnumsV2,
  SportsMarketTypeEnums,
} from '../../../enums/SystemManagement/sportEnums'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'
import MarketModifyModal from './marketModifyModal/MarketModifyModal'
import Loading from '../../../shared/components/Loading'

const MarketListItem = React.memo(
  ({
    sportMarketTypeId,
    marketItem,
    index,
    handleBettingPerByMultiply,
    handleBettingPerByPlus,
    onDeleteSubmit,
    onChageOddperPlus,
    onChangeSportsBetLineShowTypeHandler,
    handleSwitchChange,
    onChangeSportMaxLineTypeHandler,
    onChageOddperMultiply,
    onChangeSportsDetailInfoHandler,
    setMarketCombinationId,
    setMarketCombinationName,
  }) => {
    const [modifyModalShow, setModifyModalShow] = useState(false)
    const modifyModalHandler = () => {
      setModifyModalShow(prev => !prev)
    }

    const changeMixedHandler = marketId => {
      setMarketCombinationId(prev => {
        // prev가 없거나, 빈 문자열이거나, 마지막 문자가 +인 경우, + 없이 marketId만 반환
        if (!prev) {
          return `${marketId}`
        }
        if (prev.endsWith('+')) {
          return `${prev}${marketId}`
        }
        return `${prev}+${marketId}`
        // 그 외의 경우 (+가 없는 경우)
      })
    }

    const getBettingDetails = (typeId, item) => {
      const typeMap = {
        DOMESTIC: {
          minimum: item.domesticMinimumBettingPer,
          minimumStatus: item.domesticMinimumBettingPerStatus,
          maximum: item.domesticMaximumBettingPer,
          maximumStatus: item.domesticMaximumBettingPerStatus,
          sumBettingPer: item.domesticSumBettingPer,
          sumBettingSmallType: item.domesticSumBettingSmallType,
          sumBettingBigType: item.domesticSumBettingBigType,
        },
        OVERSEAS: {
          minimum: item.overseasMinimumBettingPer,
          minimumStatus: item.overseasMinimumBettingPerStatus,
          maximum: item.overseasMaximumBettingPer,
          maximumStatus: item.overseasMaximumBettingPerStatus,
          sumBettingPer: item.overseasSumBettingPer,
          sumBettingSmallType: item.overseasSumBettingSmallType,
          sumBettingBigType: item.overseasSumBettingBigType,
        },
        SPECIAL: {
          minimum: item.specialMinimumBettingPer,
          minimumStatus: item.specialMinimumBettingPerStatus,
          maximum: item.specialMaximumBettingPer,
          maximumStatus: item.specialMaximumBettingPerStatus,
          sumBettingPer: item.specialSumBettingPer,
          sumBettingSmallType: item.specialSumBettingSmallType,
          sumBettingBigType: item.specialSumBettingBigType,
        },
        LIVE: {
          minimum: item.liveMinimumBettingPer,
          minimumStatus: item.liveMinimumBettingPerStatus,
          maximum: item.liveMaximumBettingPer,
          maximumStatus: item.liveMaximumBettingPerStatus,
          sumBettingPer: item.liveSumBettingPer,
          sumBettingSmallType: item.liveSumBettingSmallType,
          sumBettingBigType: item.liveSumBettingBigType,
        },
      }
      return (
        typeMap[typeId] || {
          minimum: 'N/A',
          minimumStatus: 'N/A',
          maximum: 'N/A',
          maximumStatus: 'N/A',
          sumBettingPer: 'N/A',
        }
      )
    }

    const bettingDetails = getBettingDetails(sportMarketTypeId, marketItem)

    const getOfferBettingValues = (typeId, item, offerType) => {
      const baseKey = `${typeId.toLowerCase()}${offerType}`
      return [
        item[`${baseKey}FirstOfferBettingPerByMultiply`],
        item[`${baseKey}SecondOfferBettingPerByMultiply`],
        item[`${baseKey}ThirdOfferBettingPerByMultiply`],
        item[`${baseKey}FirstOfferBettingPerByPlus`],
        item[`${baseKey}SecondOfferBettingPerByPlus`],
        item[`${baseKey}ThirdOfferBettingPerByPlus`],
      ]
    }

    const [firstMultiply, secondMultiply, thirdMultiply, firstPlus, secondPlus, thirdPlus] = getOfferBettingValues(
      sportMarketTypeId,
      marketItem,
      '',
    )

    const getSportsBetLineShowType = (typeId, item) => {
      const types = {
        DOMESTIC: item.domesticSportsBetLineShowType,
        OVERSEAS: item.overseasSportsBetLineShowType,
        SPECIAL: item.specialSportsBetLineShowType,
        LIVE: item.liveSportsBetLineShowType,
      }
      return types[typeId] || 'default' // Assuming 'default' is a valid value or handle accordingly
    }

    const currentSportsBetLineShowType = getSportsBetLineShowType(sportMarketTypeId, marketItem)

    const getMaxLineType = (typeId, item) => {
      const types = {
        DOMESTIC: item.domesticMaxLineType,
        OVERSEAS: item.overseasMaxLineType,
        SPECIAL: item.specialMaxLineType,
        LIVE: item.liveMaxLineType,
      }
      return types[typeId] || 'default' // Assuming 'default' is a valid fallback or handle accordingly
    }

    const currentMaxLineType = getMaxLineType(sportMarketTypeId, marketItem)

    const getOneFolderBettingAllow = (typeId, item) => {
      const types = {
        DOMESTIC: item.domesticOneFolderBettingAllow,
        OVERSEAS: item.overseasOneFolderBettingAllow,
        SPECIAL: item.specialOneFolderBettingAllow,
        LIVE: item.liveOneFolderBettingAllow,
      }
      return types[typeId] || false // Default to false if typeId not matched
    }

    const currentOneFolderBettingAllow = getOneFolderBettingAllow(sportMarketTypeId, marketItem)

    const getMarketStatus = (typeId, item) => {
      const types = {
        DOMESTIC: item.domesticMarket,
        OVERSEAS: item.overseasMarket,
        SPECIAL: item.specialMarket,
        LIVE: item.liveMarket,
      }
      return types[typeId] || false // Default to false if typeId not matched
    }

    const currentMarketStatus = getMarketStatus(sportMarketTypeId, marketItem)

    const getMainMarketStatus = (typeId, item) => {
      const types = {
        DOMESTIC: item.domesticMainMarket,
        OVERSEAS: item.overseasMainMarket,
        SPECIAL: item.specialMainMarket,
        LIVE: item.liveMainMarket,
      }
      return types[typeId] || false // Default to false if typeId not matched
    }

    const currentMainMarketStatus = getMainMarketStatus(sportMarketTypeId, marketItem)

    return (
      <>
        {modifyModalShow && (
          <MarketModifyModal
            sportMarketType={sportMarketTypeId}
            marketDetailInfo={marketItem}
            onChangeSportsDetailInfoHandler={onChangeSportsDetailInfoHandler}
            modifyModalShow={modifyModalShow}
            modifyModalHandler={modifyModalHandler}
          />
        )}
        <tr key={marketItem.marketKey}>
          <td>{index + 1}</td>
          <td>
            <img
              style={{ width: '20px', height: 'auto' }}
              src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${marketItem.sportId}.png`}
              alt={SportIdTypeEnums[marketItem.sportId]}
            />
            &nbsp;
            {marketItem.sportName}
          </td>
          <CustomTd3>{marketItem.marketId}</CustomTd3>
          <td>{marketItem.marketNameKo}</td>
          <td>{marketItem.marketSortNum}</td>
          <CustomTd4 settlementMarket={marketItem.settlementMarket}>
            <span style={{ color: marketItem.settlementMarket ? 'red' : 'green' }}>
              {marketItem.settlementMarket ? 'X' : 'O'}
            </span>
          </CustomTd4>
          <td>
            {bettingDetails.minimum}
            <br />({SportBettingPerStatusEnums[bettingDetails.minimumStatus]})
          </td>
          <td>
            {bettingDetails.maximum}
            <br />({SportBettingPerStatusEnums[bettingDetails.maximumStatus]})
          </td>
          <td>{marketItem.offerBettingPerCount === 2 ? bettingDetails.sumBettingPer : '-'}</td>
          <td>
            {marketItem.offerBettingPerCount === 2
              ? SportSumBettingTypeEnumsV2[bettingDetails.sumBettingSmallType]
              : '-'}
          </td>
          <td>
            {marketItem.offerBettingPerCount === 2 ? SportSumBettingTypeEnumsV2[bettingDetails.sumBettingBigType] : '-'}
          </td>

          <td>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <span style={{ fontWeight: 600 }}>배당X :&nbsp;</span>
                {[firstMultiply, secondMultiply, thirdMultiply].map((value, idx) => (
                  <CustomInput
                    key={`${marketItem.marketKey}${idx + 1}Multiply`}
                    type="text"
                    value={value}
                    onChange={e => {
                      handleBettingPerByMultiply(
                        e,
                        marketItem.marketKey,
                        ['First', 'Second', 'Third'][idx],
                        sportMarketTypeId,
                      )
                    }}
                    style={{ width: '50px' }}
                  />
                ))}
                <CustomButton
                  onClick={() => {
                    onChageOddperMultiply(
                      marketItem.marketKey,
                      marketItem.sportId,
                      marketItem.bookmakerId,
                      firstMultiply,
                      secondMultiply,
                      thirdMultiply,
                      sportMarketTypeId,
                    )
                  }}
                  style={{ background: 'blue', marginLeft: '2px' }}
                >
                  변경
                </CustomButton>
              </div>
              <div style={{ display: 'flex' }}>
                <span style={{ fontWeight: 600 }}>
                  배당<span style={{ fontSize: '13px' }}>±</span> :&nbsp;
                </span>
                {[firstPlus, secondPlus, thirdPlus].map((value, idx) => (
                  <input
                    key={`${marketItem.marketKey}${idx + 1}Plus`}
                    type="text"
                    value={value}
                    onChange={e =>
                      handleBettingPerByPlus(
                        e,
                        marketItem.marketKey,
                        ['First', 'Second', 'Third'][idx],
                        sportMarketTypeId,
                      )
                    }
                    style={{ width: '50px' }}
                  />
                ))}
                <CustomButton
                  onClick={() => {
                    onChageOddperPlus(marketItem.marketKey, firstPlus, secondPlus, thirdPlus, sportMarketTypeId)
                  }}
                  style={{ background: 'blue', marginLeft: '2px' }}
                >
                  변경
                </CustomButton>
              </div>
            </div>
          </td>
          <td>
            <select
              onChange={e => {
                const data = {
                  sportsBetLineShowType: e.target.value,
                }
                onChangeSportsBetLineShowTypeHandler(marketItem.marketKey, data, sportMarketTypeId)
              }}
              value={currentSportsBetLineShowType}
            >
              {Object.entries(SportBetLineShowTypeEnumsV2).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </td>
          {/* 기준점 노출 갯수 */}
          <td>
            <select
              onChange={e => {
                const data = {
                  sportMaxLineType: e.target.value,
                }
                onChangeSportMaxLineTypeHandler(marketItem.marketKey, data, sportMarketTypeId)
              }}
              value={currentMaxLineType}
            >
              {Object.entries(SportMaxLineTypeEnumsV2).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
          </td>
          {/* (국내형,해외형,스페셜,라이브)메인마켓 여부 */}
          <td>
            <CustomSwitch
              onChange={() => handleSwitchChange(marketItem.marketKey, 'isMainMarket')}
              checked={currentMainMarketStatus}
            />
          </td>

          {/* (국내형,해외형,스페셜,라이브)대기/발매 여부 */}
          <td>
            <CustomSwitch
              onChange={() => handleSwitchChange(marketItem.marketKey, `${sportMarketTypeId.toLowerCase()}Market`)}
              checked={currentMarketStatus}
            />
          </td>
          <td>
            <CustomSwitch
              onChange={() => handleSwitchChange(marketItem.marketKey, 'oneFolderBettingAllow')}
              checked={currentOneFolderBettingAllow}
            />
          </td>
          <td>
            <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
              <CustomButton onClick={modifyModalHandler} style={{ background: 'blue' }}>
                변경
              </CustomButton>
              <CustomButton onClick={() => changeMixedHandler(marketItem.marketId)} style={{ background: 'red' }}>
                조합
              </CustomButton>
            </div>
          </td>
        </tr>
      </>
    )
  },
)

MarketListItem.propTypes = {
  sportMarketTypeId: PropTypes.string.isRequired,
  marketItem: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  handleBettingPerByMultiply: PropTypes.func.isRequired,
  handleBettingPerByPlus: PropTypes.func.isRequired,
  onDeleteSubmit: PropTypes.func.isRequired,
  onChageOddperPlus: PropTypes.func.isRequired,
  onChangeSportsBetLineShowTypeHandler: PropTypes.func.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  onChangeSportMaxLineTypeHandler: PropTypes.func.isRequired,
  onChageOddperMultiply: PropTypes.func.isRequired,
  onChangeSportsDetailInfoHandler: PropTypes.func.isRequired,
  setMarketCombinationName: PropTypes.func.isRequired,
  setMarketCombinationId: PropTypes.func.isRequired,
  // modifyModalHandler: PropTypes.func.isRequired,
}

const MarketList = ({
  sportMarketTypeId,
  marketContent,
  handleBettingPerByMultiply,
  handleBettingPerByPlus,
  onDeleteSubmit,
  onChageOddperPlus,
  onChangeSportsBetLineShowTypeHandler,
  handleSwitchChange,
  onChangeSportMaxLineTypeHandler,
  onChageOddperMultiply,
  onChangeSportsDetailInfoHandler,
  setMarketCombinationName,
  setMarketCombinationId,
}) => {
  const [sortOrder, setSortOrder] = useState('default') // default, sortXFirst, sortOFirst
  const [sortField, setSortField] = useState(null) // null, settlementMarket, isMainMarket, sportMarketTypeId.toLowerCase(), oneFolderBettingAllow

  const [isLoading, setIsLoading] = useState(false)

  const toggleSortOrder = field => {
    setIsLoading(true) // Start loading

    setSortOrder(prevOrder => {
      let newOrder
      if (sortField !== field) {
        setSortField(field)
        newOrder = 'ascending' // When a new field is clicked, set to ascending
      } else {
        newOrder = prevOrder === 'ascending' ? 'descending' : 'ascending'
      }
      return newOrder
    })

    setTimeout(() => {
      setIsLoading(false) // Stop loading after the sorting logic is handled
    }, 500)
  }

  const getSortValue = (marketItem, field) => {
    switch (field) {
      case 'settlementMarket':
        return marketItem.settlementMarket ? 1 : 0
      case `${sportMarketTypeId.toLowerCase()}MainMarket`:
        return marketItem[`${sportMarketTypeId.toLowerCase()}MainMarket`] ? 1 : 0
      case `${sportMarketTypeId.toLowerCase()}Market`:
        return marketItem[`${sportMarketTypeId.toLowerCase()}Market`] ? 1 : 0
      case 'oneFolderBettingAllow':
        return marketItem.oneFolderBettingAllow ? 1 : 0
      default:
        return 0
    }
  }

  const sortedMarketContent = [...marketContent].sort((a, b) => {
    if (sortOrder === 'ascending') {
      return getSortValue(a, sortField) - getSortValue(b, sortField)
    }
    if (sortOrder === 'descending') {
      return getSortValue(b, sortField) - getSortValue(a, sortField)
    }
    return 0
  })

  const renderSortIcon = field => {
    if (sortField !== field) return <FaSortUp /> // Initially show FaSortDown if it's a new field
    return sortOrder === 'ascending' ? <FaSortUp /> : <FaSortDown />
  }

  return (
    <MarketListWrap>
      {isLoading && <Loading />}
      <table>
        <thead>
          <tr>
            <th rowSpan="2">번호</th>
            <th rowSpan="2">종목</th>
            <th rowSpan="2">마켓ID</th>
            <th rowSpan="2">마켓명</th>
            <th rowSpan="2">순서</th>
            <th rowSpan="2" onClick={() => toggleSortOrder('settlementMarket')}>
              수동처리 {renderSortIcon('settlementMarket')}
              <br />
              여부
            </th>
            <CustomTh colSpan="12" sportMarketTypeId={sportMarketTypeId} style={{ borderBottom: 'none' }}>
              {SportsMarketTypeEnums[sportMarketTypeId]}
            </CustomTh>
          </tr>
          <tr>
            <th style={{ borderLeft: 'none' }}>
              현시
              <br />
              최소배당
            </th>
            <th>
              현시
              <br />
              최대배당
            </th>
            <th>
              <TooltipWrapper text="값이 0이 경우, 해당 마켓은 합배당 미사용 " place="bottom">
                <span style={{ textDecoration: 'underLine', color: '#2663a3', cursor: 'pointer' }}>합배당</span>
              </TooltipWrapper>
            </th>
            <th>
              <TooltipWrapper text="설정된 합배당 보다 작을때, 적용여부" place="bottom">
                <span style={{ textDecoration: 'underLine', color: '#2663a3', cursor: 'pointer' }}>
                  합배당
                  <br />
                  보다 작을때
                </span>
              </TooltipWrapper>
            </th>
            <th>
              <TooltipWrapper text="설정된 합배당 보다 클때, 적용여부" place="bottom">
                <span style={{ textDecoration: 'underLine', color: '#2663a3', cursor: 'pointer' }}>
                  합배당
                  <br />
                  보다 클때
                </span>
              </TooltipWrapper>
            </th>
            <th>
              <TooltipWrapper text="배당X(기본값 1): 비율로 조정, 배당±(기본값 0): +,-값으로 조정" place="bottom">
                <span style={{ textDecoration: 'underLine', color: '#2663a3', cursor: 'pointer' }}>
                  배당조정 <br />
                  (정배,무,역배)
                </span>
              </TooltipWrapper>
            </th>
            <th>
              <TooltipWrapper text="전역설정 시, 스포츠 배당설정 메뉴에서 설정된 값으로 사용" place="bottom">
                <span style={{ textDecoration: 'underLine', color: '#2663a3', cursor: 'pointer' }}>
                  기준점 <br />
                  노출타입
                </span>
              </TooltipWrapper>
            </th>
            <th>
              기준점 <br />
              노출갯수
            </th>
            <th rowSpan="2" onClick={() => toggleSortOrder(`${sportMarketTypeId.toLowerCase()}MainMarket`)}>
              메인마켓 여부{renderSortIcon(`${sportMarketTypeId.toLowerCase()}MainMarket`)}
            </th>
            <th rowSpan="2" onClick={() => toggleSortOrder(`${sportMarketTypeId.toLowerCase()}Market`)}>
              마켓사용 여부{renderSortIcon(`${sportMarketTypeId.toLowerCase()}Market`)}
            </th>
            <th rowSpan="2" onClick={() => toggleSortOrder('oneFolderBettingAllow')}>
              단폴베팅 가능{renderSortIcon('oneFolderBettingAllow')}
            </th>

            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {sortedMarketContent.map((marketItem, index) => (
            <MarketListItem
              key={`mli-${index + 1}`}
              sportMarketTypeId={sportMarketTypeId}
              marketItem={marketItem}
              index={index}
              handleBettingPerByMultiply={handleBettingPerByMultiply}
              handleBettingPerByPlus={handleBettingPerByPlus}
              onDeleteSubmit={onDeleteSubmit}
              onChageOddperPlus={onChageOddperPlus}
              onChangeSportsBetLineShowTypeHandler={onChangeSportsBetLineShowTypeHandler}
              handleSwitchChange={handleSwitchChange}
              onChangeSportMaxLineTypeHandler={onChangeSportMaxLineTypeHandler}
              onChageOddperMultiply={onChageOddperMultiply}
              onChangeSportsDetailInfoHandler={onChangeSportsDetailInfoHandler}
              setMarketCombinationId={setMarketCombinationId}
              setMarketCombinationName={setMarketCombinationName}
            />
          ))}
        </tbody>
      </table>
    </MarketListWrap>
  )
}

export default React.memo(MarketList)

MarketList.propTypes = {
  sportMarketTypeId: PropTypes.string.isRequired,
  marketContent: PropTypes.arrayOf().isRequired,
  handleBettingPerByMultiply: PropTypes.func.isRequired,
  handleBettingPerByPlus: PropTypes.func.isRequired,
  onDeleteSubmit: PropTypes.func.isRequired,
  onChageOddperPlus: PropTypes.func.isRequired,
  onChangeSportsBetLineShowTypeHandler: PropTypes.func.isRequired,
  handleSwitchChange: PropTypes.func.isRequired,
  onChangeSportMaxLineTypeHandler: PropTypes.func.isRequired,
  onChageOddperMultiply: PropTypes.func.isRequired,
  onChangeSportsDetailInfoHandler: PropTypes.func.isRequired,
  setMarketCombinationName: PropTypes.func.isRequired,
  setMarketCombinationId: PropTypes.func.isRequired,
}

const getColor = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#ffffab'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomTh = styled.th`
  background: ${props => getColor(props.sportMarketTypeId)} !important;
  color: #000 !important;
  font-weight: 600 !important;
`

const CustomInput = styled.input`
  width: 50px;
  border: solid 1px rgb(118, 118, 118);
  background-color: ${props => (props.disabled ? '#e0e0e0' : 'white')};
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`

const MarketListWrap = styled.div`
  width: 100%;
  font-size: 11px;
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-weight: 500;
    white-space: nowrap;
  }

  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
    z-index: 98;
  }

  thead tr {
    border: none;

    z-index: 98;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
    &:first-child {
      border-left: solid 1px black;
    }
    z-index: 98;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 11px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 11px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 11px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const CustomTd3 = styled.td`
  background: #faebd7;
`

const CustomTd4 = styled.td`
  background: #ededed;
  span {
    font-weight: 900;
  }
`

const CustomButton = styled.button`
  width: 30px;
  height: 20px;
  color: white;
  border: none;
`
