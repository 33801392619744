import { AccountButton, LoginForm } from '@/shared/components/account/AccountElements'
import Error from '@/shared/components/form/Error'
import {
  FormFieldButton,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import EyeIcon from 'mdi-react/EyeIcon'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import OtpInput from 'react-otp-input'
import { loginValidate } from '../../../utils/validate/authManage/authManageValidate'

const OtpForm = ({ onSubmit }) => {
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <LoginForm onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>OTP 인증번호</FormGroupLabel>
            <FormGroupField>
              <Field name="otpNum">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <OtpInput
                      onChange={e => {
                        input.onChange(e)
                      }}
                      isInputNum
                      numInputs={6}
                      separator={<span>-</span>}
                    />
                    {/* <input
                      {...input}
                      type="text"
                      value={input.value}
                      onChange={e => {
                        input.onChange(e)
                      }}
                      placeholder="OTP 번호를 입력하세요"
                    /> */}
                    {meta.touched && meta.error && <Error error={meta.error} />}
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <AccountButton type="submit" variant="primary">
            인증번호 확인
          </AccountButton>
        </LoginForm>
      )}
    </Form>
  )
}

OtpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default OtpForm
