import { CustomAuthorityButton } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../../../enums/Operation/NoteEnums'
import CustomPagination from '../../../../../shared/components/customPagination/CustomPagination'
import { searchRevolutionHoldemBuyInOutLog } from '../../../../../utils/api/holdem/revolutionHoldemApi'
import {
  convertToKst,
  convertToKstDepositDateTime,
  convertToKstSportsEventDateTime,
} from '../../../../../utils/dateTime'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberNote/MemberNote'

const MemberHistoryBettingHoldemBuyInOut = ({ memberId }) => {
  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    userId: memberId,
    page: 0,
    size: 5,
    isExact: true,
  })

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [holdemBuyInOutLogList, setHoldemBuyInOutLogList] = useState([])

  const fetchRevolutionHoldemBuyInOutLog = async () => {
    setLoading(true)

    await searchRevolutionHoldemBuyInOutLog(params)
      .then(res => {
        setTotalElement(res.data.totalElements)
        setHoldemBuyInOutLogList(res.data.holdemBuyInOutLogList)
      })
      .catch(error => {
        // console.log('dd')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchRevolutionHoldemBuyInOutLog()
  }, [params])

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }
  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 5))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))
  }

  const getBuyInHistoryCompo = buyIn => {
    let temp = ''
    Object.entries(buyIn).forEach(([index, value]) => {
      temp += ` [ 금액: ${value?.amount || 0}, 시간: ${convertToKstDepositDateTime(value?.time)} ] `
    })

    return <>{temp || '-'}</>
  }

  return (
    <>
      {/* <SearchCardTitleWrap>
        <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
          <p>* 배팅 후 잔액, 당첨금은 결과가 나온 후 표기됩니다.</p>
        </div>
      </SearchCardTitleWrap> */}
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>

      <SportBettingLogContentWrap>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>사이트명</th>
              <th>상위파트너 (이름)</th>
              <th>유저 (이름)</th>
              <th>블라인드(SB/BB)</th>
              <th>총 배팅액</th>
              <th>바이인 시간</th>
              <th>바이인 이력</th>
              <th>바이아웃 시간</th>
              <th>정산금액</th>
              <th>생성일</th>
              <th>노출여부</th>
            </tr>
          </thead>
          <tbody>
            {holdemBuyInOutLogList.length === 0 ? (
              <tr>
                <td colSpan={15}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      color: '#000',
                      fontSize: '15px',
                      padding: '20px 0px',
                    }}
                  >
                    최근 게시물이 없습니다.
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {holdemBuyInOutLogList.map(holdemBuyInOutLog => {
                  return (
                    <>
                      <tr key={holdemBuyInOutLog.holdemBettingLogKey}>
                        <td>{holdemBuyInOutLog.num}</td>
                        <td>{holdemBuyInOutLog.edgeName}</td>
                        <td>
                          {holdemBuyInOutLog.topUserId ? (
                            <>
                              {holdemBuyInOutLog.topUserId} ({holdemBuyInOutLog.topUserName})
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              window.open(`/user-info/${holdemBuyInOutLog.uid}`, '_blank')
                            }}
                            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {holdemBuyInOutLog.uid} ({holdemBuyInOutLog.userName})
                          </span>
                          <span style={{ marginLeft: '5px' }}>
                            <CustomAuthorityButton authority={holdemBuyInOutLog.userAuthority}>
                              {NoteAuthorityEnums[holdemBuyInOutLog.userAuthority]}
                            </CustomAuthorityButton>
                          </span>
                          <span>
                            <span>
                              <MemberDepositInfo memberId={holdemBuyInOutLog.uid} />
                            </span>
                            <span>
                              <MemberCoupon memberId={holdemBuyInOutLog.uid} />
                            </span>
                            <span>
                              <MemberNote memberId={holdemBuyInOutLog.uid} />
                            </span>
                            <span>
                              <MemberMoneyIncDecMini memberId={holdemBuyInOutLog.uid} />
                            </span>
                          </span>
                        </td>
                        <td>{holdemBuyInOutLog.blind || '-'}</td>
                        <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                          {holdemBuyInOutLog.totalBet.toString().replace(commonReg2, ',')}
                        </td>
                        <td>{convertToKstSportsEventDateTime(holdemBuyInOutLog.buyInAt)}</td>
                        <td>{getBuyInHistoryCompo(holdemBuyInOutLog.buyIn)}</td>
                        <td>
                          {holdemBuyInOutLog.leaveAt ? convertToKstSportsEventDateTime(holdemBuyInOutLog.leaveAt) : '-'}
                        </td>
                        <td>{holdemBuyInOutLog.seatOutMoney.toString().replace(commonReg2, ',')}</td>
                        <td>{convertToKstSportsEventDateTime(holdemBuyInOutLog.createAt)}</td>
                        <td>
                          {holdemBuyInOutLog.showFlag ? (
                            <span>노출</span>
                          ) : (
                            <span style={{ color: 'red' }}>비노출</span>
                          )}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </>
            )}
          </tbody>
        </table>
        <PaginationWrap>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </PaginationWrap>
      </SportBettingLogContentWrap>
    </>
  )
}

export default MemberHistoryBettingHoldemBuyInOut

MemberHistoryBettingHoldemBuyInOut.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const MiniGameTypeSelectWrapper = styled.div`
  padding: 10px 0;
  // margin-bottom: 5px;

  display: flex;
  gap: 5px;
`

const SportBettingLogContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
