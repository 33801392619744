import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const SportManagementTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/management/sport/bettinglist?type=SOCCER')
                }}
              >
                프리매치 스포츠 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="7"
                onClick={e => {
                  history.push('/management/sport/live-bettinglist?type=BASEBALL')
                }}
              >
                라이브 스포츠 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="8"
                onClick={e => {
                  history.push('/management/sport/unprocessed')
                }}
              >
                미처리된 경기
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/management/sport/settlement')
                }}
              >
                스포츠 정산
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/management/sport/setting')
                }}
              >
                마켓&조합 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/management/sport/league/manage?type=SOCCER')
                }}
              >
                리그/팀 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="6"
                onClick={e => {
                  history.push('/management/sport/betting/manage')
                }}
              >
                배당&배팅 설정
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="9"
                onClick={e => {
                  history.push('/management/sport/manual/manage')
                }}
              >
                수동 경기등록
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default SportManagementTabs

SportManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
