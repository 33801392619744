import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { searchMemberRollingInfo } from '../../../../../utils/api/logManage/logApi'
import CasinoRollingInfo from './CasinoRollingInfo/CasinoRollingInfo'
import SportsRollingInfo from './SportsRollingInfo/SportsRollingInfo'
import MiniGameRollingInfo from './MiniGameRollingInfo/MiniGameRollingInfo'
import HoldemRollingInfo from './HoldemRollingInfo/HoldemRollingInfo'
import VirtualGameRollingInfo from './VirtualGameRollingInfo/VirtualGameRollingInfo'

const MemberRollingInfoPage = ({ memberId }) => {
  const [params, setParams] = useState({
    memberId,
  })

  const [casinoRollingInfo, setCasinoRollingPer] = useState({})
  const [sportsRollingInfo, setSportsRollingInfo] = useState({})
  const [miniGameRollingInfo, setMiniGameRollingInfo] = useState({})
  const [holdemRollingInfo, setHoldemRollingInfo] = useState({})
  const [virtualGameRollingInfo, setVirtualGameRollingInfo] = useState({})
  const [topPartnerCasinoRollingInfo, setTopPartnerCasinoRollingPer] = useState({})
  const [topPartnerSportsRollingInfo, setTopPartnerSportsRollingInfo] = useState({})
  const [topPartnerMiniGameRollingInfo, setTopPartnerMiniGameRollingInfo] = useState({})
  const [topPartnerHoldemRollingInfo, setTopPartnerHoldemRollingInfo] = useState({})
  const [topPartnerVirtualGameRollingInfo, setTopPartnerVirtualGameRollingInfo] = useState({})
  const [topPartnerExist, setTopPartnerExist] = useState(false)

  const fetchhMemberRollingInfo = async () => {
    await searchMemberRollingInfo(params).then(res => {
      setCasinoRollingPer(res.data.casinoRollingInfo)
      setSportsRollingInfo(res.data.sportsRollingInfo)
      setMiniGameRollingInfo(res.data.miniGameRollingInfo)
      setHoldemRollingInfo(res.data.holdemRollingInfo)
      setVirtualGameRollingInfo(res.data.virtualGameRollingInfo)
      setTopPartnerCasinoRollingPer(res.data.topPartnerCasinoRollingInfo)
      setTopPartnerSportsRollingInfo(res.data.topPartnerSportsRollingInfo)
      setTopPartnerMiniGameRollingInfo(res.data.topPartnerMiniGameRollingInfo)
      setTopPartnerHoldemRollingInfo(res.data.topPartnerHoldemRollingInfo)
      setTopPartnerVirtualGameRollingInfo(res.data.topPartnerVirtualGameRollingInfo)
      setTopPartnerExist(res.data.topPartnerExist)
    })
  }

  useEffect(() => {
    fetchhMemberRollingInfo()
  }, [])

  return (
    <>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>카지노 롤링 설정</h3>
      <CasinoRollingInfo
        casinoRollingInfo={casinoRollingInfo}
        memberId={memberId}
        topPartnerCasinoRollingInfo={topPartnerCasinoRollingInfo}
        fetchhMemberRollingInfo={fetchhMemberRollingInfo}
      />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>미니게임 롤링 설정</h3>
      <MiniGameRollingInfo
        miniGameRollingInfo={miniGameRollingInfo}
        memberId={memberId}
        topPartnerMiniGameRollingInfo={topPartnerMiniGameRollingInfo}
        fetchhMemberRollingInfo={fetchhMemberRollingInfo}
      />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>스포츠 롤링 설정</h3>
      <SportsRollingInfo
        sportsRollingInfo={sportsRollingInfo}
        memberId={memberId}
        topPartnerSportsRollingInfo={topPartnerSportsRollingInfo}
        fetchhMemberRollingInfo={fetchhMemberRollingInfo}
      />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>가상게임 롤링 설정</h3>
      <VirtualGameRollingInfo
        virtualGameRollingInfo={virtualGameRollingInfo}
        memberId={memberId}
        topPartnerVirtualGameRollingInfo={topPartnerVirtualGameRollingInfo}
        fetchhMemberRollingInfo={fetchhMemberRollingInfo}
      />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>홀덤 롤링 설정</h3>
      <HoldemRollingInfo
        holdemRollingInfo={holdemRollingInfo}
        memberId={memberId}
        topPartnerHoldemRollingInfo={topPartnerHoldemRollingInfo}
        fetchhMemberRollingInfo={fetchhMemberRollingInfo}
      />
    </>
  )
}

export default MemberRollingInfoPage

MemberRollingInfoPage.propTypes = {
  memberId: PropTypes.string.isRequired,
}
