import axios from '../base/axios'

/**
 * 수동 경기 및 마켓 등록하기
 */
export async function createNewSportsEventOrMarketByManual(body) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/manual/sports-event',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 수동 경기 및 마켓 등록하기
 */
export async function createNewSportsEventOrMarketByManualV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/manual/fixture-market',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 수동 마켓 등록시 경기 조회하기
 */
export async function searchSportsEventListForManual(sportsType) {
  const response = await axios({
    url: `/tyson/admin/v1/sport/management/manual/sports-event/${sportsType}`,
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

/**
 * 수동 마켓 등록시 경기 조회하기
 */
export async function searchSportsEventListForManualV2(sportId) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/manual/sports-event/${sportId}`,
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

/**
 * 수동 마켓 등록시 마켓 조회하기
 */
export async function searchSportsMarketListForManualV2(sportId) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/manual/sports-event/markets/${sportId}`,
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

/**
 * 수동 마켓 등록시 마켓 조회 (select 용 )
 */
export async function searchSportsMarketListForManual() {
  const response = await axios({
    url: `/tyson/admin/v1/sport/management/manual/sports-event/markets`,
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

/**
 * 수동 등록된 경기들 조회
 */
export async function searchManualSportsListForManual(params) {
  const response = await axios({
    url: `/tyson/admin/v1/sport/management/manual/sports-event/manual`,
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function searchManualSportsListForManualV2(params) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/manual/sports-event-list`,
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 수동 등록된 경기 결과 처리
 */
export async function processManualSportsResultForManual(body) {
  const response = await axios({
    url: `/tyson/admin/v1/sport/management/manual/sports-event/manual/result`,
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
