export const partnerNoteValidate = values => {
  const errors = {}

  if (!values.noteTarget) {
    errors.noteTarget = '필수 선택 항목입니다.'
  }
  if (!values.noteTitle) {
    errors.noteTitle = '필수 입력 항목입니다.'
  }
  if (!values.noteContent || values.noteContent === '') {
    errors.noteContent = '필수 입력 항목입니다.'
  }

  return errors
}
