import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import BoardModifyModal from './BoardModifyModal'
import { BoardTypeEnums, BoardTargetEnums, BoardStatusEnums } from '../../../enums/Operation/boardEnums'
import { convertToKstDate, convertToKstDateTimeSimple } from '../../../utils/dateTime'
import BoardRadio from './BoardRadio'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const BoardListData = (content, fetchSearchBoardList, checkRadio, setCheckRadio, onTitleClickRowHandler) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <BoardRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
      width: 30,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.num}
          </div>
        )
      },
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.edgeName}
          </div>
        )
      },
    },
    {
      Header: '구분',
      accessor: 'type',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.type) {
          case 'EVENT':
            return (
              <button
                onClick={() => {
                  onTitleClickRowHandler()
                }}
                type="button"
                style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '900' }}
              >
                {BoardTypeEnums[original.type]}
              </button>
            )
          case 'NOTICE':
            return (
              <button
                onClick={() => {
                  onTitleClickRowHandler()
                }}
                type="button"
                style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '900' }}
              >
                {BoardTypeEnums[original.type]}
              </button>
            )

          default:
            return (
              <button
                onClick={() => {
                  onTitleClickRowHandler()
                }}
                type="button"
                style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}
              >
                {BoardTypeEnums[original.type]}
              </button>
            )
        }
      },
    },
    {
      Header: '제목',
      accessor: 'boardTitle',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.boardTitle}
          </div>
        )
      },
    },
    {
      Header: '작성자',
      accessor: 'writer',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.writer}
          </div>
        )
      },
    },
    {
      Header: '등록일',
      accessor: 'registrationDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {convertToKstDateTimeSimple(original.registrationDate)}
          </div>
        )
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'HIDDEN':
            return (
              <button
                onClick={() => {
                  onTitleClickRowHandler()
                }}
                type="button"
                style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}
              >
                {BoardStatusEnums[original.status]}
              </button>
            )
          case 'SHOW':
            return (
              <button
                onClick={() => {
                  onTitleClickRowHandler()
                }}
                type="button"
                style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '900' }}
              >
                {BoardStatusEnums[original.status]}
              </button>
            )

          default:
            return (
              <button
                onClick={() => {
                  onTitleClickRowHandler()
                }}
                type="button"
                style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}
              >
                {BoardStatusEnums[original.status]}
              </button>
            )
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default BoardListData

BoardListData.propTypes = {
  fetchSearchBoardList: PropTypes.func.isRequired,
}
