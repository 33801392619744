export const RollingBettingTypeEnums = {
  CASINO_LIVE_BETTING: '라이브 베팅',
  CASINO_SLOT_BETTING: '슬롯 베팅',
  MINI_GAME_SINGLE_BETTING: '미니게임 일반배팅',
  MINI_GAME_COMBINATION_BETTING: '미니게임 조합배팅',
  SPORTS_ONE_FOLDER_BETTING: '스포츠 단폴배팅',
  SPORTS_TWO_FOLDER_BETTING: '스포츠 2폴배팅',
  SPORTS_THREE_FOLDER_BETTING: '스포츠 3폴배팅',
  SPORTS_FOUR_FOLDER_BETTING: '스포츠 4폴배팅',
  SPORTS_FIVE_FOLDER_BETTING: '스포츠 5폴배팅',
  SPORTS_MULTI_FOLDER_BETTING: '스포츠 다폴배팅',
  HOLDEM_BETTING: '홀덤 배팅',
  VIRTUAL_GAME_SOCCER_BETTING: '가상축구 배팅',
  VIRTUAL_GAME_BASEBALL_BETTING: '가상야구 배팅',
  VIRTUAL_GAME_BASKETBALL_BETTING: '가상농구 배팅',
  VIRTUAL_GAME_HORSE_BETTING: '가상경마 배팅',
  VIRTUAL_GAME_GREYHOUNDS_BETTING: '개경주 배팅',
}

export const LosingBettingTypeEnums = {
  CASINO_LIVE_BETTING: '라이브 베팅-당첨',
  CASINO_SLOT_BETTING: '슬롯 베팅-당첨',
  MINI_GAME_SINGLE_BETTING: '미니게임 일반 배팅-당첨',
  MINI_GAME_COMBINATION_BETTING: '미니게임 조합 배팅-당첨',
  SPORTS_ONE_FOLDER_BETTING: '스포츠 단폴 배팅-당첨',
  SPORTS_TWO_FOLDER_BETTING: '스포츠 2폴 배팅-당첨',
  SPORTS_THREE_FOLDER_BETTING: '스포츠 3폴 배팅-당첨',
  SPORTS_FOUR_FOLDER_BETTING: '스포츠 4폴 배팅-당첨',
  SPORTS_FIVE_FOLDER_BETTING: '스포츠 5폴 배팅-당첨',
  SPORTS_MULTI_FOLDER_BETTING: '스포츠 다폴 배팅-당첨',
  VIRTUAL_GAME_SOCCER_FOLDER_BETTING: '가상축구 배팅-당첨',
  VIRTUAL_GAME_BASEBALL_FOLDER_BETTING: '가상야구 배팅-당첨',
  VIRTUAL_GAME_BASKETBALL_FOLDER_BETTING: '가상농구 배팅-당첨',
  VIRTUAL_GAME_HORSE_FOLDER_BETTING: '가상경마 배팅-당첨',
  VIRTUAL_GAME_GREYHOUNDS_FOLDER_BETTING: '개경주 배팅-당첨',
  HOLDEM_BETTING: '홀덤 배팅-당첨',
}
