import { DamageTypeRadioButtonGroup } from '@/enums/MemberManage/BlackListEnums'
import { CustomDatePickerWrap } from '@/shared/components/CustomDatePicker'
import {
  BlackMemberRegisterCustomStyledModal,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
} from '@/shared/components/CustomModal'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { createBlackMember } from '@/utils/api/memberManage/blackMemberManageApi'
import { blackMemberRegisterValidate } from '@/utils/validate/memberManage/blackListValidate'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import { decodeAccessToken } from '../../../utils/token'
import './BlackMember.css'

function BlackRegistrationModal({ isBlackRegistrationModalShow, onRegistrationHandler, fetchSearchBlackMember }) {
  const authority = decodeAccessToken()?.adminAuthority
  const onSubmit = e => {
    const body = {
      memberId: e.memberId,
      damageType: e.damageType,
      damageAmount: e.damageAmount,
      damageDate: e.damageDate,
      damageContent: e.damageContent,
    }

    createBlackMember(body)
      .then(res => {
        onRegistrationHandler()
        fetchSearchBlackMember()
        alert(`${e.memberId}님이 블랙리스트에 등록되었습니다.`)
      })
      .catch(error => {
        alert(`블랙리스트에 등록이 실패했습니다.`)
      })
  }

  return (
    <div>
      <BlackMemberRegisterCustomStyledModal show={isBlackRegistrationModalShow} onHide={onRegistrationHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onRegistrationHandler}
          />
          <CustomModalTitle>블랙리스트 등록</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <Form onSubmit={onSubmit} validate={blackMemberRegisterValidate}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <div style={{ marginBottom: '15px' }}>
                  {' '}
                  * <span style={{ color: 'red' }}>블랙회원</span> 등록 시, 자동으로 연락처,은행명,계좌번호 등이
                  입력되니, 아래 정보만 입력하시면 됩니다.{' '}
                </div>
                <FormGroup>
                  <FormGroupLabel>용의자 ID</FormGroupLabel>
                  <FormGroupField>
                    <Field name="memberId">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            placeholder="용의자 아이디를 입력하세요"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>피해타입</FormGroupLabel>
                  {DamageTypeRadioButtonGroup.map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>피해액</FormGroupLabel>
                  <FormGroupField>
                    <Field name="damageAmount">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            placeholder="피해금액을 입력하세요"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                  <FormGroupDescription>미입력 시 &quot;0&quot; 으로 생성됩니다.</FormGroupDescription>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>피해발생일</FormGroupLabel>
                  <FormGroupField>
                    <Field name="damageDate">
                      {({ input, meta }) => (
                        <CustomDatePickerWrap>
                          <DatePicker
                            selected={input.value}
                            onChange={date => input.onChange(date)}
                            dateFormat="yyyy-MM-dd"
                            dropDownMode="select"
                            popperPlacement="bottom-start"
                            placeholderText="피해발생일을 선택하세요."
                            isClearable
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </CustomDatePickerWrap>
                      )}
                    </Field>

                    {/* <Field name="damageDate" component={renderDatePickerField} /> */}
                    {/* <FormGroupIcon>
                      <CalendarBlankIcon />
                    </FormGroupIcon> */}
                  </FormGroupField>
                  <FormGroupDescription>미입력 시 금일 날짜로 생성됩니다.</FormGroupDescription>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>내용</FormGroupLabel>
                  <FormGroupField>
                    <Field name="damageContent">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            placeholder="피해 내용을 입력하세요"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">
                          블랙리스트 등록
                        </Button>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </CustomModalBody>
      </BlackMemberRegisterCustomStyledModal>
    </div>
  )
}

export default BlackRegistrationModal

BlackRegistrationModal.propTypes = {
  isBlackRegistrationModalShow: PropTypes.bool.isRequired,
  onRegistrationHandler: PropTypes.func.isRequired,
  fetchSearchBlackMember: PropTypes.func.isRequired,
}
