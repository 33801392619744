import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { VirtualGameSportType } from '../../../../enums/VirtualGame/virtualGameEnums'
import Loading from '../../../../shared/components/Loading'
import { searchAdminVirtualGameLevel } from '../../../../utils/api/systemManage/levelManageApi'
import VirtualGameLevelBaseball from './VirtualGameLevelBaseball'
import VirtualGameLevelBasketball from './VirtualGameLevelBasketball'
import VirtualGameLevelGreyhounds from './VirtualGameLevelGreyhounds'
import VirtualGameLevelHorse from './VirtualGameLevelHorse'
import VirtualGameLevelSoccer from './VirtualGameLevelSoccer'

const VirtualGameLevelPage = ({ selectedLevel }) => {
  const [virtualGameSportType, setVirtualGameSportType] = useState('VIRTUAL_SOCCER')
  const [name, setName] = useState('가상 축구')

  const [loading, setLoading] = useState(false)
  const [levelInfo, setLevelInfo] = useState({})

  const onClickTypeHandler = (type, name) => {
    setVirtualGameSportType(type)
    setName(name)
  }

  const fetchVirtualGameLevel = () => {
    setLoading(true)

    searchAdminVirtualGameLevel({
      virtualGameLevel: selectedLevel,
      virtualGameSportType,
    })
      .then(res => {
        setLevelInfo(res.data.virtualGameLevelModel)
      })
      .catch(error => {})
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchVirtualGameLevel()
  }, [selectedLevel, virtualGameSportType])

  const getVirtualGameCompo = type => {
    switch (type) {
      case 'VIRTUAL_SOCCER':
        return <VirtualGameLevelSoccer levelInfo={levelInfo} fetchVirtualGameLevel={fetchVirtualGameLevel} />
      case 'VIRTUAL_BASKETBALL':
        return <VirtualGameLevelBasketball levelInfo={levelInfo} fetchVirtualGameLevel={fetchVirtualGameLevel} />
      case 'VIRTUAL_BASEBALL':
        return <VirtualGameLevelBaseball levelInfo={levelInfo} fetchVirtualGameLevel={fetchVirtualGameLevel} />
      case 'VIRTUAL_GREYHOUNDS':
        return <VirtualGameLevelGreyhounds levelInfo={levelInfo} fetchVirtualGameLevel={fetchVirtualGameLevel} />
      case 'VIRTUAL_HORSE':
        return <VirtualGameLevelHorse levelInfo={levelInfo} fetchVirtualGameLevel={fetchVirtualGameLevel} />
      default:
        return null
    }
  }

  return (
    <>
      {loading && <Loading />}
      <VirtualGameSportTypeWrapper>
        {Object.entries(VirtualGameSportType).map(([key, value]) => {
          return (
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              active={key === virtualGameSportType}
              onClick={() => {
                onClickTypeHandler(key, value)
              }}
            >
              {value}
            </Button>
          )
        })}
      </VirtualGameSportTypeWrapper>

      <VirtualGameLevelContentWrapper>{getVirtualGameCompo(virtualGameSportType)}</VirtualGameLevelContentWrapper>
    </>
  )
}

export default VirtualGameLevelPage

VirtualGameLevelPage.propTypes = {
  selectedLevel: PropTypes.string.isRequired,
}

const VirtualGameSportTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
`

const VirtualGameLevelContentWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`
