import { Button } from '@/shared/components/Button'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import Switch from 'react-switch'
import styled from 'styled-components'
import { VirtualGameSportType } from '../../../../enums/VirtualGame/virtualGameEnums'
import { updateAdminVirtualGameLevel } from '../../../../utils/api/systemManage/levelManageApi'
import { priceNumPattern } from '../../../../utils/sportUtills'
import { decodeAccessToken } from '../../../../utils/token'

const VirtualGameLevelBaseball = ({ levelInfo, fetchVirtualGameLevel }) => {
  const authority = decodeAccessToken()?.adminAuthority

  const [loading, setLoading] = useState(false)
  const [newLevelInfo, setNewLevelInfo] = useState({})

  useEffect(() => {
    setNewLevelInfo(levelInfo)
  }, [levelInfo])

  const onChangeLevelInfoHandler = (key, value) => {
    setNewLevelInfo(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const onSubmit = allChange => {
    if (loading) return

    if (authority === 'NORMAL') return

    let confirmText

    if (allChange) {
      confirmText = `${VirtualGameSportType.VIRTUAL_BASEBALL} 모든 레벨 설정을 일괄 변경 하시겠습니까?`
    } else {
      confirmText = `${VirtualGameSportType.VIRTUAL_BASEBALL} 설정을 변경 하시겠습니까?`
    }

    if (window.confirm(confirmText)) {
      setLoading(true)

      const body = {
        allChange,
        virtualGameLevel: newLevelInfo,
      }

      updateAdminVirtualGameLevel(body)
        .then(res => {
          alert('변경 되었습니다.')
        })
        .catch(error => {
          alert('변경 실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
          fetchVirtualGameLevel()
        })
    }
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form: { reset } }) => (
          <VirtualGameForm horizontal onSubmit={handleSubmit}>
            <VirtualGameFormInnerBoxHeader>{VirtualGameSportType.VIRTUAL_BASEBALL}</VirtualGameFormInnerBoxHeader>
            <VirtualGameFormInnerBox>
              <table>
                <tr>
                  <th scope="row">다른경기와 배팅가능여부</th>
                  <td>
                    <CustomSwitch
                      onChange={e => {
                        onChangeLevelInfoHandler('diffLeagueBetting', e)
                      }}
                      checked={newLevelInfo?.diffLeagueBetting ?? false}
                    />
                  </td>
                  <th scope="row">경기 노출갯수</th>
                  <td>
                    <Field name="showFixtureCount">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.showFixtureCount}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('showFixtureCount', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">배팅 마감시간</th>
                  <td>
                    <Field name="bettingOkTimeCriteria">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.bettingOkTimeCriteria}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('bettingOkTimeCriteria', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">회차별 허용 배팅수</th>
                  <td>
                    <Field name="allowedBettingCount">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.allowedBettingCount}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('allowedBettingCount', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">단폴배팅 삭감비율</th>
                  <td>
                    <Field name="reduceBettingPerForSingleFolder">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.reduceBettingPerForSingleFolder}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('reduceBettingPerForSingleFolder', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">콤프 비율</th>
                  <td>
                    <Field name="virtualGameCompPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.virtualGameCompPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('virtualGameCompPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">낙첨 비율</th>
                  <td>
                    <Field name="virtualGameLossPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.virtualGameLossPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('virtualGameLossPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">최소 배팅금액</th>
                  <td>
                    <Field name="minBettingAmount">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.minBettingAmount}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('minBettingAmount', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">최대 배팅금액</th>
                  <td>
                    <Field name="maxBettingAmount">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.maxBettingAmount}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('maxBettingAmount', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">최대 당첨금액</th>
                  <td>
                    <Field name="maxWinningAmount">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.maxWinningAmount}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('maxWinningAmount', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">최대 배팅배당</th>
                  <td>
                    <Field name="maxBettingPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.maxBettingPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('maxBettingPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row">승 최대 배당</th>
                  <td>
                    <Field name="winMaxBettingPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.winMaxBettingPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('winMaxBettingPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">무 최대 배당</th>
                  <td>
                    <Field name="drawMaxBettingPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.drawMaxBettingPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('drawMaxBettingPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">패 최대 배당</th>
                  <td>
                    <Field name="loseMaxBettingPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.loseMaxBettingPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('loseMaxBettingPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">정배 배당조정</th>
                  <td>
                    <Field name="winDrawLoseForwardBettingPerAdjustment">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.winDrawLoseForwardBettingPerAdjustment}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('winDrawLoseForwardBettingPerAdjustment', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">무배 배당조정</th>
                  <td>
                    <Field name="winDrawLoseDrawBettingPerAdjustment">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.winDrawLoseDrawBettingPerAdjustment}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('winDrawLoseDrawBettingPerAdjustment', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">역배 배당조정</th>
                  <td>
                    <Field name="winDrawLoseReverseBettingPerAdjustment">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.winDrawLoseReverseBettingPerAdjustment}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('winDrawLoseReverseBettingPerAdjustment', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">오버 최대 배당</th>
                  <td>
                    <Field name="overMaxBettingPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.overMaxBettingPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('overMaxBettingPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">언더 최대 배당</th>
                  <td>
                    <Field name="underMaxBettingPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.underMaxBettingPer}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('underMaxBettingPer', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">언오버 정배 배당조정</th>
                  <td>
                    <Field name="underOverForwardBettingPerAdjustment">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.underOverForwardBettingPerAdjustment}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('underOverForwardBettingPerAdjustment', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">언오버 역배 배당조정</th>
                  <td>
                    <Field name="underOverReverseBettingPerAdjustment">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.underOverReverseBettingPerAdjustment}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('underOverReverseBettingPerAdjustment', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">승패 + 언오버 조합 여부</th>
                  <td>
                    <CustomSwitch
                      onChange={e => {
                        onChangeLevelInfoHandler('usedWinLoseUnderOver', e)
                      }}
                      checked={newLevelInfo?.usedWinLoseUnderOver ?? false}
                    />
                  </td>

                  <th scope="row">승패 + 언오버 조합 배당삭감</th>
                  <td>
                    <Field name="reduceBettingPerForWinLoseUnderOver">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.reduceBettingPerForWinLoseUnderOver}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('reduceBettingPerForWinLoseUnderOver', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>

                  <th scope="row">무 + 언오버 조합 여부</th>
                  <td>
                    <CustomSwitch
                      onChange={e => {
                        onChangeLevelInfoHandler('usedDrawUnderOver', e)
                      }}
                      checked={newLevelInfo?.usedDrawUnderOver ?? false}
                    />
                  </td>
                  <th scope="row">무 + 언오버 조합 배당삭감</th>
                  <td>
                    <Field name="reduceBettingPerForDrawUnderOver">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={newLevelInfo?.reduceBettingPerForDrawUnderOver}
                          placeholder="0"
                          onChange={e => {
                            const inputValue = e.target.value
                            onChangeLevelInfoHandler('reduceBettingPerForDrawUnderOver', inputValue)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
              </table>
            </VirtualGameFormInnerBox>
            {authority === 'SUPER' && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Button
                  size="sm"
                  variant="primary"
                  type="button"
                  onClick={() => {
                    onSubmit(false)
                  }}
                >
                  변경하기
                </Button>
                <Button
                  size="sm"
                  variant="danger"
                  type="button"
                  onClick={() => {
                    onSubmit(true)
                  }}
                >
                  모든레벨 변경하기
                </Button>
              </div>
            )}
          </VirtualGameForm>
        )}
      </Form>
    </>
  )
}

export default VirtualGameLevelBaseball

VirtualGameLevelBaseball.propTypes = {
  levelInfo: PropTypes.shape().isRequired,
  fetchVirtualGameLevel: PropTypes.func.isRequired,
}

const VirtualGameForm = styled.form`
  display: flex;
  flex-direction: column;

  padding: 20px;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid #000;
    color: #646777;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const VirtualGameFormInnerBoxHeader = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 500;

  margin-bottom: 10px;
`

const VirtualGameFormInnerBox = styled.div`
  width: 100%;

  table {
    white-space: nowrap;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      text-align: right;
      padding-right: 15px;
    }

    td {
      padding: 10px;
    }
  }
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
