export const SlotCityCallStatusType = {
  INIT: '초기 상태',
  RUNNING: '진행중',
  COMPLETE: '완료',
  CANCEL: '취소',
}

export const SlotCityCallStatusTypeSelectOptions = () => {
  return Object.entries(SlotCityCallStatusType).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
