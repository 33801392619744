import {
  Card,
  CardBody,
  PartnerSettlementCardSubhead,
  PartnerSettlementCardTitle,
  PartnerSettlementCardTitleWrap,
} from '@/shared/components/Card'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { searchAccumulateStatus } from '../../../utils/api/statistics/accumulateStatisticsApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'

const TotalAccumulateList = ({ edgeName }) => {
  /**
   * 총 누적 현황
   */

  const [content, setContent] = useState([])
  const [apiLoading, setApiLoading] = useState(false)
  const [totalProfit, setTotalProfit] = useState(0)
  const [totalHoldemProfit, setTotalHoldemProfit] = useState(0)
  const [sumData, setSumData] = useState({
    totalCount: 0,
    totalDeposit: 0,
    totalWithdrawal: 0,
    totalHoldemDeposit: 0,
    totalHoldemWithdrawal: 0,
    totalAsset: 0,
    totalBetting: 0,
    totalWinning: 0,
    totalRolling: 0,
    totalLosing: 0,
    totalMoneyProfit: 0,
    totalHoldemMoneyProfit: 0,
    totalBettingProfit: 0,
  })

  const fetchSearchAccumulateStatus = async () => {
    setApiLoading(true)
    setTotalProfit(0)
    setTotalHoldemProfit(0)
    setSumData({
      totalCount: 0,
      totalDeposit: 0,
      totalWithdrawal: 0,
      totalHoldemDeposit: 0,
      totalHoldemWithdrawal: 0,
      totalAsset: 0,
      totalHoldemAsset: 0,
      totalBetting: 0,
      totalWinning: 0,
      totalRolling: 0,
      totalLosing: 0,
      totalMoneyProfit: 0,
      totalHoldemMoneyProfit: 0,
      totalBettingProfit: 0,
    })
    await searchAccumulateStatus({ edgeName })
      .then(res => {
        setContent(res.data.content)
        const totalProfitValue = res.data.content.reduce((acc, item) => {
          return acc + item.totalMoneyProfit - item.totalAsset
        }, 0)

        setTotalProfit(prev => prev + totalProfitValue)
        setSumData(prev =>
          res.data.content.reduce(
            (acc, item) => ({
              totalCount: acc.totalCount + item.totalCount,
              totalDeposit: acc.totalDeposit + item.totalDeposit,
              totalWithdrawal: acc.totalWithdrawal + item.totalWithdrawal,
              totalHoldemDeposit: acc.totalHoldemDeposit + item.totalHoldemDeposit,
              totalHoldemWithdrawal: acc.totalHoldemWithdrawal + item.totalHoldemWithdrawal,
              totalAsset: acc.totalAsset + item.totalAsset,
              totalHoldemAsset: acc.totalHoldemAsset + item.totalHoldemAsset,
              totalBetting: acc.totalBetting + item.totalBetting,
              totalWinning: acc.totalWinning + item.totalWinning,
              totalRolling: acc.totalRolling + item.totalRolling,
              totalLosing: acc.totalLosing + item.totalLosing,
              totalMoneyProfit: acc.totalMoneyProfit + item.totalMoneyProfit,
              totalHoldemMoneyProfit: acc.totalHoldemMoneyProfit + item.totalHoldemMoneyProfit,
              totalBettingProfit: acc.totalBettingProfit + item.totalBettingProfit,
            }),
            {
              totalCount: prev.totalCount,
              totalDeposit: prev.totalDeposit,
              totalWithdrawal: prev.totalWithdrawal,
              totalHoldemDeposit: prev.totalHoldemDeposit,
              totalHoldemWithdrawal: prev.totalHoldemWithdrawal,
              totalAsset: prev.totalAsset,
              totalHoldemAsset: prev.totalHoldemAsset,
              totalBetting: prev.totalBetting,
              totalWinning: prev.totalWinning,
              totalRolling: prev.totalRolling,
              totalLosing: prev.totalLosing,
              totalMoneyProfit: prev.totalMoneyProfit,
              totalHoldemMoneyProfit: prev.totalHoldemMoneyProfit,
              totalBettingProfit: prev.totalBettingProfit,
            },
          ),
        )
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    if (!edgeName) return
    fetchSearchAccumulateStatus()
  }, [edgeName])

  const [finalProfit, setFinalProfit] = useState('')
  const checkNumber = checkMoney => {
    return Math.sign(checkMoney)
  }
  useEffect(() => {
    setFinalProfit(checkNumber(totalProfit).toString())
  }, [totalProfit])

  return (
    <>
      <Card style={{ marginTop: '30px' }}>
        <CardBody style={{ padding: '0px' }}>
          <PartnerSettlementCardTitleWrap>
            <PartnerSettlementCardTitle>
              <span style={{ fontSize: '12px', fontWeight: '600', color: 'red' }}>(가입일 부터 현재 시점 기준)</span>{' '}
            </PartnerSettlementCardTitle>
            <PartnerSettlementCardSubhead>
              <span style={{ color: 'black', fontWeight: 'bolder' }}>
                총 누적 수익액(총 입금액 - 총 출금액 - 총 보유액) (홀덤제외) :
              </span>{' '}
              &nbsp;
              {
                {
                  1: (
                    <>
                      <span style={{ fontWeight: 'bolder', color: 'blue', fontSize: '15px' }}>
                        {totalProfit.toString().replace(commonReg2, ',')}원
                      </span>
                    </>
                  ),
                  0: (
                    <>
                      <span style={{ fontWeight: 'bolder', color: 'black', fontSize: '15px' }}> {totalProfit} 원</span>
                    </>
                  ),
                  '-1': (
                    <>
                      <span style={{ fontWeight: 'bolder', color: 'red', fontSize: '15px' }}>
                        {totalProfit.toString().replace(commonReg2, ',')}원
                      </span>
                    </>
                  ),
                }[finalProfit]
              }
            </PartnerSettlementCardSubhead>
          </PartnerSettlementCardTitleWrap>

          {apiLoading ? (
            <Loading />
          ) : (
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th scope="col">구분</th>
                    <th scope="col">총 인원수</th>
                    <th>총 입금액(일반/홀덤)</th>
                    <th>총 출금액(일반/홀덤)</th>
                    <CustomTh colorText="isMain" textColor="profit">
                      총 (입금-출금)액
                      <br />
                      (일반/홀덤)
                    </CustomTh>
                    <th>총 베팅액</th>
                    <th>총 당첨액</th>
                    <CustomTh colorText="isMain" textColor="profit">
                      총 (베팅-당첨)액
                    </CustomTh>
                    <th>총 발생 롤링액</th>
                    <th>총 발생 루징액</th>
                    <th>
                      총 보유액
                      <br />
                      (머니 + 포인트)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {content?.length !== 0 && (
                    <tr style={{ background: 'rgba(255,24,8,0.3)', color: 'red' }}>
                      <td>합계</td>
                      <td>{sumData?.totalCount?.toString().replace(commonReg2, ',')}</td>
                      <td>
                        {sumData?.totalDeposit?.toString().replace(commonReg2, ',')} (
                        {sumData?.totalHoldemDeposit?.toString().replace(commonReg2, ',')})
                      </td>
                      <td>
                        {sumData?.totalWithdrawal?.toString().replace(commonReg2, ',')} (
                        {sumData?.totalHoldemWithdrawal?.toString().replace(commonReg2, ',')})
                      </td>
                      <td>
                        {sumData?.totalMoneyProfit?.toString().replace(commonReg2, ',')} (
                        {sumData?.totalHoldemMoneyProfit?.toString().replace(commonReg2, ',')})
                      </td>
                      <td>{sumData?.totalBetting?.toString().replace(commonReg2, ',')}</td>
                      <td>{sumData?.totalWinning?.toString().replace(commonReg2, ',')}</td>
                      <td>{sumData?.totalBettingProfit?.toString().replace(commonReg2, ',')}</td>
                      <td>{sumData?.totalRolling?.toString().replace(commonReg2, ',')}</td>
                      <td>{sumData?.totalLosing?.toString().replace(commonReg2, ',')}</td>
                      <td>{sumData?.totalAsset?.toString().replace(commonReg2, ',')}</td>
                    </tr>
                  )}
                  {content?.map((original, index) => (
                    <tr>
                      {original.authority === 'PARTNER' ? <td>{original.partnerLevel}LV 파트너</td> : <td>회원</td>}
                      <td>{original?.totalCount?.toString().replace(commonReg2, ',')}</td>
                      <td>
                        {original?.totalDeposit?.toString().replace(commonReg2, ',')} (
                        {original?.totalHoldemDeposit?.toString().replace(commonReg2, ',')})
                      </td>
                      <td>
                        {original?.totalWithdrawal?.toString().replace(commonReg2, ',')} (
                        {original?.totalHoldemWithdrawal?.toString().replace(commonReg2, ',')})
                      </td>
                      <td>
                        {original?.totalMoneyProfit?.toString().replace(commonReg2, ',')} (
                        {original?.totalHoldemMoneyProfit?.toString().replace(commonReg2, ',')})
                      </td>
                      <td>{original?.totalBetting?.toString().replace(commonReg2, ',')}</td>
                      <td>{original?.totalWinning?.toString().replace(commonReg2, ',')}</td>
                      <td>{original?.totalBettingProfit?.toString().replace(commonReg2, ',')}</td>
                      <td>{original?.totalRolling?.toString().replace(commonReg2, ',')}</td>
                      <td>{original?.totalLosing?.toString().replace(commonReg2, ',')}</td>
                      <td>{original?.totalAsset?.toString().replace(commonReg2, ',')}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {content?.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
            </TableWrap>
          )}
        </CardBody>
      </Card>
    </>
  )
}

export default TotalAccumulateList

TotalAccumulateList.propTypes = {
  edgeName: PropTypes.string.isRequired,
}

const getColor = colorText => {
  switch (true) {
    case colorText === 'isActiveLiveInfo':
      return 'green'
    case colorText === 'isActiveSlotInfo':
      return '#a66414'
    case colorText === 'isActiveSportsInfo':
      return 'purple'
    case colorText === 'isActiveMiniGameInfo':
      return '#808212'
    case colorText === 'isMain':
      return 'blue'
    default:
      return 'lightgrey'
  }
}

const CustomTh = styled.th`
  background: ${props => getColor(props.colorText)} !important;
  opacity: 0.7;
  color: ${props => (props.textColor === 'profit' ? 'yellow' : '#fff')} !important;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border: 1px solid #888;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`
