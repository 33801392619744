import React, { useEffect, useState } from 'react'
import { Badge, Modal as BootstrapModal, Button } from 'react-bootstrap'
import { ButtonToolbar } from '@/shared/components/Button'
import { Checkbox } from '@mui/material'
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
  FormButtonToolbar,
} from '@/shared/components/form/FormElements'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorText,
  colorWhite,
  colorFieldsBorder,
} from '@/utils/palette'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { updateSport } from '../../../utils/api/sport/sportApi'

const SportModifyModal = ({
  sportInfo,
  bookmakerList,
  sportModifyModalShow,
  onCloseSportModifyModalShowHandler,
  fetchSearchSportList,
}) => {
  const [bookmakerSelectOptionValue, setBookmakerSelectOptionValue] = useState({})
  const bookmakerSelectOption = bookmakerList.map(bookmaker => {
    return {
      value: bookmaker.bookmakerId,
      label: bookmaker.bookmakerName,
    }
  })

  const onSelectBookmakerHandler = option => {
    setBookmakerSelectOptionValue(option)
  }

  const [visible, setVisible] = useState(false)

  const [sportSortNum, setSportSortNum] = useState()

  useEffect(() => {
    setBookmakerSelectOptionValue({
      value: sportInfo.bookmakerId,
      label: sportInfo.bookmakerName,
    })
    setVisible(sportInfo.visible)
    setSportSortNum(sportInfo.sportSortNum)
  }, [sportInfo])

  const [loading, setLoading] = useState(false)

  const onSubmit = e => {
    if (loading) return

    setLoading(true)

    const body = {
      sportId: sportInfo.sportId,
      sportImageUrl: '',
      sportSortNum,
      bookmakerId: bookmakerSelectOptionValue.value,
    }

    updateSport(body)
      .then(res => {
        setLoading(false)
        alert('수정이 완료됐습니다.')
        onCloseSportModifyModalShowHandler()
        fetchSearchSportList()
      })
      .catch(error => {
        alert('수정 실패했습니다. 다시 시도해주세요.')
        setLoading(false)
        onCloseSportModifyModalShowHandler()
      })
  }

  return (
    <>
      <StyledModal show={sportModifyModalShow} onHide={onCloseSportModifyModalShowHandler} header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onCloseSportModifyModalShowHandler}
          />
          <ModalTitle>
            <span>[{sportInfo?.sportName}] 종목 수정</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ContentBox>
            <Form onSubmit={onSubmit} initialValues={{ sportInfo }}>
              {({ handleSubmit, form: { reset } }) => (
                <CustomFormContainer horizontal onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>종목 코드</FormGroupLabel>
                    <FormGroupField>
                      <Field name="sportId">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input {...input} type="text" value={sportInfo?.sportId} readOnly disabled />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>종목 명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="sportName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input {...input} type="text" value={sportInfo?.sportName} readOnly disabled />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>북메이커</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bookmakerName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <CustomSelect
                              {...input}
                              value={bookmakerSelectOptionValue}
                              onChange={option => {
                                onSelectBookmakerHandler(option)
                              }}
                              options={bookmakerSelectOption}
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>정렬 순서</FormGroupLabel>
                    <FormGroupField>
                      <Field name="sportSortNum">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input
                              {...input}
                              type="text"
                              value={sportSortNum}
                              onChange={e => {
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setSportSortNum(onlyNumber)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar style={{ marginLeft: '0' }}>
                    <Button variant="primary" type="submit">
                      수정
                    </Button>
                  </FormButtonToolbar>
                </CustomFormContainer>
              )}
            </Form>
          </ContentBox>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default SportModifyModal

SportModifyModal.propTypes = {
  sportInfo: PropTypes.shape(),
  bookmakerList: PropTypes.arrayOf(PropTypes.shape()),
  sportModifyModalShow: PropTypes.bool,
  onCloseSportModifyModalShowHandler: PropTypes.func,
  fetchSearchSportList: PropTypes.func,
}

SportModifyModal.defaultProps = {
  sportInfo: {},
  bookmakerList: [],
  sportModifyModalShow: false,
  onCloseSportModifyModalShowHandler: () => {},
  fetchSearchSportList: () => {},
}

const ContentBox = styled.div`
  width: 100%;
`

const CustomFormContainer = styled(FormContainer)`
  flex-direction: column;
  padding: 10px 0;
`
const VisibleCheckbox = styled(Checkbox)`
  width: 10px;
  height: 10px;
`

const CustomSelect = styled(Select)`
  width: 100%;
  //   height: 40px;
  font-size: 12px;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      border-right: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: hidden;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div`
  background-color: grey !important;
  font-weight: 700;
`
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 500px !important;
    margin-top: 100px;
  }

  .modal-content {
    height: max-content;
    min-height: 400px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`
