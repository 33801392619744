import { ButtonToolbar } from '@/shared/components/Button'
import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { DomainStatusEnumsRadioOptions } from '../../../enums/SystemManagement/urlEnums'
import { MemberResterBankSelect } from '../../../shared/components/CustomSelect'
import Error from '../../../shared/components/form/Error'
import { searchUrlPartnerInfos, updateUrlInfo } from '../../../utils/api/systemManage/systemManageApi'
import { decodeAccessToken } from '../../../utils/token'
import Loading from '../../../shared/components/Loading'

function UrlModifyModal({ urlInfo, fetchSearchUrls }) {
  const [isUrlModifyModalShow, setIsUrlModifyModalShow] = useState(false)

  const onClickHandler = () => {
    setIsUrlModifyModalShow(prevState => !prevState)
    fetchSearchUrls()
  }

  const [domainUrl, setDomainUrl] = useState('')
  const [urlStatus, setUrlStatus] = useState('')

  const [urlPartnerInfoOptionValue, setUrlPartnerInfoOptionValue] = useState(null)
  const [urlPartnerInfoOption, setUrlPartnerInfoOption] = useState([])

  const onUrlPatnerInfoOptionsChangeHandler = option => {
    setUrlPartnerInfoOptionValue(option)
  }

  const [searchApiLoading, setSearchApiLoading] = useState(false)

  useEffect(() => {
    if (!isUrlModifyModalShow) return
    setDomainUrl(urlInfo.domainUrl)
    setUrlStatus(urlInfo.isUsed)

    if (urlInfo.partnerId) {
      setUrlPartnerInfoOptionValue({
        value: urlInfo.partnerId,
        label: urlInfo.partnerId
          ? `${urlInfo.partnerId} [${urlInfo.partnerName}] - 추천코드 : ${urlInfo.recommendedCode} `
          : '',
        partnerName: urlInfo.partnerName,
        recommendedCode: urlInfo.recommendedCode,
      })
    }

    setSearchApiLoading(true)

    searchUrlPartnerInfos({
      edgeName: urlInfo.edgeName,
    }).then(res => {
      setUrlPartnerInfoOption(
        res.data.content.map(item => ({
          value: item.partnerId,
          label: `${item.partnerId} [${item.partnerName}] - 추천코드 : ${item.recommendedCode}`,
          partnerName: item.partnerName,
          recommendedCode: item.recommendedCode,
        })),

        setSearchApiLoading(false),
      )
    })
  }, [isUrlModifyModalShow, urlInfo])

  const [updateApiLoading, setUpdateApiLoading] = useState(false)

  const onSubmit = e => {
    if (urlPartnerInfoOptionValue && !urlPartnerInfoOptionValue.recommendedCode) {
      alert('파트너 추천코드 먼저 생성 후 할당해주세요.')
      return
    }
    setUpdateApiLoading(true)

    const body = {
      urlKey: urlInfo.domainKey,
      partnerId: urlPartnerInfoOptionValue?.value || '',
      partnerName: urlPartnerInfoOptionValue?.partnerName || '',
      recommendedCode: urlPartnerInfoOptionValue?.recommendedCode || '',
      urlStatus: e.isUsed === 'true',
    }

    updateUrlInfo(body)
      .then(res => {
        alert('수정되었습니다.')
        setUpdateApiLoading(false)
        onClickHandler()
      })
      .catch(err => {
        alert('수정 실패했습니다.')
        setUpdateApiLoading(false)
        onClickHandler()
      })
  }

  return (
    <div>
      <CustomButton onClick={() => setIsUrlModifyModalShow(true)}>수정</CustomButton>
      {updateApiLoading && <Loading />}

      {isUrlModifyModalShow && (
        <StyledModal show={isUrlModifyModalShow} onHide={onClickHandler} $header>
          <ModalHeader>
            <ModalCloseButton className="lnr lnr-cross" aria-label="close-btn" type="button" onClick={onClickHandler} />
            <ModalTitle>클라이언트 URL 상태 수정</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <SearchCardTitleWrap>
              <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
                <p>
                  * 해당 사이트의 접속을 막고 싶으시면 &nbsp;
                  <span style={{ color: 'blue', fontWeight: 'bolder' }}>미사용</span>으로 선택해주세요.
                </p>
              </div>
            </SearchCardTitleWrap>
            <Form
              onSubmit={onSubmit}
              initialValue={{
                domainUrl,
                urlStatus,
                urlPartnerInfoOptionValue,
                searchApiLoading,
              }}
            >
              {({ handleSubmit, form: { reset } }) => (
                <FormContainer horizontal onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>사이트명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="edgeName">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={urlInfo.edgeName}
                              onChange={e => {
                                input.onChange(e)
                              }}
                              disabled
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>클라이언트 URL주소</FormGroupLabel>
                    <FormGroupField>
                      <Field name="domainUrl">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={domainUrl}
                              onChange={e => {
                                input.onChange(e)
                              }}
                              disabled
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>파트너 정보</FormGroupLabel>
                    <FormGroupField>
                      <Field name="urlPartnerInfoOptionValue">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <MemberResterBankSelect
                              {...input}
                              value={urlPartnerInfoOptionValue}
                              onChange={option => {
                                onUrlPatnerInfoOptionsChangeHandler(option)
                              }}
                              options={urlPartnerInfoOption}
                              placeholder="파트너를 선택하세요."
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                    {searchApiLoading ? (
                      <FormGroupDescription style={{ color: 'red', fontSize: '11px' }}>
                        * 파트너 정보를 불러오고 있습니다.
                      </FormGroupDescription>
                    ) : (
                      <FormGroupDescription style={{ color: 'black', fontSize: '11px' }}>
                        * 파트너 미할당시 비우고 수정하세요.
                      </FormGroupDescription>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>URL 상태</FormGroupLabel>
                    {DomainStatusEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={urlStatus.toString()}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  {decodeAccessToken()?.adminAuthority === 'SUPER' && (
                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">
                        수정하기
                      </Button>
                    </FormButtonToolbar>
                  )}
                </FormContainer>
              )}
            </Form>
          </ModalBody>
        </StyledModal>
      )}
    </div>
  )
}

export default UrlModifyModal

UrlModifyModal.propTypes = {
  urlInfo: PropTypes.shape(),
  fetchSearchUrls: PropTypes.func.isRequired,
}

UrlModifyModal.defaultProps = {
  urlInfo: {},
}

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 800px !important;
  }

  .modal-content {
    height: max-content;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
const CustomButton = styled.button`
  padding: 2px 10px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  border: none;
  background-color: #fa2a57;
`
