import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  searchSettlementStatistics,
  searchSettlementStatisticsSpecify,
} from '../utils/api/partnerManage/partnerManageApi'

export const fetchSettlementStatisticsSpecify = createAsyncThunk(
  'userInfo/fetchSettlementStatisticsSpecify',

  async params => {
    const res = await searchSettlementStatisticsSpecify(params)
    return res.data
  },
)

export const fetchSettlementStatisticsBasic = createAsyncThunk(
  'userInfo/fetchSettlementStatisticsBasic',
  async params => {
    const res = await searchSettlementStatistics(params)
    return res.data
  },
)

function toggleIsOpened(userId, data) {
  return data.map(item => {
    const newItem = { ...item } // 얕은 복사로 새 객체 생성

    if (item.userId === userId) {
      newItem.isOpened = !item.isOpened
    }

    if (item.subUserInfos && item.subUserInfos.length > 0) {
      newItem.subUserInfos = toggleIsOpened(userId, item.subUserInfos)
    }

    return newItem
  })
}

function insertOrUpdateSubUserInfo(userList, newData) {
  let isInsertedOrUpdated = false

  userList.forEach(user => {
    if (isInsertedOrUpdated) return // forEach 내에서도 'break'는 사용할 수 없기 때문에 이런 방식으로 처리합니다.

    if (user.userId === newData.topUserId) {
      // 해당 topUserId를 가진 user의 subUserInfos에 newData를 추가하거나 갱신합니다.
      if (user.subUserInfos) {
        const existingIndex = user.subUserInfos.findIndex(subUser => subUser.userId === newData.userId)
        if (existingIndex !== -1) {
          // 존재하면 갱신
          user.subUserInfos[existingIndex] = newData
        } else {
          // 존재하지 않으면 추가
          user.subUserInfos.push(newData)
        }
      } else {
        user.subUserInfos = [newData]
      }

      isInsertedOrUpdated = true
    } else if (user.subUserInfos && user.subUserInfos.length > 0) {
      isInsertedOrUpdated = insertOrUpdateSubUserInfo(user.subUserInfos, newData)
    }
  })

  return isInsertedOrUpdated
}

function updateUserInfo(userList, content) {
  return userList.map(basicItem => {
    const specifyItem = content.find(specify => specify.userId === basicItem.userId)
    if (specifyItem) {
      return { ...basicItem, ...specifyItem } // 두 객체를 합칩니다.
    }
    if (basicItem.subUserInfos && basicItem.subUserInfos.length > 0) {
      basicItem.subUserInfos = updateUserInfo(basicItem.subUserInfos, content)
    }
    return basicItem
  })
}

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    userSettlementList: [],
    apiLoading: false,
    selectedUserId: '',
  },
  reducers: {
    resetSettlementData: (state, action) => {
      state.userSettlementList = []
      state.selectedUserId = ''
    },
    handleToggle: (state, action) => {
      const { userId } = action.payload
      state.selectedUserId = userId
      state.userSettlementList = toggleIsOpened(userId, state.userSettlementList)
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSettlementStatisticsBasic.pending, (state, action) => {
      state.apiLoading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSettlementStatisticsBasic.fulfilled, (state, action) => {
      const { content } = action.payload

      content.forEach(item => {
        if (state.userSettlementList.length > 0 && item.topUserId) {
          // topUserId가 있을 경우 해당 위치에 데이터를 갱신하거나 삽입합니다.
          insertOrUpdateSubUserInfo(state.userSettlementList, item)
        } else {
          // topUserId가 없는 경우 최상위 레벨에 데이터를 갱신하거나 삽입합니다.
          const existingUserIndex = state.userSettlementList.findIndex(user => user.userId === item.userId)
          if (existingUserIndex !== -1) {
            state.userSettlementList[existingUserIndex] = item
          } else {
            state.userSettlementList.push(item)
          }
        }
      })
    })
    builder.addCase(fetchSettlementStatisticsSpecify.fulfilled, (state, action) => {
      const { content } = action.payload
      state.userSettlementList = updateUserInfo(state.userSettlementList, content)
      state.apiLoading = false
    })
  },
})

export const { handleToggle, resetSettlementData } = userInfoSlice.actions

export default userInfoSlice.reducer
