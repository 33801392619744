import React, { useEffect, useState } from 'react'

export const FixtureStatus = {
  NOT_SET: '경기전(NS)',
  NSY: '경기전',
  IN_PROGRESS: '경기 중',
  FINISHED: '경기 종료',
  CANCELLED: '경기 취소',
  POSTPONED: '경기 연기',
  INTERRUPTED: '경기 중단',
  ABANDONED: '경기 포기',
  LOST_COVERAGE: '경기 손실',
  ABOUT_TO_START: '시작 예정',
  INVALID: '',
}

export const getFixtureStatusSelectOption = () => {
  return Object.entries(FixtureStatus)
    .map(([key, value]) => {
      if (key === 'NOT_SET' || key === 'INVALID') return undefined

      return { value: key, label: value }
    })
    .filter(Boolean) // undefined 값을 제거
}

export const getFixtureStatusSelectCompo = (selectedFixtureStatus, onChangeFixtureStatusHandler) => {
  return (
    <select
      onChange={e => {
        onChangeFixtureStatusHandler(e.target.value)
      }}
    >
      <option value="" selected={!selectedFixtureStatus}>
        경기 상태
      </option>
      <option value="NSY" selected={selectedFixtureStatus === 'NSY'}>
        경기 전
      </option>
      <option value="ABOUT_TO_START" selected={selectedFixtureStatus === 'ABOUT_TO_START'}>
        시작 예정
      </option>
      <option value="IN_PROGRESS" selected={selectedFixtureStatus === 'IN_PROGRESS'}>
        경기 중
      </option>
      <option value="FINISHED" selected={selectedFixtureStatus === 'FINISHED'}>
        경기 종료
      </option>
      <option value="CANCELLED" selected={selectedFixtureStatus === 'CANCELLED'}>
        경기 취소
      </option>
      <option value="POSTPONED" selected={selectedFixtureStatus === 'POSTPONED'}>
        경기 연기
      </option>
      <option value="INTERRUPTED" selected={selectedFixtureStatus === 'INTERRUPTED'}>
        경기 중단
      </option>
      <option value="ABANDONED" selected={selectedFixtureStatus === 'ABANDONED'}>
        경기 포기
      </option>
      <option value="LOST_COVERAGE" selected={selectedFixtureStatus === 'LOST_COVERAGE'}>
        경기 손실
      </option>
    </select>
  )
}
