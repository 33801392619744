import { Card, CardBody } from '@/shared/components/Card'
import React from 'react'
import WildGamesGameInfoPage from './WildGamesGameInfoPage'
import WildGamesGameListPage from './WildGamesGameListPage'

const WildGamesManagePage = () => {
  return (
    <Card>
      <CardBody>
        <WildGamesGameInfoPage />

        <WildGamesGameListPage />
      </CardBody>
    </Card>
  )
}

export default WildGamesManagePage
