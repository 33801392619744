import React, { useMemo, useState } from 'react'
import {
  bannerClickEnums,
  bannerMoreClickEnums,
  bannerPositionEnums,
  bannerStatusEnums,
  bannerTargetEnums,
  bannerTypeEnums,
} from '../../../enums/SystemManagement/bannnerEnums'

const BannerListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '순서',
      accessor: 'bannerOrderNum',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배너명',
      accessor: 'bannerName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '이미지 주소',
      accessor: 'imgUrl',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '노출여부',
      accessor: 'bannerStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.bannerStatus) {
          case 'SHOW':
            return (
              <button type="button" style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerStatusEnums[original.bannerStatus]}
              </button>
            )
          case 'HIDE':
            return (
              <button type="button" style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerStatusEnums[original.bannerStatus]}
              </button>
            )

          default:
            return <button type="button">{bannerStatusEnums[original.bannerStatus]}</button>
        }
      },
    },
    {
      Header: '설정 범위',
      accessor: 'bannerTarget',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.bannerTarget) {
          case 'WEB':
            return (
              <button type="button" style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerTargetEnums[original.bannerTarget]}
              </button>
            )
          case 'MOBILE':
            return (
              <button type="button" style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerTargetEnums[original.bannerTarget]}
              </button>
            )

          default:
            return (
              <button type="button" style={{ background: 'navy', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerTargetEnums[original.bannerTarget]}
              </button>
            )
        }
      },
    },
    {
      Header: '배너 위치',
      accessor: 'bannerPosition',
      disableGlobalFilter: true,
      width: 65,

      Cell: r => {
        const { row } = r
        const { original } = row
        return <span>{bannerPositionEnums[original.bannerPosition]}</span>
      },
    },
    {
      Header: '클릭시 이동유무',
      accessor: 'bannerClick',
      disableGlobalFilter: true,
      width: 65,

      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.bannerClick) {
          case true:
            return (
              <button type="button" style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerClickEnums[original.bannerClick]}
              </button>
            )
          case false:
            return (
              <button type="button" style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerClickEnums[original.bannerClick]}
              </button>
            )

          default:
            return <button type="button">{bannerClickEnums[original.bannerClick]}</button>
        }
      },
    },
    {
      Header: '클릭시 이동위치',
      accessor: 'bannerType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.bannerType) {
          case 'EVENT':
            return (
              <button type="button" style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerTypeEnums[original.bannerType]}
              </button>
            )
          case 'NOTICE':
            return (
              <button type="button" style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerTypeEnums[original.bannerType]}
              </button>
            )

          default:
            return (
              <button type="button" style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerTypeEnums[original.bannerType]}
              </button>
            )
        }
      },
    },
    {
      Header: '더보기 버튼유무',
      accessor: 'moreClickShow',
      disableGlobalFilter: true,
      width: 65,

      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.moreClickShow) {
          case true:
            return (
              <button type="button" style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerMoreClickEnums[original.moreClickShow]}
              </button>
            )
          case false:
            return (
              <button type="button" style={{ background: 'red', border: 'none', color: '#fff', fontWeight: '900' }}>
                {bannerMoreClickEnums[original.moreClickShow]}
              </button>
            )

          default:
            return <button type="button">{bannerMoreClickEnums[original.moreClickShow]}</button>
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default BannerListData
