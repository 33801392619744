export const bankStatusEnums = {
  SHOW: '노출',
  HIDE: '숨김',
}

export const bankStatusEnumsSelectOptions = () => {
  return Object.entries(bankStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bankStatusEnumsRadioOptions = () => {
  return Object.entries(bankStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bankStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bankStatus',
          label: value,
          radioValue: key,
        },
  )
}
