import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import { CustomCheckboxCustom, CustomCheckboxWrap } from '@/shared/components/CustomCheckBox'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import {
  SportBetLineShowTypeSelectOptions,
  SportSumBettingTypeSelectOptions,
  SportTypeEnums,
  SportsMarketTypeEnums,
} from '../../../enums/SystemManagement/sportEnums'
import { getSportsBettingRestriction, updateSportsBettingRestriction } from '../../../utils/api/sport/sportSettingApi'
import { decodeAccessToken } from '../../../utils/token'
import SportManagementTabsV2 from '../SportManagementTabsV2'
import { scrollbarStyles } from '../../../utils/palette'

const BetSettingManagement = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)

  const [existSportsBettingRestriction, setExistSportsBettingRestriction] = useState([])
  const [newSportsBettingRestriction, setNewSportsBettingRestriction] = useState([])

  const fetchSearchSportsBettingRestriction = async () => {
    getSportsBettingRestriction({}).then(res => {
      setExistSportsBettingRestriction(res.data.bettingPerSettingList)
    })
  }

  useEffect(() => {
    fetchSearchSportsBettingRestriction()
  }, [])

  useEffect(() => {
    // existSportsBettingRestriction이 업데이트될 때마다 새로운 데이터를 생성합니다.
    if (existSportsBettingRestriction.length > 0) {
      const newRestriction = {}
      existSportsBettingRestriction.forEach(item => {
        const { sportId, sportName, ...rest } = item
        if (!newRestriction[sportId]) {
          newRestriction[sportId] = []
        }
        newRestriction[sportId].push({ sportName, ...rest })
      })
      Object.values(newRestriction).forEach(item => {
        item.sort((a, b) => a.sortNum - b.sortNum)
      })
      setNewSportsBettingRestriction(newRestriction)
    }
  }, [existSportsBettingRestriction])

  const onSubmitSportTypeRestriction = () => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (window.confirm(`스포츠 배당 설정을 정말 변경하시겠습니까?`)) {
      const body = {
        bettingPerSettingList: Object.values(newSportsBettingRestriction).flatMap(sportData => sportData),
      }
      setWriteLoading(true)
      updateSportsBettingRestriction(body)
        .then(res => {
          setWriteLoading(false)
          fetchSearchSportsBettingRestriction()
          alert('설정이 변경되었습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          fetchSearchSportsBettingRestriction()
          alert('설정 변경이 실패하였습니다. 다시 시도해주세요.')
        })
    }
  }

  const handleInputChange = (sportId, marketType, fieldName, value) => {
    // if (!/^\d*\.?\d*$/.test(value)) {
    //   return
    // }
    const updatedData = {
      ...newSportsBettingRestriction,
      [sportId]: newSportsBettingRestriction[sportId].map(sport => ({
        ...sport,
        [fieldName]: sport.sportsMarketType === marketType ? value : sport[fieldName],
      })),
    }
    setNewSportsBettingRestriction(updatedData)
  }

  const onChangeBettingStatusBoolean = (sportId, marketType, fieldName, isChecked) => {
    const updatedData = {
      ...newSportsBettingRestriction,
      [sportId]: newSportsBettingRestriction[sportId].map(sport => ({
        ...sport,
        [fieldName]: sport.sportsMarketType === marketType ? isChecked : sport[fieldName],
      })),
    }
    setNewSportsBettingRestriction(updatedData)
  }

  const sportsBetLineShowTypeOption = SportBetLineShowTypeSelectOptions()
  const sportsSumBettingTypeOption = SportSumBettingTypeSelectOptions()

  const handleSelectChange = (sportId, marketType, fieldName, value) => {
    const updatedData = {
      ...newSportsBettingRestriction,
      [sportId]: newSportsBettingRestriction[sportId].map(sport => ({
        ...sport,
        [fieldName]: sport.sportsMarketType === marketType ? value : sport[fieldName],
      })),
    }
    setNewSportsBettingRestriction(updatedData)
  }

  const onSubmit2 = e => {}

  const [activeKey, setActiveKey] = useState('6')
  return (
    <Card>
      <CardBody>
        <SportManagementTabsV2 activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-30px', marginBottom: '0px' }}>
            <p>
              * <span style={{ color: 'black', fontWeight: '600' }}>마켓별 배당 조정</span>은{' '}
              <span style={{ color: 'red', fontWeight: '600' }}>마켓&조합관리</span>에서 설정하실 수 있습니다.
            </p>
            <p>
              * 언더오버,핸디캡제한 제한을 0으로 설정하면{' '}
              <span style={{ color: 'red', fontWeight: '600' }}>(홈 - 원정)배당차가 가장 작은 1개 배당</span> 을
              현시합니다.(0 에 가까운 1개 배당을 현시)
              <br />* 0 보다 크게 설정하면{' '}
              <span style={{ color: 'red', fontWeight: '600' }}>(홈 - 원정)배당차가 설정값보다 작은 배당</span>들을
              현시합니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <BettingSettingWrap style={{ marginTop: '-15px', position: 'relative' }}>
          <Form
            onSubmit={onSubmit2}
            initialValues={{
              existSportsBettingRestriction,
              underOverSumBettingStatus: existSportsBettingRestriction.underOverSumBettingStatus,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer2 onSubmit={handleSubmit}>
                <div
                  style={{
                    background: 'red',
                    color: '#fff',
                    padding: '5px',
                    display: 'flex',
                    position: 'absolute',
                    right: '10px',
                    top: '2%',
                    cursor: 'pointer',
                  }}
                  onClick={onSubmitSportTypeRestriction}
                >
                  일괄 수정
                </div>
                <TableWrap>
                  <table>
                    <thead>
                      <tr>
                        <th>종목</th>
                        <th>세부</th>
                        <th>
                          언/오 제한
                          <br />
                        </th>
                        <th>
                          핸디캡 제한
                          <br />
                        </th>
                        <th>기준점 노출 타입</th>
                        <th>조합 사용 여부</th>
                        <th>
                          <span style={{ color: 'red' }}>언더오버</span> <br /> 합배당
                        </th>
                        <th>
                          <span style={{ color: 'red' }}>언더오버</span> <br /> 합배당보다 작을때
                        </th>
                        <th>
                          <span style={{ color: 'red' }}>언더오버</span> <br /> 합배당보다 클때
                        </th>
                        <th>
                          <span style={{ color: 'blue' }}>핸디캡</span> <br /> 합배당
                        </th>
                        <th>
                          <span style={{ color: 'blue' }}>핸디캡</span> <br /> 합배당보다 작을때
                        </th>
                        <th>
                          <span style={{ color: 'blue' }}>핸디캡</span> <br /> 합배당보다 클때
                        </th>
                        <th>
                          <span style={{ color: 'blue' }}>핸디캡</span> 0.5,-0.5 <br />
                          사용여부
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(newSportsBettingRestriction).map(([sportId, sportData], index) => (
                        <React.Fragment key={sportId}>
                          {sportData.map((sport, i) => (
                            /* eslint-disable-next-line react/no-array-index-key */
                            <tr key={`${sportId}-${i}`}>
                              {i === 0 && <td rowSpan={4}>{sport.sportName}</td>}
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                {SportsMarketTypeEnums[sport.sportsMarketType]}
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox>
                                  <input
                                    type="text"
                                    value={sport.underOverRestrictionBettingPer}
                                    onChange={e =>
                                      handleInputChange(
                                        sportId,
                                        sport.sportsMarketType,
                                        'underOverRestrictionBettingPer',
                                        e.target.value,
                                      )
                                    }
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={`${sportId}-${i}`}
                                  />
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox>
                                  <input
                                    type="text"
                                    value={sport.handicapRestrictionBettingPer}
                                    onChange={e =>
                                      handleInputChange(
                                        sportId,
                                        sport.sportsMarketType,
                                        'handicapRestrictionBettingPer',
                                        e.target.value,
                                      )
                                    }
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={`${sportId}-${i}`}
                                  />
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox>
                                  <div style={{ width: '100%' }}>
                                    <select
                                      style={{ height: '25px' }}
                                      value={sport.sportsBetLineShowType}
                                      onChange={e =>
                                        handleSelectChange(
                                          sportId,
                                          sport.sportsMarketType,
                                          'sportsBetLineShowType',
                                          e.target.value,
                                        )
                                      }
                                      className="react-select"
                                    >
                                      {sportsBetLineShowTypeOption.map(option => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox
                                  style={{
                                    display: 'flex',
                                    width: '180px',
                                    gap: '10px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <label
                                    htmlFor={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedMarketCombination`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '5px',
                                    }}
                                  >
                                    <input
                                      id={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedMarketCombination`}
                                      style={{ width: '15px' }}
                                      type="checkbox"
                                      checked={sport.usedMarketCombination}
                                      onChange={e =>
                                        onChangeBettingStatusBoolean(
                                          sportId,
                                          sport.sportsMarketType,
                                          'usedMarketCombination',
                                          e.target.checked,
                                        )
                                      }
                                    />
                                    사용여부
                                  </label>
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox
                                  style={{
                                    display: 'flex',
                                    width: '180px',
                                    gap: '10px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <label
                                    htmlFor={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedUnderOverSumBetting`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '5px',
                                    }}
                                  >
                                    <input
                                      id={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedUnderOverSumBetting`}
                                      style={{ width: '15px' }}
                                      type="checkbox"
                                      checked={sport.usedUnderOverSumBetting}
                                      onChange={e =>
                                        onChangeBettingStatusBoolean(
                                          sportId,
                                          sport.sportsMarketType,
                                          'usedUnderOverSumBetting',
                                          e.target.checked,
                                        )
                                      }
                                    />
                                    사용여부
                                  </label>
                                  <input
                                    type="text"
                                    value={sport.underOverSumBettingPer}
                                    onChange={e =>
                                      handleInputChange(
                                        sportId,
                                        sport.sportsMarketType,
                                        'underOverSumBettingPer',
                                        e.target.value,
                                      )
                                    }
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={`${sportId}-${i}`}
                                  />
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox>
                                  <div style={{ width: '100%' }}>
                                    <select
                                      style={{ height: '25px' }}
                                      value={sport.sumBettingSmallUnderOverType}
                                      onChange={e =>
                                        handleSelectChange(
                                          sportId,
                                          sport.sportsMarketType,
                                          'sumBettingSmallUnderOverType',
                                          e.target.value,
                                        )
                                      }
                                      className="react-select"
                                    >
                                      {sportsSumBettingTypeOption.map(option => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox>
                                  <div style={{ width: '100%' }}>
                                    <select
                                      style={{ height: '25px' }}
                                      value={sport.sumBettingBigUnderOverType}
                                      onChange={e =>
                                        handleSelectChange(
                                          sportId,
                                          sport.sportsMarketType,
                                          'sumBettingBigUnderOverType',
                                          e.target.value,
                                        )
                                      }
                                      className="react-select"
                                    >
                                      {sportsSumBettingTypeOption.map(option => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox
                                  style={{
                                    display: 'flex',
                                    width: '180px',
                                    gap: '10px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <label
                                    htmlFor={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedHandicapSumBetting`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '5px',
                                    }}
                                  >
                                    <input
                                      id={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedHandicapSumBetting`}
                                      style={{ width: '15px' }}
                                      type="checkbox"
                                      checked={sport.usedHandicapSumBetting}
                                      onChange={e =>
                                        onChangeBettingStatusBoolean(
                                          sportId,
                                          sport.sportsMarketType,
                                          'usedHandicapSumBetting',
                                          e.target.checked,
                                        )
                                      }
                                    />
                                    사용여부
                                  </label>
                                  <input
                                    type="text"
                                    value={sport.handicapSumBettingPer}
                                    onChange={e =>
                                      handleInputChange(
                                        sportId,
                                        sport.sportsMarketType,
                                        'handicapSumBettingPer',
                                        e.target.value,
                                      )
                                    }
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={`${sportId}-${i}`}
                                  />
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox>
                                  <div style={{ width: '100%' }}>
                                    <select
                                      style={{ height: '25px' }}
                                      value={sport.sumBettingSmallHandiCapType}
                                      onChange={e =>
                                        handleSelectChange(
                                          sportId,
                                          sport.sportsMarketType,
                                          'sumBettingSmallHandiCapType',
                                          e.target.value,
                                        )
                                      }
                                      className="react-select"
                                    >
                                      {sportsSumBettingTypeOption.map(option => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox>
                                  <div style={{ width: '100%' }}>
                                    <select
                                      style={{ height: '25px' }}
                                      value={sport.sumBettingBigHandiCapType}
                                      onChange={e =>
                                        handleSelectChange(
                                          sportId,
                                          sport.sportsMarketType,
                                          'sumBettingBigHandiCapType',
                                          e.target.value,
                                        )
                                      }
                                      className="react-select"
                                    >
                                      {sportsSumBettingTypeOption.map(option => (
                                        <option key={option.value} value={option.value}>
                                          {option.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </InputBox>
                              </CustomTd>
                              <CustomTd sportsMarketType={sport.sportsMarketType}>
                                <InputBox
                                  style={{
                                    display: 'flex',
                                    width: '180px',
                                    gap: '10px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                  }}
                                >
                                  <label
                                    htmlFor={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedHandicapZeroZumFive`}
                                    style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: '5px',
                                    }}
                                  >
                                    <input
                                      id={`checkbox-${sport.sportId}-${sport.sportsMarketType}-usedHandicapZeroZumFive`}
                                      style={{ width: '15px' }}
                                      type="checkbox"
                                      checked={sport.usedHandicapZeroZumFive}
                                      onChange={e =>
                                        onChangeBettingStatusBoolean(
                                          sportId,
                                          sport.sportsMarketType,
                                          'usedHandicapZeroZumFive',
                                          e.target.checked,
                                        )
                                      }
                                    />
                                    사용여부
                                  </label>
                                </InputBox>
                              </CustomTd>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </TableWrap>
              </FormContainer2>
            )}
          </Form>
        </BettingSettingWrap>
      </CardBody>
    </Card>
  )
}

export default BetSettingManagement

const CustomTd = styled.td`
  ${({ sportsMarketType }) => {
    let backgroundColor = ''
    if (sportsMarketType === 'DOMESTIC') {
      backgroundColor = '#fcfc9a'
    } else if (sportsMarketType === 'OVERSEAS') {
      backgroundColor = '#fa91ff'
    } else if (sportsMarketType === 'SPECIAL') {
      backgroundColor = '#a6fc90'
    } else if (sportsMarketType === 'LIVE') {
      backgroundColor = '#fac78c'
    }
    return {
      backgroundColor: backgroundColor || 'black', // 기본값으로 검은색을 설정합니다.
    }
  }}
  color: #000;
`

const FormContainer2 = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 30px;
  height: 650px;
  ${scrollbarStyles};
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
    border-left: solid 1px #000;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`

const InputBox = styled.div`
  flex: 1 1 0%;

  input {
    font-weight: 700;
    color: #000;
    width: 70%;
    height: 30px;
    border: 1px solid #738087;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: left;
    padding-left: 10px;
    border-radius: 8px;
  }
`

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 70px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
`

const WithdrawalRuleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const EditoryBox = styled.div`
  .editor {
    width: 100% !important;
    min-height: 450px !important;
  }
`

export const CustomInput = styled.input`
  width: 60px !important;
  font-size: 13px;
  font-weight: 600;
  color: black;
  // margin-left: 30px;
  border: solid 1px black !important;
`

export const CustomTitleFormInputWrap = styled(FormInputWrap)`
  width: 100%;
`

export const CustomTitleWrap = styled.div`
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 25px;
  font-weight: 900;
  border-bottom: solid 2px #eee;
  margin-bottom: 5px;
`

const CustomCFormButtonToolbar = styled(FormButtonToolbar)`
  width: 80%;
`

const CheckButtonBoxWrap = styled(CustomCheckboxWrap)`
  padding-top: 5px;
`

const CheckButtonBox = styled(CustomCheckboxCustom)`
  // border: solid 2px black;
  border: none;
`
const CheckButton = styled.input`
  width: 15px !important;
  height: 15px !important;
`

const CustomFormTitleSecond = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: red;
  margin-left: 15px;
  padding-top: 2px;
  margin-right: 5px;
`

const CustomFormTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
  color: blue;
  margin-left: 15px;
  padding-top: 2px;
  margin-right: 5px;
`

const CustomWrap = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  border-bottom: dotted 1px black;
`

const BettingSettingWrap = styled.div`
  width: 100%;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 20px;
`

const BettingSecondWrap = styled.div`
  width: 100%;
`

const CustomFormGroupField = styled.div`
  // width: 110px !important;
  height: 30px;
  justify-content: center;
  display: flex;
`

const CustomFormGroupLabel = styled(FormGroupLabel)`
  width: 220px !important;
  color: black;
  height: 32px;
  border: solid 1px black;
  text-align: left;
  justify-content: left;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  // margin-right: -15px !important;
  background: #eee;
`

const CustomFormGroup = styled(FormGroup)`
  width: 100% !important;
  border: solid 1px red;
  // border-bottom: solid 1px black;
`

const CustomFormFieldWrap = styled.div`
  width: 40%;
  // margin-right: 5px;
  padding: 0 5px;
  align-items: center;
  display: flex;
  margin-top: 10px;
  gap: 10px;
`

const CustomFormContentWrap = styled.div`
  width: 100%;
  min-height: 48px;
  padding-left: 5px;
  justify-content: center;
  align-items: left;
  display: flex;
  border: solid 1px black;
  flex-direction: column;
  margin-top: 10px;
`

const CustomP = styled.p`
  color: black;
  font-size: 15px;
`

const CustomFormContainer = styled(FormContainer)`
  width: 100%;
  flex-direction: row;
`
