import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import { borderLeft } from '@/utils/directions'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBorder,
  colorHover,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '@/utils/palette'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Switch from 'react-switch'
import styled from 'styled-components'
import {
  FixtureBetResult,
  VirtualGameFixtureStatus,
  VirtualGameLeagueType,
  VirtualGameSportType,
} from '../../../enums/VirtualGame/virtualGameEnums'
import Loading from '../../../shared/components/Loading'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import {
  searchVirtualGameFixtureList,
  updateVirtualGameFixtureStatus,
} from '../../../utils/api/virtualGame/virtualGameApi'
import { convertToKst, convertToKstDateTime, convertToKstDepositDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import VirtualGameManagementTabs from './VirtualGameManagementTabs'

const VirtualGameFixtureList = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [virtualGameSportType, setVvirtualGameSportType] = useState(null)
  const [isStartGame, setStartGame] = useState(false) // Initial state is false
  const [searchStartDate, setSearchStartDate] = useState(null) // 현재 시간에서 6시간 전
  const [searchEndDate, setSearchEndDate] = useState(null) // 현재 시간에서 24시간 후
  const [searchKeyword, setSearchKeyword] = useState('')

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }

  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [params, setParams] = useState({
    page,
    size,
    isStartGame,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const onClickStartGameFixtureHandler = () => {
    setStartGame(prev => !prev) // Toggle the state
    setParams(prev => ({
      ...prev,
      page: 0,
      isStartGame: !prev.isStartGame,
    }))
  }

  // 미니게임 종목 선택
  const onClickVirtualLeagueHandler = type => {
    setVvirtualGameSportType(type)

    setParams(prev => ({
      ...prev,
      page: 0,
      virtualGameSportType: type || null,
    }))
  }

  const [apiLoading, setApiLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchVirtualGameFixtureList = async () => {
    setApiLoading(true)
    await searchVirtualGameFixtureList(params)
      .then(res => {
        setTotalElement(res.data.totalElements)
        setContent(res.data.virtualGameFixtureList)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchVirtualGameFixtureList()
  }, [params])

  const onClickSearchHandler = () => {
    setParams(prev => ({
      ...prev,
      teamName: searchKeyword || null,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 경기 노출 여부
  const onClickFixtureVisibleHandler = (virtualGameFixtureKey, showStatus) => {
    if (apiLoading) return
    const body = {
      virtualGameFixtureKey,
      isShow: showStatus,
    }
    setApiLoading(true)
    updateVirtualGameFixtureStatus(body)
      .then(res => {
        setApiLoading(false)
        fetchSearchVirtualGameFixtureList()
      })
      .catch(error => {
        alert('변경에 실패하였습니다.')
        setApiLoading(false)
        fetchSearchVirtualGameFixtureList()
      })
  }

  const getFixtureResult = fixtureItem => {
    switch (fixtureItem.virtualGameSportType) {
      case 'VIRTUAL_SOCCER':
        return `${fixtureItem.homeScore} : ${fixtureItem.awayScore}`
      case 'VIRTUAL_BASKETBALL':
        return `승패: ${FixtureBetResult[fixtureItem.winDrawLoseResult]}, 핸디캡: ${
          FixtureBetResult[fixtureItem.handicapResult]
        }, 오버언더: ${FixtureBetResult[fixtureItem.overUnderResult]}`
      case 'VIRTUAL_BASEBALL':
        return `${fixtureItem.homeScore} : ${fixtureItem.awayScore}`
      case 'VIRTUAL_GREYHOUNDS':
        return `1등 : ${fixtureItem.raceFirstNumber}번  ${fixtureItem.raceFirstName}`
      case 'VIRTUAL_HORSE':
        return `1등 : ${fixtureItem.raceFirstNumber}번  ${fixtureItem.raceFirstName}`
      default:
        return '-'
    }
  }

  return (
    <Card>
      <CardBody>
        <VirtualGameManagementTabs activeKey="5" />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 경기결과는 현재시점 기준으로 <span style={{ color: 'red' }}>3일</span> 전까지만 제공합니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <MiniGameTypeSelectWrapper>
          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            active={!virtualGameSportType}
            onClick={() => {
              onClickVirtualLeagueHandler(null)
            }}
          >
            전체
          </Button>
          {Object.entries(VirtualGameSportType).map(([key, value]) => {
            return (
              <Button
                type="button"
                size="sm"
                variant="outline-secondary"
                active={key === virtualGameSportType}
                onClick={() => {
                  onClickVirtualLeagueHandler(key)
                }}
              >
                {value}
              </Button>
            )
          })}
        </MiniGameTypeSelectWrapper>
        <SearchWrap>
          <SearchWrapItem>
            <span>팀명:</span>
            <input
              type="text"
              name="searchKeyword"
              value={searchKeyword}
              placeholder="홈팀 또는 원정명"
              onChange={e => {
                setSearchKeyword(e.target.value)
              }}
            />
            <span>시작일: </span>
            <SearchDatePickerWrap style={{ paddingTop: '2px' }}>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
            <span>종료일: </span>
            <SearchDatePickerWrap style={{ paddingTop: '2px' }}>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
            <SearchButton
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onClickSearchHandler()
              }}
            >
              검색하기
            </SearchButton>
            <SearchButton2
              size="sm"
              variant="outline-secondary"
              onClick={onClickStartGameFixtureHandler}
              isActive={!isStartGame} // Pass the state as a prop
            >
              예정 경기만
            </SearchButton2>
          </SearchWrapItem>
          {/* <SearchWrapItem>
            
          </SearchWrapItem> */}
        </SearchWrap>
        {apiLoading && <Loading />}
        <TableWrap>
          <table>
            <thead>
              <tr>
                <th style={{ borderLeft: 'solid 1px black' }}>번호</th>
                <th>경기시간</th>
                <th>종목</th>
                <th>리그</th>
                <th>홈팀 VS 원정팀</th>
                <th>상태</th>
                <th>결과</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {content.map(item => (
                <tr>
                  <CustomTd2 style={{ borderLeft: 'solid 1px black' }}>{item.num}</CustomTd2>
                  <CustomTd2>{convertToKstDepositDateTime(item.startDate)}</CustomTd2>
                  <CustomTd type={item.virtualGameSportType}>
                    {VirtualGameSportType[item.virtualGameSportType]}
                  </CustomTd>
                  <td>{VirtualGameLeagueType[item.virtualGameLeagueType]}</td>
                  <CustomTd3>
                    {item.virtualGameSportType === 'VIRTUAL_GREYHOUNDS' ||
                    item.virtualGameSportType === 'VIRTUAL_HORSE' ? (
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {item.raceOddList.map(raceItem => {
                          return (
                            <div style={{ marginRight: '5px', padding: '2px' }}>
                              <img
                                src={`${process.env.PUBLIC_URL}/img/virtualgame/${raceItem.imageUrl}`}
                                alt=""
                                style={{ marginRight: '5px', width: '20px', height: '20px' }}
                              />
                              <span>
                                {raceItem.name} {raceItem.rider && `, ${raceItem.rider}`}
                              </span>
                            </div>
                          )
                        })}
                      </div>
                    ) : (
                      <span>
                        {item.homeName} VS {item.awayName}
                      </span>
                    )}
                  </CustomTd3>
                  <CustomTd3>{VirtualGameFixtureStatus[item.fixtureStatus]}</CustomTd3>
                  <CustomTd3 style={{ color: '#fff', background: 'green', opacity: '0.6' }}>
                    {item.fixtureStatus === 'FINISHED' ? getFixtureResult(item) : '-'}
                  </CustomTd3>
                  <CustomTd2 style={{ padding: '0px' }}>
                    <CustomSwitch
                      onChange={e => {
                        onClickFixtureVisibleHandler(item.virtualGameFixtureKey, e)
                      }}
                      checked={item.isShow}
                    />
                  </CustomTd2>
                </tr>
              ))}
            </tbody>
          </table>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
          {content.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
        </TableWrap>
      </CardBody>
    </Card>
  )
}

export default VirtualGameFixtureList

const getColor = type => {
  switch (true) {
    case type === 'VIRTUAL_SOCCER':
      return '#f6a033'
    case type === 'VIRTUAL_BASKETBALL':
      return '#f44c32'
    case type === 'VIRTUAL_BASEBALL':
      return '#731cf7'
    case type === 'VIRTUAL_HORSE':
      return '#36bd29'
    case type === 'VIRTUAL_GREYHOUNDS':
      return '#24c1f0'
    default:
      return 'black'
  }
}
const MiniGameTypeSelectWrapper = styled.div`
  padding: 3px 0;
  margin-bottom: 5px;

  display: flex;
  gap: 5px;
`

const SearchButton2 = styled(Button)`
  background-color: ${props => (props.isActive ? 'lightgrey' : 'transparent')}; // 진한 회색 when active
  color: ${props => props.isActive && '#000'};
  font-weight: ${props => props.isActive && '600'};
`

const CustomTd = styled.td`
  color: #fff;
  background: ${props => getColor(props.type)};
`

const CustomTd2 = styled.td`
  min-width: 80px !important;
`

const CustomTd3 = styled.td`
  max-width: 500px !important;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`

const CustomButton = styled.button`
  width: 70px;
  height: 25px;
  background-color: red;
  color: white;
  border: none;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 630px;
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
    z-index: 51;
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    // display: table-row-group;
    vertical-align: middle;
    // border-color: inherit;
    // border: none;
    tr {
      width: 100%;
      &:hover {
        border: 2px solid red !important;
      }
    }
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
    white-space: normal; /* Allows text to wrap */
    word-wrap: break-word; /* Ensures words break to prevent overflow */
    min-width: 150px;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border: 1px solid #888;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`

export const SearchDatePickerWrap = styled.div`
  border-radius: 4px;
  color: #000;
  outline: none;
  font-size: 12px;
  height: 35px;
  transition: border 0.3s;
  background: transparent;

  input {
    width: 150px;
    height: 30px;
    border: 1px solid black;
    color: red;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
    // height: 38px;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: ${colorAccent};
    border-color: ${colorAccent};
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    right: 2px;

    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    ${borderLeft}: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent} !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`

const SearchWrap = styled.div`
  width: 100%;
  border: 1px solid #222733;
  border-radius: 3px;
  padding: 8px 8px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
`

const SearchWrapItem = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  align-items: center;

  input {
    border: 1px solid #222733;
    height: 30px;
    line-height: 28px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 7px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }

  select {
    min-width: 70px;
    display: inline-block;
    height: 30px;
    line-height: 28px;
    padding: 0 7px;
    border: 1px solid #222733;
    vertical-align: middle;
    border-radius: 3px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }
`
const SearchButton = styled(Button)`
  margin-left: 10px;
  margin-right: 20px;
  background: #dedede;
  color: #000;
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */
  z-index: 50;
  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
