export const SettlementSelectEnums = {
  ALL_USER: '직영 전부',
  MEMBER: '직영 회원만',
  PARTNER: '직영 파트너만',
}

export const DepositBonusStatusEnums = {
  true: '허용',
  false: '비허용',
}

export const DepositBonusStatusEnumsRadioOptions = () => {
  return Object.entries(DepositBonusStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'depositBonusStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'depositBonusStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const UserAuthorityEnums = {
  MEMBER: '회원',
  PARTNER: '파트너',
}

export const UserAuthorityEnumsSelectOptions = () => {
  return Object.entries(UserAuthorityEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const ExchagneDateEnums = {
  EVERY: '항상',
  FIRST: '매월1일',
  HALF: '매월15일',
  FIRSTANDHALF: '매월1일,15일',
}

export const ExchagneDateEnumsSelectOptions = () => {
  return Object.entries(ExchagneDateEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const RecommendCodeStatusEnums = {
  true: '허용',
  false: '비허용',
}

export const RecommendCodeStatusEnumsRadioOptions = () => {
  return Object.entries(RecommendCodeStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'recommendCodeStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'recommendCodeStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const UserPointExchangeAuto = {
  true: '승인 필요없음',
  false: '승인 필요함',
}

export const UserPointExchangeAutoEnumsRadioOptions = () => {
  return Object.entries(UserPointExchangeAuto).map(([key, value], i) =>
    i === 0
      ? {
          name: 'userPointExchangeAuto',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'userPointExchangeAuto',
          label: value,
          radioValue: key,
        },
  )
}

/**
 * 하부 머니증감 수정 가능 여부
 */
export const IsMoneyExchange = {
  true: '가능',
  false: '불가능',
}

export const IsMoneyExchangeRadioOptions = () => {
  return Object.entries(IsMoneyExchange).map(([key, value], i) =>
    i === 0
      ? {
          name: 'isMoneyIncExchange',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'isMoneyExchange',
          label: value,
          radioValue: key,
        },
  )
}

export const IsMoneyDecExchangeRadioOptions = () => {
  return Object.entries(IsMoneyExchange).map(([key, value], i) =>
    i === 0
      ? {
          name: 'isMoneyDecExchange',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'isMoneyDecExchange',
          label: value,
          radioValue: key,
        },
  )
}

/**
 * 하부 정산 (롤링/루징) 비율 수정 가능 여부
 */
export const IsSettlementExchange = {
  true: '가능',
  false: '불가능',
}

export const IsSettlementExchangeRadioOptions = () => {
  return Object.entries(IsSettlementExchange).map(([key, value], i) =>
    i === 0
      ? {
          name: 'isSettlementExchange',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'isSettlementExchange',
          label: value,
          radioValue: key,
        },
  )
}

export const MemberStatusEnums = {
  NORMAL: '정상',
  APPLY: '신청',
  WAITING: '대기',
  BLOCK: '블록',
  CANCEL: '취소',
}

export const PartnerStatusEnums = {
  NORMAL: '정상',
  APPLY: '신청',
  WAITING: '대기',
  CANCEL: '취소',
}

export const MemberStatusEnumsSelectOptions = () => {
  return Object.entries(MemberStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberTelecomEnums = {
  LG: 'LG',
  SKT: 'SKT',
  KT: 'KT',
  LG_PLUS: 'LG 알뜰폰',
  SKT_PLUS: 'SKT 알뜰폰',
  KT_PLUS: 'KT 알뜰폰',
  // LEAVE: '탈퇴',
}

export const MemberTelecomEnumsSelectOptions = () => {
  return Object.entries(MemberTelecomEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberModifyStatusEnums = {
  NORMAL: '정상',
  APPLY: '신청',
  WAITING: '대기',
  CANCEL: '취소',
  BLOCK: '블록',
  // LEAVE: '탈퇴',
}

export const MemberV2StatusEnums = {
  NORMAL: '정상',
  APPLY: '신청',
  WAITING: '대기',
  CANCEL: '취소',
  // LEAVE: '탈퇴',
}

export const MemberV2StatusEnumsSelectOptions = () => {
  return Object.entries(MemberV2StatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberModifyStatusEnumsSelectOptions = () => {
  return Object.entries(MemberModifyStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberFriendCompLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  VIP1: 'VIP1',
  VIP2: 'VIP2',
  VIP3: 'VIP3',
}

export const MemberDepositLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  VIP1: 'VIP1',
  VIP2: 'VIP2',
  VIP3: 'VIP3',
}

export const MemberCasinoLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  VIP1: 'VIP1',
  VIP2: 'VIP2',
  VIP3: 'VIP3',
}
export const MemberMiniGameLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  VIP1: 'VIP1',
  VIP2: 'VIP2',
  VIP3: 'VIP3',
}
export const MemberSportsLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  VIP1: 'VIP1',
  VIP2: 'VIP2',
  VIP3: 'VIP3',
}

export const MemberVirtualGameLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  VIP1: 'VIP1',
  VIP2: 'VIP2',
  VIP3: 'VIP3',
}

export const MemberHoldemLevelEnum = {
  ONE: '1LV',
  TWO: '2LV',
  THREE: '3LV',
  FOUR: '4LV',
  FIVE: '5LV',
  VIP1: 'VIP1',
  VIP2: 'VIP2',
  VIP3: 'VIP3',
}

export const MemberDepositLevelEnumSelectOptions = () => {
  return Object.entries(MemberDepositLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberCasinoLevelEnumSelectOptions = () => {
  return Object.entries(MemberCasinoLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberMiniGameLevelEnumSelectOptions = () => {
  return Object.entries(MemberMiniGameLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberSportsLevelEnumSelectOptions = () => {
  return Object.entries(MemberSportsLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberVirtualGameLevelEnumSelectOptions = () => {
  return Object.entries(MemberVirtualGameLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MemberFriendCompLevelEnumSelectOptions = () => {
  return Object.entries(MemberFriendCompLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
export const MemberHoldemLevelEnumSelectOptions = () => {
  return Object.entries(MemberHoldemLevelEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const DuplicatedSignInEnums = {
  true: '허용',
  false: '비허용',
}

export const DuplicatedSignInEnumsRadioOptions = () => {
  return Object.entries(DuplicatedSignInEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'duplicatedSignIn',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'duplicatedSignIn',
          label: value,
          radioValue: key,
        },
  )
}
