import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import { MacroTypeEnumsRadioOptions } from '../../../enums/SystemManagement/macroEnums'
import { updateMacroManage } from '../../../utils/api/systemManage/systemManageApi'
import { convertFromFront, convertFromServer } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import './Macro.css'

function MacroModifyForm({
  edgeName,
  tag,
  macroKey,
  fetchSearchMacroManage,
  onClickHandler,
  macroTitle,
  macroContent,
  type,
  sortNum,
}) {
  const authority = decodeAccessToken()?.adminAuthority
  const [content, setContent] = useState(EditorState.createEmpty())
  const contentChange = editorState => {
    setContent(editorState)
  }

  useEffect(() => {
    setContent(convertFromServer(macroContent))
  }, [macroContent])

  const onSubmit = e => {
    if (!e.tag || !e.macroTitle || !content.getCurrentContent().hasText()) {
      alert('모두 다 기입 후 수정버튼을 클릭해주세요.')
      return
    }

    const body = {
      macroKey,
      type: e.macroType,
      tag: e.tag,
      macroTitle: e.macroTitle,
      stringContent: content.getCurrentContent().getBlocksAsArray()[0].getText(),
      macroContent: convertFromFront(content),
      sortNum: e.sortNum,
    }
    updateMacroManage(body)
      .then(res => {
        alert('매크로 수정이 완료됐습니다.')
        onClickHandler()
        fetchSearchMacroManage()
      })
      .catch(error => {
        alert('매크로 수정이 실패했습니다, 잠시 후 다시 시도해주세요.')
      })
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>사이트명</FormGroupLabel>
            <FormGroupField>
              <Field name="edgeName" component="input" type="text" defaultValue={edgeName} disabled />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>정렬순서</FormGroupLabel>
            <FormGroupField>
              <Field name="sortNum" component="input" type="text" defaultValue={sortNum} />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>분류</FormGroupLabel>
            {MacroTypeEnumsRadioOptions().map(item => (
              <FormGroupField
                key={`index_${item.label}`}
                style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
              >
                <Field
                  name={`${item.name}`}
                  component={renderRadioButtonField}
                  label={item.label}
                  radioValue={item.radioValue}
                  initialValue={type}
                />
              </FormGroupField>
            ))}
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>태그</FormGroupLabel>
            <FormGroupField>
              <Field name="tag" component="input" type="text" defaultValue={tag} />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>제목</FormGroupLabel>
            <FormGroupField>
              <Field name="macroTitle" component="input" type="text" defaultValue={macroTitle} />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>내용</FormGroupLabel>
            <Editor
              editorState={content}
              onEditorStateChange={editorState => contentChange(editorState)}
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarClassName="toolbar"
            />
          </FormGroup>
          {
            {
              SUPER: (
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">
                    수정하기
                  </Button>
                </FormButtonToolbar>
              ),
            }[authority]
          }
        </FormContainer>
      )}
    </Form>
  )
}

export default MacroModifyForm

MacroModifyForm.propTypes = {
  edgeName: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  macroKey: PropTypes.string.isRequired,
  macroTitle: PropTypes.string.isRequired,
  macroContent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fetchSearchMacroManage: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
  sortNum: PropTypes.string.isRequired,
}
