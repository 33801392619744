import { Card, CardBody, SearchCardSubhead, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { PopupStatusEnumsSelectOptions } from '../../../enums/Operation/popupEnums'
import { searchPopupList } from '../../../utils/api/operation/operationApi'
import OperationTabs from '../OperationTabs'
import PopupListData from './PopupListData'
import PopupModifyModal from './PopupModifyModal'
import PopupWriteModal from './PopupWriteModal'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'

const PopupList = () => {
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  // 팝업 관리 모달
  const [isPopupModifyModalShow, setPopupModifyModalShow] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  /**
   팝업 조회
   */
  const [popupParams, setPopupParams] = useState({
    page,
    size,
    type: 'NORMAL',
    status: null,
    edgeName: null,
  })

  useEffect(() => {
    setPopupParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchPopupList = async () => {
    await searchPopupList(popupParams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchSearchPopupList()
  }, [popupParams])

  const [popupListData, setPopupListData] = useState(PopupListData(content, fetchSearchPopupList))
  useEffect(() => {
    setPopupListData(PopupListData(content, fetchSearchPopupList))
  }, [content])

  const [rows, setData] = useState(popupListData.tableRowsData)
  useEffect(() => {
    setData(popupListData.tableRowsData)
  }, [popupListData])

  const [isPopupWriteModalShow, setPopupWriteModalShow] = useState(false)

  const onClickHandler = () => {
    setPopupWriteModalShow(prevState => !prevState)
  }

  const [popupInfo, setPopupInfo] = useState({})

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setPopupInfo(rowValue.original)
    setPopupModifyModalShow(true)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = PopupStatusEnumsSelectOptions()

  const onSelectStatusOptionHandler = option => {
    if (option === null) {
      setSearchStatusOptionValue(null)
      setSearchStatus(null)
      return
    }
    setSearchStatusOptionValue(option)
    setSearchStatus(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setPopupParams(prev => ({
      ...prev,
      page: 0,
      status: !searchStatus ? null : searchStatus,
      edgeName: !searchEdgeName ? null : searchEdgeName,
    }))
  }
  // Tab Key
  const [activeKey, setActiveKey] = useState('2')
  return (
    <Card>
      <CardBody>
        <OperationTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'blue' }}>팝업</span>은 총 8개만 &quot;보이기&ldquo; 상태로 가능합니다.
            </p>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 팝업을 수정할 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>

        {/* 검색 기능 */}
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상태</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchStatus"
                placeholder="상태"
                onChange={option => {
                  onSelectStatusOptionHandler(option)
                }}
                value={searchStatusOptionValue}
                options={searchStatusOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onClickHandler()
              }}
            >
              글쓰기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>

        <ReactTableBase columns={popupListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
      <PopupWriteModal
        fetchSearchPopupList={fetchSearchPopupList}
        isPopupWriteModalShow={isPopupWriteModalShow}
        onClickHandler={onClickHandler}
      />

      {/* 팝업 수정 모달 */}
      <PopupModifyModal
        title={popupInfo?.title}
        edgeName={popupInfo?.edgeName}
        imgName={popupInfo?.imgName}
        popupKey={popupInfo?.popupKey}
        popupContent={popupInfo?.popupContent}
        status={popupInfo?.status}
        domainUrl={popupInfo?.domainUrl}
        createAt={popupInfo?.createAt}
        fetchSearchPopupList={fetchSearchPopupList}
        isPopupModifyModalShow={isPopupModifyModalShow}
        setPopupModifyModalShow={setPopupModifyModalShow}
      />
    </Card>
  )
}

export default PopupList
