import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import headerInfoSlice from './headerInfoSlice'
import userInfoSlice from './SettlementInfoSlice'
import partnerInfoSlice from './PartnerInfoSlice'
import InitInfoSlice from './InitInfoSlice'
import SportInfoSlice from './SportInfoSlice'
import SportFixtureSlice from './SportFixtureSlice'
import BookMakerInfoSlice from './BookMakerInfoSlice'
import SportMarketTypeInfoSlice from './SportMarketTypeInfoSlice'
import SportInPlayFixtureSlice from './SportInPlayFixtureSlice'
import InPlayBookMakerInfoSlice from './InPlayBookMakerInfoSlice'
import SportInPlayInfoSlice from './SportInPlayInfoSlice'
import SettlementInfoSliceV2 from './SettlementInfoSliceV2'
import CasinoInfoSlice from './CasinoInfoSlice'
import rootSaga from './saga/rootSaga'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: {
    initInfo: InitInfoSlice,
    headerInfo: headerInfoSlice,
    userInfo: userInfoSlice,
    partnerInfo: partnerInfoSlice,
    sportInfo: SportInfoSlice,
    sportInPlayInfo: SportInPlayInfoSlice,
    sportFixture: SportFixtureSlice,
    sportInPlayFixture: SportInPlayFixtureSlice,
    bookMakerInfo: BookMakerInfoSlice,
    inplayBookMakerInfo: InPlayBookMakerInfoSlice,
    sportMarketTypeInfo: SportMarketTypeInfoSlice,
    settlementInfo: SettlementInfoSliceV2,
    casinoInfo: CasinoInfoSlice,
  },
  devTools: true,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(sagaMiddleware),
})

// 루트 사가 실행
sagaMiddleware.run(rootSaga)

export default store
