import { commonReg8, commonReg11, coomonReg13 } from '../commonValidate'

export const depositlevelValidate = values => {
  const errors = {}

  if (!commonReg11.test(values.bonusNewFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusNewFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusSportsFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusSportsFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusSportsEveryPer?.toString().replaceAll('%', ''))) {
    errors.bonusSportsEveryPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusCasinoNewFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusCasinoNewFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusEveryPer?.toString().replaceAll('%', ''))) {
    errors.bonusEveryPer = '0부터 100사이 정수만 가능'
  }

  if (!commonReg11.test(values.bonusSlotCasinoNewFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusSlotCasinoNewFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusSlotFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusSlotFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusSlotEveryPer?.toString().replaceAll('%', ''))) {
    errors.bonusSlotEveryPer = '0부터 100사이 정수만 가능'
  }
  if (!coomonReg13.test(values.bonusSportsMaxAmount?.toString().replaceAll(',', ''))) {
    errors.bonusSportsMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusSportsLimitAmount?.toString().replaceAll(',', ''))) {
    errors.bonusSportsLimitAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusLiveCasinoMaxAmount?.toString().replaceAll(',', ''))) {
    errors.bonusLiveCasinoMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusLiveCasinoLimitAmount?.toString().replaceAll(',', ''))) {
    errors.bonusLiveCasinoLimitAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusSlotCasinoMaxAmount?.toString().replaceAll(',', ''))) {
    errors.bonusSlotCasinoMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusSlotCasinoLimitAmount?.toString().replaceAll(',', ''))) {
    errors.bonusSlotCasinoLimitAmount = '정수만 가능'
  }

  if (!commonReg11.test(values.bonusMiniGameNewFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusMiniGameNewFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusMiniGameFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusMiniGameFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusMiniGameEveryPer?.toString().replaceAll('%', ''))) {
    errors.bonusMiniGameEveryPer = '0부터 100사이 정수만 가능'
  }

  if (!coomonReg13.test(values.bonusMiniGameMaxAmount?.toString().replaceAll(',', ''))) {
    errors.bonusMiniGameMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusMiniGameLimitAmount?.toString().replaceAll(',', ''))) {
    errors.bonusMiniGameLimitAmount = '정수만 가능'
  }

  if (!commonReg11.test(values.bonusVirtualGameNewFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusVirtualGameNewFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusVirtualGameFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusVirtualGameFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusVirtualGameEveryPer?.toString().replaceAll('%', ''))) {
    errors.bonusVirtualGameEveryPer = '0부터 100사이 정수만 가능'
  }

  if (!coomonReg13.test(values.bonusVirtualGameMaxAmount?.toString().replaceAll(',', ''))) {
    errors.bonusVirtualGameMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusVirtualGameLimitAmount?.toString().replaceAll(',', ''))) {
    errors.bonusVirtualGameLimitAmount = '정수만 가능'
  }

  if (!coomonReg13.test(values.depositMaxAmount?.toString().replaceAll(',', ''))) {
    errors.depositMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.withdrawalMaxAmount?.toString().replaceAll(',', ''))) {
    errors.withdrawalMaxAmount = '정수만 가능'
  }

  if (!commonReg11.test(values.payBackPer?.toString().replaceAll('%', ''))) {
    errors.payBackPer = '0부터 100사이 정수만 가능'
  }

  if (!coomonReg13.test(values.payBackEventMaxAmount?.toString().replaceAll(',', ''))) {
    errors.payBackEventMaxAmount = '정수만 가능'
  }

  if (!commonReg11.test(values.bonusMiniGameNewFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusMiniGameNewFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusMiniGameFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusMiniGameFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusMiniGameEveryPer?.toString().replaceAll('%', ''))) {
    errors.bonusMiniGameEveryPer = '0부터 100사이 정수만 가능'
  }
  if (!coomonReg13.test(values.bonusMiniGameMaxAmount?.toString().replaceAll(',', ''))) {
    errors.bonusMiniGameMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusMiniGameLimitAmount?.toString().replaceAll(',', ''))) {
    errors.bonusMiniGameLimitAmount = '정수만 가능'
  }

  if (!commonReg11.test(values.bonusHoldemNewFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusHoldemNewFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusHoldemFirstPer?.toString().replaceAll('%', ''))) {
    errors.bonusHoldemFirstPer = '0부터 100사이 정수만 가능'
  }
  if (!commonReg11.test(values.bonusHoldemEveryPer?.toString().replaceAll('%', ''))) {
    errors.bonusHoldemEveryPer = '0부터 100사이 정수만 가능'
  }
  if (!coomonReg13.test(values.bonusHoldemMaxAmount?.toString().replaceAll(',', ''))) {
    errors.bonusHoldemMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.bonusHoldemLimitAmount?.toString().replaceAll(',', ''))) {
    errors.bonusHoldemLimitAmount = '정수만 가능'
  }

  return errors
}

export const depositlevelValidateV2 = values => {
  const errors = {}

  if (!coomonReg13.test(values.depositMaxAmount?.toString().replaceAll(',', ''))) {
    errors.depositMaxAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.withdrawalMaxAmount?.toString().replaceAll(',', ''))) {
    errors.withdrawalMaxAmount = '정수만 가능'
  }

  if (!commonReg11.test(values.payBackPer?.toString().replaceAll('%', ''))) {
    errors.payBackPer = '0부터 100사이 정수만 가능'
  }

  if (!coomonReg13.test(values.payBackEventMaxAmount?.toString().replaceAll(',', ''))) {
    errors.payBackEventMaxAmount = '정수만 가능'
  }

  return errors
}

export const liveLevelmManagementValidate = values => {
  const errors = {}

  if (!commonReg8.test(values.livePer?.toString().replaceAll('%', ''))) {
    errors.livePer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  return errors
}

export const friendComplevelValidate = values => {
  const errors = {}

  if (!coomonReg13.test(values.friendCompMaxAmount?.toString().replaceAll(',', ''))) {
    errors.friendCompMaxAmount = '정수만 가능'
  }
  if (!commonReg8.test(values.liveCasinoFriendBenefitBettingPer)) {
    errors.liveCasinoFriendBenefitBettingPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }
  if (!commonReg8.test(values.slotCasinoFriendBenefitBettingPer)) {
    errors.slotCasinoFriendBenefitBettingPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }
  if (!commonReg8.test(values.miniGameFriendBenefitBettingPer)) {
    errors.miniGameFriendBenefitBettingPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }
  if (!commonReg8.test(values.miniGameFriendBenefitLossPer)) {
    errors.miniGameFriendBenefitLossPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }
  if (!commonReg8.test(values.sportsFriendBenefitBettingPer)) {
    errors.sportsFriendBenefitBettingPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }
  if (!commonReg8.test(values.sportsFriendBenefitLossPer)) {
    errors.sportsFriendBenefitLossPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  if (!commonReg8.test(values.virtualGameFriendBenefitBettingPer)) {
    errors.virtualGameFriendBenefitBettingPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }
  if (!commonReg8.test(values.virtualGameFriendBenefitLossPer)) {
    errors.virtualGameFriendBenefitLossPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  return errors
}

export const slotLevelmManagementValidate = values => {
  const errors = {}
  if (!commonReg8.test(values.slotPer?.toString().replaceAll('%', ''))) {
    errors.slotPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  return errors
}

export const miniGamelevelValidate = values => {
  const errors = {}

  if (!commonReg8.test(values.miniGameLossPer)) {
    errors.miniGameLossPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  if (!commonReg8.test(values.miniGameCompPer)) {
    errors.miniGameCompPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  return errors
}

export const sportsGamelevelValidate = values => {
  const errors = {}

  // if (!commonReg8.test(values.sportsGameLossPer)) {
  //   errors.sportsGameLossPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  // }

  // if (!commonReg8.test(values.sportsGameCompPer)) {
  //   errors.sportsGameCompPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  // }

  return errors
}

export const holdemlevelValidate = values => {
  const errors = {}

  if (!commonReg8.test(values.holdemLossPer)) {
    errors.holdemLossPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  if (!commonReg8.test(values.holdemCompPer)) {
    errors.holdemCompPer = '100% 이하, 소숫점 한자리까지만 가능합니다.'
  }

  return errors
}

export const depositLevelBonusValidate = values => {
  const errors = {}

  if (!values.title) {
    errors.title = '필수 입력입니다.'
  }
  if (!values.content) {
    errors.content = '필수 입력입니다.'
  }
  if (!values.bonusTypeOptionValue) {
    errors.bonusTypeOptionValue = '필수 선택입니다.'
  }
  if (!values.orderNum) {
    errors.orderNum = '필수 입력입니다.'
  }

  return errors
}

export const depositLevelBonusValidateV2 = values => {
  const errors = {}

  // if (!values.title) {
  //   errors.title = '필수 입력입니다.'
  // }
  // if (!values.content) {
  //   errors.content = '필수 입력입니다.'
  // }
  // if (!values.bonusTypeOptionValue) {
  //   errors.bonusTypeOptionValue = '필수 선택입니다.'
  // }
  // if (!values.orderNum) {
  //   errors.orderNum = '필수 입력입니다.'
  // }

  return errors
}
