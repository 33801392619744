import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { MemberDepositStatusEnums } from '../../../enums/MemberHistory/MemberDepositEnums'
import { PartnerStatusEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import PointTypeSearchButton from '../../../shared/components/PointTypeSearchButton'
import { searchUserPointExchange, updatePartnerLosingStatus } from '../../../utils/api/partnerHistory/partnerHistoryApi'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import StatisticsTabs from '../StatisticsTabs'
import MemberPointExchangeListData from './MemberPointExchangeListData'

const MemberPointExchangeList = () => {
  const dispatch = useDispatch()
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  /**
   * 포인트 전환내역 조회
   */
  const [partnerStatusLoading, setPartnerStatusLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)
  const [pointType, setPointType] = useState(null)

  const [params, setParams] = useState({
    edgeName: null,
    authority: null,
    pointType: null,
    userId: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      pointType: !pointType ? null : pointType,
    }))
  }, [pointType])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const [loading, setLoading] = useState(false)

  const fetchSearchUserPointExchange = async () => {
    if (loading) return

    setLoading(true)

    await searchUserPointExchange(params)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchUserPointExchange()
  }, [params])

  // 가입 승인 버튼
  const [registrationApprove, setRegistrationApprove] = useState('')
  const [pointExchangeListData, setPointExchangeListData] = useState(
    MemberPointExchangeListData(content, setRegistrationApprove),
  )

  useEffect(() => {
    setPointExchangeListData(MemberPointExchangeListData(content, setRegistrationApprove))
  }, [content])

  const [rows, setData] = useState(pointExchangeListData.tableRowsData)

  useEffect(() => {
    setData(pointExchangeListData.tableRowsData)
  }, [pointExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디 , 수신, 구분 , 상태 , 처리일

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const [searchStatusToggle, setSearchStatusToggle] = useState(false)

  const onChangeSearchStatusHandler = status => {
    setSearchStatusOptionValue(status)
    setSearchStatusToggle(prev => !prev)
  }

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      status: searchStatusOptionValue,
    }))
  }, [searchStatusToggle])

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      userId: !searchMemberId ? null : searchMemberId,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
      status: !searchStatusOptionValue ? null : searchStatusOptionValue,
    }))
  }

  // 대기 버튼
  const onWaitingRegistrationHandler = () => {
    if (partnerStatusLoading) return
    if (registrationApprove.userPointExchangeAuto === '자동') {
      alert('자동건은 대기처리 할 수 없습니다.')
      return
    }
    switch (PartnerStatusEnums[registrationApprove.status]) {
      case '신청':
        if (
          window.confirm(
            `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산을 대기처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerLosingStatus({
            pointExchangeKey: registrationApprove.userPointExchangeKey,
            userId: registrationApprove.userId,
            status: 'WAITING',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              alert(`${registrationApprove.userId}(${registrationApprove.userName})의 루징정산이 대기 처리 되었습니다.`)
              setRegistrationApprove('')
              fetchSearchUserPointExchange()
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                default:
                  alert(
                    `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산 대기 처리가 실패했습니다.`,
                  )
              }
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('정산건을 먼저 선택해주세요')
        break
      default:
        alert('상태 값을 다시 확인해주세요.')
        fetchSearchUserPointExchange()
        break
    }
  }

  // 승인 버튼
  const onApproveRegistrationHandler = () => {
    if (registrationApprove.userPointExchangeAuto === '자동') {
      alert('자동건은 승인처리 할 수 없습니다.')
      return
    }
    if (partnerStatusLoading) return
    switch (PartnerStatusEnums[registrationApprove.status]) {
      case '대기':
        if (
          window.confirm(
            `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산을 승인처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerLosingStatus({
            pointExchangeKey: registrationApprove.userPointExchangeKey,
            userId: registrationApprove.userId,
            status: 'APPROVE',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              alert(`${registrationApprove.userId}(${registrationApprove.userName})의 루징정산이 승인 처리 되었습니다.`)
              setRegistrationApprove('')
              fetchSearchUserPointExchange()
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                default:
                  alert(
                    `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산 승인 처리가 실패했습니다.`,
                  )
              }
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
            })
        }
        break
      case '신청':
        if (
          window.confirm(
            `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산 승인처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerLosingStatus({
            pointExchangeKey: registrationApprove.userPointExchangeKey,
            userId: registrationApprove.userId,
            status: 'APPROVE',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              alert(`${registrationApprove.userId}(${registrationApprove.userName})의 루징정산이 승인 처리 되었습니다.`)
              setRegistrationApprove('')
              fetchSearchUserPointExchange()
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                default:
                  alert(
                    `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산 취소 처리가 실패했습니다.`,
                  )
              }
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('정산건을 먼저 선택해주세요')
        break
      default:
        alert('상태 값을 다시 확인해주세요.')
        fetchSearchUserPointExchange()
        break
    }
  }

  // 취소 버튼
  const onCancelRegistrationHandler = () => {
    if (registrationApprove.userPointExchangeAuto === '자동') {
      alert('자동건은 취소처리 할 수 없습니다.')
      return
    }
    if (partnerStatusLoading) return
    switch (PartnerStatusEnums[registrationApprove.status]) {
      case '신청':
        if (
          window.confirm(
            `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산 취소처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerLosingStatus({
            pointExchangeKey: registrationApprove.userPointExchangeKey,
            userId: registrationApprove.userId,
            status: 'CANCEL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
              fetchSearchUserPointExchange()
              alert(`${registrationApprove.userId}(${registrationApprove.userName})의 루징정산이 취소 처리 되었습니다.`)
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                default:
                  alert(
                    `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산 대기 처리가 실패했습니다.`,
                  )
              }
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
            })
        }
        break
      case '대기':
        if (
          window.confirm(
            `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산을 취소처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerLosingStatus({
            pointExchangeKey: registrationApprove.userPointExchangeKey,
            userId: registrationApprove.userId,
            status: 'CANCEL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
              fetchSearchUserPointExchange()
              alert(`${registrationApprove.userId}(${registrationApprove.userName})의 루징정산이 취소 처리 되었습니다.`)
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                default:
                  alert(
                    `${registrationApprove.userId}(${registrationApprove.userName})의 루징정산이 취소 처리가 실패했습니다.`,
                  )
              }
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('정산건을 먼저 선택해주세요')
        break
      default:
        alert('상태 값을 다시 확인해주세요.')
        fetchSearchUserPointExchange()
        break
    }
  }

  const [activeKey, setActiveKey] = useState('5')

  return (
    <Card>
      <CardBody>
        {loading && <Loading />}
        <StatisticsTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-40px', marginBottom: '10px' }}>
            <p>
              * 루징정산이 <span style={{ color: 'blue', fontWeight: '900' }}>수동인 파트너인 경우</span>
              수동 처리 하셔야 합니다.
            </p>
            <p>
              * <span style={{ color: 'blue', fontWeight: '900' }}>루징정산 자동/수동 또는 루징정산일</span>은 유저
              세부정보에서 수정이 가능합니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        {/* 검색 기능 */}
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <div style={{ display: 'flex', gap: '10px' }}>
          <PointTypeSearchButton
            pointType={pointType}
            onClickHandler={e => {
              setPointType(e.target.id)
            }}
          />
          <SearchStatusWrap>
            <Button
              size="sm"
              active={searchStatusOptionValue === null}
              variant="outline-secondary"
              onClick={e => {
                onChangeSearchStatusHandler(null)
              }}
            >
              전체
            </Button>
            {Object.keys(MemberDepositStatusEnums).map(status => {
              return (
                <Button
                  id={status}
                  size="sm"
                  active={searchStatusOptionValue === status}
                  variant="outline-secondary"
                  onClick={e => {
                    onChangeSearchStatusHandler(status)
                  }}
                >
                  {MemberDepositStatusEnums[status]}
                </Button>
              )
            })}
          </SearchStatusWrap>
        </div>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteStartDate}
                  onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteEndDate}
                  onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase
          key="common"
          columns={pointExchangeListData.tableHeaderData}
          data={rows}
          tableConfig={tableConfig}
        />
        {
          {
            SUPER: (
              <>
                <SearchContainer horizontal>
                  <SearchButtonWrap>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      style={{ backgroundColor: 'green', color: 'white' }}
                      onClick={() => {
                        onWaitingRegistrationHandler()
                      }}
                    >
                      정산 대기
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      style={{ backgroundColor: 'blue', color: 'white' }}
                      onClick={() => {
                        onApproveRegistrationHandler()
                      }}
                    >
                      정산 승인
                    </Button>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      style={{ backgroundColor: 'red', color: 'white' }}
                      onClick={() => {
                        onCancelRegistrationHandler()
                      }}
                    >
                      정산 취소
                    </Button>
                  </SearchButtonWrap>
                </SearchContainer>
              </>
            ),
          }[authority]
        }
      </CardBody>
    </Card>
  )
}

export default MemberPointExchangeList

const SearchStatusWrap = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  border-left: 3px solid grey;
  padding-left: 15px;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
