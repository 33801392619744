import { commonReg1, commonReg8 } from '../commonValidate'

export const bonsaRegisterValidate = values => {
  const errors = {}

  if (!values.partnerName) {
    errors.partnerName = '필수 입력 항목입니다.'
  }
  if (!values.partnerId) {
    errors.partnerId = '필수 입력 항목입니다.'
  }
  if (!values.partnerPassword) {
    errors.partnerPassword = '필수 입력 항목입니다.'
  }

  if (!values.accountHolder) {
    errors.accountHolder = '필수 입력 항목입니다.'
  }

  if (!values.partnerPassword) {
    errors.partnerPassword = '필수 입력 항목입니다.'
  }

  if (!values.partnerPassword) {
    errors.partnerPassword = '필수 입력 항목입니다.'
  }

  if (values.rollingFlag && (!values.slotRollingPer || !commonReg8.test(values.slotRollingPer))) {
    errors.slotRollingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }
  if (values.rollingFlag && (!values.liveRollingPer || !commonReg8.test(values.liveRollingPer))) {
    errors.liveRollingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }
  if (values.losingFlag && (!values.losingPer || !commonReg8.test(values.losingPer))) {
    errors.losingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }

  return errors
}

export const downPartnerRegisterValidate = values => {
  const errors = {}

  if (values.userAuthority && values.userAuthority.value === 'MEMBER' && !values.topPartner) {
    errors.topPartner = '유저 타입이 회원인 경우 상위 파트너 필수 선택입니다.'
  }
  if (!values.nickName) {
    errors.nickName = '필수 입력 항목입니다.'
  }

  if (!values.partnerId) {
    errors.partnerId = '필수 입력 항목입니다.'
  }
  if (!values.accountHolder) {
    errors.accountHolder = '필수 입력 항목입니다.'
  }

  if (!values.partnerPassword) {
    errors.partnerPassword = '필수 입력 항목입니다.'
  }
  if (!values.edgeName) {
    errors.edgeName = '필수 선택 항목입니다.'
  }
  if (!values.userAuthority) {
    errors.userAuthority = '필수 선택 항목입니다.'
  }
  if (!values.casinoLiveLevel) {
    errors.casinoLiveLevel = '필수 선택 항목입니다.'
  }
  if (!values.casinoSlotLevel) {
    errors.casinoSlotLevel = '필수 선택 항목입니다.'
  }
  if (!values.miniGameLevel) {
    errors.miniGameLevel = '필수 선택 항목입니다.'
  }
  if (!values.sportsLevel) {
    errors.sportsLevel = '필수 선택 항목입니다.'
  }
  if (!values.virtualGameLevel) {
    errors.virtualGameLevel = '필수 선택 항목입니다.'
  }
  if (!values.holdemLevel) {
    errors.holdemLevel = '필수 선택 항목입니다.'
  }

  if (!values.friendCompLevel) {
    errors.friendCompLevel = '필수 선택 항목입니다.'
  }

  if (!values.depositLevel) {
    errors.depositLevel = '필수 선택 항목입니다.'
  }

  return errors
}

export const chongpanRegisterValidate = values => {
  const errors = {}

  if (!values.topBonsa) {
    errors.topBonsa = '필수 선택 항목입니다.'
  }
  if (!values.topBooBonsa) {
    errors.topBooBonsa = '필수 선택 항목입니다.'
  }
  if (!values.partnerName) {
    errors.partnerName = '필수 입력 항목입니다.'
  }
  if (!values.partnerId) {
    errors.partnerId = '필수 입력 항목입니다.'
  }
  if (!values.partnerPassword) {
    errors.partnerPassword = '필수 입력 항목입니다.'
  }

  if (values.rollingFlag && (!values.slotRollingPer || !commonReg8.test(values.slotRollingPer))) {
    errors.slotRollingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }

  if (values.rollingFlag && !values.booBonsaSlotRollingPer) {
    errors.slotRollingPer = '상위 부본사를 먼저 선택해주세요.'
  }

  if (values.rollingFlag && values.slotRollingPer >= values.booBonsaSlotRollingPer) {
    errors.slotRollingPer = `선택한 부본사의 슬롯 롤링비율 ${values.booBonsaSlotRollingPer} 미만으로 설정할 수 있습니다.`
  }

  if (values.rollingFlag && (!values.liveRollingPer || !commonReg8.test(values.liveRollingPer))) {
    errors.liveRollingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }

  if (values.rollingFlag && !values.booBonsaLiveRollingPer) {
    errors.liveRollingPer = '상위 부본사를 먼저 선택해주세요.'
  }

  if (values.rollingFlag && values.liveRollingPer >= values.booBonsaLiveRollingPer) {
    errors.liveRollingPer = `선택한 부본사의 라이브 롤링비율 ${values.booBonsaLiveRollingPer} 미만으로 설정할 수 있습니다.`
  }

  if (values.losingFlag && (!values.losingPer || !commonReg8.test(values.losingPer))) {
    errors.losingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }

  if (values.losingFlag && !values.booBonsaLosingPer) {
    errors.losingPer = '상위 부본사를 먼저 선택해주세요.'
  }

  if (values.losingFlag && values.losingPer >= values.booBonsaLosingPer) {
    errors.losingPer = `선택한 부본사의 루징비율 ${values.booBonsaLosingPer} 미만으로 설정할 수 있습니다.`
  }

  return errors
}

export const maejangRegisterValidate = values => {
  const errors = {}

  if (!values.topBonsa) {
    errors.topBonsa = '필수 선택 항목입니다.'
  }
  if (!values.topBooBonsa) {
    errors.topBooBonsa = '필수 선택 항목입니다.'
  }
  if (!values.topChongpan) {
    errors.topChongpan = '필수 선택 항목입니다.'
  }

  if (!values.partnerName) {
    errors.partnerName = '필수 입력 항목입니다.'
  }
  if (!values.partnerId) {
    errors.partnerId = '필수 입력 항목입니다.'
  }
  if (!values.partnerPassword) {
    errors.partnerPassword = '필수 입력 항목입니다.'
  }

  if (values.rollingFlag && (!values.slotRollingPer || !commonReg8.test(values.slotRollingPer))) {
    errors.slotRollingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }

  if (values.rollingFlag && !values.chongpanSlotRollingPer) {
    errors.slotRollingPer = '상위 총판을 먼저 선택해주세요.'
  }

  if (values.rollingFlag && values.slotRollingPer >= values.chongpanSlotRollingPer) {
    errors.slotRollingPer = `선택한 총판의 슬롯 롤링비율 ${values.chongpanSlotRollingPer} 미만으로 설정할 수 있습니다.`
  }

  if (values.rollingFlag && (!values.liveRollingPer || !commonReg8.test(values.liveRollingPer))) {
    errors.liveRollingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }

  if (values.rollingFlag && !values.chongpanLiveRollingPer) {
    errors.liveRollingPer = '상위 총판을 먼저 선택해주세요.'
  }

  if (values.rollingFlag && values.liveRollingPer >= values.chongpanLiveRollingPer) {
    errors.liveRollingPer = `선택한 총판의 라이브 롤링비율 ${values.chongpanLiveRollingPer} 미만으로 설정할 수 있습니다.`
  }

  if (values.losingFlag && (!values.losingPer || !commonReg8.test(values.losingPer))) {
    errors.losingPer = '100% 미만, 소숫점 한자리까지만 가능합니다.'
  }

  if (values.losingFlag && !values.chongpanLosingPer) {
    errors.losingPer = '상위 총판을 먼저 선택해주세요.'
  }

  if (values.losingFlag && values.losingPer >= values.chongpanLosingPer) {
    errors.losingPer = `선택한 총판의 루징비율 ${values.chongpanLosingPer} 미만으로 설정할 수 있습니다.`
  }

  return errors
}

export const multiUserRegisterValidate = values => {
  const errors = {}

  if (!values.nickName) {
    errors.nickName = '필수 입력 항목입니다.'
  }

  if (!values.partnerId) {
    errors.partnerId = '필수 입력 항목입니다.'
  }
  if (!values.accountHolder) {
    errors.accountHolder = '필수 입력 항목입니다.'
  }

  if (!values.partnerPassword) {
    errors.partnerPassword = '필수 입력 항목입니다.'
  }

  if (!values.userAuthority) {
    errors.userAuthority = '필수 선택 항목입니다.'
  }

  return errors
}
