import React from 'react'
import { ZeroBettingEnums } from '../../../enums/Log/bettingEnums'
import { gameTypeEnums } from '../../../enums/SystemManagement/gameEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import ZeroBettingStopModal from './ZeroBettingStop/ZeroBettingStopModal'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const ZeroBettingListData = (content, fetchSearchMemberZeroBettingLog) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}
              </span>
              <span>
                <MemberDepositInfo memberId={original.memberId} />
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '종류',
      accessor: 'vendorType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.vendorType) {
          case 'LIVE':
            return (
              <button type="button" style={{ background: 'green', color: 'white', fontWeight: '900', border: 'none' }}>
                {gameTypeEnums[original.vendorType]}
              </button>
            )
          case 'SLOT':
            return (
              <button type="button" style={{ background: 'orange', color: 'white', fontWeight: '900', border: 'none' }}>
                {gameTypeEnums[original.vendorType]}
              </button>
            )
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>-</span>
        }
      },
    },
    {
      Header: '시작시간',
      accessor: 'startTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.startTime)
      },
    },
    {
      Header: '마감시간',
      accessor: 'endTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.endTime)
      },
    },
    {
      Header: '상태',
      accessor: 'zeroBettingStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.zeroBettingStatus) {
          case 'USING':
            return (
              <button type="button" style={{ background: 'blue', color: 'white', fontWeight: '900', border: 'none' }}>
                {ZeroBettingEnums[original.zeroBettingStatus]}
              </button>
            )
          case 'END':
            return (
              <button type="button" style={{ background: 'red', color: 'white', fontWeight: '900', border: 'none' }}>
                {ZeroBettingEnums[original.zeroBettingStatus]}
              </button>
            )
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>-</span>
        }
      },
    },
    {
      Header: '배팅금',
      accessor: 'zeroBettingAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.zeroBettingAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '차감 %',
      accessor: 'zeroRollingPer',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return `${original.zeroRollingPer}%`
      },
    },
    {
      Header: '차감 롤링액',
      accessor: 'zeroRollingAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.zeroRollingAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '기능',
      accessor: 'stopZero',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.zeroBettingStatus) {
          case 'USING':
            return (
              <ZeroBettingStopModal
                memberId={original.memberId}
                vendorType={original.vendorType}
                fetchSearchMemberZeroBettingLog={fetchSearchMemberZeroBettingLog}
              />
            )
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}> - </span>
        }
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default ZeroBettingListData
