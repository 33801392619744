import React, { useMemo, useState } from 'react'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  MiniGameBettingResultEnums,
} from '../../../enums/Log/miniGameBettingEnums'

const MiniGameBettingInfoData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배팅시간',
      accessor: 'bettingTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original?.bettingTime)
      },
    },
    {
      Header: '게임종류',
      accessor: 'miniGameType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MiniGameTypeEnums[original?.miniGameType]
      },
    },
    {
      Header: '배팅종류',
      accessor: 'miniGameBettingType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MiniGameBettingTypeEnums[original?.miniGameBettingType]
      },
    },
    {
      Header: '게임라운드',
      accessor: 'miniGameRound',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배팅 전 잔액',
      accessor: 'bettingBeforeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingBeforeAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '배팅액',
      accessor: 'bettingAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '배당',
      accessor: 'miniGameBettingPer',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ color: 'red', fontWeight: '900' }}>{original?.miniGameBettingPer}</span>
          </>
        )
      },
    },
    // {
    //   Header: '배팅 후 잔액',
    //   accessor: 'bettingAfterAmount',
    //   disableGlobalFilter: true,
    //   width: 65,
    //   Cell: r => {
    //     const { row } = r
    //     const { original } = row
    //     return original.bettingAfterAmount?.toString().replace(commonReg2, ',')
    //   },
    // },
    {
      Header: '당첨금',
      accessor: 'bettingExpectAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingExpectAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '배팅 결과',
      accessor: 'miniGameBettingResult',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.miniGameBettingResult) {
          case 'ING':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'green',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original.miniGameBettingResult]}
              </button>
            )
          case 'WIN':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'blue',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original.miniGameBettingResult]}
              </button>
            )
          case 'LOSE':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'red',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original.miniGameBettingResult]}
              </button>
            )
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>-</span>
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MiniGameBettingInfoData
