import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import styled from 'styled-components'
import { EventStatusEnumsSelectOptions, SportTypeEnums } from '../../../../enums/SystemManagement/sportEnums'
import { searchSportsInfo, updateSportEventShowStatus } from '../../../../utils/api/sportsManage/sportsInfo'
import { convertToKstSportsEventDateTime } from '../../../../utils/dateTime'
import SportsMarketModal from './SportsMarketModal'
import GameInfoManagementTabs from '../GameInfoManagementTabs'
import SportManagementTabs from '../../SportManagementTabs'
import EventRegistrationModal from './RegistrationManagement/EventRegistrationModal'
import ResultRegistrationModal from './ResultManagement/ResultRegistrationModal'
import { decodeAccessToken } from '../../../../utils/token'

const SportsEventList = () => {
  const location = useLocation()
  const [sportsType, setSportsType] = useState(new URLSearchParams(location.search).get('type'))
  const authority = decodeAccessToken()?.adminAuthority
  const [sportsInfoData, setSportsInfoData] = useState([])
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    sportsType,
    sportsTeamName: null,
    eventStatus: null,
  })

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('type')
    setSportsType(type)

    setParams(prev => ({
      ...prev,
      sportsType: type,
    }))
  }, [location])

  // 경기 목록 조회
  const fetchSearchSportsInfo = async () => {
    await searchSportsInfo(params).then(res => {
      const groupedData = res.data.content.reduce((acc, item) => {
        const { leagueId, startAt } = item
        const key = `${leagueId}-${startAt}`
        if (!acc[key]) {
          acc[key] = {
            leagueName: item.leagueName,
            leagueImage: item.leagueImage,
            ccKr: item.ccKr,
            ccImage: item.ccImage,
            sportsEvents: [],
          }
        }

        const newItem = { ...item }

        acc[key].sportsEvents.push(newItem)
        return acc
      }, {})

      setSportsInfoData(groupedData)
    })
  }

  useEffect(() => {
    fetchSearchSportsInfo()
  }, [params])

  const [sportsMarketModalShow, setSportsMarketModalShow] = useState(false)
  const [sportsEventIdForModal, setSportsEventIdForModal] = useState(null)
  const [sportsEventTime, setSportsEventTime] = useState(null)
  const onClickSportsMarketModalHnadler = (sportsEventId, startAt) => {
    setSportsMarketModalShow(prev => !prev)
    setSportsEventIdForModal(sportsEventId)
    setSportsEventTime(startAt)
  }

  // 팀명
  const [searchTeamName, setSearchTeamName] = useState('')

  const onSearchTeamNameHandler = e => {
    setSearchTeamName(e.target.value)
  }

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = EventStatusEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      eventStatus: !searchGameType ? null : searchGameType,
      sportsTeamName: !searchTeamName ? null : searchTeamName.trim(),
    }))
  }

  // 게임 등록 모달
  const [isGameRegistrationModalShow, setGameRegistrationModalShow] = useState(false)
  const onRegistrationHandler = () => {
    setGameRegistrationModalShow(true)
  }

  // 결과 처리 모달
  const [isResultRegistrationModalShow, setResultRegistrationModalShow] = useState(false)
  const [resultEventId, setResultEventId] = useState(null)
  const onResultHandler = eventId => {
    setResultRegistrationModalShow(true)
    setResultEventId(eventId)
  }

  const checkEventStatus = statusKr => {
    switch (statusKr) {
      case '경기종료':
        return <span style={{ color: '#31c44c' }}>{statusKr}</span>
      case '경기취소':
        return <span style={{ color: 'red' }}>{statusKr}</span>
      case '경기지연':
        return <span style={{ color: 'red' }}>{statusKr}</span>
      case '경기중단':
        return <span style={{ color: 'red' }}>{statusKr}</span>
      case '경기중':
        return <span style={{ color: '#3685f5' }}>{statusKr}</span>
      case '경기전':
        return <span style={{ color: 'orange' }}>{statusKr}</span>
      default:
        return <span style={{ color: 'black' }}>{statusKr}</span>
    }
  }

  const onEventShowHandler = (sportsEventId, homeName, awayName, status) => {
    if (authority === 'NORMAL') return
    if (status !== '경기전') {
      alert('경기상태 값이 "경기전"만 숨김 및 노출 할 수 있습니다.')
      return
    }
    if (loading) return
    if (
      window.confirm(
        `${homeName} VS ${awayName} 경기 모두를 노출 처리 하시겠습니까?\n"CF) 세부적인 경기를 노출처리 하려면 행을 클릭하세요"`,
      )
    ) {
      const body = {
        sportsEventId,
        showStatus: true,
      }
      setLoading(true)
      updateSportEventShowStatus(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setLoading(false)
          fetchSearchSportsInfo()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setLoading(false)
          fetchSearchSportsInfo()
        })
    }
  }

  const onEventHideHandler = (sportsEventId, homeName, awayName, status) => {
    if (authority === 'NORMAL') return
    if (status !== '경기전') {
      alert('경기상태 값이 "경기전"만 숨김 및 노출 할 수 있습니다.')
      return
    }
    if (
      window.confirm(
        `${homeName} VS ${awayName} 경기 모두를 숨김 처리 하시겠습니까?\n"CF) 세부적인 경기를 숨김처리 하려면 행을 클릭하세요"`,
      )
    ) {
      if (loading) return
      const body = {
        sportsEventId,
        showStatus: false,
      }
      setLoading(true)
      updateSportEventShowStatus(body)
        .then(res => {
          alert('변경에 완료됐습니다.')
          setLoading(false)
          fetchSearchSportsInfo()
        })
        .catch(error => {
          alert('변경에 실패하였습니다.')
          setLoading(false)
          fetchSearchSportsInfo()
        })
    }
  }

  return (
    <Card>
      <CardBody>
        <SportManagementTabs activeKey="1" />
        <GameInfoManagementTabs activeKey={sportsType} />

        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 행 클릭 시 <span style={{ color: 'blue', fontWeight: '500' }}>프리매치 및 스페셜 경기</span> 정보를 확인
              및 변경 할 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>팀 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchTeamName"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTeamName}
                  onChange={e => onSearchTeamNameHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>경기상태</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameType"
                placeholder="종류"
                onChange={option => {
                  onSelectGameTypeOptionHandler(option)
                }}
                value={searchGameTypeOptionValue}
                options={searchGameTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
          {/* <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'yellow' }}
              onClick={() => {
                onRegistrationHandler()
              }}
            >
              게임 수동 등록하기
            </Button>
          </SearchButtonWrap> */}
        </SearchContainer>
        <SportsInfoTableWrap>
          {sportsInfoData &&
            Object.keys(sportsInfoData).map(key => (
              <table>
                <thead>
                  <tr style={{ background: '#3730a3' }}>
                    <th style={{ width: '100%', textAlign: 'left', paddingLeft: '20px', color: '#fff' }}>
                      <SportsInfoLeagueWrap>
                        {sportsInfoData[key].leagueImage && <img src={sportsInfoData[key].leagueImage} alt="" />}
                        <span>{sportsInfoData[key].leagueName}</span>
                        <span>
                          - [&nbsp;{sportsInfoData[key].ccImage && <img src={sportsInfoData[key].ccImage} alt="" />}
                          <span>{sportsInfoData[key].ccKr}</span>&nbsp;]
                        </span>
                      </SportsInfoLeagueWrap>
                    </th>
                  </tr>
                  <tr style={{ background: '#D1D5Db' }}>
                    <th style={{ width: '6%' }}>경기 종목</th>
                    <th style={{ width: '12%' }}>경기 번호</th>
                    <th style={{ width: '10%' }}>경기 날짜</th>
                    <th style={{ width: '48%' }}>경기</th>
                    <th style={{ width: '6%' }}>경기 스코어</th>
                    <th style={{ width: '6%' }}>결과처리</th>
                    <th style={{ width: '6%' }}>노출여부</th>
                    <th style={{ width: '6%' }}>경기상태</th>
                  </tr>
                </thead>
                <tbody>
                  {sportsInfoData[key].sportsEvents.map(sportsEvent => (
                    <>
                      <tr>
                        <SportsEventWrap>
                          <SportsEventTypeWrap
                            onClick={() => {
                              onClickSportsMarketModalHnadler(sportsEvent.sportsEventId, sportsEvent.startAt)
                            }}
                          >
                            <span>{SportTypeEnums[sportsEvent.sportsType]}</span>
                          </SportsEventTypeWrap>
                          <SportsEventIdWrap
                            onClick={() => {
                              onClickSportsMarketModalHnadler(sportsEvent.sportsEventId, sportsEvent.startAt)
                            }}
                          >
                            {sportsEvent.sportsEventId} - {sportsEvent.prematchId}
                          </SportsEventIdWrap>
                          <SportsEventDateWrap
                            onClick={() => {
                              onClickSportsMarketModalHnadler(sportsEvent.sportsEventId, sportsEvent.startAt)
                            }}
                          >
                            {convertToKstSportsEventDateTime(sportsEvent.startAt)}
                          </SportsEventDateWrap>
                          <SportsEventTeamWrap
                            onClick={() => {
                              onClickSportsMarketModalHnadler(sportsEvent.sportsEventId, sportsEvent.startAt)
                            }}
                          >
                            {sportsEvent.homeImage && <img src={sportsEvent.homeImage} alt="" />}
                            <span>{sportsEvent.homeName}</span>
                            <span>VS</span>

                            <span>{sportsEvent.awayName}</span>
                            {sportsEvent.awayImage && <img src={sportsEvent.awayImage} alt="" />}
                          </SportsEventTeamWrap>
                          <SportsEventScoreWrap
                            onClick={() => {
                              onClickSportsMarketModalHnadler(sportsEvent.sportsEventId, sportsEvent.startAt)
                            }}
                          >
                            <span>{sportsEvent.score}</span>
                          </SportsEventScoreWrap>
                          <SportsEventResultWrap>
                            <SportsEventResultBtn
                              size="sm"
                              onClick={() => {
                                onResultHandler(sportsEvent.sportsEventId)
                              }}
                            >
                              결과처리
                            </SportsEventResultBtn>
                          </SportsEventResultWrap>
                          <SportsEventShowWrap>
                            {
                              {
                                true: (
                                  <FiEye
                                    onClick={() => {
                                      onEventHideHandler(
                                        sportsEvent.sportsEventId,
                                        sportsEvent.homeName,
                                        sportsEvent.awayName,
                                        sportsEvent.statusKr,
                                      )
                                    }}
                                  />
                                ),
                                false: (
                                  <FiEyeOff
                                    onClick={() => {
                                      onEventShowHandler(
                                        sportsEvent.sportsEventId,
                                        sportsEvent.homeName,
                                        sportsEvent.awayName,
                                        sportsEvent.statusKr,
                                      )
                                    }}
                                  />
                                ),
                              }[sportsEvent.showStatus]
                            }
                          </SportsEventShowWrap>
                          <SportsEventStatusWrap
                            onClick={() => {
                              onClickSportsMarketModalHnadler(sportsEvent.sportsEventId, sportsEvent.startAt)
                            }}
                          >
                            {checkEventStatus(sportsEvent.statusKr)}
                          </SportsEventStatusWrap>
                        </SportsEventWrap>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            ))}
        </SportsInfoTableWrap>
        {sportsMarketModalShow && (
          <SportsMarketModal
            sportsEventId={sportsEventIdForModal}
            sportsMarketModalShow={sportsMarketModalShow}
            onClickSportsMarketModalHnadler={onClickSportsMarketModalHnadler}
            sportsEventTime={sportsEventTime}
          />
        )}
        {isGameRegistrationModalShow && (
          <EventRegistrationModal
            isGameRegistrationModalShow={isGameRegistrationModalShow}
            setGameRegistrationModalShow={setGameRegistrationModalShow}
            fetchSearchSportsInfo={fetchSearchSportsInfo}
          />
        )}
        {isResultRegistrationModalShow && (
          <ResultRegistrationModal
            isResultRegistrationModalShow={isResultRegistrationModalShow}
            setResultRegistrationModalShow={setResultRegistrationModalShow}
            fetchSearchSportsInfo={fetchSearchSportsInfo}
            resultEventId={resultEventId}
            sportsType={sportsType}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default SportsEventList

const SportsInfoTableWrap = styled.div`
  width: 100%;

  table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    thead {
      tr {
        th {
          padding: 4px 4px;
          // border: 1px solid #888;
          border-bottom: 1px solid #888;
          white-space: nowrap;
          color: black;
          font-weight: 600;
          font-size: 12px;
          text-align: center;
        }
      }
    }

    tbody {
      gap: 10px;

      tr {
        &:hover {
          cursor: pointer;
          border: 2px solid red;
        }
      }
    }

    tr {
      width: 100%;
      display: flex;
    }
  }
`

const SportsInfoLeagueWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`
const SportsEventResultBtn = styled(Button)`
  background: #f59a0a;
  color: white;
  border: none;
`

const SportsEventIdWrap = styled.div`
  width: 12%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid black;
`

const SportsEventDateWrap = styled.div`
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid black;
`

const SportsEventTeamWrap = styled.div`
  width: 48%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  border-right: 1px solid black;

  img {
    width: 22px;
    height: 22px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    margin-left: 10px;
    text-align: center;
  }
`

const SportsEventShowWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid black;
  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
`

const SportsEventResultWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  padding: 0px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid black;
`

const SportsEventScoreWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid black;
`

const SportsEventStatusWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`

const SportsEventTypeWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid black;
`

const SportsEventWrap = styled.div`
  width: 100%;
  display: flex;
  background: #f3f4f6;
  color: black;

  height: 40px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
`
