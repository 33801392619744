import axios from '../base/axios'

// 북메이커 조회
export async function searchSportBookmakerList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/bookmaker',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
