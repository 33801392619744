import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const UnprocessedSportsBettingLog = ({ content }) => {
  const history = useHistory()
  const [newContent, setNewContent] = useState([])

  useEffect(() => {
    const updateContent = Object.keys(content).map(key => ({
      memberId: content[key][0].memberId,
      memberName: content[key][0].memberName,
      topPartnerName: content[key][0].topPartnerName, // topPartnerName은 동일하므로 첫번째 요소에서 가져옴
      details: content[key],
    }))

    setNewContent(updateContent)
  }, [content])

  const showDetailBettingLog = (memberId, details) => {
    const times = details.map(item => item.bettingAt)
    // 제일 오래된 날짜
    const startDate = new Date(Math.min(...times.map(dateStr => new Date(dateStr))))
    // 제일 최근 날짜
    const endDate = new Date(Math.max(...times.map(dateStr => new Date(dateStr))))

    history.push({
      pathname: '/management/log/sport-betting',
      state: {
        memberId,
        resultStatus: 'ING',
        startDate,
        endDate,
      },
    })
  }

  return (
    <SportsGameListTableWrap>
      <SportsGameListTable>
        <SportsGameListTableHead>
          <SportsGameListTableTr>
            <th style={{ width: '15%' }}>회원ID (명)</th>
            <th style={{ width: '11%' }}>상위파트너</th>
            <th style={{ width: '18%' }}>총 폴더 수</th>
            <th style={{ width: '56%' }}>자세히 보기</th>
          </SportsGameListTableTr>
        </SportsGameListTableHead>

        {newContent.map(item => (
          <SportsGameListTableBody key={item.sportsBettingLogKey}>
            <SportsGameListTableTr>
              <SportsGameFolderContentBox>
                <SportsGameFolderNumBox>
                  {item.memberId} ({item.memberName})
                </SportsGameFolderNumBox>
                <SportsGameFolderContentTime>{item.topPartnerName}</SportsGameFolderContentTime>
                <SportsGameFolderContentAwayBox>
                  <SportsGameFolderContentAwayPer>{item.details.length} 폴더</SportsGameFolderContentAwayPer>
                </SportsGameFolderContentAwayBox>
                <SportsGameFolderScoreBox>
                  <SportsDetailBettingLogBtn
                    size="sm"
                    onClick={() => {
                      showDetailBettingLog(item.memberId, item.details)
                    }}
                  >
                    보기
                  </SportsDetailBettingLogBtn>
                  <span style={{ fontSize: '12px', color: 'red', marginLeft: '10px' }}>
                    * 클릭시 해당 유저의 스포츠 배팅 내역 페이지로 이동합니다.
                  </span>
                </SportsGameFolderScoreBox>
              </SportsGameFolderContentBox>
            </SportsGameListTableTr>
          </SportsGameListTableBody>
        ))}
      </SportsGameListTable>
    </SportsGameListTableWrap>
  )
}

export default UnprocessedSportsBettingLog

UnprocessedSportsBettingLog.propTypes = {
  content: PropTypes.shape(),
}

UnprocessedSportsBettingLog.defaultProps = {
  content: {},
}

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  margin-top: 5px;
  padding-left: 20px;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 18%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox = styled.div`
  width: 15%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderScoreBox = styled.div`
  width: 56%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResultBox = styled.div`
  width: 11%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`
const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 18%;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGamePartnerBox = styled.div`
  width: 18%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  color: white;
  width: 11%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 36px;
`

const SportsDetailBettingLogBtn = styled(Button)`
  background: blue;
  color: white;
  border: none;
`
