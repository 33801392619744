import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../../shared/components/Tabs'

const TeamManagementTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="SOCCER"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=SOCCER')
                }}
              >
                축구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="BASEBALL"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=BASEBALL')
                }}
              >
                야구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="BASKETBALL"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=BASKETBALL')
                }}
              >
                농구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="VOLLEYBALL"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=VOLLEYBALL')
                }}
              >
                배구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="AMERICAN_FOOTBALL"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=AMERICAN_FOOTBALL')
                }}
              >
                미식축구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="ICE_HOCKEY"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=ICE_HOCKEY')
                }}
              >
                하키
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="BOXING"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=BOXING')
                }}
              >
                UFC/복싱
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="HANDBALL"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=HANDBALL')
                }}
              >
                핸드볼
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="TENNIS"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=TENNIS')
                }}
              >
                테니스
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="TABLE_TENNIS"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=TABLE_TENNIS')
                }}
              >
                탁구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="E_SPORTS"
                onClick={e => {
                  history.push('/management/sport/team/manage?type=E_SPORTS')
                }}
              >
                E-SPORT
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default TeamManagementTabs

TeamManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
