import { Button } from '@/shared/components/Button'
import { Card, CardBody } from '@/shared/components/Card'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import Error from '@/shared/components/form/Error'
import PropTypes from 'prop-types'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { borderRight } from '@/utils/directions'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import TimePicker from 'rc-time-picker'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import {
  searchDefaultBettingManage,
  updateDefaultBettingManage,
  updateDefaultBettingManageAll,
} from '../../../utils/api/systemManage/systemManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { defaultManagementValidate } from '../../../utils/validate/systemManagement/systemManage'
import {
  UserExchangeDateEnumsRadioOptions,
  UserRangeAutoStatusEnumsRadioOptions,
  UserSettingAutoStatusEnumsRadioOptions,
} from '../../../enums/SystemManagement/DefaultSettingEnums'
// import validate from '../validate'

const DefaultBettingMangeMent = ({ edgeName }) => {
  /**
   시스템설정 조회
   */
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)

  const [content, setContent] = useState([])

  const fetchSearchDefaultBettingManage = async () => {
    await searchDefaultBettingManage({ edgeName }).then(res => {
      setContent(res.data.content)
    })
  }

  useEffect(() => {
    if (!edgeName) return
    fetchSearchDefaultBettingManage()
  }, [edgeName])

  const [defaultManageData, setDefaultManageData] = useState(content)

  useEffect(() => {
    setDefaultManageData(content)
  }, [content])

  const onSubmitAll = (changeKeyWord, changeContent) => {
    if (writeLoading) return

    if (window.confirm('해당 설정을 일괄변경 하시겠습니까?\n일괄변경 시 모든 유저에게 적용됩니다.')) {
      const body = {
        edgeName,
        changeKeyWord,
        changeContent,
      }
      setWriteLoading(true)
      updateDefaultBettingManageAll(body)
        .then(res => {
          setWriteLoading(false)
          alert('기본값 설정이 변경됐습니다.')
          fetchSearchDefaultBettingManage()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('기본값 설정이 변경이 실패했습니다.')
        })
    }
  }

  // 공통 클릭 핸들러
  const onCheckField = fieldName => {
    const field =
      document.querySelector(`input[name="${fieldName}"]:checked`) ||
      document.querySelector(`input[name="${fieldName}"]`)

    if (!field) {
      alert('값을 입력하거나 선택해주세요.')
      return
    }

    const changeKeyWord = fieldName
    const changeContent = field.value

    onSubmitAll(changeKeyWord, changeContent)
  }

  const onSubmit = e => {
    if (writeLoading) return

    if (window.confirm('기본값 설정을 변경 하시겠습니까?')) {
      const body = {
        edgeName,
        userSkinNum: e.userSkinNum,
        miniGameMaxBetting: e.miniGameMaxBetting?.toString().replaceAll(',', ''),
        miniGameMinBetting: e.miniGameMinBetting?.toString().replaceAll(',', ''),
        miniGameMaxWinning: e.miniGameMaxWinning?.toString().replaceAll(',', ''),
        withdrawlRollingStatus: e.withdrawlRollingStatus,
        losingSettlementStatus: e.losingSettlementStatus,
        userLosingPointExchangeAutoStatus: e.userLosingPointExchangeAutoStatus,
        depositBonusStatus: e.depositBonusStatus,
        recommendCodeStatus: e.recommendCodeStatus,
        userExchangeDateStatus: e.userExchangeDateStatus,
        settlementExchangeStatus: e.settlementExchangeStatus,
        moneyIncExchangeStatus: e.moneyIncExchangeStatus,
        moneyDecExchangeStatus: e.moneyDecExchangeStatus,
        duplicatedSignInStatus: e.duplicatedSignInStatus,
      }
      setWriteLoading(true)
      updateDefaultBettingManage(body)
        .then(res => {
          setWriteLoading(false)
          alert('기본값 일괄설정이 변경됐습니다.')
          fetchSearchDefaultBettingManage()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('기본값 일괄설정이 변경이 실패했습니다.')
        })
    }
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody style={{ padding: '0px' }}>
          {writeLoading ? (
            <Loading />
          ) : (
            <>
              <Form
                onSubmit={onSubmit}
                validate={defaultManagementValidate}
                initialValues={{
                  userSkinNum: defaultManageData?.userSkinNum,
                  miniGameMaxBetting: defaultManageData?.miniGameMaxBetting?.toString()?.replace(commonReg2, ','),
                  miniGameMinBetting: defaultManageData?.miniGameMinBetting?.toString()?.replace(commonReg2, ','),
                  miniGameMaxWinning: defaultManageData?.miniGameMaxWinning?.toString()?.replace(commonReg2, ','),
                  withdrawlRollingStatus: defaultManageData?.withdrawlRollingStatus,
                  losingSettlementStatus: defaultManageData?.losingSettlementStatus,
                  userLosingPointExchangeAutoStatus: defaultManageData?.userLosingPointExchangeAutoStatus,
                  depositBonusStatus: defaultManageData?.depositBonusStatus,
                  recommendCodeStatus: defaultManageData?.recommendCodeStatus,
                  userExchangeDateStatus: defaultManageData?.userExchangeDateStatus,
                  settlementExchangeStatus: defaultManageData?.settlementExchangeStatus,
                  moneyIncExchangeStatus: defaultManageData?.moneyIncExchangeStatus,
                  moneyDecExchangeStatus: defaultManageData?.moneyDecExchangeStatus,
                  duplicatedSignInStatus: defaultManageData?.duplicatedSignInStatus,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <FormContainer horizontal onSubmit={handleSubmit}>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        에볼루션 스킨번호{' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('userSkinNum')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="userSkinNum">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>번</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        미니게임 최소배팅(액)
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('miniGameMinBetting')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="miniGameMinBetting">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>

                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        미니게임 최대배팅(액){' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('miniGameMaxBetting')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="miniGameMaxBetting">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>

                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        미니게임 최대당첨(액){' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('miniGameMaxWinning')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="miniGameMaxWinning">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        입금보너스 허용여부{' '}
                        <CustomButton
                          onClick={() => {
                            onCheckField('depositBonusStatus')
                          }} // name 값 전달
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="depositBonusStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        추천코드 허용여부{' '}
                        <CustomButton
                        // onClick={() => {
                        //   onSubmitAll(userSkinNum)
                        // }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="recommendCodeStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        정산금 전환여부(파트너용){' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('userLosingPointExchangeAutoStatus')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="userLosingPointExchangeAutoStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        하부 정산비율 수정여부(파트너용){' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('settlementExchangeStatus')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="settlementExchangeStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        하부 머니증가 여부(파트너용){' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('moneyIncExchangeStatus')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="moneyIncExchangeStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        하부 머니회수 여부(파트너용){' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('moneyDecExchangeStatus')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="moneyDecExchangeStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        중복로그인 허용 여부{' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('duplicatedSignInStatus')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserSettingAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="duplicatedSignInStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>
                        정산일 여부(파트너용){' '}
                        <CustomButton
                          type="button"
                          onClick={() => {
                            onCheckField('userExchangeDateStatus')
                          }}
                        >
                          일괄변경
                        </CustomButton>
                      </CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserExchangeDateEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="userExchangeDateStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <div style={{ width: '100%', marginBottom: '30px' }}>
                      <h3>전역 설정 </h3>
                    </div>

                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>출금롤링 적용 여부</CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserRangeAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="withdrawlRollingStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>
                    <CustomFormGroup style={{ display: 'flex', flexDirection: 'column' }}>
                      <CustomFormGroupLabel>입출 루징정산 적용 여부</CustomFormGroupLabel>
                      <div style={{ marginLeft: '0px', marginTop: '5px' }}>
                        {UserRangeAutoStatusEnumsRadioOptions().map(item => (
                          <CustomFormGroupField2
                            key={`index_${item.label}`}
                            style={{
                              display: 'inline-block',
                              width: 'max-content',
                              margin: '0px',
                              padding: '0px',
                            }}
                          >
                            <Field
                              name="losingSettlementStatus"
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={item.initialValue}
                            />
                          </CustomFormGroupField2>
                        ))}
                      </div>
                    </CustomFormGroup>

                    {
                      {
                        SUPER: (
                          <FormButtonToolbar className="registrationFooter">
                            <Button variant="primary" type="submit">
                              등록
                            </Button>
                          </FormButtonToolbar>
                        ),
                      }[authority]
                    }
                  </FormContainer>
                )}
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

DefaultBettingMangeMent.propTypes = {
  edgeName: PropTypes.string.isRequired,
}

export default DefaultBettingMangeMent

const CustomButton = styled.button`
  max-width: 100%;
  height: 20px;
  background-color: red;
  color: white;
  border: none;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
`

const CustomFormGroup = styled(FormGroup)`
  border-bottom: dotted 1px black;
  padding-bottom: 10px;
  width: 50%;
`

const CustomFormGroupLabel = styled(FormGroupLabel)`
  width: 100% !important;
  font-weight: bolder;
  color: black;
  // border: solid 1px red;
  // text-align: center;
`
const CustomFormGroupField2 = styled(FormGroupField)`
  input {
    border: solid 1px black;
  }
`

const CustomFormGroupField = styled(FormGroupField)`
  width: 60% !important;
  // border: solid 1px red;
  input {
    border: solid 1px black;
  }
`

// 회원 쿠폰 Select
export const MemberCouponSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
// endregion

const CustomTimePicker = styled(TimePicker)`
  & .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  & .rc-time-picker-clear,
  & .rc-time-picker-clear-icon:after {
    font-size: 15px;
  }

  & .rc-time-picker-panel-select,
  & .rc-time-picker-input,
  & .rc-time-picker-panel-input {
    font-family: 'Consolas', sans-serif;
    font-size: 16px;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`
