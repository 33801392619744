export const BetStatus = {
  NOT_SET: 'NOT SET',
  OPEN: '발매',
  SUSPENDED: '중지',
  SETTLED: '정산',
  INVALID: 'INVALID',
}

export const BetSettlementStatus = {
  CANCELLED: '취소',
  NOT_SETTLED: '대기',
  LOSER: '낙첨',
  WINNER: '적중',
  REFUND: '적특',
  HALF_LOST: '절반 낙첨',
  HALF_WON: '절반 적중',
}

export const getBetStatusSelectOption = () => {
  return Object.entries(BetStatus)
    .map(([key, value]) => {
      if (key === 'NOT_SET' || key === 'INVALID') return undefined

      return { value: key, label: value }
    })
    .filter(Boolean) // undefined 값을 제거
}

export const getBetSettlementSelectOption = () => {
  return Object.entries(BetSettlementStatus)
    .map(([key, value]) => {
      if (key === 'NOT_SET' || key === 'INVALID') return undefined

      return { value: key, label: value }
    })
    .filter(Boolean) // undefined 값을 제거
}

export const getBetStatusColor = betStatus => {
  switch (betStatus) {
    case 'SUSPENDED':
      return 'red'
    case 'SETTLED':
      return 'blue'
    default:
      return 'black'
  }
}

export const getBetSettlementColor = settlementType => {
  switch (settlementType) {
    case 'WINNER':
    case 'HALF_WON':
      return 'green'
    case 'LOSER':
    case 'HALF_LOST':
      return 'red'
    case 'CANCELLED':
      return 'pink'
    case 'REFUND':
      return 'grey'
    default:
      return 'black'
  }
}

export const SportMarketType = {
  DOMESTIC: '국내형',
  OVERSEAS: '해외형',
  SPECIAL: '스페셜',
  LIVE: '라이브',
}
