import { Card, CardBody, CustomAuthorityButton } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { HoldemResultStatusEnums } from '../../../enums/Holdem/HoldemBettingEnums'
import { HoldemBettingResultEnumsSelectOptions } from '../../../enums/Log/bettingEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import Loading from '../../../shared/components/Loading'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'
import {
  adminDownloadWildGamesBettingLog,
  deleteWildGamesBettingLog,
  searchWildGamesBettingLog,
} from '../../../utils/api/holdem/wildGamesApi'
import { convertToKst, convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import BettingManagementTabs from '../BettingManagementTabs'

const WildGamesBettingLogPage = () => {
  const history = useHistory()

  const { EdgeNameSelect, authority } = useSelector(state => {
    const { headerInfo, initInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
      authority: initInfo.authority,
    }
  })

  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    page: 0,
    size: 15,
    isExact: false,
  })

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [bettingLogList, setBettingLogList] = useState([])

  const fetchWildGamesBettingLog = async () => {
    setLoading(true)

    await searchWildGamesBettingLog(params)
      .then(res => {
        setTotalElement(res.data.totalElements)
        setBettingLogList(res.data.bettingLogList)
      })
      .catch(error => {
        // console.log('dd')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchWildGamesBettingLog()
  }, [params])

  const [userAuthority, setUserAuthority] = useState(null)

  const onSearchUserAuthorityHandler = authority => {
    setUserAuthority(authority)

    setParams(prev => ({
      ...prev,
      authority,
      page: 0,
    }))
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = HoldemBettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 배팅번호
  const [searchBettingNum, setSearchBettingNum] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      userId: !searchMemberId ? null : searchMemberId.trim(),
      topUserId: !searchTopPartnerId ? null : searchTopPartnerId.trim(),
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
      bettingResult: !searchGameResult ? null : searchGameResult,
      bettingNum: !searchBettingNum ? null : searchBettingNum,
      page: 0,
    }))
  }

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 15))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))
  }

  const [selectedBettingLog, setSelectedBettingLog] = useState({})

  // 체크박스
  const onCheckBettingLogHandler = bettingLog => {
    setSelectedBettingLog(prev => {
      // 이미 키가 존재하는지 확인
      if (prev[bettingLog.bettingLogKey]) {
        // 키가 존재하면 해당 키를 제거
        const { [bettingLog.bettingLogKey]: value, ...rest } = prev
        return rest
      }
      // 키가 존재하지 않으면 새로운 키 추가
      return { ...prev, [bettingLog.bettingLogKey]: bettingLog }
    })
  }

  // 전체 선택
  const onClickAllBettingLogHandler = () => {
    if (Object.keys(selectedBettingLog).length !== 0) {
      setSelectedBettingLog({})
    } else {
      const temp = bettingLogList.reduce((obj, current, index) => {
        obj[current.bettingLogKey] = current
        return obj
      }, {})

      setSelectedBettingLog(temp)
    }
  }

  // 배팅내역 삭제
  const onClickBettingLogDeleteHandler = () => {
    if (loading) return

    if (authority === 'NORMAL') return

    if (Object.keys(selectedBettingLog).length === 0) {
      alert('삭제(비노출) 할 내역들을 선택해주세요.')
      return
    }

    if (
      window.confirm(
        `와일드게임즈 배팅내역을 삭제(비노출) 하시겠습니까?\n삭제(비노출) 시 유저에게 해당 배팅내역은 보이지 않습니다.`,
      )
    ) {
      setLoading(true)

      const body = {
        bettingLogKeyList: Object.keys(selectedBettingLog),
        allDelete: false,
      }
      deleteWildGamesBettingLog(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
        })
        .finally(() => {
          setLoading(false)
          setSelectedBettingLog({})
          fetchWildGamesBettingLog()
        })
    }
  }

  const onClickBettingLogDeleteHandlerAll = () => {
    if (loading) return
    if (authority === 'NORMAL') return

    if (
      window.confirm(
        `레볼루션 홀덤 배팅내역을 전체삭제(비노출) 하시겠습니까?\n삭제(비노출) 시 유저에게 해당 배팅내역은 보이지 않습니다.`,
      )
    ) {
      setLoading(true)

      const body = {
        bettingLogKeyList: [],
        allDelete: true,
      }

      deleteWildGamesBettingLog(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
        })
        .finally(() => {
          setLoading(false)
          fetchWildGamesBettingLog()
        })
    }
  }

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    topPartnerName: '상위 파트너',
    userId: '유저ID',
    userName: '유저명',
    nickName: '유저명',
    authority: '권한',
    sequence: '배팅번호',
    roundId: '라운드',
    providerName: '공급사',
    gameName: '게임명',
    bet: '배팅 금액',
    win: '당첨 금액',
    jackpot: '잭팟 금액',
    other: '기타 금액',
    amount: '변동 금액',
    startAmount: '시작 금액',
    endAmount: '종료 금액',
    balance: '잔고',
    bettingAt: '처리시간',
    bettingResult: '결과',
  }

  const downloadHandler = () => {
    if (loading) return

    if (
      window.confirm(
        '와일드게임즈 배팅내역 DB를 csv파일로 다운 받으시겠습니까?\n 기간설정은 필수 사항이며, 유저ID/명 검색은 선택사항입니다.',
      )
    ) {
      if (searchStartDate === null || searchEndDate === null) {
        alert('기간 설정은 필수 입니다.')
        return
      }
      if (searchStartDate > searchEndDate) {
        alert('기간을 다시 확인해주세요.')
        return
      }

      setLoading(true)

      adminDownloadWildGamesBettingLog({
        memberId: !searchMemberId ? null : searchMemberId.trim(),
        topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
        startDate: convertToKst(searchStartDate),
        endDate: convertToKst(searchEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '와일드게임즈 배팅 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}

            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]

              if (!koreanColumnName) {
                return
              }

              mappedItem[koreanColumnName] = item[key]
            })

            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
        })
        .catch(error => {
          alert('조건에 맞는 내역이 없습니다.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Card>
        <CardBody>
          <BettingManagementTabs activeKey={7} />

          <MiniGameTypeSelectWrapper>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              active
              onClick={() => {
                history.push('/management/log/wild-games/betting')
              }}
            >
              배팅내역
            </Button>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                history.push('/management/log/wild-games/betting-detail')
              }}
            >
              배팅 세부내역
            </Button>

            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                history.push('/management/log/wild-games/game-event')
              }}
            >
              게임 이벤트내역
            </Button>
          </MiniGameTypeSelectWrapper>

          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              onSearchUserAuthorityHandler(e.target.id)
            }}
          />

          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>사이트</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchEdgeName"
                  onChange={option => {
                    onSelectEdgeNameOptionHandler(option)
                  }}
                  placeholder="전체"
                  value={searchEdgeNameOptionValue}
                  options={searchEdgeNameOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchMemberId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchMemberId}
                    onChange={e => onSearchMemberIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>상위파트너 ID / 명</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchValue"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchTopPartnerId}
                    onChange={e => onSearchTopPartnerIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>배팅번호</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchBettingNum"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchBettingNum}
                    onChange={e => {
                      setSearchBettingNum(e.target.value)
                    }}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>게임결과</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchGameResult"
                  placeholder="게임결과"
                  onChange={option => {
                    onSelectGameResultOptionHandler(option)
                  }}
                  value={searchGameResultOptionValue}
                  options={searchGameResultOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>당첨액(이상)</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchStartAmount"
                    type="text"
                    placeholder="숫자만"
                    value={searchStartAmount}
                    onChange={e => onSearchStartAmountHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>당첨액(이하)</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchEndAmount"
                    type="text"
                    placeholder="숫자만"
                    value={searchEndAmount}
                    onChange={e => onSearchEndAmountHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
          </SearchContainer>

          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
              <Button
                size="sm"
                variant="outline-secondary"
                style={{ background: 'green', color: 'white' }}
                onClick={e => {
                  downloadHandler()
                }}
              >
                배팅내역 DB다운
              </Button>
              <Button
                size="sm"
                variant="outline-secondary"
                style={{ backgroundColor: 'orange', color: 'white' }}
                onClick={() => {
                  onClickAllBettingLogHandler()
                }}
              >
                전체선택
              </Button>
              <Button
                size="sm"
                variant="outline-secondary"
                style={{ backgroundColor: 'grey', color: 'white' }}
                onClick={() => {
                  onClickBettingLogDeleteHandler()
                }}
              >
                삭제 (비노출)
              </Button>
              <Button
                size="sm"
                variant="outline-secondary"
                style={{ backgroundColor: 'red', color: 'white' }}
                onClick={() => {
                  onClickBettingLogDeleteHandlerAll()
                }}
              >
                전체삭제
              </Button>
            </SearchButtonWrap>
          </SearchContainer>

          <SportBettingLogContentWrap>
            <table>
              <thead>
                <tr>
                  <th> - </th>
                  <th>번호</th>
                  <th>사이트명</th>
                  <th>처리일시</th>
                  <th>상위파트너 (이름)</th>
                  <th>유저 (이름)</th>
                  <th>배팅번호</th>
                  <th>라운드</th>
                  <th>공급사</th>
                  <th>게임명</th>
                  <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅 금액</th>
                  <th>당첨 금액</th>
                  <th>잭팟 금액</th>
                  <th>기타 금액</th>
                  <th style={{ backgroundColor: 'green', color: '#fff' }}>
                    <TooltipWrapper text="변동금액 = 당첨 + 잭팟 - 배팅 + 기타" place="bottom">
                      <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>변동 금액</span>
                    </TooltipWrapper>
                  </th>
                  <th>시작 금액</th>
                  <th>종료 금액</th>
                  <th>잔고</th>
                  <th>결과</th>
                  <th>노출여부</th>
                </tr>
              </thead>
              <tbody>
                {bettingLogList.length === 0 ? (
                  <tr>
                    <td colSpan={20}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textAlign: 'center',
                          color: '#000',
                          fontSize: '15px',
                          padding: '20px 0px',
                        }}
                      >
                        최근 게시물이 없습니다.
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {bettingLogList.map(bettingLog => {
                      return (
                        <>
                          <tr key={bettingLog.bettingLogKey}>
                            <td>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value=""
                                  checked={bettingLog.bettingLogKey in selectedBettingLog}
                                  onClick={e => {
                                    e.stopPropagation()
                                    onCheckBettingLogHandler(bettingLog)
                                  }}
                                />
                              </div>
                            </td>
                            <td>{bettingLog.num}</td>
                            <td>{bettingLog.edgeName}</td>

                            <td>{convertToKstDateTime(bettingLog.transactionDate)}</td>
                            <td>
                              {bettingLog.topUserId ? (
                                <>
                                  {bettingLog.topUserId} ({bettingLog.topUserName})
                                </>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>
                              <span
                                onClick={() => {
                                  window.open(`/user-info/${bettingLog.userId}`, '_blank')
                                }}
                                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                              >
                                {bettingLog.userId} ({bettingLog.userName})
                              </span>
                              <span style={{ marginLeft: '5px' }}>
                                <CustomAuthorityButton authority={bettingLog.userAuthority}>
                                  {NoteAuthorityEnums[bettingLog.userAuthority]}
                                </CustomAuthorityButton>
                              </span>
                              <span>
                                <span>
                                  <MemberDepositInfo memberId={bettingLog.userId} />
                                </span>
                                <span>
                                  <MemberCoupon memberId={bettingLog.userId} />
                                </span>
                                <span>
                                  <MemberNote memberId={bettingLog.userId} />
                                </span>
                                <span>
                                  <MemberMoneyIncDecMini memberId={bettingLog.userId} />
                                </span>
                              </span>
                            </td>
                            <td>{bettingLog.sequence}</td>
                            <td>{bettingLog.roundId}</td>
                            <td>{bettingLog.providerName}</td>
                            <td>{bettingLog.gameName}</td>
                            <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                              {bettingLog.bet.toString().replace(commonReg2, ',')}
                            </td>
                            <td>{bettingLog.win.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.jackpot.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.other.toString().replace(commonReg2, ',')}</td>
                            <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                              {bettingLog.amount.toString().replace(commonReg2, ',')}
                            </td>
                            <td>{bettingLog.startAmount.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.endAmount.toString().replace(commonReg2, ',')}</td>
                            <td>{bettingLog.balance.toString().replace(commonReg2, ',')}</td>
                            <td>
                              <CustomButton type="button" bettingResult={bettingLog.bettingResult}>
                                {HoldemResultStatusEnums[bettingLog.bettingResult]}
                              </CustomButton>
                            </td>
                            <td>
                              {bettingLog.showFlag ? <span>노출</span> : <span style={{ color: 'red' }}>비노출</span>}
                            </td>
                          </tr>
                        </>
                      )
                    })}
                  </>
                )}
              </tbody>
            </table>
            <PaginationWrap>
              <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
            </PaginationWrap>
          </SportBettingLogContentWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default WildGamesBettingLogPage

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const MiniGameTypeSelectWrapper = styled.div`
  padding: 10px 0;
  // margin-bottom: 5px;

  display: flex;
  gap: 5px;
`

const SportBettingLogContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
