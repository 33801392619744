export const UserExchangeDate = {
  EVERY: '항상',
  FIRST: '매월1일',
  HALF: '매월15일',
  FIRSTANDHALF: '매월1일,15일',
}

export const UserExchangeDateEnumsRadioOptions = () => {
  return Object.entries(UserExchangeDate).map(([key, value], i) =>
    i === 0
      ? {
          name: 'userExchangeDateStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'userExchangeDateStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const UserSettingAutoStatus = {
  ALLOW: '허용',
  DENY: '비허용',
}

export const UserSettingAutoStatusEnumsRadioOptions = () => {
  return Object.entries(UserSettingAutoStatus).map(([key, value], i) =>
    i === 0
      ? {
          name: 'userSettingAutoStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'userSettingAutoStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const UserSettingAutoStatusReverseEnumsRadioOptions = () => {
  return Object.entries(UserSettingAutoStatus).map(([key, value], i) =>
    i === 1
      ? {
          name: 'userSettingAutoStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'userSettingAutoStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const UserRangeAutoStatus = {
  ALL: '전부',
  MEMBER: '회원만',
  PARTNER: '파트너만',
}

export const UserRangeAutoStatusEnumsRadioOptions = () => {
  return Object.entries(UserRangeAutoStatus).map(([key, value], i) =>
    i === 0
      ? {
          name: 'userRangeAutoStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'userRangeAutoStatus',
          label: value,
          radioValue: key,
        },
  )
}
