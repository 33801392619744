export const MemberWithdrawalStatusEnums = {
  APPLY: '신청',
  APPROVE: '승인',
  WAITING: '대기',
  CANCEL: '취소',
}

export const MemberWithdrawalStatusEnumsSelectOptions = () => {
  return Object.entries(MemberWithdrawalStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
