import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import PropTypes from 'prop-types'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../../../enums/Operation/NoteEnums'
import { BettingResultStatusEnumsSelectOptions } from '../../../../../enums/SystemManagement/sportEnums'
import {
  RealVirtualGameSportType,
  VirtualGameSportType,
  VirtualResultStatusEnums,
} from '../../../../../enums/VirtualGame/virtualGameEnums'
import Loading from '../../../../../shared/components/Loading'
import TooltipWrapper from '../../../../../shared/components/TooltipWrapper'
import CustomPagination from '../../../../../shared/components/customPagination/CustomPagination'
import { searchVirtualGameBettingLog } from '../../../../../utils/api/virtualGame/virtualGameApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../../../utils/dateTime'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import VirtualDetailBettingLog from '../../../../BettingManagement/VirtualGameBettingLog/VirtualDetailBettingLog'
import MemberMoneyIncDecMini from '../../MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberNote/MemberNote'
import MemberCoupon from '../../MemberCoupon/MemberCoupon'
import MemberDepositInfo from '../../../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

const MemberVirtualGameBettingHistory = ({ memberId }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [resultStatus, setResultStatus] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  /**
   배팅로그 조회
   */
  const [params, setParams] = useState({
    userId: memberId,
    isExact: true,
    page: 0,
    size: 10,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [virtualGameSportType, setVirtualGameSportType] = useState(null)

  // 미니게임 종목 선택
  const onClickMiniGameTypeHandler = type => {
    setVirtualGameSportType(type)

    setParams(prev => ({
      ...prev,
      page: 0,
      virtualGameSportType: type || null,
    }))
  }

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
  }

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [virtualBettingLogList, setVirtualBettingLogList] = useState([])

  const fetchVirtualGameBettingLog = async () => {
    setLoading(true)
    await searchVirtualGameBettingLog(params)
      .then(res => {
        const newVirtualBettingLogList = res.data.virtualBettingLogList.map(item => ({
          ...item,
          isVisible: false,
        }))
        setVirtualBettingLogList(newVirtualBettingLogList)
        setTotalElement(res.data.totalElements)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!params) return
    fetchVirtualGameBettingLog()
  }, [params])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState(resultStatus || '')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(
    resultStatus ? { value: 'ING', label: '진행중' } : null,
  )
  const searchGameResultOption = BettingResultStatusEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate || null)
  const [searchEndDate, setSearchEndDate] = useState(endDate || null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [searchTeamName, setSearchTeamName] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      virtualResultStatus: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      teamName: !searchTeamName ? null : searchTeamName,
    }))
  }

  const [selectedVirtualBettingLog, setSelectedVirtualBettingLog] = useState({})

  // 체크박스
  const onCheckSportsBettingLogHandler = virtualGameBettingLog => {
    setSelectedVirtualBettingLog(prev => {
      // 이미 키가 존재하는지 확인
      if (prev[virtualGameBettingLog.virtualBettingLogKey]) {
        // 키가 존재하면 해당 키를 제거
        const { [virtualGameBettingLog.virtualBettingLogKey]: value, ...rest } = prev
        return rest
      }
      // 키가 존재하지 않으면 새로운 키 추가
      return { ...prev, [virtualGameBettingLog.virtualBettingLogKey]: virtualGameBettingLog }
    })
  }
  // 특정 배팅로그 펼치기
  const onClickVirtualGameBettingLogHandler = virtualBettingLogKey => {
    setVirtualBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        if (item.virtualBettingLogKey === virtualBettingLogKey) {
          return { ...item, isVisible: !item.isVisible } // isVisible 토글
        }
        return item // 변경 없이 그대로 반환
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  return (
    <>
      {loading && <Loading />}
      {/* 검색 기능 */}
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
          <p>* 행을 클릭하면, 해당 유저의 자세한 배팅내역을 볼 수 있습니다.</p>
        </div>
      </SearchCardTitleWrap>
      <div style={{ display: 'flex', gap: '50px', width: '100%' }}>
        <MiniGameTypeSelectWrapper>
          <Button
            type="button"
            size="sm"
            variant="outline-secondary"
            active={!virtualGameSportType}
            onClick={() => {
              onClickMiniGameTypeHandler(null)
            }}
          >
            전체
          </Button>
          {Object.entries(RealVirtualGameSportType).map(([key, value]) => {
            return (
              <Button
                type="button"
                size="sm"
                variant="outline-secondary"
                active={key === virtualGameSportType}
                onClick={() => {
                  onClickMiniGameTypeHandler(key)
                }}
              >
                {value}
              </Button>
            )
          })}
        </MiniGameTypeSelectWrapper>
      </div>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>결과</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchGameResult"
              placeholder="결과"
              onChange={option => {
                onSelectGameResultOptionHandler(option)
              }}
              value={searchGameResultOptionValue}
              options={searchGameResultOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <SportBettingLogContentWrap>
        <table>
          <thead>
            <tr>
              <th> - </th>
              <th>번호</th>
              <th>사이트명</th>
              <th>배팅일시</th>
              <th>상위파트너 (이름)</th>
              <th>유저 (이름)</th>
              <th>배팅전 머니</th>
              <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액</th>
              <th>배팅후 머니</th>
              <th>타입</th>
              <th>폴더</th>
              <th>배당</th>
              <th>예상/최종 당첨금</th>
              <th>결과</th>
              <th>노출여부</th>
            </tr>
          </thead>
          <tbody>
            {virtualBettingLogList.length !== 0 &&
              virtualBettingLogList.map(virtualGameBettingLog => {
                return (
                  <>
                    <tr
                      key={virtualGameBettingLog.virtualBettingLogKey}
                      onClick={e => {
                        onClickVirtualGameBettingLogHandler(virtualGameBettingLog.virtualBettingLogKey)
                      }}
                    >
                      <td>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <input
                            type="checkbox"
                            value=""
                            checked={virtualGameBettingLog.virtualBettingLogKey in selectedVirtualBettingLog}
                            onClick={e => {
                              e.stopPropagation()
                              onCheckSportsBettingLogHandler(virtualGameBettingLog)
                            }}
                          />
                        </div>
                      </td>
                      <td>{virtualGameBettingLog.num}</td>
                      <td>{virtualGameBettingLog.edgeName}</td>
                      <td>{convertToKstSportsEventDateTime(virtualGameBettingLog.bettingAt)}</td>
                      <td>
                        {virtualGameBettingLog.topUserId ? (
                          <>
                            {virtualGameBettingLog.topUserId} ({virtualGameBettingLog.topUserName})
                          </>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <span
                          onClick={() => {
                            window.open(`/user-info/${virtualGameBettingLog.userId}`, '_blank')
                          }}
                          style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {virtualGameBettingLog.userId} ({virtualGameBettingLog.userName})
                        </span>
                        <span style={{ marginLeft: '5px' }}>
                          <CustomAuthorityButton authority={virtualGameBettingLog.userAuthority}>
                            {NoteAuthorityEnums[virtualGameBettingLog.userAuthority]}
                          </CustomAuthorityButton>
                        </span>
                        <span>
                          <span>
                            <MemberDepositInfo memberId={virtualGameBettingLog.userId} />
                          </span>
                          <span>
                            <MemberCoupon memberId={virtualGameBettingLog.userId} />
                          </span>
                          <span>
                            <MemberNote memberId={virtualGameBettingLog.userId} />
                          </span>
                          <span>
                            <MemberMoneyIncDecMini memberId={virtualGameBettingLog.userId} />
                          </span>
                        </span>
                      </td>
                      <td>{virtualGameBettingLog.bettingBeforeAmount.toString().replace(commonReg2, ',')}</td>
                      <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                        {virtualGameBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                      </td>
                      <td>{virtualGameBettingLog.bettingAfterAmount.toString().replace(commonReg2, ',')}</td>
                      <CustomTd virtualGameSportType={virtualGameBettingLog.virtualGameSportType}>
                        {VirtualGameSportType[virtualGameBettingLog.virtualGameSportType]}
                      </CustomTd>
                      <td>{virtualGameBettingLog.folderSize} 폴더</td>
                      <td>
                        {(() => {
                          const {
                            realReduceBettingPerForSingleFolder,
                            realReduceBettingPerForCombination,
                            totalOddValue,
                            originTotalOddValue,
                          } = virtualGameBettingLog
                          const reduceBettingValue =
                            realReduceBettingPerForSingleFolder || realReduceBettingPerForCombination

                          if (!reduceBettingValue) {
                            return <span>{totalOddValue}</span>
                          }

                          return (
                              <TooltipWrapper text="적용배당 / (원본배당 - 조정배당)" place="bottom">
                                {Number(reduceBettingValue) < 0 ? (
                                    <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                      {reduceBettingValue?.toFixed(2)})
                                  </span>
                                ) : (
                                    <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                      {reduceBettingValue?.toFixed(2)})
                                  </span>
                                )}
                              </TooltipWrapper>
                          )
                        })()}
                      </td>

                      <td>
                        <span style={{ color: 'blue' }}>
                          {virtualGameBettingLog.bettingExpectedAmount.toString().replace(commonReg2, ',')}
                        </span>
                        <span style={{ margin: '0 5px' }}>/</span>
                        <span style={{ color: 'red' }}>
                          {virtualGameBettingLog.bettingResultAmount.toString().replace(commonReg2, ',')}
                        </span>
                      </td>
                      <td>
                        <CustomButton type="button" bettingResult={virtualGameBettingLog.virtualResultStatus}>
                          {VirtualResultStatusEnums[virtualGameBettingLog.virtualResultStatus]}
                        </CustomButton>
                      </td>
                      <td>
                        {virtualGameBettingLog.showFlag ? (
                          <span>노출</span>
                        ) : (
                          <span style={{ color: 'red' }}>비노출</span>
                        )}
                      </td>
                    </tr>
                    {virtualGameBettingLog.isVisible && (
                      <VirtualDetailBettingLog
                        virtualDetailBettingLogList={virtualGameBettingLog.virtualDetailBettingLogList}
                      />
                    )}
                  </>
                )
              })}
            {virtualBettingLogList.length === 0 && (
              <tr>
                <td colSpan={15}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      color: '#000',
                      fontSize: '15px',
                      padding: '20px 0px',
                    }}
                  >
                    최근 게시물이 없습니다.
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <PaginationWrap>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </PaginationWrap>
      </SportBettingLogContentWrap>
    </>
  )
}

export default MemberVirtualGameBettingHistory

MemberVirtualGameBettingHistory.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const getColor2 = type => {
  switch (true) {
    case type === 'VIRTUAL_SOCCER':
      return '#f6a033'
    case type === 'VIRTUAL_BASKETBALL':
      return '#f44c32'
    case type === 'VIRTUAL_BASEBALL':
      return '#731cf7'
    case type === 'VIRTUAL_HORSE':
      return '#36bd29'
    case type === 'VIRTUAL_GREYHOUNDS':
      return '#24c1f0'
    default:
      return 'black'
  }
}

const MiniGameTypeSelectWrapper = styled.div`
  padding: 3px 0;
  margin-bottom: 5px;

  display: flex;
  gap: 5px;
`

const CustomTd = styled.td`
  color: #fff;
  font-weight: 600;
  background: ${props => getColor2(props.virtualGameSportType)} !important;
`
const SportBettingLogContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const CustomTh = styled.th`
  background: green !important;
`

const CustomTh2 = styled.th`
  background: rgb(0, 104, 86) !important;
`

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead`
  th {
    font-size: 12px;
  }
`

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
  font-size: 13px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  cursor: pointer;
  &:hover {
    border: solid 2px red;
  }
  background: lightgray;
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const SportsGameFolderEdgeBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderNumBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameDeleteBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFoldeShowBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
  color: ${props => (props.showStatus ? 'blue' : 'red')} !important;
`

const SportsGameFolderScoreBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderResultBox = styled.div`
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  border-right: 1px solid rgba(0, 0, 0, 1);
`
const SportsGameFolderContentCriteria = styled.div`
  font-weight: 500;
  color: green;
  width: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgba(0, 128, 0, 1) !important;
  opacity: 0.6;
  color: white !important;
`

const SportsGameFolderContentCriteria2 = styled.div`
  font-weight: 500;
  color: green;
  width: 7.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgb(0, 104, 86, 0.6) !important;
  color: white !important;
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 12%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGamePartnerBox = styled.div`
  width: 11%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameMoneyContent = styled.div`
  width: 9%;
  display: flex;
  // border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentTime = styled.div`
  width: 6%;
  display: flex;
  border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  height: 46px;
  border-left: solid 1px black;
`
