import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { RiCoupon2Line } from 'react-icons/ri'
import styled from 'styled-components'
import { CustomBtnTableWrap } from '../../../../shared/components/TableElements'
import { refreshUserSession } from '../../../../utils/api/memberManage/memberManageApi'

function MemberRefresh({ userId, fetchSearchMember }) {
  const [depositLoading, setDepositLoading] = useState(false)
  const onClickHandler = () => {
    if (depositLoading) return
    if (window.confirm(`${userId}회원 강제 새로고침 하시겠습니까?`)) {
      refreshUserSession({
        userId,
      })
        .then(res => {
          setDepositLoading(false)
          fetchSearchMember()
          alert(`${userId}님 강제 새로고침 됐습니다.`)
        })
        .catch(error => {
          setDepositLoading(false)
          fetchSearchMember()
          alert(`${userId}님 강제 새로고침 실패 했습니다.`)
        })
    }
  }

  return (
    <CustomBtnTableWrap>
      <button type="button" style={{ border: 'none', background: '#c47e45', color: 'white' }} onClick={onClickHandler}>
        새로고침
      </button>
    </CustomBtnTableWrap>
  )
}

export default MemberRefresh

MemberRefresh.propTypes = {
  userId: PropTypes.string.isRequired,
  fetchSearchMember: PropTypes.func.isRequired,
}

const CouponIcon = styled(RiCoupon2Line)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: #ff4500;
`
