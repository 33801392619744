import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {
  MemberPointDepositReceiverTypeEnumsSelectOptions,
  MemberPointSearchTypeEnumsSelectOptions,
} from '../../../../../enums/MemberHistory/MemberPointDepositEnums'
import { useSearchPartnerLosingPointDeposit } from '../../../../../utils/api/memberHistory/memberHistoryApi'
import { convertToKst } from '../../../../../utils/dateTime'
import PartnerLosingPointDepositListData from '../../../../Statistics/LosingSettlementHistory/PartnerLosingPointDepositListData'
import LosingSearchButton from '../../../../../shared/components/LosingSearchButton'

const MemberHistoryLosingPointDeposit = ({ memberId }) => {
  /**
    포인트 입금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [pointDepositType, setPointDepositType] = useState(null)
  const [params, setParams] = useState({
    memberId,
    pointDepositType: null,
    startDate: null,
    endDate: null,
    page,
    size,
    isExact: true,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      pointDepositType: !pointDepositType ? null : pointDepositType,
    }))
  }, [pointDepositType])

  const { totalElement, content } = useSearchPartnerLosingPointDeposit(params)

  const [pointDepositListData, setPointDepositListData] = useState(PartnerLosingPointDepositListData(content))

  useEffect(() => {
    setPointDepositListData(PartnerLosingPointDepositListData(content))
  }, [content])

  const [rows, setData] = useState(pointDepositListData.tableRowsData)
  useEffect(() => {
    setData(pointDepositListData.tableRowsData)
  }, [pointDepositListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      memberId,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }
  return (
    <>
      <LosingSearchButton
        pointDepositType={pointDepositType}
        onClickHandler={e => {
          setPointDepositType(e.target.id)
        }}
      />
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteStartDate}
                onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteEndDate}
                onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <ReactTableBase columns={pointDepositListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
    </>
  )
}

export default MemberHistoryLosingPointDeposit

MemberHistoryLosingPointDeposit.propTypes = {
  memberId: PropTypes.string.isRequired,
}
