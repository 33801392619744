import { ButtonToolbar } from '@/shared/components/Button'
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
  FormButtonToolbar,
} from '@/shared/components/form/FormElements'
import Error from '@/shared/components/form/Error'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorBackground,
  colorBlue,
  colorIcon,
  colorText,
  colorWhite,
  colorFieldsBorder,
  colorAccent,
} from '@/utils/palette'
import { Checkbox } from '@mui/material'
import Select from 'react-select'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Badge, Modal as BootstrapModal, Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { createDepositBonus } from '../../../utils/api/systemManage/depositWithdrawalRuleManageApi'
import { MemberDepositLevelEnum } from '../../../enums/MemberManage/MemberInfoEnums'
import { depositLevelBonusValidate } from '../../../utils/validate/systemManagement/levelManage'
import { DepositBounsTypeEnumsSelectOptions } from '../../../enums/MemberHistory/MemberDepositEnums'

function DepositBonusAddModal({
  depositLevel,
  nextOrderNum,
  depositBonusAddModalShow,
  onCloseDepositBonusAddModalHnadler,
  fetchSearchDepositBonus,
}) {
  const [apiLoading, setApiLoading] = useState(false)

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [isShow, setIsShow] = useState('')
  const [orderNum, setOrderNum] = useState(nextOrderNum)

  const [bonusTypeOptionValue, setBonusTypeOptionValue] = useState(null)
  const [bonusTypeOption, setBonusTypeOption] = useState(DepositBounsTypeEnumsSelectOptions())

  const onChangeBonusTypeHandler = option => {
    if (option) {
      setBonusTypeOptionValue(option)
    } else {
      setBonusTypeOptionValue(null)
    }
  }

  const onSubmit = () => {
    if (apiLoading) return

    setApiLoading(true)

    const body = {
      depositLevel,
      title,
      content,
      isShow: false,
      orderNum,
      bonusType: bonusTypeOptionValue.value,
      bonusTypeKr: bonusTypeOptionValue.label,
    }

    if (window.confirm('정말로 추가하시겠습니까?')) {
      createDepositBonus(body)
        .then(res => {
          alert('추가되었습니다.')
          setApiLoading(false)
          onCloseDepositBonusAddModalHnadler()
          fetchSearchDepositBonus()
        })
        .catch(error => {
          alert('실패했습니다.')
          fetchSearchDepositBonus()
          setApiLoading(false)
        })
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <StyledModal show={depositBonusAddModalShow} onHide={onCloseDepositBonusAddModalHnadler} $header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onCloseDepositBonusAddModalHnadler}
          />
          <ModalTitle>
            <span>충전 보너스 추가</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SportsMarketContentHeaderWrap>
            <SportsMarketContentHeader style={{ padding: '10px' }}>
              <span>
                {' '}
                * 새로 추가되는 데이터는 <span style={{ color: 'red' }}>감추기</span> 상태로 됩니다.
              </span>
            </SportsMarketContentHeader>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>입금 레벨</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>{MemberDepositLevelEnum[depositLevel]}</SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
          </SportsMarketContentHeaderWrap>
          <SportsMarketContentBodyWrap>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                title,
                content,
                isShow,
                orderNum,
                bonusTypeOptionValue,
              }}
              validate={depositLevelBonusValidate}
            >
              {({ handleSubmit, form: { reset } }) => (
                <SportsMarketFormContainer horizontal onSubmit={handleSubmit} style={{ flexDirection: 'column' }}>
                  <FormGroup>
                    <FormGroupLabel>제목</FormGroupLabel>
                    <FormGroupField>
                      <Field name="title">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={title}
                              onChange={e => {
                                setTitle(e.target.value)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>내용</FormGroupLabel>
                    <FormGroupField>
                      <Field name="content">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={content}
                              onChange={e => {
                                setContent(e.target.value)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>타입</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bonusTypeOptionValue">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <CustomSelect
                              {...input}
                              value={bonusTypeOptionValue}
                              onChange={option => {
                                onChangeBonusTypeHandler(option)
                              }}
                              options={bonusTypeOption}
                              placeholder="타입 선택"
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>순서</FormGroupLabel>
                    <FormGroupField>
                      <Field name="orderNum">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={orderNum}
                              onChange={e => {
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setOrderNum(onlyNumber)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormButtonToolbar style={{ marginLeft: '0' }}>
                    <Button variant="primary" type="submit">
                      추가하기
                    </Button>
                  </FormButtonToolbar>
                </SportsMarketFormContainer>
              )}
            </Form>
          </SportsMarketContentBodyWrap>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default DepositBonusAddModal

DepositBonusAddModal.propTypes = {
  depositLevel: PropTypes.string.isRequired,
  nextOrderNum: PropTypes.string.isRequired,
  depositBonusAddModalShow: PropTypes.bool,
  onCloseDepositBonusAddModalHnadler: PropTypes.func,
  fetchSearchDepositBonus: PropTypes.func,
}

DepositBonusAddModal.defaultProps = {
  depositBonusAddModalShow: false,
  onCloseDepositBonusAddModalHnadler: () => {},
  fetchSearchDepositBonus: () => {},
}

export const CustomButton = styled(Button)`
  width: 50px;
  height: 30px;
  font-size: 15px;
  padding: 0px;
  padding-top: 3px;
`

export const CustomInput = styled.input`
  border: none !important;
  height: 22px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
`

export const CustomFormInputWrap = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
`

const ErrorText = styled.span`
  font-size: 10px;
  font-weight: 650;
  line-height: 13px;
  color: red;
  margin-bottom: -5px;
  display: block;
  // margin-top: -10px;
`

const SportsMarketFormContainer = styled(FormContainer)`
  flex-direction: column;
  padding: 20px 0;
  // border: 1px solid gray;
`

const SportsMarketShowCheckbox = styled(Checkbox)`
  width: 10px;
  height: 10px;
`

const SportsMarketContentHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
`

const SportsMarketContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #888;
`

const SportsMarketContentHeaderLabel = styled.span`
  width: 30%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  border-right: 2px solid #888;
`

const SportsMarketContentHeaderValue = styled.span`
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
`

const SportsMarketContentBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 20px;
`

const SportsMarketNameWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* 오른쪽 끝으로 정렬 */

  width: 100%;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
  color: white;

  background-color: #888;
  border: 1px solid black;
`

const SportsMarketOddWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.name && `repeat(${props.name}, minmax(0, 1fr))`};
  &:hover {
    cursor: pointer;
    border: 2px solid red;
  }
`

const SportsMarketOddItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  font-size: 14px;
`

const SportsMarketExchangeItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  color: blue;
  font-weight: 500;
  font-size: 14px;
`

const SportsMarketOddItemTitle = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  background: #a6a6a6;
  color: white;
  font-size: 14px;
`

const StopBadge = styled(Badge)`
  width: 40px;
  height: 16px;
  background-color: #ff4861;
  font-weight: 500;
  border-radius: 7px;
  text-align: center;
  margin-left: 5px;
  span {
    width: 100%;
  }
`

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div`
  background-color: grey !important;
  font-weight: 700;
`
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 500px !important;
    margin-top: 100px;
  }

  .modal-content {
    height: max-content;
    min-height: 400px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion

const CustomSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      border-right: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
