import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const WithdrawalDeleteRadio = ({ rowInfo, setCheckRadio, checkRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        setCheckRadio(prev => [...prev, rowInfo.moneyWithdrawalKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== rowInfo.moneyWithdrawalKey))
        break
      default:
        break
    }
  }

  return (
    <div style={{ cursor: 'pointer' }}>
      <CustomInput
        style={{ width: '100%', cursor: 'pointer' }}
        checked={checkRadio.find(e => e === rowInfo.moneyWithdrawalKey)}
        type="checkbox"
        name="chech"
        onChange={e => approveHandler(e)}
      />
    </div>
  )
}
export default WithdrawalDeleteRadio

WithdrawalDeleteRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  rowInfo: PropTypes.shape({
    moneyWithdrawalKey: PropTypes.string,
  }).isRequired,
}

WithdrawalDeleteRadio.defaultProps = {
  checkRadio: [],
}

const CustomInput = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
