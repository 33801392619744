import React, { useState } from 'react'
import Scrollbar from 'react-smooth-scrollbar'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import SidebarContent from './SidebarContent'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { setHideSideBar } from '../../../redux/headerInfoSlice'

const Sidebar = ({ changeMobileSidebarVisibility, sidebarShow, collapse }) => {
  const dispatch = useDispatch()

  const {
    HONORLINK,
    GOLDLINK_SLOT,
    SLOT_CITY,
    SLOT_GATE,
    NEXUS,
    SWIX,
    REVOLUTION_HOLDEM,
    WILD_GAMES,
    hideSideBar,
    todayMemberDepositCount,
    todayMemberWithdrawalCount,
    todayPartnerDepositCount,
    todayPartnerWithdrawalCount,
    todayMemberDeposit,
    todayMemberWithdrawal,
    todayPartnerDeposit,
    todayPartnerWithdrawal,
    memberMoneyAmount,
    memberRollingPointAmount,
    partnerMoneyAmount,
    partnerRollingPointAmount,
    partnerLosingPointAmount,
    memberBettingAwaitingAmount,
    partnerBettingAwaitingAmount,
  } = useSelector(state => {
    const { headerInfo } = state

    return {
      HONORLINK: headerInfo.potInfo.HONORLINK,
      GOLDLINK_SLOT: headerInfo.potInfo.GOLDLINK_SLOT,
      SLOT_CITY: headerInfo.potInfo.SLOT_CITY,
      SLOT_GATE: headerInfo.potInfo.SLOT_GATE,
      NEXUS: headerInfo.potInfo.NEXUS,
      SWIX: headerInfo.potInfo.SWIX,
      REVOLUTION_HOLDEM: headerInfo.potInfo.REVOLUTION_HOLDEM,
      WILD_GAMES: headerInfo.potInfo.WILD_GAMES,
      hideSideBar: headerInfo.hideSideBar,
      todayMemberDeposit: headerInfo.todayMemberDeposit,
      todayMemberWithdrawal: headerInfo.todayMemberWithdrawal,
      todayPartnerDeposit: headerInfo.todayPartnerDeposit,
      todayPartnerWithdrawal: headerInfo.todayPartnerWithdrawal,
      memberMoneyAmount: headerInfo.memberMoneyAmount,
      memberRollingPointAmount: headerInfo.memberRollingPointAmount,
      partnerMoneyAmount: headerInfo.partnerMoneyAmount,
      partnerRollingPointAmount: headerInfo.partnerRollingPointAmount,
      partnerLosingPointAmount: headerInfo.partnerLosingPointAmount,
      todayMemberDepositCount: headerInfo.todayMemberDepositCount,
      todayMemberWithdrawalCount: headerInfo.todayMemberWithdrawalCount,
      todayPartnerDepositCount: headerInfo.todayPartnerDepositCount,
      todayPartnerWithdrawalCount: headerInfo.todayPartnerWithdrawalCount,
      memberBettingAwaitingAmount: headerInfo.memberBettingAwaitingAmount,
      partnerBettingAwaitingAmount: headerInfo.partnerBettingAwaitingAmount,
    }
  })

  return (
    <>
      {!hideSideBar ? (
        <SidebarWrap show={sidebarShow} collapse={collapse}>
          {/* <SidebarBack
        type="button"
        aria-label="sidebar visibility"
        onClick={changeMobileSidebarVisibility}
        show={sidebarShow}
      /> */}
          <SidebarScroll>
            <SidebarWrapper>
              <SidebarTopInfoBox>
                <SidebarTopInfo type="HONORLINK">
                  <span>HONOR 링크</span>
                  <strong>{HONORLINK?.toString().replace(commonReg2, ',')} 알</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="GOLDLINK_SLOT">
                  <span>골드 링크슬롯</span>
                  <strong>{GOLDLINK_SLOT?.toString().replace(commonReg2, ',')} 알</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="SLOT_CITY">
                  <span>슬롯시티</span>
                  <strong>{SLOT_CITY?.toString().replace(commonReg2, ',')} 알</strong>
                </SidebarTopInfo>
                {/* <SidebarTopInfo type="SLOT_GATE">
                  <span>슬롯게이트</span>
                  <strong>{SLOT_GATE?.toString().replace(commonReg2, ',')} 알</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="NEXUS">
                  <span>넥서스</span>
                  <strong>{NEXUS?.toString().replace(commonReg2, ',')} 알</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="SWIX">
                  <span>스윅스</span>
                  <strong>{SWIX?.toString().replace(commonReg2, ',')} 알</strong>
                </SidebarTopInfo> */}
                <SidebarTopInfo type="REVOLUTION_HOLDEM">
                  <span>레볼루션 홀덤</span>
                  <strong>{REVOLUTION_HOLDEM?.toString().replace(commonReg2, ',')} 원</strong>
                </SidebarTopInfo>
                <SidebarTopInfo type="WILD_GAMES">
                  <span>와일드게임즈</span>
                  <strong>{WILD_GAMES?.toString().replace(commonReg2, ',')} 원</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#81A9F0' }}>
                  <span>금일 회원 입금</span>
                  <strong>
                    {todayMemberDepositCount?.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberDeposit?.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#F08080' }}>
                  <span>금일 회원 출금</span>
                  <strong>
                    {todayMemberWithdrawalCount?.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayMemberWithdrawal?.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#81A9F0' }}>
                  <span>금일 파트너 입금</span>
                  <strong>
                    {todayPartnerDepositCount?.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerDeposit?.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#F08080' }}>
                  <span>금일 파트너 출금</span>
                  <strong>
                    {todayPartnerWithdrawalCount?.toString().replace(commonReg2, ',')}명 /{' '}
                    {todayPartnerWithdrawal?.toString().replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                {/* <SidebarTopInfo style={{ color: '#81A9F0' }}>
                  <span>금일 배팅</span>
                  <strong>{memberMoneyAmount.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: '#F08080' }}>
                  <span>금일 당첨</span>
                  <strong>{memberMoneyAmount.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo> */}
                <SidebarTopInfo style={{ color: '#F08080' }}>
                  <span>배팅대기금액</span>
                  <strong>
                    {(Number(memberBettingAwaitingAmount) + Number(partnerBettingAwaitingAmount))
                      ?.toString()
                      .replace(commonReg2, ',')}
                  </strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'greenyellow' }}>
                  <span>회원 보유액</span>
                  <strong>{memberMoneyAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'greenyellow' }}>
                  <span>회원 롤링액</span>
                  <strong>{memberRollingPointAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'yellow' }}>
                  <span>파트너 보유액</span>
                  <strong>{partnerMoneyAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'yellow' }}>
                  <span>파트너 롤링액</span>
                  <strong>{partnerRollingPointAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
                <SidebarTopInfo style={{ color: 'yellow' }}>
                  <span>파트너 루징액</span>
                  <strong>{partnerLosingPointAmount?.toString().replace(commonReg2, ',')}</strong>
                </SidebarTopInfo>
              </SidebarTopInfoBox>
              <SidebarContent
                onClick={e => {
                  // console.log('e', e)
                }}
                collapse={collapse}
              />
            </SidebarWrapper>
          </SidebarScroll>
        </SidebarWrap>
      ) : (
        <></>
      )}
    </>
  )
}

Sidebar.propTypes = {
  sidebarShow: PropTypes.bool.isRequired,
  collapse: PropTypes.bool.isRequired,
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
}

export default Sidebar

// region STYLES

const SidebarWrapper = styled.div`
  display: block;
`

const SidebarScroll = styled(Scrollbar)`
  width: 210px;

  & > div {
    height: calc(100vh - 110px);
  }

  .scrollbar-track {
    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
`

const SidebarWrap = styled.div`
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 210px;

  box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  transition: transform 0.3s;
  background: #303030;
  top: 0;
  padding-top: 80px;
  z-index: 99;
  display: block;
  transform: translateX(0);
`

const SidebarBack = styled.button`
  width: 100vw;
  position: fixed;
  display: ${props => (props.show ? 'block' : 'none')};
  background: transparent;
  // border: none;
  top: 60px;
  bottom: 0;
  border: solid 2px red;
  z-index: 300;

  // @media screen and (min-width: 576px) {
  //   display: none;
  // }
`

const getColor = type => {
  switch (true) {
    case type === 'HONORLINK':
      return '#ff4ddd'
    case type === 'GOLDLINK_SLOT':
      return '#07d2ed'
    case type === 'SLOT_CITY':
      return '#ffb269'
    case type === 'SLOT_GATE':
      return '#07d2ed'
    case type === 'NEXUS':
      return '#07d2ed'
    case type === 'SWIX':
      return '#07d2ed'
    case type === 'REVOLUTION_HOLDEM':
      return '#edacb1'
    case type === 'WILD_GAMES':
      return '#a3f08b'
    default:
      return '#fff'
  }
}

const SidebarTopInfo = styled.div`
  width: 100%;
  // height: 400px;

  color: ${props => getColor(props.type)};

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  span {
    font-size: 13px;
    font-weight: 700;
  }

  strong {
    font-size: 13px;
    float: right;
  }
`

const SidebarTopInfoBox = styled.div`
  width: 100%;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 15px 7px;
  display: flex;
  flex-direction: column;
`

// endregion
