import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { searchRealTimeMiniGameBetting } from '../../../utils/api/logManage/logApi'
import useAudio from '../../../utils/audioHook'
import RealTimeMiniBettingLogListData from './RealTimeMiniBettingLogListData'

const RealTimeMiniBettingLogList = () => {
  const [play, pause, paused, audioRef] = useAudio(`${process.env.PUBLIC_URL}/audio/betting.mp3`, {
    playbackRate: 1,
  })

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [miniGameBettingLogParams, setMiniGameBettingLogParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setMiniGameBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [miniGameTotalElement, setMiniGameTotalElement] = useState(0)
  const [miniGameTotalRealTimeCount, setMiniGameTotalRealTimeCount] = useState(0)
  const [miniGameContent, setMiniGameContent] = useState([])
  const [prevTotalElement, setPrevTotalElement] = useState(0)
  const [muteAudio, setMuteAudio] = useState(JSON.parse(sessionStorage.getItem('BTMA')))

  const onCheckMuteAudioHandler = e => {
    setMuteAudio(e.target.checked)
    // BTMA = Betting MiniGame Audio
    sessionStorage.setItem('BTMA', e.target.checked)
  }

  useEffect(() => {
    const fetchSearchRealTimeMiniGameBetting = async params => {
      await searchRealTimeMiniGameBetting(params).then(res => {
        setMiniGameTotalElement(res.data.totalElement)
        setMiniGameTotalRealTimeCount(res.data.totalRealTimeCount)
        setMiniGameContent(res.data.content)

        if (res.data.totalElement > prevTotalElement) {
          if (!muteAudio && res.data.totalRealTimeCount > 0 && paused()) {
            play()
          }
          setPrevTotalElement(res.data.totalElement)
        }
      })
    }

    fetchSearchRealTimeMiniGameBetting(miniGameBettingLogParams)

    const interval = setInterval(() => {
      fetchSearchRealTimeMiniGameBetting(miniGameBettingLogParams)
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [miniGameBettingLogParams, prevTotalElement, muteAudio])

  const [miniBettingLogListData, setMiniBettingLogListData] = useState(RealTimeMiniBettingLogListData(miniGameContent))
  useEffect(() => {
    setMiniBettingLogListData(RealTimeMiniBettingLogListData(miniGameContent))
  }, [miniGameContent])

  const [miniRows, setMiniRowsData] = useState(miniBettingLogListData.tableRowsData)
  useEffect(() => {
    setMiniRowsData(miniBettingLogListData.tableRowsData)
  }, [miniBettingLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const miniGameTableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement: miniGameTotalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  return (
    <>
      <HeaderWrap>
        <h2 style={{ color: 'orange' }}>미니게임 실시간 내역 ({miniGameTotalRealTimeCount || 0})</h2>
        <label
          htmlFor="miniGameCheck"
          style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '10px' }}
        >
          <input
            type="checkbox"
            id="miniGameCheck"
            checked={muteAudio}
            onChange={e => {
              onCheckMuteAudioHandler(e)
            }}
            style={{ marginRight: '10px' }}
          />
          <span style={{ color: 'red', fontWeight: '500', fontSize: '13px' }}>
            * 미니게임 음소거하려면 체크해주세요.
          </span>
        </label>
      </HeaderWrap>

      <ReactTableBase
        columns={miniBettingLogListData.tableHeaderData}
        data={miniRows}
        tableConfig={miniGameTableConfig}
      />
    </>
  )
}

export default RealTimeMiniBettingLogList

const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
