export const casinoVendorEnums = {
  HONOR_LINK: 'HONOR 링크',
  GOLD_LINK_SLOT: 'GOLD 링크 슬롯',
  SLOT_CITY: '슬롯시티',
}

export const CasinoVendorEnumsEnumsSelectOptions = () => {
  return Object.entries(casinoVendorEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
