import { MemberMoneyIncDecCard } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSearchSignInList } from '../../../../../utils/api/authManage/authManageApi'
import MemberIpLogListData from '../../MemberIPLog/MemberIpLogListData'

const MemberHistoryLogin = ({ memberId }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  /**
   * 회원 조회 - IP 조회
   */
  const [memberIpParams, setMemberIpParams] = useState({ memberId, page, size, isExact: true })

  useEffect(() => {
    setMemberIpParams(prev => ({
      ...prev,
      page,
      size,
      isExact: true,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchSignInList } = useSearchSignInList(memberIpParams)

  const [memberIpLogListData, setMemberIpLogListData] = useState(MemberIpLogListData(content))

  useEffect(() => {
    setMemberIpLogListData(MemberIpLogListData(content))
  }, [content])

  const [memberIpLogRows, setMemberIpLogRows] = useState(memberIpLogListData.tableRowsData)

  useEffect(() => {
    setMemberIpLogRows(memberIpLogListData.tableRowsData)
  }, [memberIpLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // IP
  const [searchLoginIp, setSearchLoginIp] = useState('')

  const onSearchLoginIpHandler = e => {
    setSearchLoginIp(e.target.value)
  }

  // 로그인일시 시작 + 끝
  const [searchLoginStartDate, setSearchLoginStartDate] = useState(null)
  const [searchLoginEndDate, setSearchLoginEndDate] = useState(null)

  const onSearchLoginStartDateChangeHandler = date => {
    setSearchLoginStartDate(date)
  }
  const onSearchLoginEndDateChangeHandler = date => {
    setSearchLoginEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMemberIpParams(prev => ({
      ...prev,
      page: 0,
      isExact: true,
      loginIp: !searchLoginIp ? null : searchLoginIp,
      startDate: !searchLoginStartDate ? null : convertToKst(searchLoginStartDate),
      endDate: !searchLoginEndDate ? null : convertToKst(searchLoginEndDate),
    }))
  }
  return (
    <>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>회원IP</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchValue"
                type="text"
                placeholder="검색 입력해주세요"
                value={searchLoginIp}
                onChange={e => onSearchLoginIpHandler(e)}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchLoginStartDate}
                onChange={date => onSearchLoginStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchLoginEndDate}
                onChange={date => onSearchLoginEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <ReactTableBase
        key="common"
        columns={memberIpLogListData.tableHeaderData}
        data={memberIpLogRows}
        tableConfig={tableConfig}
      />
    </>
  )
}

export default MemberHistoryLogin

MemberHistoryLogin.propTypes = {
  memberId: PropTypes.string.isRequired,
}
