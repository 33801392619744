import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import PopupModifyModal from './PopupModifyModal'
import { PopupTargetEnums, PopupStatusEnums } from '../../../enums/Operation/popupEnums'
import { convertToKstDate } from '../../../utils/dateTime'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const PopupListData = (content, fetchSearchPopupList) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '제목',
      accessor: 'title',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '도메인(비어있을 경우 모든 도메인 노출)',
      accessor: 'domainUrl',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '등록일',
      accessor: 'createAt',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <div>{convertToKstDate(original.createAt)}</div>
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return PopupStatusEnums[original.status]
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default PopupListData

PopupListData.propTypes = {
  fetchSearchPopupList: PropTypes.func.isRequired,
}
