import ReactTableBase from '@/shared/components/table/ReactTableBase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SportsDetailBettingInfoListData from './SportsDetailBettingInfoListData'

const SportsDetailBettingInfoContent = ({ content }) => {
  const [bettingLogListData, setBettingLogListData] = useState(SportsDetailBettingInfoListData(content))
  useEffect(() => {
    if (!content) return
    setBettingLogListData(SportsDetailBettingInfoListData(content))
  }, [content])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    if (!bettingLogListData) return
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  return (
    <SportsGameListHiddenWrap>
      <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} />
    </SportsGameListHiddenWrap>
  )
}

export default SportsDetailBettingInfoContent

SportsDetailBettingInfoContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape()),
}

SportsDetailBettingInfoContent.defaultProps = {
  content: [],
}

const CustomReactTableBase = styled(ReactTableBase)`
  background: red !important;
  table {
    thead th {
      background: black !important;
      color: #fff !important;
    }
  }
`

const SportsGameListHiddenWrap = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  th {
    background: black !important;
    color: #fff !important;
  }
  tbody {
    tr {
      background: #fbf7ea !important;
    }
  }
`
