import { createSlice } from '@reduxjs/toolkit'

const marketTypeList = {
  DOMESTIC: '국내형',
  OVERSEAS: '해외형',
  SPECIAL: '스페셜',
  LIVE: '라이브',
}

const marketTypeArray = Object.entries(marketTypeList).map(([key, value]) => ({
  sportMarketTypeId: key,
  sportMarketTypeName: value,
}))

const SportMarketTypeInfoSlice = createSlice({
  name: 'sportMarketTypeInfo',
  initialState: {
    sportMarketTypeMenu: marketTypeArray,
    activeSportMarketTypeMenu: marketTypeArray.length > 0 ? marketTypeArray[0].sportMarketTypeId : -1,
  },
  reducers: {
    setActiveSportMarketTypeMenu: (state, action) => {
      state.activeSportMarketTypeMenu = action.payload
    },
  },
})

export const { setActiveSportMarketTypeMenu } = SportMarketTypeInfoSlice.actions

export default SportMarketTypeInfoSlice.reducer

// sportMarketTypeId
