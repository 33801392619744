import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 블랙리스트 등록
 */
export async function createBlackMember(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/black',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 악성유저 조회 조회
 */
export async function searchBadMember(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/bad-black',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchBadMember(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchBadMember() {
    await searchBadMember(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchBadMember()
  }, [params])

  return { totalElement, content, fetchSearchBadMember }
}

/**
 * 블랙리스트 조회
 */
export async function searchBlackMember(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/black',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchBlackMember(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchBlackMember() {
    await searchBlackMember(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchBlackMember()
  }, [params])

  return { totalElement, content, fetchSearchBlackMember }
}
