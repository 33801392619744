import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { BonusStatusEnumsRadioOptions, MoneySortEnums } from '../../enums/MemberHistory/MemberDepositEnums'
import { MemberCasinoLevelEnum } from '../../enums/MemberManage/MemberInfoEnums'
import { fetchHeaderAlarmInfoAction } from '../../redux/headerInfoSlice'
import Loading from '../../shared/components/Loading'
import {
  searchMemberMoneyWithdrawalInfoV2,
  updateMemberMoneyDepositStatusV2,
} from '../../utils/api/memberHistory/memberHistoryApi'
import { commonReg2 } from '../../utils/validate/commonValidate'

function MoneyDepositStatusFormV2({
  applyStatus,
  memberId,
  moneyDepositKey,
  applyMoney,
  bonusPer,
  applyBonusType,
  applyDepositType,
  setCheckDepositApprove,
  setMoneyInfoModalShow,
  fetchSearchMemberMoneyDeposit,
  bonusTitle,
  bonusContent,
  moneySort,
}) {
  const dispatch = useDispatch()
  const [content, setContent] = useState({})
  const [bonusMoney, setBonusMoney] = useState(0)
  const [loading, setLoading] = useState(false)
  const [memo, setMemo] = useState(null)
  const [newBounsPer, setNewBonusPer] = useState(bonusPer)
  const [newApplyDepositType, setNewApplyDepositType] = useState(applyDepositType)
  const [newApplyBonusType, setNewApplyBonusType] = useState(applyBonusType)

  const fetchSearchMoneyInfo = async () => {
    setLoading(true)
    searchMemberMoneyWithdrawalInfoV2({
      memberId,
      moneyDepositKey,
    })
      .then(res => {
        setContent(res.data)
        setBonusMoney(res.data.bonusMoney)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  const getStatusText = status => {
    if (status === 'APPROVE') return '입금 최종승인'
    if (status === 'CANCEL') return '입금 최종취소'
    return ''
  }

  const getMoneySortText = moneySort => {
    if (moneySort === 'NORMAL_MONEY')
      return (
        <button type="button" style={{ background: '#7d2424', border: 'none', color: '#fff', fontWeight: '900' }}>
          {MoneySortEnums.NORMAL_MONEY}
        </button>
      )
    if (moneySort === 'HOLDEM_MONEY')
      return (
        <button type="button" style={{ background: '#276687', border: 'none', color: '#fff', fontWeight: '900' }}>
          {MoneySortEnums.HOLDEM_MONEY}
        </button>
      )
    return (
      <button type="button" style={{ background: '#7d2424', border: 'none', color: '#fff', fontWeight: '900' }}>
        {MoneySortEnums.NORMAL_MONEY}
      </button>
    )
  }

  useEffect(() => {
    if (!memberId) return
    fetchSearchMoneyInfo()
  }, [memberId])

  const [depositLoading, setDepositLoading] = useState(false)
  const onSubmit = e => {
    if (depositLoading) return
    if (loading) return
    if (Object.keys(content).length === 0) return
    if (window.confirm(`${memberId}님의 ${getStatusText(applyStatus)}처리 하시겠습니까?`)) {
      setDepositLoading(true)
      updateMemberMoneyDepositStatusV2({
        moneyDepositKey,
        memberId,
        status: applyStatus,
        bonusPer: newBounsPer,
        bonusMoney: applyStatus === 'APPROVE' ? bonusMoney : 0,
        memo: !e.memo ? null : e.memo,
      })
        .then(() => {
          dispatch(fetchHeaderAlarmInfoAction())
          alert(`${memberId}님의 ${getStatusText(applyStatus)} 처리 되었습니다.`)
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          switch (errorCode) {
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            case 'MONEY-2003':
              alert('해당 입금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
              break
            default:
              alert(`${memberId}님의 ${getStatusText(applyStatus)} 처리가 실패했습니다.`)
          }
        })
        .finally(() => {
          setDepositLoading(false)
          setCheckDepositApprove('')
          setMoneyInfoModalShow(false)
          fetchSearchMemberMoneyDeposit()
        })
    }
  }

  return (
    <MemberInfoWrap>
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              <table>
                <tbody>
                  <tr>
                    <th>아이디</th>
                    <td colSpan={2}>{content?.memberId}</td>
                    <th>회원명</th>
                    <td colSpan={2}>{content?.memberName}</td>
                  </tr>
                  <tr>
                    <th>은행</th>
                    <td colSpan={2}>{content?.bankName}</td>
                    <th>계좌번호</th>
                    <td colSpan={2}>{content?.accountNum}</td>
                  </tr>
                  <tr>
                    <th>상위파트너명</th>
                    <td colSpan={2}>{content?.topPartnerName}</td>
                    <th>파트너 정산방식</th>
                    <td colSpan={2}>{content?.topPartnerSettlementType}</td>
                  </tr>
                  <tr>
                    <th>입금레벨</th>
                    <td colSpan={5}>{MemberCasinoLevelEnum[content?.depositLevel?.depositLevelName]}</td>
                  </tr>
                  <tr>
                    <th style={{ color: 'yellow' }}>현재 지갑머니</th>
                    <td colSpan={1}>{content?.moneyAmount?.toString().replace(commonReg2, ',')}</td>
                    <th style={{ color: 'yellow' }}>현재 카지노머니</th>
                    <td colSpan={1}>{content?.casinoMoneyAmount?.toString().replace(commonReg2, ',')}</td>
                    <th style={{ color: 'yellow' }}>현재 홀덤머니</th>
                    <td colSpan={1}>{content?.holdemMoneyAmount?.toString().replace(commonReg2, ',')}</td>
                  </tr>
                  <tr>
                    <th style={{ color: 'pink' }}>금일 입금횟수</th>
                    <td>
                      {content?.dailyBettingWinningModel?.dailyMoneyDepositCount?.toString().replace(commonReg2, ',')}
                    </td>
                    <th style={{ color: 'pink' }}>금일 입금총액</th>
                    <td>
                      {' '}
                      {content?.dailyBettingWinningModel?.dailyMoneyDepositAmount?.toString().replace(commonReg2, ',')}
                    </td>
                    <th style={{ color: 'pink' }}>금일 보너스총액</th>
                    <td>
                      {' '}
                      {content?.dailyBettingWinningModel?.dailyMoneyBonusAmount?.toString().replace(commonReg2, ',')}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ color: 'lightblue' }}>금일 출금횟수</th>
                    <td colSpan={2}>
                      {content?.dailyBettingWinningModel?.dailyMoneyWithdrawlCount?.toString().replace(commonReg2, ',')}
                    </td>
                    <th style={{ color: 'lightblue' }}>금일 출금총액</th>
                    <td colSpan={2}>
                      {' '}
                      {content?.dailyBettingWinningModel?.dailyMoneyWithdrawalAmount
                        ?.toString()
                        .replace(commonReg2, ',')}
                    </td>
                  </tr>
                </tbody>
              </table>
              {applyStatus === 'APPROVE' ? (
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                  <p>
                    * 변경 입금 보너스% 선택 시 지급되는 &nbsp;
                    <span style={{ color: 'red', fontWeight: '600' }}>보너스액</span>은 변경됩니다.
                  </p>
                </div>
              ) : (
                <></>
              )}

              <BounsWrap style={{ marginTop: '10px' }}>
                {applyStatus === 'APPROVE' ? (
                  <>
                    <div style={{ fontSize: '15px', fontWeight: '500' }}>입금 보너스% 선택변경</div>
                    <FormGroup>
                      {BonusStatusEnumsRadioOptions().map(item => (
                        <FormGroupField
                          key={`index_${item.label}`}
                          style={{
                            display: 'inline-block',
                            width: 'max-content',
                            margin: '0px',
                            paddingLeft: '10px',
                          }}
                        >
                          <Field
                            name={`${item.name}`}
                            onCustomChange={e => {
                              const per = Number(e)
                              if (per === 0) {
                                // 보너스 기존대로
                                setNewBonusPer(bonusPer)
                                setBonusMoney(content?.bonusMoney)
                              } else if (per === 1) {
                                // 보너스 0으로
                                setNewBonusPer(0)
                                setBonusMoney(0)
                              } else {
                                const newBonus = (per / 100) * Number(applyMoney)
                                if (newBonus > Number(content?.surpriseMaximumAmount)) {
                                  setNewBonusPer(per)
                                  setBonusMoney(content?.surpriseMaximumAmount)
                                } else {
                                  setNewBonusPer(per)
                                  setBonusMoney((per / 100) * Number(applyMoney))
                                }
                              }
                            }}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={item.initialValue}
                          />
                        </FormGroupField>
                      ))}
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}
                <div style={{ fontSize: '15px', fontWeight: '500', color: 'black' }}>
                  보너스 선택 :
                  <span style={{ color: 'purple', fontSize: '15px' }}>
                    &apos; {content?.bonusTitle} - {content?.bonusContent} &apos;
                  </span>
                </div>
                <div style={{ display: 'flex' }}>
                  <div
                    style={{
                      fontSize: '15px',
                      fontWeight: '500',
                      color: 'green',
                      marginRight: '10px',
                    }}
                  >
                    일반/홀덤: {getMoneySortText(moneySort)}
                  </div>
                  <div
                    style={{
                      fontSize: '15px',
                      fontWeight: '500',
                      color: 'red',
                    }}
                  >
                    최종 입금신청액: {applyMoney?.toString().replace(commonReg2, ',')}원
                  </div>
                  <div
                    style={{
                      marginLeft: '20px',
                      fontSize: '15px',
                      fontWeight: '500',
                      color: 'blue',
                    }}
                  >
                    최종 보너스액: {bonusMoney?.toString().replace(commonReg2, ',')}원
                  </div>
                </div>
              </BounsWrap>
              <FormGroup>
                <CustomFormGroupLabel>메모</CustomFormGroupLabel>
                <FormGroupField>
                  <Field name="memo" component="input" type="text" placeholder="메모 입력 시, 간단히 입력하세요" />
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar style={{ margin: '0', marginTop: '30px' }}>
                <Button variant="danger" type="submit">
                  {getStatusText(applyStatus)}
                </Button>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      )}
    </MemberInfoWrap>
  )
}

export default MoneyDepositStatusFormV2

MoneyDepositStatusFormV2.propTypes = {
  applyStatus: PropTypes.string.isRequired,
  memberId: PropTypes.string.isRequired,
  moneyDepositKey: PropTypes.string.isRequired,
  applyMoney: PropTypes.string.isRequired,
  bonusPer: PropTypes.string.isRequired,
  applyBonusType: PropTypes.string.isRequired,
  applyDepositType: PropTypes.string.isRequired,
  setCheckDepositApprove: PropTypes.func.isRequired,
  setMoneyInfoModalShow: PropTypes.func.isRequired,
  fetchSearchMemberMoneyDeposit: PropTypes.func.isRequired,
  bonusTitle: PropTypes.string.isRequired,
  bonusContent: PropTypes.string.isRequired,
  moneySort: PropTypes.string.isRequired,
}

const CustomFormGroupLabel = styled(FormGroupLabel)`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-right: -20px !important;
  height: 30px;
  background: #3c3c3c;
  color: red;
  font-weight: 600;
  font-size: 15px;
`

const BounsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  align-items: center;
  color: blue;
`
const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 400px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: #3c3c3c !important;
    padding: 10px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
