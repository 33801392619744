import React from 'react'
import UrlModifyModal from './UrlModifyModal'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const UrlListData = (content, fetchSearchUrls) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '클라이언트 접속 URL',
      accessor: 'domainUrl',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row

        return (
          <span
            onClick={() => {
              window.open(`http://${original.domainUrl}`, '_blank')
            }}
            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {original.domainUrl}
          </span>
        )
      },
    },
    {
      Header: '파트너 ID(명)',
      accessor: 'patnerInfo',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.partnerId !== '') {
          return (
            <span
              onClick={() => {
                window.open(`/user-info/${original.partnerId}`, '_blank')
              }}
              style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
            >
              {original.partnerId}({original.partnerName})
            </span>
          )
        }
        return <span style={{ fontWeight: '500', color: 'black' }}>-</span>
      },
    },
    {
      Header: '파트너 추천코드',
      accessor: 'recommendedCode',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row

        return <span style={{ fontWeight: '500', color: 'black' }}>{original.recommendedCode || '-'}</span>
      },
    },
    {
      Header: '사용 여부',
      accessor: 'isUsed',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.isUsed) {
          return (
            <button type="button" style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '900' }}>
              사용중
            </button>
          )
        }
        return (
          <button type="button" style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '900' }}>
            미사용
          </button>
        )
      },
    },
    {
      Header: '설정',
      accessor: 'setting',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <UrlModifyModal urlInfo={original} fetchSearchUrls={fetchSearchUrls} />
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default UrlListData
