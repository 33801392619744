import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ko } from 'date-fns/esm/locale'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import {
  searchMemberDateMoneyInfo,
  searchMemberMoneyWithdrawalInfo,
} from '../../../utils/api/memberHistory/memberHistoryApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { convertToKst } from '../../../utils/dateTime'

function MemberWithdrawalInfoForm({ memberId, onClickHandler }) {
  const [content, setContent] = useState({})
  const [dateContent, setDateContent] = useState({})
  const now = new Date()
  const currentYear = now.getFullYear()
  const currentMonth = now.getMonth()
  // Set searchFirst to the 1st day of the current month at 00:00:00
  const searchFirst = new Date(currentYear, currentMonth, 1, 0, 0, 0)

  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1
  const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear
  const searchEnd = new Date(nextYear, nextMonth, 1, 0, 0, 0)

  const [bettingLogparams, setBettingLogParams] = useState({
    memberId,
    startDate: convertToKst(searchFirst),
    endDate: convertToKst(searchEnd),
  })
  const [searchStartDate, setSearchStartDate] = useState(searchFirst)
  const [searchEndDate, setSearchEndDate] = useState(searchEnd)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const fetchSearchMoneyInfo = async () => {
    searchMemberMoneyWithdrawalInfo({
      memberId,
    }).then(res => {
      setContent(res.data)
    })
  }

  const [loading, setLoading] = useState(false)

  const fetchSearchDateMoneyInfo = async () => {
    if (loading) return
    setLoading(true)
    searchMemberDateMoneyInfo(bettingLogparams)
      .then(res => {
        setDateContent(res.data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMoneyInfo()
    fetchSearchDateMoneyInfo()
  }, [memberId])

  useEffect(() => {
    fetchSearchDateMoneyInfo()
  }, [bettingLogparams])

  const onSearchClickHandler = () => {
    if (!searchStartDate || !searchEndDate) {
      alert('기간 설정은 필수 입니다.')
      return
    }
    setBettingLogParams(prev => ({
      ...prev,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  const getResultAmount = (num1, num2) => {
    const resultAmount = num1 - num2

    if (resultAmount > 0) {
      return <td style={{ color: 'blue', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
    }
    if (resultAmount < 0) {
      return <td style={{ color: 'red', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
    }
    return <td style={{ color: 'black', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</td>
  }

  return (
    <MemberInfoWrap>
      <MemberInfoHeader>
        [{content?.memberName}] 회원의 <span style={{ color: 'red', fontWeight: '600' }}>최근 한달</span> 내역입니다.
      </MemberInfoHeader>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-30px', marginBottom: '10px' }}>
          <p>
            * 아래 금일 및 기간내의 카지노 배팅액/당첨액은{' '}
            <span style={{ fontWeight: 'bolder', color: 'red' }}>타이제외, 공배팅 포함</span> 한 금액입니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <table>
        <tbody>
          <tr>
            <th colSpan={2}>아이디</th>
            <td colSpan={3}>{content?.memberId}</td>
            <th colSpan={2}>회원명</th>
            <td colSpan={2}>{content?.memberName}</td>
          </tr>
          <tr>
            <th colSpan={2} style={{ color: 'yellow' }}>
              금일 입금액
            </th>
            <td>{content?.dailyMoneyDepositAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'yellow' }}>
              금일 출금액
            </th>
            <td>{content?.dailyMoneyWithdrawalAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'yellow' }}>
              금일 입출금 차액
            </th>
            {getResultAmount(Number(content?.dailyMoneyDepositAmount), Number(content?.dailyMoneyWithdrawalAmount))}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 스포츠 배팅액
            </th>
            <td>{content?.dailySportsBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 스포츠 당첨액
            </th>
            <td> {content?.dailySportsWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 스포츠 수익
            </th>
            {getResultAmount(Number(content?.dailySportsBettingAmount), Number(content?.dailySportsWinningAmount))}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 라이브카지노 배팅액
            </th>
            <td>{content?.dailyLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 라이브카지노 당첨액
            </th>
            <td> {content?.dailyLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 라이브카지노 수익
            </th>
            {getResultAmount(
              Number(content?.dailyLiveCasinoBettingAmount),
              Number(content?.dailyLiveCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 슬롯카지노 배팅액
            </th>
            <td>{content?.dailySlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 슬롯카지노 당첨액
            </th>
            <td> {content?.dailySlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 슬롯카지노 수익
            </th>
            {getResultAmount(
              Number(content?.dailySlotCasinoBettingAmount),
              Number(content?.dailySlotCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 미니게임 배팅액
            </th>
            <td>{content?.dailyMiniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 미니게임 당첨액
            </th>
            <td> {content?.dailyMiniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 미니게임 수익
            </th>
            {getResultAmount(Number(content?.dailyMiniGameBettingAmount), Number(content?.dailyMiniGameWinningAmount))}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 홀덤 배팅액
            </th>
            <td>{content?.dailyHoldemBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 홀덤 당첨액
            </th>
            <td> {content?.dailyHoldemWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'yellow' }}>금일</span> 홀덤 수익
            </th>
            {getResultAmount(Number(content?.dailyHoldemBettingAmount), Number(content?.dailyHoldemWinningAmount))}
          </tr>
          <tr>
            <td colSpan={9} />
          </tr>
          <tr>
            <th colSpan={2} style={{ color: 'pink' }}>
              한달 입금액
            </th>
            <td>{content?.monthMoneyDepositAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'pink' }}>
              한달 출금액
            </th>
            <td>{content?.monthMoneyWithdrawalAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'pink' }}>
              한달 입출금 차액
            </th>
            {getResultAmount(Number(content?.monthMoneyDepositAmount), Number(content?.monthMoneyWithdrawalAmount))}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 스포츠 배팅액
            </th>
            <td>{content?.monthSportsBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 스포츠 당첨액
            </th>
            <td> {content?.monthSportsWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 스포츠 수익
            </th>
            {getResultAmount(Number(content?.monthSportsBettingAmount), Number(content?.monthSportsWinningAmount))}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 라이브카지노 배팅액
            </th>
            <td>{content?.monthLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 라이브카지노 당첨액
            </th>
            <td> {content?.monthLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 라이브카지노 수익
            </th>
            {getResultAmount(
              Number(content?.monthLiveCasinoBettingAmount),
              Number(content?.monthLiveCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 슬롯카지노 배팅액
            </th>
            <td>{content?.monthSlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 슬롯카지노 당첨액
            </th>
            <td> {content?.monthSlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 슬롯카지노 수익
            </th>
            {getResultAmount(
              Number(content?.monthSlotCasinoBettingAmount),
              Number(content?.monthSlotCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 미니게임 배팅액
            </th>
            <td>{content?.monthMiniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 미니게임 당첨액
            </th>
            <td> {content?.monthMiniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 미니게임 수익
            </th>
            {getResultAmount(Number(content?.monthMiniGameBettingAmount), Number(content?.monthMiniGameWinningAmount))}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 홀덤 배팅액
            </th>
            <td>{content?.monthHoldemBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 홀덤 당첨액
            </th>
            <td> {content?.monthHoldemWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>한달</span> 홀덤 수익
            </th>
            {getResultAmount(Number(content?.monthHoldemBettingAmount), Number(content?.monthHoldemWinningAmount))}
          </tr>
        </tbody>
      </table>
      <MemberInfoHeader style={{ marginTop: '20px' }}>
        [{content?.memberName}] 회원의 <span style={{ color: 'red', fontWeight: '600' }}>기간내 </span> 내역입니다.
      </MemberInfoHeader>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-30px', marginBottom: '10px' }}>
          <p>
            * 검색 시 <span style={{ fontWeight: 'bolder', color: 'red' }}>기간 설정</span>은 필수입니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <table>
        <tbody>
          <tr>
            <td colSpan={9} />
          </tr>
          <tr>
            <th colSpan={2} style={{ color: 'lightblue' }}>
              기간내 입금액
            </th>
            <td>{dateContent?.dateMoneyDepositAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'lightblue' }}>
              기간내 출금액
            </th>
            <td>{dateContent?.dateMoneyWithdrawalAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2} style={{ color: 'lightblue' }}>
              기간내 입출금 차액
            </th>
            {getResultAmount(
              Number(dateContent?.dateMoneyDepositAmount),
              Number(dateContent?.dateMoneyWithdrawalAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 스포츠 배팅액
            </th>
            <td>{dateContent?.dateSportsBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 스포츠 당첨액
            </th>
            <td> {dateContent?.dateSportsWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 스포츠 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateSportsBettingAmount),
              Number(dateContent?.dateSportsWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 라이브카지노 배팅액
            </th>
            <td>{dateContent?.dateLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 라이브카지노 당첨액
            </th>
            <td> {dateContent?.dateLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 라이브카지노 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateLiveCasinoBettingAmount),
              Number(dateContent?.dateLiveCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 슬롯카지노 배팅액
            </th>
            <td>{dateContent?.dateSlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 슬롯카지노 당첨액
            </th>
            <td> {dateContent?.dateSlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 슬롯카지노 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateSlotCasinoBettingAmount),
              Number(dateContent?.dateSlotCasinoWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 미니게임 배팅액
            </th>
            <td>{dateContent?.dateMiniGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 미니게임 당첨액
            </th>
            <td> {dateContent?.dateMiniGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'lightblue' }}>기간내</span> 미니게임 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateMiniGameBettingAmount),
              Number(dateContent?.dateMiniGameWinningAmount),
            )}
          </tr>
          <tr>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>기간내</span> 홀덤 배팅액
            </th>
            <td>{dateContent?.dateHoldemBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>기간내</span> 홀덤 당첨액
            </th>
            <td> {dateContent?.dateHoldemWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th colSpan={2}>
              <span style={{ color: 'pink' }}>기간내</span> 홀덤 수익
            </th>
            {getResultAmount(
              Number(dateContent?.dateHoldemBettingAmount),
              Number(dateContent?.dateHoldemWinningAmount),
            )}
          </tr>
        </tbody>
      </table>
    </MemberInfoWrap>
  )
}

export default MemberWithdrawalInfoForm

MemberWithdrawalInfoForm.propTypes = {
  memberId: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 320px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: #3c3c3c !important;
    padding: 10px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;
    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const MemberInfoHeader = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`
