import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
  MiniGameBettingResultEnums,
} from '../../../enums/Log/miniGameBettingEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MiniGameResultListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '날짜',
      accessor: 'date',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '게임종류',
      accessor: 'miniGameType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MiniGameTypeEnums[original?.miniGameType]
      },
    },
    {
      Header: '회차',
      accessor: 'round',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '실제 결과',
      accessor: 'realResult',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '가공된 결과',
      accessor: 'makedResult',
      disableGlobalFilter: true,
      width: 65,
    },

    {
      Header: '생성일',
      accessor: 'createAt',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original?.createAt)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MiniGameResultListData
