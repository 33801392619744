import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  CustomStyledModal,
  FriendLinkCustomStyledModal,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import {
  downloadMemberList,
  searchMember,
  updateMemberRegistrationStatus,
} from '@/utils/api/memberManage/memberManageApi'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { MemberModifyStatusEnums, MemberStatusEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import Loading from '../../../shared/components/Loading'
import { decodeAccessToken } from '../../../utils/token'
import MemberManageMentTabs from '../MemberManageMentTabs'
import PartnerNoteForm from '../PartnerNote/PartnerNoteForm'
import DuplicationIpHistory from './Duplication/DuplicationIpHistory'
import FriendLinkForm from './FriendLinkModal/FriendLinkForm'
import MemberHistoryMoneyIncDec from './MemberInfo/MemberMoneyIncDec/MemberHistoryMoneyIncDec'
import MemberRollingInfoPage from './MemberInfo/MemberRollingInfo/MemberRollingInfoPage'
import MemberListData from './MemberListData'
import MemberNoteForm from './MemberNote/MemberNoteForm'

const MemberList = () => {
  const dispatch = useDispatch()
  const [memberStatusLoading, setMemberStatusLoading] = useState(false)

  const authority = decodeAccessToken()?.adminAuthority
  /**
   * 회원 조회
   */
  const [selectedEdgeName, setSelectedEdgeName] = useState(null)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)
  const [whatSearch, setWhatSearch] = useState(null)
  const [currentUserSearch, setCurrentUserSearch] = useState(false)
  const [currentGameUserSearch, setCurrentGameUserSearch] = useState(false)
  const [testUserSearch, setTestUserSearch] = useState(false)
  // 사이트명
  const [searchStatus, setSearchStatus] = useState('')

  const [params, setParams] = useState({
    page,
    size,
    edgeName: null,
    searchStatus: null,
    whatSearch: null,
    currentUserSearch,
    currentGameUserSearch,
    testUserSearch,
    authority: null,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      whatSearch,
    }))
  }, [whatSearch])

  useEffect(() => {
    if (currentUserSearch) {
      setCurrentGameUserSearch(false)
      setTestUserSearch(false)
    }
    setParams(prev => ({
      page: 0,
      size: 15,
      edgeName: null,
      searchStatus: null,
      whatSearch: null,
      memberId: null,
      friendUserId: null,
      topPartnerName: null,
      currentGameUserSearch: false,
      testUserSearch: false,
      currentUserSearch,
    }))
  }, [currentUserSearch])

  useEffect(() => {
    if (currentGameUserSearch) {
      setCurrentUserSearch(false)
      setTestUserSearch(false)
    }
    setParams(prev => ({
      page: 0,
      size: 15,
      edgeName: null,
      searchStatus: null,
      whatSearch: null,
      memberId: null,
      friendUserId: null,
      topPartnerName: null,
      currentUserSearch: false,
      testUserSearch: false,
      currentGameUserSearch,
    }))
  }, [currentGameUserSearch])

  useEffect(() => {
    if (testUserSearch) {
      setCurrentGameUserSearch(false)
      setCurrentUserSearch(false)
    }
    setParams(prev => ({
      page: 0,
      size: 15,
      edgeName: null,
      searchStatus: null,
      whatSearch: null,
      memberId: null,
      friendUserId: null,
      topPartnerName: null,
      currentGameUserSearch: false,
      currentUserSearch: false,
      testUserSearch,
    }))
  }, [testUserSearch])

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const [searchStatusToggle, setSearchStatusToggle] = useState(false)

  const onChangeSearchStatusHandler = status => {
    setSearchStatusOptionValue(status)
    setSearchStatusToggle(prev => !prev)
  }

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      searchStatus: searchStatusOptionValue,
    }))
  }, [searchStatusToggle])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)
  const fetchSearchMember = async () => {
    if (loading) return
    setLoading(true)
    await searchMember(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
    setLoading(false)
  }

  useEffect(() => {
    fetchSearchMember()
  }, [params])

  const [isModalShow, setModalShow] = useState(false)
  const onModalClickHandler = () => {
    setModalShow(prev => !prev)
  }

  const [whatModalShow, setWhatModalShow] = useState(false)
  const onWhatModalClickHandler = what => {
    setWhatModalShow(what)
  }
  const [isModalUserId, setModalUserId] = useState('')

  // 가입 승인 버튼
  const [registrationApprove, setRegistrationApprove] = useState('')
  const [memberListData, setMemberListData] = useState(
    MemberListData(content, setRegistrationApprove, fetchSearchMember, setModalShow, setWhatModalShow, setModalUserId),
  )

  useEffect(() => {
    setMemberListData(
      MemberListData(
        content,
        setRegistrationApprove,
        fetchSearchMember,
        setModalShow,
        setWhatModalShow,
        setModalUserId,
      ),
    )
  }, [content])

  const [rows, setData] = useState(memberListData.tableRowsData)

  useEffect(() => {
    setData(memberListData.tableRowsData)
  }, [memberListData])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: selectedEdgeName,
    }))
  }, [selectedEdgeName])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 추천인
  const [searchFriendUserId, setSearchFriendUserId] = useState('')

  const onSearchFriendUserIdHandler = e => {
    setSearchFriendUserId(e.target.value)
  }

  // 매장명
  const [searchTopPartnerName, setSearchTopPartnerName] = useState('')

  const onSearchTopPartnerNameHandler = e => {
    setSearchTopPartnerName(e.target.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: selectedEdgeName,
      memberId: !searchMemberId ? null : searchMemberId,
      friendUserId: !searchFriendUserId ? null : searchFriendUserId,
      topPartnerName: !searchTopPartnerName ? null : searchTopPartnerName,
      searchStatus: !searchStatusOptionValue ? null : searchStatusOptionValue,
    }))
  }

  // 전체 쪽지 모달
  const [isTotalNoteModalShow, setTotalNoteModalShow] = useState(false)

  const onTotalNoteClickHandler = () => {
    setTotalNoteModalShow(prev => !prev)
  }

  // 추천인 링크 모달
  const [isFriendLinkModalShow, setFriendLinkModalShow] = useState(false)

  const createFriendLinkClickHandler = () => {
    setFriendLinkModalShow(prev => !prev)
  }

  // 대기 버튼
  const onWaitingRegistrationHandler = () => {
    if (memberStatusLoading) return
    switch (MemberStatusEnums[registrationApprove.status]) {
      case '신청':
        if (window.confirm(`${registrationApprove.memberId} 회원을 가입 대기처리 하시겠습니까?`)) {
          setMemberStatusLoading(true)
          updateMemberRegistrationStatus({
            memberKey: registrationApprove.userKey,
            status: 'WAITING',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입이 대기 처리 되었습니다.`)
              setRegistrationApprove('')
              fetchSearchMember()
            })
            .catch(error => {
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입 대기 처리가 실패했습니다.`)
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 회원은 신청 상태가 아니므로 대기 처리가 불가합니다.')
        fetchSearchMember()
        break
    }
  }

  // 승인 버튼
  const onApproveRegistrationHandler = () => {
    if (memberStatusLoading) return
    switch (MemberStatusEnums[registrationApprove.status]) {
      case '대기':
        if (window.confirm(`${registrationApprove.memberId} 회원을 가입 승인처리 하시겠습니까?`)) {
          setMemberStatusLoading(true)
          updateMemberRegistrationStatus({
            memberKey: registrationApprove.userKey,
            status: 'NORMAL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입이 승인 처리 되었습니다.`)
              setRegistrationApprove('')
              window.location.reload()
            })
            .catch(error => {
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입 처리가 실패했습니다.`)
              setRegistrationApprove('')
            })
        }
        break
      case '신청':
        if (window.confirm(`${registrationApprove.memberId} 회원을 가입 승인처리 하시겠습니까?`)) {
          setMemberStatusLoading(true)
          updateMemberRegistrationStatus({
            memberKey: registrationApprove.userKey,
            status: 'NORMAL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입이 승인 처리 되었습니다.`)
              setRegistrationApprove('')
              window.location.reload()
            })
            .catch(error => {
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입 처리가 실패했습니다.`)
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 회원은 신청 및 대기상태가 아니므로 승인처리가 불가합니다.')
        fetchSearchMember()
        break
    }
  }

  // 취소 버튼
  const onCancelRegistrationHandler = () => {
    if (memberStatusLoading) return
    switch (MemberStatusEnums[registrationApprove.status]) {
      case '신청':
        if (window.confirm(`${registrationApprove.memberId} 회원을 가입 취소처리 하시겠습니까?`)) {
          setMemberStatusLoading(true)
          updateMemberRegistrationStatus({
            memberKey: registrationApprove.userKey,
            status: 'CANCEL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setMemberStatusLoading(false)
              setRegistrationApprove('')
              fetchSearchMember()
              alert(`${registrationApprove.memberId}의 가입이 취소 처리 되었습니다.`)
            })
            .catch(error => {
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입 처리가 실패했습니다.`)
              setRegistrationApprove('')
            })
        }
        break
      case '대기':
        if (window.confirm(`${registrationApprove.memberId} 회원을 가입 취소처리 하시겠습니까?`)) {
          setMemberStatusLoading(true)
          updateMemberRegistrationStatus({
            memberKey: registrationApprove.userKey,
            status: 'CANCEL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setMemberStatusLoading(false)
              setRegistrationApprove('')
              fetchSearchMember()
              alert(`${registrationApprove.memberId}의 가입이 취소 처리 되었습니다.`)
            })
            .catch(error => {
              setMemberStatusLoading(false)
              alert(`${registrationApprove.memberId}의 가입 처리가 실패했습니다.`)
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 회원은 신청 및 대기상태가 아니므로 취소처리가 불가합니다.')
        fetchSearchMember()
        break
    }
  }

  // excel download
  const [downloadLoading, setDownloadLoading] = useState(false)
  const columnMapping = {
    num: '번호',
    userId: '유저ID',
    createAt: '가입일',
    signUpDomain: '가입도메인',
    signUpIp: '가입IP',
    lastLoginAt: '마지막로그인',
    lastloginIp: '마지막 로그인IP',
    edgeName: '사이트명',
    userName: '회원명',
    nickName: '닉네임',
    userPhoneNum: '연락처',
    userAuthority: '권한',
    userStatus: '유저상태',
    depositLevelName: '입금LV',
    casinoLiveLevelName: '카지노LV',
    casinoSlotLevelName: '슬롯LV',
    miniGameLevelName: '미니게임LV',
    sportsGameLevelName: '스포츠LV',
    friendCompLevelName: '추천인LV',
    holdemLevelName: '홀덤LV',
    virtualGameLevelName: '가상게임LV',
    friendId: '추천인ID',
    topUserId: '상위파트너ID',
    topUserName: '상위파트너명',
    partnerLevel: '상위파트너LV',
    losingSettlementType: '상위파트너 정산방식',
    depositAmount: '총 입금액',
    withdrawalAmount: '총 출금액',
    totalBettingAmount: '총 배팅액',
    totalWinningAmount: '총 당첨액',
    casinoLiveBettingAmount: '총 카지노 배팅액',
    casinoLiveWinningAmount: '총 카지노 당첨액',
    casinoSlotBettingAmount: '총 슬롯 배팅액',
    casinoSlotWinningAmount: '총 슬롯 당첨액',
    miniGameBettingAmount: '총 미니게임 배팅액',
    miniGameWinningAmount: '총 미니게임 당첨액',
    sportsBettingAmount: '총 스포츠 배팅액',
    sportsWinningAmount: '총 스포츠 당첨액',
    holdemBettingAmount: '총 홀덤 배팅액',
    holdemWinningAmount: '총 홀덤 당첨액',
    virtualGameBettingAmount: '총 가상게임 배팅액',
    virtualGameWinningAmount: '총 가상게임 당첨액',
  }
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (window.confirm('유저 DB를 csv파일로 다운 받으시겠습니까?')) {
      setDownloadLoading(true)
      downloadMemberList({})
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '회원 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  const [activeKey, setActiveKey] = useState('1')

  const currentUserSearchHandler = () => {
    setCurrentUserSearch(prev => !prev)
  }

  const currentGameUserSearchHandler = () => {
    setCurrentGameUserSearch(prev => !prev)
  }

  const testUserSearchHandler = () => {
    setTestUserSearch(prev => !prev)
  }

  // 전체 쪽지 모달
  const [isTotalNoteModalShowForPartner, setTotalNoteModalShowForPartner] = useState(false)

  const onTotalNoteClickHandlerForPartner = () => {
    setTotalNoteModalShowForPartner(prev => !prev)
  }

  const [isPartnerAll, setPartnerAll] = useState(false)
  const onOnePartnerNoteClickHandler = () => {
    setPartnerAll(false)
    setTotalNoteModalShowForPartner(prev => !prev)
  }

  const onTotalPartnerNoteClickHandler = () => {
    setPartnerAll(true)
    setTotalNoteModalShowForPartner(prev => !prev)
  }

  const resetSearchValue = () => {
    setUserAuthority(null)
    setSelectedEdgeName(null)
    setSearchStatusOptionValue(null)
    setSearchMemberId(null)
    setSearchTopPartnerName(null)
    setSearchStatus(null)
    setCurrentUserSearch(false)
    setCurrentGameUserSearch(false)
    setTestUserSearch(false)
  }

  const reloadOnoToOneHandler = () => {
    if (loading) return
    setLoading(true)
    resetSearchValue()
    setParams({
      page: 0,
      size,
      edgeName: null,
      memberId: null,
      topPartnerName: null,
      startDate: null,
      endDate: null,
    })
    setLoading(false)
  }

  return (
    <>
      {isTotalNoteModalShowForPartner && (
        <NoteCustomStyledModal show={isTotalNoteModalShowForPartner} onHide={onTotalNoteClickHandlerForPartner} $header>
          <CustomModalHeader>
            <CustomModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={onTotalNoteClickHandlerForPartner}
            />
            {isPartnerAll ? (
              <>
                {/* 파트너 전체한테 쪽지보내기 */}
                <CustomModalTitle>파트너 전체 쪽지 보내기</CustomModalTitle>
              </>
            ) : (
              <>
                {/* 파트너별 쪽지보내기 */}
                <CustomModalTitle>라인별 쪽지 보내기</CustomModalTitle>
              </>
            )}
          </CustomModalHeader>
          <CustomModalBody>
            {isPartnerAll ? (
              <>
                {/* 파트너 전체한테 쪽지보내기 */}
                <PartnerNoteForm onClickHandler={onOnePartnerNoteClickHandler} />
              </>
            ) : (
              <>
                {/* 파트너별 쪽지보내기 */}
                <PartnerNoteForm isTotal onClickHandler={onTotalPartnerNoteClickHandler} />
              </>
            )}
          </CustomModalBody>
        </NoteCustomStyledModal>
      )}
      {memberStatusLoading || downloadLoading || loading ? (
        <Loading />
      ) : (
        <Card>
          <CardBody>
            <MemberManageMentTabs activeKey={activeKey} />
            <SearchCardTitleWrap style={{ marginTop: '30px' }}>
              <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
                {/* <p>
                  * <span style={{ color: 'blue', fontWeight: '900' }}>회원ID 옆 돋보기</span>를 클릭하면 회원의 자세한
                  정보를 확인 할 수 있습니다.
                </p> */}
              </div>
            </SearchCardTitleWrap>

            {/* 검색 기능 */}
            <SearchStatusWrap>
              <EdgeSearchButton
                edgeName={selectedEdgeName}
                onClickHandler={e => {
                  setSelectedEdgeName(e.target.id || null)
                }}
              />
              <AuthoritySearchButton
                userAuthority={userAuthority}
                onClickHandler={e => {
                  setUserAuthority(e.target.id)
                }}
              />

              <Button
                size="sm"
                active={searchStatusOptionValue === null}
                variant="outline-secondary"
                onClick={e => {
                  onChangeSearchStatusHandler(null)
                }}
                style={{ marginLeft: '10px' }}
              >
                전체
              </Button>
              {Object.keys(MemberModifyStatusEnums).map(status => {
                return (
                  <Button
                    id={status}
                    size="sm"
                    active={searchStatusOptionValue === status}
                    variant="outline-secondary"
                    onClick={e => {
                      onChangeSearchStatusHandler(status)
                    }}
                  >
                    {MemberModifyStatusEnums[status]}
                  </Button>
                )
              })}
            </SearchStatusWrap>
            <SearchStatusWrap>
              <CustomButton
                type="button"
                onClick={e => {
                  if (whatSearch !== 'totalMoney') {
                    setWhatSearch('totalMoney')
                  } else {
                    setWhatSearch(null)
                  }
                }}
                active={whatSearch === 'totalMoney'}
              >
                보유머니 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                onClick={e => {
                  if (whatSearch !== 'totalLosingPoint') {
                    setWhatSearch('totalLosingPoint')
                  } else {
                    setWhatSearch(null)
                  }
                }}
                active={whatSearch === 'totalLosingPoint'}
              >
                루징포인트 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalDeposit'}
                onClick={e => {
                  if (whatSearch !== 'totalDeposit') {
                    setWhatSearch('totalDeposit')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                총입금액 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalWithdrawal'}
                onClick={e => {
                  if (whatSearch !== 'totalWithdrawal') {
                    setWhatSearch('totalWithdrawal')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                총출금액 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalExchange'}
                onClick={e => {
                  if (whatSearch !== 'totalExchange') {
                    setWhatSearch('totalExchange')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                환수율 정렬(⬆)
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'totalExchangeDown'}
                onClick={e => {
                  if (whatSearch !== 'totalExchangeDown') {
                    setWhatSearch('totalExchangeDown')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                환수율 정렬(⬇️)
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'depositLevel'}
                onClick={e => {
                  if (whatSearch !== 'depositLevel') {
                    setWhatSearch('depositLevel')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                입금레벨 순 정렬
              </CustomButton>
              <CustomButton
                type="button"
                active={whatSearch === 'lastLogin'}
                onClick={e => {
                  if (whatSearch !== 'lastLogin') {
                    setWhatSearch('lastLogin')
                  } else {
                    setWhatSearch(null)
                  }
                }}
              >
                최근 로그인 순 정렬
              </CustomButton>
              <CustomButton2
                size="sm"
                active={currentUserSearch}
                style={{ marginLeft: '20px' }}
                variant="outline-secondary"
                onClick={e => {
                  currentUserSearchHandler()
                }}
              >
                현재 접속중인 유저
              </CustomButton2>
              <CustomButton2
                size="sm"
                active={currentGameUserSearch}
                variant="outline-secondary"
                onClick={e => {
                  currentGameUserSearchHandler()
                }}
              >
                카지노 접속중인 유저
              </CustomButton2>
              <Button
                size="sm"
                variant="outline-secondary"
                style={{ background: 'red', color: 'white' }}
                onClick={e => {
                  setModalShow(true)
                  setWhatModalShow('DUPLICATION')
                }}
              >
                중복IP 확인
              </Button>
              <CustomButton3
                size="sm"
                active={testUserSearch}
                variant="outline-secondary"
                onClick={e => {
                  testUserSearchHandler(true)
                }}
              >
                테스트유저
              </CustomButton3>
            </SearchStatusWrap>

            <SearchContainer horizontal style={{ marginBottom: '10px' }}>
              <SearchGroup>
                <SearchGroupLabel>유저 ID(명) /닉네임 /계좌 /번호/ 추천코드</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchValue"
                      type="text"
                      placeholder="입력해주세요"
                      value={searchMemberId}
                      onChange={e => onSearchMemberIdHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>추천인 ID / 명</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchValue"
                      type="text"
                      placeholder="입력해주세요"
                      value={searchFriendUserId}
                      onChange={e => onSearchFriendUserIdHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>직영 파트너 ID / 명</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchValue"
                      type="text"
                      placeholder="검색 입력해주세요"
                      value={searchTopPartnerName}
                      onChange={e => onSearchTopPartnerNameHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>

              <SearchButtonWrap>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    onSearchClickHandler()
                  }}
                >
                  검색하기
                </Button>
              </SearchButtonWrap>

              <SearchButtonWrap>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  style={{ background: '#4e827a', color: '#fff', fontSize: '12px' }}
                  onClick={e => {
                    onTotalNoteClickHandler()
                  }}
                >
                  (회원)전체 쪽지 보내기
                </Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  style={{ background: 'blue', color: '#fff', fontSize: '12px' }}
                  onClick={e => {
                    onTotalPartnerNoteClickHandler()
                  }}
                >
                  (파트너)전체 쪽지 보내기
                </Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  style={{ background: 'purple', color: 'pink', fontSize: '12px' }}
                  onClick={e => {
                    onOnePartnerNoteClickHandler()
                  }}
                >
                  라인별 쪽지 보내기
                </Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  style={{ background: 'green', color: 'white', fontSize: '12px' }}
                  onClick={e => {
                    downloadMemberListHandler()
                  }}
                >
                  유저 DB 다운
                </Button>
                <Button
                  size="sm"
                  style={{ marginLeft: '10px', backgroundColor: 'pink', fontWeight: '600', color: '#000' }}
                  variant="outline-secondary"
                  onClick={() => {
                    reloadOnoToOneHandler()
                  }}
                >
                  새로고침
                </Button>
              </SearchButtonWrap>
            </SearchContainer>
            <ReactTableBase columns={memberListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
            <CustomStyledModal show={isTotalNoteModalShow} onHide={onTotalNoteClickHandler} $header>
              <CustomModalHeader>
                <CustomModalCloseButton
                  className="lnr lnr-cross"
                  aria-label="close-btn"
                  type="button"
                  onClick={onTotalNoteClickHandler}
                />
                <CustomModalTitle>회원 전체 쪽지 보내기</CustomModalTitle>
              </CustomModalHeader>
              <CustomModalBody>
                <MemberNoteForm isTotal onClickHandler={onTotalNoteClickHandler} />
              </CustomModalBody>
            </CustomStyledModal>
            <FriendLinkCustomStyledModal show={isFriendLinkModalShow} onHide={createFriendLinkClickHandler} $header>
              <CustomModalHeader>
                <CustomModalCloseButton
                  className="lnr lnr-cross"
                  aria-label="close-btn"
                  type="button"
                  onClick={createFriendLinkClickHandler}
                />
                <CustomModalTitle>추천인링크 생성</CustomModalTitle>
              </CustomModalHeader>
              <CustomModalBody>
                <FriendLinkForm />
              </CustomModalBody>
            </FriendLinkCustomStyledModal>
            {
              {
                SUPER: (
                  <>
                    <SearchContainer horizontal>
                      <SearchButtonWrap>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'green', color: 'white' }}
                          onClick={() => {
                            onWaitingRegistrationHandler()
                          }}
                        >
                          가입 대기
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'blue', color: 'white' }}
                          onClick={() => {
                            onApproveRegistrationHandler()
                          }}
                        >
                          가입 승인
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'red', color: 'white' }}
                          onClick={() => {
                            onCancelRegistrationHandler()
                          }}
                        >
                          가입 취소
                        </Button>
                      </SearchButtonWrap>
                    </SearchContainer>
                  </>
                ),
              }[authority]
            }
          </CardBody>
          <CustomModal show={isModalShow} onHide={onModalClickHandler} $header>
            <CustomModalHeader>
              <CustomModalCloseButton
                className="lnr lnr-cross"
                aria-label="close-btn"
                type="button"
                onClick={onModalClickHandler}
              />
              {
                {
                  INFO: (
                    <>
                      <CustomModalTitle>{isModalUserId} 유저정보</CustomModalTitle>
                    </>
                  ),
                  ASSET: (
                    <>
                      <CustomModalTitle>{isModalUserId} 유저 자산 증감</CustomModalTitle>
                    </>
                  ),
                  ROLLING: (
                    <>
                      <CustomModalTitle>{isModalUserId} 유저 롤링정보 수정</CustomModalTitle>
                    </>
                  ),
                  DUPLICATION: (
                    <>
                      <CustomModalTitle>유저 중복IP 확인</CustomModalTitle>
                    </>
                  ),
                }[whatModalShow]
              }
            </CustomModalHeader>
            <CustomModalBody>
              {
                {
                  ASSET: (
                    <>
                      <MemberHistoryMoneyIncDec memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                    </>
                  ),
                  ROLLING: (
                    <>
                      <MemberRollingInfoPage memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                    </>
                  ),
                  DUPLICATION: (
                    <>
                      <DuplicationIpHistory onClickHandler={onModalClickHandler} />
                    </>
                  ),
                }[whatModalShow]
              }
            </CustomModalBody>
          </CustomModal>
        </Card>
      )}
    </>
  )
}

export default MemberList

const SearchStatusWrap = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`

const CustomModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 1250px !important;
  }
`

const CustomButton = styled.button`
  border: none;
  color: ${props => (props.active ? '#fff' : '#000')} !important;
  background-color: ${props => (props.active ? '#454545' : 'lightgray')} !important;
`

const CustomButton2 = styled.button`
  border: none;
  color: ${props => (props.active ? '#fff' : '#fff')} !important;
  background-color: ${props => (props.active ? '#665e47' : '#d1b975')} !important;
`

const CustomButton3 = styled.button`
  border: none;
  color: ${props => (props.active ? '#fff' : '#fff')} !important;
  background-color: ${props => (props.active ? '#665e47' : '#edae07')} !important;
`
