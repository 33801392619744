export const partnerLosingStatisticsAuthorityEnums = {
  BONSA: '본사',
  BOOBONSA: '부본사',
  CHONGPAN: '총판',
  MAEJANG: '매장',
}

/**
 * 루징 정산 타입
 */
export const PartnerLosingType = {
  NONE: '없음',
  LOSING_SETTLEMENT_TYPE1: '(베팅액 - 당첨액) * 요율% - 발생롤링액',
  LOSING_SETTLEMENT_TYPE2: '(베팅액- 당첨액 - 발생롤링액) * 요율%',
  LOSING_SETTLEMENT_TYPE3: '(입금액 - 출금액) * 요율%',
  LOSING_SETTLEMENT_TYPE4: '(입금액 - 출금액) * 요율% - 발생롤링액',
  LOSING_SETTLEMENT_TYPE5: '((베팅액 - 당첨액) * 요율% - 발생롤링액) * 0.9',
  LOSING_SETTLEMENT_TYPE6: '((베팅액 - 당첨액 - 발생롤링액) * 요율%) * 0.9',
  LOSING_SETTLEMENT_TYPE7: '(베팅액 - 당첨액) * 요율%',
  LOSING_SETTLEMENT_TYPE8: '낙첨액 * 요율%',
}
