import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 배너 리스트 조회
 */
export async function searchBannerList() {
  const response = await axios({
    url: '/tyson/v1/banner/admin/banner',
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

export async function adminSearchBannerSelectList(params) {
  const response = await axios({
    url: '/tyson/v1/banner',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function adminSearchBannerList(params) {
  const response = await axios({
    url: '/tyson/v1/banner/admin/banner-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useAdminSearchBannerList(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchAdminSearchBannerList() {
    await adminSearchBannerList(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchAdminSearchBannerList()
  }, [params])

  return { totalElement, content, fetchAdminSearchBannerList }
}

/**
 * 은행 수정
 */
export async function updateBanner(body) {
  const response = await axios({
    url: '/tyson/v1/banner',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 은행 추가
 */
export async function createBanner(body) {
  const response = await axios({
    url: '/tyson/v1/banner',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
