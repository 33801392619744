import axios from '../base/axios'

// 리그 조회
export async function searchSportTeamList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/team',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportTeam(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/team',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportTeamStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/team/status',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function updateSportTeamMultipleStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/team/multiple/status',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportTeamStatusAll(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/team/status/all',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportTeamImportant(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/team/important',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
