import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const OperationTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/management/operation/board')
                }}
              >
                게시판 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/management/operation/popup')
                }}
              >
                팝업 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/management/operation/onetoone')
                }}
              >
                1:1문의
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/management/operation/note')
                }}
              >
                쪽지 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/management/system/macro')
                }}
              >
                메크로 관리
              </NavLink>
            </NavItem>
            {/* <NavItem>
                    <NavLink href="/management/operation/coupon/" eventKey="5">
                      쿠폰관리
                    </NavLink>
                  </NavItem> */}
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

OperationTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}

export default OperationTabs
