import React from 'react'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'

const CallUsersData = (content, setMemberInfo, setCallModalShow, setrecoveryPerModalShow) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },

    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '게임사',
      accessor: 'vendorName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '게임명',
      accessor: 'gameTitle',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배팅액',
      accessor: 'bettingAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingAmount?.toString().replace(commonReg2, ',')
      },
    },
    // {
    //   Header: '총 배팅액',
    //   accessor: 'totalBettingAmount',
    //   disableGlobalFilter: true,
    //   width: 65,
    //   Cell: r => {
    //     const { row } = r
    //     const { original } = row
    //     return original.totalBettingAmount?.toString().replace(commonReg2, ',')
    //   },
    // },
    // {
    //   Header: '총 당첨액',
    //   accessor: 'totalWinningAmount',
    //   disableGlobalFilter: true,
    //   width: 65,
    //   Cell: r => {
    //     const { row } = r
    //     const { original } = row
    //     return original.totalWinningAmount?.toString().replace(commonReg2, ',')
    //   },
    // },
    {
      Header: '환수율 (목적/실제)',
      accessor: 'targetRtp',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return `${original.targetRtp} / ${original.realRtp}`
      },
    },
    {
      Header: '콜 조작',
      accessor: 'callManipulation',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <button
            type="button"
            onClick={() => {
              setMemberInfo(original)
              setCallModalShow(true)
            }}
            style={{
              color: 'white',
              fontWeight: '600',
              border: 'none',
              background: 'blue',
              outline: 'none',
              lineHeight: '25px',
            }}
          >
            콜 조작
          </button>
        )
      },
      width: 70,
    },
    {
      Header: '환수 조작',
      accessor: 'recoveryPerManipulation',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <button
            type="button"
            onClick={() => {
              setMemberInfo(original)
              setrecoveryPerModalShow(true)
            }}
            style={{
              color: 'white',
              fontWeight: '600',
              border: 'none',
              background: 'red',
              outline: 'none',
              lineHeight: '25px',
            }}
          >
            환수 조작
          </button>
        )
      },
      width: 70,
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default CallUsersData
