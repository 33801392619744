export const macroTypeEnums = {
  NOTE: '쪽지',
  ONETOONE: '1:1문의',
}

export const MacroTypeEnumsSelectOptions = () => {
  return Object.entries(macroTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MacroTypeEnumsRadioOptions = () => {
  return Object.entries(macroTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'macroType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'macroType',
          label: value,
          radioValue: key,
        },
  )
}
