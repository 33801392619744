import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSearchMemberMoneyExchange } from '../../../../../utils/api/memberHistory/memberHistoryApi'
import MoneyExchangeListData from '../../../../MemberHistory/MoneyExchangeHistory/MoneyExchangeListData'

const MemberHistoryMoneyExchange = ({ memberId }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  /**
   * 회원 머니이동내역 조회
   */
  const [params, setParams] = useState({
    page,
    size,
    memberId,
    sender: null,
    receiver: null,
    executeStartDate: null,
    executeEndDate: null,
    isExact: true,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchMemberMoneyExchange } = useSearchMemberMoneyExchange(params)
  const [moneyExchangeListData, setMoneyExchangeListData] = useState(MoneyExchangeListData(content))

  useEffect(() => {
    setMoneyExchangeListData(MoneyExchangeListData(content))
  }, [content])

  const [rows, setData] = useState(moneyExchangeListData.tableRowsData)

  useEffect(() => {
    setData(moneyExchangeListData.tableRowsData)
  }, [moneyExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }
  // 송신
  const [searchSender, setSearchSender] = useState('')

  const onSearchSenderHandler = e => {
    setSearchSender(e.target.value)
  }

  // 수신
  const [searchReceiver, setSearchReceiver] = useState('')

  const onSearchReceiverHandler = e => {
    setSearchReceiver(e.target.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      memberId,
      sender: !searchSender ? null : searchSender,
      receiver: !searchReceiver ? null : searchReceiver,
      executeStartDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      executeEndDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
      page: 0,
    }))
  }

  return (
    <>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>송신</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchValue"
                type="text"
                placeholder="입력해주세요"
                value={searchSender}
                onChange={e => onSearchSenderHandler(e)}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>수신</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchValue"
                type="text"
                placeholder="입력해주세요"
                value={searchReceiver}
                onChange={e => onSearchReceiverHandler(e)}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteStartDate}
                onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteEndDate}
                onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <ReactTableBase
        key="common"
        columns={moneyExchangeListData.tableHeaderData}
        data={rows}
        tableConfig={tableConfig}
      />
    </>
  )
}

export default MemberHistoryMoneyExchange

MemberHistoryMoneyExchange.propTypes = {
  memberId: PropTypes.string.isRequired,
}
