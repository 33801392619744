import { ButtonToolbar } from '@/shared/components/Button'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import styled from 'styled-components'
import { CustomTableButton, CustomTableWrap } from '../../../shared/components/TableElements'
import { deleteMacroManage } from '../../../utils/api/systemManage/systemManageApi'
import { decodeAccessToken } from '../../../utils/token'
import MacroModifyForm from './MacroModifyForm'

function MacroModifyModal({
  edgeName,
  header,
  tag,
  macroKey,
  macroTitle,
  fetchSearchMacroManage,
  macroContent,
  type,
  sortNum,
}) {
  const authority = decodeAccessToken()?.adminAuthority
  const [isMacroModifyModalShow, setMacroModifyModalShow] = useState(false)

  const onClickHandler = () => {
    setMacroModifyModalShow(prevState => !prevState)
  }

  const onDeleteHandler = () => {
    if (window.confirm('정말 삭제하시겠습니까?')) {
      const body = {
        macroKey,
      }

      deleteMacroManage(body)
        .then(res => {
          alert('매크로가 삭제 완료됐습니다.')
          fetchSearchMacroManage()
        })
        .catch(error => {
          alert('매크로가 삭제가 실패했습니다, 잠시후 다시 시도해주세요.')
        })
    } else {
      window.close()
    }
  }

  return (
    <CustomTableWrap>
      <CustomTableButton
        role={Button}
        onClick={() => onClickHandler()}
        style={{ marginRight: '5px', color: '#fff', background: 'blue', border: 'none' }}
      >
        수정
      </CustomTableButton>
      {
        {
          SUPER: (
            <CustomTableButton
              style={{ marginRight: '5px', color: '#fff', background: 'red', border: 'none' }}
              role={Button}
              onClick={() => onDeleteHandler()}
            >
              삭제
            </CustomTableButton>
          ),
        }[authority]
      }

      <StyledModal show={isMacroModifyModalShow} onHide={onClickHandler} header={header}>
        <ModalHeader>
          <ModalCloseButton className="lnr lnr-cross" aria-label="close-btn" type="button" onClick={onClickHandler} />
          {header ? '' : ''}
          <ModalTitle>매크로 수정</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <MacroModifyForm
            edgeName={edgeName}
            fetchSearchMacroManage={fetchSearchMacroManage}
            onClickHandler={onClickHandler}
            tag={tag}
            macroKey={macroKey}
            macroTitle={macroTitle}
            macroContent={macroContent}
            type={type}
            sortNum={sortNum}
          />
        </ModalBody>
      </StyledModal>
    </CustomTableWrap>
  )
}

export default MacroModifyModal

MacroModifyModal.propTypes = {
  edgeName: PropTypes.string.isRequired,
  header: PropTypes.bool,
  tag: PropTypes.string.isRequired,
  macroTitle: PropTypes.string.isRequired,
  macroKey: PropTypes.string.isRequired,
  macroContent: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  fetchSearchMacroManage: PropTypes.func.isRequired,
  sortNum: PropTypes.string.isRequired,
}

MacroModifyModal.defaultProps = {
  header: true,
}

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 800px !important;
  }

  .modal-content {
    height: max-content;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
