// 양수 + 소숫점 첫째자리 허용  + 10% 미만 정규표현식
export const commonReg1 = /(^\d{1}$)|(^\d{1}[.]\d{1}$)/

// 콤마 찍기
export const commonReg2 = /\B(?=(\d{3})+(?!\d))/g

// 양의정수 1~50000까지
export const commonReg3 = /^[0-9]{1}$|^[1-9]{1}[0-9]{1,3}$|^[1-4]{1}[0-9]{1,4}$|^50000$/

// 양의 정수 1 ~ 30까지
export const commonReg4 = /^[0-9]{1}$|^[1-2]{1}[0-9]{1}$|30$/

// 양의 정수 1 ~ 1,000,000까지
export const commonReg5 = /^[0-9]{1}$|^[1-9]{1}[0-9]{1,5}$|1000000$/

// 양의 정수(10000~10000000)만 허용
export const commonReg6 = /^\d{5,10}$/

// 숫자만
export const commonReg7 = /[^\d]/

// 양수 + 소숫점 첫째자리 허용  + 100% 미만 정규표현식
export const commonReg8 = /(^\d{1}$)|(^\d{2}$)|(^\d{1}[.]\d{1}$)|(^\d{2}[.]\d{1}$)|^100$/

// 양의 정수(1000~10000000)만 허용
export const commonReg9 = /^\d{4,10}$/

// 양의 정수(0~100)만 허용
export const commonReg11 = /^(\d|[1-9]\d|100)$/

// 양수 + 소숫점 둘째자리 허용  + 10% 이하 정규표현식
export const commonReg10 = /(^[0-9]$)|(^[0-9][.]\d{1}$)|(^[0-9][.]\d{2}$)|^10$/

// 1부터 1,000,000까지 입력하도록 하는 정규표현식

// 콤마 정규표현식
const reg4 = '/B(?<!.d*)(?=(d{3})+(?!d))/g, ","'
// number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");

// 50 이상 95 이하
export const commonReg12 = /^(5[0-9]|[6-8][0-9]|9[0-5])$/

export const coomonReg13 = /^\d+$/
