import React, { useEffect, useState } from 'react'
import { Card, CardBody } from '@/shared/components/Card'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import { Button } from 'react-bootstrap'
import Switch from 'react-switch'
import Loading from '../../shared/components/Loading'
import { searchHoldemInfo, updateHoldemInfo } from '../../utils/api/holdem/holdemApi'
import { decodeAccessToken } from '../../utils/token'
import { searchWildGamesGameList, updateWildGamesGameInfo } from '../../utils/api/holdem/wildGamesApi'

const WildGamesGameListPage = () => {
  const authority = decodeAccessToken()?.adminAuthority

  const [loading, setLoading] = useState(false)

  const [gameInfoList, setGameInfoList] = useState([])

  const fetchWildGamesGameList = () => {
    setLoading(true)

    searchWildGamesGameList()
      .then(res => {
        setGameInfoList(res.data.gameList)
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchWildGamesGameList()
  }, [])

  // 수정된 gameInfoKey들을 저장하는 상태
  const [modifiedKeys, setModifiedKeys] = useState(new Set())

  const onChangeWildGamesGameInfoHandler = (gameInfoKey, key, value) => {
    // 1. 기존 상태에서 해당 gameInfoKey를 가진 객체의 인덱스를 찾기
    const index = gameInfoList.findIndex(game => game.wildGamesGameInfoKey === gameInfoKey)

    if (index === -1) {
      return
    }

    // 2. 해당 객체를 복사한 후, 필요한 속성 업데이트
    const updatedGameInfo = {
      ...gameInfoList[index],
      [key]: value,
    }

    // 3. 불변성을 유지하면서 새로운 배열을 생성
    const updatedGameInfoList = [...gameInfoList.slice(0, index), updatedGameInfo, ...gameInfoList.slice(index + 1)]

    // 4. 상태 업데이트
    setGameInfoList(updatedGameInfoList)

    // 5. 수정된 gameInfoKey를 modifiedKeys 상태에 추가
    setModifiedKeys(prevKeys => new Set(prevKeys).add(gameInfoKey))
  }

  const onClickHoldemInfoUpdateHandler = () => {
    if (modifiedKeys.size === 0) return

    if (loading) return

    const gameInfos = gameInfoList
      .filter(item => modifiedKeys.has(item.wildGamesGameInfoKey))
      .map(item => {
        return {
          wildGamesGameInfoKey: item.wildGamesGameInfoKey,
          gameKoreanName: item.gameKoreanName,
          isShow: item.isShow,
          isInspection: item.isInspection,
          orderNum: item.orderNum,
        }
      })

    if (gameInfos.length === 0) return

    if (window.confirm('와일드 게임즈 게임 정보를 변경하시겠습니가?')) {
      const body = {
        gameInfoList: gameInfos,
      }
      setLoading(true)
      updateWildGamesGameInfo(body)
        .then(res => {
          alert('게임 정보가 변경되었습니다.')
        })
        .catch(error => {
          alert('게임 정보가 변경에 실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <HoldemManageWrapper>
        <HoldemManageWrapperHeader>게임 리스트</HoldemManageWrapperHeader>

        <Form
          onSubmit={onClickHoldemInfoUpdateHandler}
          initialValues={{
            gameInfoList,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <HoldemInfoForm horizontal onSubmit={handleSubmit}>
              <HoldemInfoFormInnerBox>
                <table>
                  <thead>
                    <tr>
                      <th>공급사</th>
                      <th>게임코드</th>
                      <th>게임명</th>
                      <th>게임타입</th>
                      <th>웹 제공</th>
                      <th>PC 제공</th>
                      <th style={{ width: '100px' }}>노출순서</th>
                      <th>노출여부</th>
                      <th>점검여부</th>
                    </tr>
                  </thead>
                  <tbody>
                    {gameInfoList.map(gameInfo => {
                      return (
                        <tr>
                          <td>{gameInfo.providerName}</td>
                          <td>{gameInfo.gameCode}</td>
                          <td>
                            <Field name="gameKoreanName">
                              {({ input, meta }) => (
                                <input
                                  {...input}
                                  type="text"
                                  value={gameInfo.gameKoreanName}
                                  onChange={e => {
                                    onChangeWildGamesGameInfoHandler(
                                      gameInfo.wildGamesGameInfoKey,
                                      'gameKoreanName',
                                      e.target.value,
                                    )
                                  }}
                                />
                              )}
                            </Field>
                          </td>
                          <td>{gameInfo.gameType}</td>
                          <td>
                            {gameInfo.webPlatform ? <span>제공</span> : <span style={{ color: 'red' }}>미제공</span>}
                          </td>
                          <td>
                            {gameInfo.desktopPlatform ? (
                              <span>제공</span>
                            ) : (
                              <span style={{ color: 'red' }}>미제공</span>
                            )}
                          </td>
                          <td>
                            <Field name="orderNum">
                              {({ input, meta }) => (
                                <input
                                  {...input}
                                  type="text"
                                  value={gameInfo.orderNum}
                                  onChange={e => {
                                    onChangeWildGamesGameInfoHandler(
                                      gameInfo.wildGamesGameInfoKey,
                                      'orderNum',
                                      e.target.value,
                                    )
                                  }}
                                />
                              )}
                            </Field>
                          </td>
                          <td>
                            <CustomSwitch
                              onChange={() => {
                                onChangeWildGamesGameInfoHandler(
                                  gameInfo.wildGamesGameInfoKey,
                                  'isShow',
                                  !gameInfo?.isShow ?? false,
                                )
                              }}
                              checked={gameInfo?.isShow ?? false}
                            />
                          </td>
                          <td>
                            <CustomSwitch
                              onChange={() => {
                                onChangeWildGamesGameInfoHandler(
                                  gameInfo.wildGamesGameInfoKey,
                                  'isInspection',
                                  !gameInfo?.isInspection ?? false,
                                )
                              }}
                              checked={gameInfo?.isInspection ?? false}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </HoldemInfoFormInnerBox>
              {authority === 'SUPER' && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                  <Button size="lg" variant="outline-secondary" type="submit">
                    변경하기
                  </Button>
                </div>
              )}
            </HoldemInfoForm>
          )}
        </Form>
      </HoldemManageWrapper>
    </>
  )
}

export default WildGamesGameListPage

const InfoBoxWrap = styled.div`
  width: 100%;
  padding: 5px 0;

  p {
    margin-bottom: 10px;
  }
`

const HoldemManageWrapperHeader = styled.div`
  width: 100%;
  font-size: 23px;
  color: blue;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 20px;
`

const HoldemManageWrapper = styled.div`
  width: 100%;
  min-height: 700px;

  padding: 10px;

  display: flex;
  flex-direction: column;
`

const HoldemInfoForm = styled.form`
  display: flex;
  flex-direction: column;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid #000;
    color: #646777;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const HoldemInfoFormInnerBox = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
