import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 엔트리사 게임 조회
export async function searchNtryMiniGameManagement(params) {
  const response = await axios({
    url: '/tyson/admin/v1/mini-game/ntry',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

/**
 * 엔트리사 게임 업데이트
 */
export async function updateNtryMiniGame(body) {
  const response = await axios({
    url: '/tyson/admin/v1/mini-game/ntry',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 토큰게임 게임 조회
export async function searchTokenGameManagement(params) {
  const response = await axios({
    url: '/tyson/admin/v1/mini-game/token-game',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

/**
 * 토큰게임 수정
 */
export async function updateTokenGame(body) {
  const response = await axios({
    url: '/tyson/admin/v1/mini-game/token-game',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
