import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import {
  BoardStatusEnumsSelectOptions,
  BoardTargetEnumsSelectOptions,
  BoardTypeEnumsSelectOptions,
} from '../../../enums/Operation/boardEnums'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import Loading from '../../../shared/components/Loading'
import { deleteBoardList, searchBoardList } from '../../../utils/api/operation/operationApi'
import OperationTabs from '../OperationTabs'
import BoardListData from './BoardListData'
import BoardModifyModal from './BoardModifyModal'
import BoardWriteModal from './BoardWriteModal'
import { decodeAccessToken } from '../../../utils/token'

const BoardList = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  // 게시판 수정 모달
  const [isBoardModifyModalShow, setBoardModifyModalShow] = useState(false)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  /**
   게시판 조회
   */
  const [boardParams, setBoardParams] = useState({
    page,
    size,
    type: null,
    target: null,
    title: null,
    status: null,
    edgeName: null,
  })

  useEffect(() => {
    setBoardParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchBoardList = async () => {
    await searchBoardList(boardParams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchSearchBoardList()
  }, [boardParams])

  const [boardInfo, setBoardInfo] = useState({})
  const onTitleClickRowHandler = () => {
    window.scrollTo(10, 0)
    setBoardModifyModalShow(true)
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [boardListData, setBoardListData] = useState(
    BoardListData(content, fetchSearchBoardList, checkRadio, setCheckRadio, onTitleClickRowHandler),
  )
  useEffect(() => {
    setBoardListData(BoardListData(content, fetchSearchBoardList, checkRadio, setCheckRadio, onTitleClickRowHandler))
  }, [content, checkRadio])

  const [rows, setData] = useState(boardListData.tableRowsData)
  useEffect(() => {
    setData(boardListData.tableRowsData)
  }, [boardListData])

  const [isBoardWriteModalShow, setBoardWriteModalShow] = useState(false)

  const onClickHandler = () => {
    setBoardWriteModalShow(prevState => !prevState)
  }

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setBoardInfo(rowValue.original)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 제목
  const [searchTitle, setSearchTitle] = useState('')

  const onSearchTitleHandler = e => {
    setSearchTitle(e.target.value)
  }
  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 구분
  const [searchType, setSearchType] = useState('')

  const [searchTypeOptionValue, setSearchTypeOptionValue] = useState(null)
  const searchTypeOption = BoardTypeEnumsSelectOptions()

  const onSelectTypeOptionHandler = option => {
    if (option === null) {
      setSearchTypeOptionValue(null)
      setSearchType(null)
      return
    }
    setSearchType(option.value)
    setSearchTypeOptionValue(option)
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = BoardStatusEnumsSelectOptions()

  const onSelectStatusOptionHandler = option => {
    setSearchStatusOptionValue(option)
    setSearchStatus(option?.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBoardParams(prev => ({
      ...prev,
      page: 0,
      type: !searchType ? null : searchType,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      title: !searchTitle ? null : searchTitle.trim(),
      status: !searchStatus ? null : searchStatus,
    }))
  }

  const [activeKey, setActiveKey] = useState('1')

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteBoardList({
        boardKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchBoardList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchBoardList()
        })
    }
  }

  return (
    <Card>
      <CardBody>
        <OperationTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 게시판을 수정할 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상태</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchStatus"
                placeholder="상태"
                onChange={option => {
                  onSelectStatusOptionHandler(option)
                }}
                value={searchStatusOptionValue}
                options={searchStatusOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>구분</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchType"
                placeholder="구분"
                onChange={option => {
                  onSelectTypeOptionHandler(option)
                }}
                value={searchTypeOptionValue}
                options={searchTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>제목</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchTitle"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTitle}
                  onChange={e => onSearchTitleHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          {deleteLoading ? (
            <>
              <Loading />
            </>
          ) : (
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'blue', color: 'white' }}
                variant="outline-secondary"
                onClick={() => {
                  onClickHandler()
                }}
              >
                글쓰기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'lightGreen' }}
                variant="outline-secondary"
                onClick={() => {
                  setCheckRadio(content.map(value => value.boardKey))
                }}
              >
                전체선택
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'red' }}
                variant="outline-secondary"
                onClick={() => {
                  onDeleteHandler()
                }}
              >
                삭제하기
              </Button>
            </SearchButtonWrap>
          )}
        </SearchContainer>
        <ReactTableBase columns={boardListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
      <BoardWriteModal
        fetchSearchBoardList={fetchSearchBoardList}
        isBoardWriteModalShow={isBoardWriteModalShow}
        onClickHandler={onClickHandler}
      />
      {/* 게시판 관리(수정) 모달 */}
      <BoardModifyModal
        boardKey={boardInfo?.boardKey}
        edgeName={boardInfo?.edgeName}
        boardTitle={boardInfo?.boardTitle}
        imgUrl={boardInfo?.imgUrl}
        type={boardInfo?.type}
        boardContent={boardInfo?.boardContent}
        target={boardInfo?.target}
        status={boardInfo?.status}
        registrationDate={boardInfo?.registrationDate}
        fetchSearchBoardList={fetchSearchBoardList}
        isBoardModifyModalShow={isBoardModifyModalShow}
        setBoardModifyModalShow={setBoardModifyModalShow}
      />
    </Card>
  )
}

export default BoardList
