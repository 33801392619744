import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import { SearchDatePickerWrap } from '@/shared/components/Search'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/locale'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { PopupStatusEnumsRadioOptions } from '../../../enums/Operation/popupEnums'
import Loading from '../../../shared/components/Loading'
import { createPopupList } from '../../../utils/api/operation/operationApi'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import './Popup.css'

function PopupWriteForm({ fetchSearchPopupList, onClickHandler }) {
  const { edgeNameRadio, domainCheckBox } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNameRadio: headerInfo.edgeNameRadio,
      domainCheckBox: headerInfo.domainCheckBox,
    }
  })
  const [selectedDomains, setSelectedDomains] = useState([])
  const handleDomainChange = domainName => {
    setSelectedDomains(
      prev =>
        prev.includes(domainName)
          ? prev.filter(d => d !== domainName) // 도메인 제거
          : [...prev, domainName], // 도메인 추가
    )
  }
  const [searchRegistrationDate, setSearchRegistrationDate] = useState(null)
  const onSearchRegistrationDateChangeHandler = date => {
    setSearchRegistrationDate(date)
  }
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const onSubmit = e => {
    if (writeLoading) return
    if (!e.title || !e.popupStatus || !e.imgName) {
      alert('제목,노출상태,이미지URL은 필수입니다.')
      return
    }

    const body = {
      edgeName: e.edgeName,
      title: e.title.trim(),
      type: 'NORMAL',
      status: e.popupStatus,
      url: e.imgName,
      domainUrl: selectedDomains.join(',') || null,
      createAt: convertToKst(searchRegistrationDate),
    }

    setWriteLoading(true)
    createPopupList(body)
      .then(res => {
        setWriteLoading(false)
        alert('팝업 등록이 완료됐습니다.')
        onClickHandler()
        fetchSearchPopupList()
        return res
      })
      .catch(error => {
        setWriteLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'CUS-1014') {
          alert(`${message}`)
          onClickHandler()
          return
        }
        alert('팝업 등록이 실패했습니다.')
        onClickHandler()
      })
  }

  return (
    <>
      {writeLoading ? (
        <Loading />
      ) : (
        <>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <div style={{ marginBottom: '30px' }}>
                  <p>
                    * 팝업은 최대 <span style={{ fontWeight: 'bolder', color: 'blue' }}>8개</span>까지
                    &quot;보이기&ldquo; 상태로 만들 수 있습니다.
                  </p>
                </div>
                <FormGroup>
                  <FormGroupLabel>사이트명</FormGroupLabel>
                  {edgeNameRadio.map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>제목</FormGroupLabel>
                  <FormGroupField>
                    <Field name="title" component="input" type="text" placeholder="제목을 입력하세요." />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>도메인</FormGroupLabel>
                  <CustomFormGroupField>
                    {domainCheckBox.map((item, index) => (
                      <CheckboxLabel>
                        <CheckboxInput
                          type="checkbox"
                          name="domainUrl"
                          value={item.name}
                          onChange={() => handleDomainChange(item.name)}
                        />
                        {item.name}
                      </CheckboxLabel>
                    ))}
                  </CustomFormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>노출</FormGroupLabel>
                  {PopupStatusEnumsRadioOptions().map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>등록일</FormGroupLabel>
                  <FormGroupField>
                    <SearchDatePickerWrap>
                      <DatePicker
                        selected={searchRegistrationDate}
                        onChange={date => onSearchRegistrationDateChangeHandler(date)}
                        dateFormat="yyyy-MM-dd"
                        dropDownMode="select"
                        popperPlacement="bottom-start"
                        placeholderText="클릭하세요"
                        isClearable
                        locale={ko}
                      />
                    </SearchDatePickerWrap>
                  </FormGroupField>
                </FormGroup>
                <div style={{ color: 'red', margin: '10px 0px' }}>
                  팝업이미지 크기는 400px*600px로 만든 후 별도의 저장소에 올리신 후 사용하실 수 있습니다.
                </div>
                <FormGroup>
                  <FormGroupLabel>이미지 URL</FormGroupLabel>
                  <FormGroupField>
                    <Field name="imgName" component="input" type="text" placeholder="URL을 입력하세요" />
                  </FormGroupField>
                </FormGroup>
                {/* <FormGroup>
            <FormGroupLabel>이미지 첨부</FormGroupLabel>
            <FormGroupFile>
              <label htmlFor="imageFile">{imageFile ? <span>{imageFile.name}</span> : '사진을 선택하세요'}</label>
              <input type="file" name="imageFile" id="imageFile" onChange={handleChange} accept="image/*" />
            </FormGroupFile>
          </FormGroup> */}
                {
                  {
                    SUPER: (
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">
                          팝업 등록
                        </Button>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default PopupWriteForm

PopupWriteForm.propTypes = {
  fetchSearchPopupList: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

const CustomFormGroupField = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  white-space: nowrap;
`

const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`
