import { Card, CardBody } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-final-form'
import styled from 'styled-components'
import { VirtualGameLeagueType, VirtualGameSoccerLeagueType } from '../../../enums/VirtualGame/virtualGameEnums'
import Loading from '../../../shared/components/Loading'
import { searchVirturalGameTeamList, updateVirturalGameTeamList } from '../../../utils/api/virtualGame/virtualGameApi'
import { decodeAccessToken } from '../../../utils/token'
import VirtualGameManagementTabs from './VirtualGameManagementTabs'

const VirtualSoccerManagement = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [virtualGameTeamList, setVirtualGameTeamList] = useState([])
  const [changes, setChanges] = useState({})

  const [virtualLeague, setVirtualLeague] = useState(null)
  const [params, setParams] = useState({
    virtualGameSportType: 'VIRTUAL_SOCCER',
    virtualGameLeagueType: null,
  })
  // 미니게임 종목 선택
  const onClickVirtualLeagueHandler = type => {
    setVirtualLeague(type)

    setParams(prev => ({
      ...prev,
      virtualGameLeagueType: type || null,
    }))
  }

  useEffect(() => {
    // "전체" 일때는 무시처리
    if (!virtualLeague) return

    // 리그 변경 시 변경 수정하기
    setChanges({})
  }, [virtualLeague])

  const [apiLoading, setApiLoading] = useState(false)

  const fetchSearchVirtualGameTeam = async () => {
    setApiLoading(true)

    searchVirturalGameTeamList(params)
      .then(res => {
        setVirtualGameTeamList(res.data.virtualGameTeamList)
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchVirtualGameTeam()
  }, [params])

  const handleInputChange = (key, field, value) => {
    setChanges(prevChanges => ({
      ...prevChanges,
      [key]: {
        ...prevChanges[key],
        [field]: value,
      },
    }))
  }

  const onSubmit = e => {
    if (apiLoading) return
    setApiLoading(true)
    // Prepare the body with only changed items
    const updatedItems = Object.keys(changes).map(key => ({
      virtualGameTeamKey: key,
      ...virtualGameTeamList.find(item => item.virtualGameTeamKey === key),
      ...changes[key],
    }))

    // Check if there are no updated items
    if (updatedItems.length === 0) {
      alert('변경된 것이 없습니다.')
      setApiLoading(false)
      return // Exit the function early
    }
    const body = {
      virtualGameTeamList: updatedItems,
    }
    if (window.confirm('설정을 변경하시겠습니까?')) {
      updateVirturalGameTeamList(body)
        .then(res => {
          alert('수정되었습니다.')
        })
        .catch(error => {
          alert('수정 실패했습니다.')
        })
        .finally(() => {
          fetchSearchVirtualGameTeam()
          setApiLoading(false)
        })
    }
  }
  return (
    <>
      {apiLoading && <Loading />}
      <Card>
        <CardBody>
          <VirtualGameManagementTabs activeKey="2" />
          <MiniGameTypeSelectWrapper>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              active={!virtualLeague}
              onClick={() => {
                onClickVirtualLeagueHandler(null)
              }}
            >
              전체
            </Button>
            {Object.entries(VirtualGameSoccerLeagueType).map(([key, value]) => {
              return (
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active={key === virtualLeague}
                  onClick={() => {
                    onClickVirtualLeagueHandler(key)
                  }}
                >
                  {value}
                </Button>
              )
            })}
          </MiniGameTypeSelectWrapper>
          <VirtualVendorWrap>
            <Form onSubmit={onSubmit} initialValues={{}}>
              {({ handleSubmit, form: { reset } }) => (
                <FormContainer onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
                  <TableWrap>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ borderLeft: '1px solid black' }}>번호</th>
                          <th>리그</th>
                          <th>팀명</th>
                          <th>현시팀명</th>
                        </tr>
                      </thead>
                      <tbody>
                        {virtualGameTeamList.map((item, index) => (
                          <tr key={item.virtualGameTeamKey}>
                            <td style={{ borderLeft: 'solid 1px #000' }}>{index + 1}</td>
                            <CustomTd type={item.virtualGameSportType}>
                              {VirtualGameLeagueType[item.virtualGameLeagueType]}
                            </CustomTd>
                            <td>{item.orginTeamName}</td>
                            <td>
                              <input
                                value={changes[item.virtualGameTeamKey]?.teamName || item.teamName}
                                onChange={e => handleInputChange(item.virtualGameTeamKey, 'teamName', e.target.value)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </TableWrap>
                  {
                    {
                      SUPER: (
                        <ButtonBox
                          style={{
                            marginTop: '10px',
                          }}
                        >
                          <button type="submit" style={{ background: 'grey', width: '120px', padding: '10px' }}>
                            수정하기
                          </button>
                        </ButtonBox>
                      ),
                    }[authority]
                  }
                </FormContainer>
              )}
            </Form>
          </VirtualVendorWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default VirtualSoccerManagement

VirtualSoccerManagement.propTypes = {}

const VirtualManagementWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  margin-top: 10px;
`

const getColor = type => {
  switch (true) {
    case type === 'VIRTUAL_SOCCER':
      return '#f6a033'
    case type === 'VIRTUAL_BASKETBALL':
      return '#f44c32'
    case type === 'VIRTUAL_BASEBALL':
      return '#731cf7'
    case type === 'VIRTUAL_HORSE':
      return '#36bd29'
    case type === 'VIRTUAL_GREYHOUNDS':
      return '#24c1f0'
    default:
      return 'black'
  }
}

const CustomTd = styled.td`
  color: #fff;
  background: ${props => getColor(props.type)};
`
const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`

const VirtualVendorWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 70px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
`

const MiniGameTypeSelectWrapper = styled.div`
  padding: 3px 0;
  margin-bottom: 5px;

  display: flex;
  gap: 5px;
`
