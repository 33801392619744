import ReactTableBase from '@/shared/components/table/ReactTableBase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSearchMemberAttendanceHistory } from '../../../../../utils/api/memberHistory/memberHistoryApi'
import MemberAttendanceListData from '../../../../MemberHistory/AttendanceHistory/MemberAttendanceListData'

const MemberHistoryAttendance = ({ memberId }) => {
  /**
    포인트 입금내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const [params, setParams] = useState({
    memberId,
    page,
    size,
    isExact: true,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content } = useSearchMemberAttendanceHistory(params)

  const [attendanceListData, setAttendanceListData] = useState(MemberAttendanceListData(content))

  useEffect(() => {
    setAttendanceListData(MemberAttendanceListData(content))
  }, [content])

  const [rows, setData] = useState(attendanceListData.tableRowsData)
  useEffect(() => {
    setData(attendanceListData.tableRowsData)
  }, [attendanceListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  return (
    <>
      <ReactTableBase columns={attendanceListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
    </>
  )
}

export default MemberHistoryAttendance

MemberHistoryAttendance.propTypes = {
  memberId: PropTypes.string.isRequired,
}
