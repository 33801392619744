import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const SportManagementTabsV2 = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/sport/fixture')
                }}
              >
                프리매치 관리<span style={{ color: 'blue' }}>(자동)</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="12"
                onClick={e => {
                  history.push('/sport/live-fixture')
                }}
              >
                인플레이 관리<span style={{ color: 'blue' }}>(자동)</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/sport/fixture/manual')
                }}
              >
                프리매치 관리<span style={{ color: 'red' }}>(수동)</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/sport/live-fixture/manual')
                }}
              >
                인플레이 관리<span style={{ color: 'red' }}>(수동)</span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/sport/unprocessed-fixture')
                }}
              >
                미처리 경기
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/management/sport/settlements')
                }}
              >
                스포츠 정산
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="9"
                onClick={e => {
                  history.push('/sport/manual')
                }}
              >
                수동 경기등록
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="6"
                onClick={e => {
                  history.push('/management/sport/betting/manage')
                }}
              >
                스포츠 배당 설정
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                eventKey="13"
                onClick={e => {
                  history.push('/sport/basic')
                }}
              >
                스포츠 기본 설정
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                eventKey="7"
                onClick={e => {
                  history.push('/sport/sport-location')
                }}
              >
                종목&국가 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="8"
                onClick={e => {
                  history.push('/management/sport/setting')
                }}
              >
                마켓&조합 관리
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default SportManagementTabsV2

SportManagementTabsV2.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
