import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import { useSearchMemberAttendanceHistory } from '../../../utils/api/memberHistory/memberHistoryApi'
import { convertToKst } from '../../../utils/dateTime'
import MemberHistoryTabs from '../MemberHistoryTabs'
import MemberAttendanceListData from './MemberAttendanceListData'
import { MemberPointDepositReceiverTypeEnumsSelectOptions } from '../../../enums/MemberHistory/MemberPointDepositEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'

const MemberAttendanceList = () => {
  /**
    포인트 입금내역 조회
   */
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)

  const [params, setParams] = useState({
    memberId: null,
    authority: null,
    edgeName: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const { totalElement, content } = useSearchMemberAttendanceHistory(params)

  const [attendanceListData, setAttendanceListData] = useState(MemberAttendanceListData(content))

  useEffect(() => {
    setAttendanceListData(MemberAttendanceListData(content))
  }, [content])

  const [rows, setData] = useState(attendanceListData.tableRowsData)
  useEffect(() => {
    setData(attendanceListData.tableRowsData)
  }, [attendanceListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 구분
  const [searchReceiverType, setSearchReceiverType] = useState('')

  const [searchReceiverTypeOptionValue, setSearchReceiverTypeOptionValue] = useState(null)
  const searchReceiverTypeOption = MemberPointDepositReceiverTypeEnumsSelectOptions()

  const onSelectTypeHandler = option => {
    if (option === null) {
      setSearchReceiverTypeOptionValue(null)
      setSearchReceiverType(null)
      return
    }
    setSearchReceiverTypeOptionValue(option)
    setSearchReceiverType(option.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      memberId: !searchMemberId ? null : searchMemberId,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }

  const [activeKey, setActiveKey] = useState('6')
  return (
    <Card>
      <CardBody>
        <MemberHistoryTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={attendanceListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default MemberAttendanceList
