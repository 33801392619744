import axios from '../base/axios'

// 경기 목록 조회
export async function searchSportFixtureList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅한 경기 마켓 조회
export async function searchSportFixtureBettingMarketList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture/betting-market',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅한 경기 마켓 세부 배팅내역 조회
export async function searchSportFixtureBettingOneInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture/betting-market/bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 경기 노출여부 설정
export async function updateSportFixtureVisible(fixtureId, body) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/fixture/${fixtureId}/visible`,
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 경기 인기게임여부 설정
export async function updateSportFixturePopularVisible(fixtureId, body) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/fixture/${fixtureId}/popular-fixture`,
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 경기 노출여부 설정
export async function updateSportFixtureIgnoreApiSignal(fixtureId, body) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/fixture/${fixtureId}/ignoreApiSignal`,
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 경기 마켓 리스트 조회
export async function searchSportFixtureMarketList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture/market',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 경기 내 마켓 노출 여부 수정
export async function updateSportFixtureMarketVisible(eventBookmakerKey, body) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/fixture/market/${eventBookmakerKey}/visible`,
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 경기 마켓 내 특정 배당 수정
export async function updateSportFixtureMarketBetList(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture/market/bet',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 경기 마켓 내 특정 배당 수정
export async function updateSportManualFixtureMarketStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/manual/sports-event-list/status',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function updateSportManualFixtureMarketDelete(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/manual/sports-event-list/delete',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
