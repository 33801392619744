import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const MiniGameManagementTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="9"
                onClick={e => {
                  history.push('/management/system/minigame-ntry')
                }}
              >
                미니 게임사 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="8"
                onClick={e => {
                  history.push('/management/log/minigame-result')
                }}
              >
                미니게임 결과로그
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default MiniGameManagementTabs

MiniGameManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
