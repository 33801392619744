export const ZeroBettingEnums = {
  USING: '진행중',
  END: '종료',
}

export const BettingResultEnums = {
  BET: '배팅',
  WIN: '당첨',
  LOSE: '낙첨',
  DRAW: '무효',
  CANCEL: '취소',
  EXCEED_CREDIT: '최대 당첨금 제한',
}

export const BettingResultEnumsSelectOptions = () => {
  return Object.entries(BettingResultEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bettingGameTypeEnums = {
  BACCARAT: 'BACCARAT',
  DRAGONTIGER: 'DRAGONTIGER',
  TOPCARD: 'TOPCARD',
  BLACKJACK: 'BLACKJACK',
  AMERICANROULETTE: 'AMERICANROULETTE',
  ROULETTE: 'ROULETTE',
  INSTANTROULETTE: 'INSTANTROULETTE',
  CRAPS: 'CRAPS',
  LIVE: 'LIVE',
  MEGABALL: 'MEGABALL',
  DICE: 'DICE',
  SICBO: 'SICBO',
  HOLDEM: 'HOLDEM',
  POKER: 'POKER',
  SIDEBETCITY: 'SIDEBETCITY',
  FANTAN: 'FANTAN',
  BACBO: 'BACBO',
  SLOT: 'SLOT',
  LOBBY: 'LOBBY',
  ANDARBAHAR: 'ANDARBAHAR',
  MONOPOLYBINGO: 'MONOPOLYBINGO',
  FISHING: 'FISHING',
  POCKER: 'POCKER',
  CRASHGAME: 'CRASHGAME',
  INTERACTIVEGAME: 'INTERACTIVEGAME',
  INSTANTGAME: 'INSTANTGAME',
  VIRTUAL: 'VIRTUAL',
  KENO: 'KENO',
  BINGO: 'BINGO',
  LOTTERY: 'LOTTERY',
  SCRATCHCARDS: 'SCRATCHCARDS',
  OTHER: 'OTHER',
  HIGHLOW: 'HIGHLOW',
  ROCKETON: 'ROCKETON',
  GAMESHOW: 'GAMESHOW',
  WAR: 'WAR',
  CATCHALL: 'CATCHALL',
  TEENPATTI: 'TEENPATTI',
  BULLBULL: 'BULLBULL',
  THREEFACE: 'THREEFACE',
  CARDS: 'CARDS',
  SHOOTING: 'SHOOTING',
  TABLEGAMES: 'TABLEGAMES',
  FISH: 'FISH',
  NIUNIU: 'NIUNIU',
  SEDIE: 'SEDIE',
  FISHSHRIMPCRAB: 'FISHSHRIMPCRAB',
  POWERBALL: 'POWERBALL',
  LIGHTNINGLOTTO: 'LIGHTNINGLOTTO',
  ARCADE: 'ARCADE',
  TABLE: 'TABLE',
  LOTTO: 'LOTTO',
  INSTANT: 'INSTANT',
  LIVE_SPORT: 'LIVE_SPORT',
  CARD: 'CARD',
  CRAZYPACHINKO: 'CRAZYPACHINKO',
  TOURNAMENT: 'TOURNAMENT',
}

export const bettingGameTypeEnumsSelectOptions = () => {
  return Object.entries(bettingGameTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const HoldemBettingResultEnums = {
  WIN: '당첨',
  LOSE: '낙첨',
}

export const HoldemBettingResultEnumsSelectOptions = () => {
  return Object.entries(HoldemBettingResultEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
