import React from 'react'
import { VendorIsInspectionStatus, gameStatusEnums, gameTypeEnums } from '../../../enums/SystemManagement/gameEnums'
import { casinoVendorEnums } from '../../../enums/SystemManagement/casinoEnums'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const GameListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '업체',
      accessor: 'agencyName',
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span>{casinoVendorEnums[original.agencyName]}</span>
      },
    },
    {
      Header: '게임사',
      accessor: 'vendorName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span>
            [{original.vendorNameKo}] - {original.vendorName}
          </span>
        )
      },
    },
    {
      Header: '순서',
      accessor: 'vendorOrderNum',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '점검여부',
      accessor: 'vendorIsInspection',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row

        if (original.vendorIsInspection) {
          return (
            <button type="button" style={{ backgroundColor: 'red', color: 'white', fontWeight: '900', border: 'none' }}>
              {VendorIsInspectionStatus[original.vendorIsInspection]}
            </button>
          )
        }
        return (
          <button type="button" style={{ backgroundColor: 'blue', color: 'white', fontWeight: '900', border: 'none' }}>
            {VendorIsInspectionStatus[original.vendorIsInspection]}
          </button>
        )
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'SHOW':
            return (
              <button
                type="button"
                style={{ backgroundColor: 'red', color: 'white', fontWeight: '900', border: 'none' }}
              >
                {gameStatusEnums[original.status]}
              </button>
            )
          case 'HIDE':
            return (
              <button
                type="button"
                style={{ backgroundColor: 'blue', color: 'white', fontWeight: '900', border: 'none' }}
              >
                {gameStatusEnums[original.status]}
              </button>
            )
          default:
            return (
              <button
                type="button"
                style={{ backgroundColor: 'blue', color: 'white', fontWeight: '900', border: 'none' }}
              >
                {gameStatusEnums[original.status]}
              </button>
            )
        }
      },
    },
    {
      Header: '타입',
      accessor: 'vendorType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return gameTypeEnums[original.vendorType]
      },
    },
    {
      Header: '비고',
      accessor: 'memo',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ fontWeight: 'bold', fontSize: '15px' }}>{original.memo}</span>
      },
    },
    {
      Header: '게임사 로고 이미지',
      accessor: 'vendorImg',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.vendorImg ? original.vendorImg : '-'
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default GameListData
