import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 파트너 조회 API
export async function searchPartnerSettlementStatisticsV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/partner/management/settlement',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 정산 통계 조회 API
export async function searchSettlementStatistics(params) {
  const response = await axios({
    url: '/tyson/admin/v1/statistics/settlement',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 정산 통계 조회 API
export async function searchSettlementStatisticsSpecify(params) {
  const response = await axios({
    url: '/tyson/admin/v1/statistics/settlement/specify',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 파트너 조회 API
export async function searchPartner(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartner(params) {
  // const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartner() {
    await searchPartner(params).then(response => {
      // setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartner()
  }, [params])

  return { content, fetchSearchPartner }
}

export async function searchPartnerTreeData(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/treedata',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 유저 입금신청 API
export async function createUserMoneyDeposit(body) {
  const response = await axios({
    url: '/tyson/v1/asset/moneydeposit',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 유저 출금신청 API
export async function createUserMoneyWithdrawal(body) {
  const response = await axios({
    url: '/tyson/v1/asset/moneywithdrawal',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 파트너 포인트전환 신청 API
export async function createPartnerPointExchange(body) {
  const response = await axios({
    url: '/tyson/v1/asset/partner/pointexchange',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 파트너 정보 조회
 */
export async function searchPartnerInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/partner-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 파트너 정보 변경
 */
export async function updatePartnerInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/partner-info',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 파트너 머니증감 정보 변경
 */
export async function updatePartnerMoneyIncDecInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/money-incdec',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
