/**
 * @See : https://ko.javascript.info/cookie
 */

import { getAccessToken } from '../utils/token'

/**
 * @TODO : https://www.npmjs.com/package/secure-web-storage 암호화 적용하기
 */

// 권한별 ENUM
export const UserAuthority = {
  MEMBER: '회원',
  PARTNER: '파트너',
}

export const UserAuthoritySelectOptions = () => {
  return Object.entries(UserAuthority).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

/**
 * 로그인 여부 확인
 * @see : sessiong storage 에 access token 존재 여부로 파악
 * @return : accessToken 이 undefined, '' , 0  이면 false , 존재하면 true
 */
const isLogin = () => !!getAccessToken()

export default isLogin
