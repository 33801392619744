import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 사이트관리 - IP 조회 API
export async function searchIpManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/ip',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 콤프 이벤트 설정 조회 API
export async function searchCompEventManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/comp-event',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 이벤트 설정 수정 API
export async function updateCompEventManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/comp-event',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 이벤트 설정 조회 API
export async function searchEventManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/event',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 이벤트 설정 수정 API
export async function updateEventManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/event',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 기본값 설정 조회 API
export async function searchDefaultBettingManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/default/betting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 기본값설정 수정 API
export async function updateDefaultBettingManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/default/betting',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 기본값설정 수정 API
export async function updateDefaultBettingManageAll(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/default/betting/all',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 스포츠 메뉴 수정 API
export async function updateSportsMenuManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/system/switch',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 시스템설정 조회 API
export async function searchSystemManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/system',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchSystemManage(params) {
  const [content, setContent] = useState([])

  async function fetchSearchSystemManage() {
    await searchSystemManage(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchSystemManage()
  }, [params])

  return { content, fetchSearchSystemManage }
}

// 사이트관리 - 시스템설정 수정 API
export async function updateSystemManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/system',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 시스템설정 수정 API
export async function updateIpManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/ip',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 레벨요율설정 조회 API
export async function searchLevelManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/level',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

export function useSearchLevelManage(params) {
  const [content, setContent] = useState([])

  async function fetchSearchLevelManage() {
    await searchLevelManage(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchLevelManage()
  }, [params])

  return { content, fetchSearchLevelManage }
}

// 사이트관리 - 초기회 API
export async function resetManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/reset',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 레벨요율설정 업데이트 API
export async function updateLevelManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/level',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 사이트관리 - 계좌정보설정 조회 API
export async function searchAccountNumManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/accountnum',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchAccountNumManage(params) {
  const [content, setContent] = useState([])

  async function fetchSearchAccountNumManage() {
    await searchAccountNumManage(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchAccountNumManage()
  }, [params])

  return { content, fetchSearchAccountNumManage }
}

// 사이트관리 - 계좌정보설정 등록 API
export async function createAccountNumManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/sitemanage/accountnum',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// URL관리 조회 API
export async function searchUrls(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/url',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// URL관리 파트너 리스트 조회 API
export async function searchUrlPartnerInfos(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/url/partners',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchUrls(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchUrls() {
    await searchUrls(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchUrls()
  }, [params])

  return { totalElement, content, fetchSearchUrls }
}

// url관리 - url수정 API
export async function updateUrlInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/url',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 게임사관리 조회 API
export async function searchVendors(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/casino/vendor',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchVendors(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchVendors() {
    await searchVendors(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchVendors()
  }, [params])

  return { totalElement, content, fetchSearchVendors }
}

// 게임사관리 - 게임 등록 API
export async function createGameManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/gamemanage/create',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 게임사관리 - 게임수정 API
export async function updateVendor(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/casino/vendor',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 게임사관리 - 게임수정 API
export async function updateRecentVendor(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/casino/vendor-game',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// vendor 리스트 조회 (select 용)
export async function searchVendorList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/casino/vendor-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
// 게임사관리 - 게임삭제 API
export async function deleteGameManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/gamemanage/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 매크로관리 조회 API
export async function searchMacroManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/macromanage/search',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMacroManage(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMacroManage() {
    await searchMacroManage(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMacroManage()
  }, [params])

  return { totalElement, content, fetchSearchMacroManage }
}

// 매크로 Select용 조회 API
export async function searchMacroSelect(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/macromanage/search/select',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 매크로관리 -등록 API
export async function createMacroManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/macromanage/create',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 매크로관리 -수정 API
export async function updateMacroManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/macromanage/update',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 매크로관리 -삭제 API
export async function deleteMacroManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/macromanage/delete',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 메인캐러셀 등록 API
export async function createMainCarouselImgManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/image/maincarousel',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 메인캐러셀 이미지 조회 API
export async function searchMainCarouselImgManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/image/maincarousel',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMainCarouselImgManage(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMainCarouselImgManage() {
    await searchMainCarouselImgManage(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMainCarouselImgManage()
  }, [params])

  return { totalElement, content, fetchSearchMainCarouselImgManage }
}

// 게임캐러셀 이미지 -등록 API
export async function createGameCarouselImgManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/mainimgmanage/gamecarousel/create',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 게임캐러셀 이미지 조회 API
export async function searchGameCarouselManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/image/gamecarousel',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchGameCarouselManage(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchGameCarouselManage() {
    await searchGameCarouselManage(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchGameCarouselManage()
  }, [params])

  return { totalElement, content, fetchSearchGameCarouselManage }
}

// 게임 캐러샐 수정
export async function updateGameCarousel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/image/gamecarousel',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 메인 캐러샐 수정
export async function updateMainCarousel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/image/maincarousel',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 메인 캐러샐 삭제
export async function deleteMainCarousel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/image/maincarousel',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
