import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 헤더 조회 API
export async function searchHeaderInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/header-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 헤더 폴링
export async function fetchHeaderPolling(params) {
  const response = await axios({
    url: '/tyson/admin/v1/header-info/polling',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 보유알 조회
export async function searchPotAmount(params) {
  const response = await axios({
    url: '/tyson/admin/v1/header-info/pot',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// Edge 조회
export async function searchEdgeInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/header-info/edge',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
