import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { SportTypeEnums, SportTypeEnumsSelectOptions } from '../../../enums/SystemManagement/sportEnums'
import { searchUnprocessedSports } from '../../../utils/api/sportsManage/sportsInfo'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import ResultRegistrationModal from '../GameInfoManagement/SportsEventList/ResultManagement/ResultRegistrationModal'
import SportManagementTabs from '../SportManagementTabs'
import UnprocessedSportsBettingLog from './UnprocessedSportsBettingLog'

const UnprocessedSportsEventList = () => {
  const [sportsInfoData, setSportsInfoData] = useState([])
  const [params, setParams] = useState({
    sportsType: null,
  })

  const createKey = (leagueName, startAt) => {
    return `${leagueName}-${startAt}`
  }

  // 경기 목록 조회
  const fetchSearchSportsInfo = async () => {
    await searchUnprocessedSports(params).then(res => {
      const groupedData = res.data.unprocessedSportsEventList.reduce((acc, item) => {
        const { leagueName, startAt } = item
        const key = createKey(leagueName, startAt)
        if (!acc[key]) {
          acc[key] = {
            leagueName: item.leagueName,
            leagueImage: item.leagueImage,
            ccKr: item.ccKr,
            ccImage: item.ccImage,
            sportsEvents: [],
          }
        }

        const newItem = { ...item, isVisible: false }

        acc[key].sportsEvents.push(newItem)
        return acc
      }, {})

      setSportsInfoData(groupedData)
    })
  }

  useEffect(() => {
    fetchSearchSportsInfo()
  }, [params])

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = SportTypeEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      sportsType: !searchGameType ? null : searchGameType,
    }))
  }

  // 결과 처리 모달
  const [isResultRegistrationModalShow, setResultRegistrationModalShow] = useState(false)
  const [resultEventId, setResultEventId] = useState(null)
  const [selectedSportsType, setSelectedSportsType] = useState(null)
  const onResultHandler = (eventId, type) => {
    setResultRegistrationModalShow(true)
    setResultEventId(eventId)
    setSelectedSportsType(type)
  }

  const showSportsBettingLogHandler = (key, sportsEventId) => {
    const updatedSportsEvents = [...sportsInfoData[key]?.sportsEvents]
    const idx = updatedSportsEvents.findIndex(item => item.sportsEventId === sportsEventId)

    if (idx !== -1) {
      updatedSportsEvents[idx].isVisible = !updatedSportsEvents[idx].isVisible

      setSportsInfoData(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          sportsEvents: updatedSportsEvents,
        },
      }))
    }
  }

  const checkEventStatus = statusKr => {
    switch (statusKr) {
      case '경기종료':
        return <span style={{ color: '#66ff82' }}>{statusKr}</span>
      case '경기취소':
        return <span style={{ color: 'red' }}>{statusKr}</span>
      case '경기지연':
        return <span style={{ color: 'red' }}>{statusKr}</span>
      case '경기중단':
        return <span style={{ color: 'red' }}>{statusKr}</span>
      case '경기중':
        return <span style={{ color: '#62a3fc' }}>{statusKr}</span>
      case '경기전':
        return <span style={{ color: '#f7f368' }}>{statusKr}</span>
      default:
        return <span style={{ color: 'white' }}>{statusKr}</span>
    }
  }

  return (
    <Card>
      <CardBody>
        <SportManagementTabs activeKey="8" />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 해당 페이지는 유저가 배팅간 경기 중 <span style={{ color: 'red', fontWeight: '500' }}>결과 처리가</span>{' '}
              되지 않았을 때 나오는 페이지입니다.
            </p>
            <p>
              * 배팅 내역 <span style={{ color: 'blue', fontWeight: '500' }}>자세히 버튼</span> 클릭시{' '}
              <span style={{ color: 'blue', fontWeight: '500' }}>해당 경기의 유저 배팅 정보</span>를 확인 및 변경 할 수
              있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>경기 종목</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameType"
                placeholder="종류"
                onChange={option => {
                  onSelectGameTypeOptionHandler(option)
                }}
                value={searchGameTypeOptionValue}
                options={searchGameTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <SportsInfoTableWrap>
          {sportsInfoData &&
            Object.keys(sportsInfoData).map(key => (
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '100%', textAlign: 'left', paddingLeft: '20px' }}>
                      <SportsInfoLeagueWrap>
                        {sportsInfoData[key].leagueImage && <img src={sportsInfoData[key].leagueImage} alt="" />}
                        <span>{sportsInfoData[key].leagueName}</span>
                        <span>
                          - [&nbsp;{sportsInfoData[key].ccImage && <img src={sportsInfoData[key].ccImage} alt="" />}
                          <span>{sportsInfoData[key].ccKr}</span>&nbsp;]
                        </span>
                      </SportsInfoLeagueWrap>
                    </th>
                  </tr>
                  <tr>
                    <th style={{ width: '6%' }}>경기 종목</th>
                    <th style={{ width: '12%' }}>경기 번호</th>
                    <th style={{ width: '8%' }}>경기 날짜</th>
                    <th style={{ width: '20%' }}>국가 - 리그</th>
                    <th style={{ width: '25%' }}>경기명</th>
                    <th style={{ width: '6%' }}>경기 스코어</th>
                    <th style={{ width: '8%' }}>결과 처리</th>
                    <th style={{ width: '9%' }}>배팅 내역 (총 폴더수)</th>
                    <th style={{ width: '6%' }}>경기 상태</th>
                  </tr>
                </thead>
                <tbody>
                  {sportsInfoData[key].sportsEvents.map(sportsEvent => (
                    <>
                      <tr>
                        <SportsEventWrap>
                          <SportsEventTypeWrap>
                            <span>{SportTypeEnums[sportsEvent.sportsType]}</span>
                          </SportsEventTypeWrap>
                          <SportsEventIdWrap>
                            {sportsEvent.sportsEventId} - {sportsEvent.prematchId}
                          </SportsEventIdWrap>
                          <SportsEventDateWrap>
                            {convertToKstSportsEventDateTime(sportsEvent.startAt)}
                          </SportsEventDateWrap>
                          <SportsEventNationalWrap>
                            <span>
                              {sportsEvent.ccKr} - {sportsEvent.leagueName}
                            </span>
                          </SportsEventNationalWrap>
                          <SportsEventTeamWrap>
                            <span>{sportsEvent.homeName}</span>
                            <span>VS</span>
                            <span>{sportsEvent.awayName}</span>
                          </SportsEventTeamWrap>
                          <SportsEventScoreWrap>
                            <span>{sportsEvent.score}</span>
                          </SportsEventScoreWrap>
                          <SportsEventResultWrap>
                            <SportsEventResultBtn
                              size="sm"
                              onClick={() => {
                                onResultHandler(sportsEvent.sportsEventId, sportsEvent.sportsType)
                              }}
                            >
                              결과처리
                            </SportsEventResultBtn>
                          </SportsEventResultWrap>
                          <SportsEventShowWrap>
                            <SportsEventResultBtn
                              size="sm"
                              onClick={() => {
                                showSportsBettingLogHandler(
                                  createKey(sportsEvent.leagueName, sportsEvent.startAt),
                                  sportsEvent.sportsEventId,
                                )
                              }}
                            >
                              자세히 ({Object.values(sportsEvent.bettingLogs).flat().length})
                            </SportsEventResultBtn>
                          </SportsEventShowWrap>
                          <SportsEventStatusWrap>{checkEventStatus(sportsEvent.statusKr)}</SportsEventStatusWrap>
                        </SportsEventWrap>
                      </tr>
                      {sportsEvent.isVisible && <UnprocessedSportsBettingLog content={sportsEvent.bettingLogs} />}
                    </>
                  ))}
                </tbody>
              </table>
            ))}
        </SportsInfoTableWrap>
        {isResultRegistrationModalShow && selectedSportsType && (
          <ResultRegistrationModal
            isResultRegistrationModalShow={isResultRegistrationModalShow}
            setResultRegistrationModalShow={setResultRegistrationModalShow}
            fetchSearchSportsInfo={fetchSearchSportsInfo}
            resultEventId={resultEventId}
            sportsType={selectedSportsType}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default UnprocessedSportsEventList

const SportsInfoTableWrap = styled.div`
  width: 100%;

  table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    thead {
      tr {
        th {
          padding: 4px 4px;
          border: 1px solid #888;
          white-space: nowrap;
          color: black;
          font-weight: 600;
          font-size: 12px;
          text-align: center;
        }
      }
    }

    tbody {
      gap: 10px;

      tr {
        &:hover {
          cursor: pointer;
          border: 2px solid red;
        }
      }
    }

    tr {
      width: 100%;
      display: flex;
    }
  }
`

const SportsInfoLeagueWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`
const SportsEventResultBtn = styled(Button)`
  background: #f59a0a;
  color: white;
  border: none;
`

const SportsEventIdWrap = styled.div`
  width: 12%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventDateWrap = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventNationalWrap = styled.div`
  width: 20%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);

  span {
    margin-left: 10px;
    text-align: center;
  }
`

const SportsEventTeamWrap = styled.div`
  width: 25%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);

  img {
    width: 22px;
    height: 22px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    margin-left: 10px;
    text-align: center;
  }
`

const SportsEventShowWrap = styled.div`
  width: 9%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
`

const SportsEventResultWrap = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventScoreWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventStatusWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventTypeWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventWrap = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventMarketTr = styled.tr`
  width: 100%;
  display: flex;

  border: 1px solid red;

  padding: 5px 5px;
`

const SportsEventMarketContent = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid blue;
`
