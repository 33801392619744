import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 회원 환수율 조회 시작
export async function searchMemberMoneyExchangeRate(params) {
  const response = await axios({
    url: '/tyson/admin/v1/user/exchange/rate',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 기간내 조회 시작
export async function searchMemberDateMoneyInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/date-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니입금내역 조회 시작
export async function searchMemberMoneyWithdrawalInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/withdrawal-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니입금내역 조회 시작 - V2
export async function searchMemberMoneyWithdrawalInfoV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/asset/member/withdrawal-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니입금내역 조회 시작
export async function searchMemberMoneyDepositInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/deposit-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니입금내역 조회 시작
export async function searchMemberMoneyDeposit(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/deposit/history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니입금내역 조회 시작 - V2
export async function searchMemberMoneyDepositV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/asset/member/deposit/history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberMoneyDeposit(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberMoneyDeposit() {
    await searchMemberMoneyDeposit(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyDeposit()
  }, [params])

  return { totalElement, content, fetchSearchMemberMoneyDeposit }
}
// 회원 머니입금내역 조회 끝

// 회원 머니 입금 승윈/취 변경
export async function updateMemberMoneyDepositStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/deposit',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니 입금 승윈/취 변경 - V2
export async function updateMemberMoneyDepositStatusV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/asset/member/deposit',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니 입금 승윈/취 변경
export async function updateMemberMoneyDepositModify(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/deposit-memo-modify',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니 입금 승윈/취 변경
export async function updateMemberMoneyWithdrawalModify(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/withdrawal-memo-modify',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니출금내역 조회 시작
export async function searchMemberMoneyWithdrawal(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/withdrawal',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니출금내역 조회 시작 -V2
export async function searchMemberMoneyWithdrawalV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/asset/member/withdrawal',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberMoneyWithdrawal(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberMoneyWithdrawal() {
    await searchMemberMoneyWithdrawal(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyWithdrawal()
  }, [params])

  return { totalElement, content, fetchSearchMemberMoneyWithdrawal }
}
// 회원 머니출금내역 조회 끝

// 회원 머니 출금내역 삭제
export async function updateMemberMoneyHistoryDelete(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/delete/moneylist',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니 출금 상태 변경
export async function updateMemberMoneyWithdrawalStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/withdrawal',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 회원 머니이동내역 조회 시작
export async function searchMemberMoneyExchange(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/money-exchange',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 머니이동내역 삭제
export async function deleteMoneyExchange(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/money-exchange',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberMoneyExchange(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberMoneyExchange() {
    await searchMemberMoneyExchange(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyExchange()
  }, [params])

  return { totalElement, content, fetchSearchMemberMoneyExchange }
}
// 회원 머니이동내역 조회 끝

// 회원 출석내역 조회 시작
export async function searchMemberAttendance(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/attendance/history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberAttendanceHistory(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberAttendance() {
    await searchMemberAttendance(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberAttendance()
  }, [params])

  return { totalElement, content, fetchSearchMemberAttendance }
}

// 루징 포인트 입금내역 조회 시작
export async function searchPartnerLosingPointDeposit(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/losing-point-deposit',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerLosingPointDeposit(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchPartnerLosingPointDeposit() {
    await searchPartnerLosingPointDeposit(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerLosingPointDeposit()
  }, [params])

  return { totalElement, content, fetchSearchPartnerLosingPointDeposit }
}

// 회원 포인트 입금내역 조회 시작
export async function searchMemberPointDeposit(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberPointDeposit(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberPointDeposit() {
    await searchMemberPointDeposit(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberPointDeposit()
  }, [params])

  return { totalElement, content, fetchSearchMemberPointDeposit }
}
// 회원 포인트 입금내역 조회 끝

export async function downloadMemberPointDepositList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit-download',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function downloadLosingPointDepositList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/losing-point-deposit-download',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
