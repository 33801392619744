import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import Switch from 'react-switch'
import styled from 'styled-components'
import { BetSettlementStatus, SportMarketType } from '../../../enums/Sport/SportMarketEnums'
import { SportFixtureTabeItemBettingInfo } from './SportFixtureTabeItemBettingInfo'

const areEqual = (prevProps, nextProps) => {
  return prevProps.fixtureInfo === nextProps.fixtureInfo && isEqual(prevProps.fixtureInfo, nextProps.fixtureInfo) // deepEqual은 깊은 비교를 수행하는 함수를 의미
}

export const SportLiveFixtureTabeItemBettingMarket = React.memo(
  ({ fixtureInfo, bettingMarketTrOpenHandler, bettingInfoTrOpenHandler }) => {
    const [isExpanded2, setIsExpanded2] = useState(false)
    return (
      <SportDetailBettingLogWrap>
        <td colSpan={15} style={{ padding: '10px' }}>
          {fixtureInfo.bettingMarketTrShow &&
            (fixtureInfo.fixtureBettingMarketList?.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th colSpan={3}>자세히</th>
                    <th colSpan={3}>마켓ID</th>
                    <th colSpan={4}>마켓명</th>
                    <th colSpan={4}>배팅횟수</th>
                  </tr>
                </thead>
                <tbody>
                  {fixtureInfo.fixtureBettingMarketList?.map(item => (
                    <>
                      <tr
                        key={item.marketId}
                        onClick={() => {
                          const key = `${fixtureInfo.fixture.leagueId}-${fixtureInfo.fixture.startDate}`
                          bettingInfoTrOpenHandler(key, fixtureInfo.fixtureId, !item.bettingInfoTrShow, item.marketId)
                          setIsExpanded2(false) // FaMinusCircle 클릭 시 상태 변경
                        }}
                      >
                        <td colSpan={3}>
                          {isExpanded2 ? (
                            <FaMinusCircle
                              style={{ color: 'red' }}
                              onClick={() => {
                                const key = `${fixtureInfo.fixture.leagueId}-${fixtureInfo.fixture.startDate}`
                                bettingInfoTrOpenHandler(
                                  key,
                                  fixtureInfo.fixtureId,
                                  !item.bettingInfoTrShow,
                                  item.marketId,
                                )
                                setIsExpanded2(false) // FaMinusCircle 클릭 시 상태 변경
                              }}
                            />
                          ) : (
                            <FaPlusCircle
                              style={{ color: 'green' }}
                              onClick={() => {
                                const key = `${fixtureInfo.fixture.leagueId}-${fixtureInfo.fixture.startDate}`
                                bettingInfoTrOpenHandler(key, fixtureInfo.fixtureId, !item.bettingInfoTrShow)
                                setIsExpanded2(true) // FaPlusCircle 클릭 시 상태 변경
                              }}
                            />
                          )}
                        </td>
                        <td colSpan={3}>{item.marketId}</td>
                        <StyledTd isHighlighted2 colSpan={4} style={{ color: 'green' }}>
                          {item.marketNameKo}
                        </StyledTd>
                        <td colSpan={4}>{item.bettingCount}</td>
                      </tr>
                      <SportFixtureTabeItemBettingInfo fixtureInfo={fixtureInfo} item={item} />
                    </>
                  ))}
                </tbody>
              </table>
            ) : (
              <tr style={{ fontWeight: '600', color: '#000', textAlign: 'center' }}>배팅내역이 없습니다.</tr>
            ))}
        </td>
      </SportDetailBettingLogWrap>
    )
  },
  areEqual,
)

SportLiveFixtureTabeItemBettingMarket.propTypes = {
  fixtureInfo: PropTypes.shape().isRequired,
  bettingMarketTrOpenHandler: PropTypes.func.isRequired,
  bettingInfoTrOpenHandler: PropTypes.func.isRequired,
}

const StyledTd = styled.td`
  background: rgb(209, 213, 219);

  ${props =>
    props.isHighlighted4 &&
    `
background: rgba(120, 179, 66, 0.2);
`}

  ${props =>
    props.isHighlighted3 &&
    `
background: rgba(120, 179, 66, 0.5);
`}

  ${props =>
    props.isHighlighted &&
    `
  background: rgb(252, 247, 164);
  `}

  ${props =>
    props.isHighlighted2 &&
    `
  background: rgb(252, 247, 164,0.2);
  `}
`

const SportDetailBettingLogWrap = styled.tr`
  border: 1px solid #888;

  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #6b6b6b !important;

      &:first-child {
        border-top: 1px solid #888;
      }

      &:hover {
        border: none !important;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: #fff !important;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      background: #fbf7ea;

      &:hover {
        cursor: pointer;
        border: 2px solid blue !important;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
