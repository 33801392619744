import { Card, CardBody } from '@/shared/components/Card'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { EditorState } from 'draft-js'
import { Field, Form } from 'react-final-form'
import { Editor } from 'react-draft-wysiwyg'
import PropTypes from 'prop-types'
import showResults from '../../../utils/showResults'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import SystemManagementTabs from '../SystemManagementTabs'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import { convertFromFront, convertFromServer } from '../../../utils/editor'
import {
  searchWithdrawalRolling,
  searchWithdrawalRule,
  updateWithdrawalRolling,
  updateWithdrawalRule,
} from '../../../utils/api/systemManage/depositWithdrawalRuleManageApi'
import Loading from '../../../shared/components/Loading'
import { decodeAccessToken } from '../../../utils/token'

const WithdrawalRuleForm = ({ edgeName }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [withdrawalRuleContent, setWithdrawalRuleContent] = useState(EditorState.createEmpty())
  const [newSportsSingleLiveRolling, setNewSportsSingleLiveRolling] = useState(0)
  const [newSportsTwoRolling, setNewSportsTwoRolling] = useState(0)
  const [newSportsMultiRolling, setNewSportsMultiRolling] = useState(0)
  const [newMiniGameRolling, setNewMiniGameRolling] = useState(0)
  const [newVirtualGameRolling, setNewVirtualGameRolling] = useState(0)
  const [newCasinoRolling, setNewCasinoRolling] = useState(0)
  const [newSlotRolling, setNewSlotRolling] = useState(0)
  const [newHoldemRolling, setNewHoldemRolling] = useState(0)
  const [firstSportsSingleLiveRolling, setFirstSportsSingleLiveRolling] = useState(0)
  const [firstSportsTwoRolling, setFirstSportsTwoRolling] = useState(0)
  const [firstSportsMultiRolling, setFirstSportsMultiRolling] = useState(0)
  const [firstMiniGameRolling, setFirstMiniGameRolling] = useState(0)
  const [firstVirtualGameRolling, setFirstVirtualGameRolling] = useState(0)
  const [firstCasinoRolling, setFirstCasinoRolling] = useState(0)
  const [firstSlotRolling, setFirstSlotRolling] = useState(0)
  const [firstHoldemRolling, setFirstHoldemRolling] = useState(0)
  const [everySportsSingleLiveRolling, setEverySportsSingleLiveRolling] = useState(0)
  const [everySportsTwoRolling, setEverySportsTwoRolling] = useState(0)
  const [everySportsMultiRolling, setEverySportsMultiRolling] = useState(0)
  const [everyMiniGameRolling, setEveryMiniGameRolling] = useState(0)
  const [everyVirtualGameRolling, setEveryVirtualGameRolling] = useState(0)
  const [everyCasinoRolling, setEveryCasinoRolling] = useState(0)
  const [everySlotRolling, setEverySlotRolling] = useState(0)
  const [everyHoldemRolling, setEveryHoldemRolling] = useState(0)
  const [sportsSingleLiveRollingNotBonus, setSportsSingleLiveRollingNotBonus] = useState(0)
  const [sportsTwoRollingNotBonus, setSportsTwoRollingNotBonus] = useState(0)
  const [sportsMultiRollingNotBonus, setSportsMultiRollingNotBonus] = useState(0)
  const [miniGameRollingNotBonus, setMiniGameRollingNotBonus] = useState(0)
  const [virtualGameRollingNotBonus, setVirtualGameRollingNotBonus] = useState(0)
  const [casinoRollingNotBonus, setCasinoRollingNotBonus] = useState(0)
  const [slotRollingNotBonus, setSlotRollingNotBonus] = useState(0)
  const [holdemRollingNotBonus, setHoldemRollingNotBonus] = useState(0)

  const fetchSearchWithdrawalRule = async () => {
    await searchWithdrawalRule().then(res => {
      setWithdrawalRuleContent(convertFromServer(res.data.withdrawalRuleContent))
    })
  }

  const fetchSearchWithdrawalRolling = async () => {
    await searchWithdrawalRolling().then(res => {
      setNewSportsSingleLiveRolling(res.data.newSportsSingleLiveRolling)
      setNewSportsTwoRolling(res.data.newSportsTwoRolling)
      setNewSportsMultiRolling(res.data.newSportsMultiRolling)
      setNewMiniGameRolling(res.data.newMiniGameRolling)
      setNewVirtualGameRolling(res.data.newVirtualGameRolling)
      setNewCasinoRolling(res.data.newCasinoRolling)
      setNewSlotRolling(res.data.newSlotRolling)
      setNewHoldemRolling(res.data.newHoldemRolling)
      setFirstSportsSingleLiveRolling(res.data.firstSportsSingleLiveRolling)
      setFirstSportsTwoRolling(res.data.firstSportsTwoRolling)
      setFirstSportsMultiRolling(res.data.firstSportsMultiRolling)
      setFirstMiniGameRolling(res.data.firstMiniGameRolling)
      setFirstVirtualGameRolling(res.data.firstVirtualGameRolling)
      setFirstCasinoRolling(res.data.firstCasinoRolling)
      setFirstSlotRolling(res.data.firstSlotRolling)
      setFirstHoldemRolling(res.data.firstHoldemRolling)
      setEverySportsSingleLiveRolling(res.data.everySportsSingleLiveRolling)
      setEverySportsTwoRolling(res.data.everySportsTwoRolling)
      setEverySportsMultiRolling(res.data.everySportsMultiRolling)
      setEveryMiniGameRolling(res.data.everyMiniGameRolling)
      setEveryVirtualGameRolling(res.data.everyVirtualGameRolling)
      setEveryCasinoRolling(res.data.everyCasinoRolling)
      setEverySlotRolling(res.data.everySlotRolling)
      setEveryHoldemRolling(res.data.everyHoldemRolling)
      setSportsSingleLiveRollingNotBonus(res.data.sportsSingleLiveRollingNotBonus)
      setSportsTwoRollingNotBonus(res.data.sportsTwoRollingNotBonus)
      setSportsMultiRollingNotBonus(res.data.sportsMultiRollingNotBonus)
      setMiniGameRollingNotBonus(res.data.miniGameRollingNotBonus)
      setVirtualGameRollingNotBonus(res.data.virtualGameRollingNotBonus)
      setCasinoRollingNotBonus(res.data.casinoRollingNotBonus)
      setSlotRollingNotBonus(res.data.slotRollingNotBonus)
      setHoldemRollingNotBonus(res.data.holdemRollingNotBonus)
    })
  }

  useEffect(() => {
    fetchSearchWithdrawalRule()
    fetchSearchWithdrawalRolling()
  }, [])

  const onChangeWithdrawalRuleContent = withdrawalData => {
    setWithdrawalRuleContent(withdrawalData)
  }

  const [withdrawalRuleApiLoading, setWithdrawalRuleApiLoading] = useState(false)

  const onWithdrawalRuleSubmit = e => {
    if (withdrawalRuleApiLoading) return

    setWithdrawalRuleApiLoading(true)

    const body = {
      withdrawalRuleContent: convertFromFront(withdrawalRuleContent),
    }

    if (window.confirm('출금신청 규정을 수정하시겠습니까?')) {
      updateWithdrawalRule(body)
        .then(res => {
          alert('수정되었습니다.')
          fetchSearchWithdrawalRule()
          setWithdrawalRuleApiLoading(false)
        })
        .catch(error => {
          alert('수정 실패했습니다.')
          setWithdrawalRuleApiLoading(false)
        })
    }
  }

  const onWithdrawalRollingSubmit = e => {
    if (withdrawalRuleApiLoading) return

    setWithdrawalRuleApiLoading(true)

    const body = {
      newSportsSingleLiveRolling,
      newSportsTwoRolling,
      newSportsMultiRolling,
      newMiniGameRolling,
      newVirtualGameRolling,
      newCasinoRolling,
      newSlotRolling,
      newHoldemRolling,
      firstSportsSingleLiveRolling,
      firstSportsTwoRolling,
      firstSportsMultiRolling,
      firstMiniGameRolling,
      firstVirtualGameRolling,
      firstCasinoRolling,
      firstSlotRolling,
      firstHoldemRolling,
      everySportsSingleLiveRolling,
      everySportsTwoRolling,
      everySportsMultiRolling,
      everyMiniGameRolling,
      everyVirtualGameRolling,
      everyCasinoRolling,
      everySlotRolling,
      everyHoldemRolling,
      sportsSingleLiveRollingNotBonus,
      sportsTwoRollingNotBonus,
      sportsMultiRollingNotBonus,
      miniGameRollingNotBonus,
      virtualGameRollingNotBonus,
      casinoRollingNotBonus,
      slotRollingNotBonus,
      holdemRollingNotBonus,
    }

    if (window.confirm('출금신청시 필요롤링 비율을 수정하시겠습니까?')) {
      updateWithdrawalRolling(body)
        .then(res => {
          alert('수정되었습니다.')
          fetchSearchWithdrawalRolling()
          setWithdrawalRuleApiLoading(false)
        })
        .catch(error => {
          alert('수정 실패했습니다.')
          setWithdrawalRuleApiLoading(false)
        })
    }
  }

  return (
    <WithdrawalRuleBox>
      <HeaderBox>출금신청시 필요 롤링 설정</HeaderBox>
      {withdrawalRuleApiLoading && <Loading />}
      <Form
        onSubmit={onWithdrawalRollingSubmit}
        initialValues={{
          newSportsSingleLiveRolling,
          newSportsTwoRolling,
          newSportsMultiRolling,
          newMiniGameRolling,
          newVirtualGameRolling,
          newCasinoRolling,
          newSlotRolling,
          newHoldemRolling,
          firstSportsSingleLiveRolling,
          firstSportsTwoRolling,
          firstSportsMultiRolling,
          firstMiniGameRolling,
          firstVirtualGameRolling,
          firstCasinoRolling,
          firstSlotRolling,
          firstHoldemRolling,
          everySportsSingleLiveRolling,
          everySportsTwoRolling,
          everySportsMultiRolling,
          everyMiniGameRolling,
          everyVirtualGameRolling,
          everyCasinoRolling,
          everySlotRolling,
          everyHoldemRolling,
          sportsSingleLiveRollingNotBonus,
          sportsTwoRollingNotBonus,
          sportsMultiRollingNotBonus,
          miniGameRollingNotBonus,
          virtualGameRollingNotBonus,
          casinoRollingNotBonus,
          slotRollingNotBonus,
          holdemRollingNotBonus,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <FormContainer onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderLeft: '1px solid black', background: 'grey' }} />
                    <th>스포츠 단폴/실시간 (%) </th>
                    <th>스포츠 두폴 (%)</th>
                    <th>스포츠 세폴이상 (%)</th>

                    <th>미니게임 (%)</th>
                    <th>가상게임 (%)</th>
                    <th>카지노 (%)</th>
                    <th>슬롯 (%)</th>
                    <th>홀덤 (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ borderLeft: '1px solid black' }}>
                      필요롤링 (보너스 받음)
                      <br />
                      <span style={{ color: 'red', fontWeight: '500' }}>신규첫충</span>
                    </td>
                    <td>
                      <Field name="newSportsSingleLiveRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newSportsSingleLiveRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewSportsSingleLiveRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="newSportsTwoRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newSportsTwoRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewSportsTwoRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="newSportsMultiRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newSportsMultiRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewSportsMultiRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>

                    <td>
                      <Field name="newMiniGameRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newMiniGameRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewMiniGameRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="newVirtualGameRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newVirtualGameRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewVirtualGameRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="newCasinoRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newCasinoRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewCasinoRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="newSlotRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newSlotRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewSlotRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="newHoldemRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={newHoldemRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setNewHoldemRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ borderLeft: '1px solid black' }}>
                      필요롤링 (보너스 받음)
                      <br />
                      <span style={{ color: 'red', fontWeight: '500' }}>매일첫충/돌발</span>
                    </td>
                    <td>
                      <Field name="firstSportsSingleLiveRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstSportsSingleLiveRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstSportsSingleLiveRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="firstSportsTwoRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstSportsTwoRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstSportsTwoRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="firstSportsMultiRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstSportsMultiRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstSportsMultiRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>

                    <td>
                      <Field name="firstMiniGameRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstMiniGameRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstMiniGameRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="firstVirtualGameRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstVirtualGameRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstVirtualGameRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="firstCasinoRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstCasinoRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstCasinoRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="firstSlotRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstSlotRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstSlotRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="firstHoldemRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={firstHoldemRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setFirstHoldemRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ borderLeft: '1px solid black' }}>
                      필요롤링 (보너스 받음)
                      <br />
                      <span style={{ color: 'red', fontWeight: '500' }}>매일매충</span>
                    </td>
                    <td>
                      <Field name="everySportsSingleLiveRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everySportsSingleLiveRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEverySportsSingleLiveRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="everySportsTwoRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everySportsTwoRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEverySportsTwoRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="everySportsMultiRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everySportsMultiRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEverySportsMultiRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>

                    <td>
                      <Field name="everyMiniGameRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everyMiniGameRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEveryMiniGameRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="everyVirtualGameRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everyVirtualGameRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEveryVirtualGameRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="everyCasinoRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everyCasinoRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEveryCasinoRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="everySlotRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everySlotRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEverySlotRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="everyHoldemRolling">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={everyHoldemRolling}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setEveryHoldemRolling(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ borderLeft: '1px solid black' }}>
                      필요롤링 <span style={{ color: 'blue', fontWeight: '500' }}>(보너스 없음)</span>
                    </td>
                    <td>
                      <Field name="sportsSingleLiveRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={sportsSingleLiveRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setSportsSingleLiveRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="sportsTwoRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={sportsTwoRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setSportsTwoRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="sportsMultiRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={sportsMultiRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setSportsMultiRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>

                    <td>
                      <Field name="miniGameRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={miniGameRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setMiniGameRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="virtualGameRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={virtualGameRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setVirtualGameRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="casinoRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={casinoRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setCasinoRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="slotRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={slotRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setSlotRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                    <td>
                      <Field name="holdemRollingNotBonus">
                        {({ input, meta }) => (
                          <InputBox>
                            <input
                              {...input}
                              type="text"
                              value={holdemRollingNotBonus}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setHoldemRollingNotBonus(onlyNumber)
                              }}
                            />
                          </InputBox>
                        )}
                      </Field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableWrap>
            {
              {
                SUPER: (
                  <ButtonBox
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <button type="submit" style={{ background: 'grey', width: '120px', padding: '10px' }}>
                      수정하기
                    </button>
                  </ButtonBox>
                ),
              }[authority]
            }
          </FormContainer>
        )}
      </Form>
      <HeaderBox>출금신청 규정 작성하는곳</HeaderBox>
      <Form
        onSubmit={onWithdrawalRuleSubmit}
        initialValues={{
          withdrawalRuleContent,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <FormContainer onSubmit={handleSubmit}>
            <EditoryBox>
              <Editor
                editorState={withdrawalRuleContent}
                onEditorStateChange={editorState => onChangeWithdrawalRuleContent(editorState)}
                wrapperClassName="wrapper"
                editorClassName="editor"
                toolbarClassName="toolbar"
              />
            </EditoryBox>
            {
              {
                SUPER: (
                  <ButtonBox
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <button type="submit" style={{ background: 'grey', width: '120px', padding: '10px' }}>
                      수정하기
                    </button>
                  </ButtonBox>
                ),
              }[authority]
            }
          </FormContainer>
        )}
      </Form>
    </WithdrawalRuleBox>
  )
}

export default WithdrawalRuleForm

WithdrawalRuleForm.propTypes = {
  edgeName: PropTypes.string.isRequired,
}

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`

const InputBox = styled.div`
  flex: 1 1 0%;

  input {
    font-weight: 700;
    color: #000;
    width: 100%;
    height: 40px;
    border: 1px solid #738087;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: left;
    padding-left: 10px;
    border-radius: 8px;
  }
`

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 70px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
`

const WithdrawalRuleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const EditoryBox = styled.div`
  .editor {
    width: 100% !important;
    min-height: 450px !important;
  }
`
