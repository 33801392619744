import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import {
  SettlemenResulttStatusEnumsSelectOptions,
  SettlementStatusEnumsRadioOptions,
  SportSettlementStatus2Enums,
} from '../../../enums/SystemManagement/sportEnums'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import {
  createSportsSettlementManual,
  createSportsSettlementWaiting,
  getSportsSettlement,
  updateSportsSettlementType,
} from '../../../utils/api/sport/sportSettlementApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import SportManagementTabs from '../SportManagementTabs'
import SettlementHiddenContent from './SettlementHiddenContent'
import SettlementRadio from './SettlementRadio'
import SportManagementTabsV2 from '../../SportManagementV2/SportManagementTabsV2'

const SettlementManagement = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [settlementFlag, setSettlementFlag] = useState(
    new URLSearchParams(location?.search).get('isSettlement') || null,
  )

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [userAuthority, setUserAuthority] = useState(null)
  const authority = decodeAccessToken()?.adminAuthority

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
    settlementFlag: settlementFlag || null,
    userAuthority: null,
    edgeName: null,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      userAuthority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const [settlementType, setSettlementType] = useState(null)
  const fetchSearchSportsMarket = async () => {
    await getSportsSettlement(bettingLogparams).then(res => {
      setSettlementType(res.data.settlementType)
      const newContent = res.data.content.map(item => ({
        ...item,
        isVisible: false,
      }))
      setContent(newContent)
      setTotalElement(res.data.totalElement)
    })
  }

  useEffect(() => {
    fetchSearchSportsMarket()
  }, [bettingLogparams])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [settleMentInfo, setSettleMentInfo] = useState({})

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState(settlementFlag || '')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(
    settlementFlag ? { value: settlementFlag, label: '미완료' } : null,
  )

  const searchGameResultOption = SettlemenResulttStatusEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      memberId: !searchMemberId ? null : searchMemberId.trim(),
      edgeName: !searchEdgeName ? null : searchEdgeName,
      settlementFlag: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  // 검색하기 버튼
  const onSearchBettingHistoryHandler = (num, bettingLogKey) => {
    const newContent = [...content]
    const hang = newContent.find(item => item.sportsBettingLogKey === bettingLogKey)
    if (hang) {
      hang.isVisible = !hang.isVisible
      setContent(newContent)
    }
  }

  const [writeLoading, setWriteLoading] = useState(false)
  // 정산하기 버튼

  const [apiLoading, setApiLoading] = useState(false)

  const onSettlementHandler = (memberId, bettingLogKey) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return

    if (window.confirm(`${memberId}님의 배팅을 정산하시겠습니까?\n확인을 누르시면 당첨금이 회원에게 지급됩니다.`)) {
      setWriteLoading(true)
      const body = {
        sportsBettingLogKey: [bettingLogKey],
      }
      createSportsSettlementManual(body)
        .then(res => {
          dispatch(fetchHeaderAlarmInfoAction())
          alert('수동정산이 완료됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          switch (errorCode) {
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('수동정산이 실패했습니다. 잠시 후 다시 시도해주세요.')
          }
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onSettlementHandleAll = (memberId, bettingLogKey) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (checkRadio.length === 0) {
      alert('정산 할 내역들을 선택해주세요.')
      return
    }

    if (window.confirm(`스포츠 배팅을 정산하시겠습니까?\n확인을 누르시면 당첨금이 회원에게 지급됩니다.`)) {
      setWriteLoading(true)
      const body = {
        sportsBettingLogKey: checkRadio,
      }
      createSportsSettlementManual(body)
        .then(res => {
          dispatch(fetchHeaderAlarmInfoAction())
          alert('정산처리가 완료됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
        .catch(error => {
          alert('정산처리가 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onSettlementWaitingHandler = (memberId, bettingLogKey) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (checkRadio.length === 0) {
      alert('대기를 할 정산내역들을 선택해주세요.')
      return
    }

    if (window.confirm(`스포츠 배팅정산들을 대기 처리하시겠습니까?\n확인을 누르시면 더이상 알림이 울리지 않습니다.`)) {
      setWriteLoading(true)
      const body = {
        sportsBettingLogKey: checkRadio,
      }
      createSportsSettlementWaiting(body)
        .then(res => {
          dispatch(fetchHeaderAlarmInfoAction())
          alert('대기처리가 완료됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
        .catch(error => {
          alert('대기처리가 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onSubmit = e => {
    if (writeLoading) return
    if (window.confirm('정산방식을 변경 하시겠습니까?')) {
      const body = {
        sportsSettlementType: e.settlementStatus,
      }
      setWriteLoading(true)
      updateSportsSettlementType(body)
        .then(res => {
          alert('정산방식이 수정됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
        .catch(error => {
          alert('수정이 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
    }
  }

  const onSettlementStatus = customerStatus => {
    switch (customerStatus) {
      case 'COMPLETE':
        return (
          <button type="button" style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatus2Enums[customerStatus]}
          </button>
        )
      case 'WAITING':
        return (
          <button type="button" style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatus2Enums[customerStatus]}
          </button>
        )
      case 'APPLY':
        return (
          <button type="button" style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatus2Enums[customerStatus]}
          </button>
        )
      default:
        return (
          <button type="button" style={{ background: 'black', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatus2Enums[customerStatus]}
          </button>
        )
    }
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('15')
  return (
    <Card>
      <CardBody>
        <SportManagementTabsV2 activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당회원의 자세한 배팅내역을 볼 수 있습니다.</p>
            <p>
              * 해당 페이지는 <span style={{ color: 'blue', fontWeight: '600' }}>당첨된 회원</span>들의 내역이 나오며,
              수동으로 정산처리를 할 수 있습니다.
            </p>
            <p>* 자동 정산을 끄면, 수동으로 정산을 처리하셔야 합니다.</p>
          </div>
        </SearchCardTitleWrap>
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>정산여부</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="완료/미완료"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ModifyWrap>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup style={{ width: '330px', marginRight: '40px' }}>
                  <FormGroupLabel>
                    정산 <span style={{ color: 'red', fontWeight: '500' }}>수동/자동 여부</span>
                  </FormGroupLabel>
                  {SettlementStatusEnumsRadioOptions().map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name="settlementStatus"
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={settlementType}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>

                <>
                  {
                    {
                      SUPER: (
                        <FormButtonToolbar style={{ marginTop: '-80px' }}>
                          <Button size="sm" variant="danger" type="submit">
                            수정하기
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            style={{ backgroundColor: 'lightGreen', color: 'white', marginLeft: '200px' }}
                            onClick={() => {
                              setCheckRadio(content.map(value => value.sportsBettingLogKey))
                            }}
                          >
                            전체선택
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            style={{ backgroundColor: 'green', color: 'white' }}
                            onClick={() => {
                              onSettlementWaitingHandler()
                            }}
                          >
                            정산대기
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            style={{ backgroundColor: 'blue', color: 'white' }}
                            onClick={() => {
                              onSettlementHandleAll()
                            }}
                          >
                            정산승인
                          </Button>
                        </FormButtonToolbar>
                      ),
                    }[authority]
                  }
                </>
              </FormContainer>
            )}
          </Form>
        </ModifyWrap>
        {writeLoading && <Loading />}
        <SportsGameListTableWrap>
          <SportsGameListTable>
            <SportsGameListTableHead>
              <SportsGameListTableTr style={{ background: 'lightgray' }}>
                <th style={{ width: '4%' }}>선택</th>
                <th style={{ width: '4%' }}>번호</th>
                <th style={{ width: '7%' }}>사이트명</th>
                <th style={{ width: '10%' }}>배팅일시</th>
                <th style={{ width: '4%' }}>권한</th>
                <th style={{ width: '12%' }}>유저ID(명)</th>
                <th style={{ width: '11%' }}>상위파트너명</th>
                <th style={{ width: '11%' }}>배팅금액</th>
                <th style={{ width: '4%' }}>폴더</th>
                <th style={{ width: '8%' }}>배당</th>
                <th style={{ width: '11%' }}>최종당첨금</th>
                <th style={{ width: '7%' }}>정산상태</th>
                <th style={{ width: '7%' }}>수동정산</th>
              </SportsGameListTableTr>
            </SportsGameListTableHead>
            {content.map((item, index) => (
              <SportsGameListTableBody key={item.sportsBettingLogKey}>
                <SportsGameListTableTr>
                  <SportsGameFolderContentBox>
                    <SportsGameFolderNumBox>
                      <SettlementRadio rowInfo={item} checkRadio={checkRadio} setCheckRadio={setCheckRadio} />
                    </SportsGameFolderNumBox>
                    <SportsGameFolderNumBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.num}
                    </SportsGameFolderNumBox>
                    <SportsGameFolderEdgeNameBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.edgeName}
                    </SportsGameFolderEdgeNameBox>
                    <SportsGameFolderContentTime
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {convertToKstSportsEventDateTime(item.bettingTime)}
                    </SportsGameFolderContentTime>
                    <SportsGameFolderAuthorityNameBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <CustomAuthorityButton authority={item.authority}>
                        {NoteAuthorityEnums[item.authority]}
                      </CustomAuthorityButton>
                    </SportsGameFolderAuthorityNameBox>
                    <SportsGameFolderContentHomeBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentHomeName>
                        <span
                          onClick={() => {
                            window.open(`/user-info/${item.memberId}`, '_blank')
                          }}
                          style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {item.memberId}({item.memberName})
                        </span>
                      </SportsGameFolderContentHomeName>
                    </SportsGameFolderContentHomeBox>
                    <SportsGamePartnerBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentHomeName>{item.topPartnerName}</SportsGameFolderContentHomeName>
                    </SportsGamePartnerBox>
                    <SportsGameFolderContentCriteria
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderContentCriteria>
                    <SportsGameFolderNumBox
                      style={{ borderRight: 'none' }}
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentAwayPer>{item.folderSize}폴더</SportsGameFolderContentAwayPer>
                    </SportsGameFolderNumBox>
                    <SportsGameFolderScoreBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.totalOddValue}
                    </SportsGameFolderScoreBox>
                    <SportsGameFolderResultBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderResultBox>

                    <SportsGameFolderManualResultBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {onSettlementStatus(item.settlementStatus)}
                    </SportsGameFolderManualResultBox>
                    <SportsGameFolderManualResultBox>
                      {!item.settlementFlag && (
                        <button
                          type="button"
                          style={{ background: 'green', color: 'white', border: 'none' }}
                          onClick={() => {
                            onSettlementHandler(item.memberId, item.sportsBettingLogKey)
                          }}
                        >
                          수동정산
                        </button>
                      )}
                    </SportsGameFolderManualResultBox>
                  </SportsGameFolderContentBox>
                </SportsGameListTableTr>
                {item.isVisible && (
                  <SportsGameListTableTr>
                    <SettlementHiddenContent
                      content={item.sportsDetailList}
                      fetchSearchSportsMarket={fetchSearchSportsMarket}
                    />
                  </SportsGameListTableTr>
                )}
              </SportsGameListTableBody>
            ))}
          </SportsGameListTable>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}

export default SettlementManagement

const ModifyWrap = styled.div`
  width: 100%;
  display: flex;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`

const SportsGameFolderEdgeNameBox = styled.div`
  width: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`

const SportsGameFolderAuthorityNameBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`

const SportsGameFolderScoreBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
`

const SportsGameFolderResultBox = styled.div`
  width: 11%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
  border-right: 1px solid black;
`

const SportsGameFolderManualResultBox = styled.div`
  width: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`
const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: green;
  font-weight: 600;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 12%;
  display: flex;
  border-right: 1px solid black;
`

const SportsGamePartnerBox = styled.div`
  width: 11%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  width: 10%;
  display: flex;
  border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  color: black;
  height: 36px;
  border-left: solid 1px black;
`
