import { createSlice } from '@reduxjs/toolkit'

const SportInfoSlice = createSlice({
  name: 'sportInfo',
  initialState: {
    sportMenu: [],
    sportMenuExceptAll: [],
    activeMarketSportMenu: -1,
    activeMarketSportMenuExceptAll: -1,
  },
  reducers: {
    setSportMenu: (state, action) => {
      const sportList = action.payload

      const allowedSportIds = [6046, 154914, 48242, 687890, 35709, 131506, 54094, 154830, 35232, 154919, 265917]
      const filteredSportList = sportList.filter(item => allowedSportIds.includes(item.sportId))
      const newSport = {
        sportId: 0,
        sportName: '전체',
      }
      const updatedSportList = [newSport, ...filteredSportList] // Prepends the newSport to sportList
      state.sportMenuExceptAll = filteredSportList.map(item => ({
        sportId: item.sportId,
        sportName: item.sportName,
      }))
      state.sportMenu = updatedSportList.map(item => ({
        sportId: item.sportId,
        sportName: item.sportName,
      }))

      state.activeMarketSportMenu = updatedSportList[0].sportId
      state.activeMarketSportMenuExceptAll = filteredSportList[0].sportId

      // state.sportMenu = sportList.map(item => {
      //   return {
      //     sportId: item.sportId,
      //     sportName: item.sportName,
      //   }
      // })

      // state.activeMarketSportMenu = firstSport?.sportId || -1
    },
    setActiveMarketSportMenu: (state, action) => {
      state.activeMarketSportMenu = action.payload
    },
    setActiveMarketSportMenuuExceptAll: (state, action) => {
      state.activeMarketSportMenuExceptAll = action.payload
    },
  },
  extraReducers: builder => {},
})

export const { setSportMenu, setActiveMarketSportMenu, setActiveMarketSportMenuuExceptAll } = SportInfoSlice.actions

export default SportInfoSlice.reducer
