import { ButtonToolbar } from '@/shared/components/Button'
import { FormButtonToolbar, FormContainer } from '@/shared/components/form/FormElements'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import { colorBackground, colorBlue, colorIcon, colorText, colorWhite } from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import { Form } from 'react-final-form'
import styled from 'styled-components'
import { MemberDepositLevelEnum } from '../../../enums/MemberManage/MemberInfoEnums'
import Loading from '../../../shared/components/Loading'
import { updateDepositBonusMoneySetting } from '../../../utils/api/systemManage/depositWithdrawalRuleManageApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'

function DepositBonusMoneySettingUpdateModalV2({
  depositBonusModel,
  depositBonusMoneySettingUpdateModalShow,
  onCloseDepositBonusMoneySettingUpdateModalHnadler,
  fetchSearchDepositBonus,
}) {
  const [apiLoading, setApiLoading] = useState(false)

  const [depositPlusAmount, setDepositPlusAmount] = useState(Object.entries(depositBonusModel?.depositPlusAmount ?? {}))

  // 추가할때 넣는 값
  const [newDepositPlusApplyMoney, setNewDepositPlusApplyMoney] = useState('')
  const [newDepositPlusBonusMoney, setNewDepositPlusBonusMoney] = useState('')

  // 추가 버튼 누르면
  const onClickAddDepositPlusAmountHandler = () => {
    setDepositPlusAmount(prev => {
      return [...prev, [newDepositPlusApplyMoney, newDepositPlusBonusMoney]]
    })
    setNewDepositPlusApplyMoney('')
    setNewDepositPlusBonusMoney('')
  }

  // 충전금액 변경 핸들러 함수 (applyMoney 키를 변경하는 경우)
  const onChangeApplyMoneyHandler = (index, newApplyMoney) => {
    setDepositPlusAmount(prevState => {
      const updatedState = [...prevState]
      const [_, bonusMoney] = updatedState[index]
      updatedState[index] = [newApplyMoney, bonusMoney]
      return updatedState
    })
  }

  // 지급금액 변경 핸들러 함수 (bonusMoney 값을 변경하는 경우)
  const onChangeBonusMoneyHandler = (index, newBonusMoney) => {
    setDepositPlusAmount(prevState => {
      const updatedState = [...prevState]
      const [applyMoney] = updatedState[index]
      updatedState[index] = [applyMoney, newBonusMoney]
      return updatedState
    })
  }

  const onSubmit = () => {
    if (apiLoading) return

    setApiLoading(true)

    const depositPlusAmountObject = Object.fromEntries(depositPlusAmount)

    const body = {
      moneyDepositBonusKey: depositBonusModel.moneyDepositBonusKey,
      depositLevel: depositBonusModel.depositLevel,
      depositPlusAmount: depositPlusAmountObject,
    }

    if (window.confirm('충전 보너스 금액설정 하시겠습니까?')) {
      updateDepositBonusMoneySetting(body)
        .then(res => {
          alert('설정되었습니다.')
          onCloseDepositBonusMoneySettingUpdateModalHnadler()
        })
        .catch(error => {
          alert('실패했습니다.')
        })
        .finally(() => {
          fetchSearchDepositBonus()
          setApiLoading(false)
        })
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <StyledModal
        show={depositBonusMoneySettingUpdateModalShow}
        onHide={onCloseDepositBonusMoneySettingUpdateModalHnadler}
        $header
      >
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onCloseDepositBonusMoneySettingUpdateModalHnadler}
          />
          <ModalTitle>
            <span>충전 보너스 금액설정</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SportsMarketContentHeaderWrap>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>입금 레벨</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>
                {MemberDepositLevelEnum[depositBonusModel.depositLevel]}
              </SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>보너스 정보</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>
                <span>{depositBonusModel.bonusTitle}</span>
                <br />
                <span style={{ fontSize: '12px' }}>{depositBonusModel.bonusContent}</span>
              </SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
          </SportsMarketContentHeaderWrap>
          <SportsMarketContentBodyWrap>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                depositBonusModel,
                depositPlusAmount,
                newDepositPlusApplyMoney,
                newDepositPlusBonusMoney,
              }}
            >
              {({ handleSubmit, form: { reset } }) => (
                <form onSubmit={handleSubmit} style={{ flexDirection: 'column' }}>
                  <div className="input-container">
                    <div className="inputBox">
                      <div className="inputBoxItem">
                        <span>충전금액</span>
                        <input
                          type="text"
                          value={newDepositPlusApplyMoney?.toString().replace(commonReg2, ',')}
                          onChange={e => {
                            const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                            if (onlyNumber !== '') {
                              setNewDepositPlusApplyMoney(onlyNumber)
                            }
                          }}
                        />
                      </div>
                      <div className="inputBoxItem">
                        <span>지급금액</span>
                        <input
                          type="text"
                          value={newDepositPlusBonusMoney?.toString().replace(commonReg2, ',')}
                          onChange={e => {
                            const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                            if (onlyNumber !== '') {
                              setNewDepositPlusBonusMoney(onlyNumber)
                            }
                          }}
                        />
                      </div>
                    </div>
                    <Button
                      variant="success"
                      type="button"
                      onClick={() => {
                        onClickAddDepositPlusAmountHandler()
                      }}
                    >
                      추가
                    </Button>
                  </div>

                  <hr style={{ color: 'black', opacity: '1' }} />

                  {depositPlusAmount.map(([applyMoney, bonusMoney], index) => {
                    return (
                      <div className="input-container" style={{ marginBottom: '5px' }}>
                        <div className="inputBox">
                          <div className="inputBoxItem">
                            <span>충전금액</span>
                            <input
                              type="text"
                              value={applyMoney?.toString().replace(commonReg2, ',')}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                if (onlyNumber !== '') {
                                  onChangeApplyMoneyHandler(index, onlyNumber)
                                }
                              }}
                            />
                          </div>
                          <div className="inputBoxItem">
                            <span>지급금액</span>
                            <input
                              type="text"
                              value={bonusMoney?.toString().replace(commonReg2, ',')}
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                if (onlyNumber !== '') {
                                  onChangeBonusMoneyHandler(index, onlyNumber)
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}

                  <FormButtonToolbar style={{ marginLeft: '0' }}>
                    <Button variant="primary" type="submit">
                      수정하기
                    </Button>
                  </FormButtonToolbar>
                </form>
              )}
            </Form>
          </SportsMarketContentBodyWrap>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default DepositBonusMoneySettingUpdateModalV2

DepositBonusMoneySettingUpdateModalV2.propTypes = {
  depositBonusModel: PropTypes.shape().isRequired,
  depositBonusMoneySettingUpdateModalShow: PropTypes.bool,
  onCloseDepositBonusMoneySettingUpdateModalHnadler: PropTypes.func,
  fetchSearchDepositBonus: PropTypes.func,
}

DepositBonusMoneySettingUpdateModalV2.defaultProps = {
  depositBonusMoneySettingUpdateModalShow: false,
  onCloseDepositBonusMoneySettingUpdateModalHnadler: () => {},
  fetchSearchDepositBonus: () => {},
}

export const CustomButton = styled(Button)`
  width: 50px;
  height: 30px;
  font-size: 15px;
  padding: 0px;
  padding-top: 3px;
`

export const CustomInput = styled.input`
  border: none !important;
  height: 22px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
`

export const CustomFormInputWrap = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
`

const SportsMarketFormContainer = styled(FormContainer)`
  flex-direction: column;
  padding: 20px 0;
  // border: 1px solid gray;
`

const SportsMarketContentHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
`

const SportsMarketContentHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #888;
`

const SportsMarketContentHeaderLabel = styled.span`
  width: 30%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
`

const SportsMarketContentHeaderValue = styled.span`
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  border-left: 2px solid #888;
`

const SportsMarketContentBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 20px;

  .input-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .input-container .inputBox {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .input-container button {
    width: max-content;
    height: 100%;
    margin-left: 10px;
  }

  .input-container .inputBox .inputBoxItem {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .input-container .inputBox .inputBoxItem span {
    width: 30%;
    padding: 0 5px;
    text-align: center;
    font-size: 13px;
  }

  .input-container .inputBox .inputBoxItem input {
    width: 70%;
    padding: 0 5px;
    text-align: right;
  }
`

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div`
  background-color: grey !important;
  font-weight: 700;
`
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 500px !important;
    margin-top: 100px;
  }

  .modal-content {
    height: max-content;
    min-height: 400px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
