import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Badge } from 'react-bootstrap'
import {
  SettlemenResulttStatusEnumsSelectOptions,
  sportResultStatusEnums,
  sportSettlementStatusEnums,
} from '../../../enums/SystemManagement/sportEnums'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import {
  createSportsSettlementManual,
  getSportsSettlement,
  updateSportsSettlementType,
} from '../../../utils/api/sport/sportSettlementApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import RealTimeSportBettingHiddenContent from './RealTimeSportBettingHiddenContent'
import useAudio from '../../../utils/audioHook'
import { searchRealTimeSportsBetting } from '../../../utils/api/logManage/logApi'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'

const RealTimeSportBettingLogList = () => {
  const [play, pause, paused, audioRef] = useAudio(`${process.env.PUBLIC_URL}/audio/betting.mp3`, {
    playbackRate: 1,
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const authority = decodeAccessToken()?.adminAuthority

  const [muteAudio, setMuteAudio] = useState(JSON.parse(sessionStorage.getItem('BTSA')))

  const onCheckMuteAudioHandler = e => {
    setMuteAudio(e.target.checked)
    // BTSA = Betting Sports Audio
    sessionStorage.setItem('BTSA', e.target.checked)
  }

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
  }
  const [sportTotalRealTimeCount, setSportsTotalRealTimeCount] = useState(0)
  const [prevTotalElement, setPrevTotalElement] = useState(0)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchSportsMarket = async () => {
    await searchRealTimeSportsBetting(bettingLogparams).then(res => {
      // const newContent = res.data.content.map(item => (
      //   const existOpen = content.find(prev => prev.sportsBettingLogKey === item.sportsBettingLogKey)
      //   {
      //   ...item,
      //   isVisible: false,
      // }))

      setContent(prevContent =>
        res.data.content.map(item => {
          const existOpen = prevContent.find(prev => prev.sportsBettingLogKey === item.sportsBettingLogKey)

          return {
            ...item,
            isVisible: existOpen ? existOpen.isVisible : false,
          }
        }),
      )
      setTotalElement(res.data.totalElement)
      setSportsTotalRealTimeCount(res.data.totalRealTimeCount)

      if (res.data.totalElement > prevTotalElement) {
        if (!muteAudio && res.data.totalRealTimeCount > 0 && paused()) {
          play()
        }
        setPrevTotalElement(res.data.totalElement)
      }
    })
  }

  useEffect(() => {
    fetchSearchSportsMarket()

    const interval = setInterval(() => {
      fetchSearchSportsMarket()
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [bettingLogparams, prevTotalElement, muteAudio])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 행클릭하기 버튼
  const onSearchBettingHistoryHandler = (num, bettingLogKey) => {
    const newContent = [...content]
    const hang = newContent.find(item => item.sportsBettingLogKey === bettingLogKey)
    if (hang) {
      hang.isVisible = !hang.isVisible
      setContent(newContent)
    }
  }

  return (
    <Card>
      <CardBody style={{ padding: '0px' }}>
        <HeaderWrap>
          <h2 style={{ color: 'green' }}>스포츠 실시간 내역 ({sportTotalRealTimeCount || 0})</h2>
          <label
            htmlFor="miniGameCheck"
            style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '10px' }}
          >
            <input
              type="checkbox"
              id="miniGameCheck"
              checked={muteAudio}
              onChange={e => {
                onCheckMuteAudioHandler(e)
              }}
              style={{ marginRight: '10px' }}
            />
            <span style={{ color: 'red', fontWeight: '500', fontSize: '13px' }}>
              * 스포츠 음소거하려면 체크해주세요.
            </span>
          </label>
        </HeaderWrap>
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-40px', marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당회원의 자세한 배팅내역을 볼 수 있습니다.</p>
            <p>
              * 해당 페이지는 <span style={{ color: 'blue', fontWeight: '600' }}>배팅 진행중</span>의 회원들을 볼 수
              있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SportsGameListTableWrap>
          <SportsGameListTable>
            <SportsGameListTableHead>
              <SportsGameListTableTr>
                <th style={{ width: '5%' }}>사이트명</th>
                <th style={{ width: '10%' }}>배팅일시</th>
                <th style={{ width: '15%' }}>상위파트너명</th>
                <th style={{ width: '5%' }}>권한</th>
                <th style={{ width: '15%' }}>유저ID(명)</th>
                <th style={{ width: '13%' }}>배팅금액</th>
                <th style={{ width: '8%' }}>폴더</th>
                <th style={{ width: '8%' }}>배당</th>
                <th style={{ width: '13%' }}>예상 당첨금</th>
                <th style={{ width: '8%' }}>최종결과</th>
              </SportsGameListTableTr>
            </SportsGameListTableHead>
            {content.map((item, index) => (
              <SportsGameListTableBody key={item.sportsBettingLogKey}>
                <SportsGameListTableTr>
                  <SportsGameFolderContentBox>
                    <SportsGameFolderNumBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.edgeName}
                      {item.isNew && (
                        <NewBadge bg="custom">
                          <span>N</span>
                        </NewBadge>
                      )}
                    </SportsGameFolderNumBox>
                    <SportsGameFolderContentTime
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {convertToKstSportsEventDateTime(item.bettingTime)}
                    </SportsGameFolderContentTime>
                    <SportsGamePartnerBox
                      style={{ borderRight: 'solid 1px grey' }}
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentHomeName>{item.topPartnerName || '-'}</SportsGameFolderContentHomeName>
                    </SportsGamePartnerBox>
                    <SportsGameFolderNumBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {NoteAuthorityEnums[item.authority]}
                    </SportsGameFolderNumBox>
                    <SportsGameFolderContentHomeBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentHomeName>{item.memberId}</SportsGameFolderContentHomeName>
                    </SportsGameFolderContentHomeBox>

                    <SportsGameFolderContentCriteria
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderContentCriteria>
                    <SportsGameFolderContentAwayBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentAwayPer>{item.folderSize}폴더</SportsGameFolderContentAwayPer>
                    </SportsGameFolderContentAwayBox>
                    <SportsGameFolderScoreBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.totalOddValue}
                    </SportsGameFolderScoreBox>
                    <SportsGameFolderResultBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingExpectedAmount?.toString().replace(commonReg2, ',')}
                    </SportsGameFolderResultBox>
                    <SportsGameFolderResult2Box
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <CustomButton type="button" bettingResult={item.resultStatus}>
                        {sportResultStatusEnums[item.resultStatus]}
                      </CustomButton>
                    </SportsGameFolderResult2Box>
                  </SportsGameFolderContentBox>
                </SportsGameListTableTr>
                {item.isVisible && (
                  <SportsGameListTableTr>
                    <RealTimeSportBettingHiddenContent
                      content={item.sportsDetailList}
                      fetchSearchSportsMarket={fetchSearchSportsMarket}
                    />
                  </SportsGameListTableTr>
                )}
              </SportsGameListTableBody>
            ))}
          </SportsGameListTable>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}

export default RealTimeSportBettingLogList
const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  height: 25px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`
const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderScoreBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResultBox = styled.div`
  width: 13%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResult2Box = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentHomeBox = styled.div`
  width: 15%;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGamePartnerBox = styled.div`
  width: 15%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  width: 100px;
  display: flex;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  color: white;
  width: 10%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 36px;
`
const NewBadge = styled(Badge)`
  width: 20px;
  height: 14px;
  background-color: #ff4861;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  margin-left: 5px;

  span {
    width: 20px;
    text-align: center;
  }
`
