import { Card as BootstrapCard } from 'react-bootstrap'
import styled from 'styled-components'
// import { colorBackground } from '@/utils/palette'
import { borderRadius, shadow } from '@/utils/styles'
import { left } from '@/utils/directions'
import {
  colorBackground,
  colorBackgroundBody,
  colorBlue,
  colorBorder,
  colorHeaderDataPicker,
  colorHover,
  colorText,
  colorTextAdditional,
} from '@/utils/palette'

export const Card = styled(BootstrapCard)`
  width: 100%;
  // min-width: 1200px !important;
  height: ${props => props.height || '100%'};
  border: none;
  background-color: transparent;
`

export const CardBody = styled(Card.Body)`
  height: 100%;
  background-color: ${colorBackground};
  border-radius: ${borderRadius};
  box-shadow: ${shadow};
  padding: 20px;
`

export const CardTitleWrap = styled.div`
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;
  text-align: ${left};

  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`

export const CardTitle = styled.h5`
  font-size: 13px;
  text-align: ${left};
  font-weight: 700;
  ${props =>
    props.centered &&
    `
    text-align: center;
  `}
`

export const CardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
`

// 회원 머니 증감 Modal - START
export const MemberMoneyIncDecCard = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 10px;
  height: ${props => props.height || '100%'};
  border: none;
  background-color: transparent;
`

// 파트너 머니 증감 Modal - START
export const PartnerMoneyIncDecCard = styled(BootstrapCard)`
  width: 100%;
  padding-bottom: 10px;
  height: ${props => props.height || '100%'};
  border: none;
  background-color: transparent;
`
// 머니 증감 Modal - END

/* ============================================== 
파트너 정산 통계 Card 커스텀 - START
================================================= */
export const PartnerSettlementCardTitleWrap = styled.div`
  margin-bottom: 15px;
  text-transform: uppercase;
  position: relative;
  text-align: ${left};
  border-bottom: 2px solid ${colorBorder};
  &:not(:first-child) {
    margin-top: 20px;
  }

  * {
    margin-bottom: 0;
  }
`

export const PartnerSettlementCardTitle = styled.h5`
  font-size: 20px;
  text-align: ${left};
  font-weight: 700;
  margin-bottom: 10px;
  ${props =>
    props.centered &&
    `
    text-align: center;
  `};
`

export const CustomAuthorityButton = styled.button`
  color: white;
  background-color: ${props => (props.authority === 'MEMBER' ? 'orange' : '#7da832')};
  border: none;
`

export const SettlementButton = styled.button`
  color: white;
  background-color: ${props => (props.authority === 'COMPLETE' ? 'blue' : 'red')};
  border: none;
`

export const CustomSettlementTypeButton = styled.button`
  color: white;
  background-color: ${props => (props.pointType === 'ROLLING' ? 'red' : 'blue')};
  border: none;
`

export const PartnerSettlementCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
  background: #fff79e;
`
/* ============================================== 
파트너 정산 통계 Card 커스텀 - END
================================================= */

/* ============================================== 
나의 정보 MyInfo Card 커스텀 - START
================================================= */
export const MyInfoCardTitleWrap = styled.div`
  margin-bottom: 15px;
  text-transform: uppercase;
  position: relative;
  text-align: ${left};
  border-bottom: 2px solid ${colorBorder};
  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`

export const MyInfoCardTitle = styled.h5`
  font-size: 20px;
  text-align: ${left};
  font-weight: 700;
  margin-bottom: 10px;
  ${props =>
    props.centered &&
    `
    text-align: center;
  `};
`

export const MyInfoCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
`
/* ============================================== 
나의 정보 MyInfo Card 커스텀 - END
================================================= */

/* ============================================== 
운영관리 - 쿠폰관리 Card 커스텀 - END
================================================= */

export const CouponCardBody = styled(Card.Body)`
  height: 100%;
  background-color: ${colorBackground};
  border-radius: ${borderRadius};
  box-shadow: ${shadow};
  padding: 5px 10px 5px 10px;
`

export const CouponCardTitleWrap = styled.div`
  margin-bottom: 15px;
  text-transform: uppercase;
  position: relative;
  text-align: ${left};
  border-bottom: 2px solid ${colorBorder};
  &:not(:first-child) {
    margin-top: 40px;
  }

  * {
    margin-bottom: 0;
  }
`

export const CouponCardTitle = styled.h5`
  font-size: 20px;
  text-align: ${left};
  font-weight: 700;
  margin-bottom: 10px;
  ${props =>
    props.centered &&
    `
    text-align: center;
  `};
`

export const CouponCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
`

/* ============================================== 
운영관리 - 쿠폰관리 Card 커스텀 - END
================================================= */

// 검색 쪽 타이틀

export const SearchCardTitleWrap = styled.div`
  margin-bottom: 5px;
  text-transform: uppercase;
  background: #fff79e;
  position: relative;
  text-align: ${left};
  // border-bottom: 2px solid ${colorBorder};

  &:not(:first-child) {
    margin-top: 40px;
  }
`

export const SearchCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
`
/* ============================================== 
업체별 정산 통계 Card 커스텀 - START
================================================= */
export const StatisticsCardTitleWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  text-transform: uppercase;
  position: relative;
  text-align: ${left};
  border-bottom: 2px solid ${colorBorder};

  &:not(:first-child) {
    // margin-top: 30px;
  }

  * {
    margin-bottom: 0;
  }
`

export const StatisticsCardTitle = styled.h5`
  font-size: 20px;
  text-align: ${left};
  font-weight: 700;
  margin-bottom: 10px;
  ${props =>
    props.centered &&
    `
    text-align: center;
  `};
`

export const StatisticsCardSubhead = styled.p`
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.7;
  margin-top: 3px;
  margin-bottom: 5px;
`
/* ============================================== 
업체별 정산 통계 Card 커스텀 - END
================================================= */

/* ============================================== 
Custom Card - Start
================================================= */
export const CustomCard = styled(Card)``

/* ============================================== 
Custom Card - End
================================================= */

/* ============================================== 
파트너 등록 Custom Card - Start
================================================= */
export const PartnerRegisterCustomCard = styled(CustomCard)`
  padding-bottom: 0px;
`

/* ============================================== 
파트너 등록 Custom Card - End
================================================= */
