import React from 'react'
import PacmanLoader from 'react-spinners/PacmanLoader'
import styled from 'styled-components'

const Loading = () => {
  return (
    <LoadingIconWrap>
      <LoadingIcon color="#e1e1e1" size={35} />
      <LoadingInfoWrap>
        <LoadingInfo>잠시만 기다려주세요...</LoadingInfo>
      </LoadingInfoWrap>
    </LoadingIconWrap>
  )
}

export default Loading

const LoadingIconWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background: #303030;
  border: 1px solid #808080;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 220px;
  z-index: 999;
`
const LoadingIcon = styled(PacmanLoader)`
  top: 30px !important;
  left: -20px !important;
  margin: 0 auto;
`

const LoadingInfoWrap = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  width: 100%;
`

const LoadingInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 900;
  width: 100%;
  color: #e1e1e1 !important;
  // background: linear-gradient(to right, #0b6121, #00ff80);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
`
