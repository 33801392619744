import PropTypes from 'prop-types'
import React from 'react'
import { MemberFriendCompLevelEnum } from '../../enums/MemberManage/MemberInfoEnums'
import { NoteAuthorityEnums } from '../../enums/Operation/NoteEnums'
import { friendCompSettlementStatusEnums } from '../../enums/SystemManagement/siteEnums'
import { CustomAuthorityButton, SettlementButton } from '../../shared/components/Card'
import { convertToKstDateTimeSimple } from '../../utils/dateTime'
import { commonReg2 } from '../../utils/validate/commonValidate'
import MemberCoupon from '../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../MemberManagement/MemberList/MemberNote/MemberNote'
import FriendCompDetailInfo from './FriendCompDetailInfo'
import FriendCompRadio from './FriendCompRadio'
import MemberMoneyIncDecMini from '../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const FriendCompListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <FriendCompRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
      width: 30,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },

    {
      Header: '추천인 ID(명)',
      accessor: 'friendUserId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.friendUserId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.friendUserId}({original.friendUserName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.friendUserId} />
              </span>
              <span>
                <MemberCoupon memberId={original.friendUserId} />
              </span>
              <span>
                <MemberNote memberId={original.friendUserId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'friendUserAuthority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.friendUserAuthority}>
            {NoteAuthorityEnums[original.friendUserAuthority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '정산 시작일',
      accessor: 'settlementStartAt',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.settlementStartAt)
      },
    },

    {
      Header: '정산 종료일',
      accessor: 'settlementEndAt',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.settlementEndAt)
      },
    },
    {
      Header: '추천인레벨',
      accessor: 'friendCompLevelName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{MemberFriendCompLevelEnum[original.friendCompLevelName]}</>
      },
    },
    {
      Header: '자세히',
      accessor: 'friendCompDetailInfo',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <FriendCompDetailInfo friendCompInfo={original} />
      },
    },
    {
      Header: '총 지인 콤프액',
      accessor: 'totalFriendCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalFriendCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '최대 지급가능액',
      accessor: 'friendCompMaxAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.friendCompMaxAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '최종 지급액',
      accessor: 'finalFriendCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.finalFriendCompAmount?.toString().replace(commonReg2, ',')
      },
    },

    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <SettlementButton authority={original.friendCompSettlementStatus}>
            {friendCompSettlementStatusEnums[original.friendCompSettlementStatus]}
          </SettlementButton>
        )
      },
    },
    {
      Header: '피추천인 ID(명)',
      accessor: 'userId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
              <span>
                <MemberCoupon memberId={original.userId} />
              </span>
              <span>
                <MemberNote memberId={original.userId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.userId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '라이브 콤프액',
      accessor: 'liveCasinoCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.liveCasinoCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '슬롯 콤프액',
      accessor: 'slotCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.slotCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '스포츠 콤프액(배팅)',
      accessor: 'sportsCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.sportsCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '스포츠 콤프액(낙첨)',
      accessor: 'sportsLossCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.sportsLossCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '미니게임 콤프액(배팅)',
      accessor: 'miniGameCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.miniGameCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '미니게임 콤프액(낙첨)',
      accessor: 'miniGameLossCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.miniGameLossCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '가상게임 콤프액(배팅)',
      accessor: 'virtualGameCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.virtualGameCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '가상게임 콤프액(낙첨)',
      accessor: 'virtualGameLossCompAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.virtualGameLossCompAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '지급전 포인트액',
      accessor: 'beforePointAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePointAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '지급후 포인트액',
      accessor: 'afterPointAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPointAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '지급일',
      accessor: 'executeAt',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.executeAt)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default FriendCompListData

FriendCompListData.propTypes = {
  fetchSearchBoardList: PropTypes.func.isRequired,
}
