import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  MiniGameBettingResultEnumsSelectOptions,
  MiniGameTypeEnumsSelectOptions,
  MiniGameVendor,
} from '../../../enums/Log/miniGameBettingEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import { useSearchMiniGameBetting } from '../../../utils/api/logManage/logApi'
import { deleteMiniGameBettingLog } from '../../../utils/api/memberManage/deleteHistoryApi'
import { downloadMemberMiniGameList } from '../../../utils/api/memberManage/memberManageApi'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import BettingManagementTabs from '../BettingManagementTabs'
import MiniGameBettingLogListData from './MiniGameBettingLogListData'

const MiniGameBettingLogList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
    edgeName: null,
    authority: null,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [miniGameVendor, setMiniGameVendor] = useState(null)

  // 미니게임 종목 선택
  const onClickMiniGameTypeHandler = type => {
    setMiniGameVendor(type)

    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      miniGameVendor: type || null,
    }))
  }

  const { totalElement, content, fetchSearchMiniGameBetting } = useSearchMiniGameBetting(bettingLogparams)
  const [checkRadio, setCheckRadio] = useState([])
  const [bettingLogListData, setBettingLogListData] = useState(
    MiniGameBettingLogListData(content, checkRadio, setCheckRadio, fetchSearchMiniGameBetting),
  )
  useEffect(() => {
    setBettingLogListData(MiniGameBettingLogListData(content, checkRadio, setCheckRadio, fetchSearchMiniGameBetting))
  }, [content, checkRadio])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = MiniGameBettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = MiniGameTypeEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      memberId: !searchMemberId ? null : searchMemberId.trim(),
      topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      gameResult: !searchGameResult ? null : searchGameResult,
      miniGameType: !searchGameType ? null : searchGameType,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
      page: 0,
    }))
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('4')

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    topPartnerName: '상위파트너',
    authority: '권한',
    bettingTime: '배팅시간',
    memberId: '유저ID',
    memberName: '유저명',

    bettingAmount: '배팅액',
    bettingBeforeAmount: '배팅전잔액',
    bettingAfterAmount: '배팅후후잔액',
    bettingExpectAmount: '당첨예상액',
    miniGameType: '미니게임 타입',
    miniGameBettingType: '배팅종목',
    miniGameRound: '미니게임 라운드',
    miniGameBettingPer: '배당',
    miniGameBettingResult: '결과',
  }
  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (
      window.confirm(
        '미니게임 배팅내역 DB를 csv파일로 다운 받으시겠습니까?\n 기간설정은 필수 사항이며, 유저ID/명 검색은 선택사항입니다.',
      )
    ) {
      if (searchStartDate === null || searchEndDate === null) {
        alert('기간 설정은 필수 입니다.')
        return
      }
      if (searchStartDate > searchEndDate) {
        alert('기간을 다시 확인해주세요.')
        return
      }
      // if (searchEndDate - searchStartDate > 604800000) {
      //   alert('두 날짜 간의 차이는 1주일 이하여야 합니다.')
      //   return
      // }

      setDownloadLoading(true)
      downloadMemberMiniGameList({
        memberId: !searchMemberId ? null : searchMemberId.trim(),
        topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
        startDate: convertToKst(searchStartDate),
        endDate: convertToKst(searchEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '미니게임 배팅 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  const [writeLoading, setWriteLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority
  const onDeleteHandler = () => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (checkRadio.length === 0) {
      alert('비노출 할 내역들을 선택해주세요.')
      return
    }

    if (
      window.confirm(`미니게임 배팅내역을 비노출 하시겠습니까?\n비노출 시 유저에게 해당 배팅내역은 보이지 않습니다.`)
    ) {
      setWriteLoading(true)
      const body = {
        miniGameHistoryKeyList: checkRadio,
        allDelete: false,
      }
      deleteMiniGameBettingLog(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
          fetchSearchMiniGameBetting()
          setWriteLoading(false)
          setCheckRadio([])
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchMiniGameBetting()
          setWriteLoading(false)
          setCheckRadio([])
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onDeleteHandlerAll = () => {
    if (writeLoading) return
    if (authority === 'NORMAL') return

    if (
      window.confirm(
        `미니게임 배팅내역을 전체 비노출 하시겠습니까?\n비노출 시 유저에게 해당 배팅내역은 보이지 않습니다.`,
      )
    ) {
      setWriteLoading(true)
      const body = {
        miniGameHistoryKeyList: [],
        allDelete: true,
      }
      deleteMiniGameBettingLog(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
          fetchSearchMiniGameBetting()
          setWriteLoading(false)
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchMiniGameBetting()
          setWriteLoading(false)
        })
    } else {
      setWriteLoading(false)
    }
  }
  return (
    <Card>
      <CardBody>
        <BettingManagementTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ fontWeight: 'bolder', color: 'red' }}>상위파트너로 검색</span>시, 해당 파트너 포함,
              직영/하부 회원&파트너들의 내역을 가져옵니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        {/* 검색 기능 */}
        <div style={{ display: 'flex', gap: '50px', width: '100%' }}>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          <MiniGameTypeSelectWrapper>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              active={!miniGameVendor}
              onClick={() => {
                onClickMiniGameTypeHandler(null)
              }}
            >
              전체
            </Button>
            {Object.entries(MiniGameVendor).map(([key, value]) => {
              return (
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active={key === miniGameVendor}
                  onClick={() => {
                    onClickMiniGameTypeHandler(key)
                  }}
                >
                  {value}
                </Button>
              )
            })}
          </MiniGameTypeSelectWrapper>
        </div>

        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상위파트너 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTopPartnerId}
                  onChange={e => onSearchTopPartnerIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="게임결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임종류</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameType"
                placeholder="종류"
                onChange={option => {
                  onSelectGameTypeOptionHandler(option)
                }}
                value={searchGameTypeOptionValue}
                options={searchGameTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이상)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchStartAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchStartAmount}
                  onChange={e => onSearchStartAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이하)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchEndAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchEndAmount}
                  onChange={e => onSearchEndAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'green', color: 'white' }}
              onClick={e => {
                downloadMemberListHandler()
              }}
            >
              배팅내역 DB다운
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'orange', color: 'white' }}
              onClick={() => {
                setCheckRadio(content.map(value => value.miniGameBettingLogKey))
              }}
            >
              전체선택
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'grey', color: 'white' }}
              onClick={() => {
                onDeleteHandler()
              }}
            >
              삭제 (비노출)
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={() => {
                onDeleteHandlerAll()
              }}
            >
              전체삭제
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default MiniGameBettingLogList

const MiniGameTypeSelectWrapper = styled.div`
  padding: 3px 0;
  margin-bottom: 5px;

  display: flex;
  gap: 5px;
`
