import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import { AiOutlineMail } from 'react-icons/ai'
import Tooltip from '@/shared/components/Tooltip'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { NoteButton, CustomBtnTableWrap } from '../../../../shared/components/TableElements'

import MemberNoteForm from './MemberNoteForm'

function MemberNote({ memberId }) {
  const [isMemberNoteModalShow, setMemberNoteModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberNoteModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <NoteButton role={Button} onClick={() => onClickHandler()}>
        <Tooltip text="쪽지" place="bottom">
          <span>
            <NoteIcon />
          </span>
        </Tooltip>
      </NoteButton>
      <NoteCustomStyledModal show={isMemberNoteModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>쪽지 보내기 --&gt; {memberId}</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <MemberNoteForm memberId={memberId} onClickHandler={onClickHandler} />
        </CustomModalBody>
      </NoteCustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default MemberNote

MemberNote.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const NoteIcon = styled(AiOutlineMail)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: #ff1493;
  &:hover {
    cursor: pointer;
  }
`
