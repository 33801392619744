import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Card, CardBody } from '../../../../shared/components/Card'
import MiniGameManagement from '../MiniGameManagement'
import Loading from '../../../../shared/components/Loading'
import { searchTokenGameManagement } from '../../../../utils/api/systemManage/miniGameManageApi'
import TokenGameHiloForm from './TokenGameHiloForm'
import TokenGameHilo5sForm from './TokenGameHilo5sForm'
import TokenGameRouletteForm from './TokenGameRouletteForm'
import TokenGameBaccaratForm from './TokenGameBaccaratForm'
import TokenGameHorseForm from './TokenGameHorseForm'
import TokenGameFwForm from './TokenGameFwForm'
import TokenGameSnail3Form from './TokenGameSnail3Form'
import TokenGameSnail4Form from './TokenGameSnail4Form'
import TokenGamePowerBallForm from './TokenGamePowerBallForm'
import { TokenGameTypeEnums } from '../../../../enums/Log/miniGameBettingEnums'

const TokenGameManagement = () => {
  const [apiLoading, setApiLoading] = useState(false)

  const [type, setType] = useState('TOKEN_GAME_POWER_BALL')
  const [name, setName] = useState('파워볼')

  const onClickTypeHandler = (type, name) => {
    setType(type)
    setName(name)
  }

  const getNtrySettingCompo = type => {
    switch (type) {
      case 'TOKEN_GAME_POWER_BALL':
        return <TokenGamePowerBallForm miniGameType="TOKEN_GAME_POWER_BALL" />
      case 'TOKEN_GAME_ROULETTE':
        return <TokenGameRouletteForm miniGameType="TOKEN_GAME_ROULETTE" />
      case 'TOKEN_GAME_HILO':
        return <TokenGameHiloForm miniGameType="TOKEN_GAME_HILO" />
      case 'TOKEN_GAME_HILO_5S':
        return <TokenGameHilo5sForm miniGameType="TOKEN_GAME_HILO_5S" />
      case 'TOKEN_GAME_BACCARAT':
        return <TokenGameBaccaratForm miniGameType="TOKEN_GAME_BACCARAT" />
      case 'TOKEN_GAME_HORSE':
        return <TokenGameHorseForm miniGameType="TOKEN_GAME_HORSE" />
      case 'TOKEN_GAME_FW':
        return <TokenGameFwForm miniGameType="TOKEN_GAME_FW" />
      case 'TOKEN_GAME_SNAIL3':
        return <TokenGameSnail3Form miniGameType="TOKEN_GAME_SNAIL3" />
      case 'TOKEN_GAME_SNAIL4':
        return <TokenGameSnail4Form miniGameType="TOKEN_GAME_SNAIL4" />
      default:
        return null
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <Card>
        <CardBody>
          <MiniGameManagement activeKey="6" />

          <InfoBoxWrap>
            <p style={{ background: '#fff79e' }}>
              * <span style={{ color: 'red' }}>상태</span>를 점검으로 바꿀 시, 해당 게임은 배팅할 수 없습니다.
              <br />* <span style={{ color: 'red' }}>동일라운드</span> 배팅허용 시, 해당 게임은 같은 라운드내 2회 배팅이
              가능합니다.
              <br />* <span style={{ color: 'red' }}>마감시간</span> x초 설정 시, 해당 게임은 라운드 마감 x초 전에
              마감됩니다.
            </p>
          </InfoBoxWrap>

          <MiniGameTypeWrapper>
            {Object.entries(TokenGameTypeEnums).map(([key, value]) => {
              return (
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active={key === type}
                  onClick={() => {
                    onClickTypeHandler(key, value)
                  }}
                >
                  {value}
                </Button>
              )
            })}
          </MiniGameTypeWrapper>

          <LevelBoxWrap>
            <LevelBoxHeader>{name}</LevelBoxHeader>
            {getNtrySettingCompo(type)}
          </LevelBoxWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default TokenGameManagement

const LevelBoxWrap = styled.div`
  width: 100%;
  min-height: 300px;

  padding: 10px;

  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    margin-top: 15px;
  }

  p {
    margin-bottom: 10px;
  }
`

const LevelBoxHeader = styled.div`
  width: 100%;
  font-size: 23px;
  color: blue;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 10px;
`

const InfoBoxWrap = styled.div`
  width: 100%;
  padding: 5px 0;

  p {
    margin-bottom: 10px;
  }
`

const MiniGameTypeWrapper = styled.div`
  width: 100%;
  padding: 5px 0;
  display: flex;
  gap: 5px;
`
