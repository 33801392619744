import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import { AiOutlineMail } from 'react-icons/ai'
import Tooltip from '@/shared/components/Tooltip'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import WithdrawalMemoForm from './WithdrawalMemoForm'
import { CustomBtnTableWrap } from '../../../shared/components/TableElements'

function WithdrawalMemo({ memberId, memo, moneyWithdrawalKey, fetchSearchMemberMoneyWithdrawal }) {
  const [isMemberNoteModalShow, setMemberNoteModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberNoteModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <span
        style={{ cursor: 'pointer', textDecoration: 'underLine', fontWeight: '500', color: 'red' }}
        onClick={() => onClickHandler()}
      >
        {memo || '메모'}
      </span>
      <NoteCustomStyledModal show={isMemberNoteModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>출금 메모 수정 --&gt; {memberId}</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <WithdrawalMemoForm
            memberId={memberId}
            onClickHandler={onClickHandler}
            moneyWithdrawalKey={moneyWithdrawalKey}
            memo={memo}
            fetchSearchMemberMoneyWithdrawal={fetchSearchMemberMoneyWithdrawal}
          />
        </CustomModalBody>
      </NoteCustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default WithdrawalMemo

WithdrawalMemo.propTypes = {
  memberId: PropTypes.string.isRequired,
  fetchSearchMemberMoneyWithdrawal: PropTypes.func.isRequired,
  moneyWithdrawalKey: PropTypes.string.isRequired,
  memo: PropTypes.string.isRequired,
}

const NoteIcon = styled(AiOutlineMail)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: #ff1493;
  &:hover {
    cursor: pointer;
  }
`
