import { Button } from '@/shared/components/Button'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import PropTypes from 'prop-types'
import { borderRight } from '@/utils/directions'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import { EditorState } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import {
  IpRestrictStatusEnumsSelectOptions,
  ServiceStatusEnumsSelectOptions,
  IpRestrictStatusEnums,
} from '../../../enums/SystemManagement/siteEnums'
import { CustomDatePickerWrap } from '../../../shared/components/CustomDatePicker'
import Loading from '../../../shared/components/Loading'
import { updateIpManage, searchIpManage, useSearchSystemManage } from '../../../utils/api/systemManage/systemManageApi'
import { convertToKstDateTimePicker } from '../../../utils/dateTime'
import { convertFromFront, convertFromServer } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { systemManagementValidate } from '../../../utils/validate/systemManagement/systemManage'
// import validate from '../validate'

const IpMangeMent = () => {
  /**
   시스템설정 조회
   */
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)

  // 서비스 상태
  const [serviceStatus, setServiceStatus] = useState(null)
  const [serviceStatusValue, setServiceStatusValue] = useState(null)
  const serviceStatusOption = IpRestrictStatusEnumsSelectOptions()
  const [ipAllowList, setIpAllowList] = useState([])
  const [finalList, setFinalList] = useState([])
  const onChangeServiceStatusSelectHandler = option => {
    setServiceStatusValue(option)
  }
  const [formGroups, setFormGroups] = useState([])
  const [newFormData, setNewFormData] = useState([])

  const onAddHandler = () => {
    setFormGroups(prevFormGroups => [...prevFormGroups, {}])
  }

  const fetchIpManage = async () => {
    await searchIpManage().then(res => {
      setServiceStatusValue({
        value: `${res.data.ipRestrictStatus}`,
        label: IpRestrictStatusEnums[res.data.ipRestrictStatus],
      })
      setIpAllowList(res.data.ipAllowList)
      setFinalList(res.data.ipAllowList)
    })
  }

  useEffect(() => {
    fetchIpManage()
  }, [])

  useEffect(() => {
    // formGroups의 입력값이 있는 요소들만 추출하여 finalList 상태 업데이트
    const nonEmptyInputs = newFormData.filter(item => item)
    const existIpList = ipAllowList.filter(item => item !== '')
    const mergedList = [...existIpList, ...nonEmptyInputs]

    setFinalList(mergedList)
  }, [ipAllowList, newFormData])

  const onSubmit = e => {
    if (writeLoading) return

    if (window.confirm('IP 설정을 변경 하시겠습니까?')) {
      const body = {
        ipRestrictStatus: serviceStatusValue.value,
        ipAllowList: finalList,
      }

      setWriteLoading(true)
      updateIpManage(body)
        .then(res => {
          setWriteLoading(false)
          alert('IP 설정이 변경됐습니다.')
          window.location.reload()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('IP설정이 변경이 실패했습니다.')
          window.location.reload()
        })
    }
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <SearchCardTitleWrap>
            <div style={{ marginBottom: '10px' }}>
              <p>
                * IP 제한 상태를 비허용 시 <span style={{ fontWeight: 'bolder', color: 'red' }}>어느곳에든</span> 접근이
                가능합니다.
              </p>
              <p>
                * IP 리스트에서 제거 시 <span style={{ fontWeight: 'bolder', color: 'red' }}>빈 값</span>을 입력하시면
                됩니다.
              </p>
            </div>
          </SearchCardTitleWrap>

          {writeLoading ? (
            <Loading />
          ) : (
            <>
              <Form
                onSubmit={onSubmit}
                validate={systemManagementValidate}
                initialValues={{
                  serviceStatus: serviceStatusValue || {},
                  // tawkInfo: systemManageData.tawkInfo,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <FormContainer horizontal onSubmit={handleSubmit}>
                    <FormGroup>
                      <FormGroupLabel>IP 제한여부</FormGroupLabel>
                      <FormGroupField>
                        <Field name="serviceStatus">
                          {({ input, meta }) => (
                            <div style={{ width: '100%' }}>
                              <MemberCouponSelect
                                {...input}
                                value={serviceStatusValue}
                                onChange={option => {
                                  input.onChange(option)
                                  onChangeServiceStatusSelectHandler(option)
                                }}
                                options={serviceStatusOption}
                                placeholder="IP 제한"
                                className="react-select"
                                classNamePrefix="react-select"
                              />
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </div>
                          )}
                        </Field>
                      </FormGroupField>
                    </FormGroup>
                    {ipAllowList.map((item, index) => (
                      <FormGroup>
                        <FormGroupLabel>IP 허용{index + 1}</FormGroupLabel>
                        <FormGroupField>
                          <Field name={`ipRestrict_${index}`}>
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={item}
                                  onChange={e => {
                                    input.onChange(e)
                                    setIpAllowList(prevIpAllowList => {
                                      const updatedIpAllowList = [...prevIpAllowList]
                                      updatedIpAllowList[index] = e.target.value
                                      return updatedIpAllowList
                                    })
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                    ))}
                    {formGroups.map((item, index) => (
                      <FormGroup>
                        <FormGroupLabel>IP 추가허용{index + 1}</FormGroupLabel>
                        <FormGroupField>
                          <Field name={`ipRestrict_${index}`}>
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                    setNewFormData(prevNewFormData => {
                                      const updatedNewFormData = [...prevNewFormData]
                                      if (!updatedNewFormData[index]) {
                                        // 해당 인덱스의 요소가 없으면 새로운 요소 추가

                                        updatedNewFormData[index] = e.target.value
                                      } else {
                                        // 해당 인덱스의 요소가 있으면 기존 요소 업데이트
                                        updatedNewFormData[index] = e.target.value
                                      }
                                      return updatedNewFormData
                                    })
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                    ))}
                    {
                      {
                        SUPER: (
                          <FormButtonToolbar className="registrationFooter">
                            <Button variant="danger" onClick={onAddHandler}>
                              IP 추가
                            </Button>
                            <Button variant="primary" onClick={onSubmit}>
                              수정하기
                            </Button>
                          </FormButtonToolbar>
                        ),
                      }[authority]
                    }
                  </FormContainer>
                )}
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

IpMangeMent.propTypes = {
  // onSubmit: PropTypes.func.isRequired,
}

export default IpMangeMent

// 회원 쿠폰 Select
export const MemberCouponSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
// endregion
