import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Field, Form } from 'react-final-form'
import { decodeAccessToken } from '../../../utils/token'
import {
  searchVirturalGameMarketList,
  searchVirturalGameSportList,
  updateVirturalGameMarketList,
  updateVirturalGameSportList,
} from '../../../utils/api/virtualGame/virtualGameApi'
import { VirtualGameSportType } from '../../../enums/VirtualGame/virtualGameEnums'

const VirtualVendorManagement = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [virtualGameSportList, setVirtualGameSportList] = useState([])
  const [changes, setChanges] = useState({})
  const fetchSearchVirtualGameSport = async () => {
    const res = await searchVirturalGameSportList()
    setVirtualGameSportList(res.data.virtualGameSportList)
  }

  const handleInputChange = (key, field, value) => {
    setChanges({
      ...changes,
      [key]: {
        ...changes[key],
        [field]: value,
      },
    })
  }

  const [virtualGameMarketList, setVirtualGameMarketList] = useState([])
  const [marketChanges, setMarketChanges] = useState({})
  const fetchSearchVirtualGametMarket = async () => {
    const res = await searchVirturalGameMarketList()
    setVirtualGameMarketList(res.data.virtualGameMarketList)
  }
  useEffect(() => {
    fetchSearchVirtualGametMarket()
    fetchSearchVirtualGameSport()
  }, [])

  const handleMarketInputChange = (key, field, value) => {
    setMarketChanges({
      ...marketChanges,
      [key]: {
        ...marketChanges[key],
        [field]: value,
      },
    })
  }

  const [apiLoading, setApiLoading] = useState(false)

  const onSubmit = e => {
    if (apiLoading) return
    setApiLoading(true)
    // Prepare the body with only changed items
    const updatedItems = Object.keys(changes).map(key => ({
      virtualGameSportKey: key,
      ...virtualGameSportList.find(item => item.virtualGameSportKey === key),
      ...changes[key],
    }))

    // Check if there are no updated items
    if (updatedItems.length === 0) {
      alert('변경된 것이 없습니다.')
      setApiLoading(false)
      return // Exit the function early
    }
    const body = {
      virtualGameSportList: updatedItems,
    }
    if (window.confirm('설정을 변경하시겠습니까?')) {
      updateVirturalGameSportList(body)
        .then(res => {
          alert('수정되었습니다.')
          fetchSearchVirtualGameSport()
          setApiLoading(false)
        })
        .catch(error => {
          alert('수정 실패했습니다.')
          fetchSearchVirtualGameSport(false)
          setApiLoading(false)
        })
    }
  }

  const onMarketSubmit = e => {
    if (apiLoading) return
    setApiLoading(true)
    // Prepare the body with only changed items
    const updatedItems = Object.keys(marketChanges).map(key => ({
      marketKey: key,
      ...virtualGameMarketList.find(item => item.marketKey === key),
      ...marketChanges[key],
    }))

    // Check if there are no updated items
    if (updatedItems.length === 0) {
      alert('변경된 것이 없습니다.')
      setApiLoading(false)
      return // Exit the function early
    }
    const body = {
      virtualGameMarketList: updatedItems,
    }
    if (window.confirm('설정을 변경하시겠습니까?')) {
      updateVirturalGameMarketList(body)
        .then(res => {
          alert('수정되었습니다.')
          fetchSearchVirtualGametMarket()
          setApiLoading(false)
        })
        .catch(error => {
          alert('수정 실패했습니다.')
          fetchSearchVirtualGametMarket(false)
          setApiLoading(false)
        })
    }
  }
  return (
    <VirtualVendorWrap>
      <HeaderBox>종목관리</HeaderBox>
      <Form onSubmit={onSubmit} initialValues={{}}>
        {({ handleSubmit, form: { reset } }) => (
          <FormContainer onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderLeft: '1px solid black' }}>번호</th>
                    <th>이름</th>
                    <th>점검여부</th>
                    <th>순서</th>
                  </tr>
                </thead>
                <tbody>
                  {virtualGameSportList.map((item, index) => (
                    <tr key={item.virtualGameSportKey}>
                      <td style={{ borderLeft: 'solid 1px #000' }}>{index + 1}</td>
                      <CustomTd type={item.virtualGameSportType}>
                        {VirtualGameSportType[item.virtualGameSportType]}
                      </CustomTd>
                      <td>
                        <input
                          type="checkbox"
                          style={{ width: '18px', height: '18px' }}
                          checked={
                            changes[item.virtualGameSportKey]?.isShow !== undefined
                              ? changes[item.virtualGameSportKey].isShow
                              : item.isShow
                          }
                          onChange={e => handleInputChange(item.virtualGameSportKey, 'isShow', e.target.checked)}
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          value={changes[item.virtualGameSportKey]?.orderNum || item.orderNum}
                          onChange={e =>
                            handleInputChange(item.virtualGameSportKey, 'orderNum', parseInt(e.target.value, 10))
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableWrap>
            {
              {
                SUPER: (
                  <ButtonBox
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <button type="submit" style={{ background: 'grey', width: '120px', padding: '10px' }}>
                      수정하기
                    </button>
                  </ButtonBox>
                ),
              }[authority]
            }
          </FormContainer>
        )}
      </Form>
      <HeaderBox>마켓관리</HeaderBox>
      <Form onSubmit={onMarketSubmit} initialValues={{}}>
        {({ handleSubmit, form: { reset } }) => (
          <FormContainer onSubmit={handleSubmit} style={{ marginBottom: '10px' }}>
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderLeft: '1px solid black' }}>번호</th>
                    <th>종목</th>
                    <th>마켓명</th>
                    <th>사용여부</th>
                  </tr>
                </thead>
                <tbody>
                  {virtualGameMarketList.map((item, index) => (
                    <tr key={item.marketKey}>
                      <td style={{ borderLeft: 'solid 1px #000' }}>{index + 1}</td>
                      <CustomTd type={item.sportName}>{VirtualGameSportType[item.sportName]}</CustomTd>
                      <td>
                        <input type="text" value={item.marketNameKo} disabled />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          style={{ width: '18px', height: '18px' }}
                          checked={
                            marketChanges[item.marketKey]?.usedMarket !== undefined
                              ? marketChanges[item.marketKey].usedMarket
                              : item.usedMarket
                          }
                          onChange={e => handleMarketInputChange(item.marketKey, 'usedMarket', e.target.checked)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableWrap>
            {
              {
                SUPER: (
                  <ButtonBox
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <button
                      type="button"
                      onClick={onMarketSubmit}
                      style={{ background: 'grey', width: '120px', padding: '10px' }}
                    >
                      수정하기
                    </button>
                  </ButtonBox>
                ),
              }[authority]
            }
          </FormContainer>
        )}
      </Form>
    </VirtualVendorWrap>
  )
}

export default VirtualVendorManagement

VirtualVendorManagement.propTypes = {}

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 5px;
  border-top: 2px solid black;
  display: flex;
  position: relative;
  p {
    font-size: 11px;
    color: red;
  }
`

const getColor = type => {
  switch (true) {
    case type === 'VIRTUAL_SOCCER':
      return '#f6a033'
    case type === 'VIRTUAL_BASKETBALL':
      return '#f44c32'
    case type === 'VIRTUAL_BASEBALL':
      return '#731cf7'
    case type === 'VIRTUAL_HORSE':
      return '#36bd29'
    case type === 'VIRTUAL_GREYHOUNDS':
      return '#24c1f0'
    default:
      return 'black'
  }
}

const CustomTd = styled.td`
  color: #fff;
  background: ${props => getColor(props.type)};
`
const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`

const VirtualVendorWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 70px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
`
