import { ButtonToolbar } from '@/shared/components/Button'
import {
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
  FormButtonToolbar,
} from '@/shared/components/form/FormElements'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import { colorBackground, colorBlue, colorIcon, colorText, colorWhite } from '@/utils/palette'
import { Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Badge, Modal as BootstrapModal, Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { searchSportsMarket } from '../../../../utils/api/sportsManage/sportsInfo'
import { addNewMarketPoint, updateSportsMarketInfo } from '../../../../utils/api/sport/sportIInfoApi'
import { convertToKstSportsEventDateTime } from '../../../../utils/dateTime'
import Loading from '../../../../shared/components/Loading'

function SportsMarketPointAddModal({
  marketInfo,
  sportsMarketPointAddModalShow,
  onClickSportsMarketPointAddModalHnadler,
  fetchSearchSportsMarket,
}) {
  const [newMarketInfo, setNewMarketInfo] = useState({})
  const [lastSportsMarketPoint, setLastSportsMarketPoint] = useState({})

  useEffect(() => {
    setNewMarketInfo(marketInfo)
    setLastSportsMarketPoint(marketInfo.sportsMarketPoints[marketInfo.sportsMarketPoints.length - 1])
  }, [marketInfo])

  const [resultPoint, setResultPoint] = useState({
    pointValue: '',
    homeOddValue: '',
    homeOddName: '',
    homeOddNameKr: '',
    awayOddValue: '',
    awayOddName: '',
    awayOddNameKr: '',
    drawOddValue: '',
    drawOddName: '',
    drawOddNameKr: '',
  })

  const onChangePointValue = value => {
    setResultPoint(prev => ({
      ...prev, // 이전 상태를 복사
      pointValue: value, // pointValue만 업데이트
    }))
  }

  const onChangOddValue = (oddName, oddNameKr, value) => {
    let keyPrefix

    switch (oddName) {
      case 'Home':
      case 'Under':
        keyPrefix = 'home'
        break

      case 'Away':
      case 'Over':
        keyPrefix = 'away'
        break

      case 'Draw':
        keyPrefix = 'draw'
        break

      default:
        break
    }

    setResultPoint(prev => ({
      ...prev, // 이전 상태를 복사
      [`${keyPrefix}OddValue`]: value,
      [`${keyPrefix}OddName`]: oddName,
      [`${keyPrefix}OddNameKr`]: oddNameKr,
    }))
  }

  const getPointBoxCompo = () => {
    const name = lastSportsMarketPoint.name2 ? lastSportsMarketPoint.name2 : lastSportsMarketPoint.name

    if (name) {
      return (
        <>
          <FormGroup>
            <FormGroupLabel>기준점</FormGroupLabel>
            <FormGroupField>
              <Field name="pointValue">
                {({ input, meta }) => (
                  <FormInputWrap style={{ border: '1px solid black' }}>
                    <input
                      {...input}
                      type="text"
                      value={resultPoint?.pointValue}
                      onChange={e => {
                        const inputValue = e.target.value

                        // 정규식: 숫자와 소수점만 허용
                        if (/^-?\d*(\.\d{0,2})?$/.test(inputValue)) {
                          onChangePointValue(inputValue)
                        }
                      }}
                    />
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
        </>
      )
    }
    return <></>
  }

  const getNewOddValue = oddName => {
    let key

    switch (oddName) {
      case 'Home':
      case 'Under':
        key = 'homeOddValue'
        break

      case 'Away':
      case 'Over':
        key = 'awayOddValue'
        break

      case 'Draw':
        key = 'drawOddValue'
        break

      default:
        break
    }

    return resultPoint[key]
  }

  const [apiLoading, setApiLoading] = useState(false)
  const onSubmit = () => {
    if (apiLoading) return

    setApiLoading(true)

    const body = {
      sportsMarketKey: newMarketInfo.sportsMarketKey,
      marketId: newMarketInfo.marketId,
      marketName: newMarketInfo.marketName,
      pointValue: resultPoint.pointValue,
      homeOddValue: resultPoint.homeOddValue,
      homeOddName: resultPoint.homeOddName,
      homeOddNameKr: resultPoint.homeOddNameKr,
      awayOddValue: resultPoint.awayOddValue,
      awayOddName: resultPoint.awayOddName,
      awayOddNameKr: resultPoint.awayOddNameKr,
      drawOddValue: resultPoint.drawOddValue,
      drawOddName: resultPoint.drawOddName,
      drawOddNameKr: resultPoint.drawOddNameKr,
    }

    if (window.confirm('정말로 추가하시겠습니까?')) {
      addNewMarketPoint(body)
        .then(res => {
          alert('추가되었습니다.')
          setApiLoading(false)
          fetchSearchSportsMarket()
          onClickSportsMarketPointAddModalHnadler()
        })
        .catch(error => {
          alert('실패했습니다.')
          fetchSearchSportsMarket()
          setApiLoading(false)
        })
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <StyledModal show={sportsMarketPointAddModalShow} onHide={onClickSportsMarketPointAddModalHnadler} header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickSportsMarketPointAddModalHnadler}
          />
          <ModalTitle>
            <span>경기 마켓 배당 추가</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SportsMarketContentHeaderWrap>
            {/* <SportsMarketContentHeader style={{ padding: '10px' }}>
              <span> * 추가된 배당은 기본적으로 유저들에게 보여집니다.</span>
            </SportsMarketContentHeader> */}
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>마켓 명</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>{newMarketInfo?.marketName}</SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
          </SportsMarketContentHeaderWrap>
          <SportsMarketContentBodyWrap>
            <Form onSubmit={onSubmit} initialValues={{ resultPoint }}>
              {({ handleSubmit, form: { reset } }) => (
                <SportsMarketFormContainer horizontal onSubmit={handleSubmit} style={{ flexDirection: 'column' }}>
                  {getPointBoxCompo()}

                  {lastSportsMarketPoint?.odds?.map((odd, index) => {
                    return (
                      <FormGroup>
                        <FormGroupLabel>{odd?.oddNameKr}</FormGroupLabel>
                        <FormGroupField>
                          <Field name={`oddValue-${index}`}>
                            {({ input, meta }) => (
                              <FormInputWrap style={{ border: '1px solid black' }}>
                                <input
                                  {...input}
                                  type="text"
                                  value={getNewOddValue(odd.oddName)}
                                  onChange={e => {
                                    const inputValue = e.target.value

                                    // 정규식: 숫자와 소수점만 허용
                                    if (/^\d*(\.\d{0,2})?$/.test(inputValue)) {
                                      onChangOddValue(odd.oddName, odd.oddNameKr, inputValue)
                                    }
                                  }}
                                />
                                {/* {meta.error && <Error error={meta.error} />} */}
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>
                    )
                  })}

                  <FormButtonToolbar style={{ marginLeft: '0' }}>
                    <Button variant="primary" type="submit">
                      추가하기
                    </Button>
                  </FormButtonToolbar>
                </SportsMarketFormContainer>
              )}
            </Form>
          </SportsMarketContentBodyWrap>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default SportsMarketPointAddModal

SportsMarketPointAddModal.propTypes = {
  marketInfo: PropTypes.shape(),
  sportsMarketPointAddModalShow: PropTypes.bool,
  onClickSportsMarketPointAddModalHnadler: PropTypes.func,
  fetchSearchSportsMarket: PropTypes.func,
}

SportsMarketPointAddModal.defaultProps = {
  marketInfo: {},
  sportsMarketPointAddModalShow: false,
  onClickSportsMarketPointAddModalHnadler: () => {},
  fetchSearchSportsMarket: () => {},
}

export const CustomButton = styled(Button)`
  width: 50px;
  height: 30px;
  font-size: 15px;
  padding: 0px;
  padding-top: 3px;
`

export const CustomInput = styled.input`
  border: none !important;
  height: 22px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
`

export const CustomFormInputWrap = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
`

const ErrorText = styled.span`
  font-size: 10px;
  font-weight: 650;
  line-height: 13px;
  color: red;
  margin-bottom: -5px;
  display: block;
  // margin-top: -10px;
`

const SportsMarketFormContainer = styled(FormContainer)`
  flex-direction: column;
  padding: 20px 0;
  // border: 1px solid gray;
`

const SportsMarketShowCheckbox = styled(Checkbox)`
  width: 10px;
  height: 10px;
`

const SportsMarketContentHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
`

const SportsMarketContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #888;
`

const SportsMarketContentHeaderLabel = styled.span`
  width: 30%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  border-right: 2px solid #888;
`

const SportsMarketContentHeaderValue = styled.span`
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
`

const SportsMarketContentBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 20px;
`

const SportsMarketNameWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* 오른쪽 끝으로 정렬 */

  width: 100%;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
  color: white;

  background-color: #888;
  border: 1px solid black;
`

const SportsMarketOddWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.name && `repeat(${props.name}, minmax(0, 1fr))`};
  &:hover {
    cursor: pointer;
    border: 2px solid red;
  }
`

const SportsMarketOddItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  font-size: 14px;
`

const SportsMarketExchangeItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  color: blue;
  font-weight: 500;
  font-size: 14px;
`

const SportsMarketOddItemTitle = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  background: #a6a6a6;
  color: white;
  font-size: 14px;
`

const StopBadge = styled(Badge)`
  width: 40px;
  height: 16px;
  background-color: #ff4861;
  font-weight: 500;
  border-radius: 7px;
  text-align: center;
  margin-left: 5px;
  span {
    width: 100%;
  }
`

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div`
  background-color: grey !important;
  font-weight: 700;
`
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 500px !important;
    margin-top: 100px;
  }

  .modal-content {
    height: max-content;
    min-height: 400px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
