import axios from '../base/axios'

// 경기 목록 조회
export async function searchSportInPlayFixtureList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture-inplay',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 경기 마켓 리스트 조회
export async function searchSportInPlayFixtureMarketList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture-inplay/market',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅한 경기 마켓 조회
export async function searchSportInPlayFixtureBettingMarketList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture-inplay/betting-market',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 경기 마켓 내 특정 배당 수정
export async function updateSportInPlayFixtureMarketBetList(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture-inplay/market/bet',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 배팅한 경기 마켓 세부 배팅내역 조회
export async function searchSportInPlayFixtureBettingOneInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/fixture-inplay/betting-market/bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 경기 노출여부 설정
export async function updateSportInPlayFixtureVisible(fixtureId, body) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/fixture-inplay/${fixtureId}/visible`,
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 경기 노출여부 설정
export async function updateSportInPlayFixtureIgnoreApiSignal(fixtureId, body) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/fixture-inplay/${fixtureId}/ignoreApiSignal`,
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 경기 내 마켓 노출 여부 수정
export async function updateSportInPlayFixtureMarketVisible(eventBookmakerKey, body) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/fixture-inplay/market/${eventBookmakerKey}/visible`,
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
