import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { searchMemberInfo, updateMemberZeroRollingInfo } from '@/utils/api/memberManage/memberManageApi'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../../shared/components/Loading'
import { memberZeroRollingInfoValidate } from '../../../../utils/validate/memberManage/memberInfoValidate'
import { decodeAccessToken } from '../../../../utils/token'

function MemberZeroRollingModifyForm({ memberId, onModalShowHandler, fetchSearchMember }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [infoLoading, setInfoLoading] = useState(false)

  // 롤링 슬롯, 라이브 비율
  const [zeroSlotRollingPer, setZeroSlotRollingPer] = useState('')
  const [zeroLiveRollingPer, setZeroLiveRollingPer] = useState('')

  // 매장, 총판, 부본사, 본사 변수
  const [partnerLine, setPartnerLine] = useState('')

  const [memberInfo, setMemberInfo] = useState({})

  const getMemberInfo = async () => {
    await searchMemberInfo({
      memberId,
    }).then(res => {
      setMemberInfo(res.data.content)
      setZeroSlotRollingPer(res.data.content.zeroSlotRollingPer)
      setZeroLiveRollingPer(res.data.content.zeroLiveRollingPer)
      setPartnerLine(res.data.content.partnerLine)
    })
  }

  useEffect(() => {
    if (memberId === null) return
    getMemberInfo()
  }, [memberId])

  const onSubmit = e => {
    if (infoLoading) return
    if (!window.confirm(`[${memberInfo?.memberName}] 회원의 공배팅 요율 정보를 수정하시겠습니까?`)) {
      return
    }

    if (memberInfo.status === 'CANCEL' || memberInfo.status === 'WAITING' || memberInfo.status === 'APPLY') {
      alert('회원의 상태가 신청,대기,취소 인 경우에는 회원 공배팅 요율정보를 변경할 수 없습니다.')
      return
    }

    const body = {
      memberKey: memberInfo.memberKey,
      zeroSlotRollingPer: e.zeroSlotRollingPer,
      zeroLiveRollingPer: e.zeroLiveRollingPer,
    }
    setInfoLoading(true)

    updateMemberZeroRollingInfo(body)
      .then(res => {
        setInfoLoading(false)
        onModalShowHandler()
        alert('회원 공배팅 요율 정보가 수정되었습니다.')
        fetchSearchMember()
      })
      .catch(error => {
        setInfoLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'MEMBER-1007') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1008') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1009') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1010') {
          alert(`${message}`)
        } else {
          alert('회원 공배팅 요율 정보 수정이 실패했습니다. 잠시 후 다시 시도해주세요.')
        }
      })
  }

  return (
    <>
      {infoLoading ? (
        <Loading />
      ) : (
        <>
          <Form
            onSubmit={onSubmit}
            validate={memberZeroRollingInfoValidate}
            initialValues={{
              memberId: `${memberInfo?.memberId}(${memberInfo?.memberName})`,
              memberName: memberInfo?.memberName,
              zeroSlotRollingPer,
              zeroLiveRollingPer,
              partnerLine,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>회원ID(명)</FormGroupLabel>
                  <FormGroupField>
                    <Field name="memberId">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            disabled
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>파트너 라인</FormGroupLabel>
                  <FormGroupField>
                    <Field name="partnerLine">
                      {({ input, meta }) => (
                        <FormInputWrap
                          style={{ border: 'solid 1px #f2f4f7', paddingLeft: '5px', backgroundColor: '#F2F4F7' }}
                        >
                          <div>{partnerLine} </div>
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>공배팅비율%(SLOT/LIVE)</FormGroupLabel>
                  <FormGroupField>
                    <Field name="zeroSlotRollingPer">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ borderRight: 'solid 1px' }}>
                          <input
                            {...input}
                            type="text"
                            style={{ textAlign: 'center' }}
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                      )}
                    </Field>
                    <Field name="zeroLiveRollingPer">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            style={{ textAlign: 'center' }}
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                  <FormGroupDescription style={{ marginTop: '10px' }}>
                    공배팅 비율은 <span style={{ color: 'blue', fontWeight: '600' }}>1 ~ 50</span> 사이의 숫자를
                    입력해주세요.
                  </FormGroupDescription>
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                        <CustomButton variant="primary" type="submit">
                          변경하기
                        </CustomButton>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default MemberZeroRollingModifyForm

MemberZeroRollingModifyForm.propTypes = {
  fetchSearchMember: PropTypes.func.isRequired,
  onModalShowHandler: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`
