import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colorDarkRed, colorVeryLightRed } from '@/utils/palette'
import { right } from '@/utils/directions'

const Error = ({ error, top, mt }) => (
  <ErrorText $top={top} $mt={mt}>
    {error}
  </ErrorText>
)

Error.propTypes = {
  error: PropTypes.string.isRequired,
  top: PropTypes.bool,
  mt: PropTypes.string,
}

Error.defaultProps = {
  top: false,
  mt: '10',
}

export default Error

// region STYLES

const ErrorText = styled.span`
  font-size: 10px;
  font-weight: 650;
  line-height: 13px;
  // color: ${colorDarkRed};
  color: red;
  // margin-bottom: -5px;
  display: block;
  // margin-top: 10px;
  margin-top: ${props => props.$mt && `${props.$mt}px`};

  ${props =>
    props.$top &&
    `
    position: absolute;
    margin: 0;
    ${right(props)}: 0;
    top: 0;
    padding: 5px 10px;
    background: ${colorVeryLightRed};
    border-radius: 3px;

    &:after {
      content: '';
      position: absolute;
      ${right(props)}: 10px;
      bottom: -8px;
      border: 4px solid transparent;
      border-top: 4px solid ${colorVeryLightRed};
    }
  `}
`

// endregion
