import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 콜 유저 목록 조회
export async function getCallUsers(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/callmanage/users',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 콜 목록 조회
export async function getCallList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/callmanage/call-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 콜 적용
export async function applyCall(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/callmanage/call',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 환수율 변경
export async function updateRecoveryPer(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/callmanage/recovery-per',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 콜 이력 조회
export async function getCallHistory(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/callmanage/call-history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useGetCallHistory(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  async function fetchGetCallHistory() {
    await getCallHistory(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchGetCallHistory()
  }, [params])

  return { content, totalElement, fetchGetCallHistory }
}
