import React from 'react'
import { IncDecTypeEnums, MoneyIncDecTypeEnums } from '../../../../enums/Log/moneyIncDecEnums'
import { convertToKstDateTime, convertToKstDateTimeSimple } from '../../../../utils/dateTime'
import { commonReg2 } from '../../../../utils/validate/commonValidate'

const MemberMoneyIncDecListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '지급인',
      accessor: 'executeId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '종류',
      accessor: 'incDecType',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.incDecType) {
          case 'INC':
            return <span style={{ color: 'blue' }}>{IncDecTypeEnums[original.incDecType]}</span>
          case 'DEC':
            return <span style={{ color: 'red' }}>{IncDecTypeEnums[original.incDecType]}</span>
          default:
            return <>{IncDecTypeEnums[original.incDecType]}</>
        }
      },
    },
    {
      Header: '구분',
      accessor: 'assetType',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MoneyIncDecTypeEnums[original.assetType]
      },
    },
    {
      Header: '보유금액',
      accessor: 'beforeAmount',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '증감금액',
      accessor: 'changeAmount',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '최종금액',
      accessor: 'afterAmount',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일시',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.executeDate)
      },
    },
    {
      Header: '메모',
      accessor: 'incDecReason',
      disableGlobalFilter: true,
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberMoneyIncDecListData
