import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setActiveMarketSportMenu, setActiveMarketSportMenuuExceptAll } from '../../../redux/SportInfoSlice'

const MarketSportMenu = () => {
  const dispatch = useDispatch()

  const { sportMenu, activeMarketSportMenu } = useSelector(state => {
    const { sportInfo } = state

    return {
      sportMenu: sportInfo.sportMenuExceptAll,
      activeMarketSportMenu: sportInfo.activeMarketSportMenuExceptAll,
    }
  })

  const onClickSportMenuHandler = useCallback(
    sportId => {
      dispatch(setActiveMarketSportMenuuExceptAll(sportId))
    },
    [dispatch],
  )
  return (
    <MenuBoxWrap>
      {sportMenu.map(sportMenu => {
        return (
          <MenuTitleBox
            key={sportMenu.sportId}
            active={activeMarketSportMenu === sportMenu.sportId}
            onClick={() => {
              onClickSportMenuHandler(sportMenu.sportId)
            }}
          >
            {sportMenu.sportName}
          </MenuTitleBox>
        )
      })}
    </MenuBoxWrap>
  )
}

export default React.memo(MarketSportMenu)

const MenuBoxWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 5px 0;
`

const MenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 1px solid black;
  `};
`
