import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { OneToOneAuthorityEnums } from '../../../enums/Operation/OneToOneEnums'
import { DepoWithdrawTypeEnums, MoneyIncDecAuthorityEnums, IncDecTypeEnums } from '../../../enums/Log/moneyIncDecEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import { MoneySortEnums } from '../../../enums/MemberHistory/MemberDepositEnums'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyDepoWithdrawListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '유저ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div style={{ width: '100%' }}>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.memberId} />
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </div>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {MoneyIncDecAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '최상위 파트너명',
      accessor: 'topOfTopPartnerName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '직영 파트너명',
      accessor: 'topPartnerName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '종류',
      accessor: 'moneyLogType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.moneyLogType) {
          case 'DEPOSIT':
            return (
              <span style={{ color: 'blue', fontWeight: '900' }}>{DepoWithdrawTypeEnums[original.moneyLogType]}</span>
            )
          case 'WITHDRAWAL':
            return (
              <span style={{ color: 'red', fontWeight: '900' }}>{DepoWithdrawTypeEnums[original.moneyLogType]}</span>
            )
          default:
            return (
              <span style={{ color: 'black', fontWeight: '900' }}>{DepoWithdrawTypeEnums[original.moneyLogType]}</span>
            )
        }
      },
    },
    {
      Header: '일반/홀덤',
      accessor: 'moneySort',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.moneySort) {
          case 'NORMAL_MONEY':
            return (
              <button type="button" style={{ background: '#7d2424', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MoneySortEnums[original.moneySort]}
              </button>
            )
          case 'HOLDEM_MONEY':
            return (
              <button type="button" style={{ background: '#276687', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MoneySortEnums[original.moneySort]}
              </button>
            )

          default:
            return (
              <button type="button" style={{ background: '#7d2424', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MoneySortEnums.NORMAL_MONEY}
              </button>
            )
        }
      },
    },
    {
      Header: '변경전 머니',
      accessor: 'beforeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '입출금액',
      accessor: 'changeAmountlog',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changeAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '보너스액',
      accessor: 'bonusAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bonusAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후 머니',
      accessor: 'afterAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyDepoWithdrawListData
