import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { CustomBtnTableWrap } from '../../../shared/components/TableElements'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { adminSearchWildGamesBettingInfoByBettingLogKey } from '../../../utils/api/logManage/logApi'
import WildGamesBettingInfoData from './WildGamesBettingInfoData'

const WildGamesBettingInfo = ({ bettingUserId, bettingLogKey, receiverType }) => {
  const [isMemberNoteModalShow, setMemberNoteModalShow] = useState(false)
  const [apiFlag, setApiFlag] = useState(false)
  const onClickHandler = () => {
    setApiFlag(true)
    setMemberNoteModalShow(prevState => !prevState)
  }

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [content, setContent] = useState([])
  const [totalElement, setTotalElement] = useState(0)

  const fetchSearchWildGamesBettingInfoByBettingLogKey = async () => {
    await adminSearchWildGamesBettingInfoByBettingLogKey({ bettingLogKey }).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }

  useEffect(() => {
    if (!apiFlag) return
    fetchSearchWildGamesBettingInfoByBettingLogKey()
  }, [apiFlag])

  const [memberBettingLogListData, setMemberBettingLogListData] = useState(WildGamesBettingInfoData(content))

  useEffect(() => {
    setMemberBettingLogListData(WildGamesBettingInfoData(content))
  }, [content])

  const [memberBettingLogRows, setMemberBettingLogRows] = useState(memberBettingLogListData.tableRowsData)

  useEffect(() => {
    setMemberBettingLogRows(memberBettingLogListData.tableRowsData)
  }, [memberBettingLogListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  return (
    <CustomBtnTableWrap style={{ marginLeft: '10px' }}>
      <CustomButton role={Button} onClick={() => onClickHandler()}>
        배팅정보
      </CustomButton>
      <NoteCustomStyledModal show={isMemberNoteModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>배팅세부정보 - {bettingUserId}</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <ReactTableBase
            key="common"
            columns={memberBettingLogListData.tableHeaderData}
            data={memberBettingLogRows}
            tableConfig={tableConfig}
          />
        </CustomModalBody>
      </NoteCustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default WildGamesBettingInfo

WildGamesBettingInfo.propTypes = {
  bettingUserId: PropTypes.string.isRequired,
  bettingLogKey: PropTypes.string.isRequired,
  receiverType: PropTypes.string.isRequired,
}

const CustomButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
`
