import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { searchRealTimeCasinoBetting } from '../../../utils/api/logManage/logApi'
import useAudio from '../../../utils/audioHook'
import RealTimeCasinoBettingLogListData from './RealTimeCasinoBettingLogListData'

const RealTimeCasinoBettingLogList = () => {
  const [play, pause, paused, audioRef] = useAudio(`${process.env.PUBLIC_URL}/audio/betting.mp3`, {
    playbackRate: 1,
  })

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [casinoBettingLogParams, setCasinoBettingLogParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setCasinoBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [casinoTotalElement, setCasinoTotalElement] = useState(0)
  const [casinoTotalRealTimeCount, setCasinoTotalRealTimeCount] = useState(0)
  const [casinoContent, setCasinoContent] = useState([])
  const [prevTotalElement, setPrevTotalElement] = useState(0)
  const [muteAudio, setMuteAudio] = useState(JSON.parse(sessionStorage.getItem('BTCA')))

  const onCheckMuteAudioHandler = e => {
    setMuteAudio(e.target.checked)
    // BTCA = Betting Casino Audio
    sessionStorage.setItem('BTCA', e.target.checked)
  }

  useEffect(() => {
    const fetchSearchRealTimeCasinoBetting = async params => {
      await searchRealTimeCasinoBetting(params).then(res => {
        setCasinoTotalElement(res.data.totalElement)
        setCasinoTotalRealTimeCount(res.data.totalRealTimeCount)
        setCasinoContent(res.data.content)

        if (res.data.totalElement > prevTotalElement) {
          if (!muteAudio && res.data.totalRealTimeCount > 0 && paused()) {
            play()
          }
          setPrevTotalElement(res.data.totalElement)
        }
      })
    }

    fetchSearchRealTimeCasinoBetting(casinoBettingLogParams)

    const interval = setInterval(() => {
      fetchSearchRealTimeCasinoBetting(casinoBettingLogParams)
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [casinoBettingLogParams, prevTotalElement, muteAudio])

  const [casinoBettingLogListData, setCasinoBettingLogListData] = useState(
    RealTimeCasinoBettingLogListData(casinoContent),
  )
  useEffect(() => {
    setCasinoBettingLogListData(RealTimeCasinoBettingLogListData(casinoContent))
  }, [casinoContent])

  const [casinoiRows, setCasinoRowsData] = useState(casinoBettingLogListData.tableRowsData)
  useEffect(() => {
    setCasinoRowsData(casinoBettingLogListData.tableRowsData)
  }, [casinoBettingLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const casinoTableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement: casinoTotalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  return (
    <>
      <HeaderWrap>
        <h2 style={{ color: 'red' }}>카지노 실시간 내역 ({casinoTotalRealTimeCount || 0})</h2>
        <label
          htmlFor="casinoCheck"
          style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '10px' }}
        >
          <input
            type="checkbox"
            id="casinoCheck"
            checked={muteAudio}
            onChange={e => {
              onCheckMuteAudioHandler(e)
            }}
            style={{ marginRight: '10px' }}
          />
          <span style={{ color: 'red', fontWeight: '500', fontSize: '13px' }}>* 카지노 음소거하려면 체크해주세요.</span>
        </label>
      </HeaderWrap>

      <ReactTableBase
        columns={casinoBettingLogListData.tableHeaderData}
        data={casinoiRows}
        tableConfig={casinoTableConfig}
      />
    </>
  )
}

export default RealTimeCasinoBettingLogList

const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
