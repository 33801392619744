import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import Switch from 'react-switch'
import styled from 'styled-components'
import { FixtureStatus } from '../../../enums/Sport/SportFixtureEnums'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { SportLiveFixtureTabeItemBettingMarket } from './SportLiveFixtureTabeItemBettingMarket'
import { SportIdTypeEnums } from '../../../enums/SystemManagement/sportEnums'

const areEqual = (prevProps, nextProps) => {
  return prevProps.fixtureInfo === nextProps.fixtureInfo && isEqual(prevProps.fixtureInfo, nextProps.fixtureInfo) // deepEqual은 깊은 비교를 수행하는 함수를 의미
}

const SportLiveFixtureTableBodyItem = React.memo(
  ({
    fixtureInfo,
    onClickFixtureVisibleHandler,
    onClickFixtureIgnoreApiSignalSwitchHandler,
    marketPanelOpenHandler,
    bettingMarketTrOpenHandler,
    bettingInfoTrOpenHandler,
  }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const [isExpanded2, setIsExpanded2] = useState(false)
    return (
      <>
        <tr
          onClick={() => {
            const key = `${fixtureInfo.fixture.leagueId}-${fixtureInfo.fixture.startDate}`
            bettingMarketTrOpenHandler(key, fixtureInfo.fixtureId, !fixtureInfo.bettingMarketTrShow)
            setIsExpanded(false) // FaMinusCircle 클릭 시 상태 변경
          }}
        >
          <td>
            {isExpanded ? (
              <FaMinusCircle
                style={{ color: 'red' }}
                onClick={() => {
                  const key = `${fixtureInfo.fixture.leagueId}-${fixtureInfo.fixture.startDate}`
                  bettingMarketTrOpenHandler(key, fixtureInfo.fixtureId, !fixtureInfo.bettingMarketTrShow)
                  setIsExpanded(false) // FaMinusCircle 클릭 시 상태 변경
                }}
              />
            ) : (
              <FaPlusCircle
                style={{ color: 'green' }}
                onClick={() => {
                  const key = `${fixtureInfo.fixture.leagueId}-${fixtureInfo.fixture.startDate}`
                  bettingMarketTrOpenHandler(key, fixtureInfo.fixtureId, !fixtureInfo.bettingMarketTrShow)
                  setIsExpanded(true) // FaPlusCircle 클릭 시 상태 변경
                }}
              />
            )}
          </td>
          <td>{fixtureInfo.fixture.sportName}</td>
          <td>{fixtureInfo.fixtureId}</td>
          <td> {convertToKstSportsEventDateTime(fixtureInfo.fixture.startDate)}</td>
          <td style={{ width: '10%' }}>
            {fixtureInfo.fixture.homeImageUrl && (
              <img style={{ width: '25px', height: '20px' }} src={fixtureInfo.fixture.homeImageUrl} alt="" />
            )}
            <span>{fixtureInfo.fixture.homeName}</span>
            <br />
            <span style={{ color: 'red' }}>
              &nbsp;{fixtureInfo.fixture.fixtureHomeBetMoney.toString().replace(commonReg2, ',')} 원
            </span>
            <span style={{ color: 'red' }}>&nbsp;({fixtureInfo.fixture.fixtureHomeBetCount})</span>
          </td>
          <td style={{ width: '10%' }}>
            <span> VS </span>
            <br />
            <span style={{ color: 'red' }}>
              &nbsp;{fixtureInfo.fixture.fixtureDrawBetMoney.toString().replace(commonReg2, ',')} 원
            </span>
            <span style={{ color: 'red' }}>&nbsp;({fixtureInfo.fixture.fixtureDrawBetCount})</span>
          </td>
          <td style={{ width: '10%' }}>
            <span>{fixtureInfo.fixture.awayName}</span>
            {fixtureInfo.fixture.awayImageUrl && (
              <img style={{ width: '25px', height: '20px' }} src={fixtureInfo.fixture.awayImageUrl} alt="" />
            )}
            <br />
            <span style={{ color: 'red' }}>
              &nbsp;{fixtureInfo.fixture.fixtureAwayBetMoney.toString().replace(commonReg2, ',')} 원
            </span>
            <span style={{ color: 'red' }}>&nbsp;({fixtureInfo.fixture.fixtureAwayBetCount})</span>
          </td>

          <td>
            {fixtureInfo.liveScore ? (
              <span>
                {fixtureInfo.liveScore.homeScore} : {fixtureInfo.liveScore.awayScore}
              </span>
            ) : (
              <span>0 : 0</span>
            )}
          </td>
          <td style={{ color: 'red', fontWeight: '600' }}>{fixtureInfo.fixture.fixtureTotalBetCount}</td>
          <td>{fixtureInfo.fixture.fixtureTotalBetMoney.toString().replace(commonReg2, ',')}</td>
          <CustomTd fixtureStatus={fixtureInfo.fixture.fixtureStatus}>
            {FixtureStatus[fixtureInfo.fixture.fixtureStatus]}
          </CustomTd>
          <td>
            {fixtureInfo.fixture.fixtureVisible ? (
              <FiEye
                onClick={() => {
                  onClickFixtureVisibleHandler(fixtureInfo.fixtureId, !fixtureInfo.fixture.fixtureVisible)
                }}
              />
            ) : (
              <FiEyeOff
                onClick={() => {
                  onClickFixtureVisibleHandler(fixtureInfo.fixtureId, !fixtureInfo.fixture.fixtureVisible)
                }}
              />
            )}
          </td>

          <td>
            <CustomSwitch
              uncheckedIcon={false}
              checkedIcon={false}
              onChange={checked => {
                onClickFixtureIgnoreApiSignalSwitchHandler(fixtureInfo.fixtureId, checked)
              }}
              checked={fixtureInfo.fixture.ignoreApiSignal}
            />
          </td>
          <td>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', gap: '5px' }}>
              <SportsEventResultBtn
                size="sm"
                onClick={e => {
                  e.preventDefault()
                  marketPanelOpenHandler(
                    fixtureInfo.fixtureId,
                    fixtureInfo.fixture.sportId === 687890 ? 999 : 8,
                    fixtureInfo.fixture.fixtureStatusId,
                    fixtureInfo.fixture.fixtureStatus,
                  )
                }}
              >
                마켓관리
              </SportsEventResultBtn>
              {/* <SportsEventResultBtn
                size="sm"
                onClick={() => {
                  //   onResultHandler(sportsEvent.sportsEventId)
                }}
              >
                마켓추가
              </SportsEventResultBtn> */}
              {/* <SportsEventResultBtn
                size="sm"
                onClick={() => {
                  //   onResultHandler(sportsEvent.sportsEventId)
                }}
              >
                결과처리
              </SportsEventResultBtn> */}
            </div>
          </td>
        </tr>
        {fixtureInfo.bettingMarketTrShow && (
          <SportLiveFixtureTabeItemBettingMarket
            fixtureInfo={fixtureInfo}
            bettingMarketTrOpenHandler={bettingMarketTrOpenHandler}
            bettingInfoTrOpenHandler={bettingInfoTrOpenHandler}
          />
        )}
      </>
    )
  },
  areEqual,
)

SportLiveFixtureTableBodyItem.propTypes = {
  fixtureInfo: PropTypes.shape().isRequired,
  onClickFixtureVisibleHandler: PropTypes.func.isRequired,
  onClickFixtureIgnoreApiSignalSwitchHandler: PropTypes.func.isRequired,
  marketPanelOpenHandler: PropTypes.func.isRequired,
  bettingMarketTrOpenHandler: PropTypes.func.isRequired,
  bettingInfoTrOpenHandler: PropTypes.func.isRequired,
}

const getColor2 = fixtureStatus => {
  switch (fixtureStatus) {
    case 'IN_PROGRESS':
      return 'green'
    case 'FINISHED':
      return 'blue'
    case 'CANCELLED':
      return 'red'
    case 'POSTPONED':
      return 'red'
    case 'INTERRUPTED':
      return 'red'
    case 'ABANDONED':
      return 'red'
    case 'LOST_COVERAGE':
      return 'red'
    default:
      return '#000'
  }
}

const CustomTd = styled.td`
  color: ${props => getColor2(props.fixtureStatus)} !important;
`
const StyledTd = styled.td`
  background: rgb(209, 213, 219);

  ${props =>
    props.isHighlighted4 &&
    `
background: rgba(120, 179, 66, 0.2);
`}

  ${props =>
    props.isHighlighted3 &&
    `
background: rgba(120, 179, 66, 0.5);
`}

  ${props =>
    props.isHighlighted &&
    `
  background: rgb(252, 247, 164);
  `}

  ${props =>
    props.isHighlighted2 &&
    `
  background: rgb(252, 247, 164,0.2);
  `}
`

const SportLiveFixtureTabeItem = React.memo(
  ({
    tableItemKey,
    sportFixture,
    onClickFixtureVisibleHandler,
    onClickFixtureIgnoreApiSignalSwitchHandler,
    marketPanelOpenHandler,
    bettingMarketTrOpenHandler,
    bettingInfoTrOpenHandler,
  }) => {
    return (
      <table key={tableItemKey}>
        <thead>
          <tr style={{ background: '#3730a3' }}>
            <th colSpan={14}>
              <SportFixtureLeagueWrap>
                <span>
                  <img
                    style={{ width: '25px', height: 'auto' }}
                    src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${sportFixture.sportId}.png`}
                    alt={SportIdTypeEnums[sportFixture.sportId]}
                  />
                  [&nbsp;
                  {sportFixture.locationImageUrl && (
                    <img style={{ width: '25px', height: '20px' }} src={sportFixture.locationImageUrl} alt="" />
                  )}
                  <span>{sportFixture.locationName}</span>&nbsp;]
                </span>

                {sportFixture.leagueImageUrl && (
                  <img style={{ width: '25px', height: '20px' }} src={sportFixture.leagueImageUrl} alt="" />
                )}
                <span>{sportFixture.leagueName}</span>
              </SportFixtureLeagueWrap>
            </th>
          </tr>
          <tr style={{ background: '#D1D5Db' }}>
            <th>배팅</th>
            <th>종목</th>
            <th>경기 번호</th>
            <th>경기 날짜</th>
            <th colSpan={3}>경기</th>
            <th>경기 스코어</th>
            <th>총 배팅 수</th>
            <th>총 배팅금</th>
            <th>경기상태</th>
            <th>노출여부</th>
            <th>경기상태 고정</th>
            <th>기능</th>
          </tr>
        </thead>
        <tbody>
          {sportFixture.fixtures.map(fixtureInfo => {
            return (
              <SportLiveFixtureTableBodyItem
                key={`${fixtureInfo.fixtureId}-tableBodyItem`}
                fixtureInfo={fixtureInfo}
                onClickFixtureVisibleHandler={onClickFixtureVisibleHandler}
                onClickFixtureIgnoreApiSignalSwitchHandler={onClickFixtureIgnoreApiSignalSwitchHandler}
                marketPanelOpenHandler={marketPanelOpenHandler}
                bettingMarketTrOpenHandler={bettingMarketTrOpenHandler}
                bettingInfoTrOpenHandler={bettingInfoTrOpenHandler}
              />
            )
          })}
        </tbody>
      </table>
    )
  },
)

export default SportLiveFixtureTabeItem

SportLiveFixtureTabeItem.propTypes = {
  tableItemKey: PropTypes.string.isRequired,
  sportFixture: PropTypes.shape().isRequired,
  onClickFixtureVisibleHandler: PropTypes.func.isRequired,
  onClickFixtureIgnoreApiSignalSwitchHandler: PropTypes.func.isRequired,
  marketPanelOpenHandler: PropTypes.func.isRequired,
  bettingMarketTrOpenHandler: PropTypes.func.isRequired,
  bettingInfoTrOpenHandler: PropTypes.func.isRequired,
}

const SearchWrap = styled.div`
  width: 100%;
  border: 1px solid #222733;
  border-radius: 3px;
  padding: 8px 8px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
`

const SearchWrapItem = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  align-items: center;

  input {
    border: 1px solid #222733;
    height: 30px;
    line-height: 28px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 7px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }

  select {
    min-width: 70px;
    display: inline-block;
    height: 30px;
    line-height: 28px;
    padding: 0 7px;
    border: 1px solid #222733;
    vertical-align: middle;
    border-radius: 3px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }
`

const SearchButton = styled(Button)``

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportMenuWrap = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
`

const SportMenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 3px solid black;
  `};
`

const SportFixtureTableWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin-top: 15px;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  thead {
    tr {
      width: 100%;
      background: #f0f0f0;

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: 600;
        font-size: 12px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        ${props =>
          props.isHighlighted &&
          `
        background: yellow;
        `}
        padding: 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }

        svg {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`

const SportFixtureLeagueWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const SportsEventResultBtn = styled(Button)`
  background: #f59a0a;
  color: white;
  border: none;
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
