export const urlTypeEnums = {
  USE: '사용',
  NOUSE: '미사용',
  END: '종료',
}

export const UrlTypeEnumsSelectOptions = () => {
  return Object.entries(urlTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const UrlStatusEnumsRadioOptions = () => {
  return Object.entries(urlTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'urlStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'urlStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const domainTypeEnums = {
  true: '사용',
  false: '미사용',
}

export const DomainStatusEnumsRadioOptions = () => {
  return Object.entries(domainTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'isUsed',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'isUsed',
          label: value,
          radioValue: key,
        },
  )
}
