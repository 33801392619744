import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomTableWrap } from '../../TableElements'

const ReactTableDefaultBody = ({ page, headerGroups, getTableBodyProps, prepareRow, onClickRowHandler }) => (
  <tbody {...getTableBodyProps()}>
    {page.length === 0 ? (
      <NoTableContentTr>
        <td colSpan={headerGroups[0].headers.length}>최근 게시물이 없습니다.</td>
      </NoTableContentTr>
    ) : (
      <>
        {page.map(row => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              onClick={e => {
                if (onClickRowHandler !== null) {
                  // row 에 대한걸 넘겨도 될듯?
                  onClickRowHandler(row)
                }
              }}
            >
              {row.cells.map(cell => (
                <td className={`${cell.column.id}td`} {...cell.getCellProps()}>
                  <CustomTableWrap>{cell.render('Cell')}</CustomTableWrap>
                </td>
              ))}
            </tr>
          )
        })}
      </>
    )}
  </tbody>
)

ReactTableDefaultBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    }),
  ).isRequired,
  onClickRowHandler: PropTypes.func,
}

ReactTableDefaultBody.defaultProps = {
  onClickRowHandler: null,
}

const ReactTableBody = ({ page, headerGroups, getTableBodyProps, prepareRow, onClickRowHandler }) => (
  <Fragment>
    <ReactTableDefaultBody
      page={page}
      headerGroups={headerGroups}
      getTableBodyProps={getTableBodyProps}
      prepareRow={prepareRow}
      onClickRowHandler={onClickRowHandler}
    />
  </Fragment>
)

ReactTableBody.propTypes = {
  page: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getTableBodyProps: PropTypes.func.isRequired,
  prepareRow: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(
    PropTypes.shape({
      headers: PropTypes.arrayOf(PropTypes.shape()),
      getHeaderGroupProps: PropTypes.func,
      getFooterGroupProps: PropTypes.func,
    }),
  ).isRequired,
  onClickRowHandler: PropTypes.func,
}

ReactTableBody.defaultProps = {
  onClickRowHandler: null,
}

export default ReactTableBody

const NoTableContentTr = styled.tr`
  height: 100px;
  font-weight: bolder;
  font-size: 16px;
`
