import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import { Button } from '@/shared/components/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'

function FriendLinkForm() {
  const onSubmit = () => {
    console.log('')
  }
  const [data, setData] = useState(
    'http://localhost:3000/partner/history/5http://localhost:3000/partner/history/5http://localhost:3000/partner/history/5',
  )

  // const handleCopy = e => {
  //   e.preventDefault()
  //   e.clipboardData.setData('text/html', data)
  //   alert('링크가 복사되었습니다.')
  //   window.location.reload()
  // }
  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <FormContainer horizontal>
          <div style={{ marginTop: '-10px', marginBottom: '10px' }}>
            <p>
              * 하단 &nbsp;<span style={{ fontWeight: 'bolder', color: 'blue' }}>주소</span>를 통해서 가입을 하면
              자동으로 회원에게 매장코드가 발급됩니다.
            </p>
          </div>
          <FormGroup>
            <FormGroupLabel>가입 주소</FormGroupLabel>
            <FormGroupField>
              <Field name="friendLinkUrl" component="input" type="text" placeholder={data} disabled />
            </FormGroupField>
          </FormGroup>
          <FormButtonToolbar>
            <CopyToClipboard
              text={data}
              onCopy={() => {
                alert('추천인 링크가 복사되었습니다.')
              }}
            >
              <Button variant="primary" type="submit">
                링크 복사
              </Button>
            </CopyToClipboard>
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  )
}

export default FriendLinkForm
