import { ButtonToolbar } from '@/shared/components/Button'
import { FormContainer } from '@/shared/components/form/FormElements'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import { colorBackground, colorBlue, colorIcon, colorText, colorWhite } from '@/utils/palette'
import { Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Badge, Modal as BootstrapModal, Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { searchSportsMarket } from '../../../../utils/api/sportsManage/sportsInfo'
import { syncSportsMarket, updateSportsMarketInfo } from '../../../../utils/api/sport/sportIInfoApi'
import { convertToKstSportsEventDateTime } from '../../../../utils/dateTime'
import SportsMarketPointAddModal from './SportsMarketPointAddModal'
import Loading from '../../../../shared/components/Loading'

function SportsMarketModal({ sportsEventId, sportsMarketModalShow, onClickSportsMarketModalHnadler, sportsEventTime }) {
  const [content, setContent] = useState({})
  const [writeLoading, setWriteLoading] = useState(false)
  const [marketNewPoint, setMarketNewPoint] = useState([])
  const [newMarket, setNewMarket] = useState([])
  const fetchSearchSportsMarket = async () => {
    searchSportsMarket({
      sportsEventId,
    }).then(res => {
      setContent(res.data)
      setNewMarket(res.data.markets)
    })
  }

  useEffect(() => {
    if (!sportsEventId) return

    fetchSearchSportsMarket()
  }, [sportsEventId])

  const onChangeOtherOddByNewExchangeRate = (marketOddList, newExchangeRate, existExchangeRate) => {
    const resultList = marketOddList
      .filter(item => !Number.isNaN(item.oddValue))
      .map(item => {
        const trueOdds = (Number(item.oddValue) / Number(existExchangeRate)) * 100
        item.oddValue = Math.round((trueOdds - (trueOdds * (100 - Number(newExchangeRate))) / 100) * 100) / 100
        return item
      })

    return resultList
  }

  const onChangeNewExchangeRateByChangeOdd = newOddList => {
    const newOddValueList = newOddList.filter(item => !Number.isNaN(item.oddValue)).map(item => item.oddValue)
    let result = 100 / newOddValueList.reduce((acc, value) => acc + 100 / value, 0)
    result = Math.round(result * 1000) / 10

    return result
  }

  const onChangeOddHandler = (marketId, sportsMarketPointKey, sportsMarketOddKey, newOdd) => {
    const result = [...newMarket]
    const resultMarket = result.find(item => item.marketId === marketId)
    if (resultMarket) {
      const newMarketPoint = resultMarket.sportsMarketPoints.find(
        item => item.sportsMarketPointKey === sportsMarketPointKey,
      )

      if (newMarketPoint) {
        const newMarketOdd = newMarketPoint.odds.find(item => item.sportsMarketOddKey === sportsMarketOddKey)
        if (newMarketOdd && Number(newOdd)) {
          if (!newOdd || newOdd === null) {
            newMarketOdd.oddValue = '1'
          } else {
            newMarketOdd.oddValue = newOdd
          }
          const newExchangeRate = onChangeNewExchangeRateByChangeOdd(newMarketPoint.odds)
          newMarketPoint.exchangeRate = newExchangeRate
          setNewMarket(result)
        }
      }
    }
  }

  const onChangeExchangeRateHandler = (marketId, sportsMarketPointKey, newExchangeRate) => {
    const result = [...newMarket]
    const resultMarket = result.find(item => item.marketId === marketId)
    if (resultMarket) {
      const newMarketPoint = resultMarket.sportsMarketPoints.find(
        item => item.sportsMarketPointKey === sportsMarketPointKey,
      )

      if (newMarketPoint) {
        const existMarketOddList = newMarketPoint.odds
        if (existMarketOddList && Number(newExchangeRate)) {
          const existExchangeRate = newMarketPoint.exchangeRate
          newMarketPoint.exchangeRate = newExchangeRate
          newMarketPoint.odds = onChangeOtherOddByNewExchangeRate(
            existMarketOddList,
            newExchangeRate,
            existExchangeRate,
          )
          setNewMarket(result)
        }
      }
    }
  }

  const onChangeShowStatusHandler = (marketId, sportsMarketPointKey, showStatus) => {
    const result = [...newMarket]
    const resultMarket = result.find(item => item.marketId === marketId)
    if (resultMarket) {
      const newMarketPoint = resultMarket.sportsMarketPoints.find(
        item => item.sportsMarketPointKey === sportsMarketPointKey,
      )
      if (newMarketPoint) {
        newMarketPoint.showStatus = showStatus
        setNewMarket(result)
      }
    }
  }

  const onChangeIsAdminChangeHandler = (marketId, sportsMarketPointKey, isAdminChange) => {
    const result = [...newMarket]
    const resultMarket = result.find(item => item.marketId === marketId)
    if (resultMarket) {
      const newMarketPoint = resultMarket.sportsMarketPoints.find(
        item => item.sportsMarketPointKey === sportsMarketPointKey,
      )
      if (newMarketPoint) {
        newMarketPoint.isAdminChange = isAdminChange
        setNewMarket(result)
      }
    }
  }

  const [addMarketInfo, setAddMarketInfo] = useState({})

  const [sportsMarketPointAddModalShow, setSportsMarketPointAddShow] = useState(false)

  const onClickSportsMarketPointAddModalHnadler = () => {
    setSportsMarketPointAddShow(prev => !prev)
  }

  const onClickAddNewPointHandler = marketInfo => {
    setAddMarketInfo(marketInfo)
    setSportsMarketPointAddShow(true)
  }

  const getAddPointBouttonCompo = market => {
    switch (market?.marketName) {
      case '승패':
      case '승무패':
      case '핸디캡':
      case '언더오버':
        return (
          <CustomButton
            style={{ width: 'max-content', padding: '0 5px', color: '#fff' }}
            variant="warning"
            type="button"
            onClick={() => {
              onClickAddNewPointHandler(market)
            }}
          >
            배당 추가
          </CustomButton>
        )
      default:
        return <></>
    }
  }

  const onClickMarketSyncHandler = (marketId, marketName) => {
    if (writeLoading) return

    if (window.confirm(`${marketName} 배당을 최신화 하시겠습니까?\n배당고정은 해제됩니다.`)) {
      const body = {
        sportsEventId,
        marketId,
      }

      setWriteLoading(true)

      syncSportsMarket(body)
        .then(res => {
          setWriteLoading(false)
          fetchSearchSportsMarket()
          alert('최신화 되었습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          alert('최신화 실패했습니다.')
        })
    }
  }

  const onSubmit = (marketId, marketName) => {
    if (writeLoading) return
    if (window.confirm(`${marketName}를 수정하시겠습니까?`)) {
      const newMarketInfo = newMarket.find(item => item.marketId === marketId)

      const body = {
        sportsEventId,
        sportMarketPointList: newMarketInfo.sportsMarketPoints.map(item => ({
          sportsMarketPointKey: item.sportsMarketPointKey,
          showStatus: item.showStatus,
          exchangeRate: item.exchangeRate,
          isAdminChange: item.isAdminChange,
          odds: item.odds,
        })),
      }
      setWriteLoading(true)
      updateSportsMarketInfo(body)
        .then(res => {
          setWriteLoading(false)
          fetchSearchSportsMarket()
          alert('설정이 변경됐습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'SPORTS-1007') {
            alert(`${message}`)
          } else {
            alert('정보 수정이 실패했습니다. 잠시 후 다시 시도해주세요.')
          }
          fetchSearchSportsMarket()
        })
    }
  }

  return (
    <>
      {writeLoading && <Loading />}
      {sportsMarketPointAddModalShow && (
        <SportsMarketPointAddModal
          marketInfo={addMarketInfo}
          sportsMarketPointAddModalShow={sportsMarketPointAddModalShow}
          onClickSportsMarketPointAddModalHnadler={onClickSportsMarketPointAddModalHnadler}
          fetchSearchSportsMarket={fetchSearchSportsMarket}
        />
      )}
      <StyledModal show={sportsMarketModalShow} onHide={onClickSportsMarketModalHnadler} header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickSportsMarketModalHnadler}
          />
          <ModalTitle>
            {content?.leagueImage && <img src={content?.leagueImage} alt="" />}
            <span style={{ color: 'black', fontWeight: 600 }}>{content?.leagueName} -</span>
            {content?.ccImage && <img src={content?.ccImage} alt="" />}
            <span style={{ color: 'black', fontWeight: 600 }}>{content?.ccKr}</span>
            <span>경기 마켓 설정</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SportsMarketContentHeaderWrap>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>경기 번호</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>
                {content?.sportsEventId} - {content?.prematchId}
              </SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>경기</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>
                {content?.homeName} vs {content?.awayName}
              </SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>경기시간</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>
                {convertToKstSportsEventDateTime(sportsEventTime)}
              </SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
          </SportsMarketContentHeaderWrap>
          <SportsMarketContentBodyWrap>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { reset } }) => (
                <SportsMarketFormContainer horizontal onSubmit={handleSubmit} style={{ flexDirection: 'column' }}>
                  {newMarket.map(market => {
                    return (
                      <div style={{ marginBottom: '10px' }}>
                        <SportsMarketNameWrap>
                          {market.marketName}
                          {content?.statusId === 0 && (
                            <div style={{ display: 'flex', gap: '10px' }}>
                              <CustomButton
                                variant="outline-secondary"
                                style={{ background: 'green', color: 'white' }}
                                onClick={() => {
                                  onClickMarketSyncHandler(market.marketId, market.marketName)
                                }}
                              >
                                최신화
                              </CustomButton>
                              {getAddPointBouttonCompo(market)}
                              <CustomButton
                                onClick={() => {
                                  onSubmit(market.marketId, market.marketName)
                                }}
                              >
                                수정
                              </CustomButton>
                            </div>
                          )}
                        </SportsMarketNameWrap>
                        {market.sportsMarketPoints.map((point, idx) => {
                          const name = point.name2 ? point.name2 : point.name
                          const count = point?.odds?.length + 4
                          return (
                            <>
                              {idx === 0 && (
                                <SportsMarketOddWrap name={count}>
                                  <SportsMarketOddItemTitle>기준점 </SportsMarketOddItemTitle>
                                  {point.odds.map(odd => (
                                    <SportsMarketOddItemTitle>{odd.oddNameKr}</SportsMarketOddItemTitle>
                                  ))}
                                  <SportsMarketOddItemTitle>환수율(%)</SportsMarketOddItemTitle>
                                  <SportsMarketOddItemTitle>배당고정 </SportsMarketOddItemTitle>
                                  <SportsMarketOddItemTitle>노출여부 </SportsMarketOddItemTitle>
                                </SportsMarketOddWrap>
                              )}

                              <SportsMarketOddWrap name={count}>
                                <SportsMarketOddItem>
                                  {name || '-'}{' '}
                                  {(point.oddsStop || market.marketStop) && <StopBadge bg="custom">정지</StopBadge>}
                                </SportsMarketOddItem>
                                {point.odds.map(
                                  odd =>
                                    ({
                                      false: (
                                        <SportsMarketExchangeItem>
                                          <Field name="dd">
                                            {({ input, meta }) => (
                                              <>
                                                <CustomFormInputWrap>
                                                  <CustomInput
                                                    {...input}
                                                    type="text"
                                                    onChange={e => {
                                                      onChangeOddHandler(
                                                        market.marketId,
                                                        point.sportsMarketPointKey,
                                                        odd.sportsMarketOddKey,
                                                        e.target.value,
                                                      )
                                                    }}
                                                    value={odd.oddValue}
                                                  />
                                                  {meta.error && <ErrorText>{meta.error}</ErrorText>}
                                                </CustomFormInputWrap>
                                              </>
                                            )}
                                          </Field>
                                        </SportsMarketExchangeItem>
                                      ),
                                      true: <SportsMarketOddItem style={{ color: 'red' }}>-</SportsMarketOddItem>,
                                    }[!odd.oddValue]),
                                  // <SportsMarketOddItem>{odd.oddValue || '-'}</SportsMarketOddItem>
                                )}
                                {
                                  {
                                    false: (
                                      <SportsMarketExchangeItem>
                                        <Field name="dd">
                                          {({ input, meta }) => (
                                            <>
                                              <CustomFormInputWrap>
                                                <CustomInput
                                                  {...input}
                                                  style={{ color: 'blue' }}
                                                  type="text"
                                                  onChange={e => {
                                                    onChangeExchangeRateHandler(
                                                      market.marketId,
                                                      point.sportsMarketPointKey,
                                                      e.target.value,
                                                    )
                                                  }}
                                                  value={point.exchangeRate}
                                                />
                                                {meta.error && <ErrorText>{meta.error}</ErrorText>}
                                              </CustomFormInputWrap>
                                            </>
                                          )}
                                        </Field>
                                      </SportsMarketExchangeItem>
                                    ),
                                    true: <SportsMarketOddItem style={{ color: 'red' }}>-</SportsMarketOddItem>,
                                  }[!point.exchangeRate]
                                }
                                <SportsMarketOddItem>
                                  <SportsMarketShowCheckbox
                                    checked={point.isAdminChange}
                                    onChange={e => {
                                      onChangeIsAdminChangeHandler(
                                        market.marketId,
                                        point.sportsMarketPointKey,
                                        e.target.checked,
                                      )
                                    }}
                                  />
                                  &nbsp;배당고정 여부
                                </SportsMarketOddItem>
                                <SportsMarketOddItem>
                                  <SportsMarketShowCheckbox
                                    checked={point.showStatus}
                                    onChange={e => {
                                      onChangeShowStatusHandler(
                                        market.marketId,
                                        point.sportsMarketPointKey,
                                        e.target.checked,
                                      )
                                    }}
                                  />
                                  &nbsp;체크 시 노출
                                </SportsMarketOddItem>
                              </SportsMarketOddWrap>
                            </>
                          )
                        })}
                      </div>
                    )
                  })}
                </SportsMarketFormContainer>
              )}
            </Form>
          </SportsMarketContentBodyWrap>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default SportsMarketModal

SportsMarketModal.propTypes = {
  sportsEventId: PropTypes.number,
  sportsMarketModalShow: PropTypes.bool,
  onClickSportsMarketModalHnadler: PropTypes.func,
  sportsEventTime: PropTypes.number,
}

SportsMarketModal.defaultProps = {
  sportsEventId: null,
  sportsMarketModalShow: false,
  onClickSportsMarketModalHnadler: null,
  sportsEventTime: null,
}

export const CustomButton = styled(Button)`
  width: 50px;
  height: 30px;
  font-size: 15px;
  padding: 0px;
  padding-top: 3px;
`

export const CustomInput = styled.input`
  border: none !important;
  height: 22px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
`

export const CustomFormInputWrap = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
`

const ErrorText = styled.span`
  font-size: 10px;
  font-weight: 650;
  line-height: 13px;
  color: red;
  margin-bottom: -5px;
  display: block;
  // margin-top: -10px;
`

const SportsMarketFormContainer = styled(FormContainer)`
  flex-direction: column;
`

const SportsMarketShowCheckbox = styled(Checkbox)`
  width: 10px;
  height: 10px;
`

const SportsMarketContentHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
`

const SportsMarketContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #888;
`

const SportsMarketContentHeaderLabel = styled.span`
  width: 30%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  border-right: 2px solid #888;
`

const SportsMarketContentHeaderValue = styled.span`
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
`

const SportsMarketContentBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 20px;
`

const SportsMarketNameWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* 오른쪽 끝으로 정렬 */

  width: 100%;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
  color: white;

  background-color: #888;
  border: 1px solid black;
`

const SportsMarketOddWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${props => props.name && `repeat(${props.name}, minmax(0, 1fr))`};
  &:hover {
    cursor: pointer;
    border: 2px solid red;
  }
`

const SportsMarketOddItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  font-size: 14px;
`

const SportsMarketExchangeItem = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  color: blue;
  font-weight: 500;
  font-size: 14px;
`

const SportsMarketOddItemTitle = styled.div`
  width: 100%;
  border: 1px solid #888;
  text-align: center;
  background: #a6a6a6;
  color: white;
  font-size: 14px;
`

const StopBadge = styled(Badge)`
  width: 40px;
  height: 16px;
  background-color: #ff4861;
  font-weight: 500;
  border-radius: 7px;
  text-align: center;
  margin-left: 5px;
  span {
    width: 100%;
  }
`

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 1200px !important;
  }

  .modal-content {
    height: max-content;
    min-height: 800px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
