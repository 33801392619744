import { marginRight, paddingLeft } from '@/utils/directions'
import {
  colorAccent,
  colorAccentHover,
  colorDustyWhite,
  colorFieldsBorder,
  colorHover,
  colorIcon,
  colorText,
  colorWhite,
} from '@/utils/palette'
import styled from 'styled-components'

export const CheckboxCustom = styled.span`
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid ${props => props.color || colorIcon};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  background-color: ${props => props.disabled && colorDustyWhite};

  svg {
    transition: all 0.3s;
    opacity: 0;
    height: 16px;
    width: 16px;
    fill: ${props => (props.color ? colorWhite : colorAccent)};
  }
`

export const CheckboxLabel = styled.span`
  line-height: 18px;
  ${paddingLeft}: 28px;
  padding-top: 2px;
  color: ${colorText};
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s;
`

export const CheckboxInput = styled.input`
  display: none;
  &:checked + ${CheckboxCustom} {
    svg {
      opacity: 1;
    }
  }
`

export const CheckboxSvgWrap = styled.span``

export const CheckboxWrap = styled.label`
  display: flex;
  cursor: pointer;

  &:hover {
    ${CheckboxCustom} {
      border-color: ${colorAccent};
    }

    ${CheckboxLabel} {
      color: ${colorAccent};
    }
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: default;
    opacity: 0.4;

    ${CheckboxCustom} {
      transition: 0s;
      background-color: ${colorHover(props)};
      border-color: ${colorFieldsBorder(props)};

      svg {
        fill: ${colorDustyWhite};
      }
    }

    ${CheckboxLabel} {
      color: ${colorDustyWhite};
    }
  `}

  ${props =>
    props.styleType === 'colored' &&
    `

    ${CheckboxCustom} {
      border-color: ${colorAccent};
      background-color: ${colorAccent};

      svg {
        fill: ${colorWhite};
      }
    }

    ${
      props.disabled &&
      `

      ${CheckboxCustom} {
        border-color: ${colorAccent};
        background-color: ${colorAccent};
      }
    `
    }
  `}

  ${props =>
    props.styleType === 'colored-click' &&
    `

    ${CheckboxInput}:checked + ${CheckboxCustom} {
      border-color: ${colorAccent};
      background-color: ${colorAccent};

      svg {
        fill: ${colorWhite};
      }
    }

    ${
      props.disabled &&
      `

      ${CheckboxInput}:checked + ${CheckboxCustom} {
        background-color: ${colorHover(props)};
        border-color: ${colorFieldsBorder(props)};

        svg {
          fill: ${colorDustyWhite};
        }
      }
    `
    }
  `}

  ${props =>
    props.styleType === 'button' &&
    `
    background: ${colorAccent};
    min-width: 150px;
    color: ${colorWhite};
    height: 24px;
    border-radius: 4px;
    transition: all 0.3s;
    padding: 0 10px;
    width: 100%;
    justify-content: center;
    align-items: center;

    ${CheckboxLabel} {
      color: ${colorWhite};
    }

    ${CheckboxCustom} {
      display: none;
    }

    ${CheckboxSvgWrap} {
      ${marginRight(props)}: 4px;
      margin-top: auto;
      margin-bottom: auto;
      height: 16px;
      line-height: 1;

      svg {
        fill: ${colorWhite};
        width: 14px;
        height: 14px;
      }

      svg:first-of-type {
        display: none;
      }
    }

    ${CheckboxInput}:checked ~ ${CheckboxSvgWrap} {

      svg:first-of-type {
        display: block;
      }

      svg:last-of-type {
        display: none;
      }
    }

    ${CheckboxLabel} {
      padding: 0;
    }

    &:hover {
      background: ${colorAccentHover};

      ${CheckboxLabel} {
        color: ${colorWhite};
      }
    }

    ${
      props.disabled &&
      `
      opacity: 0.4;

      ${CheckboxLabel} {
        color: ${colorWhite};
      }
    `
    }
  `}
`

export const CustomCheckboxCustom = styled(CheckboxCustom)`
  /* 체크됐을 때 체크 색상 설정 */
  input:checked + & {
    background-color: blue;
  }
`
export const CustomCheckboxLabel = styled(CheckboxLabel)``
export const CustomCheckboxInput = styled(CheckboxInput)``
export const CustomCheckboxSvgWrap = styled(CheckboxSvgWrap)``
export const CustomCheckboxWrap = styled(CheckboxWrap)``
