import React, { useEffect, useState } from 'react'
import { Card, CardBody } from '@/shared/components/Card'
import styled from 'styled-components'
import SportManagementTabsV2 from '../SportManagementTabsV2'
import SportPage from './SportPage'
import LocationPage from './LocationPage'
import LeaguePage from './LeaguePage'
import TeamPage from './TeamPage'

const SportLocationManagePage = () => {
  const [locationId, setLocationId] = useState(null)
  const [locationNameKo, setLocationNameKo] = useState(null)
  const [leagueId, setLeagueId] = useState(null)
  const [leagueNameKo, setLeagueNameKo] = useState(null)
  const [leagueSportId, setLeagueSportId] = useState(6046)

  return (
    <Card>
      <CardBody>
        <SportManagementTabsV2 activeKey="7" />
        <SportLocationMangeBox>
          <div style={{ width: '35%', borderRight: 'solid 1px #000', marginRight: '10px' }}>
            <LocationPage setLocationId={setLocationId} setLocationNameKo={setLocationNameKo} />
          </div>
          <div style={{ width: '65%' }}>
            <LeaguePage
              locationId={locationId}
              locationNameKo={locationNameKo}
              setLeagueId={setLeagueId}
              setLeagueNameKo={setLeagueNameKo}
              setLeagueSportId={setLeagueSportId}
            />
          </div>
          {/* <SportPage /> */}
          {/* <LocationPage /> */}
        </SportLocationMangeBox>
        <SportLocationMangeBox>
          <div style={{ width: '35%', borderRight: 'solid 1px #000', marginRight: '10px' }}>
            <SportPage />
          </div>
          <div style={{ width: '65%' }}>
            <TeamPage
              leagueId={leagueId}
              leagueSportId={leagueSportId}
              locationNameKo={locationNameKo}
              leagueNameKo={leagueNameKo}
            />
          </div>
          {/* <SportPage /> */}
        </SportLocationMangeBox>
        {/* <SportLocationMangeBox2>
          <LocationPage />
          <LocationPage />
        </SportLocationMangeBox2> */}
      </CardBody>
    </Card>
  )
}

export default SportLocationManagePage

const SportLocationMangeBox = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  border: 1px solid black;
`

const SportLocationMangeBox2 = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  border: 1px solid black;
`
