import { Card, CardBody } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { deleteRestrictionIp, useSearchRestrictionIP } from '../../../utils/api/logManage/logApi'
import MemberManageMentTabs from '../../MemberManagement/MemberManageMentTabs'
import RestrictionIpListData from './RestrictionIpListData'
import { decodeAccessToken } from '../../../utils/token'
import IpRegistrationModal from './IpRegistrationModal'

const RestrictionIpList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const authority = decodeAccessToken()?.adminAuthority
  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchRestrictionIP } = useSearchRestrictionIP(bettingLogparams)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = (restrictionIpKey, restrictionIp) => {
    if (deleteLoading) return
    if (authority === 'NORMAL') return
    if (window.confirm(`${restrictionIp}를 IP차단에서 제거 하시겠습니까?"`)) {
      const body = {
        restrictionIpKey,
      }
      setDeleteLoading(true)
      deleteRestrictionIp(body)
        .then(res => {
          alert('제거가 완료됐습니다.')
          setDeleteLoading(false)
          fetchSearchRestrictionIP()
        })
        .catch(error => {
          alert('제거가 실패하였습니다.')
          setDeleteLoading(false)
          fetchSearchRestrictionIP()
        })
    }
  }

  const [bettingLogListData, setBettingLogListData] = useState(RestrictionIpListData(content, onDeleteHandler))
  useEffect(() => {
    setBettingLogListData(RestrictionIpListData(content, onDeleteHandler))
  }, [content])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 회원 아이디
  const [searchRestrictionIp, setSearchRestrictionIp] = useState('')

  const onSearchRestrictionIpHandler = e => {
    setSearchRestrictionIp(e.target.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      userId: !searchMemberId ? null : searchMemberId.trim(),
      restrictionIp: !searchRestrictionIp ? null : searchRestrictionIp,
      page: 0,
    }))
  }

  const [isRegistrationModalShow, setRegistrationModalShow] = useState(false)
  const onRegistrationHandler = () => {
    setRegistrationModalShow(prevState => !prevState)
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('7')

  return (
    <Card>
      <CardBody>
        <MemberManageMentTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>유저 ID/명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>차단 IP</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchRestrictionIp"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchRestrictionIp}
                  onChange={e => onSearchRestrictionIpHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'red', color: 'white' }}
              onClick={() => {
                onRegistrationHandler()
              }}
            >
              등록하기
            </Button>
            <IpRegistrationModal
              isRegistrationModalShow={isRegistrationModalShow}
              onRegistrationHandler={onRegistrationHandler}
              fetchSearchRestrictionIP={fetchSearchRestrictionIP}
            />
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default RestrictionIpList
