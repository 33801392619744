import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { Button } from 'react-bootstrap'
import { searchSportBookmakerList } from '../../../utils/api/sport/sportBookmakerApi'
import { searchSportList, updateSportIdStatus } from '../../../utils/api/sport/sportApi'
import SportModifyModal from './SportModifyModal'
import { decodeAccessToken } from '../../../utils/token'
import { SportIdTypeEnums } from '../../../enums/SystemManagement/sportEnums'

const SportPage = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [bookmakerList, setBookmakerList] = useState([])
  const [sportEventList, setSportEventList] = useState([])

  const [sportInfo, setSportInfo] = useState({})
  const [sportModifyModalShow, setSportModifyModalShow] = useState(false)
  const onCloseSportModifyModalShowHandler = () => {
    setSportModifyModalShow(false)
  }

  // 북메이커 리스트 조회
  const fetchSearchSportBookmakerList = () => {
    searchSportBookmakerList().then(res => {
      setBookmakerList(res.data.bookmakerList)
    })
  }

  // 종목 리스트 조회
  const fetchSearchSportList = () => {
    searchSportList().then(res => {
      setSportEventList(res.data.sportEventList)
    })
  }

  useEffect(() => {
    fetchSearchSportBookmakerList()
    fetchSearchSportList()
  }, [])

  const [changeLoading, setChangeLoading] = useState(false)
  const onEventHideAndShowHandler = (sportId, visible) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return
    let confirmationMessage

    if (visible) {
      confirmationMessage = `${SportIdTypeEnums[sportId]}종목을 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${SportIdTypeEnums[sportId]}종목을 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        sportId,
        visible,
      }
      setChangeLoading(true)
      updateSportIdStatus(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportList()
        })
    }
  }

  return (
    <>
      <SportPageBox>
        <HeaderBox>스포츠 종목 관리</HeaderBox>
        <TableWrap>
          <table>
            <thead>
              <tr>
                <th>종목 코드</th>
                <th>종목명</th>
                <th>북메이커</th>
                <th>노출 여부</th>
                <th>정렬 순서</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              {sportEventList.map(sport => {
                return (
                  <tr>
                    <td>{sport.sportId}</td>
                    <td>
                      <img
                        style={{ width: '20px', marginRight: '5px' }}
                        src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${sport.sportId}.png`}
                        alt={SportIdTypeEnums[sport.sportId]}
                      />
                      {sport.sportName}
                    </td>
                    <td>{sport.bookmakerName}</td>
                    <td>
                      {
                        {
                          true: (
                            <FiEye
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                onEventHideAndShowHandler(sport.sportId, false)
                              }}
                            />
                          ),
                          false: (
                            <FiEyeOff
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                onEventHideAndShowHandler(sport.sportId, true)
                              }}
                            />
                          ),
                        }[sport.visible]
                      }
                    </td>
                    <td>{sport.sportSortNum}</td>
                    <td>
                      <CustomButton
                        variant="outline-secondary"
                        style={{ background: '#2196f3', color: '#fff' }}
                        onClick={() => {
                          setSportInfo(sport)
                          setSportModifyModalShow(true)
                        }}
                      >
                        수정
                      </CustomButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </TableWrap>
      </SportPageBox>
      {sportModifyModalShow && (
        <SportModifyModal
          sportInfo={sportInfo}
          bookmakerList={bookmakerList}
          sportModifyModalShow={sportModifyModalShow}
          onCloseSportModifyModalShowHandler={onCloseSportModifyModalShowHandler}
          fetchSearchSportList={fetchSearchSportList}
        />
      )}
    </>
  )
}

export default SportPage

const SportPageBox = styled.div`
  width: 100%;
  padding: 5px;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 5px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`

const CustomButton = styled(Button)`
  width: 100%;
  font-size: 12px;
  padding: 5px;
`
