import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import { EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import { convertFromServer } from '../../../utils/editor'
import './Note.css'

function NoteInfoForm({ fetchSearchNoteList, onClickHandler, noteTitle, sendContent, userId }) {
  const [content, setContent] = useState(EditorState.createEmpty())

  useEffect(() => {
    setContent(convertFromServer(sendContent))
  }, [sendContent])

  const onSubmit = e => {
    onClickHandler()
    fetchSearchNoteList()
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>유저ID</FormGroupLabel>
            <FormGroupField>
              <Field name="userId" component="input" type="text" defaultValue={userId} disabled />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>쪽지 제목</FormGroupLabel>
            <FormGroupField>
              <Field name="title" component="input" type="text" defaultValue={noteTitle} disabled />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>쪽지 내용</FormGroupLabel>
            <Editor
              editorState={content}
              wrapperClassName="wrapper"
              editorClassName="noteEditor"
              toolbarClassName="noteToolbar"
              readOnly
            />
          </FormGroup>
          <FormButtonToolbar>
            <Button variant="primary" type="submit">
              확인
            </Button>
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  )
}

export default NoteInfoForm

NoteInfoForm.propTypes = {
  fetchSearchNoteList: PropTypes.func.isRequired,
  noteTitle: PropTypes.string.isRequired,
  sendContent: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}
