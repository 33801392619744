import PropTypes from 'prop-types'
import React from 'react'
import MemberHistoryRollingPointDeposit from './MemberHistoryRollingPointDeposit'
import MemberHistoryPointExchange from './MemberHistoryPointExchange'
import MemberHistoryLosingPointDeposit from './MemberHistoryLosingPointDeposit'

const MemberHistoryPoint = ({ memberId }) => {
  return (
    <>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>롤링 포인트 입금 내역</h3>
      <MemberHistoryRollingPointDeposit memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>루징 포인트 입금 내역(파트너용)</h3>
      <MemberHistoryLosingPointDeposit memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>포인트 전환 내역</h3>
      <MemberHistoryPointExchange memberId={memberId} />
    </>
  )
}

export default MemberHistoryPoint

MemberHistoryPoint.propTypes = {
  memberId: PropTypes.string.isRequired,
}
