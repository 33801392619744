import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../../../shared/components/Loading'
import { UserAuthorityEnums } from '../../../../../enums/MemberManage/MemberInfoEnums'
import { decodeAccessToken } from '../../../../../utils/token'
import {
  searchMemberRollingPerInfo,
  registerMemberRollingPerInfo,
  updateMemberRollingStatus,
} from '../../../../../utils/api/memberManage/memberManageApi'

function RollingPerModalForm({ memberId, onModalShowHandler, fetchSearchMember }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [infoLoading, setInfoLoading] = useState(false)

  const [rollingPerInfo, setRollingPerInfo] = useState({})
  const [newSportsSingleLiveRolling, setNewSportsSingleLiveRolling] = useState(0)
  const [newSportsTwoRolling, setNewSportsTwoRolling] = useState(0)
  const [newSportsMultiRolling, setNewSportsMultiRolling] = useState(0)
  const [newMiniGameRolling, setNewMiniGameRolling] = useState(0)
  const [newVirtualGameRolling, setNewVirtualGameRolling] = useState(0)
  const [newCasinoRolling, setNewCasinoRolling] = useState(0)
  const [newSlotRolling, setNewSlotRolling] = useState(0)
  const [newHoldemRolling, setNewHoldemRolling] = useState(0)
  const [firstSportsSingleLiveRolling, setFirstSportsSingleLiveRolling] = useState(0)
  const [firstSportsTwoRolling, setFirstSportsTwoRolling] = useState(0)
  const [firstSportsMultiRolling, setFirstSportsMultiRolling] = useState(0)
  const [firstMiniGameRolling, setFirstMiniGameRolling] = useState(0)
  const [firstVirtualGameRolling, setFirstVirtualGameRolling] = useState(0)
  const [firstCasinoRolling, setFirstCasinoRolling] = useState(0)
  const [firstSlotRolling, setFirstSlotRolling] = useState(0)
  const [firstHoldemRolling, setFirstHoldemRolling] = useState(0)
  const [everySportsSingleLiveRolling, setEverySportsSingleLiveRolling] = useState(0)
  const [everySportsTwoRolling, setEverySportsTwoRolling] = useState(0)
  const [everySportsMultiRolling, setEverySportsMultiRolling] = useState(0)
  const [everyMiniGameRolling, setEveryMiniGameRolling] = useState(0)
  const [everyVirtualGameRolling, setEveryVirtualGameRolling] = useState(0)
  const [everyCasinoRolling, setEveryCasinoRolling] = useState(0)
  const [everySlotRolling, setEverySlotRolling] = useState(0)
  const [everyHoldemRolling, setEveryHoldemRolling] = useState(0)
  const [sportsSingleLiveRollingNotBonus, setSportsSingleLiveRollingNotBonus] = useState(0)
  const [sportsTwoRollingNotBonus, setSportsTwoRollingNotBonus] = useState(0)
  const [sportsMultiRollingNotBonus, setSportsMultiRollingNotBonus] = useState(0)
  const [miniGameRollingNotBonus, setMiniGameRollingNotBonus] = useState(0)
  const [virtualGameRollingNotBonus, setVirtualGameRollingNotBonus] = useState(0)
  const [casinoRollingNotBonus, setCasinoRollingNotBonus] = useState(0)
  const [slotRollingNotBonus, setSlotRollingNotBonus] = useState(0)
  const [holdemRollingNotBonus, setHoldemRollingNotBonus] = useState(0)

  const getMemberInfo = async () => {
    await searchMemberRollingPerInfo({
      memberId,
    }).then(res => {
      setNewSportsSingleLiveRolling(res.data.newSportsSingleLiveRolling)
      setNewSportsTwoRolling(res.data.newSportsTwoRolling)
      setNewSportsMultiRolling(res.data.newSportsMultiRolling)
      setNewMiniGameRolling(res.data.newMiniGameRolling)
      setNewVirtualGameRolling(res.data.newVirtualGameRolling)
      setNewCasinoRolling(res.data.newCasinoRolling)
      setNewSlotRolling(res.data.newSlotRolling)
      setNewHoldemRolling(res.data.newHoldemRolling)
      setFirstSportsSingleLiveRolling(res.data.firstSportsSingleLiveRolling)
      setFirstSportsTwoRolling(res.data.firstSportsTwoRolling)
      setFirstSportsMultiRolling(res.data.firstSportsMultiRolling)
      setFirstMiniGameRolling(res.data.firstMiniGameRolling)
      setFirstVirtualGameRolling(res.data.firstVirtualGameRolling)
      setFirstCasinoRolling(res.data.firstCasinoRolling)
      setFirstSlotRolling(res.data.firstSlotRolling)
      setFirstHoldemRolling(res.data.firstHoldemRolling)
      setEverySportsSingleLiveRolling(res.data.everySportsSingleLiveRolling)
      setEverySportsTwoRolling(res.data.everySportsTwoRolling)
      setEverySportsMultiRolling(res.data.everySportsMultiRolling)
      setEveryMiniGameRolling(res.data.everyMiniGameRolling)
      setEveryVirtualGameRolling(res.data.everyVirtualGameRolling)
      setEveryCasinoRolling(res.data.everyCasinoRolling)
      setEverySlotRolling(res.data.everySlotRolling)
      setEveryHoldemRolling(res.data.everyHoldemRolling)
      setSportsSingleLiveRollingNotBonus(res.data.sportsSingleLiveRollingNotBonus)
      setSportsTwoRollingNotBonus(res.data.sportsTwoRollingNotBonus)
      setSportsMultiRollingNotBonus(res.data.sportsMultiRollingNotBonus)
      setMiniGameRollingNotBonus(res.data.miniGameRollingNotBonus)
      setVirtualGameRollingNotBonus(res.data.virtualGameRollingNotBonus)
      setCasinoRollingNotBonus(res.data.casinoRollingNotBonus)
      setSlotRollingNotBonus(res.data.slotRollingNotBonus)
      setHoldemRollingNotBonus(res.data.holdemRollingNotBonus)
    })
  }

  useEffect(() => {
    if (memberId === null) return
    getMemberInfo()
  }, [memberId])

  const onSubmit = e => {
    if (infoLoading) return
    if (!window.confirm(`[${memberId}] 유저의 롤링% 정보(출금용)를 수정하시겠습니까?`)) {
      return
    }
    const body = {
      memberId,
      newSportsSingleLiveRolling,
      newSportsTwoRolling,
      newSportsMultiRolling,
      newMiniGameRolling,
      newVirtualGameRolling,
      newCasinoRolling,
      newSlotRolling,
      newHoldemRolling,
      firstSportsSingleLiveRolling,
      firstSportsTwoRolling,
      firstSportsMultiRolling,
      firstMiniGameRolling,
      firstVirtualGameRolling,
      firstCasinoRolling,
      firstSlotRolling,
      firstHoldemRolling,
      everySportsSingleLiveRolling,
      everySportsTwoRolling,
      everySportsMultiRolling,
      everyMiniGameRolling,
      everyVirtualGameRolling,
      everyCasinoRolling,
      everySlotRolling,
      everyHoldemRolling,
      sportsSingleLiveRollingNotBonus,
      sportsTwoRollingNotBonus,
      sportsMultiRollingNotBonus,
      miniGameRollingNotBonus,
      virtualGameRollingNotBonus,
      casinoRollingNotBonus,
      slotRollingNotBonus,
      holdemRollingNotBonus,
    }

    setInfoLoading(true)

    registerMemberRollingPerInfo(body)
      .then(res => {
        setInfoLoading(false)
        onModalShowHandler()
        alert('유저의 롤링비율% 정보가 수정되었습니다.')
        fetchSearchMember()
      })
      .catch(error => {
        setInfoLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'CUS-1006') {
          alert(`${message}`)
        } else {
          alert('유저의 롤링비율% 수정이 실패했습니다.')
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        memberId,
        newSportsSingleLiveRolling,
        newSportsTwoRolling,
        newSportsMultiRolling,
        newMiniGameRolling,
        newVirtualGameRolling,
        newCasinoRolling,
        newSlotRolling,
        newHoldemRolling,
        firstSportsSingleLiveRolling,
        firstSportsTwoRolling,
        firstSportsMultiRolling,
        firstMiniGameRolling,
        firstVirtualGameRolling,
        firstCasinoRolling,
        firstSlotRolling,
        firstHoldemRolling,
        everySportsSingleLiveRolling,
        everySportsTwoRolling,
        everySportsMultiRolling,
        everyMiniGameRolling,
        everyVirtualGameRolling,
        everyCasinoRolling,
        everySlotRolling,
        everyHoldemRolling,
        sportsSingleLiveRollingNotBonus,
        sportsTwoRollingNotBonus,
        sportsMultiRollingNotBonus,
        miniGameRollingNotBonus,
        virtualGameRollingNotBonus,
        casinoRollingNotBonus,
        slotRollingNotBonus,
        holdemRollingNotBonus,
      }}
    >
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          {infoLoading ? (
            <Loading />
          ) : (
            <>
              <FormGroup>
                <CustomFormGroupLabel>유저ID(명)</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <SearchCardTitleWrap style={{ width: '100%', marginTop: '-15px' }}>
                <p style={{ color: 'red', fontWeight: '500' }}>
                  순서대로 신규첫충, 매일첫충/돌발, 매일매충, 보너스 없음
                </p>
                <p style={{ color: 'red', fontWeight: '500' }}>값을 0으로 설정 시, 공통 롤링사항으로 적용</p>
              </SearchCardTitleWrap>
              <FormGroup>
                <CustomFormGroupLabel>스포츠 단폴/실시간 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newSportsSingleLiveRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewSportsSingleLiveRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstSportsSingleLiveRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstSportsSingleLiveRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everySportsSingleLiveRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEverySportsSingleLiveRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="sportsSingleLiveRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setSportsSingleLiveRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <FormGroup>
                <CustomFormGroupLabel>스포츠 2폴 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newSportsTwoRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewSportsTwoRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstSportsTwoRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstSportsTwoRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everySportsTwoRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEverySportsTwoRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="sportsTwoRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setSportsTwoRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <FormGroup>
                <CustomFormGroupLabel>스포츠 3폴이상 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newSportsMultiRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewSportsMultiRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstSportsMultiRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstSportsMultiRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everySportsMultiRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEverySportsMultiRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="sportsMultiRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setSportsMultiRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <FormGroup>
                <CustomFormGroupLabel>미니게임 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newMiniGameRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewMiniGameRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstMiniGameRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstMiniGameRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everyMiniGameRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEveryMiniGameRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="miniGameRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setMiniGameRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <FormGroup>
                <CustomFormGroupLabel>카지노 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newCasinoRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewCasinoRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstCasinoRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstCasinoRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everyCasinoRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEveryCasinoRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="casinoRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setCasinoRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <FormGroup>
                <CustomFormGroupLabel>슬롯 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newSlotRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewSlotRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstSlotRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstSlotRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everySlotRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEverySlotRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="slotRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setSlotRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <FormGroup>
                <CustomFormGroupLabel>홀덤 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newHoldemRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewHoldemRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstHoldemRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstHoldemRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everyHoldemRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEveryHoldemRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="holdemRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setHoldemRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>
              <FormGroup>
                <CustomFormGroupLabel>가상게임 롤링%</CustomFormGroupLabel>
                <CustomFormGroupField>
                  <Field name="newVirtualGameRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewVirtualGameRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="firstVirtualGameRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setFirstVirtualGameRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="everyVirtualGameRolling">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setEveryVirtualGameRolling(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="virtualGameRollingNotBonus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setVirtualGameRollingNotBonus(e.target.value)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </CustomFormGroupField>
              </FormGroup>

              {
                {
                  SUPER: (
                    <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                      <CustomButton variant="primary" type="submit">
                        변경하기
                      </CustomButton>
                    </FormButtonToolbar>
                  ),
                }[authority]
              }
            </>
          )}
        </FormContainer>
      )}
    </Form>
  )
}

export default RollingPerModalForm

RollingPerModalForm.propTypes = {
  fetchSearchMember: PropTypes.func.isRequired,
  onModalShowHandler: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`

const CustomFormGroupLabel = styled(FormGroupLabel)`
  width: 220px !important;
`

const CustomFormGroupField = styled(FormGroupField)`
  width: 400px !important;
  input {
    border: solid 1px black;
  }
`
