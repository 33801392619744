import { Card, CardBody } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import showResults from '../../../utils/showResults'
import SystemManagementTabs from '../SystemManagementTabs'
import DefaultBettingMangeMent from './DefaultBettingMangeMent'
import IpMangeMent from './IpMangeMent'
import './SiteManage.css'
import SyetemMangeMent from './SyetemMangeMent'
import EventMangeMent from './EventManagement'

const SiteManageList = () => {
  const [activeKey, setActiveKey] = useState('1')
  const { edgeNames } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNames: headerInfo.edgeNames,
    }
  })
  const [edgeName, setEdgeName] = useState(edgeNames[0])
  useEffect(() => {
    if (edgeName) return
    setEdgeName(edgeNames[0])
  }, [edgeNames])

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <SystemManagementTabs activeKey={activeKey} />
          <EdgeSearchButton
            isAll={false}
            edgeName={edgeName}
            onClickHandler={e => {
              setEdgeName(e.target.id)
            }}
          />
          <Row style={{ borderBottom: 'solid 1px black' }}>
            <Col md={6} lg={6} xl={6} style={{ borderRight: 'solid 1px black' }}>
              <Card>
                <CardBody>
                  <h3>시스템 설정 </h3>
                  <SyetemMangeMent edgeName={edgeName} onSubmit={showResults} />
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <h3 style={{ marginBottom: '10px' }}>이벤트 설정 </h3>
                  <EventMangeMent onSubmit={showResults} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={6} xl={6} style={{ borderRight: 'solid 1px black' }}>
              <Card>
                <CardBody>
                  <h3 style={{ marginBottom: '10px' }}>회원가입시 기본값 설정 </h3>
                  <DefaultBettingMangeMent edgeName={edgeName} onSubmit={showResults} />
                </CardBody>
              </Card>
            </Col>
            <Col md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <h3>관리자 접속IP 설정 </h3>
                  <IpMangeMent edgeName={edgeName} onSubmit={showResults} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col md={6} lg={6} xl={6} style={{ borderRight: 'solid 1px black' }}>
              <Card>
                <CardBody>
                  <h3>초기화 설정이다다다다다다다다다 </h3>
                  <ResetManage edgeName={edgeName} onSubmit={showResults} />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </CardBody>
      </Card>
    </Col>
  )
}
export default SiteManageList

// region STYLES

const HeaderWrap = styled.div`
  display: flex;

  & > div:first-child {
    margin-right: auto;
  }
`

// endregion
