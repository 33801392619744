export const IpRestrictStatusEnums = {
  USE: '제한 O',
  NOUSE: '제한 X',
}

export const IpRestrictStatusEnumsSelectOptions = () => {
  return Object.entries(IpRestrictStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const serviceStatusEnums = {
  NORMAL: '정상운영',
  INSPECTION: '긴급점검',
}

export const ServiceStatusEnumsSelectOptions = () => {
  return Object.entries(serviceStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const payBackSettlementStatusEnums = {
  COMPLETE: '정산 완료',
  INCOMPLETE: '정산 미완료',
}

export const PayBackSettlementStatusEnumsSelectOptions = () => {
  return Object.entries(payBackSettlementStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

/**
 * 추천인 정산
 */
export const friendCompSettlementStatusEnums = {
  COMPLETE: '정산 완료',
  INCOMPLETE: '정산 미완료',
}

export const FriendCompSettlementStatusEnumsSelectOptions = () => {
  return Object.entries(friendCompSettlementStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const eventStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const eventStatusEnumsSelectOptions = () => {
  return Object.entries(eventStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const accountNumStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const accountNumStatusEnumsSelectOptions = () => {
  return Object.entries(accountNumStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const attendanceEventStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const AttendanceEventEnumsRaidoOptions = () => {
  return Object.entries(attendanceEventStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'attendanceEventStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'attendanceEventStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const paybackEventStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const PayBackEventEnumsRaidoOptions = () => {
  return Object.entries(paybackEventStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'payBackEventStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'payBackEventStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const FriendCompEventStatusEnums = {
  EVERY: '실시간',
  SELECTED_DAY: '기간 선정',
}

export const FriendCompEnumsRaidoOptions = () => {
  return Object.entries(FriendCompEventStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'compEventStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'compEventStatus',
          label: value,
          radioValue: key,
        },
  )
}
