import React, { useMemo, useState } from 'react'
import { convertToKstDate } from '../../../utils/dateTime'
import { couponTypeEnums } from '../../../enums/Operation/couponEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const CouponCreateListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '쿠폰 번호',
      accessor: 'couponKey',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.couponKey.substring(0, 8)
      },
    },
    {
      Header: '쿠폰명',
      accessor: 'couponTitle',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '쿠폰 타입',
      accessor: 'couponType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return couponTypeEnums[original.couponType]
      },
    },
    {
      Header: '금액(원)/비율(%)',
      accessor: 'couponPrice',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.couponPrice?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '생성일',
      accessor: 'createDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{convertToKstDate(original.createDate)}</>
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default CouponCreateListData
