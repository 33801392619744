import { useEffect, useState } from 'react'
import axios from '../base/axios'

export async function postUserRegistrationExcel(body) {
  const response = await axios({
    url: '/tyson/v1/excel',
    method: 'POST',
    data: body,
  }).then(res => {
    return res.data
  })
  return response
}
