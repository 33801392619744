import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchSlotCityBonusCallHistory } from '../utils/api/casino/slotCityApi'

export const fetchSlotCityBonusCallHistory = createAsyncThunk(
  'casinoInfo/fetchSlotCityBonusCallHistory',
  async params => {
    const res = await searchSlotCityBonusCallHistory(params)
    return res.data
  },
)

const casinoInfoSlice = createSlice({
  name: 'casinoInfo',
  initialState: {
    slotCityCallMinAmount: 0,
    slotCityOnlineGameList: [],
    slotCityCallHistoryLoading: false,
    slotCityCallHistoryParams: {
      userId: null,
      gameName: null,
      callStatus: null,
      startTime: null,
      endTime: null,
      page: 0,
      size: 10,
    },
    slotCityCallHistoryTotalElement: 0,
    slotCityCallHistoryContent: [],
  },
  reducers: {
    setSlotCityCallMinAmount: (state, action) => {
      state.slotCityCallMinAmount = action.payload
    },

    setSlotCityOnlineGameList: (state, action) => {
      state.slotCityOnlineGameList = action.payload
    },
    setSlotCityCallHistory: (state, action) => {
      const { totalElement, content } = action.payload

      state.slotCityCallHistoryTotalElement = totalElement
      state.slotCityCallHistoryContent = content
    },
    setPage: (state, action) => {
      state.slotCityCallHistoryParams = {
        ...state.slotCityCallHistoryParams,
        page: action.payload,
      }
    },
    setSearchParams: (state, action) => {
      const { userId, gameName, callStatus, startTime, endTime } = action.payload

      state.slotCityCallHistoryParams = {
        ...state.slotCityCallHistoryParams,
        userId,
        gameName,
        callStatus,
        startTime,
        endTime,
        page: 0,
        size: 10,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSlotCityBonusCallHistory.pending, (state, action) => {
      state.slotCityCallHistoryLoading = true
    })

    builder.addCase(fetchSlotCityBonusCallHistory.fulfilled, (state, action) => {
      const { totalElement, content } = action.payload

      state.slotCityCallHistoryTotalElement = totalElement
      state.slotCityCallHistoryContent = content
      state.slotCityCallHistoryLoading = false
    })
  },
})

export const { setSlotCityCallMinAmount, setSlotCityOnlineGameList, setSlotCityCallHistory, setPage, setSearchParams } =
  casinoInfoSlice.actions

export default casinoInfoSlice.reducer
