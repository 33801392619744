import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const DepositDeleteRadio = ({ rowInfo, setCheckRadio, checkRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        setCheckRadio(prev => [...prev, rowInfo.moneyDepositKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== rowInfo.moneyDepositKey))
        break
      default:
        break
    }
  }

  return (
    <div style={{ cursor: 'pointer' }}>
      <CustomInput
        style={{ width: '100%', cursor: 'pointer' }}
        checked={checkRadio.find(e => e === rowInfo.moneyDepositKey)}
        type="checkbox"
        name="chech"
        onChange={e => approveHandler(e)}
      />
    </div>
  )
}
export default DepositDeleteRadio

DepositDeleteRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  rowInfo: PropTypes.shape({
    moneyDepositKey: PropTypes.string,
  }).isRequired,
}

DepositDeleteRadio.defaultProps = {
  checkRadio: [],
}

const CustomInput = styled.input`
  width: 15px;
  height: 15px;
`
