import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const CasinoManagementTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/management/system/gamecompany')
                }}
              >
                게임사 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="7"
                onClick={e => {
                  history.push('/management/system/callmanage/gold-link')
                }}
              >
                골드링크 슬롯 콜 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="8"
                onClick={e => {
                  history.push('/management/system/callmanage/slot-city')
                }}
              >
                슬롯시티 콜 관리
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default CasinoManagementTabs

CasinoManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
