import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  bannerStatusEnumsSelectOptions,
  bannerTargetEnumsSelectOptions,
} from '../../../enums/SystemManagement/bannnerEnums'
import SystemManagementTabs from '../SystemManagementTabs'
import BannerCreateModal from './BannerCreateModal'
import BannerListData from './BannerListData'
import BannerModifyModal from './BannerModifyModal'
import { adminSearchBannerList } from '../../../utils/api/systemManage/bannerManageApi'

const BannerList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const [bannerListparams, setBannerListParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBannerListParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchBannerManage = async () => {
    await adminSearchBannerList(bannerListparams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchBannerManage()
  }, [bannerListparams])

  const [bannerListData, setBannerListData] = useState(BannerListData(content))
  useEffect(() => {
    setBannerListData(BannerListData(content))
  }, [content])

  const [rows, setData] = useState(bannerListData.tableRowsData)
  useEffect(() => {
    setData(bannerListData.tableRowsData)
  }, [bannerListData])

  const [bannerInfo, setBannerInfo] = useState({})

  const [isBannerModifyModalShow, setIsBannerModifyModalShow] = useState(false)

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setBannerInfo(rowValue.original)
    setIsBannerModifyModalShow(true)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const [activeKey, setActiveKey] = useState('3')

  // 검색 - 은행명
  const [searchBannerName, setSearchBannerName] = useState('')
  // 검색 - 은행 상태
  const [searchBannerStatus, setSearchBannerStatus] = useState('')
  const [searchBannerTarget, setSearchBannerTarget] = useState('')

  const [searchBannerStatusOptionValue, setSearchBannerStatusOptionValue] = useState(null)
  const searchBannerStatusOption = bannerStatusEnumsSelectOptions()
  const onSelectStatusOptionHandler = option => {
    if (option == null) {
      setSearchBannerStatusOptionValue(null)
      setSearchBannerStatus('')
      return
    }
    setSearchBannerStatusOptionValue(option)
    setSearchBannerStatus(option.value)
  }

  const [searchBannerTargetOptionValue, setSearchBannerTargetOptionValue] = useState(null)
  const searchBannerTargetOption = bannerTargetEnumsSelectOptions()
  const onSelectTargetOptionHandler = option => {
    if (option == null) {
      setSearchBannerTargetOptionValue(null)
      setSearchBannerTarget('')
      return
    }
    setSearchBannerTargetOptionValue(option)
    setSearchBannerTarget(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBannerListParams(prev => ({
      ...prev,
      page: 0,
      bannerStatus: !searchBannerStatus ? null : searchBannerStatus.trim(),
      bannerTarget: !searchBannerTarget ? null : searchBannerTarget.trim(),
    }))
  }

  // 추가하기 버튼
  const [isBannerCreateModalShow, setIsBannerCreateModalShow] = useState(false)

  const onCreateClickHandler = () => {
    setIsBannerCreateModalShow(true)
  }

  return (
    <Card>
      <CardBody>
        <SystemManagementTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 배너의 설정을 수정할 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <input type="text" style={{ display: 'none' }} />
            <SearchGroupLabel>노출여부</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchVendorName"
                onChange={option => {
                  onSelectStatusOptionHandler(option)
                }}
                value={searchBannerStatusOptionValue}
                placeholder="노출/비노출"
                options={searchBannerStatusOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>설정 범위</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchVendorName"
                onChange={option => {
                  onSelectTargetOptionHandler(option)
                }}
                value={searchBannerTargetOptionValue}
                placeholder="PC/모바일"
                options={searchBannerTargetOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onCreateClickHandler()
              }}
            >
              추가하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>

        <ReactTableBase columns={bannerListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>

      {isBannerCreateModalShow && (
        <BannerCreateModal
          isBannerCreateModalShow={isBannerCreateModalShow}
          setIsBannerCreateModalShow={setIsBannerCreateModalShow}
          fetchAdminSearchBannerList={fetchBannerManage}
        />
      )}

      {isBannerModifyModalShow && (
        <BannerModifyModal
          edgeName={bannerInfo?.edgeName}
          bannerKey={bannerInfo?.bannerKey}
          bannerName={bannerInfo?.bannerName}
          imgUrl={bannerInfo?.imgUrl}
          bannerOrderNum={bannerInfo?.bannerOrderNum}
          bannerStatus={bannerInfo?.bannerStatus}
          bannerTarget={bannerInfo?.bannerTarget}
          bannerPosition={bannerInfo?.bannerPosition}
          bannerClick={bannerInfo?.bannerClick}
          bannerType={bannerInfo?.bannerType}
          moreClickShow={bannerInfo?.moreClickShow}
          isBannerModifyModalShow={isBannerModifyModalShow}
          setIsBannerModifyModalShow={setIsBannerModifyModalShow}
          fetchAdminSearchBannerList={fetchBannerManage}
        />
      )}
    </Card>
  )
}

export default BannerList
