import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 종목별 마켓 노출갯수 select 수정
export async function updateSportMarketDetailInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/detail-info',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 종목별 마켓 노출갯수 select 수정
export async function updateAllSportMarketMinOrMaxBettingPer(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/min-max-per',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 종목별 마켓 노출갯수 select 수정
export async function updateAllSportMarketOddPerByMultiply(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/odd-multiply-all',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 종목별 마켓 노출갯수 select 수정
export async function updateSportMarketOddPerByMultiply(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/odd-multiply',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 종목별 마켓 노출갯수 select 수정
export async function updateSportMarketOddPerByPlus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/odd-plus',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 종목별 마켓 노출갯수 select 수정
export async function updateAllSportMarketOddPerByPlus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/odd-plus-all',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 종목별 마켓 노출갯수 select 수정
export async function updateSportMarketSelectMaxLine(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/select/max-line',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// * 종목별 마켓 기준점 노출 타입 select 수정
export async function updateSportMarketSelectBetLine(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/select/bet-line',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// select용 조회
export async function getLeagueNameByNational(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/select/league',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// select용 조회
export async function getNationalNameBySportType(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/select/national',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅 규정 조회(2)
export async function getSportsBettingRestriction(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/betting-per/setting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅설정 업데이트
export async function updateSportsBettingRestriction(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/betting-per/setting',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 배팅설정 조회
export async function getSportsBettingManage(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/betting/manage',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅설정 조회
export async function getSportsBettingManageV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/setting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅설정 업데이트
export async function updateSportsBettingManage(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/betting/manage',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 배팅설정 업데이트
export async function updateSportsBettingManageV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/setting',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// select용 조회
export async function getMarketNameByMarketId(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/combination/marketname',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// select용 조회
export async function getMarketNameBySportType(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/combination/select',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useGetMarketNameBySportType(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  async function fetchGetMarketNameBySportType() {
    await getMarketNameBySportType(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchGetMarketNameBySportType()
  }, [params])

  return { content, totalElement, fetchGetMarketNameBySportType }
}

/**
 * 경기 수동 등록
 */
export async function createSportEventManual(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/create/manual',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 조합 등록
 */
export async function createSportMixed(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/combination/create',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 조합 배당조정
 */
export async function updateSportsMarketCombinationPer(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/combination/per',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 조합 사용유무 변경
 */
export async function changeUsingSportMarketCombination(sportKey) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/market/combination/change/isused/${sportKey}`,
    method: 'PUT',
  }).then(res => {
    return res
  })

  return response
}

// 마켓리스트 조회
export async function getSportsMarketList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 마켓리스트 조회
export async function updateSportsMarketSwitch(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/switch',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 조합 내역 조회
export async function getSportsMixed(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/market/combination',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSportsMixed(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  async function fetchSportsMixed() {
    await getSportsMixed(params).then(response => {
      setContent(response.data.content)
      setTotalElement(response.data.totalElement)
    })
  }

  useEffect(() => {
    fetchSportsMixed()
  }, [params])

  return { content, totalElement, fetchSportsMixed }
}

// 조합 삭제 API
export async function deleteSportMixed(sportKey) {
  const response = await axios({
    url: `/tyson/admin/v2/sport/market/combination/${sportKey}`,
    method: 'DELETE',
  }).then(res => {
    return res
  })
  return response
}

// Flag 내역 조회
export async function getSportsFlag(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/market-flag',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSportsFlag(params) {
  const [content, setContent] = useState([])
  async function fetchSportsFlag() {
    await getSportsFlag(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSportsFlag()
  }, [params])

  return { content, fetchSportsFlag }
}

// Flag 상태 변경
export async function updateSportsFlag(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/market-flag',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// Flag 상태 변경
export async function updateLiveSportsExchangeRate(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/setting/live-sports',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
