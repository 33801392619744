import Tooltip from '@/shared/components/Tooltip'
import PropTypes from 'prop-types'
import React from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import {
  OneToOneAnswerStatus2Enums,
  OneToOneAnswerStatusEnums,
  OneToOneAuthorityEnums,
} from '../../../enums/Operation/OneToOneEnums'
import {
  convertToKstDateTime,
  convertToKstDateTimeSimple,
  convertToKstSportsEventDateTime,
} from '../../../utils/dateTime'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import OnetoOneRadio from './OnetoOneRadio'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const OnetoOneListData = (content, fetchSearchOnetoOneList, checkRadio, setCheckRadio, onTitleClickRowHandler) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <OnetoOneRadio rowInfo={original} checkRadio={checkRadio} setCheckRadio={setCheckRadio} />
      },
      width: 30,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.num}
          </div>
        )
      },
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.edgeName}
          </div>
        )
      },
    },

    {
      Header: '유저 ID(명/닉네임) - 권한',
      accessor: 'userId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div style={{ width: '100%' }}>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName} / {original.nickName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.userId} />
              </span>
              <span>
                <MemberCoupon memberId={original.userId} />
              </span>
              <span>
                <MemberNote memberId={original.userId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.userId} />
              </span>
            </span>
            &nbsp; -{' '}
            <CustomAuthorityButton authority={original.authority}>
              {OneToOneAuthorityEnums[original.authority]}
            </CustomAuthorityButton>
          </div>
        )
      },
    },
    {
      Header: '입금메모',
      accessor: 'depositMemo',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.depositMemo}
          </div>
        )
      },
    },
    {
      Header: '가입 후 입금여부/시간',
      accessor: 'depositExist',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.depositExist) {
          return (
            <span style={{ color: 'green', fontWeight: '600', fontSize: '14px' }}>
              O&nbsp; ({convertToKstSportsEventDateTime(original.lastDepositTime)})
            </span>
          )
        }
        return <span style={{ color: 'red', fontWeight: '600', fontSize: '14px' }}>X</span>
      },
    },
    {
      Header: '제목',
      accessor: 'questionTitle',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.questionTitle}
          </div>
        )
      },
    },

    {
      Header: '답변읽음 상태',
      accessor: 'readStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {OneToOneAnswerStatusEnums[original.readStatus]}
          </div>
        )
      },
    },
    {
      Header: '답변상태',
      accessor: 'customerStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.customerStatus) {
          case 'COMPLETE':
            return (
              <button type="button" style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '500' }}>
                {OneToOneAnswerStatus2Enums[original.customerStatus]}
              </button>
            )
          case 'WAITING':
            return (
              <button type="button" style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '900' }}>
                {OneToOneAnswerStatus2Enums[original.customerStatus]}
              </button>
            )
          case 'APPLY':
            return (
              <button type="button" style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '900' }}>
                {OneToOneAnswerStatus2Enums[original.customerStatus]}
              </button>
            )
          default:
            return (
              <button type="button" style={{ background: 'black', border: 'none', color: '#fff', fontWeight: '900' }}>
                {OneToOneAnswerStatus2Enums[original.customerStatus]}
              </button>
            )
        }
      },
    },
    {
      Header: '답변 등록일',
      accessor: 'registrationDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {convertToKstDateTime(original.registrationDate)}
          </div>
        )
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default OnetoOneListData

OnetoOneListData.propTypes = {
  fetchSearchOnetoOneList: PropTypes.func.isRequired,
}

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
