import React, { useMemo } from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import MemberBlackListDetail from './MemberBlackListDetail'
import MemberBlackInfoModal from './MemberBlackInfoModal'
import { convertToKstDate } from '../../../utils/dateTime'
import { DamageTypeEnums } from '../../../enums/MemberManage/BlackListEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'

const MemberBlackListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '피해본 유형',
      accessor: 'damageType',
      Cell: r => {
        const { row } = r
        const { original } = row
        return DamageTypeEnums[original.damageType]
      },
    },
    {
      Header: '유저 ID(명)',
      accessor: 'memberId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return <MemberBlackInfoModal blackMemberInfo={original} />
        // return
      },
    },
    {
      Header: '피해본 금액',
      accessor: 'damageAmount',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.damageAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '연락처',
      accessor: 'phoneNum',
      disableGlobalFilter: true,
    },
    {
      Header: '피해 내용',
      accessor: 'damageContent',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.damageContent) {
          case '':
            return '-'
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>{original.damageContent}</span>
        }
      },
    },
    {
      Header: '블랙 등록일',
      accessor: 'damageDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDate(original.damageDate)
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberBlackListData

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
