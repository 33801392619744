import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { SlotCityCallStatusType } from '../../../enums/Casino/SlotCityEnums'
import { fetchSlotCityBonusCallHistory, setSlotCityOnlineGameList } from '../../../redux/CasinoInfoSlice'
import { Button } from '../../../shared/components/Button'
import {
  cancelSlotCityBonusCall,
  closeSlotCityGameSession,
  searchSlotCityOnlineGameList,
} from '../../../utils/api/casino/slotCityApi'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import SlotCityCallStartModal from './SlotCityCallStartModal'

const SlotCityOnlineGameList = () => {
  const dispatch = useDispatch()

  const { slotCityOnlineGameList, slotCityCallHistoryParams } = useSelector(state => {
    const { casinoInfo } = state

    return {
      slotCityOnlineGameList: casinoInfo.slotCityOnlineGameList,
      slotCityCallHistoryParams: casinoInfo.slotCityCallHistoryParams,
    }
  })

  // 현재 진행중인 게임 리스트 조회
  const fetchSlotCityOnlineGameList = async () => {
    await searchSlotCityOnlineGameList().then(res => {
      dispatch(setSlotCityOnlineGameList(res.data.content))
    })
  }

  useEffect(() => {
    fetchSlotCityOnlineGameList()

    // 10초 간격 실행
    const interval = setInterval(() => {
      fetchSlotCityOnlineGameList()
    }, 10000)

    // 컴포넌트 언마운트 시 인터벌 정리
    return () => {
      clearInterval(interval)
    }
  }, [])

  const [loading, setLoading] = useState(false)

  const cancelCallHandler = callId => {
    if (loading) return

    setLoading(true)

    const body = {
      callId,
    }

    if (window.confirm('콜 취소하시겠습니까?')) {
      cancelSlotCityBonusCall(body)
        .then(res => {
          alert('콜 취소되었습니다.')
          dispatch(fetchSlotCityBonusCallHistory(slotCityCallHistoryParams))
        })
        .catch(error => {
          alert('콜 취소 요청이 실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const closeGameSessionHandler = casinoGameUserId => {
    if (loading) return

    setLoading(true)

    const body = {
      casinoGameUserId,
    }

    if (window.confirm('접속되어 있는 모든 게임의 세션이 끊어집니다. \n정말 종료시키겠습니까?')) {
      closeSlotCityGameSession(body)
        .then(res => {
          alert('해당 유저 연결이 종료되었습니다.')
        })
        .catch(error => {
          alert('세션 종료실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const [slotCityCallModel, setSlotCityCallModel] = useState(null)
  const [slotCityCallStartModalShow, setSlotCityCallStartModalShow] = useState(false)

  const onOpenSlotCityCallStartModalHnadler = item => {
    setSlotCityCallModel(item)
    setSlotCityCallStartModalShow(true)
  }

  const onCloseSlotCityCallStartModalHnadler = () => {
    setSlotCityCallStartModalShow(false)
    setSlotCityCallModel(null)
  }

  const getBonusCallFunctionBtn = item => {
    if (item.callId === 0 && item.callEnable && item.callEndFlag) {
      return (
        <Button
          variant="primary"
          size="sm"
          type="button"
          onClick={() => {
            onOpenSlotCityCallStartModalHnadler(item)
          }}
        >
          콜 적용
        </Button>
      )
    }

    if (item.callId !== 0 && item.callStatus !== 'COMPLETE' && !item.callEndFlag) {
      return (
        <Button
          variant="danger"
          size="sm"
          type="button"
          onClick={() => {
            cancelCallHandler(item.callId)
          }}
        >
          콜 취소
        </Button>
      )
    }
  }

  return (
    <>
      {slotCityCallStartModalShow && (
        <SlotCityCallStartModal
          slotCityCallModel={slotCityCallModel}
          slotCityCallStartModalShow={slotCityCallStartModalShow}
          onCloseSlotCityCallStartModalHnadler={onCloseSlotCityCallStartModalHnadler}
        />
      )}

      <ContentTitle>진행중 게임 ({slotCityOnlineGameList.length})</ContentTitle>
      <ContentWrap>
        <table>
          <thead>
            <tr>
              <th>유저</th>
              <th>게임사</th>
              <th>게임</th>
              <th>카테고리</th>
              <th>라운드 베팅</th>
              <th>총 베팅</th>
              <th>총 당첨</th>
              <th>수익</th>
              <th>시작시간</th>
              <th>콜 당첨액</th>
              <th>콜 상태</th>
              <th>기능</th>
            </tr>
          </thead>
          <tbody>
            {slotCityOnlineGameList.length === 0 ? (
              <tr>
                <td colSpan={12}>데이터 없음</td>
              </tr>
            ) : (
              <>
                {slotCityOnlineGameList.map(slotCityOnlineGame => {
                  return (
                    <tr>
                      <td>
                        <span
                          onClick={() => {
                            window.open(`/user-info/${slotCityOnlineGame.userId}`, '_blank')
                          }}
                          style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {slotCityOnlineGame.userId} ({slotCityOnlineGame.userName})
                        </span>
                        <span>
                          <span>
                            <MemberDepositInfo memberId={slotCityOnlineGame.userId} />
                          </span>
                          <span>
                            <MemberCoupon memberId={slotCityOnlineGame.userId} />
                          </span>
                          <span>
                            <MemberNote memberId={slotCityOnlineGame.userId} />
                          </span>
                          <span>
                            <MemberMoneyIncDecMini memberId={slotCityOnlineGame.userId} />
                          </span>
                        </span>
                      </td>
                      <td>{slotCityOnlineGame.providerName}</td>
                      <td>{slotCityOnlineGame.gameName}</td>
                      <td>{slotCityOnlineGame.category}</td>
                      <td>{slotCityOnlineGame.lastRoundBettingAmount.toString().replace(commonReg2, ',')}</td>
                      <td>{slotCityOnlineGame.bettingAmount.toString().replace(commonReg2, ',')}</td>
                      <td>{slotCityOnlineGame.winningAmount.toString().replace(commonReg2, ',')}</td>
                      <td>
                        {(Number(slotCityOnlineGame.winningAmount) - Number(slotCityOnlineGame.bettingAmount))
                          .toString()
                          .replace(commonReg2, ',')}
                      </td>
                      <td>{convertToKstDateTime(slotCityOnlineGame.startTime)}</td>
                      <td>{slotCityOnlineGame.callWinningAmount.toString().replace(commonReg2, ',')}</td>
                      <td>
                        {slotCityOnlineGame.callId === 0 ? (
                          '-'
                        ) : (
                          <>{SlotCityCallStatusType[slotCityOnlineGame.callStatus]}</>
                        )}
                      </td>
                      <td>
                        <CallBtnBox>
                          {getBonusCallFunctionBtn(slotCityOnlineGame)}
                          <Button
                            variant="primary"
                            size="sm"
                            type="button"
                            onClick={() => {
                              closeGameSessionHandler(slotCityOnlineGame.casinoGameUserId)
                            }}
                          >
                            세션 종료
                          </Button>
                        </CallBtnBox>
                      </td>
                    </tr>
                  )
                })}
              </>
            )}
          </tbody>
        </table>
      </ContentWrap>
    </>
  )
}

export default SlotCityOnlineGameList

const CallBtnBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin-bottom: 0;
    padding: 3px 10px;
    font-size: 12px;
  }
`

const ContentTitle = styled.h3`
  font-weight: 500;
  margin-bottom: 10px;
  color: #000;
`

const ContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
