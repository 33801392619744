import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const MemberManageMentTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/user/management/member')
                }}
              >
                유저 조회(간략)
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/user/management/partner')
                }}
              >
                유저 조회(세부)
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/user/management/registration')
                }}
              >
                유저 등록
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/partner/management/apply')
                }}
              >
                파트너 신청
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/user/management/black')
                }}
              >
                블랙리스트
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="7"
                onClick={e => {
                  history.push('/user/management/black/ip')
                }}
              >
                IP 차단리스트
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="6"
                onClick={e => {
                  history.push('/user/management/bad')
                }}
              >
                악성유저 조회
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}
export default MemberManageMentTabs

MemberManageMentTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
