import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 충전 보너스 조회
export async function searchDepositBonus(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/deposit-bonus',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 조회 V2
export async function searchDepositBonusV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/system/management/deposit-bonus',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 생성
export async function createDepositBonus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/deposit-bonus',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 생성 V2
export async function createDepositBonusV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/system/management/deposit-bonus',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 수정
export async function updateDepositBonus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/deposit-bonus',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 수정 V2
export async function updateDepositBonusV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/system/management/deposit-bonus',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 금액 설정
export async function updateDepositBonusMoneySetting(body) {
  const response = await axios({
    url: '/tyson/admin/v2/system/management/deposit-bonus/money-setting',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 삭제
export async function deleteDepositBonus(depositKey) {
  const response = await axios({
    url: `/tyson/admin/v1/system/management/deposit-bonus/${depositKey}`,
    method: 'DELETE',
  }).then(res => {
    return res
  })

  return response
}

// 충전 보너스 삭제 V2
export async function deleteDepositBonusV2(depositKey) {
  const response = await axios({
    url: `/tyson/admin/v2/system/management/deposit-bonus/${depositKey}`,
    method: 'DELETE',
  }).then(res => {
    return res
  })

  return response
}

// 입금신청 규정 조회
export async function searchDepositRule(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/deposit-rule',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 입금신청 규정 수정
export async function updateDepositRule(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/deposit-rule',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 출금신청 규정 조회
export async function searchWithdrawalRule(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/withdrawal-rule',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 출금신청 규정 수정
export async function updateWithdrawalRule(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/withdrawal-rule',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 출금신청  필요 롤링 조회
export async function searchWithdrawalRolling(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/withdrawal-rolling',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 출금신청 필요 롤링 수정
export async function updateWithdrawalRolling(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/withdrawal-rolling',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
