import { Card, CardBody } from '@/shared/components/Card'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import VirtualGameManagementTabs from './VirtualGameManagementTabs'
import VirtualVendorManagement from './VirtualVendorManagement'
import VirtualLeagueManagement from './VirtualLeagueManagement'

const VirtualGameManagement = () => {
  const history = useHistory()

  return (
    <Card>
      <CardBody>
        <VirtualGameManagementTabs activeKey="1" />
        <VirtualManagementWrap>
          <VirtualVendorManagement />
          <VirtualLeagueManagement />
        </VirtualManagementWrap>
      </CardBody>
    </Card>
  )
}

export default VirtualGameManagement

VirtualGameManagement.propTypes = {}

const VirtualManagementWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  margin-top: 10px;
`
