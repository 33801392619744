import { Card, CardBody, CouponCardSubhead, CouponCardTitle, CouponCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { CouponStatusEnumsSelectOptions } from '../../../enums/Operation/couponEnums'
import { useSearchCouponUseList } from '../../../utils/api/operation/operationApi'
import { convertToKst } from '../../../utils/dateTime'
import OperationTabs from '../OperationTabs'
import CouponListData from './CouponListData'
import CouponTabs from './CouponTabs'

const CouponList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  /**
   쿠폰 조회
   */
  const [couponUseListparams, setCouponUseListparamsParams] = useState({
    page,
    size,
    userId: null,
    status: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setCouponUseListparamsParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchCouponUseList } = useSearchCouponUseList(couponUseListparams)
  const [couponListData, setCouponListData] = useState(CouponListData(content))
  useEffect(() => {
    setCouponListData(CouponListData(content))
  }, [content])

  const [rows, setData] = useState(couponListData.tableRowsData)
  useEffect(() => {
    setData(couponListData.tableRowsData)
  }, [couponListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 사용유저
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = CouponStatusEnumsSelectOptions()

  const onSelectStatusOptionHandler = option => {
    if (option === null) {
      setSearchStatusOptionValue(null)
      setSearchStatus(null)
      return
    }
    setSearchStatusOptionValue(option)
    setSearchStatus(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setCouponUseListparamsParams(prev => ({
      ...prev,
      page: 0,
      userId: !searchUserId ? null : searchUserId.trim(),
      status: !searchStatus ? null : searchStatus,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }
  // Tab Key
  const [activeKey, setActiveKey] = useState('5')
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <OperationTabs activeKey={activeKey} />
          <Row style={{ marginBottom: '-30px' }}>
            <CouponTabs style={{ border: 'solid 1px red' }} />{' '}
          </Row>
          <Row style={{ borderTop: 'solid 1px black', paddingTop: '10px' }}>
            {' '}
            <CouponCardTitleWrap>
              <CouponCardTitle>쿠폰 사용 내역</CouponCardTitle>
              <CouponCardSubhead>* 회원 및 파트너들의 쿠폰 사용내역입니다.</CouponCardSubhead>
            </CouponCardTitleWrap>
            {/* 검색 기능 */}
            <SearchContainer horizontal>
              <SearchGroup>
                <SearchGroupLabel>사용유저</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchUserId"
                      type="text"
                      placeholder="입력해주세요"
                      value={searchUserId}
                      onChange={e => onSearchUserIdHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>상태</SearchGroupLabel>
                <SearchGroupField>
                  <SearchSelect
                    name="searchStatus"
                    placeholder="상태"
                    onChange={option => {
                      onSelectStatusOptionHandler(option)
                    }}
                    value={searchStatusOptionValue}
                    options={searchStatusOption}
                    className="react-select"
                    classNamePrefix="react-select"
                    isClearable
                  />
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>시작일</SearchGroupLabel>
                <SearchGroupField>
                  <SearchDatePickerWrap>
                    <DatePicker
                      selected={searchStartDate}
                      onChange={date => onSearchStartDateChangeHandler(date)}
                      dateFormat="yyyy-MM-dd"
                      dropDownMode="select"
                      popperPlacement="bottom-start"
                      placeholderText="시작일"
                      locale={ko}
                      isClearable
                    />
                  </SearchDatePickerWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>종료일</SearchGroupLabel>
                <SearchGroupField>
                  <SearchDatePickerWrap>
                    <DatePicker
                      selected={searchEndDate}
                      onChange={date => onSearchEndDateChangeHandler(date)}
                      dateFormat="yyyy-MM-dd"
                      dropDownMode="select"
                      popperPlacement="bottom-start"
                      placeholderText="종료일"
                      locale={ko}
                      isClearable
                    />
                  </SearchDatePickerWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchButtonWrap>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    onSearchClickHandler()
                  }}
                >
                  검색하기
                </Button>
              </SearchButtonWrap>
            </SearchContainer>
            <ReactTableBase columns={couponListData.tableHeaderData} data={rows} tableConfig={tableConfig} />{' '}
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

CouponList.propTypes = {
  couponListData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
}

export default CouponList
