import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import { colorBackground, colorBlue, colorIcon, colorText, colorWhite } from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { fetchSlotCityBonusCallHistory } from '../../../redux/CasinoInfoSlice'
import { ButtonToolbar } from '../../../shared/components/Button'
import Loading from '../../../shared/components/Loading'
import { startSlotCityBonusCall } from '../../../utils/api/casino/slotCityApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'

const SlotCityCallStartModal = ({
  slotCityCallModel,
  slotCityCallStartModalShow,
  onCloseSlotCityCallStartModalHnadler,
}) => {
  const dispatch = useDispatch()

  const [apiLoading, setApiLoading] = useState(false)

  const [amount, setAmount] = useState('')
  const [memo, setMemo] = useState('')

  const { slotCityCallHistoryParams } = useSelector(state => {
    const { casinoInfo } = state

    return {
      slotCityCallHistoryParams: casinoInfo.slotCityCallHistoryParams,
    }
  })

  const onSubmit = () => {
    if (apiLoading) return

    setApiLoading(true)

    const body = {
      gamePlayId: slotCityCallModel.gamePlayId,
      amount,
      memo,
    }

    if (window.confirm('콜 적용하시겠습니까?')) {
      startSlotCityBonusCall(body)
        .then(res => {
          alert('콜 적용 되었습니다.')
          dispatch(fetchSlotCityBonusCallHistory(slotCityCallHistoryParams))
          onCloseSlotCityCallStartModalHnadler()
        })
        .catch(error => {
          alert('콜 적용 실패했습니다.')
        })
        .finally(() => {
          setApiLoading(false)
        })
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <StyledModal show={slotCityCallStartModalShow} onHide={onCloseSlotCityCallStartModalHnadler} $header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onCloseSlotCityCallStartModalHnadler}
          />
          <ModalTitle>
            <span>슬롯시티 콜 적용</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <SportsMarketContentHeaderWrap>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>유저</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>
                {slotCityCallModel.userId} - {slotCityCallModel.userName}
              </SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>게임사</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>{slotCityCallModel.providerName}</SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
            <SportsMarketContentHeader>
              <SportsMarketContentHeaderLabel>게임</SportsMarketContentHeaderLabel>
              <SportsMarketContentHeaderValue>{slotCityCallModel.gameName}</SportsMarketContentHeaderValue>
            </SportsMarketContentHeader>
          </SportsMarketContentHeaderWrap>
          <SportsMarketContentBodyWrap>
            <Form
              onSubmit={onSubmit}
              initialValues={{
                slotCityCallModel,
              }}
            >
              {({ handleSubmit, form: { reset } }) => (
                <SportsMarketFormContainer horizontal onSubmit={handleSubmit} style={{ flexDirection: 'column' }}>
                  <FormGroup>
                    <FormGroupLabel>콜 적용 금액</FormGroupLabel>
                    <FormGroupField>
                      <Field name="amount">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={amount?.toString().replace(commonReg2, ',')}
                              placeholder="0"
                              onChange={e => {
                                const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                                setAmount(onlyNumber)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormGroup>
                    <FormGroupLabel>메모</FormGroupLabel>
                    <FormGroupField>
                      <Field name="memo">
                        {({ input, meta }) => (
                          <FormInputWrap
                            style={{
                              border: '1px solid black',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <input
                              {...input}
                              type="text"
                              value={memo}
                              onChange={e => {
                                setMemo(e.target.value)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>

                  <FormButtonToolbar style={{ marginLeft: '0' }}>
                    <Button variant="primary" type="submit">
                      콜 적용
                    </Button>
                  </FormButtonToolbar>
                </SportsMarketFormContainer>
              )}
            </Form>
          </SportsMarketContentBodyWrap>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default SlotCityCallStartModal

SlotCityCallStartModal.propTypes = {
  slotCityCallModel: PropTypes.shape().isRequired,
  slotCityCallStartModalShow: PropTypes.bool,
  onCloseSlotCityCallStartModalHnadler: PropTypes.func,
}

SlotCityCallStartModal.defaultProps = {
  slotCityCallStartModalShow: false,
  onCloseSlotCityCallStartModalHnadler: () => {},
}

const CustomLabel = styled.label`
  min-width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
  margin-right: 5px;

  input {
    width: 15px;
    margin-right: 5px;
  }
`

export const CustomButton = styled(Button)`
  width: 50px;
  height: 30px;
  font-size: 15px;
  padding: 0px;
  padding-top: 3px;
`

export const CustomInput = styled.input`
  border: none !important;
  height: 22px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-align: center !important;
  align-items: center !important;
  justify-content: center !important;
`

export const CustomFormInputWrap = styled.div`
  width: 100%;
  font-size: 14px;
  display: flex;
`

const SportsMarketFormContainer = styled(FormContainer)`
  flex-direction: column;
  padding: 20px 0;
  // border: 1px solid gray;
`

const SportsMarketContentHeaderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
`

const SportsMarketContentHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #888;
`

const SportsMarketContentHeaderLabel = styled.span`
  width: 30%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  border-right: 2px solid #888;
`

const SportsMarketContentHeaderValue = styled.span`
  width: 70%;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
`

const SportsMarketContentBodyWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div`
  background-color: grey !important;
  font-weight: 700;
`
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 500px !important;
    margin-top: 50px;
  }

  .modal-content {
    height: max-content;
    min-height: 400px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
