import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BetSettlementStatus, BetStatus } from '../../../enums/Sport/SportMarketEnums'
import { updateSportsDetailBettingLogV2 } from '../../../utils/api/sport/sportBettingLogApi'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const SportDetailBettingLog = ({ sportsDetailBettingLogList, bonsuFolder }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [newSettlementType, setNewSettlementType] = useState(null)

  useEffect(() => {
    setNewSettlementType(sportsDetailBettingLogList.betSettlementType)
  }, [sportsDetailBettingLogList])

  const onSubmit2 = (sportsDetailBettingLogKey, adminChange, betSettlementType) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (!betSettlementType) {
      alert('진행중으로는 결과변경이 불가합니다.')
      return
    }
    if (
      window.confirm('해당 경기의 결과를 변경하시겠습니까? \n 해당 회원의 최종적인 결과는 1분이내 자동으로 반영됩니다.')
    ) {
      const body = {
        sportsDetailBettingLogKey,
        betSettlementType,
        adminChange,
      }
      setWriteLoading(true)

      updateSportsDetailBettingLogV2(body)
        .then(res => {
          setWriteLoading(false)
          alert('스포츠 결과가 변경됐습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          alert('스포츠 결과가 변경이 실패했습니다.')
        })
    }
  }

  const [betSettlementTypes, setBetSettlementTypes] = useState(
    sportsDetailBettingLogList.reduce((acc, log) => {
      acc[log.sportsDetailBettingLogKey] = log.betSettlementType || ''
      return acc
    }, {}),
  )

  const [adminChanges, setAdminChanges] = useState(
    sportsDetailBettingLogList.reduce((acc, log) => {
      acc[log.sportsDetailBettingLogKey] = log.adminChange || false
      return acc
    }, {}),
  )

  const handleCheckboxChange = sportsDetailBettingLogKey => {
    setAdminChanges(prev => ({
      ...prev,
      [sportsDetailBettingLogKey]: !prev[sportsDetailBettingLogKey],
    }))
  }

  const handleSelectChange = (sportsDetailBettingLogKey, value) => {
    setBetSettlementTypes(prev => ({
      ...prev,
      [sportsDetailBettingLogKey]: value,
    }))
  }

  const getBetBox = detail => {
    const pattern = /^-?\d+\.\d+ \(\d+-\d+\)$/
    const match = detail.baseLine?.match(pattern)

    // 핸디일때
    if (detail.marketFamilyType === 11 && match) {
      const innerPattern = /\((\d+)-(\d+)\)/
      const scoreMatch = detail.line.match(innerPattern)

      const homeScore = Number.isNaN(Number(scoreMatch[1])) ? 0 : Number(scoreMatch[1])
      const awayScore = Number.isNaN(Number(scoreMatch[2])) ? 0 : Number(scoreMatch[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine

      // 원정팀 선택시
      if (detail.betName === '1') {
        finalLine = detail.lineNum - homeScore + awayScore
      } else {
        finalLine = detail.lineNum + homeScore - awayScore
      }

      return (
        <>
          {detail.line !== null ? (
            <span>
              {detail.line} <span style={{ color: 'red' }}>( {finalLine.toFixed(1)} )</span>
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      )
    }

    return <>{detail.line !== null ? <span>{detail.lineNum.toFixed(1)}</span> : <span>-</span>}</>
  }

  return (
    <SportDetailBettingLogWrap>
      <td colSpan={17} style={{ padding: '10px' }}>
        <table>
          <thead>
            <tr>
              <th>경기시간</th>
              <th>종목</th>
              <th>국가 - 리그</th>
              <th>경기</th>
              <th>마켓</th>
              <th>배팅</th>
              <th colSpan={1}>
                <TooltipWrapper
                  text="(홈) 기준점 - 홈스코어 + 어웨이스코어"
                  textnd="(원정) 기준점 + 홈스코어 - 어웨이스코어"
                  textrd="ex. -0.5 (0-1) : (-0.5)-0+1 = 0.5"
                  place="bottom"
                >
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>기준점</span>
                </TooltipWrapper>
              </th>
              <th>배당</th>
              {/* <th>점수</th> */}
              <th>상태</th>
              <th>결과</th>
              <th>처리시간</th>
              <th>
                <TooltipWrapper text="체크시, API 실제 결과 값을 무시" place="bottom">
                  <span style={{ color: 'lightgrey', textDecoration: 'underline', cursor: 'pointer' }}>결과고정</span>
                </TooltipWrapper>
              </th>
              <th>기능</th>
            </tr>
          </thead>
          <tbody>
            {bonsuFolder.bonusFolderNum !== 0 && (
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td
                  style={{
                    background: 'rgba(237, 222, 88,0.5)',
                    color: '#000',
                  }}
                >
                  {bonsuFolder.bonusFolderNum} 폴더 이상
                </td>
                <td
                  style={{
                    background: 'rgba(237, 222, 88,0.5)',
                    color: '#000',
                  }}
                >
                  보너스 배당
                </td>
                <td>-</td>
                <td>{bonsuFolder.bonusFolderPer}</td>
                {/* <td> - </td> */}
                <td>정산</td>
                <td>적중</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            )}

            {sportsDetailBettingLogList.map(sportsDetailBettingLog => {
              return (
                <tr>
                  <td>{convertToKstSportsEventDateTime(sportsDetailBettingLog.startDate)}</td>
                  <td>{sportsDetailBettingLog.sportName}</td>
                  <td>
                    {sportsDetailBettingLog.locationName} - {sportsDetailBettingLog.leagueName}
                  </td>
                  <td>
                    {sportsDetailBettingLog.homeName} vs {sportsDetailBettingLog.awayName}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {sportsDetailBettingLog.marketName}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {sportsDetailBettingLog.betNameKr}
                  </td>
                  <td>{getBetBox(sportsDetailBettingLog)}</td>
                  <td>{sportsDetailBettingLog.priceNum}</td>
                  {/* <td> - </td> */}
                  <td>{BetStatus[sportsDetailBettingLog.betStatus]}</td>
                  <td>
                    {sportsDetailBettingLog.betSettlementType === 'CANCELLED' ? (
                      <span style={{ color: 'red', fontWeight: '500' }}>취소</span>
                    ) : (
                      <select
                        style={{ padding: '0px 3px' }}
                        value={betSettlementTypes[sportsDetailBettingLog.sportsDetailBettingLogKey]}
                        onChange={e =>
                          handleSelectChange(sportsDetailBettingLog.sportsDetailBettingLogKey, e.target.value)
                        }
                      >
                        <option
                          value="NOT_SETTLED"
                          selected={sportsDetailBettingLog.betSettlementType === 'NOT_SETTLED'}
                        >
                          진행중
                        </option>
                        <option value="WINNER" selected={sportsDetailBettingLog.betSettlementType === 'WINNER'}>
                          적중
                        </option>
                        <option value="LOSER" selected={sportsDetailBettingLog.betSettlementType === 'LOSER'}>
                          미적중
                        </option>
                        <option value="REFUND" selected={sportsDetailBettingLog.betSettlementType === 'REFUND'}>
                          적특
                        </option>
                      </select>
                    )}
                  </td>
                  <td>{convertToKstSportsEventDateTime(sportsDetailBettingLog.resultExecuteAt)}</td>
                  <td>
                    <input
                      style={{ width: '17px', height: '17' }}
                      type="checkbox"
                      checked={adminChanges[sportsDetailBettingLog.sportsDetailBettingLogKey]}
                      onChange={() => handleCheckboxChange(sportsDetailBettingLog.sportsDetailBettingLogKey)}
                    />
                  </td>
                  <td>
                    {sportsDetailBettingLog.betSettlementType === 'CANCELLED' ? (
                      '-'
                    ) : (
                      <button
                        type="button"
                        style={{ border: 'none', background: 'grey', color: '#fff' }}
                        onClick={() => {
                          onSubmit2(
                            sportsDetailBettingLog.sportsDetailBettingLogKey,
                            adminChanges[sportsDetailBettingLog.sportsDetailBettingLogKey],
                            betSettlementTypes[sportsDetailBettingLog.sportsDetailBettingLogKey],
                          )
                        }}
                      >
                        변경
                      </button>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </td>
    </SportDetailBettingLogWrap>
  )
}

export default SportDetailBettingLog

SportDetailBettingLog.propTypes = {
  sportsDetailBettingLogList: PropTypes.arrayOf().isRequired,
  bonsuFolder: PropTypes.shape(),
}

SportDetailBettingLog.defaultProps = {
  bonsuFolder: null,
}

const SportDetailBettingLogWrap = styled.tr`
  border: 1px solid #888;

  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #6b6b6b !important;

      &:first-child {
        border-top: 1px solid #888;
      }

      &:hover {
        border: none;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: #fff !important;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      background: #fbf7ea;

      &:hover {
        border: none;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
