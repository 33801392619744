import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { MoneyExchangeTypeEnums } from '../../../enums/Log/moneyIncDecEnums'
import { CustomAuthorityButton, CustomSettlementTypeButton } from '../../../shared/components/Card'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import LosingApproveRegistrationRadio from './LosingApproveRegistrationRadio'
import { ExchagneDateEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import { MemberDepositStatusEnums } from '../../../enums/MemberHistory/MemberDepositEnums'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MemberPointExchangeListData = (content, setRegistrationApprove) => {
  const columns = [
    {
      Header: '승인',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <LosingApproveRegistrationRadio rowPartnerInfo={original} setRegistrationApprove={setRegistrationApprove} />
        )
      },
      width: 50,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '유저 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.userId} />
              </span>
              <span>
                <MemberCoupon memberId={original.userId} />
              </span>
              <span>
                <MemberNote memberId={original.userId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.userId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '롤링/루징',
      accessor: 'pointType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomSettlementTypeButton pointType={original.pointType}>
            {MoneyExchangeTypeEnums[original.pointType]}
          </CustomSettlementTypeButton>
        )
      },
    },
    {
      Header: '정산(자동/수동)',
      accessor: 'userPointExchangeAuto',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '정산일',
      accessor: 'userExchangeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ color: 'black', fontWeight: '600' }}>{ExchagneDateEnums[original.userExchangeDate]}</span>
      },
    },
    {
      Header: '변경전(지갑)',
      accessor: 'beforeMoneyAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeMoneyAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경전(C)',
      accessor: 'beforePoint',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '전환액',
      accessor: 'changePointExchange',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후(지갑)',
      accessor: 'afterMoneyAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterMoneyAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후(C)',
      accessor: 'afterPoint',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'APPROVE':
            return <span style={{ color: 'blue', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
          case 'WAITING':
            return (
              <span style={{ color: 'orange', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
            )
          case 'CANCEL':
            return <span style={{ color: 'red', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
          case 'APPLY':
            return (
              <span style={{ color: 'green', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
            )
          default:
            return (
              <span style={{ color: 'black', fontWeight: '900' }}>{MemberDepositStatusEnums[original.status]}</span>
            )
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberPointExchangeListData
