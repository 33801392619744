import { ButtonToolbar } from '@/shared/components/Button'
import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Modal as BootstrapModal } from 'react-bootstrap'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import {
  GameStatusEnumsRadioOptions,
  VendorInspectionEnumsRadioOptions,
} from '../../../enums/SystemManagement/gameEnums'
import { updateRecentVendor, updateVendor } from '../../../utils/api/systemManage/systemManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { casinoVendorEnums } from '../../../enums/SystemManagement/casinoEnums'
import Loading from '../../../shared/components/Loading'

function GameModifyModal({
  vendorNameKo,
  vendorKey,
  agencyName,
  vendorName,
  status,
  vendorImg,
  vendorOrderNum,
  memo,
  vendorIsInspection,
  fetchSearchVendors,
  isGameModifyModalShow,
  setGameModifyModalShow,
}) {
  const [newVendorImg, setNewVendorImg] = useState(vendorImg || '')

  const [newVendorOrderNum, setNewVendorOrderNum] = useState(vendorOrderNum || '')

  const authority = decodeAccessToken()?.adminAuthority
  const onClickHandler = () => {
    setGameModifyModalShow(prevState => !prevState)
    fetchSearchVendors()
  }

  const onSubmit = e => {
    const body = {
      vendorKey,
      status: e.gameStatus,
      vendorImg: !newVendorImg ? null : newVendorImg,
      vendorIsInspection: e.vendorIsInspection,
      vendorOrderNum: newVendorOrderNum,
    }

    updateVendor(body)
      .then(res => {
        alert('수정되었습니다.')
        onClickHandler()
      })
      .catch(err => {
        alert('수정 실패했습니다.')
      })
  }
  const [loading, setLoading] = useState(false)
  const onNewSubmit = (vendorKey, agencyName, vendorName) => {
    if (loading) return
    if (
      window.confirm(
        `[${agencyName}]-${vendorNameKo}를 최신화 하시겠습니까?\n영상사는 다르지만, 같은 이름의 벤더사인 경우 한곳만 최신화를 해도 동시 적용됩니다.\n연속적인 최신화는 서버에 부하가 발생합니다.`,
      )
    ) {
      setLoading(true)
      const body = {
        vendorKey,
        agencyName,
        vendorName,
      }

      updateRecentVendor(body)
        .then(res => {
          alert(`[${agencyName}]-${vendorNameKo} 최신화되었습니다.`)
          setLoading(false)
          onClickHandler()
        })
        .catch(err => {
          alert(`[${agencyName}]-${vendorNameKo} 최신화가 실패했습니다.`)
          setLoading(false)
        })
    }
  }

  return (
    <StyledModal show={isGameModifyModalShow} onHide={onClickHandler} $header>
      <ModalHeader>
        <ModalCloseButton className="lnr lnr-cross" aria-label="close-btn" type="button" onClick={onClickHandler} />
        <ModalTitle>게임사 수정</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red', fontWeight: 'bolder' }}>점검</span>을 선택하면 유저가 게임사 클릭 시, 점검
              알림이 나옵니다.
            </p>
            <p>
              * 상태값을 <span style={{ color: 'blue', fontWeight: 'bolder' }}>숨김</span>을 선택하면 유저에게 게임사에
              속한 게임들이 보이지 않습니다.
            </p>
            {/* <p>
              * 영상사는 다르지만, <span style={{ color: 'red' }}>같은 이름의 벤더사</span>인 경우 한곳만 최신화를 해도
              같이 적용됩니다.
            </p> */}
          </div>
        </SearchCardTitleWrap>
        {loading && <Loading />}
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer
              horizontal
              onSubmit={handleSubmit}
              initialValue={{
                agencyName,
                vendorName,
                status,
                vendorImg: newVendorImg,
                vendorOrderNum: newVendorOrderNum,
                vendorIsInspection,
              }}
            >
              <FormGroup>
                <FormGroupLabel>업체</FormGroupLabel>
                <FormGroupField>
                  <Field name="agencyName">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={casinoVendorEnums[agencyName]}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>게임사명</FormGroupLabel>
                <FormGroupField>
                  <Field name="vendorName">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={vendorName}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>게임사 상태</FormGroupLabel>
                {GameStatusEnumsRadioOptions().map(item => (
                  <FormGroupField
                    key={`index_${item.label}`}
                    style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                  >
                    <Field
                      name={`${item.name}`}
                      component={renderRadioButtonField}
                      label={item.label}
                      radioValue={item.radioValue}
                      initialValue={status}
                    />
                  </FormGroupField>
                ))}
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>게임사 점검여부</FormGroupLabel>
                {VendorInspectionEnumsRadioOptions().map(item => (
                  <FormGroupField
                    key={`index_${item.label}`}
                    style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                  >
                    <Field
                      name={`${item.name}`}
                      component={renderRadioButtonField}
                      label={item.label}
                      radioValue={item.radioValue}
                      initialValue={vendorIsInspection}
                    />
                  </FormGroupField>
                ))}
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>순서</FormGroupLabel>
                <FormGroupField>
                  <Field name="vendorOrderNum">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={newVendorOrderNum}
                          onChange={e => {
                            // input.onChange(e)
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')
                            setNewVendorOrderNum(onlyNumber)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>비고</FormGroupLabel>
                <FormGroupField>
                  <Field name="memo">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input {...input} type="text" value={memo} disabled readOnly />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>게임사 로고</FormGroupLabel>
                <FormGroupField>
                  <Field name="vendorImg">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={newVendorImg}
                          onChange={e => {
                            input.onChange(e)
                            setNewVendorImg(e.target.value)
                          }}
                          placeholder="게임사 로고 이미지 URL를 입력하세요."
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              {
                {
                  SUPER: (
                    <FormButtonToolbar style={{ marginRight: '120px' }}>
                      <Button variant="primary" type="submit">
                        수정하기
                      </Button>
                      {agencyName !== 'CMD' && (
                        <Button
                          style={{ background: 'red', border: 'red' }}
                          variant="primary"
                          onClick={() => {
                            onNewSubmit(vendorKey, agencyName, vendorName)
                          }}
                        >
                          최신화
                        </Button>
                      )}
                    </FormButtonToolbar>
                  ),
                }[authority]
              }
            </FormContainer>
          )}
        </Form>
      </ModalBody>
    </StyledModal>
  )
}

export default GameModifyModal

GameModifyModal.propTypes = {
  vendorNameKo: PropTypes.string,
  vendorKey: PropTypes.string,
  agencyName: PropTypes.string,
  vendorName: PropTypes.string,
  status: PropTypes.string,
  vendorImg: PropTypes.string,
  vendorOrderNum: PropTypes.string,
  memo: PropTypes.string,
  vendorIsInspection: PropTypes.string,
  fetchSearchVendors: PropTypes.func.isRequired,
  isGameModifyModalShow: PropTypes.bool.isRequired,
  setGameModifyModalShow: PropTypes.func.isRequired,
}

GameModifyModal.defaultProps = {
  vendorNameKo: '',
  vendorKey: '',
  agencyName: '',
  vendorName: '',
  status: '',
  vendorImg: '',
  vendorOrderNum: '',
  memo: '',
  vendorIsInspection: 'false',
}

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 800px !important;
  }

  .modal-content {
    height: max-content;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
