import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import MacroModifyModal from './MacroModifyModal'
import { macroTypeEnums } from '../../../enums/SystemManagement/macroEnums'
import { convertLongData } from '../../../utils/editor'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MacroListData = (content, fetchSearchMacroManage) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '정렬순서',
      accessor: 'sortNum',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '분류',
      accessor: 'type',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return macroTypeEnums[original.type]
      },
    },
    {
      Header: '태그',
      accessor: 'tag',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '제목',
      accessor: 'macroTitle',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '내용',
      accessor: 'stringContent',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertLongData(original.stringContent)
      },
    },
    {
      Header: '처리',
      accessor: 'process',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <MacroModifyModal
            edgeName={original.edgeName}
            macroKey={original.macroKey}
            tag={original.tag}
            macroTitle={original.macroTitle}
            macroContent={original.macroContent}
            type={original.type}
            fetchSearchMacroManage={fetchSearchMacroManage}
            sortNum={original.sortNum}
          />
        )
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MacroListData
