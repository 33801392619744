import { all, call, fork, put, select, take, takeLatest } from 'redux-saga/effects'
import { createSocketChannel } from './createSocketChannel'
import { SocketIOEventName } from '../../enums/WebSocket/SocketIOEventName'
import {
  fetchBasicInfoAction,
  fetchHeaderAlarmInfoAction,
  fetchHeaderInfoAction,
  setAlarmInfo,
  setEdgeInfo,
  setHeaderInfo,
} from '../headerInfoSlice'
import { SocketIORequestMessageType } from '../../enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../../utils/socket'
import { setSportMenu } from '../SportInfoSlice'
import { setSportInPlayMenu } from '../SportInPlayInfoSlice'
import { setBookMakerMenu } from '../BookMakerInfoSlice'
import { setInPlayBookMakerMenu } from '../InPlayBookMakerInfoSlice'
import { deleteAccessToken, getAccessToken, setAccessToken } from '../../utils/token'
import { reAuthenticationAction, setAdminInfo } from '../InitInfoSlice'

function* onLostSessionMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.LOST_SESSION)

  while (true) {
    try {
      const message = yield take(channel)

      deleteAccessToken()
      alert('세션이 만료되어, 로그아웃됩니다.')
      window.location.href = '/login'
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* onDuplicatedSessionMessage() {
  const channel = yield call(createSocketChannel, SocketIOEventName.DUPLICATE_LOGIN)

  while (true) {
    try {
      const message = yield take(channel)

      deleteAccessToken()
      alert('다른 환경에서 로그인되어, 로그아웃됩니다.')
      window.location.href = '/login'
    } catch (e) {
      //   alert(e.message)
    }
  }
}

function* handleReAuthentication(action) {
  try {
    const { newWindow } = action.payload

    const payload = {
      type: SocketIORequestMessageType.RE_AUTHENTICATION_AD,
      payload: {
        accessToken: getAccessToken(),
        newWindow,
      },
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.CREATE, payload)

    if (res.status) {
      setAccessToken(res.data.accessToken)

      yield put(
        setAdminInfo({
          adminId: res.data.adminId,
          authority: res.data.authority,
        }),
      )
      return res.data.accessToken
    }

    deleteAccessToken()
    window.location.href = '/login'

    return null
  } catch (error) {
    deleteAccessToken()
    window.location.href = '/login'
    return null
  }
}

function* watchReAuthentication() {
  yield takeLatest(reAuthenticationAction, handleReAuthentication)
}

export default function* authSaga() {
  yield all([fork(onLostSessionMessage), fork(onDuplicatedSessionMessage), watchReAuthentication()])
}
