import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { MgmTypeEnums } from '../../../../enums/Log/miniGameBettingEnums'
import { Card, CardBody } from '../../../../shared/components/Card'
import Loading from '../../../../shared/components/Loading'
import MiniGameManagement from '../MiniGameManagement'
import MgmBaccarat1Form from './MgmBaccarat1Form'
import MgmGoStopForm from './MgmGoStopForm'
import MgmOddEvenForm from './MgmOddEvenForm'
import MgmBaccarat2Form from './MgmBaccarat2Form'
import MgmBaccarat3Form from './MgmBaccarat3Form'

const MgmManagement = () => {
  const [apiLoading, setApiLoading] = useState(false)

  const [type, setType] = useState('MGM_BACCARAT_1')
  const [name, setName] = useState('바카라1')

  const onClickTypeHandler = (type, name) => {
    setType(type)
    setName(name)
  }

  const getNtrySettingCompo = type => {
    switch (type) {
      case 'MGM_BACCARAT_1':
        return <MgmBaccarat1Form miniGameType="MGM_BACCARAT_1" />
      case 'MGM_BACCARAT_2':
        return <MgmBaccarat2Form miniGameType="MGM_BACCARAT_2" />
      case 'MGM_BACCARAT_3':
        return <MgmBaccarat3Form miniGameType="MGM_BACCARAT_3" />
      case 'MGM_ODD_EVEN':
        return <MgmOddEvenForm miniGameType="MGM_ODD_EVEN" />
      case 'MGM_GO_STOP':
        return <MgmGoStopForm miniGameType="MGM_GO_STOP" />
      default:
        return null
    }
  }

  return (
    <>
      {apiLoading && <Loading />}
      <Card>
        <CardBody>
          <MiniGameManagement activeKey="8" />

          <InfoBoxWrap>
            <p style={{ background: '#fff79e' }}>
              * <span style={{ color: 'red' }}>상태</span>를 점검으로 바꿀 시, 해당 게임은 배팅할 수 없습니다.
              <br />* <span style={{ color: 'red' }}>동일라운드</span> 배팅허용 시, 해당 게임은 같은 라운드내 2회 배팅이
              가능합니다.
              <br />* <span style={{ color: 'red' }}>마감시간</span> x초 설정 시, 해당 게임은 라운드 마감 x초 전에
              마감됩니다.
            </p>
          </InfoBoxWrap>

          <MiniGameTypeWrapper>
            {Object.entries(MgmTypeEnums).map(([key, value]) => {
              return (
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active={key === type}
                  onClick={() => {
                    onClickTypeHandler(key, value)
                  }}
                >
                  {value}
                </Button>
              )
            })}
          </MiniGameTypeWrapper>

          <LevelBoxWrap>
            <LevelBoxHeader>{name}</LevelBoxHeader>
            {getNtrySettingCompo(type)}
          </LevelBoxWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default MgmManagement

const LevelBoxWrap = styled.div`
  width: 100%;
  min-height: 300px;

  padding: 10px;

  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    margin-top: 15px;
  }

  p {
    margin-bottom: 10px;
  }
`

const LevelBoxHeader = styled.div`
  width: 100%;
  font-size: 23px;
  color: blue;
  font-weight: 900;
  line-height: 30px;
  margin-bottom: 10px;
`

const InfoBoxWrap = styled.div`
  width: 100%;
  padding: 5px 0;

  p {
    margin-bottom: 10px;
  }
`

const MiniGameTypeWrapper = styled.div`
  width: 100%;
  padding: 5px 0;
  display: flex;
  gap: 5px;
`
