import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { UrlTypeEnumsSelectOptions, domainTypeEnums, urlTypeEnums } from '../../../enums/SystemManagement/urlEnums'
import { searchUrls } from '../../../utils/api/systemManage/systemManageApi'
import SystemManagementTabs from '../SystemManagementTabs'
import UrlListData from './UrlListData'
import UrlModifyModal from './UrlModifyModal'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'

const UrlList = () => {
  const [selectedEdgeName, setSelectedEdgeName] = useState(null)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(20)

  /**
   게임사관리 조회
   */
  const [urlListparams, setUrlListParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setUrlListParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setUrlListParams(prev => ({
      ...prev,
      page: 0,
      edgeName: selectedEdgeName,
    }))
  }, [selectedEdgeName])

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const [searchStatusToggle, setSearchStatusToggle] = useState(false)

  const onChangeSearchStatusHandler = status => {
    setSearchStatusOptionValue(status)
    setSearchStatusToggle(prev => !prev)
  }

  useEffect(() => {
    setUrlListParams(prev => ({
      ...prev,
      page: 0,
      status: searchStatusOptionValue,
    }))
    setPage(0)
  }, [searchStatusToggle])

  const [content, setContent] = useState([])
  const [totalElement, setTotalElement] = useState(0)
  const fetchUrlManage = async () => {
    await searchUrls(urlListparams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }

  useEffect(() => {
    fetchUrlManage()
  }, [urlListparams])

  const [urlListData, setUrlListData] = useState(UrlListData(content, fetchUrlManage))

  useEffect(() => {
    setUrlListData(UrlListData(content, fetchUrlManage))
  }, [content])

  const [rows, setData] = useState(urlListData.tableRowsData)

  useEffect(() => {
    setData(urlListData.tableRowsData)
  }, [urlListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  return (
    <Card>
      <CardBody>
        <SystemManagementTabs activeKey="5" />

        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>미사용</span>인 도메인은 회원이 접속할 수 없습니다.
            </p>
            <p>
              *<span style={{ color: 'blue' }}>새로운 URL</span>을 원하시면, 개발자에게 요청바랍니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <div style={{ display: 'flex', gap: '10px' }}>
          <EdgeSearchButton
            edgeName={selectedEdgeName}
            onClickHandler={e => {
              setSelectedEdgeName(e.target.id || null)
            }}
          />
          <SearchStatusWrap>
            <Button
              size="sm"
              active={searchStatusOptionValue === null}
              variant="outline-secondary"
              onClick={e => {
                onChangeSearchStatusHandler(null)
              }}
            >
              전체
            </Button>
            {Object.keys(domainTypeEnums).map(status => {
              return (
                <Button
                  id={status}
                  size="sm"
                  active={searchStatusOptionValue === status}
                  variant="outline-secondary"
                  onClick={e => {
                    onChangeSearchStatusHandler(status)
                  }}
                >
                  {domainTypeEnums[status]}
                </Button>
              )
            })}
          </SearchStatusWrap>
        </div>
        <ReactTableBase columns={urlListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default UrlList

const SearchStatusWrap = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  border-left: 3px solid grey;
  padding-left: 15px;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
