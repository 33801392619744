import { Button } from '@/shared/components/Button'
import { NoteSelect } from '@/shared/components/CustomSelect'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupFile,
  FormInputWrap,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import styled from 'styled-components'
import { publishToOneMemberNote, publishToAllMemberNote } from '@/utils/api/operation/operationApi'
import { searchMacroSelect } from '@/utils/api/systemManage/systemManageApi'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { convertFromFront, convertFromServer } from '@/utils/editor'
import Error from '@/shared/components/form/Error'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import { useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import { memberNoteValidate } from '../../../../utils/validate/memberManage/partnerNoteValidate'
import Loading from '../../../../shared/components/Loading'
import { decodeAccessToken } from '../../../../utils/token'
import '../../../Operation/noteCustomStyle.css'

function MemberNoteForm({ memberId, isTotal, onClickHandler }) {
  const { edgeNameRadio } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNameRadio: headerInfo.edgeNameRadio,
    }
  })

  // console.log('dddddd', edgeNameRadio.map((item)=>{

  // }))
  const [edgeName, setEdgeName] = useState('')
  const authority = decodeAccessToken()?.adminAuthority
  const [noteLoading, setNoteLoading] = useState(false)

  // 쪽지 제목
  const [noteTitle, setNoteTitle] = useState('')
  const onChangeNoteTitle = e => {
    setNoteTitle(e.target.value)
  }

  // 쪽지 내용
  const [noteContent, setNoteContent] = useState('')

  const onChangeNodeContentHandler = editorState => {
    setNoteContent(editorState)
  }

  // 미리 등록한 쪽지 목록
  const [noteOption, setNoteOption] = useState([])
  const [noteOptionValue, setNoteOptionValue] = useState(null)
  const onChangeNoteSelectHandler = option => {
    if (option === null) {
      setNoteOptionValue(null)
      setNoteTitle('')
      setNoteContent('')
      return
    }
    setNoteOptionValue(option)
    setNoteTitle(option.label)
    setNoteContent(option.noteContent)
  }

  // 사진 첨부
  const [imageFile, setImageFile] = useState()

  useEffect(() => {
    searchMacroSelect({
      userId: memberId,
      searchType: 'NOTE',
    }).then(res => {
      res.data.content.map(item =>
        setNoteOption(prev => [
          ...prev,
          {
            value: item.macroTitle,
            label: item.macroTitle,
            noteContent: item.macroContent,
          },
        ]),
      )
    })
  }, [])

  const onSubmit = e => {
    if (noteLoading) return
    if (isTotal) {
      if (!edgeName) {
        alert('사이트를 선택해주세요.')
        return
      }

      const body = {
        edgeName,
        noteTitle,
        noteContent,
      }
      setNoteLoading(true)
      publishToAllMemberNote(body)
        .then(res => {
          setNoteLoading(false)
          alert('전체 쪽지 보내기가 완료되었습니다.')
          onClickHandler()
          window.location.href = '/management/operation/note'
        })
        .catch(error => {
          setNoteLoading(false)
          alert('쪽지 보내기가 실패했습니다. 다시 시도해주세요.')
        })
    } else {
      const body = {
        authority: 'MEMBER',
        userId: e.userId,
        noteTitle,
        noteContent,
      }
      setNoteLoading(true)
      publishToOneMemberNote(body)
        .then(res => {
          setNoteLoading(false)
          alert('쪽지 보내기가 완료되었습니다.')
          onClickHandler()
          window.location.href = '/management/operation/note'
        })
        .catch(error => {
          setNoteLoading(false)
          alert('쪽지 보내기가 실패했습니다. 다시 시도해주세요.')
        })
    }
  }

  return (
    <>
      {noteLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ marginTop: '-15px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>쪽지</span>는 매크로에 등록된 쪽지를 선택하거나 직접 입력할 수 있습니다.
            </p>
          </div>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              userId: memberId,
              selectedNote: noteOptionValue,
              noteTitle,
              edgeName,
              noteContent,
            }}
            validate={memberNoteValidate}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                {isTotal ? (
                  <FormGroup>
                    <FormGroupLabel>사이트</FormGroupLabel>
                    {edgeNameRadio.map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          onCustomChange={e => {
                            setEdgeName(e)
                          }}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={edgeName}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <FormGroupLabel>아이디</FormGroupLabel>
                    <FormGroupField>
                      <Field name="userId">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              type="text"
                              value={input.value}
                              onChange={e => {
                                input.onChange(e)
                              }}
                              disabled
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                )}
                <FormGroup>
                  <FormGroupLabel>쪽지 선택</FormGroupLabel>
                  <FormGroupField>
                    <Field name="selectedNote">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <NoteSelect
                            {...input}
                            value={input.value}
                            onChange={option => {
                              input.onChange(option)
                              onChangeNoteSelectHandler(option)
                            }}
                            options={noteOption}
                            placeholder="쪽지 선택"
                            className="react-select"
                            classNamePrefix="react-select"
                            isClearable
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>제목</FormGroupLabel>
                  <FormGroupField>
                    <Field name="noteTitle">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={noteTitle}
                            onChange={e => {
                              input.onChange(e)
                              onChangeNoteTitle(e)
                            }}
                            placeholder="제목을 작성하세요"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>내용</FormGroupLabel>
                  <CustomFormGroupField>
                    <Field name="noteContent">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <ReactQuill
                            value={noteContent}
                            onChange={value => {
                              setNoteContent(value) // questionContent 업데이트
                            }}
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: '1' }, { header: '2' }, { font: [] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ color: [] }, { background: [] }],
                              ],
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </CustomFormGroupField>
                </FormGroup>
                <FormButtonToolbar style={{ marginLeft: '0px' }}>
                  {
                    {
                      SUPER: (
                        <>
                          <Button variant="primary" type="submit">
                            쪽지 보내기
                          </Button>
                        </>
                      ),
                    }[authority]
                  }
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default MemberNoteForm

MemberNoteForm.propTypes = {
  memberId: PropTypes.string,
  isTotal: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
}

MemberNoteForm.defaultProps = {
  memberId: null,
  isTotal: false,
}

export const CustomFormGroupField = styled(FormGroupField)`
  // border: solid 1px #000;
  height: 270px;
  width: 695px !important;
  padding: 0px !important;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    opacity: 0.3;
  }
  margin-left: 20px !important;
  // padding-left: 5px !important;
  padding-top: 5px !important;
`
