import { ButtonToolbar } from '@/shared/components/Button'
import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { createBanner } from '../../../utils/api/systemManage/bannerManageApi'
import { decodeAccessToken } from '../../../utils/token'
import {
  bannerClickEnumsRadioOptions,
  bannerMoreClickEnumsRadioOptions,
  bannerPositionEnumsRadioOptions,
  bannerSizeEnums,
  bannerStatusEnumsRadioOptions,
  bannerTargetEnumsRadioOptions,
  bannerTypeEnumsRadioOptions,
} from '../../../enums/SystemManagement/bannnerEnums'
import Loading from '../../../shared/components/Loading'

const BannerCreateModal = ({ isBannerCreateModalShow, setIsBannerCreateModalShow, fetchAdminSearchBannerList }) => {
  const { edgeNameRadio, domainCheckBox } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNameRadio: headerInfo.edgeNameRadio,
      domainCheckBox: headerInfo.domainCheckBox,
    }
  })
  const authority = decodeAccessToken()?.adminAuthority

  const onClickHandler = () => {
    setIsBannerCreateModalShow(prevState => !prevState)
    fetchAdminSearchBannerList()
  }

  const [bannerName, setBannerName] = useState('')
  const [imgUrl, setImgUrl] = useState('')
  const [bannerOrderNum, setBannerOrderNum] = useState('')
  const [writeLoading, setWriteLoading] = useState(false)
  const onSubmit = e => {
    if (writeLoading) return
    if (!bannerName) {
      alert('배너명을 입력해주세요.')
      return
    }

    if (!imgUrl) {
      alert('이미지 주소를 입력해주세요.')
      return
    }

    if (e.bannerTarget === 'MOBILE' && e.bannerPositionEnums !== 'CENTER') {
      alert('모바일 배너인 경우, 위치는 중앙만 선택이 가능합니다.')
      return
    }

    const body = {
      edgeName: e.edgeName,
      bannerName,
      imgUrl,
      bannerOrderNum: !bannerOrderNum ? null : bannerOrderNum,
      bannerTarget: e.bannerTarget,
      bannerStatus: e.bannerStatus,
      bannerPosition: e.bannerPosition,
      bannerClick: e.bannerClick,
      bannerType: e.bannerType,
      moreClickShow: e.moreClickShow,
    }
    setWriteLoading(true)
    createBanner(body)
      .then(res => {
        alert('생성 되었습니다.')
        onClickHandler()
        setWriteLoading(false)
      })
      .catch(err => {
        alert('생성 실패했습니다.')
        onClickHandler()
        setWriteLoading(false)
      })
  }

  return (
    <StyledModal show={isBannerCreateModalShow} onHide={onClickHandler} $header>
      <ModalHeader>
        <ModalCloseButton className="lnr lnr-cross" aria-label="close-btn" type="button" onClick={onClickHandler} />
        <ModalTitle>배너 추가</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>* 배너 순서를 입력하지 않으면 마지막으로 설정됩니다.</p>
            <p>
              * 배너 크기 :{' '}
              <span style={{ fontWeight: '600', color: 'red' }}>
                {Object.entries(bannerSizeEnums)
                  .filter(([key]) => key.includes('DEMO4'))
                  .map(([key, value]) => value)
                  .join(', ')}
              </span>
            </p>
          </div>
        </SearchCardTitleWrap>
        {writeLoading ? (
          <Loading />
        ) : (
          <>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { reset } }) => (
                <FormContainer
                  horizontal
                  onSubmit={handleSubmit}
                  initialValue={{
                    imgUrl,
                    bannerName,
                    bannerOrderNum,
                  }}
                >
                  <FormGroup>
                    <FormGroupLabel>사이트명</FormGroupLabel>
                    {edgeNameRadio.map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>배너명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bannerName">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              style={{ border: 'solid 1px #ddd' }}
                              type="text"
                              value={bannerName}
                              onChange={e => {
                                setBannerName(e.target.value)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>이미지 주소</FormGroupLabel>
                    <FormGroupField>
                      <Field name="imgUrl">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              {...input}
                              style={{ border: 'solid 1px #ddd' }}
                              type="text"
                              value={imgUrl}
                              onChange={e => {
                                setImgUrl(e.target.value)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>순서</FormGroupLabel>
                    <FormGroupField>
                      <Field name="bannerOrderNum">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <input
                              style={{ border: 'solid 1px #ddd' }}
                              {...input}
                              type="text"
                              value={bannerOrderNum}
                              onChange={e => {
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')

                                setBannerOrderNum(onlyNumber)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>노출</FormGroupLabel>
                    {bannerStatusEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          ㄴㅅ
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>설정 범위</FormGroupLabel>
                    {bannerTargetEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>배너 위치</FormGroupLabel>
                    {bannerPositionEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>클릭시 이동유무</FormGroupLabel>
                    {bannerClickEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>클릭시 이동위치</FormGroupLabel>
                    {bannerTypeEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>더보기 버튼유무</FormGroupLabel>
                    {bannerMoreClickEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={item.initialValue}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                  {
                    {
                      SUPER: (
                        <FormButtonToolbar>
                          <Button variant="primary" type="submit">
                            추가하기
                          </Button>
                        </FormButtonToolbar>
                      ),
                    }[authority]
                  }
                </FormContainer>
              )}
            </Form>
          </>
        )}
      </ModalBody>
    </StyledModal>
  )
}

export default BannerCreateModal

BannerCreateModal.propTypes = {
  isBannerCreateModalShow: PropTypes.bool.isRequired,
  setIsBannerCreateModalShow: PropTypes.func.isRequired,
  fetchAdminSearchBannerList: PropTypes.func.isRequired,
}

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 800px !important;
  }

  .modal-content {
    height: max-content;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
