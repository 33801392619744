import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '@/shared/components/Tabs'
import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import MiniGameManagementTabs from '../../MiniGameManagement/MiniGameManagementTabs'

const MiniGameManagement = ({ activeKey }) => {
  const history = useHistory()

  return (
    <>
      <MiniGameManagementTabs activeKey="9" />
      <BorderedBottomTabs>
        <Tab.Container activeKey={activeKey}>
          <TabsWrap>
            <Nav className="nav-tabs">
              <NavItem>
                <NavLink
                  eventKey="1"
                  onClick={e => {
                    history.push('/management/system/minigame-ntry')
                  }}
                >
                  엔트리
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="2"
                  onClick={e => {
                    history.push('/management/system/minigame-boscore')
                  }}
                >
                  보스코어
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="3"
                  onClick={e => {
                    history.push('/management/system/minigame-named')
                  }}
                >
                  네임드
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="4"
                  onClick={e => {
                    history.push('/management/system/minigame-sureman')
                  }}
                >
                  슈어맨
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="5"
                  onClick={e => {
                    history.push('/management/system/minigame-gopick')
                  }}
                >
                  고픽
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="6"
                  onClick={e => {
                    history.push('/management/system/minigame-token')
                  }}
                >
                  토큰게임
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="7"
                  onClick={e => {
                    history.push('/management/system/minigame-crown')
                  }}
                >
                  크라운
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="8"
                  onClick={e => {
                    history.push('/management/system/minigame-mgm')
                  }}
                >
                  MGM
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="9"
                  onClick={e => {
                    history.push('/management/system/minigame-lotus')
                  }}
                >
                  로투스
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="10"
                  onClick={e => {
                    history.push('/management/system/minigame-donghang')
                  }}
                >
                  동행
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="11"
                  onClick={e => {
                    history.push('/management/system/minigame-bepick')
                  }}
                >
                  베픽
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  eventKey="12"
                  onClick={e => {
                    history.push('/management/system/minigame-next')
                  }}
                >
                  넥스트
                </NavLink>
              </NavItem>
            </Nav>
          </TabsWrap>
        </Tab.Container>
      </BorderedBottomTabs>
    </>
  )
}

export default MiniGameManagement

MiniGameManagement.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
