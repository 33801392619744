import { commonReg7, commonReg10, commonReg8, commonReg11, commonReg12 } from '../commonValidate'

export const memberInfoValidate = values => {
  const errors = {}

  // 연락처 = 번호만
  // if (!values.phoneNum || commonReg7.test(values.phoneNum)) {
  //   errors.phoneNum = '숫자만 입력 가능합니다.'
  // }

  // 계좌번호 - 번호만
  // if (!values.accountNum || commonReg7.test(values.accountNum)) {
  //   errors.accountNum = '숫자만 입력 가능합니다.'
  // }

  // 인증번호 - 인증번호 전송했을 때만 체크
  // if (values.isConfirmPhoneNum && !values.confirmPhoneNum) {
  //   errors.confirmPhoneNum = '필수 입력 항목입니다.'
  // }

  return errors
}

export const memberRollingInfoValidate = values => {
  const errors = {}

  if (!values.lineSlotRollingPer && (!values.lineSlotRollingPer || !commonReg8.test(values.lineSlotRollingPer))) {
    errors.lineSlotRollingPer = '100%미만, 소숫점 첫째짜리까지 가능.'
  }

  if (!values.lineLiveRollingPer && (!values.lineLiveRollingPer || !commonReg8.test(values.lineLiveRollingPer))) {
    errors.lineLiveRollingPer = '100%미만, 소숫점 첫째짜리까지 가능.'
  }
  return errors
}

export const memberZeroRollingInfoValidate = values => {
  const errors = {}

  if (!values.zeroSlotRollingPer && (!values.zeroSlotRollingPer || !commonReg11.test(values.zeroSlotRollingPer))) {
    errors.zeroSlotRollingPer = '50%이하, 0 ~ 50까지의 정수만 가능.'
  }

  if (!values.zeroLiveRollingPer && (!values.zeroLiveRollingPer || !commonReg11.test(values.zeroLiveRollingPer))) {
    errors.zeroLiveRollingPer = '50%이하, 0 ~ 50까지의 정수만 가능.'
  }

  return errors
}

export const memberBackRollingInfoValidate = values => {
  const errors = {}

  if (!values.recoveryPer || commonReg7.test(values.recoveryPer)) {
    errors.recoveryPer = '숫자만 입력 가능합니다.'
  }

  if (!values.recoveryPer || !commonReg12.test(values.recoveryPer)) {
    errors.recoveryPer = '50 ~ 95까지의 정수만 가능.'
  }
  return errors
}
