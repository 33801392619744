import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
// import ReactTableBase from '@/shared/components/table/ReactTableBase'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import { useSelector } from 'react-redux'
import { MoneyIncDecTypeEnumsSelectOptions } from '../../../enums/Log/moneyIncDecEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { downloadAssetIncDec, searchAssetIncDec } from '../../../utils/api/logManage/logApi'
import { convertToKst } from '../../../utils/dateTime'
import LogPageTabs from '../LogPageTabs'
import MoneyIncDecListData from './MoneyIncDecListData'
import Loading from '../../../shared/components/Loading'

const MoneyIncDecList = () => {
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)

  /**
   머니증차감로그 조회
   */
  const [moneyIncDecparams, setMoneyIncDecParams] = useState({
    page,
    size,
    isExact: false,
    edgeName: null,
    userAuthority: null,
    userId: null,
    assetType: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page: 0,
      userAuthority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchAssetIncDec = async () => {
    await searchAssetIncDec(moneyIncDecparams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchSearchAssetIncDec()
  }, [moneyIncDecparams])

  const [moneyIncDecListData, setMoneyIncDecListData] = useState(MoneyIncDecListData(content))
  useEffect(() => {
    setMoneyIncDecListData(MoneyIncDecListData(content))
  }, [content])

  const [rows, setData] = useState(moneyIncDecListData.tableRowsData)
  useEffect(() => {
    setData(moneyIncDecListData.tableRowsData)
  }, [moneyIncDecListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 회원ID
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 행위자ID
  const [searchExecuteUserId, setSearchExecuteUserId] = useState('')

  const onSearchExecuteUserIdHandler = e => {
    setSearchExecuteUserId(e.target.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 답변
  const [searchMoneyType, setSearchMoneyType] = useState('')

  const [searchMoneyTypeOptionValue, setSearchMoneyTypeOptionValue] = useState(null)
  const searchMoneyTypeOption = MoneyIncDecTypeEnumsSelectOptions()

  const onSelectMoneyTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyTypeOptionValue(null)
      setSearchMoneyType(null)
      return
    }
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyType(option.value)
  }

  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      assetType: !searchMoneyType ? null : searchMoneyType,
      userId: !searchUserId ? null : searchUserId.trim(),
      executeUserId: !searchExecuteUserId ? null : searchExecuteUserId.trim(),
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    executeId: '행위자 ID',
    userId: '유저ID',
    userName: '유저명',
    authority: '권한',
    assetType: '자산 종류',
    incDecType: '증감 구분',
    beforeAmount: '변경전 자산액',
    changeAmount: '변경 자산액',
    afterAmount: '변경후 자산액',
    incDecReason: '메모',
    executeDate: '처리일',
  }
  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadAssetIncDecListHandler = () => {
    if (downloadLoading) return
    if (
      window.confirm(
        '자산증감 DB를 csv파일로 다운 받으시겠습니까? \n 유저ID 및 날짜를 입력하지 않고 다운받을 경우 느릴 수 있습니다.',
      )
    ) {
      setDownloadLoading(true)
      downloadAssetIncDec({
        edgeName: !searchEdgeName ? null : searchEdgeName,
        assetType: !searchMoneyType ? null : searchMoneyType,
        userAuthority: !userAuthority ? null : userAuthority,
        userId: !searchUserId ? null : searchUserId.trim(),
        executeUserId: !searchExecuteUserId ? null : searchExecuteUserId.trim(),
        startDate: !searchStartDate ? null : convertToKst(searchStartDate),
        endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '자산증감 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  // Tab Key
  const [activeKey, setActiveKey] = useState('2')
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <LogPageTabs activeKey={activeKey} />
          {/* 검색 기능 */}
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
              <p>
                * 해당 페이지는 관리자 및 파트너가 <span style={{ color: 'red', fontWeight: 'bolder' }}>수동</span>으로
                회원 및 파트너의 자산을 증차감한 내역을 확인할 수 있습니다.
              </p>
            </div>
          </SearchCardTitleWrap>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>사이트</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchEdgeName"
                  onChange={option => {
                    onSelectEdgeNameOptionHandler(option)
                  }}
                  placeholder="전체"
                  value={searchEdgeNameOptionValue}
                  options={searchEdgeNameOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>행위자ID</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchExecuteUserId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchExecuteUserId}
                    onChange={e => onSearchExecuteUserIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchUserId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchUserId}
                    onChange={e => onSearchUserIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>자산 종류</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchMoneyType"
                  onChange={option => {
                    onSelectMoneyTypeOptionHandler(option)
                  }}
                  placeholder="종류"
                  value={searchMoneyTypeOptionValue}
                  options={searchMoneyTypeOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
              <Button
                size="sm"
                variant="outline-secondary"
                style={{ background: 'green', color: 'white' }}
                onClick={e => {
                  downloadAssetIncDecListHandler()
                }}
              >
                자산증감 DB 다운
              </Button>
            </SearchButtonWrap>
          </SearchContainer>
          <ReactTableBase columns={moneyIncDecListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default MoneyIncDecList
