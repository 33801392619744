import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { FriendCompSettlementStatusEnumsSelectOptions } from '../../enums/SystemManagement/siteEnums'
import Loading from '../../shared/components/Loading'
import {
  deleteFriendCompHistory,
  searchFriendCompHistory,
  updateFriendCompHistory,
} from '../../utils/api/friendComp/friendCompApi'
import { convertToKst } from '../../utils/dateTime'
import { decodeAccessToken } from '../../utils/token'
import PayBackTabs from '../PaybackHistory/PayBackTabs'
import FriendCompListData from './FriendCompListData'

const FriendCompList = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  // 게시판 수정 모달
  const [isBoardModifyModalShow, setBoardModifyModalShow] = useState(false)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  /**
   게시판 조회
   */
  const [params, setParams] = useState({
    edgeName: null,
    page,
    size,
    userId: null,
    friendCompSettlementStatus: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchFriendCompList = async () => {
    await searchFriendCompHistory(params).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }

  useEffect(() => {
    fetchSearchFriendCompList()
  }, [params])

  const [checkRadio, setCheckRadio] = useState([])
  const [friendCompListData, setFriendCompListData] = useState(FriendCompListData(content, checkRadio, setCheckRadio))
  useEffect(() => {
    setFriendCompListData(FriendCompListData(content, checkRadio, setCheckRadio))
  }, [content, checkRadio])

  const [rows, setData] = useState(friendCompListData.tableRowsData)
  useEffect(() => {
    setData(friendCompListData.tableRowsData)
  }, [friendCompListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }
  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = FriendCompSettlementStatusEnumsSelectOptions()

  const onSelectStatusOptionHandler = option => {
    setSearchStatusOptionValue(option)
    setSearchStatus(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      userId: !searchMemberId ? null : searchMemberId.trim(),
      friendCompSettlementStatus: !searchStatus ? null : searchStatus,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  const [deleteLoading, setDeleteLoading] = useState(false)

  // 정산하기 버튼
  const onSettlementHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('정산 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 정산하시겠습니까?')) {
      setDeleteLoading(true)
      updateFriendCompHistory({
        friendCompHistoryKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('정산이 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchFriendCompList()
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          switch (errorCode) {
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('정산이 실패했습니다.')
          }
          setDeleteLoading(false)
          setCheckRadio([])
          fetchSearchFriendCompList()
        })
    }
  }

  // 정산하기 버튼
  const onDeleteHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteFriendCompHistory({
        friendCompHistoryKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchFriendCompList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchFriendCompList()
        })
    }
  }

  return (
    <Card>
      <CardBody>
        {/* 검색 기능 */}
        <PayBackTabs activeKey="2" />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-50px', marginBottom: '10px' }}>
            <p>
              * 추천인 정산은 <span style={{ color: 'red' }}>추천인 레벨</span>기준으로 처리됩니다. <br />*{' '}
              <span style={{ color: 'red' }}>배팅액 기준:</span> 기간내 총 배팅금 ,{' '}
              <span style={{ color: 'red' }}>낙첨액 기준:</span> 기간내 낙첨처리된 총 배팅금
            </p>
            <p>
              * <span style={{ color: 'red' }}>정산하기</span>를 클릭하면 선택된 것에 대해 정산이 시작됩니다.
            </p>
            <p>
              * 추천인 이벤트 설정은 <span style={{ color: 'red' }}>시스템관리-레벨관리</span>에서 설정 하실 수
              있습니다.
            </p>
            <p>
              * 카지노의 경우 <span style={{ color: 'red' }}>무효, 취소</span>는 정산에 제외됩니다.
            </p>
            <p>
              * 스포츠의 경우 정산시점 <span style={{ color: 'red' }}>진행중인 배팅건은</span> 정산에 제외됩니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>추천인 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>정산 상태</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchStatus"
                placeholder="상태"
                onChange={option => {
                  onSelectStatusOptionHandler(option)
                }}
                value={searchStatusOptionValue}
                options={searchStatusOption}
                className="react-select"
                classNamePrefix="react-select"
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          {deleteLoading ? (
            <>
              <Loading />
            </>
          ) : (
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'lightGreen' }}
                variant="outline-secondary"
                onClick={() => {
                  setCheckRadio(content.map(value => value.friendCompSettlementHistoryKey))
                }}
              >
                전체선택
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'red', color: '#fff' }}
                variant="outline-secondary"
                onClick={() => {
                  onSettlementHandler()
                }}
              >
                정산하기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'blue', color: '#fff' }}
                variant="outline-secondary"
                onClick={() => {
                  onDeleteHandler()
                }}
              >
                삭제하기
              </Button>
            </SearchButtonWrap>
          )}
        </SearchContainer>
        <ReactTableBase columns={friendCompListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default FriendCompList
