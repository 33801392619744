import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import { ExportToCsv } from 'export-to-csv'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {
  MemberDepositStatusEnums,
  MemberDepositStatusEnumsSelectOptions,
} from '../../../../../enums/MemberHistory/MemberDepositEnums'
import Loading from '../../../../../shared/components/Loading'
import MoneySortSearchButton from '../../../../../shared/components/MoneySortSearchButton'
import {
  searchMemberMoneyDepositV2,
  updateMemberMoneyDepositStatus,
} from '../../../../../utils/api/memberHistory/memberHistoryApi'
import { downloadMemberDepositList } from '../../../../../utils/api/memberManage/memberManageApi'
import { decodeAccessToken } from '../../../../../utils/token'
import DepositListDataV2 from '../../../../MemberHistory/DepositHistory/DepositListDataV2'

const MemberHistoryMoenyDeposit = ({ memberId }) => {
  const [depositLoading, setDepositLoading] = useState(false)

  const authority = decodeAccessToken()?.adminAuthority
  const [isCheckDepositApprove, setCheckDepositApprove] = useState('')

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  /**
   *  회원 입금내역 조회
   */

  const [params, setParams] = useState({
    memberId,
    status: null,
    couponUse: null,
    startDate: null,
    endDate: null,
    page,
    size,
    isExact: true,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [moneySort, setMoneySort] = useState(null)

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      moneySort: !moneySort ? null : moneySort,
    }))
  }, [moneySort])

  const [content, setContent] = useState([])
  const fetchSearchMemberMoneyDeposit = async () => {
    if (loading) return

    setLoading(true)
    await searchMemberMoneyDepositV2(params)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)

        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyDeposit()
  }, [params])

  const [depositListData, setDepositListData] = useState(DepositListDataV2(setCheckDepositApprove, content))
  useEffect(() => {
    setDepositListData(DepositListDataV2(setCheckDepositApprove, content))
  }, [content])

  const [rows, setData] = useState(depositListData.tableRowsData)
  useEffect(() => {
    setData(depositListData.tableRowsData)
  }, [depositListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = MemberDepositStatusEnumsSelectOptions()

  const onSelectOptionHandler = option => {
    if (option === null) {
      setSearchStatusOptionValue(null)
      setSearchStatus(null)
      return
    }
    setSearchStatusOptionValue(option)
    setSearchStatus(option.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      memberId,
      status: !searchStatus ? null : searchStatus,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }

  // 대기 버튼
  const onWaitingDepositHandler = () => {
    if (depositLoading) return
    switch (MemberDepositStatusEnums[isCheckDepositApprove.status]) {
      case '신청':
        if (window.confirm(`${isCheckDepositApprove.memberId}님의 입금을 대기 처리 하시겠습니까?`)) {
          setDepositLoading(true)
          updateMemberMoneyDepositStatus({
            moneyDepositKey: isCheckDepositApprove.moneyDepositKey,
            memberId: isCheckDepositApprove.memberId,
            status: 'WAITING',
          })
            .then(res => {
              setDepositLoading(false)
              setCheckDepositApprove('')
              fetchSearchMemberMoneyDeposit()
              alert(`${isCheckDepositApprove.memberId}님의 입금이 대기 처리 되었습니다.`)
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                case 'MONEY-2003':
                  alert('해당 입금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
                  break
                default:
                  alert(`${isCheckDepositApprove.memberId}님의 입금이 대기 처리가 실패했습니다.`)
              }
              setDepositLoading(false)
              setCheckDepositApprove('')
            })
        }
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 건은 신청상태가 아니므로 대기처리가 불가합니다.')
        break
    }
  }
  const [infoModal, setInfoModal] = useState(false)

  // 승인 버튼
  const onApproveDepositHandler = () => {
    if (depositLoading) return
    switch (MemberDepositStatusEnums[isCheckDepositApprove.status]) {
      case '대기':
        if (window.confirm(`${isCheckDepositApprove.memberId}님의 입금을 승인처리 하시겠습니까?`)) {
          setDepositLoading(true)
          updateMemberMoneyDepositStatus({
            moneyDepositKey: isCheckDepositApprove.moneyDepositKey,
            memberId: isCheckDepositApprove.memberId,
            status: 'APPROVE',
          })
            .then(res => {
              setDepositLoading(false)
              setCheckDepositApprove('')
              alert(`${isCheckDepositApprove.memberId}님의 입금이 완료 처리 되었습니다.`)
              window.location.reload()
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                case 'MONEY-2003':
                  alert('해당 입금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
                  break
                default:
                  alert(`${isCheckDepositApprove.memberId}님의 입금이 처리가 실패했습니다.`)
              }
              setDepositLoading(false)
              setCheckDepositApprove('')
            })
        }
        break
      case '신청':
        if (window.confirm(`${isCheckDepositApprove.memberId}님의 입금을 승인처리 하시겠습니까?`)) {
          setDepositLoading(true)
          updateMemberMoneyDepositStatus({
            moneyDepositKey: isCheckDepositApprove.moneyDepositKey,
            memberId: isCheckDepositApprove.memberId,
            status: 'APPROVE',
          })
            .then(res => {
              setDepositLoading(false)
              setCheckDepositApprove('')
              alert(`${isCheckDepositApprove.memberId}님의 입금이 완료 처리 되었습니다.`)
              window.location.reload()
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                case 'MONEY-2003':
                  alert('해당 입금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
                  break
                default:
                  alert(`${isCheckDepositApprove.memberId}님의 입금이 처리가 실패했습니다.`)
              }
              setDepositLoading(false)
              setCheckDepositApprove('')
            })
        }
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 건은 신청 및 대기상태가 아니므로 승인처리가 불가합니다.')
        break
    }
  }

  // 취소 버튼
  const onCancelDepositHandler = () => {
    if (depositLoading) return
    switch (MemberDepositStatusEnums[isCheckDepositApprove.status]) {
      case '대기':
        if (window.confirm(`${isCheckDepositApprove.memberId}님의 입금을 취소처리 하시겠습니까?`)) {
          setDepositLoading(true)
          updateMemberMoneyDepositStatus({
            moneyDepositKey: isCheckDepositApprove.moneyDepositKey,
            couponUseKey: !isCheckDepositApprove.couponUseKey ? null : isCheckDepositApprove.couponUseKey,
            memberId: isCheckDepositApprove.memberId,
            status: 'CANCEL',
          })
            .then(res => {
              setDepositLoading(false)
              setCheckDepositApprove('')
              fetchSearchMemberMoneyDeposit()
              alert(`${isCheckDepositApprove.memberId}님의 입금이 취소 처리 되었습니다.`)
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                case 'MONEY-2003':
                  alert('해당 입금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
                  break
                default:
                  alert(`${isCheckDepositApprove.memberId}님의 입금이 처리가 실패했습니다.`)
              }
              setDepositLoading(false)
              setCheckDepositApprove('')
            })
        }
        break
      case '신청':
        if (window.confirm(`${isCheckDepositApprove.memberId}님의 입금을 취소처리 하시겠습니까?`)) {
          setDepositLoading(true)
          updateMemberMoneyDepositStatus({
            moneyDepositKey: isCheckDepositApprove.moneyDepositKey,
            couponUseKey: !isCheckDepositApprove.couponUseKey ? null : isCheckDepositApprove.couponUseKey,
            memberId: isCheckDepositApprove.memberId,
            status: 'CANCEL',
          })
            .then(res => {
              setDepositLoading(false)
              setCheckDepositApprove('')
              fetchSearchMemberMoneyDeposit()
              alert(`${isCheckDepositApprove.memberId}님의 입금이 취소 처리 되었습니다.`)
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                case 'MONEY-2003':
                  alert('해당 입금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
                  break
                default:
                  alert(`${isCheckDepositApprove.memberId}님의 입금이 처리가 실패했습니다.`)
              }
              setDepositLoading(false)
              setCheckDepositApprove('')
            })
        }
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 건은 신청 및 대기상태가 아니므로 취소처리가 불가합니다.')
        break
    }
  }

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    bonusTypeKr: '보너스타입',
    userPhoneNum: '핸드폰',
    userBankName: '은행명',
    userAccountNum: '계좌번호',
    authority: '권한',
    depositType: '첫충/매충/돌발',
    memberId: '유저ID',
    memberName: '유저명',
    bonusMoney: '보너스액',
    beforeMoney: '신청전 지갑머니',
    beforeCasinoMoney: '신청전 게임머니',
    applyMoney: '신청액',
    paymentMoney: '지급액',
    applyDate: '승인일',
    executeDate: '승인일',
    status: '상태',
    memo: '메모',
  }

  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (window.confirm('입금내역 DB를 csv파일로 다운 받으시겠습니까?')) {
      setDownloadLoading(true)
      downloadMemberDepositList({ memberId })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '입금 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  return (
    <>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
          <p>
            * 입금신청한 회원의 상태값은 <span style={{ fontWeight: 'bolder', color: 'red' }}>&quot;신청&ldquo;</span>
            이며 해당건을&nbsp;
            <span style={{ fontWeight: 'bolder', color: 'blue' }}>&quot;대기 , 승인 , 취소 &ldquo;</span>
            &nbsp;를 &nbsp; <span style={{ fontWeight: 'bolder', color: 'black' }}>&quot;1건&ldquo;</span>별 처리할 수
            있습니다.
          </p>
          <p>* 승인 처리시 머니입금이 지급액 기준으로 자동으로 이루어지기 때문에 주의하여 사용해주세요.</p>
        </div>
      </SearchCardTitleWrap>
      <SearchContainer horizontal>
        <SearchGroup>
          <MoneySortSearchButton
            moneySort={moneySort}
            onClickHandler={e => {
              setMoneySort(e.target.id)
            }}
          />
          <SearchGroupLabel style={{ marginLeft: '10px' }}>상태</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchType"
              onChange={option => {
                onSelectOptionHandler(option)
              }}
              value={searchStatusOptionValue}
              options={searchStatusOption}
              placeholder="상태"
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteStartDate}
                onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteEndDate}
                onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
          <Button
            size="sm"
            variant="outline-secondary"
            style={{ background: 'green', color: 'white' }}
            onClick={e => {
              downloadMemberListHandler()
            }}
          >
            입금내역 DB 다운
          </Button>
        </SearchButtonWrap>
      </SearchContainer>

      {depositLoading ? (
        <Loading />
      ) : (
        <>
          <ReactTableBase
            key="common"
            columns={depositListData.tableHeaderData}
            data={rows}
            tableConfig={tableConfig}
          />
          {
            {
              SUPER: (
                <>
                  <SearchContainer horizontal>
                    <SearchButtonWrap>
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        style={{ backgroundColor: 'green', color: 'white' }}
                        onClick={() => {
                          onWaitingDepositHandler()
                        }}
                      >
                        입금대기
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        style={{ backgroundColor: 'blue', color: 'white' }}
                        onClick={() => {
                          onApproveDepositHandler()
                        }}
                      >
                        입금승인
                      </Button>
                      <Button
                        size="sm"
                        variant="outline-secondary"
                        style={{ backgroundColor: 'red', color: 'white' }}
                        onClick={() => {
                          onCancelDepositHandler()
                        }}
                      >
                        입금취소
                      </Button>
                    </SearchButtonWrap>
                  </SearchContainer>
                </>
              ),
            }[authority]
          }
        </>
      )}
    </>
  )
}

export default MemberHistoryMoenyDeposit

MemberHistoryMoenyDeposit.propTypes = {
  memberId: PropTypes.string.isRequired,
}
