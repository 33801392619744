import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { Button } from 'react-bootstrap'
import { ko } from 'date-fns/esm/locale'
import DatePicker from 'react-datepicker'
import MemberStatisticsBettingLiveCasino from './MemberStatisticsBettingLiveCasino'
import MemberStatisticsBettingMini from './MemberStatisticsBettingMini'
import MemberStatisticsBettingSlotCasino from './MemberStatisticsBettingSlotCasino'
import { searchMemberBettingStatistics } from '../../../../../utils/api/logManage/logApi'
import { convertToKst } from '../../../../../utils/dateTime'
import MemberStatisticsBettingSports from './MemberStatisticsBettingSports'
import { searchMemberMoneyWithdrawalInfo } from '../../../../../utils/api/memberHistory/memberHistoryApi'
import MemberStatisticsBettingHoldem from './MemberStatisticsBettingHoldem'
import MemberStatisticsBettingVirtualGame from './MemberStatisticsBettingVirtualGame'

const MemberStatisticsBetting = ({ memberId }) => {
  const today = new Date()
  const endDate = new Date()
  const startDate = new Date()

  endDate.setDate(today.getDate() + 1)
  endDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정
  startDate.setMonth(endDate.getMonth() - 1)
  startDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정

  const [bettingStatisticsParams, setBettingStatisticsParams] = useState({
    memberId,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
  })

  const [liveCasino, setLiveCasino] = useState({})
  const [slotCasino, setSlotCasino] = useState({})
  const [miniGame, setMiniGame] = useState({})
  const [sportsGame, setSportsGame] = useState({})
  const [holdemGame, setHoldemGame] = useState({})
  const [virtualGame, setVirtualGame] = useState({})

  useEffect(() => {
    searchMemberMoneyWithdrawalInfo(bettingStatisticsParams)
      .then(res => {
        const dailyBettingModel = res.data.dailyBettingWinningModel
        const monthBettingModel = res.data.monthBettingWinningModel
        const periodBettingModel = res.data.dateBettingWinningModel

        setLiveCasino({
          dailyLiveCasinoBettingAmount: dailyBettingModel.dailyLiveCasinoBettingAmount,
          dailyLiveCasinoWinningAmount: dailyBettingModel.dailyLiveCasinoWinningAmount,
          dailyLiveCasinoRollingAmount: dailyBettingModel.dailyLiveCasinoRollingAmount,
          monthLiveCasinoBettingAmount: monthBettingModel.monthLiveCasinoBettingAmount,
          monthLiveCasinoWinningAmount: monthBettingModel.monthLiveCasinoWinningAmount,
          monthLiveCasinoRollingAmount: monthBettingModel.monthLiveCasinoRollingAmount,
          dateLiveCasinoBettingAmount: periodBettingModel.dateLiveCasinoBettingAmount,
          dateLiveCasinoWinningAmount: periodBettingModel.dateLiveCasinoWinningAmount,
          dateLiveCasinoRollingAmount: periodBettingModel.dateLiveCasinoRollingAmount,
        })
        setSlotCasino({
          dailySlotCasinoBettingAmount: dailyBettingModel.dailySlotCasinoBettingAmount,
          dailySlotCasinoWinningAmount: dailyBettingModel.dailySlotCasinoWinningAmount,
          dailySlotCasinoRollingAmount: dailyBettingModel.dailySlotCasinoRollingAmount,
          monthSlotCasinoBettingAmount: monthBettingModel.monthSlotCasinoBettingAmount,
          monthSlotCasinoWinningAmount: monthBettingModel.monthSlotCasinoWinningAmount,
          monthSlotCasinoRollingAmount: monthBettingModel.monthSlotCasinoRollingAmount,
          dateSlotCasinoBettingAmount: periodBettingModel.dateSlotCasinoBettingAmount,
          dateSlotCasinoWinningAmount: periodBettingModel.dateSlotCasinoWinningAmount,
          dateSlotCasinoRollingAmount: periodBettingModel.dateSlotCasinoRollingAmount,
        })
        setMiniGame({
          dailyMiniGameBettingAmount: dailyBettingModel.dailyMiniGameBettingAmount,
          dailyMiniGameWinningAmount: dailyBettingModel.dailyMiniGameWinningAmount,
          dailyMiniGameRollingAmount: dailyBettingModel.dailyMiniGameRollingAmount,
          monthMiniGameBettingAmount: monthBettingModel.monthMiniGameBettingAmount,
          monthMiniGameWinningAmount: monthBettingModel.monthMiniGameWinningAmount,
          monthMiniGameRollingAmount: monthBettingModel.monthMiniGameRollingAmount,
          dateMiniGameBettingAmount: periodBettingModel.dateMiniGameBettingAmount,
          dateMiniGameWinningAmount: periodBettingModel.dateMiniGameWinningAmount,
          dateMiniGameRollingAmount: periodBettingModel.dateMiniGameRollingAmount,
        })
        setSportsGame({
          dailySportsBettingAmount: dailyBettingModel.dailySportsBettingAmount,
          dailySportsWinningAmount: dailyBettingModel.dailySportsWinningAmount,
          dailySportsRollingAmount: dailyBettingModel.dailySportsRollingAmount,
          monthSportsBettingAmount: monthBettingModel.monthSportsBettingAmount,
          monthSportsWinningAmount: monthBettingModel.monthSportsWinningAmount,
          monthSportsRollingAmount: monthBettingModel.monthSportsRollingAmount,
          dateSportsBettingAmount: periodBettingModel.dateSportsBettingAmount,
          dateSportsWinningAmount: periodBettingModel.dateSportsWinningAmount,
          dateSportsRollingAmount: periodBettingModel.dateSportsRollingAmount,
        })
        setHoldemGame({
          dailyHoldemBettingAmount: dailyBettingModel.dailyHoldemBettingAmount,
          dailyHoldemWinningAmount: dailyBettingModel.dailyHoldemWinningAmount,
          dailyHoldemRollingAmount: dailyBettingModel.dailyHoldemRollingAmount,
          monthHoldemBettingAmount: monthBettingModel.monthHoldemBettingAmount,
          monthHoldemWinningAmount: monthBettingModel.monthHoldemWinningAmount,
          monthHoldemRollingAmount: monthBettingModel.monthHoldemRollingAmount,
          dateHoldemBettingAmount: periodBettingModel.dateHoldemBettingAmount,
          dateHoldemWinningAmount: periodBettingModel.dateHoldemWinningAmount,
          dateHoldemRollingAmount: periodBettingModel.dateHoldemRollingAmount,
        })
        setVirtualGame({
          dailyVirtualGameBettingAmount: dailyBettingModel.dailyVirtualGameBettingAmount,
          dailyVirtualGameWinningAmount: dailyBettingModel.dailyVirtualGameWinningAmount,
          dailyVirtualGameRollingAmount: dailyBettingModel.dailyVirtualGameRollingAmount,
          monthVirtualGameBettingAmount: monthBettingModel.monthVirtualGameBettingAmount,
          monthVirtualGameWinningAmount: monthBettingModel.monthVirtualGameWinningAmount,
          monthVirtualGameRollingAmount: monthBettingModel.monthVirtualGameRollingAmount,
          dateVirtualGameBettingAmount: periodBettingModel.dateVirtualGameBettingAmount,
          dateVirtualGameWinningAmount: periodBettingModel.dateVirtualGameWinningAmount,
          dateVirtualGameRollingAmount: periodBettingModel.dateVirtualGameRollingAmount,
        })
      })
      .catch(error => {})
  }, [bettingStatisticsParams])

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate)
  const [searchEndDate, setSearchEndDate] = useState(endDate)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingStatisticsParams(prev => ({
      ...prev,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  return (
    <>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>카지노 배팅통계</h3>
      <MemberStatisticsBettingLiveCasino liveCasino={liveCasino} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>슬롯 배팅통계</h3>
      <MemberStatisticsBettingSlotCasino slotCasino={slotCasino} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>미니게임 배팅통계</h3>
      <MemberStatisticsBettingMini miniGame={miniGame} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>스포츠 배팅통계</h3>
      <MemberStatisticsBettingSports sportsGame={sportsGame} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>가상게임 배팅통계</h3>
      <MemberStatisticsBettingVirtualGame virtualGame={virtualGame} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>홀덤 배팅통계</h3>
      <MemberStatisticsBettingHoldem holdemGame={holdemGame} />
    </>
  )
}

export default MemberStatisticsBetting

MemberStatisticsBetting.propTypes = {
  memberId: PropTypes.string.isRequired,
}
