import { SearchCardTitleWrap } from '@/shared/components/Card'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { SportTypeEnums, SportTypeEnumsSelectOptions } from '../../../enums/SystemManagement/sportEnums'
import { Button } from '../../../shared/components/Button'
import { MemberResterBankSelect } from '../../../shared/components/CustomSelect'
import Error from '../../../shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '../../../shared/components/form/FormElements'
import { DatePickerWrap } from '../../../shared/components/form/date-pickers/DatePickerElements'
import {
  createNewSportsEventOrMarketByManual,
  searchManualSportsListForManual,
  searchSportsEventListForManual,
  searchSportsMarketListForManual,
} from '../../../utils/api/sportsManage/manualSportRegister'
import { convertToKst, convertToKstDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import { manualSportsRegisterValidate } from '../../../utils/validate/sport/sportSettingManage'
import Loading from '../../../shared/components/Loading'
import ManualSportsResultModal from './ManualSportsResultModal'

const ManualTypeEnums = {
  NEW_EVENT: '수동 경기등록',
  NEW_MARKET: '수동 마켓등록',
}

const ManualTypeEnumsEnumsRadioOptions = () => {
  return Object.entries(ManualTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'manualType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'manualType',
          label: value,
          radioValue: key,
        },
  )
}

const ManualMarketList = [
  {
    sportsType: '야구',
    marketName: '첫 볼넷',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '야구',
    marketName: '첫 홈런',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '야구',
    marketName: '첫 안타',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '야구',
    marketName: '1이닝 득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '아이스 하키',
    marketName: '첫 득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '첫 3점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '첫 자유투',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '첫 득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '선 5득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '선 7득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '선 10득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '선 15득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '농구',
    marketName: '선 20득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '배구',
    marketName: '선 5득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '배구',
    marketName: '선 7득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: '배구',
    marketName: '선 10득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: 'E-SPORTS',
    marketName: '첫 킬',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: 'E-SPORTS',
    marketName: '첫 용',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: 'E-SPORTS',
    marketName: '첫 바론',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: 'E-SPORTS',
    marketName: '첫 타워',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  {
    sportsType: 'E-SPORTS',
    marketName: '1세트 경기시간',
    oddName: '언더(UNDER) / 오버(OVER)',
  },
  {
    sportsType: '기타',
    marketName: '승패',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
]

const ManualSportsList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [apiLoading, setApiLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchManualSportsListForManual = async () => {
    setApiLoading(true)
    await searchManualSportsListForManual(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchManualSportsListForManual(params)
  }, [params])

  const [isManualSportsResultModalShow, setIsManualSportsResultModalShow] = useState(false)
  const onCloseManualSportsResultModal = () => {
    setIsManualSportsResultModalShow(prevState => !prevState)
    fetchSearchManualSportsListForManual()
  }
  const [modalManualSportsInfo, setModalManualSportsInfo] = useState({})

  return (
    <>
      {isManualSportsResultModalShow && (
        <ManualSportsResultModal
          isManualSportsResultModalShow={isManualSportsResultModalShow}
          onCloseManualSportsResultModal={onCloseManualSportsResultModal}
          manualSportsInfo={modalManualSportsInfo}
        />
      )}
      <SearchCardTitleWrap>
        <div style={{ marginBottom: '10px' }}>
          <p>* 해당 페이지는 수동으로 등록된 경기의 결과를 처리할 수 있습니다.</p>
        </div>
      </SearchCardTitleWrap>
      {apiLoading && <Loading />}
      <TableWrap>
        <table>
          <thead>
            <tr>
              <th style={{ borderLeft: 'solid 1px black' }}>번호</th>
              <th>경기</th>
              <th>종목</th>
              <th>마켓</th>
              <th>경기시간</th>
              <th>결과처리</th>
              <th>결과처리 여부</th>
            </tr>
          </thead>
          <tbody>
            {content.map(item => (
              <tr>
                <td style={{ borderLeft: 'solid 1px black' }}>{item.num}</td>
                <td>
                  [{item.ccKr} - {item.leagueName}] {item.homeName} vs {item.awayName}
                </td>
                <td>{SportTypeEnums[item.sportsType]}</td>
                <td>{item.marketName}</td>
                <td>{convertToKstDateTime(item.startAt)}</td>
                <td>
                  {item.isResultProcessed ? (
                    '-'
                  ) : (
                    <SportsEventResultWrap>
                      <SportsEventResultBtn
                        size="sm"
                        onClick={() => {
                          setModalManualSportsInfo(item)
                          setIsManualSportsResultModalShow(true)
                        }}
                      >
                        결과처리
                      </SportsEventResultBtn>
                    </SportsEventResultWrap>
                  )}
                </td>
                <td>
                  {item.isResultProcessed ? (
                    <span style={{ color: 'blue', fontWeight: 'bold' }}> 처리 </span>
                  ) : (
                    <span style={{ color: 'red', fontWeight: 'bold' }}> 미처리 </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {content.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
      </TableWrap>
    </>
  )
}

export default ManualSportsList

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 630px;
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const TableBodyWrap = styled.div`
  width: 100%;
  display: flex;
  background: #f3f4f6;
  color: black;
  min-height: 30px;
`

const TableBodySportsTypeWrap = styled.div`
  width: 35%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-right: 1px solid black;
`

const TableBodySportsMarketWrap = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-right: 1px solid black;
`

const TableBodySportsMarketOddNameWrap = styled.div`
  width: 60%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border: 1px solid #888;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`

const SportsEventResultWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
`

const SportsEventResultBtn = styled.button`
  background: #f59a0a;
  color: white;
  border: none;
`
