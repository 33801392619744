import axios from '../base/axios'

// 종목 조회
export async function searchSportList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 종목 수정
export async function updateSport(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 종목 수정
export async function updateSportIdStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/status',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
