import PropTypes from 'prop-types'
import React from 'react'
import { NoteAuthorityEnums } from '../../enums/Operation/NoteEnums'
import { payBackSettlementStatusEnums } from '../../enums/SystemManagement/siteEnums'
import { CustomAuthorityButton, SettlementButton } from '../../shared/components/Card'
import { convertToKstDateTimeSimple } from '../../utils/dateTime'
import MemberCoupon from '../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../MemberManagement/MemberList/MemberNote/MemberNote'
import PayBackRadio from './PayBackRadio'
import { commonReg2 } from '../../utils/validate/commonValidate'
import { MemberDepositLevelEnum } from '../../enums/MemberManage/MemberInfoEnums'
import MemberMoneyIncDecMini from '../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const PayBackListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <PayBackRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
      width: 30,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'userAuthority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.userAuthority}>
            {NoteAuthorityEnums[original.userAuthority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저 ID(명)',
      accessor: 'memberId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.userId} />
              </span>
              <span>
                <MemberCoupon memberId={original.userId} />
              </span>
              <span>
                <MemberNote memberId={original.userId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.userId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '정산 시작일',
      accessor: 'paybackStartDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.paybackStartDate)
      },
    },
    {
      Header: '정산 종료일',
      accessor: 'paybackEndDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.paybackEndDate)
      },
    },
    {
      Header: '입금횟수',
      accessor: 'totalDepositCount',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '입금액',
      accessor: 'totalDepositAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalDepositAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '출금횟수',
      accessor: 'totalWithdrawalCount',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '출금액',
      accessor: 'totalWithdrawalAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalWithdrawalAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '당시 보유액',
      accessor: 'calculateMoneyAmonut',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.calculateMoneyAmonut?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '페이백 레벨',
      accessor: 'paybackLevelName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{MemberDepositLevelEnum[original.paybackLevelName]}</>
      },
    },
    {
      Header: '페이백 비율',
      accessor: 'paybackPer',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{original.paybackPer}%</>
      },
    },
    {
      Header: '계산된 페이백액',
      accessor: 'calculatePointAmonut',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.calculatePointAmonut?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '1회 최대페이백액',
      accessor: 'edgeCommonPayBackEventMaxAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.edgeCommonPayBackEventMaxAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '최종 지급액',
      accessor: 'changePointAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changePointAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '지급전 포인트액',
      accessor: 'beforePointAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePointAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '지급후 포인트액',
      accessor: 'afterPointAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPointAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '지급일',
      accessor: 'executeAt',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.executeAt)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <SettlementButton authority={original.payBackSettlementStatus}>
            {payBackSettlementStatusEnums[original.payBackSettlementStatus]}
          </SettlementButton>
        )
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default PayBackListData

PayBackListData.propTypes = {
  fetchSearchBoardList: PropTypes.func.isRequired,
}
