import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSlotCityCallMinAmount } from '../../../redux/CasinoInfoSlice'
import { searchSlotCityBonusCallConfig } from '../../../utils/api/casino/slotCityApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import CasinoManagementTabs from '../CasinoManagementTabs'
import SlotCityAgentRTPUpdate from './SlotCityAgentRTPUpdate'
import SlotCityOnlineGameList from './SlotCityOnlineGameList'

const SlotCityCallManagement = () => {
  const dispatch = useDispatch()

  const { slotCityCallMinAmount } = useSelector(state => {
    const { casinoInfo } = state

    return {
      slotCityCallMinAmount: casinoInfo.slotCityCallMinAmount,
    }
  })

  // 보너스 콜 최소 금액
  const fetchSlotCityBonusCallConfig = async () => {
    await searchSlotCityBonusCallConfig().then(res => {
      dispatch(setSlotCityCallMinAmount(res.data.callMinAmount))
    })
  }

  useEffect(() => {
    fetchSlotCityBonusCallConfig()
  }, [])

  return (
    <Card>
      <CardBody>
        <CasinoManagementTabs activeKey="8" />

        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 콜 금액은
              <span style={{ color: 'red' }}> {slotCityCallMinAmount.toString().replace(commonReg2, ',')} 원</span>
              이상으로 가능합니다.
            </p>
            <p>
              * 진행중인 게임목록은 <span style={{ color: 'red' }}>10초 간격</span>으로 갱신이 됩니다.
            </p>
            <p>
              * 세션종료는 해당 유저의 진행중인 <span style={{ color: 'red' }}>모든 게임</span>을 종료처리 됩니다.
            </p>
          </div>
        </SearchCardTitleWrap>

        <SlotCityAgentRTPUpdate />

        <SlotCityOnlineGameList />

        {/* <SlotCityCallHistory /> */}
      </CardBody>
    </Card>
  )
}

export default SlotCityCallManagement
