import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const SystemManagementTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/management/system/clienturl')
                }}
              >
                URL 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/management/system/banner')
                }}
              >
                배너 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="6"
                onClick={e => {
                  history.push('/management/system/bank')
                }}
              >
                은행 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/management/system/site')
                }}
              >
                사이트 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="8"
                onClick={e => {
                  history.push('/management/system/level')
                }}
              >
                레벨 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="9"
                onClick={e => {
                  history.push('/management/system/deposit-bonus')
                }}
              >
                입출금 규정 관리
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/management/system/mainimg')
                }}
              >
                이미지 관리
              </NavLink>
            </NavItem> */}
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default SystemManagementTabs

SystemManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
