import { createAction, createSlice } from '@reduxjs/toolkit'

export const reAuthenticationAction = createAction('RE_AUTHENTICATION')

const initInfoSlice = createSlice({
  name: 'initInfo',
  initialState: {
    adminId: null,
    authority: null,
  },
  reducers: {
    setAdminInfo: (state, action) => {
      const { adminId, authority } = action.payload
      state.adminId = adminId
      state.authority = authority
    },
  },
  extraReducers: builder => {},
})

export const { setAdminInfo } = initInfoSlice.actions

export default initInfoSlice.reducer
