import { DamageTypeEnumsSelectOptions } from '@/enums/MemberManage/BlackListEnums'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { useSearchBadMember } from '@/utils/api/memberManage/blackMemberManageApi'
import { convertToKst } from '@/utils/dateTime'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { decodeAccessToken } from '../../../utils/token'
import MemberManageMentTabs from '../MemberManageMentTabs'
import MemberBadListData from './MemberBadListData'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'

const MemberBadList = () => {
  const { edgeNames } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNames: headerInfo.edgeNames,
    }
  })
  const [selectedEdgeName, setSelectedEdgeName] = useState(null)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)

  /**
   *  회원 조회
   */
  const [blackMemberParams, setBlackMemberParams] = useState({
    page,
    size,
    authority: null,
    edgeName: null,
    memberId: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setBlackMemberParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setBlackMemberParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  useEffect(() => {
    setBlackMemberParams(prev => ({
      ...prev,
      page: 0,
      edgeName: selectedEdgeName,
    }))
  }, [selectedEdgeName])

  const { totalElement, content, fetchSearchBadMember } = useSearchBadMember(blackMemberParams)

  const [memberBlackListData, setMemberBlackListData] = useState(MemberBadListData(content))

  useEffect(() => {
    setMemberBlackListData(MemberBadListData(content))
  }, [content])

  const [rows, setData] = useState(memberBlackListData.tableRowsData)

  useEffect(() => {
    setData(memberBlackListData.tableRowsData)
  }, [memberBlackListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
  }

  // 용의자 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 피해 유형
  const [searchDamageType, setSearchDamageType] = useState('')

  const [searchDamageTypeOptionValue, setSearchDamageTypeOptionValue] = useState(null)
  const searchDamageTypeOption = DamageTypeEnumsSelectOptions()

  const onSelectDamageTypeOptionHandler = option => {
    setSearchDamageTypeOptionValue(option)
    setSearchDamageType(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchDamageStartDate, setSearchDamageStartDate] = useState(null)
  const [searchDamageEndDate, setSearchDamageEndDate] = useState(null)

  const onSearchDamageStartDateChangeHandler = date => {
    setSearchDamageStartDate(date)
  }
  const onSearchDamageEndDateChangeHandler = date => {
    setSearchDamageEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBlackMemberParams(prev => ({
      ...prev,
      page: 0,
      edgeName: selectedEdgeName,
      memberId: !searchMemberId ? null : searchMemberId,
      startDate: !searchDamageStartDate ? null : convertToKst(searchDamageStartDate),
      endDate: !searchDamageEndDate ? null : convertToKst(searchDamageEndDate),
    }))
  }

  const [activeKey, setActiveKey] = useState('6')
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <MemberManageMentTabs activeKey={activeKey} />
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
              <p>
                * 악성 유저는 <span style={{ fontWeight: 'bolder', color: 'red' }}>관리자가 설정한 시간 내</span>{' '}
                새로고침을 연속적으로 한 유저입니다.
              </p>
            </div>
          </SearchCardTitleWrap>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          {/* 검색 기능 */}
          <SearchContainer horizontal>
            <EdgeSearchButton
              edgeName={selectedEdgeName}
              onClickHandler={e => {
                setSelectedEdgeName(e.target.id || null)
              }}
            />
            <SearchGroup style={{ marginLeft: '20px' }}>
              <SearchGroupLabel>유저 ID</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchValue"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchMemberId}
                    onChange={e => onSearchMemberIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            {/* <SearchGroup>
              <SearchGroupLabel>피해유형</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchType"
                  onChange={option => {
                    onSelectDamageTypeOptionHandler(option)
                  }}
                  value={searchDamageTypeOptionValue}
                  options={searchDamageTypeOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder="피해유형"
                />
              </SearchGroupField>
            </SearchGroup> */}
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchDamageStartDate}
                    onChange={date => onSearchDamageStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    isClearable
                    locale={ko}
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchDamageEndDate}
                    onChange={date => onSearchDamageEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    isClearable
                    locale={ko}
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>
          <ReactTableBase columns={memberBlackListData.tableHeaderData} data={rows} tableConfig={tableConfig} />

          {/*  상세보기 모달 */}
          {/* <MemberBlackListDetail
            blackMemberInfo={blackMemberInfo}
            isMemberBlackListDetailModalShow={isMemberBlackListDetailModalShow}
            setMemberBlackListDetailModalShow={setMemberBlackListDetailModalShow}
          /> */}
        </CardBody>
      </Card>
    </Col>
  )
}

export default MemberBadList
