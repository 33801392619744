import PropTypes from 'prop-types'
import React from 'react'
import MemberHistoryMoenyDeposit from './MemberHistoryMoenyDeposit'
import MemberHistoryMoenyWithdrawal from './MemberHistoryMoenyWithdrawal'
import MemberHistoryMoneyExchange from './MemberHistoryMoneyExchange'

const MemberHistoryMoney = ({ memberId }) => {
  return (
    <>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>머니 입금 내역</h3>
      <MemberHistoryMoenyDeposit memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>머니 출금 내역</h3>
      <MemberHistoryMoenyWithdrawal memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>머니 이동 내역</h3>
      <MemberHistoryMoneyExchange memberId={memberId} />
    </>
  )
}

export default MemberHistoryMoney

MemberHistoryMoney.propTypes = {
  memberId: PropTypes.string.isRequired,
}
