import { Card, CardBody } from '@/shared/components/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

import { CustomCol } from '../../shared/components/CustomCol'

const PayBackTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <CustomCol md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <BorderedBottomTabs>
            <Tab.Container defaultActiveKey={activeKey}>
              <TabsWrap>
                <Nav className="nav-tabs">
                  <NavItem>
                    <NavLink
                      eventKey="1"
                      onClick={e => {
                        history.push('/user/manage/payback')
                      }}
                    >
                      페이백 정산내역
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      eventKey="2"
                      onClick={e => {
                        history.push('/user/manage/friend-comp')
                      }}
                    >
                      추천인 정산내역
                    </NavLink>
                  </NavItem>
                </Nav>
              </TabsWrap>
            </Tab.Container>
          </BorderedBottomTabs>
        </CardBody>
      </Card>
    </CustomCol>
  )
}

export default PayBackTabs

PayBackTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
