import { convertToKstDateTimeSimple } from '@/utils/dateTime'
import React from 'react'
import { MoneyChangeLogAssetTypeEnums, MoneyChangeLogSortEnums } from '../../../enums/Log/MoneyChangeLogEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MoneyChangeLogRadio from './MoneyChangeLogRadio'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyChangeLogListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <MoneyChangeLogRadio rowInfo={original} checkRadio={checkRadio} setCheckRadio={setCheckRadio} />
      },
      width: 30,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '유저 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.memberId} />{' '}
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '변경내용',
      accessor: 'changeContent',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '구분',
      accessor: 'moneyChangeLogSort',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span>{MoneyChangeLogSortEnums[original.moneyChangeLogSort]}</span>
      },
    },
    {
      Header: '일반/홀덤',
      accessor: 'moneyChangeLogAssetType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.moneyChangeLogAssetType) {
          case 'NORMAL_MONEY':
            return (
              <button type="button" style={{ background: '#7d2424', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MoneyChangeLogAssetTypeEnums[original.moneyChangeLogAssetType]}
              </button>
            )
          case 'HOLDEM_MONEY':
            return (
              <button type="button" style={{ background: '#276687', border: 'none', color: '#fff', fontWeight: '900' }}>
                {MoneyChangeLogAssetTypeEnums[original.moneyChangeLogAssetType]}
              </button>
            )

          default:
            return <span>-</span>
        }
      },
    },
    {
      Header: '변경전',
      accessor: 'beforeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경액',
      accessor: 'changeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후',
      accessor: 'afterAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.executeDate)
      },
    },
    // {
    //   Header: '노출여부',
    //   accessor: 'memberHiddenFlag',
    //   disableGlobalFilter: true,
    //   disableSortBy: true,
    //   width: 65,
    //   Cell: r => {
    //     const { row } = r
    //     const { original } = row
    //     if (original.memberHiddenFlag) {
    //       return <span style={{ color: 'blue', fontWeight: '500' }}>노출</span>
    //     }
    //     return <span style={{ color: 'red', fontWeight: '500' }}>비노출</span>
    //   },
    // },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyChangeLogListData
