import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../../../enums/Operation/NoteEnums'
import { SportMarketType } from '../../../../../enums/Sport/SportMarketEnums'
import {
  BettingResultStatusEnumsSelectOptions,
  BettingResultStatusEnumsSelectOptions2,
  sportResultStatusEnums,
} from '../../../../../enums/SystemManagement/sportEnums'
import CustomPagination from '../../../../../shared/components/customPagination/CustomPagination'
import { searchSportsBettingLogV2 } from '../../../../../utils/api/sport/sportBettingLogApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../../../utils/dateTime'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import SportDetailBettingLog from '../../../../BettingManagement/SportBettingLogV2/SportDetailBettingLog'
import MemberDepositInfo from '../../../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberNote/MemberNote'
import TooltipWrapper from '../../../../../shared/components/TooltipWrapper'

const MemberSportsBettingHistoryV2 = ({ memberId }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)
  const [resultStatus, setResultStatus] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
    userId: memberId,
    sportsResultStatus: null,
    startDate: null,
    endDate: null,
    isExact: true,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [sportsBettingLogList, setSportsBettingLogList] = useState([])

  const fetchSportsBettingLog = async () => {
    setLoading(true)
    await searchSportsBettingLogV2(bettingLogparams)
      .then(res => {
        const newSportsBettingLogList = res.data.sportsBettingLogList.map(item => ({
          ...item,
          isVisible: false,
        }))
        setSportsBettingLogList(newSportsBettingLogList)
        setTotalElement(res.data.totalElements)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!bettingLogparams) return
    fetchSportsBettingLog()
  }, [bettingLogparams])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 특정 배팅로그 펼치기
  const onClickSportsBettingLogHandler = sportsBettingLogKey => {
    setSportsBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        if (item.sportsBettingLogKey === sportsBettingLogKey) {
          return { ...item, isVisible: !item.isVisible } // isVisible 토글
        }
        return item // 변경 없이 그대로 반환
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState(resultStatus || '')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(
    resultStatus ? { value: 'ING', label: '진행중' } : null,
  )
  const searchGameResultOption = BettingResultStatusEnumsSelectOptions2()
  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }
  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      sportsResultStatus: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  return (
    <Card>
      <CardBody style={{ padding: '0px' }}>
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당 회원의 자세한 배팅내역을 볼 수 있습니다.</p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>게임결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <SportsGameListTableWrap>
          <table>
            <thead>
              <tr>
                <th>번호</th>
                <th>사이트명</th>
                <th>배팅일시</th>
                <th>상위파트너 (이름)</th>
                <th>유저 (이름)</th>
                <th>배팅전 머니</th>
                <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액</th>
                <th>배팅후 머니</th>
                <th>타입</th>
                <th>폴더</th>
                <th>배당</th>
                <th>예상/최종 당첨금</th>
                <th>결과</th>
                <th>결과 처리시간</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {sportsBettingLogList.length !== 0 &&
                sportsBettingLogList.map(sportsBettingLog => {
                  return (
                    <>
                      <tr
                        key={sportsBettingLog.sportsBettingLogKey}
                        onClick={e => {
                          onClickSportsBettingLogHandler(sportsBettingLog.sportsBettingLogKey)
                        }}
                      >
                        <td>{sportsBettingLog.num}</td>
                        <td>{sportsBettingLog.edgeName}</td>
                        <td>{convertToKstSportsEventDateTime(sportsBettingLog.bettingAt)}</td>
                        <td>
                          {sportsBettingLog.topUserId ? (
                            <>
                              {sportsBettingLog.topUserId} ({sportsBettingLog.topUserName})
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              window.open(`/user-info/${sportsBettingLog.userId}`, '_blank')
                            }}
                            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {sportsBettingLog.userId} ({sportsBettingLog.userName})
                          </span>
                          <span style={{ marginLeft: '5px' }}>
                            <CustomAuthorityButton authority={sportsBettingLog.userAuthority}>
                              {NoteAuthorityEnums[sportsBettingLog.userAuthority]}
                            </CustomAuthorityButton>
                          </span>
                          <span>
                            <span>
                              <MemberDepositInfo memberId={sportsBettingLog.userId} />
                            </span>
                            <span>
                              <MemberCoupon memberId={sportsBettingLog.userId} />
                            </span>
                            <span>
                              <MemberNote memberId={sportsBettingLog.userId} />
                            </span>
                            <span>
                              <MemberMoneyIncDecMini memberId={sportsBettingLog.userId} />
                            </span>
                          </span>
                        </td>
                        <td>{sportsBettingLog.bettingBeforeAmount.toString().replace(commonReg2, ',')}</td>
                        <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                          {sportsBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                        </td>
                        <td>{sportsBettingLog.bettingAfterAmount.toString().replace(commonReg2, ',')}</td>
                        <CustomTd sportMarketType={sportsBettingLog.sportMarketType}>
                          {SportMarketType[sportsBettingLog.sportMarketType]}
                        </CustomTd>
                        <td>{sportsBettingLog.folderSize} 폴더</td>
                        <td>
                          {(() => {
                            const {
                              realReduceBettingPerForSingleFolder,
                              realReduceBettingPerForCombination,
                              totalOddValue,
                              originTotalOddValue,
                            } = sportsBettingLog
                            const reduceBettingValue =
                              realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                            if (reduceBettingValue === null) {
                              return <span>{totalOddValue}</span>
                            }

                            return (
                              <TooltipWrapper text="적용배당 / (원본배당 - 조정배당)" place="bottom">
                                {Number(reduceBettingValue) < 0 ? (
                                  <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                    {reduceBettingValue?.toFixed(2)})
                                  </span>
                                ) : (
                                  <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                    {reduceBettingValue?.toFixed(2)})
                                  </span>
                                )}
                              </TooltipWrapper>
                            )
                          })()}
                        </td>

                        <td>
                          <span style={{ color: 'blue' }}>
                            {sportsBettingLog.bettingExpectedAmount.toString().replace(commonReg2, ',')}
                          </span>
                          <span style={{ margin: '0 5px' }}>/</span>
                          <span style={{ color: 'red' }}>
                            {sportsBettingLog.bettingResultAmount.toString().replace(commonReg2, ',')}
                          </span>
                        </td>
                        <td>
                          <CustomButton type="button" bettingResult={sportsBettingLog.sportsResultStatus}>
                            {sportResultStatusEnums[sportsBettingLog.sportsResultStatus]}
                          </CustomButton>
                        </td>
                        <td>{convertToKstSportsEventDateTime(sportsBettingLog.resultExecuteAt)}</td>
                        <td>
                          {sportsBettingLog.showFlag ? <span>노출</span> : <span style={{ color: 'red' }}>비노출</span>}
                        </td>
                      </tr>
                      {sportsBettingLog.isVisible && (
                        <SportDetailBettingLog
                          sportsDetailBettingLogList={sportsBettingLog.sportsDetailBettingLogList}
                          bonsuFolder={{
                            bonusFolderNum: sportsBettingLog.bonusFolderNum,
                            bonusFolderPer: sportsBettingLog.bonusFolderPer,
                          }}
                        />
                      )}
                    </>
                  )
                })}
              {sportsBettingLogList.length === 0 && (
                <tr>
                  <td colSpan={15} style={{ color: '#000', fontWeight: '600', fontSize: '16px', padding: '30px 0px' }}>
                    최근 게시물이 없습니다.
                  </td>{' '}
                </tr>
              )}
            </tbody>
          </table>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}
// http://localhost:5001/user-info/test333
export default MemberSportsBettingHistoryV2

MemberSportsBettingHistoryV2.propTypes = {
  memberId: PropTypes.string.isRequired,
}
const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    default:
      return 'green'
  }
}

const getColor2 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomTd = styled.td`
  color: #000;
  font-weight: 600;
  background: ${props => getColor2(props.sportMarketType)} !important;
`

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  background: lightgrey;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderScoreBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResultBox = styled.div`
  width: 13%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentCriteria2 = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 13%;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGamePartnerBox = styled.div`
  width: 13%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  color: white;
  width: 8%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameAuthorityBox = styled.div`
  font-size: 13px;
  color: white;
  width: 5%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 36px;
`
