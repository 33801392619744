export const NoteAuthorityEnums = {
  PARTNER: '파트너',
  MEMBER: '회원',
}

export const NoteAuthoritySelectOptions = () => {
  return Object.entries(NoteAuthorityEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const NoteReadStatusEnums = {
  READ: '확인',
  NOREAD: '미확인',
}

export const NoteReadStatusSelectOptions = () => {
  return Object.entries(NoteReadStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
