// 피해 유형
export const DamageTypeEnums = {
  ACCOUNT_THREAT: '통장협박',
  ACCOUNT_REPORT: '통장신고',
  BOTH_MEMBER: '양방회원',
  OTHER_REASON: '기타사유',
}

export const DamageTypeEnumsSelectOptions = () => {
  return Object.entries(DamageTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

// 피해 유형 (Radio button group)
export const DamageTypeRadioButtonGroup = [
  {
    name: 'damageType',
    label: '통장신고',
    radioValue: 'ACCOUNT_REPORT',
    initialValue: 'ACCOUNT_REPORT',
  },
  {
    name: 'damageType',
    label: '양방회원',
    radioValue: 'BOTH_MEMBER',
  },
  {
    name: 'damageType',
    label: '통장협박',
    radioValue: 'ACCOUNT_THREAT',
  },
  {
    name: 'damageType',
    label: '기타사유',
    radioValue: 'OTHER_REASON',
  },
]
