import React from 'react'
import { convertToKstDateTime } from '@/utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MoneyExchangeRadio from './MoneyExchangeRadio'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MoneyExchangeListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <MoneyExchangeRadio rowInfo={original} checkRadio={checkRadio} setCheckRadio={setCheckRadio} />
      },
      width: 30,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '유저 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.memberId} />{' '}
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '송신',
      accessor: 'sender',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '수신',
      accessor: 'receiver',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '변경전(지갑)',
      accessor: 'beforeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경액',
      accessor: 'changeAmountMoneyExchange',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changeAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후(지갑)',
      accessor: 'afterAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyExchangeListData
