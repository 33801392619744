export const PopupTargetEnums = {
  MEMBER: '회원',
  PARTNER: '파트너',
  MEMBERPARTNER: '회원+파트너',
}

export const PopupTargetEnumsSelectOptions = () => {
  return Object.entries(PopupTargetEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const PopupTargetEnumsRadioOptions = () => {
  return Object.entries(PopupTargetEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'popupTarget',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'popupTarget',
          label: value,
          radioValue: key,
        },
  )
}

export const MoneyDepositAutoEnums = {
  true: '자동',
  false: '수동',
}

export const MoneyDepositAutoEnumsRadioOptions = () => {
  return Object.entries(MoneyDepositAutoEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'moneyDepositAuto',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'moneyDepositAuto',
          label: value,
          radioValue: key,
        },
  )
}

export const PopupStatusEnums = {
  SHOW: '보이기',
  HIDDEN: '감추기',
}

export const PopupStatusEnumsSelectOptions = () => {
  return Object.entries(PopupStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const PopupStatusEnumsRadioOptions = () => {
  return Object.entries(PopupStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'popupStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'popupStatus',
          label: value,
          radioValue: key,
        },
  )
}
