import React from 'react'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { callHistoryStatusEnums } from '../../../enums/SystemManagement/callManageEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'

const CallHistoryData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '날짜',
      accessor: 'applyAt',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.applyAt)
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '게임사',
      accessor: 'vendorName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '게임명',
      accessor: 'gameTitle',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배팅액',
      accessor: 'bettingAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '콜금액',
      accessor: 'expectedAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.expectedAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '조작 전 유저 보유금',
      accessor: 'beforeMoneyAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforeMoneyAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '조작 후 유저 보유금',
      accessor: 'afterMoneyAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterMoneyAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '조작 전 보유알',
      accessor: 'beforePotAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePotAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '조작 후 보유알',
      accessor: 'afterPotAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPotAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '실금액',
      accessor: 'realAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.realAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '차액',
      accessor: 'missedAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.missedAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'WAITING':
            return <span style={{ color: 'orange', fontWeight: '900' }}>{callHistoryStatusEnums[original.status]}</span>
          case 'PROCESSING':
            return <span style={{ color: 'blue', fontWeight: '900' }}>{callHistoryStatusEnums[original.status]}</span>
          case 'FINISHED':
            return <span style={{ color: 'green', fontWeight: '900' }}>{callHistoryStatusEnums[original.status]}</span>
          case 'REJECTED':
            return <span style={{ color: 'red', fontWeight: '900' }}>{callHistoryStatusEnums[original.status]}</span>
          case 'CANCELED':
            return <span style={{ color: 'red', fontWeight: '900' }}>{callHistoryStatusEnums[original.status]}</span>
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>{callHistoryStatusEnums[original.status]}</span>
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default CallHistoryData
