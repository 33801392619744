import { ButtonToolbar } from '@/shared/components/Button'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import { colorBackground, colorBlue, colorIcon, colorText, colorWhite } from '@/utils/palette'
import { Modal as BootstrapModal } from 'react-bootstrap'
import styled from 'styled-components'
import './modal-custom.css'

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 900px !important;
    margin: 1.75rem auto;
  }

  .modal-content {
    border-radius: 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`
/** =============================================
 *  Custom Modal - Start
 *  ============================================= */

export const CustomModalFooter = styled(ModalFooter)``
export const CustomModalTitle = styled(ModalTitle)``
export const CustomModalCloseButton = styled(ModalCloseButton)``
export const CustomModalTitleIcon = styled(ModalTitleIcon)``
export const CustomModalHeader = styled(ModalHeader)``
export const CustomModalBody = styled(ModalBody)``
export const CustomStyledModal = styled(StyledModal)``

/** =============================================
 *  Custom Modal - End
 *  ============================================= */

/** =============================================
 *  회원 조회 쿠폰 Custom Modal - Start
 *  ============================================= */

export const ResultCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 600px !important;
  }
`

export const CouponCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 430px !important;
  }
`

export const DepositStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 900px !important;
  }
`

export const CustomDepositStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 1250px !important;
  }
`

export const MemberBettingLogStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 1300px !important;
  }
`

/** =============================================
 *  회원 조회  쿠폰 Custom Modal - End
 *  ============================================= */

/** =============================================
 *  쪽지 Custom Modal - Start
 *  ============================================= */

export const NoteCustomStyledModal = styled(CustomStyledModal)`
  // .modal-dialog {
  //   max-width: 900px !important;
  // }
`

/** =============================================
 *  쪽지 Custom Modal - End
 *  ============================================= */

/** =============================================
 *   회원 등록하기 Custom Modal - Start
 *  ============================================= */

export const MemberRegistrationCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 600px !important;
  }
`

/** =============================================
 *  회원 등록하기 Custom Modal - End
 *  ============================================= */

/** =============================================
 *  추천인 링크 Custom Modal - Start
 *  ============================================= */

export const FriendLinkCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 500px !important;
  }
`
/** =============================================
 *  추천인 링크 Custom Modal - End
 *  ============================================= */

export const MoneyInfoCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 500px !important;
  }
`

/** =============================================
 *  블랙리스트 회원 정보 모달 Custom Modal - Start
 *  ============================================= */

export const BlackMemberCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 500px !important;
  }
`
/** =============================================
 *  블랙리스트 회원 정보 모달 Custom Modal - End
 *  ============================================= */

/** =============================================
 *  블랙리스트 회원 등록 모달 Custom Modal - Start
 *  ============================================= */

export const BlackMemberRegisterCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 650px !important;
  }
`
/** =============================================
 *  블랙리스트 회원 등록 모달 Custom Modal - End
 *  ============================================= */

/** =============================================
 *  파트너 수정 모달 Custom Modal - Start
 *  ============================================= */

export const PartnerInfoCustomStyledModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 700px !important;
  }
`
/** =============================================
 *  파트너 수정 모달 Custom Modal - End
 *  ============================================= */
