import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { bankStatusEnumsSelectOptions } from '../../../enums/SystemManagement/bankEnums'
import { adminSearchBankList } from '../../../utils/api/memberManage/memberManageApi'
import SystemManagementTabs from '../SystemManagementTabs'
import BankCreateModal from './BankCreateModal'
import BankListData from './BankListData'
import BankModifyModal from './BankModifyModal'

const BankList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const [bankListparams, setBankListParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setBankListParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchBankManage = async () => {
    await adminSearchBankList(bankListparams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchBankManage()
  }, [bankListparams])

  const [bankListData, setBankListData] = useState(BankListData(content))
  useEffect(() => {
    setBankListData(BankListData(content))
  }, [content])

  const [rows, setData] = useState(bankListData.tableRowsData)
  useEffect(() => {
    setData(bankListData.tableRowsData)
  }, [bankListData])

  const [bankInfo, setBankInfo] = useState({})

  const [isBankModifyModalShow, setIsBankModifyModalShow] = useState(false)

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setBankInfo(rowValue.original)
    setIsBankModifyModalShow(true)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const [activeKey, setActiveKey] = useState('6')

  // 검색 - 은행명
  const [searchBankName, setSearchBankName] = useState('')
  // 검색 - 은행 상태
  const [searchBankStatus, setSearchBankStatus] = useState('')

  const [searchBankStatusOptionValue, setSearchBankStatusOptionValue] = useState(null)
  const searchBankStatusOption = bankStatusEnumsSelectOptions()
  const onSelectStatusOptionHandler = option => {
    if (option == null) {
      setSearchBankStatusOptionValue(null)
      setSearchBankStatus('')
      return
    }
    setSearchBankStatusOptionValue(option)
    setSearchBankStatus(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBankListParams(prev => ({
      ...prev,
      page: 0,
      bankName: !searchBankName ? null : searchBankName.trim(),
      bankStatus: !searchBankStatus ? null : searchBankStatus.trim(),
    }))
  }

  // 추가하기 버튼
  const [isBankCreateModalShow, setIsBankCreateModalShow] = useState(false)

  const onCreateClickHandler = () => {
    setIsBankCreateModalShow(true)
  }

  return (
    <Card>
      <CardBody>
        <SystemManagementTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 은행의 순서를 수정할 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <input type="text" style={{ display: 'none' }} />
            <SearchGroupLabel>은행명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  type="text"
                  placeholder="입력해주세요"
                  value={searchBankName}
                  onChange={e => setSearchBankName(e.target.value)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          {/* <SearchGroup>
              <SearchGroupLabel>상태</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchVendorName"
                  onChange={option => {
                    onSelectStatusOptionHandler(option)
                  }}
                  value={searchBankStatusOptionValue}
                  placeholder="상태"
                  options={searchBankStatusOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup> */}
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onCreateClickHandler()
              }}
            >
              추가하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>

        <ReactTableBase columns={bankListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>

      {isBankCreateModalShow && (
        <BankCreateModal
          isBankCreateModalShow={isBankCreateModalShow}
          setIsBankCreateModalShow={setIsBankCreateModalShow}
          fetchAdminSearchBankList={fetchBankManage}
        />
      )}

      {isBankModifyModalShow && (
        <BankModifyModal
          bankKey={bankInfo?.bankKey}
          bankName={bankInfo?.bankName}
          bankOrderNum={bankInfo?.bankOrderNum}
          bankStatus={bankInfo?.bankStatus}
          isBankModifyModalShow={isBankModifyModalShow}
          setIsBankModifyModalShow={setIsBankModifyModalShow}
          fetchAdminSearchBankList={fetchBankManage}
        />
      )}
    </Card>
  )
}

export default BankList
