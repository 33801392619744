import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { MacroTypeEnumsRadioOptions } from '../../../enums/SystemManagement/macroEnums'
import Loading from '../../../shared/components/Loading'
import { createMacroManage } from '../../../utils/api/systemManage/systemManageApi'
import { convertFromFront } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import './Macro.css'

function MacroRegistrationForm({ fetchSearchMacroManage, onClickHandler }) {
  const [writeLoading, setWriteLoading] = useState(false)
  const [content, setContent] = useState(EditorState.createEmpty())
  const { edgeNameRadio } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNameRadio: headerInfo.edgeNameRadio,
    }
  })
  const authority = decodeAccessToken()?.adminAuthority
  const contentChange = editorState => {
    setContent(editorState)
  }
  const onSubmit = e => {
    if (writeLoading) return
    if (!e.macroType || !e.tag || !e.macroTitle || !content.getCurrentContent().hasText()) {
      alert('모두 다 기입 후 등록버튼을 클릭해주세요.')
      return
    }

    const body = {
      edgeName: e.edgeName,
      type: e.macroType,
      tag: e.tag,
      macroTitle: e.macroTitle,
      stringContent: content.getCurrentContent().getBlocksAsArray()[0].getText(),
      macroContent: convertFromFront(content),
    }
    setWriteLoading(true)
    createMacroManage(body)
      .then(res => {
        setWriteLoading(false)
        alert('매크로 등록이 완료됐습니다.')
        onClickHandler()
        fetchSearchMacroManage()
      })
      .catch(error => {
        setWriteLoading(false)
        alert('매크로 등록이 실패헸습니다, 잠시 후 다시 시도해주세요.')
      })
  }

  return (
    <>
      {writeLoading ? (
        <Loading />
      ) : (
        <>
          <Form onSubmit={onSubmit} initialValues={{}}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>사이트명</FormGroupLabel>
                  {edgeNameRadio.map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>분류</FormGroupLabel>
                  {MacroTypeEnumsRadioOptions().map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name={`${item.name}`}
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={item.initialValue}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>태그</FormGroupLabel>
                  <FormGroupField>
                    <Field name="tag" component="input" type="text" />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>제목</FormGroupLabel>
                  <FormGroupField>
                    <Field name="macroTitle" component="input" type="text" />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>내용</FormGroupLabel>
                  <Editor
                    editorState={content}
                    onEditorStateChange={editorState => contentChange(editorState)}
                    wrapperClassName="wrapper"
                    editorClassName="editor"
                    toolbarClassName="toolbar"
                  />
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">
                          등록하기
                        </Button>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default MacroRegistrationForm

MacroRegistrationForm.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  fetchSearchMacroManage: PropTypes.func.isRequired,
}
