import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import styled from 'styled-components'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import Error from '@/shared/components/form/Error'
import { commonReg2 } from '../../../../../../utils/validate/commonValidate'
import { updateMemberRollingInfo } from '../../../../../../utils/api/logManage/logApi'
import { decodeAccessToken } from '../../../../../../utils/token'

const SportsRollingInfo = ({ sportsRollingInfo, memberId, topPartnerSportsRollingInfo, fetchhMemberRollingInfo }) => {
  const [loading, setLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority
  const [sportsOneFolderRollingPer, setSportsOneFolderRollingPer] = useState(
    sportsRollingInfo.sportsOneFolderRollingPer,
  )
  const [sportsTwoFolderRollingPer, setSportsTwoFolderRollingPer] = useState(
    sportsRollingInfo.sportsTwoFolderRollingPer,
  )
  const [sportsThreeFolderRollingPer, setSportsThreeFolderRollingPer] = useState(
    sportsRollingInfo.sportsThreeFolderRollingPer,
  )
  const [sportsFourFolderRollingPer, setSportsFourFolderRollingPer] = useState(
    sportsRollingInfo.sportsFourFolderRollingPer,
  )
  const [sportsFiveFolderRollingPer, setSportsFiveFolderRollingPer] = useState(
    sportsRollingInfo.sportsFiveFolderRollingPer,
  )
  const [sportsMultiFolderRollingPer, setSportsMultiFolderRollingPer] = useState(
    sportsRollingInfo.sportsMultiFolderRollingPer,
  )

  useEffect(() => {
    setSportsOneFolderRollingPer(sportsRollingInfo.sportsOneFolderRollingPer)
    setSportsTwoFolderRollingPer(sportsRollingInfo.sportsTwoFolderRollingPer)
    setSportsThreeFolderRollingPer(sportsRollingInfo.sportsThreeFolderRollingPer)
    setSportsFourFolderRollingPer(sportsRollingInfo.sportsFourFolderRollingPer)
    setSportsFiveFolderRollingPer(sportsRollingInfo.sportsFiveFolderRollingPer)
    setSportsMultiFolderRollingPer(sportsRollingInfo.sportsMultiFolderRollingPer)
  }, [sportsRollingInfo])

  const onSubmit = e => {
    if (loading) return
    if (window.confirm(`${memberId}의 스포츠 롤링비율을 변경 하시겠습니까?`)) {
      setLoading(true)
      const body = {
        memberId,
        sportsRollingInfo: {
          sportsOneFolderRollingPer,
          sportsTwoFolderRollingPer,
          sportsThreeFolderRollingPer,
          sportsFourFolderRollingPer,
          sportsFiveFolderRollingPer,
          sportsMultiFolderRollingPer,
        },
      }

      updateMemberRollingInfo(body)
        .then(res => {
          alert('수정이 완료됐습니다.')
          fetchhMemberRollingInfo()
          setLoading(false)
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          if (errorCode === 'MEMBER-1005') {
            alert(`${message}`)
          } else if (errorCode === 'PARTNER-1013') {
            alert(`${message}`)
          } else if (errorCode === 'PARTNER-1014') {
            alert(`${message}`)
          } else {
            alert('수정이 실패했습니다.')
          }
          fetchhMemberRollingInfo()
          setLoading(false)
        })
    }
  }
  return (
    <MemberInfoWrap>
      <SearchCardTitleWrap>
        <div style={{ marginBottom: '10px' }}>
          <p>
            * 롤링비율 수정 시 <span style={{ color: 'red', fontWeight: '900' }}>상위 파트너의 롤링비율</span>보다
            같거나 작어야 합니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          topPartnerSportsOneFolderRollingPer: topPartnerSportsRollingInfo.sportsOneFolderRollingPer,
          topPartnerSportsTwoFolderRollingPer: topPartnerSportsRollingInfo.sportsTwoFolderRollingPer,
          topPartnerSportsThreeFolderRollingPer: topPartnerSportsRollingInfo.sportsThreeFolderRollingPer,
          topPartnerSportsFourFolderRollingPer: topPartnerSportsRollingInfo.sportsFourFolderRollingPer,
          topPartnerSportsFiveFolderRollingPer: topPartnerSportsRollingInfo.sportsFiveFolderRollingPer,
          topPartnerSportsMultiFolderRollingPer: topPartnerSportsRollingInfo.sportsMultiFolderRollingPer,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <CustomFormContainer horizontal onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <th style={{ color: 'red' }}>
                    상위파트너 <br /> 단폴더 롤링비율
                  </th>
                  <td>
                    <Field name="topPartnerSportsOneFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                  <th style={{ color: 'red' }}>
                    상위파트너 <br /> 2폴더 롤링비율
                  </th>
                  <td>
                    <Field name="topPartnerSportsTwoFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                  <th style={{ color: 'red' }}>
                    상위파트너 <br /> 3폴더 롤링비율
                  </th>
                  <td>
                    <Field name="topPartnerSportsThreeFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                  <th style={{ color: 'red' }}>
                    상위파트너 <br /> 4폴더 롤링비율
                  </th>
                  <td>
                    <Field name="topPartnerSportsFourFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                  <th style={{ color: 'red' }}>
                    상위파트너 <br /> 5폴더 롤링비율
                  </th>
                  <td>
                    <Field name="topPartnerSportsFiveFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                  <th style={{ color: 'red' }}>
                    상위파트너 <br /> 다폴더 롤링비율
                  </th>
                  <td>
                    <Field name="topPartnerSportsMultiFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th>단폴더 롤링비율</th>
                  <td>
                    <Field name="sportsOneFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={sportsOneFolderRollingPer}
                            onChange={e => {
                              setSportsOneFolderRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                  <th>2폴더 롤링비율</th>
                  <td>
                    <Field name="sportsTwoFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={sportsTwoFolderRollingPer}
                            onChange={e => {
                              setSportsTwoFolderRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                  <th>3폴더 롤링비율</th>
                  <td>
                    <Field name="sportsThreeFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={sportsThreeFolderRollingPer}
                            onChange={e => {
                              setSportsThreeFolderRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                  <th>4폴더 롤링비율</th>
                  <td>
                    <Field name="sportsFourFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={sportsFourFolderRollingPer}
                            onChange={e => {
                              setSportsFourFolderRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                  <th>5폴더 롤링비율</th>
                  <td>
                    <Field name="sportsFiveFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={sportsFiveFolderRollingPer}
                            onChange={e => {
                              setSportsFiveFolderRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                  <th>다폴더 롤링비율</th>
                  <td>
                    <Field name="sportsMultiFolderRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={sportsMultiFolderRollingPer}
                            onChange={e => {
                              setSportsMultiFolderRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </table>
            {
              {
                SUPER: (
                  <FormButtonToolbar className="registrationFooter">
                    <Button variant="primary" type="submit" style={{ marginRight: '150px' }}>
                      스포츠 롤링 변경하기
                    </Button>
                  </FormButtonToolbar>
                ),
              }[authority]
            }
          </CustomFormContainer>
        )}
      </Form>
    </MemberInfoWrap>
  )
}

export default SportsRollingInfo

SportsRollingInfo.propTypes = {
  memberId: PropTypes.string.isRequired,
  sportsRollingInfo: PropTypes.shape({
    sportsOneFolderRollingPer: PropTypes.number,
    sportsTwoFolderRollingPer: PropTypes.number,
    sportsThreeFolderRollingPer: PropTypes.number,
    sportsFourFolderRollingPer: PropTypes.number,
    sportsFiveFolderRollingPer: PropTypes.number,
    sportsMultiFolderRollingPer: PropTypes.number,
  }).isRequired,
  topPartnerSportsRollingInfo: PropTypes.shape({
    sportsOneFolderRollingPer: PropTypes.number,
    sportsTwoFolderRollingPer: PropTypes.number,
    sportsThreeFolderRollingPer: PropTypes.number,
    sportsFourFolderRollingPer: PropTypes.number,
    sportsFiveFolderRollingPer: PropTypes.number,
    sportsMultiFolderRollingPer: PropTypes.number,
  }).isRequired,
  fetchhMemberRollingInfo: PropTypes.func.isRequired,
}

const CustomFormContainer = styled(FormContainer)`
  max-width: 100%;
`

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 150px;
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  input {
    border: solid 1px black;
  }
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    // background-color: grey !important;
    padding: 10px 4px;
    border: 1px solid black;
    white-space: nowrap;
    color: blue;
    font-weight: 600;
    font-size: 12px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
