import React from 'react'
import { Badge } from 'react-bootstrap'
import styled from 'styled-components'
import { BettingResultEnums } from '../../../enums/Log/bettingEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { colorLightText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { MoneyIncDecAuthorityEnums } from '../../../enums/Log/moneyIncDecEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const RealTimeCasinoBettingLogListData = content => {
  const columns = [
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span>{original?.edgeName}</span>
            {original?.isNew && (
              <NewBadge bg="custom">
                <span>N</span>
              </NewBadge>
            )}
          </>
        )
      },
    },
    {
      Header: '배팅번호',
      accessor: 'bettingId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배팅시간',
      accessor: 'bettingTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.bettingTime)
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.userAuthority}>
            {MoneyIncDecAuthorityEnums[original.userAuthority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '게임명',
      accessor: 'gameName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.gameName || '-'
      },
    },
    {
      Header: '배팅액',
      accessor: 'bettingAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '당첨액',
      accessor: 'bettingResultAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingResultAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '결과',
      accessor: 'bettingResult',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <BettingResultWrap bettingResult={original.bettingResult}>
            {BettingResultEnums[original.bettingResult]}
          </BettingResultWrap>
        )
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default RealTimeCasinoBettingLogListData

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'green'
    default:
      return colorLightText
  }
}

const BettingResultWrap = styled.div`
  width: 100%;
  font-weight: 700;
  color: ${props => getColor(props.bettingResult)};
`

const NewBadge = styled(Badge)`
  width: 20px;
  height: 14px;
  background-color: #ff4861;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  margin-left: 5px;

  span {
    width: 20px;
    text-align: center;
  }
`
