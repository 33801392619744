import { Card, CardBody } from '@/shared/components/Card'
import { FormButtonToolbar, FormContainer, FormGroup, FormGroupLabel } from '@/shared/components/form/FormElements'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Form } from 'react-final-form'
import styled from 'styled-components'
import {
  getSportsBettingManage,
  getSportsBettingManageV2,
  updateSportsBettingManage,
  updateSportsBettingManageV2,
} from '../../../utils/api/sport/sportSettingApi'
import { decodeAccessToken } from '../../../utils/token'
import SportManagementTabsV2 from '../SportManagementTabsV2'

const SportSettingManagement = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [state, setState] = useState({
    bettingOkTimeCriteria: 0,
    bettingCancelTimeCriteriaAfterBetting: 0,
    bettingCancelTimeCriteriaBeforeStart: 0,
    bettingCancelCountCriteria: 0,
    bettingSuddenDropCriteria: 0,
    reduceBettingPerForSingleFolder: 0,
    criteriaAllowedSingleFolderBettingPer: 0,
    criteriaAllowedTowFolderSumBettingPer: 0,
    criteriaAllowedMultiFolderBettingPer: 0,
    firstBonusFolderNum: 0,
    firstBonusFolderPer: 0,
    secondBonusFolderNum: 0,
    secondBonusFolderPer: 0,
    thirdBonusFolderNum: 0,
    thirdBonusFolderPer: 0,
    bonusFolderAutoSelect: false,
    bonusFolderPerCriteria: 0,
  })

  const handleInputChange = (name, type) => event => {
    const { value } = event.target // Destructuring used here
    let newValue = value
    if (type === 'double') {
      // 소숫점 두 자리까지 입력 허용
      const match = value.match(/^\d*\.?\d{0,2}/)
      newValue = match ? match[0] : ''
    } else if (type === 'int') {
      // 정수만 입력 허용
      newValue = parseInt(value, 10) || 0
    } else if (type === 'boolean') {
      newValue = value === 'true' // Convert the string back to a boolean
    }

    setState(prevState => ({
      ...prevState,
      [name]: newValue,
    }))
  }

  const fetchSearchSportsBettingManage = async () => {
    const res = await getSportsBettingManageV2({})
    setState(prevState => ({ ...prevState, ...res.data }))
  }

  useEffect(() => {
    fetchSearchSportsBettingManage()
  }, [])

  const onSubmit = e => {
    if (writeLoading) return
    if (window.confirm('설정을 변경 하시겠습니까? ')) {
      const body = {
        newData: state,
      }

      setWriteLoading(true)
      updateSportsBettingManageV2(body)
        .then(res => {
          setWriteLoading(false)
          fetchSearchSportsBettingManage()
          alert('설정이 변경됐습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          fetchSearchSportsBettingManage()
          alert('설정 변경이 실패하였습니다. 다시 시도해주세요.')
        })
    }
  }

  return (
    <Card>
      <CardBody>
        <SportManagementTabsV2 activeKey="13" />
        <BettingSettingWrap>
          <BettingSecondWrap>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { reset } }) => (
                <CustomFormContainer horizontal onSubmit={handleSubmit}>
                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>급락배당 알림기준</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.bettingSuddenDropCriteria}
                          onChange={handleInputChange('bettingSuddenDropCriteria', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> ex) 0.15로 세팅 시, ± 0.15 배당이 바뀌었을 경우 알림발생</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>
                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>베팅마감 시간설정(초)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.bettingOkTimeCriteria}
                          onChange={handleInputChange('bettingOkTimeCriteria', 'int')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP>경기 시작시간 기준 몇 초전까지 가능한지의 값</CustomP>
                      <CustomP> ex) 10으로 세팅 시, 14:00:00경기는 13:59:50까지 베팅가능</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>
                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>베팅취소 시간설정(초) - 베팅 후</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.bettingCancelTimeCriteriaAfterBetting}
                          onChange={handleInputChange('bettingCancelTimeCriteriaAfterBetting', 'int')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP>유저가 베팅 후, 몇초 후까지 취소가능 여부(한 경기라도 시작 시, 취소 불가)</CustomP>
                      <CustomP> ex) 120(초)으로 세팅 시, 13:50:00 베팅 시 13:52:00까지 취소가능</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>
                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>베팅취소 시간설정(초) - 경기시작 전</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.bettingCancelTimeCriteriaBeforeStart}
                          onChange={handleInputChange('bettingCancelTimeCriteriaBeforeStart', 'int')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP>
                        유저가 베팅 후, 경기시작 몇초 전까지 취소가능 여부(한 경기라도 시작 시, 취소 불가)
                      </CustomP>
                      <CustomP> ex) 60(초)으로 세팅 시, 14:00:00 경기는 13:59:00까지 취소가능</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>베팅취소 가능횟수</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.bettingCancelCountCriteria}
                          onChange={handleInputChange('bettingCancelCountCriteria', 'int')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP>하루 기준 최대 취소가능 횟수</CustomP>
                      <CustomP> ex) 3으로 세팅 시, 하루 3번만 취소가능</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>보너스 폴더 자동선택 여부</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <div>
                          <label htmlFor="autoSelectTrue">
                            <input
                              type="radio"
                              style={{ width: '15px', height: '15px', marginRight: '5px' }}
                              id="autoSelectTrue"
                              value="true"
                              checked={state.bonusFolderAutoSelect === true}
                              onChange={handleInputChange('bonusFolderAutoSelect', 'boolean')}
                            />
                            자동선택
                          </label>
                        </div>
                        <div style={{ marginLeft: '5px' }}>
                          <label htmlFor="autoSelectFalse">
                            <input
                              type="radio"
                              style={{ width: '15px', height: '15px', marginRight: '5px' }}
                              id="autoSelectFalse"
                              value="false"
                              checked={state.bonusFolderAutoSelect === false}
                              onChange={handleInputChange('bonusFolderAutoSelect', 'boolean')}
                            />
                            자동선택 안함
                          </label>
                        </div>
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP>유저가 베팅카트에 폴더 넣을 경우, 보너스 기준에 맞을 시 자동선택 여부</CustomP>
                      <CustomP>ex) 체크 시 자동선택</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>보너스 폴더 기준배당</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.bonusFolderPerCriteria}
                          onChange={handleInputChange('bonusFolderPerCriteria', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> ex) 1.3으로 적을 시, 1.3배당 이상만 폴더수로 포함</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>1번째 보너스 폴더 기준(폴더수,배당)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.firstBonusFolderNum}
                          onChange={handleInputChange('firstBonusFolderNum', 'int')}
                        />
                      </CustomFormGroupField>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.firstBonusFolderPer}
                          onChange={handleInputChange('firstBonusFolderPer', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> x폴더 이상 시 x.xx배당 보너스 </CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>2번째 보너스 폴더 기준(폴더수,배당)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.secondBonusFolderNum}
                          onChange={handleInputChange('secondBonusFolderNum', 'int')}
                        />
                      </CustomFormGroupField>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.secondBonusFolderPer}
                          onChange={handleInputChange('secondBonusFolderPer', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> x폴더 이상 시 x.xx배당 보너스 </CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>1번째 보너스 폴더 기준(폴더수,배당)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.thirdBonusFolderNum}
                          onChange={handleInputChange('thirdBonusFolderNum', 'int')}
                        />
                      </CustomFormGroupField>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.thirdBonusFolderPer}
                          onChange={handleInputChange('thirdBonusFolderPer', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> x폴더 이상 시 x.xx배당 보너스 </CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>단폴더 베팅 시 삭감율(%)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.reduceBettingPerForSingleFolder}
                          onChange={handleInputChange('reduceBettingPerForSingleFolder', 'int')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> X 입력시, 기존 배당의 x % 삭감</CustomP>
                      <CustomP> ex) 3으로 입력 시, 2.00 배당은 -&gt; 2.00 - (2.00 * 0.03) 으로 변경</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>단폴더 베팅시 배당 허용기준</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.criteriaAllowedSingleFolderBettingPer}
                          onChange={handleInputChange('criteriaAllowedSingleFolderBettingPer', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> X 입력시, 단폴 배당율이 X 이상되어야 베팅이 성립 </CustomP>
                      <CustomP> ex) 1.55로 입력 시, 단폴 배당율의 1.55 이상 되어야 베팅이 성립</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>2폴더 베팅시 배당 허용기준</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.criteriaAllowedTowFolderSumBettingPer}
                          onChange={handleInputChange('criteriaAllowedTowFolderSumBettingPer', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP> X 입력시, 두폴더의 배당율의 합이 X 이상 되어야 베팅이 성립 </CustomP>
                      <CustomP> ex) 2.5로 입력 시, 두폴 구성 배당합이 2.5 이상 되어야 베팅이 성립</CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>

                  <CustomWrap>
                    <CustomFormFieldWrap>
                      <CustomFormGroupLabel>다폴더 베팅시 배당 허용기준</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <CustomInput
                          type="text"
                          value={state.criteriaAllowedMultiFolderBettingPer}
                          onChange={handleInputChange('criteriaAllowedMultiFolderBettingPer', 'double')}
                        />
                      </CustomFormGroupField>
                    </CustomFormFieldWrap>
                    <CustomFormContentWrap>
                      <CustomP>
                        X 입력시, 다폴 구성 배당율 X 이상의 폴더가 반드시 하나이상 일때 시스템에서 베팅이 성립{' '}
                      </CustomP>
                      <CustomP>
                        ex) 1.4로 입력 시, 1.4 이상의 폴더가 반드시 하나이상 일때 시스템에서 베팅이 성립
                      </CustomP>
                    </CustomFormContentWrap>
                  </CustomWrap>
                  {
                    {
                      SUPER: (
                        <CustomCFormButtonToolbar className="registrationFooter">
                          <Button variant="primary" type="submit">
                            변경하기
                          </Button>
                        </CustomCFormButtonToolbar>
                      ),
                    }[authority]
                  }
                </CustomFormContainer>
              )}
            </Form>
          </BettingSecondWrap>
        </BettingSettingWrap>
      </CardBody>
    </Card>
  )
}

export default SportSettingManagement

const BettingSettingWrap = styled.div`
  width: 100%;
  gap: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 20px;
`
const SportMenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 3px solid black;
  `};
`

const AuthoritySearchButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 5px 0;
`

const CustomP = styled.p`
  color: black;
  font-size: 15px;
`

const CustomFormContainer = styled(FormContainer)`
  width: 100%;
  flex-direction: row;
`

const CustomWrap = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  border-bottom: dotted 1px black;
`

const BettingSecondWrap = styled.div`
  width: 100%;
`

const CustomFormGroupField = styled.div`
  // width: 110px !important;
  height: 30px;
  justify-content: center;
  display: flex;
`

const CustomFormGroupLabel = styled(FormGroupLabel)`
  width: 220px !important;
  color: black;
  height: 32px;
  border: solid 1px black;
  text-align: left;
  justify-content: left;
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  padding-left: 5px;
  // margin-right: -15px !important;
  background: #eee;
`

const CustomFormGroup = styled(FormGroup)`
  width: 100% !important;
  border: solid 1px red;
  // border-bottom: solid 1px black;
`

const CustomFormFieldWrap = styled.div`
  width: 40%;
  // margin-right: 5px;
  padding: 0 5px;
  align-items: center;
  display: flex;
  margin-top: 10px;
  gap: 10px;
`

const CustomFormContentWrap = styled.div`
  width: 100%;
  min-height: 48px;
  padding-left: 5px;
  background: #fff79e;
  justify-content: center;
  align-items: left;
  display: flex;
  border: solid 1px black;
  flex-direction: column;
  margin-top: 10px;
`

export const CustomInput = styled.input`
  width: 60px !important;
  font-size: 13px;
  font-weight: 600;
  color: black;
  // margin-left: 30px;
  border: solid 1px black !important;
`
const CustomCFormButtonToolbar = styled(FormButtonToolbar)`
  width: 80%;
`
