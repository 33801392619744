import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import {
  SportBettingPerStatusEnums,
  SportBettingPerStatusEnumsSelectOptions,
  SportSumBettingTypeEnumsV2,
  SportSumBettingTypeV2SelectOptions,
} from '../../../../enums/SystemManagement/sportEnums'
import { CustomSelect } from '../../../../shared/components/CustomSelect'
import Loading from '../../../../shared/components/Loading'
import { SportDetailInfoValidate } from '../../../../utils/validate/sport/sportSettingManage'

function MarketModifyModalForm({
  sportMarketType,
  marketDetailInfo,
  onModalShowHandler,
  onChangeSportsDetailInfoHandler,
}) {
  const [infoLoading, setInfoLoading] = useState(false)
  const [newMarketNameKo, setNewMarketNameKo] = useState('')
  const [newSumbettingPer, setNewSumbettingPer] = useState(0)
  const [newMiniMumBettingPer, setNewMiniMumBettingPer] = useState(0)
  const [newMaxiMumBettingPer, setNewMaxiMumBettingPer] = useState(0)
  const [newMarketSortNum, setNewMarketSortNum] = useState(0)

  // 회원 입금 레벨
  const [miniMumBettingPerStatusValue, setMiniMumBettingPerStatusValue] = useState(null)
  const [miniMumBettingPerStatusOptions, setMiniMumBettingPerStatusOptions] = useState(
    SportBettingPerStatusEnumsSelectOptions(),
  )
  const onChangeMiniMumBettingPerStatusHandler = option => {
    setMiniMumBettingPerStatusValue(option)
  }

  const [maxiMumBettingPerStatusValue, setMaxiMumBettingPerStatusValue] = useState(null)
  const [maxiMumBettingPerStatusOptions, setMaxiMumBettingPerStatusOptions] = useState(
    SportBettingPerStatusEnumsSelectOptions(),
  )
  const onChangeMaxiMumBettingPerStatusHandler = option => {
    setMaxiMumBettingPerStatusValue(option)
  }

  const [sumBettingPerBigStatusValue, setSumBettingPerBigStatusValue] = useState(null)
  const [sumBettingPerBigStatusOptions, setSumBettingPerBigStatusOptions] = useState(
    SportSumBettingTypeV2SelectOptions(),
  )
  const onChangeSumBettingPerBigStatusHandler = option => {
    setSumBettingPerBigStatusValue(option)
  }

  const [sumBettingPerSmallStatusValue, setSumBettingPerSmallStatusValue] = useState(null)
  const [sumBettingPerSmallStatusOptions, setSumBettingPerSmallStatusOptions] = useState(
    SportSumBettingTypeV2SelectOptions(),
  )
  const onChangeSumBettingPerSmallStatusHandler = option => {
    setSumBettingPerSmallStatusValue(option)
  }

  const getBettingValues = (marketInfo, type, property) => {
    const typeMap = {
      DOMESTIC: 'domestic',
      OVERSEAS: 'overseas',
      SPECIAL: 'special',
      LIVE: 'live',
    }
    return marketInfo[`${typeMap[type]}${property}`]
  }

  const getStatusOption = (status, enums) => ({
    value: status,
    label: enums[status] || 'Unknown',
  })

  useEffect(() => {
    if (!marketDetailInfo) return
    const marketType = sportMarketType.toUpperCase()
    setNewMarketSortNum(marketDetailInfo.marketSortNum)

    setNewMarketNameKo(marketDetailInfo.marketNameKo)
    setNewSumbettingPer(getBettingValues(marketDetailInfo, marketType, 'SumBettingPer'))
    setNewMiniMumBettingPer(getBettingValues(marketDetailInfo, marketType, 'MinimumBettingPer'))
    setNewMaxiMumBettingPer(getBettingValues(marketDetailInfo, marketType, 'MaximumBettingPer'))

    setMiniMumBettingPerStatusValue(
      getStatusOption(
        getBettingValues(marketDetailInfo, marketType, 'MinimumBettingPerStatus'),
        SportBettingPerStatusEnums,
      ),
    )
    setMaxiMumBettingPerStatusValue(
      getStatusOption(
        getBettingValues(marketDetailInfo, marketType, 'MaximumBettingPerStatus'),
        SportBettingPerStatusEnums,
      ),
    )

    setSumBettingPerBigStatusValue(
      getStatusOption(getBettingValues(marketDetailInfo, marketType, 'SumBettingBigType'), SportSumBettingTypeEnumsV2),
    )
    setSumBettingPerSmallStatusValue(
      getStatusOption(
        getBettingValues(marketDetailInfo, marketType, 'SumBettingSmallType'),
        SportSumBettingTypeEnumsV2,
      ),
    )
  }, [marketDetailInfo, sportMarketType])

  const newSubmit = e => {}
  const onSubmit = e => {
    onChangeSportsDetailInfoHandler(
      sportMarketType,
      marketDetailInfo.marketKey,
      newMarketNameKo,
      newMiniMumBettingPer,
      miniMumBettingPerStatusValue.value,
      newMaxiMumBettingPer,
      maxiMumBettingPerStatusValue.value,
      newSumbettingPer,
      newMarketSortNum,
      sumBettingPerBigStatusValue.value,
      sumBettingPerSmallStatusValue.value,
    )
    onModalShowHandler()
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={SportDetailInfoValidate}
      initialValues={{
        sumBettingPer: newSumbettingPer,
        minimumBettingPer: newMiniMumBettingPer,
        maximumBettingPer: newMaxiMumBettingPer,
        marketName: newMarketNameKo,
      }}
    >
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          {infoLoading ? (
            <Loading />
          ) : (
            <>
              <FormGroup>
                <FormGroupLabel>마켓명(원본)</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input {...input} type="text" value={marketDetailInfo.marketName} disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>마켓명(수정)</FormGroupLabel>
                <FormGroupField>
                  <Field name="marketName">
                    {({ input, meta }) => (
                      <FormInputWrap style={{ display: 'flex', flexDirection: 'column' }}>
                        <input
                          {...input}
                          type="text"
                          value={newMarketNameKo}
                          onChange={e => {
                            input.onChange(e)
                            setNewMarketNameKo(e.target.value)
                          }}
                        />
                        {meta.touched && meta.error && <Error error={meta.error} />}
                        <span style={{ color: 'red', fontSize: '11px' }}>
                          마켓명은 국내,해외,스페셜,라이브 공통사항
                        </span>
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  현시 최소배당 / 기준
                  <br />
                  (0: 미사용)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="minimumBettingPer">
                    {({ input, meta }) => (
                      <FormInputWrap style={{ display: 'flex', flexDirection: 'column' }}>
                        <input
                          type="text"
                          value={newMiniMumBettingPer}
                          onChange={e => {
                            setNewMiniMumBettingPer(e.target.value)
                          }}
                        />
                        {meta.touched && meta.error && <Error error={meta.error} />}
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="miniMumBettingPerStatus">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <MemberResterBankSelect
                          value={miniMumBettingPerStatusValue}
                          onChange={option => {
                            onChangeMiniMumBettingPerStatusHandler(option)
                          }}
                          options={miniMumBettingPerStatusOptions}
                          className="react-select"
                          classNamePrefix="react-select"
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  현시 최대배당 / 기준
                  <br />
                  (0: 미사용)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="maximumBettingPer">
                    {({ input, meta }) => (
                      <FormInputWrap style={{ display: 'flex', flexDirection: 'column' }}>
                        <input
                          type="text"
                          value={newMaxiMumBettingPer}
                          onChange={e => {
                            setNewMaxiMumBettingPer(e.target.value)
                          }}
                        />
                        {meta.touched && meta.error && <Error error={meta.error} />}
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <MemberResterBankSelect
                          value={maxiMumBettingPerStatusValue}
                          onChange={option => {
                            onChangeMaxiMumBettingPerStatusHandler(option)
                          }}
                          options={maxiMumBettingPerStatusOptions}
                          className="react-select"
                          classNamePrefix="react-select"
                          style={{ width: '100%', border: 'none' }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>합배당(0:미사용)</FormGroupLabel>
                <FormGroupField>
                  <Field name="sumBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ display: 'flex', flexDirection: 'column' }}>
                          <input
                            type="text"
                            value={
                              marketDetailInfo.offerBettingPerCount !== 2
                                ? '해당 마켓은 합배당이 불가합니다.'
                                : newSumbettingPer
                            }
                            onChange={e => {
                              setNewSumbettingPer(e.target.value)
                            }}
                            disabled={marketDetailInfo.offerBettingPerCount !== 2}
                            placeholder={
                              marketDetailInfo.offerBettingPerCount !== 2 ? '해당 마켓은 합배당이 불가합니다.' : ''
                            }
                          />
                          {meta.touched && meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  합배당
                  <br />
                  보다 작을때
                </FormGroupLabel>
                <FormGroupField>
                  {marketDetailInfo.offerBettingPerCount !== 2 ? (
                    <>
                      <Field name="sumBettingPer">
                        {({ input, meta }) => (
                          <>
                            <FormInputWrap style={{ display: 'flex', flexDirection: 'column' }}>
                              <input type="text" value="해당 마켓은 합배당이 불가합니다." disabled />
                            </FormInputWrap>
                          </>
                        )}
                      </Field>
                    </>
                  ) : (
                    <>
                      <Field name="sumBettingSmallType">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <MemberResterBankSelect
                              value={sumBettingPerSmallStatusValue}
                              onChange={option => {
                                onChangeSumBettingPerSmallStatusHandler(option)
                              }}
                              options={sumBettingPerSmallStatusOptions}
                              isDisabled={marketDetailInfo.offerBettingPerCount !== 2}
                              placeholder={
                                marketDetailInfo.offerBettingPerCount !== 2 ? '해당 마켓은 합배당이 불가합니다.' : ''
                              }
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </>
                  )}
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  합배당
                  <br />
                  보다 클때
                </FormGroupLabel>
                <FormGroupField>
                  {marketDetailInfo.offerBettingPerCount !== 2 ? (
                    <>
                      <Field name="sumBettingPer">
                        {({ input, meta }) => (
                          <>
                            <FormInputWrap style={{ display: 'flex', flexDirection: 'column' }}>
                              <input type="text" value="해당 마켓은 합배당이 불가합니다." disabled />
                            </FormInputWrap>
                          </>
                        )}
                      </Field>
                    </>
                  ) : (
                    <>
                      <Field name="sumBettingBigType">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <MemberResterBankSelect
                              value={sumBettingPerBigStatusValue}
                              onChange={option => {
                                onChangeSumBettingPerBigStatusHandler(option)
                              }}
                              options={sumBettingPerBigStatusOptions}
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </>
                  )}
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>마켓 순서</FormGroupLabel>
                <FormGroupField>
                  <Field name="marketSortNum">
                    {({ input, meta }) => (
                      <FormInputWrap style={{ display: 'flex', flexDirection: 'column' }}>
                        <input
                          {...input}
                          type="text"
                          value={newMarketSortNum}
                          onChange={e => {
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')
                            setNewMarketSortNum(onlyNumber)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                <CustomButton variant="primary" type="submit">
                  변경하기
                </CustomButton>
              </FormButtonToolbar>
            </>
          )}
        </FormContainer>
      )}
    </Form>
  )
}

export default MarketModifyModalForm

MarketModifyModalForm.propTypes = {
  sportMarketType: PropTypes.string.isRequired,
  onChangeSportsDetailInfoHandler: PropTypes.func.isRequired,
  onModalShowHandler: PropTypes.func.isRequired,
  marketDetailInfo: PropTypes.shape({
    sportName: PropTypes.string.isRequired,
    marketId: PropTypes.number.isRequired,
    marketName: PropTypes.string.isRequired,
    marketNameKo: PropTypes.string.isRequired,
    marketSortNum: PropTypes.number.isRequired,
    domesticMarket: PropTypes.bool.isRequired,
    settlementMarket: PropTypes.bool.isRequired,
    minimumBettingPer: PropTypes.number.isRequired,
    minimumBettingPerStatus: PropTypes.string.isRequired,
    maximumBettingPer: PropTypes.number.isRequired,
    maximumBettingPerStatus: PropTypes.string.isRequired,
    sumBettingPer: PropTypes.number.isRequired,
    offerBettingPerCount: PropTypes.number.isRequired,
    marketKey: PropTypes.string.isRequired,
    firstOfferBettingPerByMultiply: PropTypes.string.isRequired,
    secondOfferBettingPerByMultiply: PropTypes.string.isRequired,
    thirdOfferBettingPerByMultiply: PropTypes.string.isRequired,
    firstOfferBettingPerByPlus: PropTypes.string.isRequired,
    secondOfferBettingPerByPlus: PropTypes.string.isRequired,
    thirdOfferBettingPerByPlus: PropTypes.string.isRequired,
    isMainMarket: PropTypes.bool.isRequired,
    overseasMarket: PropTypes.bool.isRequired,
    specialMarket: PropTypes.bool.isRequired,
    oneFolderBettingAllow: PropTypes.bool.isRequired,
    domesticMaxLineType: PropTypes.string.isRequired,
    overseasMaxLineType: PropTypes.string.isRequired,
    specialMaxLineType: PropTypes.string.isRequired,
    liveMaxLineType: PropTypes.string.isRequired,
    sportsBetLineShowType: PropTypes.string.isRequired,
  }).isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`

export const MemberResterBankSelect = styled(CustomSelect)`
  height: 35px;
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
    border: 1px solid #3c3c3c;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }

  .react-select__control {
    background-color: ${props => props.isDisabled && 'lightgrey'} !important;
    // border: 1px solid #3c3c3c;

    &.react-select__control--is-focused,
    &:hover {
      border-color: #3c3c3c !important;
    }
  }
`
