import { Card, CardBody } from '@/shared/components/Card'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { EditorState } from 'draft-js'
import { Field, Form } from 'react-final-form'
import { Editor } from 'react-draft-wysiwyg'
import Select from 'react-select'
import showResults from '../../../utils/showResults'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import SystemManagementTabs from '../SystemManagementTabs'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import { convertFromFront } from '../../../utils/editor'
import WithdrawalRuleForm from './WithdrawalRuleForm'
import DepositRuleForm from './DepositRuleForm'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorFieldsBorder,
  colorIcon,
  colorText,
} from '../../../utils/palette'
import { searchDepositBonus, updateDepositBonus } from '../../../utils/api/systemManage/depositWithdrawalRuleManageApi'
import { DepositBounsTypeEnumsSelectOptions } from '../../../enums/MemberHistory/MemberDepositEnums'
import Loading from '../../../shared/components/Loading'

const DepositWithdrawalRuleManagement = () => {
  const { edgeNames } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNames: headerInfo.edgeNames,
    }
  })
  const [edgeName, setEdgeName] = useState(edgeNames[0])
  useEffect(() => {
    if (edgeName) return
    setEdgeName(edgeNames[0])
  }, [edgeNames])

  return (
    <Card>
      <CardBody>
        <SystemManagementTabs activeKey="9" />
        <DepositBonusManagementWrap>
          <DepositRuleForm edgeName={edgeName} />
          <WithdrawalRuleForm edgeName={edgeName} />
        </DepositBonusManagementWrap>
      </CardBody>
    </Card>
  )
}

export default DepositWithdrawalRuleManagement

const DepositBonusManagementWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  margin-top: 10px;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 630px;
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`

const InputBox = styled.div`
  flex: 1 1 0%;

  input {
    font-weight: 700;
    color: #000;
    width: 100%;
    height: 40px;
    border: 1px solid #738087;
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    text-align: left;
    padding-left: 10px;
    border-radius: 8px;
  }
`

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 70px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
`

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`

const CustomSelect = styled(Select)`
  width: 150px;
  height: 40px;
  font-size: 12px;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      border-right: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
