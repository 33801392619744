import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 카지노 내역 삭제
 */
export async function deleteCasinoBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/delete/casino/bettinglist',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 홀덤 내역 삭제
 */
export async function deleteHoldemBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/delete/holdem/bettinglist',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 미니게임 내역 삭제
 */
export async function deleteMiniGameBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/delete/minigame/bettinglist',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 토큰게임 내역 삭제
 */
export async function adminDeleteTokenGameHistory(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/delete/tokengame-betting',
    method: 'PUT',
    data: body,
  })

  return response
}

/**
 * 스포츠 내역 삭제
 */
export async function deleteSportsBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/betting/sports/delete/sports/bettinglist',
    method: 'PUT',
    data: body,
  })

  return response
}
