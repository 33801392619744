import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { VirtualGameSportType, VirtualResultStatusEnums } from '../../../enums/VirtualGame/virtualGameEnums'
import { CustomBtnTableWrap } from '../../../shared/components/TableElements'
import { searchVirtualGameBettingByBettingLogKey } from '../../../utils/api/logManage/logApi'
import { convertToKstDateTimeSimple } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import VirtualDetailBettingLog from '../../BettingManagement/VirtualGameBettingLog/VirtualDetailBettingLog'

const VirtualGameBettingInfo = ({ bettingUserId, bettingLogKey, receiverType }) => {
  const [isMemberNoteModalShow, setMemberNoteModalShow] = useState(false)
  const [apiFlag, setApiFlag] = useState(false)
  const [content, setContent] = useState([])
  const [visible, setVisible] = useState(false)
  const onClickHandler = () => {
    setApiFlag(true)
    setMemberNoteModalShow(prevState => !prevState)
  }
  const fetchSearchVirtualGameBettingLog = async () => {
    if (!bettingLogKey) return
    setApiFlag(true)
    await searchVirtualGameBettingByBettingLogKey({ bettingLogKey })
        .then(res => {
          res.data.content.isVisible = false
          const newContent = res.data.content // 수정된 객체를 newContent에 할당합니다.
          setContent(newContent) // setContent 함수로 상태 업데이트
        })
        .finally(() => {
          setApiFlag(false)
        })
  }

  useEffect(() => {
    if (!bettingLogKey) return
    if (!apiFlag) return
    fetchSearchVirtualGameBettingLog()
  }, [bettingLogKey, apiFlag])

  // 검색하기 버튼
  // 검색하기 버튼
  const onSearchBettingHistoryHandler = (num, bettingLogKey) => {
    setVisible(prevState => !prevState)
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('2')
  return (
      <CustomBtnTableWrap style={{ marginLeft: '10px' }}>
        <CustomButton2 role={Button} onClick={() => onClickHandler()}>
          배팅정보
        </CustomButton2>
        <CustomStyledModal show={isMemberNoteModalShow} onHide={onClickHandler} $header>
          <CustomModalHeader>
            <CustomModalCloseButton
                className="lnr lnr-cross"
                aria-label="close-btn"
                type="button"
                onClick={onClickHandler}
            />
            <CustomModalTitle>
              <span style={{ color: 'blue', fontWeight: '600' }}>배팅세부정보({bettingUserId})</span> - 행 클릭 시,
              세부배팅 확인
            </CustomModalTitle>
          </CustomModalHeader>
          <CustomModalBody>
            <SportsGameListTableWrap>
              <SportsGameListTable>
                <SportsGameListTableHead>
                  <SportsGameListTableTr>
                    <th style={{ width: '12%' }}>배팅일</th>
                    <th style={{ width: '22%' }}>유저ID(명)-권한</th>
                    <th style={{ width: '8%' }}>타입</th>
                    <th style={{ width: '15%', color: 'red' }}>배팅액</th>
                    <th style={{ width: '8%' }}>폴더</th>
                    <th style={{ width: '8%' }}>배당</th>
                    <th style={{ width: '19%' }}>예상/최종 당첨금</th>
                    <th style={{ width: '8%' }}>결과</th>
                  </SportsGameListTableTr>
                </SportsGameListTableHead>
                <SportsGameListTableBody key={content.virtualBettingLogKey}>
                  <SportsGameListTableTr>
                    <SportsGameFolderContentBox>
                      <SportsGameFolderContentTime
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        {convertToKstDateTimeSimple(content.bettingAt)}
                      </SportsGameFolderContentTime>
                      <SportsGameFolderContentHomeBox
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        <SportsGameFolderContentHomeName>
                          {content.userId}({content.userName})-{NoteAuthorityEnums[content.userAuthority]}
                        </SportsGameFolderContentHomeName>
                      </SportsGameFolderContentHomeBox>
                      <SportsGameFolderNumBox
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        {VirtualGameSportType[content.virtualGameSportType]}
                      </SportsGameFolderNumBox>
                      <SportsGameFolderContentCriteria
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        {content?.bettingAmount?.toString().replace(commonReg2, ',')}
                      </SportsGameFolderContentCriteria>
                      <SportsGameFolderContentAwayBox
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        <SportsGameFolderContentAwayPer>{content.folderSize}폴더</SportsGameFolderContentAwayPer>
                      </SportsGameFolderContentAwayBox>
                      <SportsGameFolderScoreBox
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        {content.totalOddValue}
                      </SportsGameFolderScoreBox>
                      <SportsGameFolderResultBox
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        {content?.bettingExpectedAmount?.toString().replace(commonReg2, ',')}/
                        {content?.bettingResultAmount?.toString().replace(commonReg2, ',')}
                      </SportsGameFolderResultBox>
                      <SportsGameFolderManualResultBox
                          onClick={() => {
                            onSearchBettingHistoryHandler(content.num, content.virtualBettingLogKey)
                          }}
                      >
                        <CustomButton type="button" bettingResult={content.virtualResultStatus}>
                          {VirtualResultStatusEnums[content.virtualResultStatus]}
                        </CustomButton>
                      </SportsGameFolderManualResultBox>
                    </SportsGameFolderContentBox>
                  </SportsGameListTableTr>
                  {visible && (
                      <SportsGameListTableTr>
                        <VirtualDetailBettingLog content={content.virtualDetailBettingLogList} />
                      </SportsGameListTableTr>
                  )}
                </SportsGameListTableBody>
              </SportsGameListTable>
            </SportsGameListTableWrap>
          </CustomModalBody>
        </CustomStyledModal>
      </CustomBtnTableWrap>
  )
}

export default VirtualGameBettingInfo

VirtualGameBettingInfo.propTypes = {
  bettingUserId: PropTypes.string.isRequired,
  bettingLogKey: PropTypes.string.isRequired,
  receiverType: PropTypes.string.isRequired,
}

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const CustomStyledModal = styled(NoteCustomStyledModal)`
  .modal-dialog {
    max-width: 1100px !important;
  }
`

const CustomButton2 = styled.button`
  background-color: orange;
  color: white;
  border: none;
`

const CustomButton = styled.button`
  width: 50px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const SportsGameFolderEdgeBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderNumBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderScoreBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResultBox = styled.div`
  width: 19%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`
const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 22%;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGamePartnerBox = styled.div`
  width: 12%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`

const SportsGameMoneyContent = styled.div`
  font-size: 13px;
  color: white;
  width: 9%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  color: white;
  width: 12%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 36px;
`
