import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import isLogin from '../../enums/authority'
import { reAuthenticationAction } from '../../redux/InitInfoSlice'
import {
  fetchBasicInfoAction,
  fetchHeaderAlarmInfoAction,
  fetchHeaderInfoAction,
  fetchPotInfoAction,
} from '../../redux/headerInfoSlice'

const MainWrapper = ({ children }) => {
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    if (!isLogin()) return

    // 보유알 조회
    dispatch(fetchPotInfoAction())

    dispatch(fetchHeaderInfoAction())
    dispatch(fetchHeaderAlarmInfoAction())
    dispatch(fetchBasicInfoAction())

    // 인터벌 설정
    const intervalId = setInterval(() => {
      dispatch(fetchHeaderInfoAction())
      dispatch(fetchHeaderAlarmInfoAction())
    }, 5000)

    const pathName = location.pathname
    const newWindow = pathName.includes('real-time-betting') || pathName.includes('user-info')
    dispatch(reAuthenticationAction({ newWindow }))

    return () => {
      clearInterval(intervalId) // 인터벌 정리
    }
  }, [dispatch, location])

  return <div>{children}</div>
}

MainWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainWrapper

const MobileViewWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: lightgray;
`

const MobileViewInfoWrap = styled.div`
  width: 100%;
  max-width: 400px;

  height: 200px;
  max-height: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MobileViewInfo = styled.p`
  font-size: 23px;
  line-height: 50px;
  font-weight: 400;

  color: black;

  text-align: center;
`
