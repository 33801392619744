import { Button } from '@/shared/components/Button'
import { PartnerNoteSelect } from '@/shared/components/CustomSelect'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupFile,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import {
  publishToOnePartnerNote,
  searchNotePartnerList,
  publishToLinePartnerNote,
} from '@/utils/api/operation/operationApi'
import styled from 'styled-components'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { searchMacroSelect } from '@/utils/api/systemManage/systemManageApi'
import { useSelector } from 'react-redux'
import { convertFromFront, convertFromServer } from '@/utils/editor'
import { EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { decodeAccessToken } from '../../../utils/token'
import { partnerNoteValidate } from '../../../utils/validate/partnerManage/partnerNoteValidate'
import Loading from '../../../shared/components/Loading'
import '../../Operation/noteCustomStyle.css'

function PartnerNoteForm({ userId, isTotal, onClickHandler }) {
  const { edgeNameRadio } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNameRadio: headerInfo.edgeNameRadio,
    }
  })
  const [edgeName, setEdgeName] = useState('')
  const authority = decodeAccessToken()?.adminAuthority
  const [noteLoading, setNoteLoading] = useState(false)

  // 쪽지 제목
  const [noteTitle, setNoteTitle] = useState('')
  const onChangeNoteTitle = e => {
    setNoteTitle(e.target.value)
  }

  // 쪽지 내용
  const [noteContent, setNoteContent] = useState('')

  const onChangeNodeContentHandler = editorState => {
    setNoteContent(editorState)
  }
  // 쪽지 목록
  const [noteOption, setNoteOption] = useState([])
  const [noteOptionValue, setNoteOptionValue] = useState(null)
  const onChangeNoteSelectHandler = option => {
    if (option === null) {
      setNoteOptionValue(null)
      setNoteTitle('')
      setNoteContent('')
      return
    }
    setNoteOptionValue(option)
    setNoteTitle(option.label)
    setNoteContent(option.noteContent)
  }

  // 쪽지 대상

  const [noteTargetOptionValue, setNoteTargetOptionValue] = useState(null)
  const [noteTargetOption, setNoteTargetOption] = useState([])

  const onChangeNoteTargetHandler = option => {
    setNoteTargetOptionValue(option)
  }

  // 사진 첨부
  const [imageFile, setImageFile] = useState()

  const handleChange = e => {
    e.preventDefault()
    const files = [...e.target.files]
    setImageFile({ file: files[0], name: files[0].name })
  }

  const [selectedEdgeName, setSelectedEdgeName] = useState('')

  useEffect(() => {
    searchMacroSelect({
      userId,
      searchType: 'NOTE',
    }).then(res => {
      res.data.content.map(item =>
        setNoteOption(prev => [
          ...prev,
          {
            value: item.macroTitle,
            label: item.macroTitle,
            noteContent: item.macroContent,
          },
        ]),
      )
    })

    if (isTotal) {
      searchNotePartnerList({}).then(res => {
        res.data.partnerList.map(item =>
          setNoteTargetOption(prev => [
            ...prev,
            {
              value: item.partnerKey,
              label: `${item.partnerId} / ${item.partnerName}[${item.partnerLevel}LV]`,
            },
          ]),
        )
      })
    }
  }, [selectedEdgeName])

  const onSubmit = e => {
    if (noteLoading) return
    if (isTotal) {
      const body = {
        partnerKey: !e.noteTarget.value ? null : e.noteTarget.value,
        noteTitle,
        noteContent,
      }
      setNoteLoading(true)
      publishToLinePartnerNote(body)
        .then(res => {
          setNoteLoading(false)
          alert('전체 쪽지 보내기가 완료되었습니다.')
          onClickHandler()
        })
        .catch(error => {
          setNoteLoading(false)
          alert('쪽지 보내기가 실패했습니다. 잠시 후 다시 시도해주세요.')
        })
    } else {
      if (!edgeName) {
        alert('사이트를 선택해주세요.')
        return
      }
      const body = {
        edgeName,
        noteTitle,
        noteContent,
      }
      setNoteLoading(true)
      publishToLinePartnerNote(body)
        .then(res => {
          setNoteLoading(false)
          alert('쪽지 보내기가 완료되었습니다.')
          onClickHandler()
        })
        .catch(error => {
          setNoteLoading(false)
          alert('쪽지 보내기가 실패했습니다. 잠시 후 다시 시도해주세요.')
        })
    }
  }

  return (
    <>
      {noteLoading ? (
        <Loading />
      ) : (
        <>
          {isTotal ? (
            <div style={{ marginTop: '-15px', marginBottom: '10px' }}>
              <p>
                * 라인별 쪽지는 <span style={{ color: 'red' }}>파트너</span>를 선택 후 해당 파트너포함 하위조직에 쪽지를
                보낼 수 있습니다.
              </p>
            </div>
          ) : (
            <></>
          )}
          <Form
            onSubmit={onSubmit}
            initialValues={{
              userId,
              selectedNote: noteOptionValue,
              noteTarget: isTotal ? noteTargetOptionValue : {},
              noteTitle,
              noteContent,
              imageFile,
              edgeName,
            }}
            validate={partnerNoteValidate}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                {isTotal ? (
                  <></>
                ) : (
                  <FormGroup>
                    <FormGroupLabel>사이트</FormGroupLabel>
                    {edgeNameRadio.map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          onCustomChange={e => {
                            setEdgeName(e)
                          }}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={edgeName}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroup>
                )}
                {isTotal ? (
                  <FormGroup>
                    <FormGroupLabel>파트너 선택</FormGroupLabel>
                    <FormGroupField>
                      <Field name="noteTarget">
                        {({ input, meta }) => (
                          <FormInputWrap>
                            <PartnerNoteSelect
                              name="noteTarget"
                              value={noteTargetOptionValue}
                              onChange={option => {
                                onChangeNoteTargetHandler(option)
                              }}
                              options={noteTargetOption}
                              clearable={false}
                              placeholder="대상을 선택하세요."
                              className="react-select"
                              classNamePrefix="react-select"
                            />
                            {meta.error && meta.touched && <Error error={meta.error} top />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                ) : (
                  <></>
                )}
                <FormGroup>
                  <FormGroupLabel>쪽지 선택</FormGroupLabel>
                  <FormGroupField>
                    <Field name="selectedNote">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <PartnerNoteSelect
                            {...input}
                            value={input.value}
                            onChange={option => {
                              input.onChange(option)
                              onChangeNoteSelectHandler(option)
                            }}
                            options={noteOption}
                            placeholder="쪽지 선택"
                            className="react-select"
                            classNamePrefix="react-select"
                            isClearable
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>

                <FormGroup>
                  <FormGroupLabel>제목</FormGroupLabel>
                  <FormGroupField>
                    <Field name="noteTitle">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={noteTitle}
                            onChange={e => {
                              input.onChange(e)
                              onChangeNoteTitle(e)
                            }}
                            placeholder="제목을 작성하세요"
                          />
                          {meta.error && meta.touched && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>내용</FormGroupLabel>
                  <CustomFormGroupField>
                    <Field name="noteContent">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <ReactQuill
                            value={noteContent}
                            onChange={value => {
                              setNoteContent(value) // questionContent 업데이트
                            }}
                            theme="snow"
                            modules={{
                              toolbar: [
                                [{ header: '1' }, { header: '2' }, { font: [] }],
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ color: [] }, { background: [] }],
                              ],
                            }}
                          />
                          {meta.touched && meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </CustomFormGroupField>
                </FormGroup>
                {/* <FormGroup>
              <FormGroupLabel>사진 첨부</FormGroupLabel>
              <FormGroupField>
                <Field name="imageFile" type="file">
                  {({ input, meta }) => (
                    <FormGroupFile>
                      <label htmlFor="imageFile">
                        {imageFile ? <span>{imageFile.name}</span> : '사진을 선택하세요'}
                      </label>
                      <input
                        type="file"
                        name="imageFile"
                        id="imageFile"
                        onChange={e => {
                          input.onChange(e)
                          handleChange(e)
                        }}
                        accept="image/*"
                      />
                    </FormGroupFile>
                  )}
                </Field>
              </FormGroupField>
            </FormGroup> */}
                <FormButtonToolbar>
                  {
                    {
                      SUPER: (
                        <>
                          <Button variant="primary" type="submit">
                            쪽지 보내기
                          </Button>
                        </>
                      ),
                    }[authority]
                  }
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default PartnerNoteForm

PartnerNoteForm.propTypes = {
  userId: PropTypes.string,
  isTotal: PropTypes.bool,
  onClickHandler: PropTypes.func.isRequired,
}
PartnerNoteForm.defaultProps = {
  userId: null,
  isTotal: false,
}

export const CustomFormGroupField = styled(FormGroupField)`
  // border: solid 1px #000;
  height: 270px;
  width: 695px !important;
  padding: 0px !important;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    opacity: 0.3;
  }
  margin-left: 20px !important;
  // padding-left: 5px !important;
  padding-top: 5px !important;
`
