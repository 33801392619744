import React, { useEffect, useState } from 'react'

const DuplicationListData = content => {
  const columns = [
    {
      Header: '로그인 IP',
      accessor: 'loginIp',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '유저 목록',
      accessor: 'userIdNameAuthorityList',
      disableGlobalFilter: true,
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default DuplicationListData
