import { Button } from '@/shared/components/Button'
import {
  CouponCustomStyledModal,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
} from '@/shared/components/CustomModal'
import { Form, Field } from 'react-final-form'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { RiCoupon2Line } from 'react-icons/ri'
import styled from 'styled-components'
import { CustomBtnTableWrap } from '../../../shared/components/TableElements'
import ManualProcessResultForm from './ManualProcessResultForm'
import { decodeAccessToken } from '../../../utils/token'
import { MiniGameBettingTypeEnums, MiniGameTypeEnums } from '../../../enums/Log/miniGameBettingEnums'
import { updateMiniGameBetting } from '../../../utils/api/logManage/logApi'
import { commonReg2 } from '../../../utils/validate/commonValidate'

function ManualProcessResult({
  miniGameBettingLogKey,
  memberId,
  gameRound,
  miniGameType,
  miniGameBettingType,
  bettingAmount,
  fetchSearchMiniGameBetting,
}) {
  const authority = decodeAccessToken()?.adminAuthority
  const [isMemberCouponModalShow, setMemberCouponModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberCouponModalShow(prevState => !prevState)
  }
  const [couponLoading, setCouponLoading] = useState(false)
  const [result, setResult] = useState('WIN')
  const handleSelectChange = newValue => {
    setResult(newValue)
  }
  const onSubmit = e => {
    if (couponLoading) return

    const body = {
      miniGameBettingLogKey,
      result,
    }
    setCouponLoading(true)
    updateMiniGameBetting(body)
      .then(res => {
        setCouponLoading(false)
        alert('결과 수정이 성공했습니다.')
        onClickHandler()
        fetchSearchMiniGameBetting()
      })
      .catch(error => {
        setCouponLoading(false)
        alert('결과 수정이 실패했습니다.')
        fetchSearchMiniGameBetting()
      })
  }

  return (
    <CustomBtnTableWrap>
      <span
        onClick={() => onClickHandler()}
        style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
      >
        수동처리
      </span>
      <CouponCustomStyledModal show={isMemberCouponModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>미니게임 수동결과처리 - {memberId}</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>아이디</FormGroupLabel>
                  <FormGroupField>
                    <input name="memberId" type="text" value={memberId} disabled />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>게임종류</FormGroupLabel>
                  <FormGroupField>
                    <input name="miniGameType" type="text" value={MiniGameTypeEnums[miniGameType]} disabled />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>배팅종류</FormGroupLabel>
                  <FormGroupField>
                    <input
                      name="miniGameBettingType"
                      type="text"
                      value={MiniGameBettingTypeEnums[miniGameBettingType]}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>게임라운드</FormGroupLabel>
                  <FormGroupField>
                    <input name="miniGameBettingType" type="text" value={gameRound} disabled />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>배팅액</FormGroupLabel>
                  <FormGroupField>
                    <input
                      name="bettingAmount"
                      type="text"
                      value={bettingAmount?.toString().replace(commonReg2, ',')}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>결과처리</FormGroupLabel>
                  <FormGroupField>
                    <select value={result} onChange={e => handleSelectChange(e.target.value)}>
                      <option value="WIN">적중</option>
                      <option value="LOSE">미적중</option>
                      <option value="CANCEL">취소</option>
                    </select>
                  </FormGroupField>
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">
                          발행하기
                        </Button>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </CustomModalBody>
      </CouponCustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default ManualProcessResult

ManualProcessResult.propTypes = {
  memberId: PropTypes.string.isRequired,
  miniGameBettingLogKey: PropTypes.string.isRequired,
  gameRound: PropTypes.string.isRequired,
  miniGameType: PropTypes.string.isRequired,
  miniGameBettingType: PropTypes.string.isRequired,
  bettingAmount: PropTypes.string.isRequired,
  fetchSearchMiniGameBetting: PropTypes.func.isRequired,
}

const CouponIcon = styled(RiCoupon2Line)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: #ff4500;
`
