import React, { useEffect, useState } from 'react'
import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import Error from '@/shared/components/form/Error'
import { borderRight } from '@/utils/directions'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/esm/locale'
import { SearchDatePickerWrap } from '@/shared/components/Search'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import PropTypes from 'prop-types'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import { decodeAccessToken } from '../../../../../utils/token'
import { SportTypeEnumsSelectOptions } from '../../../../../enums/SystemManagement/sportEnums'
import { getMarketNameBySportType } from '../../../../../utils/api/sport/sportSettingApi'
import { GameSettingSelect } from '../../../GameSettingManagement/GameSettingSelect'

function EventRegistrationForm({ onClickHandler }) {
  const authority = decodeAccessToken()?.adminAuthority

  // useEffect(() => {
  //   searchMacroSelect(macroSelectParams).then(res => {
  //     res.data.content.map(item =>
  //       setAnswerContentOption(prev => [
  //         ...prev,
  //         {
  //           value: item.macroTitle,
  //           label: item.macroTitle,
  //           oneToOneContent: item.macroContent,
  //         },
  //       ]),
  //     )
  //   })
  // }, [])
  const [sportTypeValue, setSportTypeValue] = useState(null)
  const [marketTypeOption1, setMarketTypeOption1] = useState([])
  const [marketTypeValue1, setMarketTypeValue1] = useState(null)

  const sportTypeOptions = SportTypeEnumsSelectOptions()

  const onSportTypeChangeHandler = option => {
    setSportTypeValue(option)
  }

  const onMarketEnumChangeHandler1 = option => {
    setMarketTypeValue1(option)
  }

  useEffect(() => {
    if (sportTypeValue == null) return
    setMarketTypeValue1(null)
    getMarketNameBySportType({ sportsType: sportTypeValue.value }).then(res => {
      const option = res.data.sportMarketNameList.map(item => ({
        value: item.marketId,
        label: item.marketName,
      }))
      setMarketTypeOption1(option)
    })
  }, [sportTypeValue])

  // 등록일 시작
  const [searchRegistrationDate, setSearchRegistrationDate] = useState(null)
  const onSearchRegistrationDateChangeHandler = date => {
    setSearchRegistrationDate(date)
  }

  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!
  // API만들어 둠!!!!!!!!!!!!!!!!!!!!

  const onSubmit = e => {
    // 로딩 넣기!!!!!!!!!!
    // 로딩 넣기!!!!!!!!!!
    // 로딩 넣기!!!!!!!!!!
    // 로딩 넣기!!!!!!!!!!
    // if (!answerContent.getCurrentContent().hasText()) {
    //   alert('답변 매크로 선택 또는 답변을 직접 기입해주세요.')
    //   return
    // }
    // const body = {
    //   edgeKey: getEdgeKey(),
    //   oneToOneKey: onetoOneInfo.oneToOneKey,
    //   answerContent: convertFromFront(answerContent),
    // }
    // updateOnetoOne(body)
    //   .then(res => {
    //     alert('답변이 성공적으로 보내졌습니다.')
    //     onClickHandler()
    //     fetchSearchOnetoOneList()
    //   })
    //   .catch(error => {
    //     alert('답변 보내기가 실패했습니다. 잠시 후 다시 시도해주세요.')
    //   })
  }

  const onConfirmHandler = () => {
    onClickHandler()
    // fetchSearchOnetoOneList()
  }

  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          <div style={{ marginBottom: '30px' }}>
            <p>
              * 수동으로 <span style={{ color: 'red', fontWeight: '500' }}>크로스(승패 경기)</span>만 등록 할 수
              있습니다.
            </p>
            <p>
              * 수동으로 등록한 경기의 결과는 <span style={{ color: 'red', fontWeight: '500' }}>수동 결과처리</span>를
              하셔야 합니다.
            </p>
          </div>
          <FormGroup>
            <FormGroupLabel>경기시간(필수)</FormGroupLabel>
            <FormGroupField>
              <DatePicker
                selected={searchRegistrationDate}
                onChange={date => onSearchRegistrationDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                showTimeInput
                timeFormat="HH:mm"
                popperPlacement="bottom-start"
                placeholderText="클릭하세요"
                isClearable
                locale={ko}
              />
            </FormGroupField>
          </FormGroup>
          <div>
            * 경기종목을 <span style={{ color: 'red', fontWeight: '500' }}> 기타</span>로 선택 시 국가명,리그명은 입력
            불가합니다.
          </div>
          <FormGroup>
            <FormGroupLabel>경기종목(필수)</FormGroupLabel>
            <FormGroupField>
              <Field name="sportType">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <GameSettingSelect
                      {...input}
                      value={sportTypeValue}
                      onChange={option => {
                        input.onChange(option)
                        onSportTypeChangeHandler(option)
                      }}
                      options={sportTypeOptions}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="먼저 선택해주세요."
                    />
                    {meta.touched && meta.error && <Error error={meta.error} top />}
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>국가명(자동 선택)</FormGroupLabel>
            <FormGroupField>
              <Field name="marketType1">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <GameSettingSelect
                      {...input}
                      value={marketTypeValue1}
                      onChange={option => {
                        input.onChange(option)
                        onMarketEnumChangeHandler1(option)
                      }}
                      options={marketTypeOption1}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="경기 종목을 먼저 선택해주세요."
                      isDisabled={sportTypeValue?.value === 'ETC'}
                    />
                    {meta.touched && meta.error && <Error error={meta.error} top />}
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>리그명(자동 선택)</FormGroupLabel>
            <FormGroupField>
              <Field name="marketType1">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <GameSettingSelect
                      {...input}
                      value={marketTypeValue1}
                      onChange={option => {
                        input.onChange(option)
                        onMarketEnumChangeHandler1(option)
                      }}
                      options={marketTypeOption1}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="국가명을 먼저 선택해주세요."
                      isDisabled={sportTypeValue?.value === 'ETC'}
                    />
                    {meta.touched && meta.error && <Error error={meta.error} top />}
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>마켓명(자동)</FormGroupLabel>
            <FormGroupField>
              <Field name="marketType1">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <GameSettingSelect
                      {...input}
                      // value={marketTypeValue1}
                      // onChange={option => {
                      //   input.onChange(option)
                      //   onMarketEnumChangeHandler1(option)
                      // }}
                      // options={marketTypeOption1}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="승패"
                      isDisabled
                    />
                    {meta.touched && meta.error && <Error error={meta.error} top />}
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <div>
            <span style={{ color: 'red', fontWeight: '500' }}>* 마켓종류(수동 입력)는</span> 위 경기종목에서 기타를 클릭
            후 입력 가능합니다. ( ex) 19대 대통령선거 )
          </div>
          <FormGroup>
            <FormGroupLabel>마켓명(수동)</FormGroupLabel>
            <FormGroupField>
              <Field
                name="userId"
                component="input"
                type="text"
                placeholder="경기종목을 기타로 클릭 후 입력가능합니다."
                disabled={sportTypeValue?.value !== 'ETC'}
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>홈팀명</FormGroupLabel>
            <FormGroupField>
              <Field name="userId" component="input" type="text" placeholder="홈팀명" />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>원정팀명</FormGroupLabel>
            <FormGroupField>
              <Field name="userId" component="input" type="text" placeholder="원정팀명" />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>배당(홈,기준점,원정)</FormGroupLabel>
            <FormGroupField>
              <Field name="memberId">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <input
                      {...input}
                      type="text"
                      value="3.5"
                      // onChange={e => {
                      //   const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                      //   onChangeAwayScoreHandler(onlyNumber, key)
                      // }}
                    />
                  </FormInputWrap>
                )}
              </Field>
              <Field name="memberId">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <input {...input} type="text" value="VS" disabled />
                  </FormInputWrap>
                )}
              </Field>
              <Field name="memberId">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <input
                      {...input}
                      type="text"
                      value="1.9"
                      // onChange={e => {
                      //   const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
                      //   onChangeAwayScoreHandler(onlyNumber, key)
                      // }}
                    />
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <FormButtonToolbar style={{ marginLeft: '-10px' }}>
            <>
              {
                {
                  SUPER: (
                    <Button variant="primary" type="submit">
                      등록하기
                    </Button>
                  ),
                }[authority]
              }
            </>
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  )
}

export default EventRegistrationForm

EventRegistrationForm.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
}

// 1대1 문의 Select
export const OneToOneSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  // editor 때문에 추가함
  z-index: 101;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

// endregion
