import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { FormButtonToolbar } from '../../../shared/components/form/FormElements'
import Loading from '../../../shared/components/Loading'
import { searchSportsGameLevel, updateSportsGameLevel } from '../../../utils/api/systemManage/levelManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { sportsGamelevelValidate } from '../../../utils/validate/systemManagement/levelManage'
import { colorAdditional, colorFieldsBorder } from '../../../utils/palette'
import DomesticSportsGameLevel from './DomesticSportsGameLevel'
import OverseasSportsGameLevel from './OverseasSportsGameLevel'
import SpecialSportsGameLevel from './SpecialSportsGameLevel'
import LiveSportsGameLevel from './LiveSportsGameLevel'

const SportsGameLevelForm = ({ selectedLevel }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [domesticSportsGameLevel, setDomesticSportsGameLevel] = useState(null)
  const [overseasSportsGameLevel, setOverseasSportsGameLevel] = useState(null)
  const [specialSportsGameLevel, setSpecialSportsGameLevel] = useState(null)
  const [liveSportsGameLevel, setLiveSportsGameLevel] = useState(null)

  const fetchSportsGameLevel = () => {
    if (loading) return

    setLoading(true)

    searchSportsGameLevel({
      sportsGameLevel: selectedLevel,
    })
      .then(res => {
        const { sportsGameLevelName, sportsGameLossPer, sportsGameCompPer } = res.data
        setDomesticSportsGameLevel(res.data.domesticSportsGameLevel)
        setOverseasSportsGameLevel(res.data.overseasSportsGameLevel)
        setSpecialSportsGameLevel(res.data.specialSportsGameLevel)
        setLiveSportsGameLevel(res.data.liveSportsGameLevel)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSportsGameLevel()
  }, [selectedLevel])
  console.log('domesticLddd', domesticSportsGameLevel)
  // const onSubmit = e => {
  //   if (writeLoading) return

  //   if (window.confirm('스포츠게임 레벨 설정을 변경 하시겠습니까? ')) {
  //     const body = {
  //       sportsGameLevelName,
  //       sportsGameLossPer,
  //       sportsGameCompPer,
  //     }

  //     setWriteLoading(true)

  //     updateSportsGameLevel(body)
  //       .then(res => {
  //         setWriteLoading(false)
  //         alert('스포츠게임 레벨 설정이 변경됐습니다.')
  //         fetchSportsGameLevel()
  //       })
  //       .catch(error => {
  //         setWriteLoading(false)
  //         alert('스포츠게임 레벨 설정 변경을 실패하였습니다. 다시 시도해주세요.')
  //       })
  //   }
  // }
  return (
    <div style={{ display: 'flex' }}>
      {(loading || writeLoading) && <Loading />}
      <div style={{ width: '25%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <DomesticSportsGameLevel
          domesticSportsGameLevel={domesticSportsGameLevel}
          fetchSportsGameLevel={fetchSportsGameLevel}
          selectedLevel={selectedLevel}
        />
      </div>
      <div style={{ width: '25%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <OverseasSportsGameLevel
          overseasSportsGameLevel={overseasSportsGameLevel}
          fetchSportsGameLevel={fetchSportsGameLevel}
          selectedLevel={selectedLevel}
        />
      </div>
      <div style={{ width: '25%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <SpecialSportsGameLevel
          specialSportsGameLevel={specialSportsGameLevel}
          fetchSportsGameLevel={fetchSportsGameLevel}
          selectedLevel={selectedLevel}
        />
      </div>
      <div style={{ width: '25%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <LiveSportsGameLevel
          liveSportsGameLevel={liveSportsGameLevel}
          fetchSportsGameLevel={fetchSportsGameLevel}
          selectedLevel={selectedLevel}
        />
      </div>
    </div>
  )
}

export default SportsGameLevelForm

SportsGameLevelForm.propTypes = {
  selectedLevel: PropTypes.string.isRequired,
}

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 34px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid black;
    color: black;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
`

const FormGroupLabel = styled.div`
  width: 45%;
  height: 100%;
  padding: 3px 10px;
  color: black;
  font-size: 13px;
  line-height: 15px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #dedede;
  border: 1px solid black;
  border-right: none;
`

const FormGroupField = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
`

const FormGroupIcon = styled.div`
  padding: 6px;
  min-width: 40px;
  height: 34px;
  background: ${colorFieldsBorder};
  border: 1px solid black;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colorAdditional};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`

const FormInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`
