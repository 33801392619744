import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import { FormButtonToolbar } from '@/shared/components/form/FormElements'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { searchCasinoLevel, updateCasinoLevel } from '../../../utils/api/systemManage/levelManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { liveLevelmManagementValidate } from '../../../utils/validate/systemManagement/levelManage'
import { colorAdditional, colorFieldsBorder } from '../../../utils/palette'
import Loading from '../../../shared/components/Loading'

const CasinoLevelForm = ({ selectedLevel }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [casinoLevelName, setCasinoLevelName] = useState('')
  const [livePer, setLivePer] = useState('0')

  const fetchCasinoLiveLevel = () => {
    if (loading) return

    setLoading(true)

    searchCasinoLevel({
      casinoLiveLevel: selectedLevel,
    })
      .then(res => {
        const { casinoLevelName, livePer } = res.data
        setCasinoLevelName(casinoLevelName)
        setLivePer(livePer)
        setLoading(false)
      })
      .catch(error => {
        setCasinoLevelName('')
        setLivePer('0')

        setLoading(false)
      })
  }

  useEffect(() => {
    fetchCasinoLiveLevel()
  }, [selectedLevel])

  const onSubmit = e => {
    if (writeLoading) return

    if (window.confirm('카지노 레벨 설정을 변경 하시겠습니까? ')) {
      const body = {
        casinoLevelName,
        livePer,
      }

      setWriteLoading(true)
      updateCasinoLevel(body)
        .then(res => {
          setWriteLoading(false)
          alert('카지노 레벨 설정이 변경됐습니다.')
          fetchCasinoLiveLevel()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('카지노 레벨 설정 변경을 실패하였습니다. 다시 시도해주세요.')
        })
    }
  }

  return (
    <>
      {(loading || writeLoading) && <Loading />}
      <div style={{ width: '100%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <HeaderBox>카지노</HeaderBox>
        <Form
          onSubmit={onSubmit}
          validate={liveLevelmManagementValidate}
          initialValues={{
            casinoLevelName,
            livePer,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel>콤프비율 (%)</FormGroupLabel>
                <FormGroupField>
                  <Field name="livePer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={livePer}
                            onChange={e => {
                              setLivePer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              {authority === 'SUPER' && (
                <FormButtonToolbar className="registrationFooter">
                  <Button variant="primary" type="submit">
                    변경하기
                  </Button>
                </FormButtonToolbar>
              )}
            </FormContainer>
          )}
        </Form>
      </div>
    </>
  )
}

export default CasinoLevelForm

CasinoLevelForm.propTypes = {
  selectedLevel: PropTypes.string.isRequired,
}

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 34px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid black;
    color: black;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
`

const FormGroupLabel = styled.div`
  width: 20%;
  height: 100%;
  padding: 3px 10px;
  color: black;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  border: 1px solid black;
  border-right: none;
`

const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const FormGroupIcon = styled.div`
  padding: 6px;
  min-width: 40px;
  height: 34px;
  background: ${colorFieldsBorder};
  border: 1px solid black;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colorAdditional};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`

const FormInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`
