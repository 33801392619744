import {
  BlackMemberRegisterCustomStyledModal,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
} from '@/shared/components/CustomModal'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { IpRegisterValidate } from '@/utils/validate/memberManage/blackListValidate'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import { updateRestrictionIp } from '../../../utils/api/logManage/logApi'
import { decodeAccessToken } from '../../../utils/token'

function IpRegistrationModal({ isRegistrationModalShow, onRegistrationHandler, fetchSearchRestrictionIP }) {
  const authority = decodeAccessToken()?.adminAuthority
  const onSubmit = e => {
    const body = {
      memo: e.memo,
      restrictionIp: e.restrictionIp,
    }

    updateRestrictionIp(body)
      .then(res => {
        onRegistrationHandler()
        fetchSearchRestrictionIP()
        alert(`${e.restrictionIp}가 차단리스트에 등록되었습니다.`)
      })
      .catch(error => {
        alert(`차단리스트에 등록이 실패했습니다.`)
      })
  }

  return (
    <div>
      <BlackMemberRegisterCustomStyledModal show={isRegistrationModalShow} onHide={onRegistrationHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onRegistrationHandler}
          />
          <CustomModalTitle>IP 차단리스트 등록</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <Form onSubmit={onSubmit} validate={IpRegisterValidate}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <div style={{ marginBottom: '15px' }}>
                  {' '}
                  * <span style={{ color: 'red' }}>IP 차단 시</span> 해당 IP로는 로그인 및 회원가입이 불가합니다.
                </div>
                <FormGroup>
                  <FormGroupLabel>차단 IP</FormGroupLabel>
                  <FormGroupField>
                    <Field name="restrictionIp">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            placeholder="IP를 입력하세요"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>메모</FormGroupLabel>
                  <FormGroupField>
                    <Field name="memo">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={input.value}
                            onChange={e => {
                              input.onChange(e)
                            }}
                            placeholder="간단히 입력하세요"
                          />
                          {meta.touched && meta.error && <Error error={meta.error} top />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar style={{ marginLeft: '-25px' }}>
                        <Button variant="primary" type="submit">
                          IP 차단리스트 등록
                        </Button>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </CustomModalBody>
      </BlackMemberRegisterCustomStyledModal>
    </div>
  )
}

export default IpRegistrationModal

IpRegistrationModal.propTypes = {
  isRegistrationModalShow: PropTypes.bool.isRequired,
  onRegistrationHandler: PropTypes.func.isRequired,
  fetchSearchRestrictionIP: PropTypes.func.isRequired,
}
