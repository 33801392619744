import { ButtonToolbar } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorFieldsBorder,
  colorIcon,
  colorText,
  colorWhite,
} from '@/utils/palette'
import { Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Modal as BootstrapModal, Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { updateSportLeague } from '../../../utils/api/sport/sportLeagueApi'

const LeagueModifyModal = ({
  leagueInfo,
  leagueModifyModalShow,
  onCloseLeagueModifyModalShowHandler,
  fetchSearchSportLeagueList,
}) => {
  const [leagueName, setLeagueName] = useState('')
  const [leagueNameKo, setLeagueNameKo] = useState('')
  const [leagueImageUrl, setLeagueImageUrl] = useState('')
  const [visible, setVisible] = useState(false)

  const [leagueSortNum, setLeagueSortNum] = useState(0)

  useEffect(() => {
    setLeagueName(leagueInfo.leagueName)
    setLeagueNameKo(leagueInfo.leagueNameKo)
    setVisible(leagueInfo.visible)
    setLeagueSortNum(leagueInfo.leagueSortNum)
  }, [leagueInfo])

  const [loading, setLoading] = useState(false)

  const onSubmit = e => {
    if (loading) return
    setLoading(true)

    const body = {
      leagueId: leagueInfo.leagueId,
      leagueNameKo,
      leagueImageUrl: leagueImageUrl || leagueInfo.leagueImageUrl,
      leagueSortNum,
    }

    updateSportLeague(body)
      .then(res => {
        setLoading(false)
        alert('수정이 완료됐습니다.')
        onCloseLeagueModifyModalShowHandler()
        fetchSearchSportLeagueList()
      })
      .catch(error => {
        alert('수정 실패했습니다. 다시 시도해주세요.')
        setLoading(false)
        onCloseLeagueModifyModalShowHandler()
      })
  }

  return (
    <>
      {loading && <Loading />}
      <StyledModal show={leagueModifyModalShow} onHide={onCloseLeagueModifyModalShowHandler} header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onCloseLeagueModifyModalShowHandler}
          />
          <ModalTitle>
            <span>[ {leagueInfo?.leagueNameKo} ] 리그 수정</span>
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          <ContentBox>
            <Form onSubmit={onSubmit} initialValues={{ leagueInfo }}>
              {({ handleSubmit, form: { reset } }) => (
                <CustomFormContainer horizontal onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>국가 코드</FormGroupLabel>
                    <FormGroupField>
                      <Field name="locationId">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input {...input} type="text" value={leagueInfo?.leagueId} readOnly disabled />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>리그 이미지</FormGroupLabel>
                    <FormGroupField>
                      <Field name="locationImageUrl">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input
                              {...input}
                              type="text"
                              value={leagueImageUrl}
                              onChange={e => {
                                setLeagueImageUrl(e.target.value)
                              }}
                              placeholder="변경시 입력하세요."
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>리그 명(원본)</FormGroupLabel>
                    <FormGroupField>
                      <Field name="locationName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input
                              {...input}
                              type="text"
                              value={leagueName}
                              // onChange={e => {
                              //   setLocationName(e.target.value)
                              // }}
                              disabled
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>리그 명(수정)</FormGroupLabel>
                    <FormGroupField>
                      <Field name="locationName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input
                              {...input}
                              type="text"
                              value={leagueNameKo}
                              onChange={e => {
                                setLeagueNameKo(e.target.value)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>정렬 순서</FormGroupLabel>
                    <FormGroupField>
                      <Field name="locationSortNum">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ border: '1px solid black' }}>
                            <input
                              {...input}
                              type="text"
                              value={leagueSortNum}
                              onChange={e => {
                                const { value } = e.target
                                const onlyNumber = value.replace(/[^0-9]/g, '')
                                setLeagueSortNum(onlyNumber)
                              }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormButtonToolbar style={{ marginLeft: '0' }}>
                    <Button variant="primary" type="submit">
                      수정
                    </Button>
                  </FormButtonToolbar>
                </CustomFormContainer>
              )}
            </Form>
          </ContentBox>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default LeagueModifyModal

LeagueModifyModal.propTypes = {
  leagueInfo: PropTypes.shape(),
  leagueModifyModalShow: PropTypes.bool,
  onCloseLeagueModifyModalShowHandler: PropTypes.func,
  fetchSearchSportLeagueList: PropTypes.func,
}

LeagueModifyModal.defaultProps = {
  leagueInfo: {},
  leagueModifyModalShow: false,
  onCloseLeagueModifyModalShowHandler: () => {},
  fetchSearchSportLeagueList: () => {},
}

const ContentBox = styled.div`
  width: 100%;
`

const CustomFormContainer = styled(FormContainer)`
  flex-direction: column;
  padding: 10px 0;
`
const VisibleCheckbox = styled(Checkbox)`
  width: 10px;
  height: 10px;
`

const CustomSelect = styled(Select)`
  width: 100%;
  //   height: 40px;
  font-size: 12px;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      border-right: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: hidden;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    // font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div`
  background-color: grey !important;
  font-weight: 700;
`
const ModalBody = styled.div``

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 500px !important;
    margin-top: 100px;
  }

  .modal-content {
    height: max-content;
    min-height: 400px;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 20px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`
