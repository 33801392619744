import React from 'react'
import PropTypes from 'prop-types'
import { Pagination, Form } from 'react-bootstrap'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon'
import styled from 'styled-components'
import { colorAccent, colorAdditional, colorBackground, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import { marginRight, marginLeft } from '@/utils/directions'

const ReactTablePagination = ({
  gotoPage,
  canPreviousPage,
  pageOptions,
  pageIndex,
  previousPage,
  nextPage,
  canNextPage,
  setPageIndex,
}) => {
  const pageLength = pageOptions.length

  let arrayPageIndex = []

  if (pageLength > 10) {
    if (pageLength - pageIndex < 6) {
      arrayPageIndex = pageOptions.slice(pageLength - 10, pageLength + 1)
    } else {
      arrayPageIndex = pageIndex - 5 < 0 ? pageOptions.slice(0, 10) : pageOptions.slice(pageIndex - 4, pageIndex + 6)
    }
  } else {
    arrayPageIndex = pageOptions.slice(0, 10)
  }

  return (
    <PaginationWrap>
      <Pagination>
        <Pagination.First
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(0)
            gotoPage(0)
          }}
        >
          <ChevronDoubleLeftIcon />
        </Pagination.First>
        <Pagination.Prev
          disabled={!canPreviousPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(pageIndex - 1)
            previousPage(pageIndex - 1)
          }}
        >
          <ChevronLeftIcon />
        </Pagination.Prev>
        {arrayPageIndex.map(i => (
          <Pagination.Item
            key={i}
            active={pageIndex === i}
            onClick={() => {
              setPageIndex(i)
              gotoPage(i)
            }}
          >
            {i + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(pageIndex + 1)
            nextPage(pageIndex + 1)
          }}
        >
          <ChevronRightIcon />
        </Pagination.Next>
        <Pagination.Last
          disabled={!canNextPage}
          className="pagination__item--arrow"
          onClick={() => {
            setPageIndex(pageOptions.length - 1)
            gotoPage(pageOptions.length - 1)
          }}
        >
          <ChevronDoubleRightIcon />
        </Pagination.Last>
      </Pagination>
    </PaginationWrap>
  )
}

ReactTablePagination.propTypes = {
  gotoPage: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageIndex: PropTypes.number.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
}

export default ReactTablePagination

// region STYLES

export const PaginationWrap = styled.div`
  display: flex;
  // 가운데로 이동
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;

    & > * {
      margin: 10px 0;
    }
  }

  .page-item {
    ${marginRight}: 2px;

    .page-link {
      background: transparent;
      border: none;
      color: ${colorAdditional};
      height: 33px;
      font-size: 15px;
      padding: 5px;
      text-align: center;
      min-width: 33px;
      transition: all 0.3s;

      &:hover {
        color: ${colorAccent};
        background: transparent;
      }

      &:focus,
      &:active {
        box-shadow: none;
      }

      svg {
        width: 18px;
        height: 18px;
        fill: ${colorAdditional};
        transition: 0.3s;
        position: absolute;
        top: 7px;
        left: 8px;
      }
    }

    &.pagination__item--arrow .page-link {
      border-radius: 50%;
      width: 33px;
      height: 33px;
      background: ${colorFieldsBorder};
      // background: black;

      &:hover {
        background: ${colorAccent};

        svg {
          fill: white;
        }
      }
    }

    &.pagination__item--arrow.disabled .page-link {
      cursor: default;
      opacity: 0.4;
      background: ${colorFieldsBorder};

      svg {
        fill: ${colorAdditional};
      }
    }

    &.active .page-link {
      background-color: transparent;
      font-weight: bold;
      color: ${colorText};
    }

    &.disabled .page-link svg {
      fill: ${colorIcon};
    }
  }
`

const PaginationInfo = styled.div`
  color: ${colorAdditional};
  font-size: 12px;
  ${marginRight}: 0;
  ${marginLeft}: 10px;
`

const PaginationSelectWrap = styled(PaginationInfo)`
  ${marginLeft}: 20px;
  color: ${colorAdditional};
`

const PaginationFormSelect = styled(Form.Select)`
  color: ${colorAdditional};
  font-size: 12px;
  background-color: ${colorBackground};
  padding: 6px 12px;
  appearance: auto;
  background-image: none;
`

const PaginationFormOption = styled.option`
  color: ${colorAdditional};
  font-size: 14px;
`

// endregion
