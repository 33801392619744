import { TopbarButton } from '@/shared/components/Button'
import Tooltip from '@/shared/components/Tooltip'
import React, { useEffect, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { IoVolumeHighSharp, IoVolumeMute } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { setHideSideBar } from '../../../redux/headerInfoSlice'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import TopbarProfile from './AdminTopbarProfile'
import {
  SecondTopbarContainer,
  TopbarCenter,
  TopbarContainer,
  TopbarTextInfo,
  TopbarTextWrap,
} from './BasicTopbarComponents'

function AdminTopbar() {
  const dispatch = useDispatch()
  const location = useLocation()

  // 경로가 '/statistics/rolling2'인 경우
  useEffect(() => {
    // 경로가 '/statistics/rolling2'인 경우에만 실행
    if (
      location.pathname === '/statistics/rolling2' ||
      location.pathname === '/statistics/rolling' ||
      location.pathname === '/user/management/partner'
    ) {
      // setHideSideBar 액션을 dispatch하여 hideSideBar 상태를 true로 설정
      dispatch(setHideSideBar(true))
    } else {
      dispatch(setHideSideBar(false))
    }
  }, [dispatch, location.pathname]) // useE

  // HIA = Header Info Arudio
  const [audioPlayer, setAudioPlayer] = useState(new Audio())
  const [isMuteAudio, setIsMuteAudio] = useState(JSON.parse(sessionStorage.getItem('HIA')) ?? false)
  useEffect(() => {
    const audioEndedHandler = () => {
      // 재생이 끝나면 다시 재생합니다.
      audioPlayer.currentTime = 0
      audioPlayer.play()
    }
    audioPlayer.addEventListener('ended', audioEndedHandler)
    return () => {
      audioPlayer.removeEventListener('ended', audioEndedHandler)
    }
  }, [audioPlayer])

  const history = useHistory()

  const onClickBtnHandler = path => {
    history.push(path)
  }

  const {
    hideSideBar,
    newMember,
    totalMember,
    newPartner,
    totalPartner,
    todayMemberDepositCount,
    todayMemberWithdrawalCount,
    todayPartnerDepositCount,
    todayPartnerWithdrawalCount,
    todayMemberDeposit,
    todayMemberWithdrawal,
    todayPartnerDeposit,
    todayPartnerWithdrawal,
    signInCount,
    alarmTotalCount,
    alarmMemberSiginUp,
    alarmPartnerSignUp,
    alarmUserDeposit,
    alarmUserWithdrawal,
    alarmOneToOne,
    alarmSportsSettlement,
    alarmLosingSettlement,
    memberSiginUpTotalCount,
    partnerSignUpTotalCount,
    userDepositTotalCount,
    userWithdrawalTotalCount,
    oneToOneTotalCount,
    sportsSettlementTotalCount,
    losingSettlementTotalCount,
  } = useSelector(state => {
    const { headerInfo } = state

    return {
      hideSideBar: headerInfo.hideSideBar,
      newMember: headerInfo.newMember,
      totalMember: headerInfo.totalMember,
      newPartner: headerInfo.newPartner,
      totalPartner: headerInfo.totalPartner,
      todayMemberDeposit: headerInfo.todayMemberDeposit,
      todayMemberWithdrawal: headerInfo.todayMemberWithdrawal,
      todayPartnerDeposit: headerInfo.todayPartnerDeposit,
      todayPartnerWithdrawal: headerInfo.todayPartnerWithdrawal,
      memberMoneyAmount: headerInfo.memberMoneyAmount,
      partnerMoneyAmount: headerInfo.partnerMoneyAmount,
      partnerRollingPointAmount: headerInfo.partnerRollingPointAmount,
      partnerLosingPointAmount: headerInfo.partnerLosingPointAmount,
      signInCount: headerInfo.signInCount,
      alarmTotalCount: headerInfo.alarmTotalCount,
      alarmMemberSiginUp: headerInfo.alarmMemberSiginUp,
      alarmPartnerSignUp: headerInfo.alarmPartnerSignUp,
      alarmUserDeposit: headerInfo.alarmUserDeposit,
      alarmUserWithdrawal: headerInfo.alarmUserWithdrawal,
      alarmOneToOne: headerInfo.alarmOneToOne,
      alarmSportsSettlement: headerInfo.alarmSportsSettlement,
      alarmLosingSettlement: headerInfo.alarmLosingSettlement,
      memberSiginUpTotalCount: headerInfo.memberSiginUpTotalCount,
      partnerSignUpTotalCount: headerInfo.partnerSignUpTotalCount,
      userDepositTotalCount: headerInfo.userDepositTotalCount,
      userWithdrawalTotalCount: headerInfo.userWithdrawalTotalCount,
      oneToOneTotalCount: headerInfo.oneToOneTotalCount,
      sportsSettlementTotalCount: headerInfo.sportsSettlementTotalCount,
      losingSettlementTotalCount: headerInfo.losingSettlementTotalCount,
      todayMemberDepositCount: headerInfo.todayMemberDepositCount,
      todayMemberWithdrawalCount: headerInfo.todayMemberWithdrawalCount,
      todayPartnerDepositCount: headerInfo.todayPartnerDepositCount,
      todayPartnerWithdrawalCount: headerInfo.todayPartnerWithdrawalCount,
    }
  })
  const changeButton = () => {
    dispatch(setHideSideBar(!hideSideBar))
  }

  // 오디오
  useEffect(() => {
    if (isMuteAudio) {
      audioPlayer.pause()
    }

    if (alarmTotalCount <= 0) {
      audioPlayer.pause()
    }
    if (!audioPlayer.paused && !audioPlayer.ended) {
      return
    }
    if (alarmTotalCount > 0 && !isMuteAudio) {
      switch (true) {
        case alarmMemberSiginUp.count > 0:
          audioPlayer.src = `${process.env.PUBLIC_URL}/audio/memberSignUpAudio.mp3`
          audioPlayer.loop = true
          audioPlayer.play()
          break
        case alarmUserDeposit.count > 0:
          audioPlayer.src = `${process.env.PUBLIC_URL}/audio/memberDepositAudio.mp3`
          audioPlayer.loop = true
          audioPlayer.play()
          break
        case alarmUserWithdrawal.count > 0:
          audioPlayer.src = `${process.env.PUBLIC_URL}/audio/memberWithdrawalAudio.mp3`
          audioPlayer.loop = true
          audioPlayer.play()
          break
        case alarmPartnerSignUp.count > 0:
          audioPlayer.src = `${process.env.PUBLIC_URL}/audio/partnerSignUpAudio.mp3`
          audioPlayer.loop = true
          audioPlayer.play()
          break
        case alarmOneToOne.count > 0:
          audioPlayer.src = `${process.env.PUBLIC_URL}/audio/oneToOneAudio.mp3`
          audioPlayer.loop = true
          audioPlayer.play()
          break
        case alarmSportsSettlement.count > 0:
          audioPlayer.src = `${process.env.PUBLIC_URL}/audio/sportsSettlementAudio.mp3`
          audioPlayer.loop = true
          audioPlayer.play()
          break
        case alarmLosingSettlement.count > 0:
          audioPlayer.src = `${process.env.PUBLIC_URL}/audio/partnerDepositAudio.mp3`
          audioPlayer.loop = true
          audioPlayer.play()
          break
        default:
      }
    }
  }, [
    audioPlayer,
    isMuteAudio,
    alarmTotalCount,
    alarmMemberSiginUp,
    alarmPartnerSignUp,
    alarmUserDeposit,
    alarmUserWithdrawal,
    alarmOneToOne,
    alarmSportsSettlement,
    alarmLosingSettlement,
  ])

  const onClickMuteAudioHandler = () => {
    setIsMuteAudio(prev => {
      const newValue = !prev
      sessionStorage.setItem('HIA', newValue)
      return newValue
    })
  }

  return (
    <>
      <TopbarContainer
        alarm={
          memberSiginUpTotalCount +
            partnerSignUpTotalCount +
            userDepositTotalCount +
            userWithdrawalTotalCount +
            oneToOneTotalCount +
            sportsSettlementTotalCount +
            losingSettlementTotalCount >
          0
        }
      >
        <TopbarCenter>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            {/* <TopbarButton size="sm" style={{ backgroundColor: 'gray' }} onClick={() => onClickRealTimeHistoryHandler()}>
              실시간 배팅현황 ({realTimeHisotrySize})
            </TopbarButton> */}
            <TopbarButton
              size="sm"
              backgroundColor="rgba(11, 84, 1, 1)"
              count={memberSiginUpTotalCount > 0}
              onClick={() => {
                onClickBtnHandler(alarmMemberSiginUp?.path)
              }}
            >
              회원 가입신청 ({memberSiginUpTotalCount || 0})
            </TopbarButton>
            <TopbarButton
              size="sm"
              backgroundColor="rgba(11, 84, 1, 1)"
              count={partnerSignUpTotalCount > 0}
              onClick={() => {
                onClickBtnHandler(alarmPartnerSignUp?.path)
              }}
            >
              {' '}
              파트너 가입신청 ({partnerSignUpTotalCount || 0})
            </TopbarButton>
            <TopbarButton
              size="sm"
              backgroundColor="rgba(249, 0, 133, 1)"
              count={userDepositTotalCount > 0}
              onClick={() => {
                onClickBtnHandler(alarmUserDeposit?.path)
              }}
            >
              유저 입금신청 ({userDepositTotalCount || 0})
            </TopbarButton>
            <TopbarButton
              size="sm"
              backgroundColor="rgba(249, 0, 133, 1)"
              count={userWithdrawalTotalCount > 0}
              onClick={() => {
                onClickBtnHandler(alarmUserWithdrawal?.path)
              }}
            >
              유저 출금신청 ({userWithdrawalTotalCount || 0})
            </TopbarButton>
            <TopbarButton
              size="sm"
              backgroundColor="rgba(181, 163, 139, 1)"
              count={losingSettlementTotalCount > 0}
              onClick={() => {
                onClickBtnHandler(alarmLosingSettlement?.path)
              }}
            >
              루징 정산 ({losingSettlementTotalCount || 0})
            </TopbarButton>
            <TopbarButton
              size="sm"
              backgroundColor="rgba(181, 163, 139, 1)"
              count={sportsSettlementTotalCount > 0}
              onClick={() => {
                onClickBtnHandler(alarmSportsSettlement?.path)
              }}
            >
              스포츠 정산 ({sportsSettlementTotalCount || 0})
            </TopbarButton>
            <TopbarButton
              size="sm"
              backgroundColor="rgba(133, 16, 192, 1)"
              count={oneToOneTotalCount > 0}
              onClick={() => {
                onClickBtnHandler(alarmOneToOne?.path)
              }}
            >
              1:1문의 ({oneToOneTotalCount || 0})
            </TopbarButton>
          </div>
          <div>
            {isMuteAudio ? (
              <CustomIoVolumeMute onClick={() => onClickMuteAudioHandler()} />
            ) : (
              <CustomIoVolumeHighSharp onClick={() => onClickMuteAudioHandler()} />
            )}
          </div>

          <TopbarProfile />
        </TopbarCenter>
      </TopbarContainer>
      {/* 2전째 topbar 추가됨에 따라 Sidebar-SidebarWrap  의 padding-top , Router-ContainerWrap 의 padding-top 수정 필요 */}
      <SecondTopbarContainer>
        <div
          style={{
            display: 'flex',
            position: 'absolute',
            left: '20px',
            color: 'white',
            fontSize: '20px',
            gap: '5px',
            cursor: 'pointer',
          }}
        >
          <span
            onClick={() => {
              window.location.href = '/statistics'
            }}
          >
            ADMIN
          </span>

          <AiOutlineMenu style={{ color: 'white' }} size={30} onClick={changeButton} />
        </div>

        <TopbarTextWrap>
          {/* color : 색상, 색상 코드도 가능 */}
          <Tooltip text="금일 회원 총 가입수" placement="bottom">
            <TopbarTextInfo color="#ff4545" onClick={() => onClickBtnHandler('/user/management/member')}>
              [ 금일 가입회원: {newMember?.toString().replace(commonReg2, ',')} /{' '}
              {totalMember?.toString().replace(commonReg2, ',')} ]
            </TopbarTextInfo>
          </Tooltip>

          <Tooltip text="금일 회원 총 입금액" placement="bottom">
            <TopbarTextInfo color="#ff4545" onClick={() => onClickBtnHandler('/user/history/moneydeposit')}>
              [ 금일 회원입금: {todayMemberDepositCount?.toString().replace(commonReg2, ',')}명 /{' '}
              {todayMemberDeposit?.toString().replace(commonReg2, ',')} ]
            </TopbarTextInfo>
          </Tooltip>
          <Tooltip text="금일 회원 총 출금액" placement="bottom">
            <TopbarTextInfo color="#ff4545" onClick={() => onClickBtnHandler('/user/history/moneywithdrawal')}>
              [ 금일 회원출금: {todayMemberWithdrawalCount?.toString().replace(commonReg2, ',')}명 /{' '}
              {todayMemberWithdrawal?.toString().replace(commonReg2, ',')} ]
            </TopbarTextInfo>
          </Tooltip>
        </TopbarTextWrap>

        <TopbarTextWrap>
          <Tooltip text="금일 파트너 총 가입수" placement="bottom">
            <TopbarTextInfo color="#36ff6f" onClick={() => onClickBtnHandler('/user/management/partner')}>
              [ 금일 가입파트너: {newPartner?.toString().replace(commonReg2, ',')} /{' '}
              {totalPartner?.toString().replace(commonReg2, ',')} ]
            </TopbarTextInfo>
          </Tooltip>
          <Tooltip text="금일 파트너 총 입금액" placement="bottom">
            <TopbarTextInfo color="#36ff6f" onClick={() => onClickBtnHandler('/user/history/moneydeposit')}>
              [ 금일 파트너입금: {todayPartnerDepositCount?.toString().replace(commonReg2, ',')}명 /{' '}
              {todayPartnerDeposit?.toString().replace(commonReg2, ',')} ]
            </TopbarTextInfo>
          </Tooltip>
          <Tooltip text="금일 파트너 총 출금액" placement="bottom">
            <TopbarTextInfo color="#36ff6f" onClick={() => onClickBtnHandler('/user/history/moneywithdrawal')}>
              [ 금일 파트너출금: {todayPartnerWithdrawalCount?.toString().replace(commonReg2, ',')}명 /{' '}
              {todayPartnerWithdrawal?.toString().replace(commonReg2, ',')} ]
            </TopbarTextInfo>
          </Tooltip>
        </TopbarTextWrap>

        <TopbarTextWrap>
          <TopbarTextInfo color="#e35bf5">[ 접속자: {signInCount} ]</TopbarTextInfo>
        </TopbarTextWrap>
      </SecondTopbarContainer>
    </>
  )
}

export default AdminTopbar

const CustomIoVolumeHighSharp = styled(IoVolumeHighSharp)`
  width: 30px;
  height: 30px;
  color: red;
  cursor: pointer;
`
const CustomIoVolumeMute = styled(IoVolumeMute)`
  width: 30px;
  height: 30px;
  color: red;
  cursor: pointer;
`
