import { convertToKstDateTimeSimple } from '@/utils/dateTime'
import React from 'react'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberDepositInfo from '../DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const checkNumber = checkMoney => {
  return Math.sign(checkMoney)
}

const MoneyExchangeRateListData = (content, checkRadio, setCheckRadio) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '시작일',
      accessor: 'settlementStartAt',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.startDate)
      },
    },

    {
      Header: '종료일',
      accessor: 'settlementEndAt',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTimeSimple(original.endDate)
      },
    },
    {
      Header: '유저 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.userId} />{' '}
              </span>
              <span>
                <MemberCoupon memberId={original.userId} />
              </span>
              <span>
                <MemberNote memberId={original.userId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.userId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.userAuthority}>
            {NoteAuthorityEnums[original.userAuthority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '총 입금액',
      accessor: 'totalDepositAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalDepositAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 출금액',
      accessor: 'totalWithdrawalAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalWithdrawalAmount.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '입금액 - 출금액',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        const exchangeAmount = original.totalDepositAmount - original.totalWithdrawalAmount
        switch (checkNumber(exchangeAmount)) {
          case 1: {
            return (
              <span style={{ color: 'blue', fontWeight: '600' }}>
                {exchangeAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          case -1: {
            return (
              <span style={{ color: 'red', fontWeight: '600' }}>
                {exchangeAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          default:
            return (
              <span style={{ color: 'black', fontWeight: '600' }}>
                {exchangeAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyExchangeRateListData
