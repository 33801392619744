import { createAction, createSlice } from '@reduxjs/toolkit'

export const fetchHeaderInfoAction = createAction('FETCH_HEADER_INFO')
export const fetchHeaderAlarmInfoAction = createAction('FETCH_HEADER_ALARM_INFO')
export const fetchBasicInfoAction = createAction('FETCH_BASIC_INFO')
export const fetchPotInfoAction = createAction('FETCH_POT_INFO')

const updateAlarmCount = (state, alarmKey, count) => {
  state[alarmKey] = {
    ...state[alarmKey],
    count,
  }
}

const headerInfoSlice = createSlice({
  name: 'headerInfo',
  initialState: {
    edgeNames: [],
    edgeNameSelect: [],
    edgeNameRadio: [],
    domainCheckBox: [],
    hideSideBar: false,
    newMember: 0,
    totalMember: 0,
    newPartner: 0,
    totalPartner: 0,
    todayMemberDepositCount: 0,
    todayMemberWithdrawalCount: 0,
    todayPartnerDepositCount: 0,
    todayPartnerWithdrawalCount: 0,
    todayMemberDeposit: 0,
    todayMemberWithdrawal: 0,
    todayPartnerDeposit: 0,
    todayPartnerWithdrawal: 0,
    memberMoneyAmount: 0,
    memberBettingAwaitingAmount: 0,
    partnerBettingAwaitingAmount: 0,
    memberRollingPointAmount: 0,
    partnerMoneyAmount: 0,
    partnerRollingPointAmount: 0,
    partnerLosingPointAmount: 0,
    signInCount: 0,
    alarmTotalCount: 0,
    memberSiginUpTotalCount: 0,
    partnerSignUpTotalCount: 0,
    userDepositTotalCount: 0,
    userWithdrawalTotalCount: 0,
    oneToOneTotalCount: 0,
    sportsSettlementTotalCount: 0,
    losingSettlementTotalCount: 0,
    alarmMemberSiginUp: {
      count: 0,
      path: '/user/management/member',
      isMute: false,
    },
    alarmPartnerSignUp: {
      count: 0,
      path: '/partner/management/apply',
      isMute: false,
    },
    alarmUserDeposit: {
      count: 0,
      path: '/user/history/moneydepositV2',
      isMute: false,
    },
    alarmUserWithdrawal: {
      count: 0,
      path: '/user/history/moneywithdrawalV2',
      isMute: false,
    },
    alarmOneToOne: {
      count: 0,
      path: '/management/operation/onetoone',
      isMute: false,
    },
    alarmSportsSettlement: {
      count: 0,
      path: '/management/sport/settlements',
      isMute: false,
    },
    alarmLosingSettlement: {
      count: 0,
      path: '/user/history/pointexchange',
      isMute: false,
    },
    potInfo: {
      HONORLINK: 0,
      GOLDLINK_SLOT: 0,
      SLOT_CITY: 0,
      SLOT_GATE: 0,
      NEXUS: 0,
      SWIX: 0,
      REVOLUTION_HOLDEM: 0,
      WILD_GAMES: 0,
    },
  },
  reducers: {
    setAlarmInfo: (state, action) => {
      const {
        totalSize,
        oneToOneApplySize,
        userDepositApplySize,
        userWithdrawalApplySize,
        partnerSignUpApplySize,
        memberSignUpApplySize,
        losingSettlementApplySize,
        sportsSettlementApplySize,
        oneToOneWaitingSize,
        userDepositWaitingSize,
        userWithdrawalWaitingSize,
        partnerSignUpWaitingSize,
        memberSignUpWaitingSize,
        losingSettlementWaitingSize,
        sportsSettlementWaitingSize,
      } = action.payload

      state.alarmTotalCount = totalSize
      state.memberSiginUpTotalCount = memberSignUpApplySize + memberSignUpWaitingSize
      state.partnerSignUpTotalCount = partnerSignUpApplySize + partnerSignUpWaitingSize
      state.userDepositTotalCount = userDepositApplySize + userDepositWaitingSize
      state.userWithdrawalTotalCount = userWithdrawalApplySize + userWithdrawalWaitingSize
      state.oneToOneTotalCount = oneToOneApplySize + oneToOneWaitingSize
      state.sportsSettlementTotalCount = sportsSettlementApplySize + sportsSettlementWaitingSize
      state.losingSettlementTotalCount = losingSettlementApplySize + losingSettlementWaitingSize

      updateAlarmCount(state, 'alarmMemberSiginUp', memberSignUpApplySize)
      updateAlarmCount(state, 'alarmPartnerSignUp', partnerSignUpApplySize)
      updateAlarmCount(state, 'alarmUserDeposit', userDepositApplySize)
      updateAlarmCount(state, 'alarmUserWithdrawal', userWithdrawalApplySize)
      updateAlarmCount(state, 'alarmOneToOne', oneToOneApplySize)
      updateAlarmCount(state, 'alarmSportsSettlement', sportsSettlementApplySize)
      updateAlarmCount(state, 'alarmLosingSettlement', losingSettlementApplySize)
    },
    setHeaderInfo: (state, action) => {
      const {
        newMember,
        newPartner,
        totalMember,
        totalPartner,
        todayMemberDeposit,
        todayMemberDepositCount,
        todayMemberWithdrawal,
        todayMemberWithdrawalCount,
        todayPartnerDeposit,
        todayPartnerDepositCount,
        todayPartnerWithdrawalCount,
        todayPartnerWithdrawal,
        memberMoneyAmount,
        memberBettingAwaitingAmount,
        partnerBettingAwaitingAmount,
        memberRollingPointAmount,
        partnerMoneyAmount,
        partnerRollingPointAmount,
        partnerLosingPointAmount,
        signInCount,
      } = action.payload

      state.newMember = newMember
      state.totalMember = totalMember
      state.newPartner = newPartner
      state.totalPartner = totalPartner
      state.todayMemberDeposit = todayMemberDeposit
      state.todayMemberDepositCount = todayMemberDepositCount
      state.todayMemberWithdrawal = todayMemberWithdrawal
      state.todayMemberWithdrawalCount = todayMemberWithdrawalCount
      state.todayPartnerDeposit = todayPartnerDeposit
      state.todayPartnerDepositCount = todayPartnerDepositCount
      state.todayPartnerWithdrawalCount = todayPartnerWithdrawalCount
      state.todayPartnerWithdrawal = todayPartnerWithdrawal
      state.memberMoneyAmount = memberMoneyAmount
      state.memberBettingAwaitingAmount = memberBettingAwaitingAmount
      state.partnerBettingAwaitingAmount = partnerBettingAwaitingAmount
      state.memberRollingPointAmount = memberRollingPointAmount
      state.partnerMoneyAmount = partnerMoneyAmount
      state.partnerRollingPointAmount = partnerRollingPointAmount
      state.partnerLosingPointAmount = partnerLosingPointAmount
      state.signInCount = signInCount
    },
    setDomainInfo: (state, action) => {
      const domainList = action.payload
      state.domainCheckBox = domainList.map(domain => ({
        name: domain,
        checked: false, // 초기 상태에서는 모든 체크박스가 선택되지 않은 상태입니다.
      }))
    },
    setEdgeInfo: (state, action) => {
      const edgeList = action.payload

      state.edgeNames = edgeList.map(item => item.edgeName)
      state.edgeNameRadio = edgeList.map((item, index) =>
        index === 0
          ? {
              name: 'edgeName',
              label: item.edgeName,
              radioValue: item.edgeName,
              initialValue: item.edgeName,
            }
          : {
              name: 'edgeName',
              label: item.edgeName,
              radioValue: item.edgeName,
            },
      )
      state.edgeNameSelect = edgeList.map((item, index) => ({
        value: `edgeName${index}`,
        label: item.edgeName,
      }))
    },
    setAlarmIsMute: (state, action) => {
      const { alarmKey, isMute } = action.payload

      state[alarmKey] = {
        ...state[alarmKey],
        isMute,
      }
    },
    setHideSideBar: (state, action) => {
      state.hideSideBar = action.payload
    },
    setPotInfo: (state, action) => {
      const {
        potAmount,
        goldLinkPotAmount,
        slotCityPotAmount,
        slotGatePotAmount,
        nexusPotAmount,
        swixPotAmount,
        revolutionHoldemPotAmount,
        wildGamesPotAmount,
      } = action.payload

      state.potInfo = {
        HONORLINK: potAmount,
        GOLDLINK_SLOT: goldLinkPotAmount,
        SLOT_CITY: slotCityPotAmount,
        SLOT_GATE: slotGatePotAmount,
        NEXUS: nexusPotAmount,
        SWIX: swixPotAmount,
        REVOLUTION_HOLDEM: revolutionHoldemPotAmount,
        WILD_GAMES: wildGamesPotAmount,
      }
    },
  },
})

export const { setAlarmIsMute, setHideSideBar, setAlarmInfo, setHeaderInfo, setEdgeInfo, setPotInfo, setDomainInfo } =
  headerInfoSlice.actions

export default headerInfoSlice.reducer
