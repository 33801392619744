import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { searchSportsLeague, updateSportLeagueFlag } from '../../../utils/api/sportsManage/sportsInfo'
import SportManagementTabs from '../SportManagementTabs'
import LeagueManagementTabs from './LeagueManagementTabs'
import LeagueTeamManagementTabs from './LeagueTeamManagementTabs'
import { decodeAccessToken } from '../../../utils/token'

const LeagueManagementList = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [showLeague, setShowLeague] = useState([])
  const [hideLeague, setHideLeague] = useState([])
  const location = useLocation()
  const [sportsType, setSportsType] = useState(new URLSearchParams(location.search).get('type'))
  const [params, setParams] = useState({
    sportsType,
    sportsTeamName: null,
  })

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('type')
    setSportsType(type)

    setParams(prev => ({
      ...prev,
      sportsType: type,
    }))
  }, [location])

  // 경기 목록 조회
  const fetchSearchSportsLeague = async () => {
    await searchSportsLeague(params).then(res => {
      setShowLeague(res.data.showContent)
      setHideLeague(res.data.hiddenContent)
    })
  }

  useEffect(() => {
    fetchSearchSportsLeague()
  }, [params])

  // 팀명
  const [searchTeamName, setSearchTeamName] = useState('')

  const onSearchTeamNameHandler = e => {
    setSearchTeamName(e.target.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      sportsTeamName: !searchTeamName ? null : searchTeamName.trim(),
    }))
  }

  const [loading, setLoading] = useState(false)
  const onChangeHandler = (leagueNum, flag) => {
    if (authority === 'NORMAL') return
    if (loading) return
    const body = {
      leagueNum,
      showFlag: flag,
    }
    setLoading(true)
    updateSportLeagueFlag(body)
      .then(res => {
        alert('변경이 완료됐습니다.')
        setLoading(false)
        fetchSearchSportsLeague()
      })
      .catch(error => {
        alert('변경이 실패하였습니다.')
        setLoading(false)
        fetchSearchSportsLeague()
      })
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('5')
  const [subActiveKey, setSubActiveKey] = useState('1')

  return (
    <Card>
      <CardBody>
        <SportManagementTabs activeKey={activeKey} />
        <LeagueTeamManagementTabs activeKey={subActiveKey} />
        <LeagueManagementTabs activeKey={sportsType} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 리그 숨김 처리 시, 해당리그는 유저에게 <span style={{ color: 'red', fontWeight: '500' }}>숨김</span>{' '}
              처리됩니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        {/* <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>팀 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchTeamName"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTeamName}
                  onChange={e => onSearchTeamNameHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer> */}
        <CustomFormContainer>
          {/* 노출 리그 목록 */}
          <CustomDivGroup>
            <CustomTitle>노출 리그 목록</CustomTitle>
            <CustomContentWrap>
              {showLeague.map(item => (
                <CustomHangWrap>
                  <CustomLeagueNum>{item.leagueNum}</CustomLeagueNum>
                  <CustomLeagueNational>
                    <NationalImg src={`${process.env.PUBLIC_URL}/img/national/${item.cc}.png`} />
                    <span>{item.ccKr}</span>
                  </CustomLeagueNational>
                  <CustomLeagueName>{item.leagueName}</CustomLeagueName>
                  <CustomLeagueShow>
                    <FiEye
                      onClick={() => {
                        onChangeHandler(item.leagueNum, false)
                      }}
                    />
                  </CustomLeagueShow>
                </CustomHangWrap>
              ))}
            </CustomContentWrap>
          </CustomDivGroup>
          {/* 숨김 리그 목록 */}
          <CustomDivGroup>
            <CustomTitle>숨김 리그 목록</CustomTitle>
            <CustomContentWrap>
              {hideLeague.map(item => (
                <CustomHangWrap>
                  <CustomLeagueNum>{item.leagueNum}</CustomLeagueNum>
                  <CustomLeagueNational>
                    <NationalImg src={`${process.env.PUBLIC_URL}/img/national/${item.cc}.png`} />
                    <span>{item.ccKr}</span>
                  </CustomLeagueNational>
                  <CustomLeagueName>{item.leagueName}</CustomLeagueName>
                  <CustomLeagueShow>
                    <FiEyeOff
                      onClick={() => {
                        onChangeHandler(item.leagueNum, true)
                      }}
                    />
                  </CustomLeagueShow>
                </CustomHangWrap>
              ))}
            </CustomContentWrap>
          </CustomDivGroup>
        </CustomFormContainer>
      </CardBody>
    </Card>
  )
}

export default LeagueManagementList

const NationalImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`

const CustomHangWrap = styled.div`
  width: 100%;
  display: flex;
  &:hover {
    background: rgba(140, 140, 143, 0.5);
  }
  border-bottom: solid 1px #e6e7eb;
`

const CustomLeagueNum = styled.div`
  width: 20%;
  text-align: center;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`
const CustomLeagueNational = styled.div`
  width: 25%;
  text-align: center;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  display: flex;
  gap: 10px;
`

const CustomLeagueName = styled.div`
  width: 45%;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`

const CustomLeagueShow = styled.div`
  width: 10%;
  text-align: center;
  height: 30px !important;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
`

const CustomTitle = styled.div`
  width: 100%;
  border-bottom: solid 1px black;
  color: blue;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  height: 50px;
`

const CustomDivGroup = styled.div`
  width: 100% !important;
  border: solid 2px black;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CustomContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll !important;
  height: 650px;
`

const CustomFormContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  max-height: 1000px;
`
