import { useEffect, useState } from 'react'
import axios from '../base/axios'

export async function searchSportsBettingLogV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/betting-log/sports',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function deleteSportsBettingLogV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/betting-log/sports/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function updateSportsDetailBettingLogV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/betting-log/sports/betSettlementType',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function cancelSportsBettingLogV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/betting-log/sports/cancel',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
