export const MemberLosingStatisticsLevelEnum = {
  ONE: '1등급',
  TWO: '2등급',
  THREE: '3등급',
  FOUR: '4등급',
  FIVE: '5등급',
}

export const MemberLosingTypeEnum = {
  NONE: '없음',
  LOSING_SETTLEMENT_TYPE1: '(베팅액 - 당첨액) * 요율% - 발생롤링액',
  LOSING_SETTLEMENT_TYPE2: '(베팅액- 당첨액 - 발생롤링액) * 요율%',
  LOSING_SETTLEMENT_TYPE3: '(입금액 - 출금액) * 요율%',
  LOSING_SETTLEMENT_TYPE4: '(입금액 - 출금액) * 요율% - 발생롤링액',
  LOSING_SETTLEMENT_TYPE5: '((베팅액 - 당첨액) * 요율% - 발생롤링액) * 0.9',
  LOSING_SETTLEMENT_TYPE6: '((베팅액 - 당첨액 - 발생롤링액) * 요율%) * 0.9',
  LOSING_SETTLEMENT_TYPE7: '(베팅액 - 당첨액) * 요율%',
  LOSING_SETTLEMENT_TYPE8: '낙첨액 * 요율%',
}

export const MemberLosingTypeEnumSelectOptions = () => {
  return Object.entries(MemberLosingTypeEnum).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
