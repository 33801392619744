export const callHistoryStatusEnums = {
  WAITING: '대기중',
  PROCESSING: '진행중',
  FINISHED: '완료',
  REJECTED: '거절',
  CANCELED: '취소',
}

export const CallTypeEnums = {
  1: '일반',
  2: '구매',
}

export const CallTypeEnumsSelectOptions = () => {
  return Object.entries(CallTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
