import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import styled from 'styled-components'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import Error from '@/shared/components/form/Error'
import { commonReg2 } from '../../../../../../utils/validate/commonValidate'
import { updateMemberRollingInfo } from '../../../../../../utils/api/logManage/logApi'
import { decodeAccessToken } from '../../../../../../utils/token'

const MiniGameRollingInfo = ({
  miniGameRollingInfo,
  memberId,
  topPartnerMiniGameRollingInfo,
  fetchhMemberRollingInfo,
}) => {
  const [loading, setLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority
  const [miniGameSingleRollingPer, setMiniGameSingleRollingPer] = useState(miniGameRollingInfo.miniGameSingleRollingPer)
  const [miniGameCombinationRollingPer, setMiniGameCombinationRollingPer] = useState(
    miniGameRollingInfo.miniGameCombinationRollingPer,
  )
  useEffect(() => {
    setMiniGameSingleRollingPer(miniGameRollingInfo?.miniGameSingleRollingPer)
    setMiniGameCombinationRollingPer(miniGameRollingInfo?.miniGameCombinationRollingPer)
  }, [miniGameRollingInfo])

  const onSubmit = e => {
    if (loading) return
    if (window.confirm(`${memberId}의 미니게임 롤링비율을 변경 하시겠습니까?`)) {
      setLoading(true)
      const body = {
        memberId,
        miniGameRollingInfo: {
          miniGameSingleRollingPer,
          miniGameCombinationRollingPer,
        },
      }

      updateMemberRollingInfo(body)
        .then(res => {
          alert('수정이 완료됐습니다.')
          fetchhMemberRollingInfo()
          setLoading(false)
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          if (errorCode === 'MEMBER-1005') {
            alert(`${message}`)
          } else if (errorCode === 'PARTNER-1013') {
            alert(`${message}`)
          } else if (errorCode === 'PARTNER-1014') {
            alert(`${message}`)
          } else {
            alert('수정이 실패했습니다.')
          }
          fetchhMemberRollingInfo()
          setLoading(false)
        })
    }
  }

  return (
    <MemberInfoWrap>
      <SearchCardTitleWrap>
        <div style={{ marginBottom: '10px' }}>
          <p>
            * 롤링비율 수정 시 <span style={{ color: 'red', fontWeight: '900' }}>상위 파트너의 롤링비율</span>보다
            같거나 작어야 합니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <Form
        onSubmit={onSubmit}
        initialValues={{
          topPartnerSingleRollingPer: topPartnerMiniGameRollingInfo.miniGameSingleRollingPer,
          topPartnerCombinationRollingPer: topPartnerMiniGameRollingInfo.miniGameCombinationRollingPer,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <CustomFormContainer horizontal onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <th style={{ color: 'red' }}>상위파트너 일반베팅 롤링비율</th>
                  <td>
                    <Field name="topPartnerSingleRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                  <th style={{ color: 'red' }}>상위파트너 조합베팅 롤링비율</th>
                  <td>
                    <Field name="topPartnerCombinationRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input {...input} type="text" value={input.value} disabled />
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th>일반베팅 롤링비율</th>
                  <td>
                    <Field name="miniGameSingleRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={miniGameSingleRollingPer}
                            onChange={e => {
                              setMiniGameSingleRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                  <th>조합베팅 롤링비율</th>
                  <td>
                    <Field name="miniGameCombinationRollingPer">
                      {({ input, meta }) => (
                        <>
                          <input
                            {...input}
                            type="text"
                            value={miniGameCombinationRollingPer}
                            onChange={e => {
                              setMiniGameCombinationRollingPer(e.target.value)
                              input.onChange(e)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </>
                      )}
                    </Field>
                  </td>
                </tr>
              </tbody>
            </table>
            {
              {
                SUPER: (
                  <FormButtonToolbar className="registrationFooter">
                    <Button variant="primary" type="submit" style={{ marginRight: '150px' }}>
                      미니게임 롤링 변경하기
                    </Button>
                  </FormButtonToolbar>
                ),
              }[authority]
            }
          </CustomFormContainer>
        )}
      </Form>
    </MemberInfoWrap>
  )
}

export default MiniGameRollingInfo

MiniGameRollingInfo.propTypes = {
  memberId: PropTypes.string.isRequired,
  miniGameRollingInfo: PropTypes.shape({
    miniGameSingleRollingPer: PropTypes.number,
    miniGameCombinationRollingPer: PropTypes.number,
  }).isRequired,
  topPartnerMiniGameRollingInfo: PropTypes.shape({
    miniGameSingleRollingPer: PropTypes.number,
    miniGameCombinationRollingPer: PropTypes.number,
  }).isRequired,
  fetchhMemberRollingInfo: PropTypes.func.isRequired,
}

const CustomFormContainer = styled(FormContainer)`
  max-width: 100%;
`

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 150px;
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  input {
    border: solid 1px black;
  }
  th {
    // background-color: grey !important;
    padding: 10px 4px;
    border: 1px solid black;
    white-space: nowrap;
    color: blue;
    font-weight: 600;
    font-size: 12px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
