import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SettlementRadio = ({ rowInfo, checkRadio, setCheckRadio }) => {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        setCheckRadio(prev => [...prev, rowInfo.sportsBettingLogKey])
        break
      case false:
        setCheckRadio(checkRadio.filter(el => el !== rowInfo.sportsBettingLogKey))
        break
      default:
        break
    }
  }
  return (
    <div style={{}}>
      <CustomInput
        style={{ width: '100%' }}
        checked={checkRadio.find(e => e === rowInfo.sportsBettingLogKey)}
        type="checkbox"
        name="chech"
        onChange={e => {
          approveHandler(e)
        }}
      />
    </div>
  )
}
export default SettlementRadio

SettlementRadio.propTypes = {
  checkRadio: PropTypes.arrayOf(PropTypes.string),
  setCheckRadio: PropTypes.func.isRequired,
  rowInfo: PropTypes.shape({
    sportsBettingLogKey: PropTypes.string,
    answerStatus: PropTypes.string,
  }).isRequired,
}

SettlementRadio.defaultProps = {
  checkRadio: [],
}

const CustomInput = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
