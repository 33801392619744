import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  searchSportFixtureBettingMarketList,
  searchSportFixtureList,
  searchSportFixtureMarketList,
} from '../utils/api/sport/sportFixtureApi'

export const fetchSportFixtureList = createAsyncThunk('sportFixture/fetchSportFixtureList', async params => {
  const res = await searchSportFixtureList(params)
  return res.data
})

export const fetchSportsFixtureBettingMarketList = createAsyncThunk(
  'sportFixture/fetchSportsFixtureBettingMarketList',
  async params => {
    const res = await searchSportFixtureBettingMarketList(params)
    return res.data
  },
)

const SportFixtureSlice = createSlice({
  name: 'sportFixture',
  initialState: {
    loading: true,
    marketLoading: true,
    totalElements: 0,
    sportFixtures: {},
  },
  reducers: {
    setSportFixtures: (state, action) => {
      console.log('action ', JSON.stringify(action.payload))
    },

    updatedSportFixtureMarketPanelShow: (state, action) => {
      const { key, fixtureId, marketPanelShow } = action.payload

      const fixtureIndex = state.sportFixtures[key]?.fixtures?.findIndex(item => item.fixtureId === fixtureId)

      state.sportFixtures[key].fixtures[fixtureIndex].marketPanelShow = marketPanelShow
    },

    updatedSportFixtureBettingMarketListShow: (state, action) => {
      const { key, fixtureId, bettingMarketTrShow } = action.payload

      const fixtureIndex = state.sportFixtures[key]?.fixtures?.findIndex(item => item.fixtureId === fixtureId) ?? -1

      if (fixtureIndex !== -1) {
        state.sportFixtures[key].fixtures[fixtureIndex].bettingMarketTrShow = bettingMarketTrShow
      }
    },

    updatedSportBettingInfoListShow: (state, action) => {
      const { key, fixtureId, bettingInfoTrShow, marketId } = action.payload

      const fixtureIndex = state.sportFixtures[key]?.fixtures?.findIndex(item => item.fixtureId === fixtureId)
      if (fixtureIndex === -1) return
      const marketIndex = state.sportFixtures[key]?.fixtures[fixtureIndex]?.fixtureBettingMarketList.findIndex(
        item => item.marketId === marketId,
      )
      if (marketIndex === -1) return
      state.sportFixtures[key].fixtures[fixtureIndex].fixtureBettingMarketList[marketIndex].bettingInfoTrShow =
        bettingInfoTrShow
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchSportsFixtureBettingMarketList.pending, (state, action) => {
      state.loading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSportsFixtureBettingMarketList.fulfilled, (state, action) => {
      const { leagueId, leagueName, startDate, fixtureId, fixtureBettingMarketList } = action.payload
      const sportFixtureKey = `${leagueId}-${startDate}`

      if (fixtureBettingMarketList.length === 0) {
        state.loading = false
        return
      }
      const fixtureIndex = state.sportFixtures[sportFixtureKey]?.fixtures?.findIndex(
        item => item.fixtureId === fixtureId,
      )

      if (fixtureIndex === -1) {
        state.loading = false
        return
      }

      // "sportFixtureKey" : "7807-KBO-2024-04-26T18:30+09:00",
      // 7807-KBO-2024-04-26T18:30:00+09:00
      state.sportFixtures[sportFixtureKey].fixtures[fixtureIndex] = {
        ...state.sportFixtures[sportFixtureKey].fixtures[fixtureIndex],
        fixtureBettingMarketList,
      }

      state.loading = false
    })

    builder.addCase(fetchSportFixtureList.pending, (state, action) => {
      state.loading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSportFixtureList.fulfilled, (state, action) => {
      const { sportEvents, totalElements } = action.payload

      if (!sportEvents || sportEvents.length === 0) {
        state.sportFixtures = []
        state.totalElements = 0
        state.loading = false
        return
      }

      state.totalElements = totalElements

      state.sportFixtures = sportEvents.reduce((grouped, sportEvent) => {
        const key = `${sportEvent.fixture.leagueId}-${sportEvent.fixture.startDate}`

        if (!grouped[key]) {
          grouped[key] = {
            sportId: sportEvent.fixture.sportId,
            sportName: sportEvent.fixture.sportName,
            sportImageUrl: sportEvent.fixture.sportImageUrl,
            locationId: sportEvent.fixture.locationId,
            locationName: sportEvent.fixture.locationName,
            locationImageUrl: sportEvent.fixture.locationImageUrl,
            leagueId: sportEvent.fixture.leagueId,
            leagueName: sportEvent.fixture.leagueName,
            leagueImageUrl: sportEvent.fixture.leagueImageUrl,
            fixtures: [],
          }
        }
        grouped[key].fixtures.push({ ...sportEvent, marketPanelShow: false, bettingMarketTrShow: false })

        return grouped
      }, {})

      state.loading = false
    })
  },
})

export const {
  setSportFixtures,
  updatedSportFixtureMarketPanelShow,
  updatedSportFixtureBettingMarketListShow,
  updatedSportBettingInfoListShow,
} = SportFixtureSlice.actions

export default SportFixtureSlice.reducer
