import axios from '../base/axios'

// 리그 조회
export async function searchSportLeagueList(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportLeague(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportLeagueStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league/status',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function updateSportLeagueMultipleStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league/mutiple/status',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportLeagueStatusAll(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league/status/all',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 수정
export async function updateSportLeagueImportant(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league/important',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 스포츠 밸런스 노출 여부
export async function updateSportLeagueBalanceVisible(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league/balance-visible',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 리그 생성
export async function createSportLeague(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/league',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
