import { useEffect, useRef, useState } from 'react'

// 알림 소리 hook
// const useAudio = (src, { volume = 1, playbackRate = 1 }) => {
//   const audio = useRef(new Audio(src))

//   useEffect(() => {
//     audio.current.volume = volume
//   }, [volume])

//   useEffect(() => {
//     audio.current.playbackRate = playbackRate
//   }, [playbackRate])

//   return audio.current
// }

// export default useAudio

const useAudio = (src, { volume = 1, playbackRate = 1 } = {}) => {
  const audioRef = useRef(null)

  useEffect(() => {
    // Create new Audio instance only if audioRef is empty or src has changed
    if (!audioRef.current || audioRef.current.src !== src) {
      audioRef.current = new Audio(src)
    }

    // Set audio properties
    audioRef.current.volume = volume
    audioRef.current.playbackRate = playbackRate
  }, [src, volume, playbackRate])

  // Expose audio methods and properties
  const play = async () => {
    try {
      await audioRef.current.play()
    } catch (error) {
      console.error('Failed to play audio:', error)
    }
  }

  const pause = async () => {
    try {
      audioRef.current.currentTime = 0
      await audioRef.current.pause()
    } catch (error) {
      console.error('Failed to pause audio:', error)
    }
  }

  const paused = () => {
    return audioRef.current.paused
  }

  return [play, pause, paused, audioRef]
}

export default useAudio
