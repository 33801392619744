import React from 'react'
import {
  MiniGameBettingResultEnums,
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
} from '../../../enums/Log/miniGameBettingEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MiniGameDeleteRadio from './MiniGameDeleteRadio'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import ManualProcessResult from './ManualProcessResult'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const MiniGameBettingLogListData = (content, checkRadio, setCheckRadio, fetchSearchMiniGameBetting) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '삭제',
      accessor: 'deleteRadio',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <MiniGameDeleteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },

    {
      Header: '배팅시간',
      accessor: 'bettingTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original?.bettingTime)
      },
    },
    {
      Header: '상위 파트너명',
      accessor: 'topPartnerName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ color: 'black' }}>{original?.topPartnerName}</span>
          </>
        )
      },
    },
    {
      Header: '유저 ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.memberId} />{' '}
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '게임종류',
      accessor: 'miniGameType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MiniGameTypeEnums[original?.miniGameType]
      },
    },
    {
      Header: '배팅종류',
      accessor: 'miniGameBettingType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MiniGameBettingTypeEnums[original?.miniGameBettingType]
      },
    },
    {
      Header: '게임라운드',
      accessor: 'miniGameRound',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배팅 전 잔액',
      accessor: 'bettingBeforeAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingBeforeAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '배팅액',
      accessor: 'bettingAmountCasino',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ fontWeight: '500' }}>{original.bettingAmount?.toString().replace(commonReg2, ',')}</span>
      },
    },
    {
      Header: '배당',
      accessor: 'miniGameBettingPer',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ color: 'orange', fontWeight: '900' }}>{original?.miniGameBettingPer}</span>
          </>
        )
      },
    },
    {
      Header: '당첨금',
      accessor: 'bettingExpectAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'red', fontWeight: '500' }}>
            {original.bettingExpectAmount?.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '배팅 후 잔액',
      accessor: 'bettingAfterAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingAfterAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '배팅 결과',
      accessor: 'miniGameBettingResult',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.miniGameBettingResult) {
          case 'ING':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'green',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original.miniGameBettingResult]}
              </button>
            )
          case 'WIN':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'blue',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original.miniGameBettingResult]}
              </button>
            )
          case 'LOSE':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'red',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original.miniGameBettingResult]}
              </button>
            )
          case 'CANCEL':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'orange',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original.miniGameBettingResult]}
              </button>
            )
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>-</span>
        }
      },
    },
    {
      Header: '수동처리',
      accessor: 'manualProcess',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.miniGameBettingResult === 'ING') {
          return (
            <span>
              <ManualProcessResult
                miniGameBettingLogKey={original.miniGameBettingLogKey}
                memberId={original.memberId}
                gameRound={original.miniGameRound}
                miniGameType={original.miniGameType}
                miniGameBettingType={original.miniGameBettingType}
                bettingAmount={original.bettingAmount}
                fetchSearchMiniGameBetting={fetchSearchMiniGameBetting}
              />
            </span>
          )
        }
        return <span style={{ color: 'black', fontWeight: '500' }}>-</span>
      },
    },
    {
      Header: '노출여부',
      accessor: 'showFlag',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.showFlag) {
          return <span style={{ color: 'blue', fontWeight: '500' }}>노출</span>
        }
        return <span style={{ color: 'red', fontWeight: '500' }}>비노출</span>
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MiniGameBettingLogListData
