import { ButtonToolbar } from '@/shared/components/Button'
import { FormContainer, FormGroup, FormGroupField, FormGroupLabel } from '@/shared/components/form/FormElements'
import FormField from '@/shared/components/form/FormField'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Modal as BootstrapModal } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { DamageTypeEnums } from '../../../enums/MemberManage/BlackListEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'

function MemberBlackListDetail({
  blackMemberInfo,
  isMemberBlackListDetailModalShow,
  setMemberBlackListDetailModalShow,
}) {
  const onClickHandler = () => {
    setMemberBlackListDetailModalShow(prevState => !prevState)
  }

  const onSubmit = () => {
    setMemberBlackListDetailModalShow(prevState => !prevState)
  }

  return (
    <StyledModal show={isMemberBlackListDetailModalShow} onHide={onClickHandler} $header>
      <ModalHeader>
        <ModalCloseButton className="lnr lnr-cross" aria-label="close-btn" type="button" onClick={onClickHandler} />
        <ModalTitle>블랙회원 상세보기</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel>피해본 유형</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="damageType"
                    component={FormField}
                    type="text"
                    defaultValue={DamageTypeEnums[blackMemberInfo.damageType]}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>피해본 금액</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="damageAmount"
                    component={FormField}
                    type="text"
                    defaultValue={blackMemberInfo.damageAmount}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>용의자 이름</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="memberName"
                    component={FormField}
                    type="text"
                    defaultValue={blackMemberInfo.memberName}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>용의자 은행명</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="bankName"
                    component={FormField}
                    type="text"
                    defaultValue={blackMemberInfo.bankName}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>용의자 계좌번호</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="accountNum"
                    component={FormField}
                    type="text"
                    defaultValue={blackMemberInfo.accountNum}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>용의자 예금주</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="accountHolder"
                    component={FormField}
                    type="text"
                    defaultValue={blackMemberInfo.accountHolder}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>용의자 연락처</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="suspectPhoneNum"
                    component={FormField}
                    type="text"
                    defaultValue={blackMemberInfo.suspectPhoneNum}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>피해발생일</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="damageDate"
                    component={FormField}
                    type="text"
                    defaultValue={convertToKstDateTime(blackMemberInfo.damageDate)}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>피해내용</FormGroupLabel>
                <FormGroupField>
                  <Field
                    name="damageContent"
                    component={FormField}
                    type="text"
                    defaultValue={blackMemberInfo.damageContent}
                    disabled
                  />
                </FormGroupField>
              </FormGroup>
            </FormContainer>
          )}
        </Form>
      </ModalBody>
    </StyledModal>
  )
}

export default MemberBlackListDetail

MemberBlackListDetail.propTypes = {
  blackMemberInfo: PropTypes.shape({
    num: PropTypes.number,
    damageType: PropTypes.string,
    memberId: PropTypes.string,
    memberPassword: PropTypes.string,
    memberName: PropTypes.string,
    damageAmount: PropTypes.string,
    suspectPhoneNum: PropTypes.string,
    bankName: PropTypes.string,
    accountNum: PropTypes.string,
    accountHolder: PropTypes.string,
    damageContent: PropTypes.string,
    damageDate: PropTypes.string,
  }).isRequired,
  isMemberBlackListDetailModalShow: PropTypes.bool,
  setMemberBlackListDetailModalShow: PropTypes.func,
}

MemberBlackListDetail.defaultProps = {
  isMemberBlackListDetailModalShow: false,
  setMemberBlackListDetailModalShow: null,
}

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 400px !important;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
