import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { searchSportLocationList, updateSportLocationStatus } from '../../../utils/api/sport/sportLocationApi'
import { decodeAccessToken } from '../../../utils/token'
import LocationModifyModal from './LocationModifyModal'

const LocationPage = ({ setLocationId, setLocationNameKo }) => {
  const [locationList, setLocationList] = useState([])
  const authority = decodeAccessToken()?.adminAuthority
  const [locationInfo, setLocationInfo] = useState({})
  const [locationModifyModalShow, setLocationModifyModalShow] = useState(false)
  const onCloseLocationModifyModalShowHandler = () => {
    setLocationModifyModalShow(false)
  }

  // 팀명
  const [searchLocationName, setSearchLocationName] = useState('')

  const onSearchLocationNameHandler = e => {
    setSearchLocationName(e.target.value)
  }

  const [params, setParams] = useState({
    locationName: null,
  })

  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      locationName: !searchLocationName ? null : searchLocationName.trim(),
    }))
  }
  const [loading, setLoading] = useState(false)
  const fetchSearchSportLocationList = () => {
    if (loading) return
    setLoading(true)
    searchSportLocationList(params).then(res => {
      setLocationList(res.data.locationList)
    })
    setLoading(false)
  }

  useEffect(() => {
    fetchSearchSportLocationList()
  }, [params])

  const [changeLoading, setChangeLoading] = useState(false)
  const onEventHideAndShowHandler = (locationId, loactionNameKo, visible) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return
    let confirmationMessage

    if (visible) {
      confirmationMessage = `${loactionNameKo} 국가를 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${loactionNameKo} 국가를 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        locationId,
        visible,
      }
      setChangeLoading(true)
      updateSportLocationStatus(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportLocationList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportLocationList()
        })
    }
  }

  return (
    <>
      {(loading || changeLoading) && <Loading />}
      <LocationPageBox>
        <HeaderBox>
          국가 관리
          <div style={{ display: 'flex', marginLeft: '30px', marginTop: '2px' }}>
            <div style={{ fontSize: '15px', fontWeight: '300' }}>
              <input
                name="searchTeamName"
                type="text"
                placeholder="국가명/코드"
                value={searchLocationName}
                onChange={e => onSearchLocationNameHandler(e)}
              />
            </div>
            <CustomButton
              style={{ marginLeft: '15px', background: '#dedede', color: '#000' }}
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색
            </CustomButton>
          </div>
        </HeaderBox>
        <TableWrap>
          <table>
            <thead>
              <tr style={{ borderTop: '1px solid black' }}>
                <th>국가 코드</th>
                {/* <th>국가명</th> */}
                <th>국가명</th>
                <th>노출 여부</th>
                <th>정렬 순서</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              {locationList.map(location => {
                return (
                  <tr>
                    <td>{location.locationId}</td>
                    <td style={{ background: 'rgb(251, 247, 234)' }}>
                      <img
                        src={location.locationImageUrl}
                        style={{ width: '30px', height: '25px', marginRight: '5px' }}
                        alt=""
                      />
                      {location.locationNameKo || '-'}
                    </td>
                    <td>
                      {
                        {
                          true: (
                            <FiEye
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                onEventHideAndShowHandler(location.locationId, location.locationName, false)
                              }}
                            />
                          ),
                          false: (
                            <FiEyeOff
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                onEventHideAndShowHandler(location.locationId, location.locationName, true)
                              }}
                            />
                          ),
                        }[location.visible]
                      }
                    </td>
                    <td>{location.locationSortNum}</td>
                    <td>
                      <CustomButton
                        variant="outline-secondary"
                        style={{ marginRight: '5px', background: '#2196f3', color: '#fff' }}
                        onClick={() => {
                          setLocationInfo(location)
                          setLocationModifyModalShow(true)
                        }}
                      >
                        수정
                      </CustomButton>
                      <CustomButton
                        variant="outline-secondary"
                        style={{ marginRight: '5px', background: '#47ce3a', color: '#fff' }}
                        onClick={() => {
                          setLocationId(location.locationId)
                          setLocationNameKo(location.locationNameKo)
                        }}
                      >
                        리그정보
                      </CustomButton>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </TableWrap>
      </LocationPageBox>
      {locationModifyModalShow && (
        <LocationModifyModal
          locationInfo={locationInfo}
          locationModifyModalShow={locationModifyModalShow}
          onCloseLocationModifyModalShowHandler={onCloseLocationModifyModalShowHandler}
          fetchSearchSportLocationList={fetchSearchSportLocationList}
        />
      )}
    </>
  )
}

export default LocationPage

LocationPage.propTypes = {
  setLocationId: PropTypes.func.isRequired,
  setLocationNameKo: PropTypes.func.isRequired,
}

const LocationPageBox = styled.div`
  width: 100%;
  padding: 5px;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 5px;
  border-top: 2px solid black;
  display: flex;

  p {
    font-size: 11px;
    color: red;
  }
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  max-height: 520px;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
`

const CustomButton = styled(Button)`
  width: 50px;
  font-size: 12px;
  padding: 5px;
`
