import React from 'react'
import { convertToKstDateTime } from '../../../utils/dateTime'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const RestrictionIpListData = (content, onDeleteHandler) => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '제한IP',
      accessor: 'restrictionIp',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '메모',
      accessor: 'memo',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '등록일',
      accessor: 'registrationAt',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.registrationAt)
      },
    },
    {
      Header: '제거',
      accessor: 'delete',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <button
            type="button"
            onClick={() => {
              onDeleteHandler(original.restrictionIpKey, original.restrictionIp)
            }}
            style={{
              width: '60px',
              height: '25px',
              background: 'blue',
              color: 'white',
              fontWeight: '900',
              border: 'none',
            }}
          >
            제거
          </button>
        )
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default RestrictionIpListData

RestrictionIpListData.propTypes = {}
