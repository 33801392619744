import { Button } from '@/shared/components/Button'
import { Card, CouponCardBody, CouponCardSubhead, CouponCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import { CouponTypeEnumsRadioOptions } from '../../../enums/Operation/couponEnums'
import Loading from '../../../shared/components/Loading'
import { createCouponList } from '../../../utils/api/operation/operationApi'
import { decodeAccessToken } from '../../../utils/token'

function CouponCreateForm() {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const onSubmit = e => {
    if (writeLoading) return
    if (!e.couponTitle || !e.couponPrice) {
      alert('모두 다 기입 후 생성하기 버튼을 클릭해주세요.')
      return
    }
    if (e.couponType === 'MONEY') {
      if (!Number(e.couponPrice) || Number(e.couponPrice) < 0 || Number(e.couponPrice) >= 1000001) {
        alert('금액을 올바르게 입력해주세요.')
        return
      }
    }

    if (e.couponType === 'RATIO') {
      if (!Number(e.couponPrice) || Number(e.couponPrice) < 0 || Number(e.couponPrice) > 100) {
        alert('비율을 올바르게 입력해주세요.')
        return
      }
    }

    const body = {
      couponTitle: e.couponTitle,
      couponType: e.couponType,
      couponPrice: e.couponPrice,
    }
    setWriteLoading(true)
    createCouponList(body)
      .then(res => {
        setWriteLoading(false)
        alert('쿠폰이 생성됐습니다.')
        window.location.reload()
        return res
      })
      .catch(error => {
        setWriteLoading(false)
        alert('쿠폰 생성이 실패했습니다. 다시 시도해주세요.')
        return error
      })
  }

  return (
    <Col md={4}>
      <Card>
        <CouponCardBody>
          <CouponCardTitleWrap>
            <CouponCardSubhead>* 쿠폰을 생성할 수 있는 탭 입니다.</CouponCardSubhead>
            <CouponCardSubhead>
              <p>
                * 금액 선택시 입력값 범위는 <span style={{ color: 'red', fontWeight: 'bolder' }}>1~1,000,000</span>{' '}
                입니다
              </p>
              <p>
                * 비율 선택시 입력값 범위는 <span style={{ color: 'red', fontWeight: 'bolder' }}>1~100</span> 입니다
              </p>
            </CouponCardSubhead>
          </CouponCardTitleWrap>
          {writeLoading ? (
            <Loading />
          ) : (
            <>
              <Form onSubmit={onSubmit}>
                {({ handleSubmit, form: { reset } }) => (
                  <FormContainer horizontal onSubmit={handleSubmit}>
                    <FormGroup>
                      <FormGroupLabel>쿠폰명</FormGroupLabel>
                      <FormGroupField>
                        <Field
                          name="couponTitle"
                          component="input"
                          type="text"
                          isAboveError
                          placeholder="쿠폰명을 입력하세요"
                        />
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>타입</FormGroupLabel>
                      {CouponTypeEnumsRadioOptions().map(item => (
                        <FormGroupField
                          key={`index_${item.label}`}
                          style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                        >
                          <Field
                            name={`${item.name}`}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={item.initialValue}
                          />
                        </FormGroupField>
                      ))}
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>(금액/비율) 입력</FormGroupLabel>
                      <FormGroupField>
                        <Field name="couponPrice" component="input" type="text" placeholder="숫자만 입력하세요" />
                      </FormGroupField>
                    </FormGroup>
                    {
                      {
                        SUPER: (
                          <FormButtonToolbar>
                            <Button variant="primary" type="submit">
                              생성하기
                            </Button>
                          </FormButtonToolbar>
                        ),
                      }[authority]
                    }
                  </FormContainer>
                )}
              </Form>
            </>
          )}
        </CouponCardBody>
      </Card>
    </Col>
  )
}

export default CouponCreateForm

CouponCreateForm.propTypes = {}
