import { commonReg12, commonReg7 } from '../commonValidate'

export const SportDetailInfoValidate = values => {
  const errors = {}

  if (!values.marketName) {
    errors.marketName = '마켓명을 입력해주세요.'
  }

  if (!/^\d+(\.\d{1,2})?$/.test(values.minimumBettingPer)) {
    errors.minimumBettingPer = '0 이상 소수점 2자리까지 입력가능.'
  }

  if (!/^\d+(\.\d{1,2})?$/.test(values.maximumBettingPer)) {
    errors.maximumBettingPer = '0 이상 소수점 2자리까지 입력가능.'
  }

  if (!/^\d+(\.\d{1,2})?$/.test(values.sumBettingPer)) {
    errors.sumBettingPer = '0 이상 4 이하의 소수점 2자리까지 입력가능.'
  } else if (parseFloat(values.sumBettingPer) < 0 || parseFloat(values.sumBettingPer) > 4) {
    errors.sumBettingPer = '0 이상 4 이하의 소수점 2자리까지 입력가능.'
  }

  // if (!values.marketTypeValue2) {
  //   errors.marketType1 = '종목 및 세부 내용을 먼저 선택하세요'
  // }

  // if (
  //   values.marketTypeValue1 !== null &&
  //   values.marketTypeValue2 !== null &&
  //   values.marketTypeValue1.value === values.marketTypeValue2.value
  // ) {
  //   errors.marketType2 = '같은 타입은 조합이 불가합니다.'
  // }

  // if (
  //   values.marketTypeValue1 !== null &&
  //   values.marketTypeValue2 !== null &&
  //   values.marketTypeValue3 !== null &&
  //   (values.marketTypeValue1.value === values.marketTypeValue2.value ||
  //     values.marketTypeValue1.value === values.marketTypeValue3.value ||
  //     values.marketTypeValue2.value === values.marketTypeValue3.value)
  // ) {
  //   errors.marketType2 = '같은 타입은 조합이 불가합니다.'
  // }

  return errors
}

export const sportSettingValidate = values => {
  const errors = {}
  // if (!values.marketTypeValue1) {
  //   errors.marketType1 = '종목 및 세부 내용을 먼저 선택하세요'
  // }

  // if (!values.marketTypeValue2) {
  //   errors.marketType1 = '종목 및 세부 내용을 먼저 선택하세요'
  // }

  // if (
  //   values.marketTypeValue1 !== null &&
  //   values.marketTypeValue2 !== null &&
  //   values.marketTypeValue1.value === values.marketTypeValue2.value
  // ) {
  //   errors.marketType2 = '같은 타입은 조합이 불가합니다.'
  // }

  // if (
  //   values.marketTypeValue1 !== null &&
  //   values.marketTypeValue2 !== null &&
  //   values.marketTypeValue3 !== null &&
  //   (values.marketTypeValue1.value === values.marketTypeValue2.value ||
  //     values.marketTypeValue1.value === values.marketTypeValue3.value ||
  //     values.marketTypeValue2.value === values.marketTypeValue3.value)
  // ) {
  //   errors.marketType2 = '같은 타입은 조합이 불가합니다.'
  // }

  return errors
}

export const callManageCallValidate = values => {
  const errors = {}

  if (values.dataLoading) {
    errors.memberStatus = '데이터를 불러오고 있습니다.'
  }
  if (!values.dataLoading && !values.callListValue) {
    errors.memberStatus = '선택해주세요.'
  }

  return errors
}

export const manualSportsRegisterValidate = values => {
  const errors = {}

  if (values.manualType === 'NEW_EVENT') {
    if (!values.sportsTypeOptionValue) {
      errors.sportsTypeOptionValue = '필수 선택입니다.'
    }

    if (!values.sportsMarketTypeOptionValue) {
      errors.sportsMarketTypeOptionValue = '필수 선택입니다.'
    }

    if (!values.ccKr) {
      errors.ccKr = '필수 입력입니다.'
    }
    if (!values.leagueName) {
      errors.leagueName = '필수 입력입니다.'
    }
    if (!values.homeName) {
      errors.homeName = '필수 입력입니다.'
    }
    if (!values.awayName) {
      errors.awayName = '필수 입력입니다.'
    }
    if (!values.homeOddValue) {
      errors.homeOddValue = '필수 입력입니다.'
    }
    if (!values.awayOddValue) {
      errors.awayOddValue = '필수 입력입니다.'
    }
    if (!values.startAt) {
      errors.startAt = '필수 입력입니다.'
    }
    if (!values.manualSportsMarketOptionValue) {
      errors.manualSportsMarketOptionValue = '필수 선택입니다.'
    }

    // if (values.sportsTypeOptionValue && values.manualSportsMarketOptionValue) {
    //   if (values.sportsTypeOptionValue.value !== values.manualSportsMarketOptionValue.sportId) {
    //     errors.manualSportsMarketOptionValue = '종목에 맞는 마켓을 선택하세요.'
    //   }
    // }
  } else {
    if (!values.sportsMarketTypeOptionValue) {
      errors.sportsMarketTypeOptionValue = '필수 선택입니다.'
    }
    if (!values.sportsTypeOptionValue) {
      errors.sportsTypeOptionValue = '필수 선택입니다.'
    }
    if (!values.sportsEventListOptionValue) {
      errors.sportsEventListOptionValue = '필수 선택입니다.'
    }
    if (!values.homeOddValue) {
      errors.homeOddValue = '필수 입력입니다.'
    }
    if (!values.awayOddValue) {
      errors.awayOddValue = '필수 입력입니다.'
    }
    if (!values.manualSportsMarketOptionValue) {
      errors.manualSportsMarketOptionValue = '필수 선택입니다.'
    }

    // if (values.sportsTypeOptionValue && values.manualSportsMarketOptionValue) {
    //   if (values.sportsTypeOptionValue.value !== values.manualSportsMarketOptionValue.sportId) {
    //     errors.manualSportsMarketOptionValue = '종목에 맞는 마켓을 선택하세요.'
    //   }
    // }
  }

  return errors
}
