import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Card, CardBody, SearchCardTitleWrap, SearchCardSubhead } from '@/shared/components/Card'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import {
  SearchGroupLabel,
  SearchGroup,
  SearchContainer,
  SearchInputWrap,
  SearchButtonWrap,
  SearchGroupField,
  SearchSelect,
  SearchDatePickerWrap,
} from '@/shared/components/Search'
import { useSelector } from 'react-redux'
import { ko } from 'date-fns/esm/locale'
import SystemLogListData from './SystemLogListData'
import LogPageTabs from '../LogPageTabs'
import { convertToKst } from '../../../utils/dateTime'
import { SystemLogTypeEnumsEnumsSelectOptions } from '../../../enums/Log/systemEnums'
import { searchSystemLog } from '../../../utils/api/logManage/logApi'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'

const SystemLogList = () => {
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  /**
   시스템 로그 조회
   */
  const [systemLogparams, setSystemLogParams] = useState({
    page,
    size,
    edgeName: null,
  })

  useEffect(() => {
    setSystemLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchSystemLog = async () => {
    await searchSystemLog(systemLogparams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchSearchSystemLog()
  }, [systemLogparams])

  const [systemLogListData, setSystemLogListData] = useState(SystemLogListData(content))
  useEffect(() => {
    setSystemLogListData(SystemLogListData(content))
  }, [content])

  const [rows, setData] = useState(systemLogListData.tableRowsData)
  useEffect(() => {
    setData(systemLogListData.tableRowsData)
  }, [systemLogListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 회원ID
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 회원ID
  const [searchChangeContent, setSearchChangeContent] = useState('')

  const onSearchChangeContentHandler = e => {
    setSearchChangeContent(e.target.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 구분
  const [searchLogType, setSearchLogType] = useState('')

  const [searchLogTypeOptionValue, setSearchLogTypeOptionValue] = useState(null)
  const searchLogTypeOption = SystemLogTypeEnumsEnumsSelectOptions()

  const onSelectLogOptionHandler = option => {
    setSearchLogTypeOptionValue(option)
    setSearchLogType(option.value)
  }

  // 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setSystemLogParams(prev => ({
      ...prev,
      page: 0,
      logType: !searchLogTypeOptionValue?.value ? null : searchLogTypeOptionValue.value,
      userId: !searchUserId ? null : searchUserId.trim(),
      edgeName: !searchEdgeName ? null : searchEdgeName,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      changeContent: !searchChangeContent ? null : searchChangeContent.trim(),
    }))
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('6')
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <LogPageTabs activeKey={activeKey} />
          {/* 검색 기능 */}
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
              <p>
                * 해당 페이지는 관리자가 <span style={{ color: 'red', fontWeight: 'bolder' }}>수동</span>으로 변경한
                내역을 확인하실 수 있습니다.
              </p>
            </div>
          </SearchCardTitleWrap>
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>사이트</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchEdgeName"
                  onChange={option => {
                    onSelectEdgeNameOptionHandler(option)
                  }}
                  placeholder="전체"
                  value={searchEdgeNameOptionValue}
                  options={searchEdgeNameOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>유저ID</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchUserId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchUserId}
                    onChange={e => onSearchUserIdHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>변경내용</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchChangeContent"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchChangeContent}
                    onChange={e => onSearchChangeContentHandler(e)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>구분</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchLogType"
                  placeholder="종류"
                  onChange={option => {
                    onSelectLogOptionHandler(option)
                  }}
                  value={searchLogTypeOptionValue}
                  options={searchLogTypeOption}
                  className="react-select"
                  classNamePrefix="react-select"
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>
          <ReactTableBase columns={systemLogListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default SystemLogList
