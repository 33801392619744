import {
  AccountCard,
  AccountContent,
  AccountHead,
  AccountLogo,
  AccountLogoAccent,
  AccountTitle,
  AccountWrap,
} from '@/shared/components/account/AccountElements'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import OtpInput from 'react-otp-input'
import { useHistory } from 'react-router-dom'
import { postSiginIn } from '@/utils/api/authManage/authManageApi'
import { useDispatch } from 'react-redux'
import LogInForm from './components/LogInForm'
import OtpForm from './components/OtpForm'
import { createAccessToken, handleSignIn } from '../../utils/socketio/userAuthSocketIOHandler'
import { setAccessToken } from '../../utils/token'
import { setAdminInfo } from '../../redux/InitInfoSlice'

const LogIn = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [otpShown, setOtpShown] = useState(false)
  const [loginIp, setLoginIp] = useState('')
  useEffect(() => {
    axios.get('https://geolocation-db.com/json/').then(res => {
      setLoginIp(res.data.IPv4 || res.data.IPv6)
    })
  }, [])

  const onLoginSubmit = e => {
    const body = {
      adminId: e.userId,
      password: e.password,
      loginIp,
    }

    handleSignIn(body).then(signInRes => {
      if (!signInRes.status) {
        alert('아이디 및 암호를 다시 확인해주세요.')
        return
      }

      const tokenData = {
        adminId: body.adminId,
        password: body.password,
      }

      // 토큰 발급
      createAccessToken(tokenData).then(atRes => {
        if (!atRes.status) {
          alert('다시 로그인해주세요.')
          return
        }
        dispatch(
          setAdminInfo({
            adminId: atRes.data.adminId,
            authority: atRes.data.authority,
          }),
        )
        // 로컬 스토리지에 토큰 저장
        setAccessToken(atRes.data.accessToken)
        history.push('/')
      })
    })

    // postSiginIn({
    //   adminId: e.userId,
    //   password: e.password,
    //   loginIp,
    // })
    //   .then(res => {
    //     history.push('/')
    //   })
    //   .catch(error => {
    //     const { errorCode, message } = error.response.data
    //     if (errorCode === 'SIGN-1100') {
    //       alert(`${message}`)
    //     } else {
    //       alert('아이디 및 암호를 다시 확인해주세요.')
    //     }
    //   })
  }

  const onOtpSubmit = e => {
    // postOtp({
    //   otpNum: e.optNum,
    // })
    //   .then(res => {
    //     alert('최종 로그인 성공')
    //     // console.log('res => ', res)
    //     // history.push('/')
    //   })
    //   .catch(error => {
    //     console.log('error => ', error.response.data)
    //     alert('OTP인증에 실패 했습니다. 다시 로그인 해주세요.')
    //     window.location.reload()
    //   })
  }

  return (
    <AccountWrap>
      <AccountContent>
        <AccountCard>
          <AccountHead>
            <AccountTitle>
              <AccountLogo>
                <AccountLogoAccent>TYSON-관리자</AccountLogoAccent>
              </AccountLogo>
            </AccountTitle>
            {/* <h4 className="subhead">Start your business easily</h4> */}
          </AccountHead>
          {
            {
              true: <OtpForm onSubmit={onOtpSubmit} />,
              false: <LogInForm onSubmit={onLoginSubmit} />,
            }[otpShown]
          }
        </AccountCard>
      </AccountContent>
    </AccountWrap>
  )
}

export default LogIn
