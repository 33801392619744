import React, { useMemo, useState } from 'react'
import { BettingResultEnums } from '../../../enums/Log/bettingEnums'
import { systemLogTypeEnums } from '../../../enums/Log/systemEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const SystemLogListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '관리자ID',
      accessor: 'adminId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '구분',
      accessor: 'systemLogType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return systemLogTypeEnums[original.systemLogType]
      },
    },
    {
      Header: '유저ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.memberId !== '') {
          return (
            <div style={{ width: '100%' }}>
              <span style={{ fontWeight: '500' }}>
                <span
                  onClick={() => {
                    window.open(`/user-info/${original.memberId}`, '_blank')
                  }}
                  style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                >
                  {original.memberId}
                </span>
                <span>
                  <MemberDepositInfo memberId={original.memberId} />
                </span>
                <span>
                  <MemberCoupon memberId={original.memberId} />
                </span>
                <span>
                  <MemberNote memberId={original.memberId} />
                </span>
              </span>
            </div>
          )
        }
        return <span>-</span>
      },
    },
    {
      Header: '변경시간',
      accessor: 'changeTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.changeTime)
      },
    },
    {
      Header: '변경내용',
      accessor: 'changeContent',
      disableGlobalFilter: true,
      width: 65,
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default SystemLogListData
