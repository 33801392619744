export const couponStatusEnums = {
  USED: '사용완료',
  NOUSED: '미사용',
  EXPIRED: '만료',
  USING: '사용중',
}

export const CouponStatusEnumsSelectOptions = () => {
  return Object.entries(couponStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const couponTypeEnums = {
  RATIO: '비율',
  MONEY: '금액',
}

export const CouponTypeEnumsSelectOptions = () => {
  return Object.entries(couponTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const CouponTypeEnumsRadioOptions = () => {
  return Object.entries(couponTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'couponType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'couponType',
          label: value,
          radioValue: key,
        },
  )
}
