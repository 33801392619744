import { commonReg12, commonReg7 } from '../commonValidate'

export const callManageRecoverPerValidate = values => {
  const errors = {}

  if (!values.afterRtp || commonReg7.test(values.afterRtp)) {
    errors.afterRtp = '숫자만 입력 가능합니다.'
  }

  if (!values.afterRtp || !commonReg12.test(values.afterRtp)) {
    errors.afterRtp = '50 ~ 100까지의 정수만 가능.'
  }

  return errors
}

export const callManageCallValidate = values => {
  const errors = {}

  if (values.dataLoading) {
    errors.memberStatus = '데이터를 불러오고 있습니다.'
  }
  if (!values.dataLoading && !values.callListValue) {
    errors.memberStatus = '선택해주세요.'
  }

  return errors
}
