import { Card, CardBody, SearchCardTitleWrap, CustomAuthorityButton } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  BettingResultStatusEnumsSelectOptions,
  sportResultStatusEnums,
} from '../../../enums/SystemManagement/sportEnums'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import { adminCancelSportsBettingLog, searchSportsBettingLog } from '../../../utils/api/logManage/logApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import BettingManagementTabs from '../BettingManagementTabs'
import SportsDetailBettingContent from './SportsDetailBettingContent'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { downloadMemberSportsList } from '../../../utils/api/memberManage/memberManageApi'
import SportsDeleteRadio from './SportsDeleteRadio'
import { decodeAccessToken } from '../../../utils/token'
import { deleteSportsBettingLog } from '../../../utils/api/memberManage/deleteHistoryApi'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'

const SportBettingLogList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })

  const location = useLocation()
  const [memberId, setMemberId] = useState(location?.state?.memberId || null)
  const [resultStatus, setResultStatus] = useState(location?.state?.resultStatus || null)
  const [startDate, setStartDate] = useState(location?.state?.startDate || null)
  const [endDate, setEndDate] = useState(location?.state?.endDate || null)

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    edgeName: null,
    authority: null,
    memberId,
    sportsResultStatus: resultStatus || null,
    startDate,
    endDate,
    page,
    size,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
  }

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchSportsMarket = async () => {
    await searchSportsBettingLog(bettingLogparams).then(res => {
      const newContent = res.data.content.map(item => ({
        ...item,
        isVisible: false,
      }))
      setContent(newContent)
      setTotalElement(res.data.totalElement)
    })
  }

  useEffect(() => {
    fetchSearchSportsMarket()
  }, [bettingLogparams])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState(memberId || '')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState(resultStatus || '')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(
    resultStatus ? { value: 'ING', label: '진행중' } : null,
  )
  const searchGameResultOption = BettingResultStatusEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate || null)
  const [searchEndDate, setSearchEndDate] = useState(endDate || null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [searchTeamName, setSearchTeamName] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      memberId: !searchMemberId ? null : searchMemberId.trim(),
      topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      sportsResultStatus: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      teamName: !searchTeamName ? null : searchTeamName,
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
      page: 0,
    }))
  }

  // 검색하기 버튼
  const onSearchBettingHistoryHandler = (num, bettingLogKey) => {
    const newContent = [...content]
    const hang = newContent.find(item => item.sportsBettingLogKey === bettingLogKey)
    if (hang) {
      hang.isVisible = !hang.isVisible
      setContent(newContent)
    }
  }

  const onClickClearSearchHandler = () => {
    setSearchMemberId('')
    setSearchGameResult(null)
    setSearchGameResultOptionValue(null)
    setSearchEdgeName(null)
    setSearchEdgeNameOptionValue(null)
    setSearchStartDate(null)
    setSearchEndDate(null)
    setSearchTeamName('')
    setBettingLogParams(prev => ({
      ...prev,
      memberId: null,
      edgeName: null,
      topPartnerId: null,
      sportsResultStatus: null,
      startDate: null,
      endDate: null,
      teamName: null,
      startAmount: null,
      endAmount: null,
      page: 0,
      size,
    }))
  }

  const [cancelApiLoading, setCancelApiLoading] = useState(false)
  const onCancelSportsBettingLogHandelr = (sportsBettingLogKey, sportsResultStatus) => {
    if (sportsResultStatus !== 'ING') {
      alert('진행중인 배팅에 대해서만 취소가 가능합니다.')
      return
    }
    if (cancelApiLoading) return

    setCancelApiLoading(true)

    adminCancelSportsBettingLog(sportsBettingLogKey)
      .then(res => {
        alert('취소 처리 되었습니다.')
        fetchSearchSportsMarket()
        setCancelApiLoading(false)
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('취소 처리 실패했습니다.')
        }
        setCancelApiLoading(false)
      })
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('9')

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    topPartnerName: '상위 파트너',
    authority: '권한',
    bettingTime: '배팅시간',
    memberId: '유저ID',
    memberName: '유저명',
    bettingAmount: '배팅액',
    bettingBeforeAmount: '배팅전잔액',
    bettingAfterAmount: '배팅후잔액',
    bettingExpectedAmount: '예상당첨',
    bettingResultAmount: '결과액',
    folderSize: '폴더수',
    totalOddValue: '총배당',
    resultStatus: '결과',
  }
  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (
      window.confirm(
        '스포츠 배팅내역 DB를 csv파일로 다운 받으시겠습니까?\n 기간설정은 필수 사항이며, 유저ID/명 검색은 선택사항입니다.',
      )
    ) {
      if (searchStartDate === null || searchEndDate === null) {
        alert('기간 설정은 필수 입니다.')
        return
      }
      if (searchStartDate > searchEndDate) {
        alert('기간을 다시 확인해주세요.')
        return
      }
      // if (searchEndDate - searchStartDate > 604800000) {
      //   alert('두 날짜 간의 차이는 1주일 이하여야 합니다.')
      //   return
      // }

      setDownloadLoading(true)
      downloadMemberSportsList({
        memberId: !searchMemberId ? null : searchMemberId.trim(),
        topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
        startDate: convertToKst(searchStartDate),
        endDate: convertToKst(searchEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '스포츠 배팅 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }
  const [checkRadio, setCheckRadio] = useState([])
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const onDeleteHandler = () => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (checkRadio.length === 0) {
      alert('비노출 할 내역들을 선택해주세요.')
      return
    }

    if (window.confirm(`스포츠 배팅내역을 비노출 하시겠습니까?\n비노출 시 유저에게 해당 배팅내역은 보이지 않습니다.`)) {
      setWriteLoading(true)
      const body = {
        sportsHistoryKeyList: checkRadio,
      }
      deleteSportsBettingLog(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
    } else {
      setWriteLoading(false)
    }
  }

  return (
    <Card>
      <CardBody>
        <BettingManagementTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당 유저의 자세한 배팅내역을 볼 수 있습니다.</p>
            <p>
              * <span style={{ fontWeight: 'bolder', color: 'red' }}>상위파트너로 검색</span>시, 해당 파트너 포함,
              직영/하부 회원&파트너들의 내역을 가져옵니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상위파트너 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTopPartnerId}
                  onChange={e => onSearchTopPartnerIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>(홈/원정)팀 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchTeamName"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTeamName}
                  onChange={e => setSearchTeamName(e.target.value)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이상)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchStartAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchStartAmount}
                  onChange={e => onSearchStartAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이하)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchEndAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchEndAmount}
                  onChange={e => onSearchEndAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          {/* <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              style={{ background: 'blue', color: 'white' }}
              variant="outline-secondary"
              onClick={() => {
                onClickClearSearchHandler()
              }}
            >
              검색어 초기화
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'green', color: 'white' }}
              onClick={e => {
                downloadMemberListHandler()
              }}
            >
              배팅내역 DB다운
            </Button>
          </SearchButtonWrap> */}
        </SearchContainer>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              style={{ background: 'blue', color: 'white' }}
              variant="outline-secondary"
              onClick={() => {
                onClickClearSearchHandler()
              }}
            >
              검색어 초기화
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'green', color: 'white' }}
              onClick={e => {
                downloadMemberListHandler()
              }}
            >
              배팅내역 DB다운
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'orange', color: 'white' }}
              onClick={() => {
                setCheckRadio(content.map(value => value.sportsBettingLogKey))
              }}
            >
              전체선택
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'grey', color: 'white' }}
              onClick={() => {
                onDeleteHandler()
              }}
            >
              비노출하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <SportsGameListTableWrap>
          <SportsGameListTable>
            <SportsGameListTableHead>
              <SportsGameListTableTr>
                <th style={{ width: '4%' }}>번호</th>
                <th style={{ width: '3%' }}>삭제</th>
                <th style={{ width: '4%' }}>사이트명</th>
                <th style={{ width: '6%' }}>배팅일시</th>
                <th style={{ width: '11%' }}>상위파트너명</th>
                <th style={{ width: '12%' }}>유저ID(명) - 권한</th>
                <th style={{ width: '9%' }}>배팅전 머니</th>
                <CustomTh style={{ width: '9%', color: 'white' }}>배팅액</CustomTh>
                {/* <th style={{ width: '9%' }}>배팅후 머니</th> */}
                <th style={{ width: '9%' }}>배팅후 머니</th>
                <th style={{ width: '5%' }}>폴더</th>
                <th style={{ width: '5%' }}>배당</th>
                <th style={{ width: '10%' }}>예상/최종 당첨금</th>
                <th style={{ width: '3%' }}>노출여부</th>
                <th style={{ width: '5%' }}>결과</th>
                <th style={{ width: '5%' }}>취소</th>
              </SportsGameListTableTr>
            </SportsGameListTableHead>
            {content.map((item, index) => (
              <SportsGameListTableBody key={item.sportsBettingLogKey}>
                <SportsGameListTableTr>
                  <SportsGameFolderContentBox>
                    <SportsGameFolderNumBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.num}
                    </SportsGameFolderNumBox>
                    <SportsGameDeleteBox>
                      <SportsDeleteRadio rowInfo={item} checkRadio={checkRadio} setCheckRadio={setCheckRadio} />
                    </SportsGameDeleteBox>
                    <SportsGameFolderEdgeBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.edgeName}
                    </SportsGameFolderEdgeBox>
                    <SportsGameFolderContentTime
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {convertToKstSportsEventDateTime(item.bettingTime)}
                    </SportsGameFolderContentTime>
                    <SportsGamePartnerBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentHomeName>{item.topPartnerName || '-'}</SportsGameFolderContentHomeName>
                    </SportsGamePartnerBox>
                    <SportsGameFolderContentHomeBox
                    // onClick={() => {
                    //   onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                    // }}
                    >
                      <SportsGameFolderContentHomeName style={{ display: 'flex', flexDirection: 'column' }}>
                        <span
                          onClick={() => {
                            window.open(`/user-info/${item.memberId}`, '_blank')
                          }}
                          style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {item.memberId}({item.memberName}) - &nbsp;
                          <CustomAuthorityButton authority={item.authority}>
                            {NoteAuthorityEnums[item.authority]}
                          </CustomAuthorityButton>
                        </span>
                        <span>
                          <span>
                            <MemberDepositInfo memberId={item.memberId} />
                          </span>
                          <span>
                            <MemberCoupon memberId={item.memberId} />
                          </span>
                          <span>
                            <MemberNote memberId={item.memberId} />
                          </span>
                          <span>
                            <MemberMoneyIncDecMini memberId={item.memberId} />
                          </span>
                        </span>
                      </SportsGameFolderContentHomeName>
                    </SportsGameFolderContentHomeBox>
                    <SportsGameMoneyContent
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingBeforeAmount.toString().replace(commonReg2, ',')}
                    </SportsGameMoneyContent>
                    <SportsGameFolderContentCriteria
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderContentCriteria>
                    <SportsGameMoneyContent
                      style={{ borderRight: 'solid 1px #000' }}
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingAfterAmount.toString().replace(commonReg2, ',')}
                    </SportsGameMoneyContent>
                    <SportsGameFolderContentAwayBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentAwayPer>{item.folderSize}폴더</SportsGameFolderContentAwayPer>
                    </SportsGameFolderContentAwayBox>
                    <SportsGameFolderScoreBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.totalOddValue}
                    </SportsGameFolderScoreBox>
                    <SportsGameFolderResultBox
                      style={{ borderRight: 'solid 1px black' }}
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <span style={{ color: 'blue' }}>
                        {item.bettingExpectedAmount.toString().replace(commonReg2, ',')}
                      </span>{' '}
                      &nbsp; / &nbsp;
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderResultBox>
                    <SportsGameFoldeShowBox showStatus={item.showFlag}>
                      {item.showFlag ? '노출' : '비노출'}
                    </SportsGameFoldeShowBox>
                    <SportsGameFolderManualResultBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <CustomButton type="button" bettingResult={item.resultStatus}>
                        {sportResultStatusEnums[item.resultStatus]}
                      </CustomButton>
                    </SportsGameFolderManualResultBox>
                    <SportsGameFolderManualResultBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.resultStatus === 'ING' && (
                        <CustomButton
                          type="button"
                          bettingResult="LOSE"
                          onClick={() => {
                            if (window.confirm('해당 배팅건을 취소 처리 하시겠습니까?')) {
                              onCancelSportsBettingLogHandelr(item.sportsBettingLogKey, item.resultStatus)
                            }
                          }}
                        >
                          취소
                        </CustomButton>
                      )}
                    </SportsGameFolderManualResultBox>
                  </SportsGameFolderContentBox>
                </SportsGameListTableTr>
                {item.isVisible && (
                  <SportsGameListTableTr
                    style={{
                      background: 'transparent',
                      padding: '5px',
                      borderLeft: '1px solid black',
                      borderRight: '1px solid black',
                    }}
                  >
                    <SportsDetailBettingContent
                      content={item.sportsDetailList}
                      fetchSearchSportsMarket={fetchSearchSportsMarket}
                    />
                  </SportsGameListTableTr>
                )}
              </SportsGameListTableBody>
            ))}
          </SportsGameListTable>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}

export default SportBettingLogList
const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const CustomTh = styled.th`
  background: green !important;
`

const CustomTh2 = styled.th`
  background: rgb(0, 104, 86) !important;
`

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead`
  th {
    font-size: 12px;
  }
`

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
  font-size: 13px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  cursor: pointer;
  &:hover {
    border: solid 2px red;
  }
  background: lightgray;
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const SportsGameFolderEdgeBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderNumBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameDeleteBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFoldeShowBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
  color: ${props => (props.showStatus ? 'blue' : 'red')} !important;
`

const SportsGameFolderScoreBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderResultBox = styled.div`
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  border-right: 1px solid rgba(0, 0, 0, 1);
`
const SportsGameFolderContentCriteria = styled.div`
  font-weight: 500;
  color: green;
  width: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgba(0, 128, 0, 1) !important;
  opacity: 0.6;
  color: white !important;
`

const SportsGameFolderContentCriteria2 = styled.div`
  font-weight: 500;
  color: green;
  width: 7.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgb(0, 104, 86, 0.6) !important;
  color: white !important;
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 12%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGamePartnerBox = styled.div`
  width: 11%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameMoneyContent = styled.div`
  width: 9%;
  display: flex;
  // border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentTime = styled.div`
  width: 6%;
  display: flex;
  border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  height: 46px;
  border-left: solid 1px black;
`
