import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 본사 등록
 */
export async function registerBonsa(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/register/bonsa',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 부본사 등록
 */
export async function registerBooBonsa(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/register/boobonsa',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 총판 등록
 */
export async function registerChongpan(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/register/chongpan',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 매장 등록
 */
export async function registerMaejang(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/register/maejang',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 파트너 조회
 */
export async function searchPartners(params) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/register/partners',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 어드민 -> 파트너 등록
 */
export async function registerPartner(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/register',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 어드민 -> 파트너 등록
 */
export async function registerPartnerAll(body) {
  const response = await axios({
    url: '/tyson/admin/v1/partner/management/register-multi',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
