import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { updateLiveSportsExchangeRate } from '../../../../utils/api/sport/sportSettingApi'
import { searchLiveSportsInfo } from '../../../../utils/api/sportsManage/sportsInfo'
import { convertToKstSportsEventDateTime } from '../../../../utils/dateTime'
import SportManagementTabs from '../../SportManagementTabs'
import LiveGameInfoManagementTabs from '../LiveGameInfoManagementTabs'
import Loading from '../../../../shared/components/Loading'
import { decodeAccessToken } from '../../../../utils/token'

const LiveSportsEventList = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const location = useLocation()
  const [sportsType, setSportsType] = useState(new URLSearchParams(location.search).get('type'))
  const [endGameFlag, setEndGameFlag] = useState(false)
  const [liveSportsInfoDatas, setLiveSportsInfoDatas] = useState([])
  const [liveSportsExchangeRate, setLiveSportsExchangeRate] = useState([])

  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    sportsType,
    endGameFlag,
  })

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('type')
    setSportsType(type)

    setParams(prev => ({
      ...prev,
      sportsType: type,
    }))
  }, [location])

  // 경기 목록 조회
  const fetchSearchSportsInfo = async () => {
    setLoading(true)
    await searchLiveSportsInfo(params).then(res => {
      setLiveSportsInfoDatas(res.data.liveSportsGameList)
      setLiveSportsExchangeRate(JSON.parse(res.data.liveSportsExchangeRate))
    })
    setLoading(false)
  }
  const [newLiveSportsExchangeRate, setNewLiveSportsExchangeRate] = useState(liveSportsExchangeRate || {})
  useEffect(() => {
    setNewLiveSportsExchangeRate(liveSportsExchangeRate)
  }, [liveSportsExchangeRate])

  // 환수율 변경
  const [newExchangeRate, setNewExchnageRate] = useState(0)
  const onChangeHandler = newValue => {
    setNewExchnageRate(newValue)
    setNewLiveSportsExchangeRate(prev => ({
      ...prev,
      [sportsType]: {
        exchangeRate: newValue,
      },
    }))
  }

  // 팀명
  const [searchTeamName, setSearchTeamName] = useState('')

  const onSearchTeamNameHandler = e => {
    setSearchTeamName(e.target.value)
  }

  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      teamName: !searchTeamName ? null : searchTeamName,
    }))
  }

  // 검색하기 버튼
  const onSubmitHandler = () => {
    if (loading) return
    if (!Number(newExchangeRate)) {
      alert('값을 다시 확인해주세요.')
    } else if (Number(newExchangeRate) < 50 || Number(newExchangeRate) > 100) {
      alert('환수율은 50이상 100이하만 가능합니다.')
    } else {
      const body = {
        liveSportsExchangeRate: JSON.stringify(newLiveSportsExchangeRate, null, 0),
      }

      setLoading(true)
      updateLiveSportsExchangeRate(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setLoading(false)
          fetchSearchSportsInfo()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setLoading(false)
          fetchSearchSportsInfo()
        })
    }
  }

  const [selectedOption, setSelectedOption] = useState('ongoing')

  const handleOptionClick = option => {
    setSelectedOption(option)
    if (option === 'ongoing') {
      setSearchTeamName('')
      setParams(prev => ({
        ...prev,
        endGameFlag: false,
      }))
    } else {
      setSearchTeamName('')
      setParams(prev => ({
        ...prev,
        endGameFlag: true,
      }))
    }
  }

  useEffect(() => {
    fetchSearchSportsInfo(params)
  }, [params])

  return (
    <Card>
      <CardBody>
        <SportManagementTabs activeKey="7" />
        <LiveGameInfoManagementTabs activeKey={sportsType} />
        <SportsInfoSpan isSelected={selectedOption === 'ongoing'} onClick={() => handleOptionClick('ongoing')}>
          진행중 경기
        </SportsInfoSpan>
        <SportsInfoSpan isSelected={selectedOption === 'finished'} onClick={() => handleOptionClick('finished')}>
          종료된 경기
        </SportsInfoSpan>

        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>팀명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchTeamName"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTeamName}
                  onChange={e => onSearchTeamNameHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="danger"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        {loading ? (
          <Loading />
        ) : (
          <SportsInfoTableWrap>
            {liveSportsInfoDatas.map(liveSportsInfoData => (
              <table>
                <thead>
                  <tr>
                    <td
                      style={{
                        width: '100%',
                        textAlign: 'left',
                        paddingLeft: '20px',
                        height: '50px',
                        display: 'flex',
                        backgroundColor: '#3730a3',
                      }}
                    >
                      <SportsInfoLeagueWrap>
                        {liveSportsInfoData.leagueImage && <img src={liveSportsInfoData.leagueImage} alt="" />}
                        <span>{liveSportsInfoData.leagueName}</span>
                        <span>
                          - [&nbsp;
                          {liveSportsInfoData.ccImage && <img src={liveSportsInfoData.ccImage} alt="" />}
                          <span>{liveSportsInfoData.ccKr}</span>&nbsp;]
                        </span>
                        {liveSportsInfoData.homeImage && <img src={liveSportsInfoData.homeImage} alt="" />}
                        <span style={{ marginLeft: '10px', fontSize: '20px' }}>{liveSportsInfoData.homeName}</span>
                        <span style={{ fontSize: '20px' }}>VS</span>
                        {liveSportsInfoData.awayImage && <img src={liveSportsInfoData.awayImage} alt="" />}
                        <span style={{ marginLeft: '10px', fontSize: '20px' }}>{liveSportsInfoData.awayName}</span>
                      </SportsInfoLeagueWrap>
                      <SportsInfoTimeWrap>
                        <span>경기시간: {convertToKstSportsEventDateTime(liveSportsInfoData.startTime)}</span>
                      </SportsInfoTimeWrap>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {liveSportsInfoData.sportMarkets.map(sportsMarket => (
                    <>
                      <tr>
                        <td
                          style={{
                            width: '100%',
                            textAlign: 'left',
                            paddingLeft: '20px',
                            height: '30px',
                            display: 'flex',
                            borderBottom: '1px solid #888',
                            backgroundColor: 'rgba(209,213,219,1)',
                          }}
                        >
                          <SportsMarketTitleWrap>
                            <span>
                              스페셜{sportsMarket.marketName}(마켓ID:{sportsMarket.marketId})
                            </span>
                            {sportsMarket.marketStop && (
                              <span style={{ width: '40px', background: 'red', color: 'white' }}>정지</span>
                            )}
                          </SportsMarketTitleWrap>
                        </td>
                      </tr>
                      {sportsMarket.sportsMarketPoints.map(sportsMarketPoint => (
                        <>
                          <tr style={{ textAlign: 'center' }}>
                            {sportsMarketPoint.odds.map(sportsMarketPointOdd => (
                              <td
                                style={{
                                  width: '100%',
                                  textAlign: 'center',
                                  paddingLeft: '20px',
                                  height: '30px',
                                  display: 'flex',
                                  borderBottom: '1px solid #888',
                                  backgroundColor: '#f3f4f6',
                                }}
                              >
                                <SportsMarkeTitleWrap>
                                  <span>{sportsMarketPointOdd.oddNameKr}</span>
                                </SportsMarkeTitleWrap>
                              </td>
                            ))}
                          </tr>
                          <tr style={{ textAlign: 'center' }}>
                            {sportsMarketPoint.odds.map(sportsMarketPointOdd => (
                              <td
                                style={{
                                  width: '100%',
                                  textAlign: 'center',
                                  paddingLeft: '20px',
                                  height: '30px',
                                  display: 'flex',
                                  borderBottom: '1px solid #888',
                                  backgroundColor: '#ffffff',
                                }}
                              >
                                <SportsMarkeTitleWrap>
                                  <span>{sportsMarketPointOdd.oddValue}</span>
                                </SportsMarkeTitleWrap>
                              </td>
                            ))}
                          </tr>
                        </>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
            ))}
          </SportsInfoTableWrap>
        )}
      </CardBody>
    </Card>
  )
}

export default LiveSportsEventList

const SportsInfoSpan = styled.span`
  height: 43px !important;
  width: 100px;
  display: inline-block;
  padding-top: 10px;
  color: black;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? '#8c8c8f80' : 'transparent')};
  border-bottom: ${({ isSelected }) => (isSelected ? 'solid 2px black' : 'none')};
`

const SportsInfoTimeWrap = styled.div`
  width: 20%;
  align-items: center;
  display: flex;
  span {
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
    color: white;
  }
`
const SportsInfoTableWrap = styled.div`
  width: 100%;

  table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    thead {
      tr {
        th {
          padding: 4px 4px;
          border: 1px solid #888;
          white-space: nowrap;
          color: black;
          font-weight: 600;
          font-size: 12px;
          text-align: center;
        }
      }
    }

    tbody {
      gap: 10px;

      tr {
        &:hover {
          cursor: pointer;
          border: 2px solid red;
        }
      }
    }

    tr {
      width: 100%;
      display: flex;
    }
  }
`

const SportsMarkeTitleWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 16px;
    margin-left: 5px;
    text-align: center;
    font-weight: 600;
  }
`

const SportsMarketTitleWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 16px;
    margin-left: 5px;
    text-align: center;
    font-weight: 600;
    color: #4b5563;
  }
`

const SportsInfoLeagueWrap = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
    color: white;
  }
`
const SportsEventResultBtn = styled(Button)`
  background: #f59a0a;
  color: white;
  border: none;
`

const SportsEventIdWrap = styled.div`
  width: 12%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventDateWrap = styled.div`
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventTeamWrap = styled.div`
  width: 48%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);

  img {
    width: 22px;
    height: 22px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    margin-left: 10px;
    text-align: center;
  }
`

const SportsEventShowWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
  }
`

const SportsEventResultWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventScoreWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventStatusWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventTypeWrap = styled.div`
  width: 6%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventWrap = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsEventMarketTr = styled.tr`
  width: 100%;
  display: flex;

  border: 1px solid red;

  padding: 5px 5px;
`

const SportsEventMarketContent = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid blue;
`
