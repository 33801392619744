import { useEffect, useState } from 'react'
import axios from '../base/axios'

/**
 * 스포츠 팀 관리 - 리그 선택함에 따라, detail 팀 조회
 */
export async function searchDetailSportsTeamForLeague(params) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/league/detail-team',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 팀 관리 - 리그 목록 조회
 */
export async function searchSportsTeamForLeague(params) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/league/team',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 리그 관리 - 목록 조회
 */
export async function searchSportsLeague(params) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/league',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 라이브 스포츠 게임정보 관리 - 목록 조회
 */
export async function searchLiveSportsInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/game-info/live-market',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 게임정보 관리 - 목록 조회
 */
export async function searchSportsInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/game-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 게임정보  - 결과 조회
 */
export async function searchSportsEventResult(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/result',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 게임정보 관리 - 경기 마켓 리스트 조회
 */
export async function searchSportsMarket(params) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/game-info/market',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 게임정보 관리 - 경기 이벤트 숨김/비숨김 처리
 */
export async function updateSportEventShowStatus(body) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/game-info/event-flag',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 리그 flag update
 */
export async function updateSportLeagueFlag(body) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/league/team',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 스포츠 팀명 update
 */
export async function updateSportTeamName(body) {
  const response = await axios({
    url: '/tyson/admin/v1/sport/management/league/detail-team',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 미처리된 경기 조회
 */
export async function searchUnprocessedSports(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/unprocessed',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
