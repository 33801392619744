import { createSlice } from '@reduxjs/toolkit'

const SportInPlayInfoSlice = createSlice({
  name: 'sportInPlayInfo',
  initialState: {
    sportMenu: [],
    activeMarketSportMenu: -1,
  },
  reducers: {
    setSportInPlayMenu: (state, action) => {
      const sportList = action.payload
      // 축구,야구,Esports 인플레이만 제공한다는 전제하에 아래와 같이 작업
      // 6046: '축구',
      // 48242: '농구',
      // 154914: '야구',
      // 35232: '아이스하키',
      // 154830: '배구',
      // 54094: '테니스',
      // 35709: '핸드볼',
      // 687890: 'E - 스포츠',
      // 131506: '미식축구',
      // 154919: '복싱',
      // 265917: '탁구',
      const allowedSportIds = [6046, 48242,154914, 35232, 154830, 54094, 35709, 687890, 131506, 154919, 265917]
      const filteredSportList = sportList.filter(item => allowedSportIds.includes(item.sportId))
      const newSport = {
        sportId: 0,
        sportName: '전체',
      }
      const updatedSportList = [newSport, ...filteredSportList] // Prepends the newSport to sportList

      state.sportMenu = updatedSportList.map(item => ({
        sportId: item.sportId,
        sportName: item.sportName,
      }))
      state.activeMarketSportMenu = updatedSportList[0].sportId
    },
    setActiveMarketSportInPlayMenu: (state, action) => {
      state.activeMarketSportMenu = action.payload
    },
  },
  extraReducers: builder => {},
})

export const { setSportInPlayMenu, setActiveMarketSportInPlayMenu } = SportInPlayInfoSlice.actions

export default SportInPlayInfoSlice.reducer
