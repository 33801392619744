import axios from '../base/axios'

// 홀덤 배팅내역 조회
export async function searchRevolutionHoldemBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/revolution/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 배팅 내역 삭제
export async function deleteRevolutionHoldemBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/revolution/betting-log/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 배팅 내역 db 다운
export async function adminDownloadRevolutionHoldemBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/download/revolution/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 디테일 배팅 내역 조회
export async function searchRevolutionHoldemDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/revolution/betting-log-detail',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 배팅 세부 내역 삭제
export async function deleteRevolutionHoldemDetailBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/revolution/betting-log-detail/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 배팅 세부 내역 db 다운
export async function adminDownloadRevolutionHoldemDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/download/revolution/betting-log-detail',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 디테일 배팅 내역 조회
export async function searchRevolutionHoldemBuyInOutLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/revolution/buyinout-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 배팅 세부 내역 삭제
export async function deleteRevolutionHoldemBuyInOutLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/revolution/buyinout-log/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 배팅 세부 내역 db 다운
export async function adminDownloadRevolutionHoldemBuyInOutLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/download/revolution/buyinout-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
