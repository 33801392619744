import React, { useMemo } from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import { convertToKstDate, convertToKstDateTime } from '../../../utils/dateTime'
import { DamageTypeEnums } from '../../../enums/MemberManage/BlackListEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import MemberNote from '../MemberList/MemberNote/MemberNote'
import MemberCoupon from '../MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'

const MemberBadListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '회원 ID / 이름 / 닉네임 ',
      accessor: 'memberId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                ({original.memberId} / {original.memberName}/ {original.nickName})
              </span>

              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
      width: 115,
    },
    {
      Header: '세부내용',
      accessor: 'detail',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '발생일',
      accessor: 'excuteAt',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.excuteAt)
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberBadListData

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
