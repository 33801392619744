import { SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import styled from 'styled-components'
import Loading from '../../../shared/components/Loading'
import { searchManualSportsListForManualV2 } from '../../../utils/api/sportsManage/manualSportRegister'
import { convertToKstDateTime } from '../../../utils/dateTime'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import {
  updateSportManualFixtureMarketDelete,
  updateSportManualFixtureMarketStatus,
} from '../../../utils/api/sport/sportFixtureApi'
import { decodeAccessToken } from '../../../utils/token'

const ManualSportsListV2 = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const [params, setParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [apiLoading, setApiLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchManualSportsListForManual = async () => {
    setApiLoading(true)
    await searchManualSportsListForManualV2(params)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
        setApiLoading(false)
      })
      .catch(error => {
        setApiLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchManualSportsListForManual()
  }, [params])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  const onEventDeleteHandler = manualSportsEventKey => {
    if (authority === 'NORMAL') return
    if (apiLoading) return
    if (window.confirm(`해당 경기를 정말 삭제하시겠습니까?`)) {
      setApiLoading(true)
      const body = {
        manualSportsEventKey,
      }

      updateSportManualFixtureMarketDelete(body)
        .then(res => {
          alert('변경에 완료됐습니다.')
          setApiLoading(false)
          fetchSearchManualSportsListForManual()
          window.location.reload()
        })
        .catch(error => {
          alert('변경에 실패하였습니다.')
          setApiLoading(false)
          fetchSearchManualSportsListForManual()
          window.location.reload()
        })
    }
  }

  // 경기 노출 여부
  const onClickFixtureVisibleHandler = (manualSportsEventKey, sportMarketType, showStatus) => {
    if (apiLoading) return

    if (window.confirm(`해당 마켓을 ${showStatus ? '노출' : '비노출'}로 변경하시겠습니까?`)) {
      setApiLoading(true)

      const body = {
        manualSportsEventKey,
        sportMarketType,
        showStatus,
      }

      updateSportManualFixtureMarketStatus(body)
        .then(res => {
          alert('해당 마켓 노출여부 수정이 성공했습니다.')
          fetchSearchManualSportsListForManual()
        })
        .catch(error => {
          alert('해당 마켓 노출여부 수정이 실패했습니다.')
        })
        .finally(() => {
          setApiLoading(false)
        })
    }
  }

  return (
    <>
      <SearchCardTitleWrap>
        <div style={{ marginBottom: '10px' }}>
          <p>
            * 수동으로 등록한 경기마켓의 세부적인 배당 및 상태조정은{' '}
            <span style={{ color: 'red' }}>프리매치-관리(수동), 인플레이-관리(수동)</span>에서 가능합니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      {apiLoading && <Loading />}
      <TableWrap>
        <table>
          <thead>
            <tr>
              <th style={{ borderLeft: 'solid 1px black' }}>번호</th>
              <th>등록시간</th>
              <th>경기시간</th>
              <th>국가&리그&종목</th>
              <th>경기명</th>
              <th>구분</th>
              <th>마켓</th>
              <th>기준점</th>
              <th>홈배당</th>
              <th>원정배당</th>
              <th>배팅유저 수(ID)</th>
              <th>
                노출여부
                <br />
                (국내형,해외형,스페셜,라이브)
              </th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {content.map(item => (
              <tr>
                <td style={{ borderLeft: 'solid 1px black' }}>{item.num}</td>
                <td>{convertToKstDateTime(item.createAt)}</td>
                <td>{convertToKstDateTime(item.startDate)}</td>
                <td>
                  [{item.locationName} - {item.leagueName} - {item.sportName}]
                </td>
                <td>
                  {item.homeName} VS {item.awayName}
                </td>
                <td>
                  {item.isInPlayFixture ? (
                    <span style={{ color: 'red' }}>인플레이</span>
                  ) : (
                    <span style={{ color: 'blue' }}>프리매치</span>
                  )}
                </td>
                <td style={{ color: 'orange' }}>{item.marketName}</td>
                <td>{item.baseLine || '-'}</td>
                <td>{item.homePrice}</td>
                <td>{item.awayPrice}</td>
                <td style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                  <TooltipWrapper text={`${item.marketName} 마켓 배팅 유저 ID: ${item.bettingUserIds}`} place="bottom">
                    <span>{item.bettingUsersCount}명</span>
                  </TooltipWrapper>
                </td>
                <td>
                  {item.isInPlayFixture ? (
                    <>
                      <span>
                        인플레이:
                        {item.liveShowStatus ? (
                          <FiEye
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'LIVE', false)
                            }}
                          />
                        ) : (
                          <FiEyeOff
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'LIVE', true)
                            }}
                          />
                        )}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        국내형:
                        {item.domesticShowStatus ? (
                          <FiEye
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'DOMESTIC', false)
                            }}
                          />
                        ) : (
                          <FiEyeOff
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'DOMESTIC', true)
                            }}
                          />
                        )}
                      </span>
                      <span>
                        &nbsp;해외형:
                        {item.overseasShowStatus ? (
                          <FiEye
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'OVERSEAS', false)
                            }}
                          />
                        ) : (
                          <FiEyeOff
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'OVERSEAS', true)
                            }}
                          />
                        )}
                      </span>
                      <span>
                        &nbsp;스페셜:
                        {item.specialShowStatus ? (
                          <FiEye
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'SPECIAL', false)
                            }}
                          />
                        ) : (
                          <FiEyeOff
                            style={{ width: '20px', height: '30px', cursor: 'pointer' }}
                            onClick={() => {
                              onClickFixtureVisibleHandler(item.manualSportsEventKey, 'SPECIAL', true)
                            }}
                          />
                        )}
                      </span>
                    </>
                  )}
                </td>
                <td>
                  {' '}
                  <CustomButton
                    onClick={() => {
                      onEventDeleteHandler(item.manualSportsEventKey)
                    }}
                  >
                    삭제
                  </CustomButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <PaginationWrap>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </PaginationWrap>
        {content.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
      </TableWrap>
    </>
  )
}

export default ManualSportsListV2

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
`

const CustomButton = styled.button`
  width: 70px;
  height: 25px;
  background-color: red;
  color: white;
  border: none;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 630px;
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    // display: table-row-group;
    vertical-align: middle;
    // border-color: inherit;
    // border: none;
    tr {
      width: 100%;
      &:hover {
        border: 2px solid red !important;
      }
    }
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border: 1px solid #888;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`
