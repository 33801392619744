import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  searchSportInPlayFixtureBettingMarketList,
  searchSportInPlayFixtureList,
} from '../utils/api/sport/sportInplayFixtureApi '

export const fetchSportInPlayFixtureList = createAsyncThunk(
  'sportInPlayFixture/fetchSportInPlayFixtureList',
  async params => {
    const res = await searchSportInPlayFixtureList(params)
    return res.data
  },
)

export const fetchSportsInPlayFixtureBettingMarketList = createAsyncThunk(
  'sportInplayFixture/fetchSportsInplayFixtureBettingMarketList',
  async params => {
    const res = await searchSportInPlayFixtureBettingMarketList(params)
    return res.data
  },
)

const SportInPlayFixtureSlice = createSlice({
  name: 'sportInPlayFixture',
  initialState: {
    loading: true,
    marketLoading: true,
    totalElements: 0,
    sportFixtures: {},
  },
  reducers: {
    setSportInPlayFixtures: (state, action) => {
      console.log('action ', JSON.stringify(action.payload))
    },

    // updatedSportFixtureMarketPanelShow: (state, action) => {
    //   const { key, fixtureId, marketPanelShow } = action.payload

    //   const fixtureIndex = state.sportFixtures[key]?.fixtures?.findIndex(item => item.fixtureId === fixtureId)

    //   state.sportFixtures[key].fixtures[fixtureIndex].marketPanelShow = marketPanelShow
    // },

    updatedSportInplayFixtureBettingMarketListShow: (state, action) => {
      const { key, fixtureId, bettingMarketTrShow } = action.payload
      const fixtureIndex = state.sportFixtures[key]?.fixtures?.findIndex(item => item.fixtureId === fixtureId)

      state.sportFixtures[key].fixtures[fixtureIndex].bettingMarketTrShow = bettingMarketTrShow
    },

    updatedSportInplayBettingInfoListShow: (state, action) => {
      const { key, fixtureId, bettingInfoTrShow, marketId } = action.payload

      const fixtureIndex = state.sportFixtures[key]?.fixtures?.findIndex(item => item.fixtureId === fixtureId)
      if (fixtureIndex === -1) return
      const marketIndex = state.sportFixtures[key]?.fixtures[fixtureIndex]?.fixtureBettingMarketList.findIndex(
        item => item.marketId === marketId,
      )
      if (marketIndex === -1) return
      state.sportFixtures[key].fixtures[fixtureIndex].fixtureBettingMarketList[marketIndex].bettingInfoTrShow =
        bettingInfoTrShow
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchSportsInPlayFixtureBettingMarketList.pending, (state, action) => {
      state.loading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSportsInPlayFixtureBettingMarketList.fulfilled, (state, action) => {
      const { leagueId, leagueName, startDate, fixtureId, fixtureBettingMarketList } = action.payload
      const sportFixtureKey = `${leagueId}-${startDate}`

      if (fixtureBettingMarketList.length === 0) {
        state.loading = false
        return
      }
      const fixtureIndex = state.sportFixtures[sportFixtureKey]?.fixtures?.findIndex(
        item => item.fixtureId === fixtureId,
      )

      if (fixtureIndex === -1) {
        state.loading = false
        return
      }

      state.sportFixtures[sportFixtureKey].fixtures[fixtureIndex] = {
        ...state.sportFixtures[sportFixtureKey].fixtures[fixtureIndex],
        fixtureBettingMarketList,
      }

      state.loading = false
    })

    builder.addCase(fetchSportInPlayFixtureList.pending, (state, action) => {
      state.loading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSportInPlayFixtureList.fulfilled, (state, action) => {
      const { sportEvents, totalElements } = action.payload
      if (!sportEvents || sportEvents.length === 0) {
        state.sportFixtures = []
        state.totalElements = 0
        state.loading = false
        return
      }
      state.totalElements = totalElements

      state.sportFixtures = sportEvents.reduce((grouped, sportEvent) => {
        const key = `${sportEvent.fixture.leagueId}-${sportEvent.fixture.startDate}`
        if (!grouped[key]) {
          grouped[key] = {
            sportId: sportEvent.fixture.sportId,
            sportName: sportEvent.fixture.sportName,
            sportImageUrl: sportEvent.fixture.sportImageUrl,
            locationId: sportEvent.fixture.locationId,
            locationName: sportEvent.fixture.locationName,
            locationImageUrl: sportEvent.fixture.locationImageUrl,
            leagueId: sportEvent.fixture.leagueId,
            leagueName: sportEvent.fixture.leagueName,
            leagueImageUrl: sportEvent.fixture.leagueImageUrl,
            fixtures: [],
          }
        }
        grouped[key].fixtures.push({ ...sportEvent, marketPanelShow: false, bettingMarketTrShow: false })

        return grouped
      }, {})
      state.loading = false
    })
  },
})

export const {
  setSportInPlayFixtures,
  updatedSportInplayBettingInfoListShow,
  updatedSportInplayFixtureBettingMarketListShow,
} = SportInPlayFixtureSlice.actions

export default SportInPlayFixtureSlice.reducer
