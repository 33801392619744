import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { MemberWithdrawalStatusEnums } from '../../../enums/MemberHistory/MemberWithdrawalEnums'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  DepositStyledModal,
} from '../../../shared/components/CustomModal'
import Loading from '../../../shared/components/Loading'
import {
  searchMemberMoneyWithdrawal,
  updateMemberMoneyHistoryDelete,
  updateMemberMoneyWithdrawalStatus,
} from '../../../utils/api/memberHistory/memberHistoryApi'
import { downloadMemberWithdrawalList } from '../../../utils/api/memberManage/memberManageApi'
import { decodeAccessToken } from '../../../utils/token'
import MemberHistoryTabs from '../MemberHistoryTabs'
import MoneyWithdrawalStatusForm from '../MoneyWithdrawalStatusForm'
import WithdrawalListData from './WithdrawalListData'

const WithdrawalList = () => {
  const dispatch = useDispatch()
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [withdrawalLoading, setWithdrawalLoading] = useState(false)

  const authority = decodeAccessToken()?.adminAuthority
  const [isCheckWithdrawalApprove, setCheckWithdrawalApprove] = useState('')

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)

  /**
   * 회원 머니출금내역 조회
   */
  const [params, setParams] = useState({
    page,
    size,
    edgeName: null,
    authority: null,
    memberId: null,
    status: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchSearchMemberMoneyWithdrawal = async () => {
    if (loading) return

    setLoading(true)

    await searchMemberMoneyWithdrawal(params)
      .then(response => {
        setTotalElement(response.data.totalElement)
        setContent(response.data.content)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchMemberMoneyWithdrawal()
  }, [params])

  const [checkRadio, setCheckRadio] = useState([])

  const [withdrawalListData, setWithdrawalListData] = useState(
    WithdrawalListData(setCheckWithdrawalApprove, content, checkRadio, setCheckRadio, fetchSearchMemberMoneyWithdrawal),
  )

  useEffect(() => {
    setWithdrawalListData(
      WithdrawalListData(
        setCheckWithdrawalApprove,
        content,
        checkRadio,
        setCheckRadio,
        fetchSearchMemberMoneyWithdrawal,
      ),
    )
  }, [content, checkRadio])

  const [rows, setData] = useState(withdrawalListData.tableRowsData)
  useEffect(() => {
    setData(withdrawalListData.tableRowsData)
  }, [withdrawalListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디, 상태, 처리일 검색
  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 상태
  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const [searchStatusToggle, setSearchStatusToggle] = useState(false)

  const onChangeSearchStatusHandler = status => {
    setSearchStatusOptionValue(status)
    setSearchStatusToggle(prev => !prev)
  }

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      status: searchStatusOptionValue,
    }))
  }, [searchStatusToggle])

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      memberId: !searchMemberId ? null : searchMemberId,
      topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
      status: !searchStatusOptionValue ? null : searchStatusOptionValue,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }
  const [applyMoney, setApplyMoney] = useState('')
  const [applyWithdrawalKey, setApplyWithdrawalKey] = useState('')
  const [applyMember, setApplyMember] = useState('')
  const [applyStatus, setApplyStatus] = useState('')
  const [isMoneyInfoModalShow, setMoneyInfoModalShow] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDeleteHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 목록을 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?\n삭제할 경우 유저에게만 보이지 않습니다.')) {
      setDeleteLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'WITHDRAWAL',
        moneyHistoryKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
    }
  }

  const deleteAllHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return

    if (window.confirm('출금내역 전체를 정말 삭제하시겠습니까?\n삭제할 경우 유저에게만 보이지 않습니다.')) {
      setDeleteLoading(true)
      updateMemberMoneyHistoryDelete({
        moneyType: 'WITHDRAWAL',
        moneyHistoryKeyList: [],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제 실패했습니다.')
          setCheckRadio([])
          fetchSearchMemberMoneyWithdrawal()
        })
    }
  }

  const createMoneyModalClickHandler = () => {
    setMoneyInfoModalShow(prev => !prev)
  }
  // 대기 버튼
  const onWaitingWithdrawalHandler = () => {
    if (withdrawalLoading) return
    switch (MemberWithdrawalStatusEnums[isCheckWithdrawalApprove.status]) {
      case '신청':
        if (window.confirm(`${isCheckWithdrawalApprove.memberId}님의 출금을 대기처리 하시겠습니까?`)) {
          setWithdrawalLoading(true)
          updateMemberMoneyWithdrawalStatus({
            moneyWithdrawalKey: isCheckWithdrawalApprove.moneyWithdrawalKey,
            memberId: isCheckWithdrawalApprove.memberId,
            status: 'WAITING',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setWithdrawalLoading(false)
              setCheckWithdrawalApprove('')
              fetchSearchMemberMoneyWithdrawal()
              alert(`${isCheckWithdrawalApprove.memberId}회원의 출금이 대기 처리 되었습니다.`)
            })
            .catch(error => {
              const { errorCode, message } = error.response.data

              switch (errorCode) {
                case 'ASSET-7001':
                  alert('잠시후 다시 시도해주세요.')
                  break
                case 'MONEY-2002':
                  alert('해당 출금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
                  break
                default:
                  alert(`${isCheckWithdrawalApprove.memberId}회원의 출금 대기 처리가 실패했습니다.`)
              }
              setWithdrawalLoading(false)
              setCheckWithdrawalApprove('')
            })
        }
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 건은 신청상태가 아니므로 대기처리가 불가합니다.')
        break
    }
  }

  // 승인 버튼
  const onApproveWithdrawalHandler = () => {
    if (withdrawalLoading) return
    switch (MemberWithdrawalStatusEnums[isCheckWithdrawalApprove.status]) {
      case '대기':
        setApplyStatus('APPROVE')
        setMoneyInfoModalShow(true)
        setApplyMember(isCheckWithdrawalApprove.memberId)
        setApplyWithdrawalKey(isCheckWithdrawalApprove.moneyWithdrawalKey)
        setApplyMoney(isCheckWithdrawalApprove.applyMoney)
        break
      case '신청':
        setApplyStatus('APPROVE')
        setMoneyInfoModalShow(true)
        setApplyMember(isCheckWithdrawalApprove.memberId)
        setApplyWithdrawalKey(isCheckWithdrawalApprove.moneyWithdrawalKey)
        setApplyMoney(isCheckWithdrawalApprove.applyMoney)
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 건은 신청 및 대기상태가 아니므로 승인처리가 불가합니다.')
        break
    }
  }

  // 취소 버튼
  const onCancelWithdrawalHandler = () => {
    if (withdrawalLoading) return
    switch (MemberWithdrawalStatusEnums[isCheckWithdrawalApprove.status]) {
      case '대기':
        setApplyStatus('CANCEL')
        setMoneyInfoModalShow(true)
        setApplyMember(isCheckWithdrawalApprove.memberId)
        setApplyWithdrawalKey(isCheckWithdrawalApprove.moneyWithdrawalKey)
        setApplyMoney(isCheckWithdrawalApprove.applyMoney)
        break
      case '신청':
        setApplyStatus('CANCEL')
        setMoneyInfoModalShow(true)
        setApplyMember(isCheckWithdrawalApprove.memberId)
        setApplyWithdrawalKey(isCheckWithdrawalApprove.moneyWithdrawalKey)
        setApplyMoney(isCheckWithdrawalApprove.applyMoney)
        break
      case undefined:
        alert('회원을 먼저 선택해주세요')
        break
      default:
        alert('해당 건은 신청 및 대기상태가 아니므로 취소처리가 불가합니다.')
        break
    }
  }
  const [activeKey, setActiveKey] = useState('2')
  const getStatusText = status => {
    if (status === 'APPROVE') return '출금 최종승인'
    if (status === 'CANCEL') return '출금 최종취소'
    return ''
  }

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    topPartnerId: '상위 파트너',
    userPhoneNum: '핸드폰',
    authority: '권한',
    memberId: '유저ID',
    memberName: '유저명',
    bankName: '은행명',
    beforeAmount: '신청전 머니',
    beforeCasinoMoney: '신청전 게임머니',
    accountNum: '계좌번호',
    applyMoney: '신청액',
    applyDate: '신청일',
    executeDate: '승인일',
    status: '상태',
    memo: '메모',
  }
  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (
      window.confirm(
        '출금내역 DB를 csv파일로 다운 받으시겠습니까? \n 유저ID 및 날짜를 입력하지 않고 다운받을 경우 느릴 수 있습니다.',
      )
    ) {
      setDownloadLoading(true)
      downloadMemberWithdrawalList({
        memberId: !searchMemberId ? null : searchMemberId,
        topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
        startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
        endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '출금 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  const resetSearchValue = () => {
    setUserAuthority(null)
    setSearchEdgeName('')
    setSearchEdgeNameOptionValue(null)
    setSearchStatusOptionValue(null)
    setSearchMemberId(null)
    setSearchTopPartnerId(null)
    setSearchExecuteStartDate(null)
    setSearchExecuteEndDate(null)
  }

  const reloadOnoToOneHandler = () => {
    if (loading) return
    setLoading(true)
    resetSearchValue()
    setParams({
      page: 0,
      size,
      edgeName: null,
      userId: null,
      topPartnerId: null,
      startDate: null,
      endDate: null,
    })
    setLoading(false)
  }

  return (
    <Card>
      <CardBody>
        <MemberHistoryTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 출금신청한 유저의 상태값은 <span style={{ fontWeight: 'bolder', color: 'red' }}>&quot;신청&ldquo;</span>
              이며 해당건을&nbsp;
              <span style={{ fontWeight: 'bolder', color: 'blue' }}>&quot;대기, 승인, 취소&ldquo;</span>&nbsp;를 &nbsp;{' '}
              <span style={{ fontWeight: 'bolder', color: 'black' }}>&quot;1건&ldquo;</span>별 처리할 수 있습니다.
            </p>
            <p>
              * <span style={{ fontWeight: 'bolder', color: 'red' }}>상위파트너로 검색</span>시, 해당 파트너 포함,
              직영/하부 회원&파트너들의 내역을 가져옵니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal style={{ marginBottom: '0px' }}>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상위파트너 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTopPartnerId}
                  onChange={e => onSearchTopPartnerIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteStartDate}
                  onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchExecuteEndDate}
                  onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'green', color: 'white' }}
              onClick={e => {
                downloadMemberListHandler()
              }}
            >
              출금내역 DB 다운
            </Button>
            <Button
              size="sm"
              style={{ marginLeft: '10px', backgroundColor: 'pink', fontWeight: '600', color: '#000' }}
              variant="outline-secondary"
              onClick={() => {
                reloadOnoToOneHandler()
              }}
            >
              새로고침
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <SearchStatusWrap>
          <Button
            size="sm"
            active={searchStatusOptionValue === null}
            variant="outline-secondary"
            onClick={e => {
              onChangeSearchStatusHandler(null)
            }}
          >
            전체
          </Button>
          {Object.keys(MemberWithdrawalStatusEnums).map(status => {
            return (
              <Button
                id={status}
                size="sm"
                active={searchStatusOptionValue === status}
                variant="outline-secondary"
                onClick={e => {
                  onChangeSearchStatusHandler(status)
                }}
              >
                {MemberWithdrawalStatusEnums[status]}
              </Button>
            )
          })}
        </SearchStatusWrap>

        {withdrawalLoading || loading ? (
          <Loading />
        ) : (
          <>
            <ReactTableBase
              key="common"
              columns={withdrawalListData.tableHeaderData}
              data={rows}
              tableConfig={tableConfig}
            />
            <DepositStyledModal show={isMoneyInfoModalShow} onHide={createMoneyModalClickHandler} $header>
              <CustomModalHeader>
                <CustomModalCloseButton
                  className="lnr lnr-cross"
                  aria-label="close-btn"
                  type="button"
                  onClick={createMoneyModalClickHandler}
                />
                <CustomModalTitle>
                  {applyMember}유저 {getStatusText(applyStatus)}
                </CustomModalTitle>
              </CustomModalHeader>
              <CustomModalBody>
                <MoneyWithdrawalStatusForm
                  applyStatus={applyStatus}
                  memberId={applyMember}
                  moneyWithdrawalKey={applyWithdrawalKey}
                  applyMoney={applyMoney}
                  setCheckWithdrawalApprove={setCheckWithdrawalApprove}
                  setMoneyInfoModalShow={setMoneyInfoModalShow}
                  fetchSearchMemberMoneyWithdrawal={fetchSearchMemberMoneyWithdrawal}
                />
              </CustomModalBody>
            </DepositStyledModal>
            {
              {
                SUPER: (
                  <>
                    <SearchContainer horizontal>
                      <SearchButtonWrap>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'green', color: 'white' }}
                          onClick={() => {
                            onWaitingWithdrawalHandler()
                          }}
                        >
                          출금대기
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'blue', color: 'white' }}
                          onClick={() => {
                            onApproveWithdrawalHandler()
                          }}
                        >
                          출금승인
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'red', color: 'white' }}
                          onClick={() => {
                            onCancelWithdrawalHandler()
                          }}
                        >
                          출금취소
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'orange', color: 'white' }}
                          onClick={() => {
                            setCheckRadio(content.map(value => value.moneyWithdrawalKey))
                          }}
                        >
                          전체선택
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'grey', color: 'white' }}
                          onClick={() => {
                            onDeleteHandler()
                          }}
                        >
                          비노출하기
                        </Button>
                        <Button
                          size="sm"
                          style={{ backgroundColor: 'red', color: '#fff' }}
                          variant="outline-secondary"
                          onClick={() => {
                            deleteAllHandler()
                          }}
                        >
                          전체비노출
                        </Button>
                      </SearchButtonWrap>
                    </SearchContainer>
                  </>
                ),
              }[authority]
            }
          </>
        )}
      </CardBody>
    </Card>
  )
}

export default WithdrawalList

const SearchStatusWrap = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 0;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
