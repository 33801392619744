import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BetSettlementStatus, BetStatus } from '../../../enums/Sport/SportMarketEnums'
import { updateSportsDetailBettingLogV2 } from '../../../utils/api/sport/sportBettingLogApi'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'
import {
  FixtureBetResult,
  RealVirtualGameSportType,
  VirtualGameBettingType,
  VirtualGameLeagueType,
} from '../../../enums/VirtualGame/virtualGameEnums'
import { updateVirtualGameDetailBettingLog } from '../../../utils/api/virtualGame/virtualGameApi'

const VirtualDetailBettingLog = ({ virtualDetailBettingLogList }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [newSettlementType, setNewSettlementType] = useState(null)

  useEffect(() => {
    setNewSettlementType(virtualDetailBettingLogList.betSettlementType)
  }, [virtualDetailBettingLogList])

  const onSubmit2 = (virtualDetailBettingLogKey, adminChange, betSettlementType) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (!betSettlementType) {
      alert('진행중으로는 결과변경이 불가합니다.')
      return
    }
    if (
      window.confirm('해당 경기의 결과를 변경하시겠습니까? \n 해당 회원의 최종적인 결과는 1분이내 자동으로 반영됩니다.')
    ) {
      const body = {
        virtualDetailBettingLogKey,
        betSettlementType,
        adminChange,
      }
      setWriteLoading(true)

      updateVirtualGameDetailBettingLog(body)
        .then(res => {
          setWriteLoading(false)
          alert('가상게임 결과가 변경됐습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          alert('가상게임 결과가 변경이 실패했습니다.')
        })
    }
  }

  const [betSettlementTypes, setBetSettlementTypes] = useState(
    virtualDetailBettingLogList.reduce((acc, log) => {
      acc[log.virtualDetailBettingLogKey] = log.betSettlementType || ''
      return acc
    }, {}),
  )

  const [adminChanges, setAdminChanges] = useState(
    virtualDetailBettingLogList.reduce((acc, log) => {
      acc[log.virtualDetailBettingLogKey] = log.adminChange || false
      return acc
    }, {}),
  )

  const handleCheckboxChange = virtualDetailBettingLogKey => {
    setAdminChanges(prev => ({
      ...prev,
      [virtualDetailBettingLogKey]: !prev[virtualDetailBettingLogKey],
    }))
  }

  const handleSelectChange = (virtualDetailBettingLogKey, value) => {
    setBetSettlementTypes(prev => ({
      ...prev,
      [virtualDetailBettingLogKey]: value,
    }))
  }

  const getFixtureResult = detailBettingLog => {
    switch (detailBettingLog.sportType) {
      case 'VIRTUAL_SOCCER':
        if (detailBettingLog.betSettlementType === 'NOT_SETTLED') {
          return '-'
        }
        return `${detailBettingLog.homeScore} : ${detailBettingLog.awayScore}`
      case 'VIRTUAL_BASKETBALL':
        if (detailBettingLog.betSettlementType === 'NOT_SETTLED') {
          return '-'
        }
        return `승패: ${FixtureBetResult[detailBettingLog.winDrawLoseResult]}, 핸디캡: ${
          FixtureBetResult[detailBettingLog.handicapResult]
        }, 오버언더: ${FixtureBetResult[detailBettingLog.overUnderResult]}`
      case 'VIRTUAL_BASEBALL':
        if (detailBettingLog.betSettlementType === 'NOT_SETTLED') {
          return '-'
        }
        return `${detailBettingLog.homeScore} : ${detailBettingLog.awayScore}`
      case 'VIRTUAL_GREYHOUNDS':
        if (detailBettingLog.betSettlementType === 'NOT_SETTLED') {
          return '-'
        }
        return `1등 : ${detailBettingLog.raceFirstNumber}번  ${detailBettingLog.raceFirstName}`
      case 'VIRTUAL_HORSE':
        if (detailBettingLog.betSettlementType === 'NOT_SETTLED') {
          return '-'
        }
        return `1등 : ${detailBettingLog.raceFirstNumber}번  ${detailBettingLog.raceFirstName}`
      default:
        return '-'
    }
  }

  return (
    <SportDetailBettingLogWrap>
      <td colSpan={17} style={{ padding: '10px' }}>
        <table>
          <thead>
            <tr>
              <th>경기시간</th>
              <th>종목</th>
              <th>리그</th>
              <th>경기명</th>
              <th>경기결과</th>
              <th>마켓</th>
              <th>배팅</th>
              <th>배당</th>
              <th colSpan={1}>
                <TooltipWrapper place="bottom">
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>기준점</span>
                </TooltipWrapper>
              </th>
              <th>배팅 결과</th>
              {/* <th>
                <TooltipWrapper text="체크시, API 실제 결과 값을 무시" place="bottom">
                  <span style={{ color: 'lightgrey', textDecoration: 'underline', cursor: 'pointer' }}>결과고정</span>
                </TooltipWrapper>
              </th> */}
              <th>기능</th>
            </tr>
          </thead>
          <tbody>
            {virtualDetailBettingLogList.map(virtualGameDetailBettingLog => {
              return (
                <tr>
                  <td>{convertToKstSportsEventDateTime(virtualGameDetailBettingLog.startDate)}</td>
                  <td>{RealVirtualGameSportType[virtualGameDetailBettingLog.sportType]}</td>
                  <td>{VirtualGameLeagueType[virtualGameDetailBettingLog.leagueType]}</td>
                  <td>
                    {virtualGameDetailBettingLog.homeName
                      ? `${virtualGameDetailBettingLog.homeName} vs ${virtualGameDetailBettingLog.awayName}`
                      : '-'}
                  </td>
                  <td>{getFixtureResult(virtualGameDetailBettingLog)}</td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {virtualGameDetailBettingLog.marketName}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {virtualGameDetailBettingLog.selectedBetType === 'RACE_WINNER_PICK'
                      ? `${virtualGameDetailBettingLog.selectedRaceNumber}번 ${
                          virtualGameDetailBettingLog.selectedRaceName
                        } ${VirtualGameBettingType[virtualGameDetailBettingLog.selectedBetType]}`
                      : `${VirtualGameBettingType[virtualGameDetailBettingLog.selectedBetType]}`}
                  </td>
                  <td
                    style={{
                      background: 'rgba(237, 222, 88,0.5)',
                      color: '#000',
                    }}
                  >
                    {virtualGameDetailBettingLog?.selectedBetPrice || 0}
                  </td>
                  <td>{virtualGameDetailBettingLog.lineNum || '-'}</td>
                  <td>
                    {virtualGameDetailBettingLog.betSettlementType === 'CANCELLED' ? (
                      <span style={{ color: 'red', fontWeight: '500' }}>취소</span>
                    ) : (
                      <select
                        style={{ padding: '0px 3px' }}
                        value={betSettlementTypes[virtualGameDetailBettingLog.virtualDetailBettingLogKey]}
                        onChange={e =>
                          handleSelectChange(virtualGameDetailBettingLog.virtualDetailBettingLogKey, e.target.value)
                        }
                      >
                        <option value="" selected={!virtualGameDetailBettingLog.betSettlementType}>
                          진행중
                        </option>
                        <option value="WINNER" selected={virtualGameDetailBettingLog.betSettlementType === 'WINNER'}>
                          적중
                        </option>
                        <option value="LOSER" selected={virtualGameDetailBettingLog.betSettlementType === 'LOSER'}>
                          미적중
                        </option>
                        <option value="REFUND" selected={virtualGameDetailBettingLog.betSettlementType === 'REFUND'}>
                          적특
                        </option>
                      </select>
                    )}
                  </td>
                  {/* <td>
                    <input
                      style={{ width: '17px', height: '17' }}
                      type="checkbox"
                      checked={adminChanges[virtualGameDetailBettingLog.virtualDetailBettingLogKey]}
                      onChange={() => handleCheckboxChange(virtualGameDetailBettingLog.virtualDetailBettingLogKey)}
                    />
                  </td> */}
                  <td>
                    {virtualGameDetailBettingLog.betSettlementType === 'CANCELLED' ? (
                      '-'
                    ) : (
                      <button
                        type="button"
                        style={{ border: 'none', background: 'grey', color: '#fff' }}
                        onClick={() => {
                          onSubmit2(
                            virtualGameDetailBettingLog.virtualDetailBettingLogKey,
                            adminChanges[virtualGameDetailBettingLog.virtualDetailBettingLogKey],
                            betSettlementTypes[virtualGameDetailBettingLog.virtualDetailBettingLogKey],
                          )
                        }}
                      >
                        변경
                      </button>
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </td>
    </SportDetailBettingLogWrap>
  )
}

export default VirtualDetailBettingLog

VirtualDetailBettingLog.propTypes = {
  virtualDetailBettingLogList: PropTypes.arrayOf().isRequired,
}

VirtualDetailBettingLog.defaultProps = {}

const SportDetailBettingLogWrap = styled.tr`
  border: 1px solid #888;

  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #6b6b6b !important;

      &:first-child {
        border-top: 1px solid #888;
      }

      &:hover {
        border: none;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: #fff !important;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      background: #fbf7ea;

      &:hover {
        border: none;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
