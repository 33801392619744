import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import { CustomStyledModal } from '@/shared/components/CustomModal'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ko from 'date-fns/locale/ko'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { MemberStatusEnums, SettlementSelectEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { PartnerLosingType } from '../../../enums/Statistics/partnerLosingStatistics'
import {
  fetchSearchPartnerSettlementStatisticsV2,
  handleIsClosed,
  handleSearchUserId,
  resetSettlementDataV2,
} from '../../../redux/SettlementInfoSliceV2'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import Loading from '../../../shared/components/Loading'
import { convertToKst } from '../../../utils/dateTime'
import { scrollbarStyles } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import StatisticsTabs from '../StatisticsTabs'
import AuthoritySearchButtonV2 from '../../../shared/components/AuthoritySearchButtonV2'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const getUserAuthority = userAuthority => {
  return (
    <CustomAuthorityButton authority={userAuthority} disabled>
      {NoteAuthorityEnums[userAuthority]}
    </CustomAuthorityButton>
  )
}

const getMoneyAmount = moneyAmount => {
  if (!moneyAmount) return <span>0</span>
  if (moneyAmount > 1000000) {
    return <span style={{ color: 'red', fontWeight: '500' }}>{moneyAmount.toString().replace(commonReg2, ',')}</span>
  }
  return <span style={{ color: 'black', fontWeight: '500' }}>{moneyAmount.toString().replace(commonReg2, ',')}</span>
}

const getSettlementType = settlementType => {
  switch (settlementType) {
    case 'LOSING_SETTLEMENT_TYPE1':
      return <span style={{ color: 'orange', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE2':
      return <span style={{ color: 'purple', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE3':
      return <span style={{ color: '#B92EFA', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE4':
      return <span style={{ color: '#D79209', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE5':
      return <span style={{ color: 'brown', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE6':
      return <span style={{ color: '#A8D420', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE7':
      return <span style={{ color: '#634BC6', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE8':
      return <span style={{ color: '#f28c0f', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE9':
      return <span style={{ color: '#f28c0f', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    default:
      return <span style={{ color: '#black', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
  }
}

const getUserStatus = status => {
  switch (status) {
    case 'NORMAL':
      return <span style={{ color: 'blue', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'WAITING':
      return <span style={{ color: 'orange', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'CANCEL':
      return <span style={{ color: 'red', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'BLOCK':
      return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    case 'APPLY':
      return <span style={{ color: 'green', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
    default:
      return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[status]}</span>
  }
}

const twoDifferenceValue = (n1, n2) => {
  return (Number(n1) - Number(n2)).toString().replace(commonReg2, ',')
}

const twoSumValue = (n1, n2) => {
  return (Number(n1) + Number(n2)).toString().replace(commonReg2, ',')
}

const threeSumValue = (n1, n2, n3) => {
  return (Number(n1) + Number(n2) + Number(n3)).toString().replace(commonReg2, ',')
}

const DisplayUserComponent = ({
  user = {},
  scrollUserKey,
  depth = 0,
  toggleIsOpened,
  isActiveAssetInfo,
  isActiveLiveInfo,
  isActiveSlotInfo,
  isActiveHoldemInfo,
  isActiveMiniGameInfo,
  isActiveSportsInfo,
  isActiveVirtualGameInfo,
  setModalShow,
  setWhatModalShow,
  setModalUserId,
}) => {
  const userRef = useRef(null) // 사용자 요소에 대한 참조
  const handleIconClick = (key, isOpened) => {
    toggleIsOpened(key, isOpened)
    // 마지막으로 누른 시점을 기억
    // localStorage.setItem('lastClickedUserKey', key)
  }

  useEffect(() => {
    // 사용자 요소가 마운트된 후에만 실행되도록 설정
    if (user.userKey === scrollUserKey && user.isOpened && userRef.current) {
      // 사용자 요소가 화면에 표시되도록 스크롤 이동
      userRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [user.isOpened])

  return (
    <>
      <CustomTr
        ref={userRef}
        style={{ background: depth === 0 ? '#fbf7ea' : 'inherit', border: 'solid 2px red' }}
        depth={depth}
      >
        <td
          style={{
            paddingLeft: `${depth * 20 + 20}px`,
            textAlign: 'left',
            borderLeft: 'solid 1px black',
            fontSize: '13px',
          }}
        >
          {depth > 0 && `\u2514 `}
          {user?.subUsersCount > 0 && (
            <span
              onClick={() => {
                handleIconClick(user.userKey, user.isOpened)
              }}
              style={{ cursor: 'pointer' }}
            >
              {user.isOpened ? <MinusSquare /> : <PlusSquare />}
            </span>
          )}
          <CustomSpan
            isBlock={user.userDefaultInfoForSettlement.userStatus}
            onClick={() => {
              window.open(`/user-info/${user.userDefaultInfoForSettlement.userId}`, '_blank')
            }}
          >
            {user.userDefaultInfoForSettlement.userId} ({user.userDefaultInfoForSettlement.userName}){' '}
            {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' &&
              `- [${user.userDefaultInfoForSettlement.partnerLevel}LV]`}
          </CustomSpan>
          <span>
            <MemberDepositInfo memberId={user.userDefaultInfoForSettlement.userId} />
          </span>
          <span>
            <MemberCoupon memberId={user.userDefaultInfoForSettlement.userId} />
          </span>
          <span>
            <MemberNote memberId={user.userDefaultInfoForSettlement.userId} />
          </span>
          &nbsp;- {getUserAuthority(user.userDefaultInfoForSettlement.userAuthority)}
        </td>
        <td>
          {user.userDefaultInfoForSettlement.topUserId ? (
            <span
              style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                window.open(`/user-info/${user.userDefaultInfoForSettlement.topUserId}`, '_blank')
              }}
            >
              {user.userDefaultInfoForSettlement.topUserId || '-'}
              <CustomHr />({user.userDefaultInfoForSettlement.topUserName || '-'})
            </span>
          ) : (
            <span> - </span>
          )}
        </td>
        <td>{user.userDefaultInfoForSettlement.edgeName}</td>
        <td>
          {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER'
            ? getSettlementType(user.userDefaultInfoForSettlement.losingSettlementType)
            : '-'}
        </td>
        <td>
          {user.userDefaultInfoForSettlement.subUsersCount}명/
          {user.userAssetInfoForSettlement.totalDirectAndSubUsersCount -
            1 -
            user.userDefaultInfoForSettlement.subUsersCount >=
          0
            ? user.userAssetInfoForSettlement.totalDirectAndSubUsersCount -
              1 -
              user.userDefaultInfoForSettlement.subUsersCount
            : 0}
          명
        </td>
        <td>
          {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' &&
          user.userSignUpInfoForSettlement.userSignUpCount > 0 ? (
            <TooltipWrapper text={user.userSignUpInfoForSettlement.userSignUpIds} place="bottom">
              <span style={{ textDecoration: 'underline', cursor: 'pointer', color: '#2663a3' }}>
                {user.userSignUpInfoForSettlement.userSignUpCount}명
              </span>
            </TooltipWrapper>
          ) : (
            '-'
          )}
        </td>
        <td>
          {getMoneyAmount(user.userAssetInfoForSettlement.totalMoneyAmount)}
          <CustomHr />
          {getMoneyAmount(user.userAssetInfoForSettlement.totalCasinoMoneyAmount)}
          <CustomHr />
          {getMoneyAmount(user.userAssetInfoForSettlement.totalHoldemAmount)}
          <CustomHr />
          {getMoneyAmount(user.userAssetInfoForSettlement.totalRollingPointAmount)}
          <CustomHr />
          {getMoneyAmount(user.userAssetInfoForSettlement.totalLosingPointAmount)}
        </td>
        <td>{user.userDefaultInfoForSettlement.depositMinusWithdrawalLosingPer}%</td>
        <td>
          {user.userDefaultInfoForSettlement.casinoLiveRollingPer}% /{' '}
          {user.userDefaultInfoForSettlement.casinoLiveBettingMinusWinningLosingPer}%
          <CustomHr />
          {user.userDefaultInfoForSettlement.casinoSlotRollingPer}% /{' '}
          {user.userDefaultInfoForSettlement.casinoSlotBettingMinusWinningLosingPer}%
          <CustomHr />
          {user.userDefaultInfoForSettlement.holdemRollingPer}%
        </td>
        {isActiveMiniGameInfo && (
          <td>
            {user.userDefaultInfoForSettlement.miniGameSingleRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.miniGameSingleBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.miniGameCombinationRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.miniGameCombinationBettingMinusWinningLosingPer}%
          </td>
        )}
        {isActiveSportsInfo && (
          <td>
            {user.userDefaultInfoForSettlement.sportsOneFolderRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.sportsOneFolderBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.sportsTwoFolderRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.sportsTwoFolderBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.sportsThreeFolderRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.sportsThreeFolderBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.sportsFourFolderRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.sportsFourFolderBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.sportsFiveFolderRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.sportsFiveFolderBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.sportsMultiFolderRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.sportsMultiFolderBettingMinusWinningLosingPer}%
          </td>
        )}
        {isActiveVirtualGameInfo && (
          <td>
            {user.userDefaultInfoForSettlement.virtualSoccerGameRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.virtualSoccerGameBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.virtualBaseballGameRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.virtualBaseballGameBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.virtualBasketballGameRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.virtualBasketballGameBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.virtualHorseGameRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.virtualHorseGameBettingMinusWinningLosingPer}%
            <CustomHr />
            {user.userDefaultInfoForSettlement.virtualGreyhoundsGameRollingPer}% /{' '}
            {user.userDefaultInfoForSettlement.virtualGreyhoundsGameBettingMinusWinningLosingPer}%
          </td>
        )}

        {isActiveAssetInfo && (
          <>
            <td>
              {getMoneyAmount(user.userDailyAssetInfoForSettlement.totalDepositAmount)}
              <CustomHr />
              {getMoneyAmount(user.userDailyAssetInfoForSettlement.totalWithdrawalAmount)}
              <CustomHr />
              {getMoneyAmount(
                twoDifferenceValue(
                  user.userDailyAssetInfoForSettlement.totalDepositAmount,
                  user.userDailyAssetInfoForSettlement.totalWithdrawalAmount,
                ),
              )}
              <CustomHr />
              {getMoneyAmount(
                twoDifferenceValue(
                  user.userDailyAssetInfoForSettlement.totalIncMoneyAmount,
                  user.userDailyAssetInfoForSettlement.totalDecMoneyAmount,
                ),
              )}
              <CustomHr />
              {getMoneyAmount(
                twoDifferenceValue(
                  user.userDailyAssetInfoForSettlement.totalIncPointAmount,
                  user.userDailyAssetInfoForSettlement.totalDecPointAmount,
                ),
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {getMoneyAmount(
                    threeSumValue(
                      user.userDailyAssetInfoForSettlement.totalLosingPointAmount,
                      user.userDailyAssetInfoForSettlement.totalLosingDirectSubUserPointAmount,
                      user.userDailyAssetInfoForSettlement.totalLosingSubUserPointAmount,
                    ),
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userDailyAssetInfoForSettlement.totalLosingPointAmount.toString().replace(commonReg2, ',')}/
                      하부:&nbsp;
                      {twoSumValue(
                        user.userDailyAssetInfoForSettlement.totalLosingDirectSubUserPointAmount,
                        user.userDailyAssetInfoForSettlement.totalLosingSubUserPointAmount,
                      )}
                    </>
                  ) : (
                    <>본인: {getMoneyAmount(user.userDailyAssetInfoForSettlement.totalLosingPointAmount)}</>
                  )}
                </>
              )}
            </td>
          </>
        )}
        {isActiveLiveInfo && (
          <>
            <td>
              {twoDifferenceValue(
                user.userCasinoInfoForSettlement.totalLiveBettingAmount,
                user.userCasinoInfoForSettlement.totalLiveDrawAmount,
              )}
              ({user.userCasinoInfoForSettlement.totalLiveDrawAmount.toString().replace(commonReg2, ',')})
              <CustomHr />
              {user.userCasinoInfoForSettlement.totalLiveWinningAmount.toString().replace(commonReg2, ',')} (
              {user.userCasinoInfoForSettlement.totalLiveDrawAmount.toString().replace(commonReg2, ',')})
              <CustomHr />
              {twoDifferenceValue(
                user.userCasinoInfoForSettlement.totalLiveBettingAmount -
                  user.userCasinoInfoForSettlement.totalLiveDrawAmount,
                user.userCasinoInfoForSettlement.totalLiveWinningAmount,
              )}
              <CustomHr />
              {user.userCasinoInfoForSettlement.totalLiveCompAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userCasinoInfoForSettlement.totalLiveRollingAmount,
                    user.userCasinoInfoForSettlement.totalLiveDirectSubUserRollingAmount,
                    user.userCasinoInfoForSettlement.totalLiveSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userCasinoInfoForSettlement.totalLiveRollingAmount.toString().replace(commonReg2, ',')}/
                      하부:&nbsp;
                      {twoSumValue(
                        user.userCasinoInfoForSettlement.totalLiveDirectSubUserRollingAmount,
                        user.userCasinoInfoForSettlement.totalLiveSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userCasinoInfoForSettlement.totalLiveRollingAmount.toString().replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userCasinoInfoForSettlement.totalLiveLosingAmount,
                    user.userCasinoInfoForSettlement.totalLiveDirectSubUserLosingAmount,
                    user.userCasinoInfoForSettlement.totalLiveSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인: {user.userCasinoInfoForSettlement.totalLiveLosingAmount.toString().replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userCasinoInfoForSettlement.totalLiveDirectSubUserLosingAmount,
                        user.userCasinoInfoForSettlement.totalLiveSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인: {user.userCasinoInfoForSettlement.totalLiveLosingAmount.toString().replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
          </>
        )}
        {isActiveSlotInfo && (
          <>
            <td>
              {user.userCasinoInfoForSettlement.totalSlotBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoSumValue(
                user.userCasinoInfoForSettlement.totalSlotWinningAmount,
                user.userCasinoInfoForSettlement.totalSlotDrawAmount,
              )}
              <CustomHr />
              {twoDifferenceValue(
                user.userCasinoInfoForSettlement.totalSlotBettingAmount,
                user.userCasinoInfoForSettlement.totalSlotWinningAmount +
                  user.userCasinoInfoForSettlement.totalSlotDrawAmount,
              )}
              <CustomHr />
              {user.userCasinoInfoForSettlement.totalSlotCompAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userCasinoInfoForSettlement.totalSlotRollingAmount,
                    user.userCasinoInfoForSettlement.totalSlotDirectSubUserRollingAmount,
                    user.userCasinoInfoForSettlement.totalSlotSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userCasinoInfoForSettlement.totalSlotRollingAmount.toString().replace(commonReg2, ',')}/
                      하부:&nbsp;
                      {twoSumValue(
                        user.userCasinoInfoForSettlement.totalSlotDirectSubUserRollingAmount,
                        user.userCasinoInfoForSettlement.totalSlotSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userCasinoInfoForSettlement.totalSlotRollingAmount.toString().replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userCasinoInfoForSettlement.totalSlotLosingAmount,
                    user.userCasinoInfoForSettlement.totalSlotDirectSubUserLosingAmount,
                    user.userCasinoInfoForSettlement.totalSlotSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인: {user.userCasinoInfoForSettlement.totalSlotLosingAmount.toString().replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userCasinoInfoForSettlement.totalSlotDirectSubUserLosingAmount,
                        user.userCasinoInfoForSettlement.totalSlotSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인: {user.userCasinoInfoForSettlement.totalSlotLosingAmount.toString().replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
          </>
        )}
        {isActiveHoldemInfo && (
          <>
            <td>
              {user.userDailyAssetInfoForSettlement.totalHoldemDepositAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userDailyAssetInfoForSettlement.totalHoldemWithdrawalAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userDailyAssetInfoForSettlement.totalHoldemDepositAmount,
                user.userDailyAssetInfoForSettlement.totalHoldemWithdrawalAmount,
              )}
              <CustomHr />
              {getMoneyAmount(
                twoDifferenceValue(
                  user.userDailyAssetInfoForSettlement.totalIncHoldemMoneyAmount,
                  user.userDailyAssetInfoForSettlement.totalDecHoldemMoneyAmount,
                ),
              )}
            </td>
          </>
        )}
        {isActiveHoldemInfo && (
          <>
            <td>
              {user.userHoldemInfoForSettlement.totalHoldemBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userHoldemInfoForSettlement.totalHoldemWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userHoldemInfoForSettlement.totalHoldemBettingAmount,
                user.userHoldemInfoForSettlement.totalHoldemWinningAmount,
              )}
              <CustomHr />
              {user.userHoldemInfoForSettlement.totalHoldemCompAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userHoldemInfoForSettlement.totalHoldemLossAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userHoldemInfoForSettlement.totalHoldemRollingAmount,
                    user.userHoldemInfoForSettlement.totalHoldemDirectSubUserRollingAmount,
                    user.userHoldemInfoForSettlement.totalHoldemSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userHoldemInfoForSettlement.totalHoldemRollingAmount.toString().replace(commonReg2, ',')}/
                      하부:&nbsp;
                      {twoSumValue(
                        user.userHoldemInfoForSettlement.totalHoldemDirectSubUserRollingAmount,
                        user.userHoldemInfoForSettlement.totalHoldemSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userHoldemInfoForSettlement.totalHoldemRollingAmount.toString().replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
          </>
        )}
        {isActiveMiniGameInfo && (
          <>
            <td>
              {user.userMiniGameInfoForSettlement.totalMiniGameSingleBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userMiniGameInfoForSettlement.totalMiniGameSingleWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userMiniGameInfoForSettlement.totalMiniGameSingleBettingAmount,
                user.userMiniGameInfoForSettlement.totalMiniGameSingleWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userMiniGameInfoForSettlement.totalMiniGameSingleRollingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameSingleDirectSubUserRollingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameSingleSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameSingleRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userMiniGameInfoForSettlement.totalMiniGameSingleDirectSubUserRollingAmount,
                        user.userMiniGameInfoForSettlement.totalMiniGameSingleSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameSingleRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userMiniGameInfoForSettlement.totalMiniGameSingleLosingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameSingleDirectSubUserLosingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameSingleSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameSingleLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userMiniGameInfoForSettlement.totalMiniGameSingleDirectSubUserLosingAmount,
                        user.userMiniGameInfoForSettlement.totalMiniGameSingleSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameSingleLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userMiniGameInfoForSettlement.totalMiniGameCombinationBettingAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {user.userMiniGameInfoForSettlement.totalMiniGameCombinationWinningAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userMiniGameInfoForSettlement.totalMiniGameCombinationBettingAmount,
                user.userMiniGameInfoForSettlement.totalMiniGameCombinationWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userMiniGameInfoForSettlement.totalMiniGameCombinationRollingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameCombinationDirectSubUserRollingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameCombinationSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameCombinationRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userMiniGameInfoForSettlement.totalMiniGameCombinationDirectSubUserRollingAmount,
                        user.userMiniGameInfoForSettlement.totalMiniGameCombinationSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameCombinationRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userMiniGameInfoForSettlement.totalMiniGameCombinationLosingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameCombinationDirectSubUserLosingAmount,
                    user.userMiniGameInfoForSettlement.totalMiniGameCombinationSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameCombinationLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userMiniGameInfoForSettlement.totalMiniGameCombinationDirectSubUserLosingAmount,
                        user.userMiniGameInfoForSettlement.totalMiniGameCombinationSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userMiniGameInfoForSettlement.totalMiniGameCombinationLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userMiniGameInfoForSettlement.totalMiniGameCompAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userMiniGameInfoForSettlement.totalMiniGameLossAmount.toString().replace(commonReg2, ',')}
            </td>
          </>
        )}
        {isActiveSportsInfo && (
          <>
            <td>
              {user.userSportsInfoForSettlement.totalSportsOneFolderBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userSportsInfoForSettlement.totalSportsOneFolderWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userSportsInfoForSettlement.totalSportsOneFolderBettingAmount,
                user.userSportsInfoForSettlement.totalSportsOneFolderWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsOneFolderRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsOneFolderDirectSubUserRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsOneFolderSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsOneFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsOneFolderDirectSubUserRollingAmount,
                        user.userSportsInfoForSettlement.totalSportsOneFolderSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsOneFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsOneFolderLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsOneFolderDirectSubUserLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsOneFolderSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsOneFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsOneFolderDirectSubUserLosingAmount,
                        user.userSportsInfoForSettlement.totalSportsOneFolderSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsOneFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userSportsInfoForSettlement.totalSportsTwoFolderBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userSportsInfoForSettlement.totalSportsTwoFolderWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userSportsInfoForSettlement.totalSportsTwoFolderBettingAmount,
                user.userSportsInfoForSettlement.totalSportsTwoFolderWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsTwoFolderRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsTwoFolderDirectSubUserRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsTwoFolderSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsTwoFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsTwoFolderDirectSubUserRollingAmount,
                        user.userSportsInfoForSettlement.totalSportsTwoFolderSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsTwoFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsTwoFolderLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsTwoFolderDirectSubUserLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsTwoFolderSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsTwoFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsTwoFolderDirectSubUserLosingAmount,
                        user.userSportsInfoForSettlement.totalSportsTwoFolderSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsTwoFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userSportsInfoForSettlement.totalSportsThreeFolderBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userSportsInfoForSettlement.totalSportsThreeFolderWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userSportsInfoForSettlement.totalSportsThreeFolderBettingAmount,
                user.userSportsInfoForSettlement.totalSportsThreeFolderWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsThreeFolderRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsThreeFolderDirectSubUserRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsThreeFolderSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsThreeFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsThreeFolderDirectSubUserRollingAmount,
                        user.userSportsInfoForSettlement.totalSportsThreeFolderSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsThreeFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsThreeFolderLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsThreeFolderDirectSubUserLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsThreeFolderSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsThreeFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsThreeFolderDirectSubUserLosingAmount,
                        user.userSportsInfoForSettlement.totalSportsThreeFolderSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsThreeFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>

            <td>
              {user.userSportsInfoForSettlement.totalSportsFourFolderBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userSportsInfoForSettlement.totalSportsFourFolderWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userSportsInfoForSettlement.totalSportsFourFolderBettingAmount,
                user.userSportsInfoForSettlement.totalSportsFourFolderWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsFourFolderRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsFourFolderDirectSubUserRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsFourFolderSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFourFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsFourFolderDirectSubUserRollingAmount,
                        user.userSportsInfoForSettlement.totalSportsFourFolderSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFourFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsFourFolderLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsFourFolderDirectSubUserLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsFourFolderSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFourFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsFourFolderDirectSubUserLosingAmount,
                        user.userSportsInfoForSettlement.totalSportsFourFolderSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFourFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userSportsInfoForSettlement.totalSportsFiveFolderBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userSportsInfoForSettlement.totalSportsFiveFolderWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userSportsInfoForSettlement.totalSportsFiveFolderBettingAmount,
                user.userSportsInfoForSettlement.totalSportsFiveFolderWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsFiveFolderRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsFiveFolderDirectSubUserRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsFiveFolderSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFiveFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsFiveFolderDirectSubUserRollingAmount,
                        user.userSportsInfoForSettlement.totalSportsFiveFolderSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFiveFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsFiveFolderLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsFiveFolderDirectSubUserLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsFiveFolderSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFiveFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsFiveFolderDirectSubUserLosingAmount,
                        user.userSportsInfoForSettlement.totalSportsFiveFolderSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsFiveFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userSportsInfoForSettlement.totalSportsMultiFolderBettingAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userSportsInfoForSettlement.totalSportsMultiFolderWinningAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userSportsInfoForSettlement.totalSportsMultiFolderBettingAmount,
                user.userSportsInfoForSettlement.totalSportsMultiFolderWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsMultiFolderRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsMultiFolderDirectSubUserRollingAmount,
                    user.userSportsInfoForSettlement.totalSportsMultiFolderSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsMultiFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsMultiFolderDirectSubUserRollingAmount,
                        user.userSportsInfoForSettlement.totalSportsMultiFolderSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsMultiFolderRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userSportsInfoForSettlement.totalSportsMultiFolderLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsMultiFolderDirectSubUserLosingAmount,
                    user.userSportsInfoForSettlement.totalSportsMultiFolderSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsMultiFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userSportsInfoForSettlement.totalSportsMultiFolderDirectSubUserLosingAmount,
                        user.userSportsInfoForSettlement.totalSportsMultiFolderSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userSportsInfoForSettlement.totalSportsMultiFolderLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userSportsInfoForSettlement.totalSportsCompAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userSportsInfoForSettlement.totalSportsLossAmount.toString().replace(commonReg2, ',')}
            </td>
          </>
        )}
        {isActiveVirtualGameInfo && (
          <>
            <td>
              {user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameBettingAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameWinningAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameBettingAmount,
                user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameDirectSubUserRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameDirectSubUserRollingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameDirectSubUserLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameDirectSubUserLosingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualSoccerGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameBettingAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameWinningAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameBettingAmount,
                user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameDirectSubUserRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameDirectSubUserRollingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameDirectSubUserLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameDirectSubUserLosingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBaseballGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameBettingAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameWinningAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameBettingAmount,
                user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameDirectSubUserRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameDirectSubUserRollingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameDirectSubUserLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameDirectSubUserLosingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualBasketballGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>

            <td>
              {user.userVirtualGameInfoForSettlement.totalVirtualHorseGameBettingAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {user.userVirtualGameInfoForSettlement.totalVirtualHorseGameWinningAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userVirtualGameInfoForSettlement.totalVirtualHorseGameBettingAmount,
                user.userVirtualGameInfoForSettlement.totalVirtualHorseGameWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualHorseGameRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualHorseGameDirectSubUserRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualHorseGameSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualHorseGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualHorseGameDirectSubUserRollingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualHorseGameSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualHorseGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualHorseGameLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualHorseGameDirectSubUserLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualHorseGameSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualHorseGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualHorseGameDirectSubUserLosingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualHorseGameSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualHorseGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameBettingAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameWinningAmount
                .toString()
                .replace(commonReg2, ',')}
              <CustomHr />
              {twoDifferenceValue(
                user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameBettingAmount,
                user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameWinningAmount,
              )}
              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameDirectSubUserRollingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameSubUserRollingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameDirectSubUserRollingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameSubUserRollingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameRollingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}

              <CustomHr />
              {user.subUsersCount > 0 ? (
                <>
                  {threeSumValue(
                    user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameDirectSubUserLosingAmount,
                    user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameSubUserLosingAmount,
                  )}
                </>
              ) : (
                <>
                  {user.userDefaultInfoForSettlement.userAuthority === 'PARTNER' ? (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                      / 하부:&nbsp;
                      {twoSumValue(
                        user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameDirectSubUserLosingAmount,
                        user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameSubUserLosingAmount,
                      )}
                    </>
                  ) : (
                    <>
                      본인:{' '}
                      {user.userVirtualGameInfoForSettlement.totalVirtualGreyhoundsGameLosingAmount
                        .toString()
                        .replace(commonReg2, ',')}
                    </>
                  )}
                </>
              )}
            </td>
            <td>
              {user.userVirtualGameInfoForSettlement.totalVirtualGameCompAmount.toString().replace(commonReg2, ',')}
              <CustomHr />
              {user.userVirtualGameInfoForSettlement.totalVirtualGameLossAmount.toString().replace(commonReg2, ',')}
            </td>
          </>
        )}
      </CustomTr>
      {user.isOpened && user.subUsersCount > 0 ? (
        <>
          {user.subUserInfos?.map((subUser, idx) => (
            <DisplayUserComponent
              key={subUser.userKey}
              scrollUserKey={scrollUserKey}
              user={subUser}
              depth={depth + 1}
              toggleIsOpened={toggleIsOpened}
              isActiveLiveInfo={isActiveLiveInfo}
              isActiveSlotInfo={isActiveSlotInfo}
              isActiveHoldemInfo={isActiveHoldemInfo}
              isActiveMiniGameInfo={isActiveMiniGameInfo}
              isActiveSportsInfo={isActiveSportsInfo}
              isActiveVirtualGameInfo={isActiveVirtualGameInfo}
              setModalShow={setModalShow}
              setModalUserId={setModalUserId}
              setWhatModalShow={setWhatModalShow}
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

DisplayUserComponent.propTypes = {
  user: PropTypes.shape(),
  scrollUserKey: PropTypes.string,
  depth: PropTypes.number,
  toggleIsOpened: PropTypes.func,
  isActiveAssetInfo: PropTypes.bool,
  isActiveLiveInfo: PropTypes.bool,
  isActiveSlotInfo: PropTypes.bool,
  isActiveHoldemInfo: PropTypes.bool,
  isActiveMiniGameInfo: PropTypes.bool,
  isActiveSportsInfo: PropTypes.bool,
  isActiveVirtualGameInfo: PropTypes.bool,
  setModalShow: PropTypes.func,
  setModalUserId: PropTypes.func,
  setWhatModalShow: PropTypes.func,
}

DisplayUserComponent.defaultProps = {
  user: {},
  scrollUserKey: '',
  depth: 0,
  toggleIsOpened: () => {},
  setModalShow: () => {},
  setModalUserId: () => {},
  setWhatModalShow: () => {},
  isActiveAssetInfo: true,
  isActiveLiveInfo: true,
  isActiveSlotInfo: false,
  isActiveHoldemInfo: false,
  isActiveMiniGameInfo: false,
  isActiveSportsInfo: false,
  isActiveVirtualGameInfo: false,
}

const SettlementList = () => {
  const dispatch = useDispatch()
  const { edgeNames, userSettlementList, apiLoading, hideSideBar, scrollUserKey } = useSelector(state => {
    const { headerInfo, settlementInfo } = state

    return {
      edgeNames: headerInfo.edgeNames,
      hideSideBar: headerInfo.hideSideBar,
      userSettlementList: settlementInfo.userSettlementList,
      apiLoading: settlementInfo.apiLoading,
      scrollUserKey: settlementInfo.scrollUserKey,
    }
  })

  const [selectedEdgeName, setSelectedEdgeName] = useState()
  useEffect(() => {
    if (selectedEdgeName) return
    setSelectedEdgeName(edgeNames.length > 0 ? edgeNames[0] : '')
  }, [edgeNames, selectedEdgeName])

  const [userAuthority, setUserAuthority] = useState(null)

  /**
   * 파트너 조회
   */
  const today = new Date()
  const endDate = new Date()
  const startDate = new Date()

  // endDate 설정
  endDate.setDate(today.getDate() + 1)
  endDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정

  // startDate 설정
  startDate.setMonth(endDate.getMonth() - 1)
  startDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정

  const [params, setParams] = useState({
    edgeName: null,
    userId: null,
    topUserKey: null,
    userAuthority: null,
    settlementSelect: null,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
  })

  const [settlementSelect, setSettlementSelect] = useState(null)

  // 미니게임 종목 선택
  const onClickSettlementSelectTypeHandler = type => {
    setSettlementSelect(type)
  }

  useEffect(() => {
    dispatch(resetSettlementDataV2())
    dispatch(
      fetchSearchPartnerSettlementStatisticsV2({
        edgeName: selectedEdgeName,
        userId: null,
        topUserKey: null,
        userAuthority,
        startDate: convertToKst(startDate),
        endDate: convertToKst(endDate),
        settlementSelect,
      }),
    )
  }, [userAuthority])

  useEffect(() => {
    dispatch(resetSettlementDataV2())
    dispatch(
      fetchSearchPartnerSettlementStatisticsV2({
        edgeName: selectedEdgeName,
        userId: null,
        topUserKey: null,
        userAuthority,
        startDate: convertToKst(startDate),
        endDate: convertToKst(endDate),
        settlementSelect,
      }),
    )
  }, [settlementSelect])

  // +버튼 눌렀을 때
  const handleToggleIsOpened = (topUserKey, isOpened) => {
    dispatch(handleIsClosed(topUserKey))
    if (!isOpened) {
      setParams(prev => ({
        ...prev,
        userId: null,
        userAuthority,
        settlementSelect,
        topUserKey,
      }))
    }
  }

  // useEffect(() => {
  //   if (!selectedUserId) return
  //   setParams(prev => ({
  //     ...prev,
  //     topUserId: selectedUserId,
  //   }))
  // }, [selectedUserId])

  useEffect(() => {
    if (!params.edgeName) return
    dispatch(fetchSearchPartnerSettlementStatisticsV2(params))
  }, [params])

  // 아이디, 권한, 상태, 등록일
  // 아이디
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate)
  const [searchEndDate, setSearchEndDate] = useState(endDate)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  useEffect(() => {
    if (!selectedEdgeName) return
    dispatch(resetSettlementDataV2())
    setParams(prev => ({
      topUserKey: null,
      userId: null,
      userAuthority,
      settlementSelect,
      edgeName: selectedEdgeName,
      startDate: convertToKst(startDate),
      endDate: convertToKst(endDate),
    }))
  }, [selectedEdgeName])

  useEffect(() => {
    dispatch(resetSettlementDataV2())
  }, [])

  const adjustTimes = date => {
    if (!date) return
    const adjustedDate = new Date(date)
    adjustedDate.setHours(0, 0, 0) // 초만 59로 조정
    return adjustedDate
  }
  // 검색하기 버튼
  const onSearchClickHandler = () => {
    if (searchStartDate === null || searchEndDate === null) {
      alert('시작일과 종료일 설정은 필수입니다.')
      return
    }
    dispatch(resetSettlementDataV2())
    const adjustedStartDate = adjustTimes(searchStartDate)
    const adjustedEndDate = adjustTimes(searchEndDate)
    if (searchUserId) {
      dispatch(handleSearchUserId(searchUserId))
    }
    setParams(prev => ({
      ...prev,
      settlementSelect,
      topUserKey: null,
      userId: !searchUserId ? null : searchUserId.trim(),
      startDate: !searchStartDate ? null : convertToKst(adjustedStartDate),
      endDate: !searchEndDate ? null : convertToKst(adjustedEndDate),
    }))
  }

  // 입출 베팅통계
  const [isActiveAssetInfo, setIsActiveAssetInfo] = useState(true)
  // 라이브 베팅통계
  const [isActiveLiveInfo, setIsActiveLiveInfo] = useState(true)
  // 슬롯 베팅통계
  const [isActiveSlotInfo, setIsActiveSlotInfo] = useState(true)
  // 홀덤 베팅통계
  const [isActiveHoldemInfo, setIsActiveHoldemInfo] = useState(false)
  // 미니게임 베팅통계
  const [isActiveMiniGameInfo, setIsActiveMiniGameInfo] = useState(false)
  // 스포츠 베팅통계
  const [isActiveSportsInfo, setIsActiveSportsInfo] = useState(false)
  // 스포츠 베팅통계
  const [isActiveVirtualGameInfo, setIsActiveVirtualGameInfo] = useState(false)

  const [isModalUserId, setModalUserId] = useState('')
  const [isModalShow, setModalShow] = useState(false)
  const onModalClickHandler = () => {
    setModalShow(prev => !prev)
  }

  const [whatModalShow, setWhatModalShow] = useState(false)
  const onWhatModalClickHandler = what => {
    setWhatModalShow(what)
  }

  const getColSpan = () => {
    const activeCount = Number(isActiveMiniGameInfo) + Number(isActiveSportsInfo) + Number(isActiveVirtualGameInfo)

    switch (activeCount) {
      case 1:
        return 3 // 1개의 항목이 true이면 colspan을 3로 설정
      case 2:
        return 4 // 2개의 항목이 true이면 colspan을 4로 설정
      case 3:
        return 5 // 3개의 항목이 true이면 colspan을 5로 설정
      default:
        return 2 // 기본값은 colspan을 2로 설정
    }
  }

  useEffect(() => {
    getColSpan()
  }, [isActiveMiniGameInfo, isActiveSportsInfo, isActiveVirtualGameInfo])

  return (
    <>
      <Card>
        <CardBody style={{ paddingBottom: '0px' }}>
          <StatisticsTabs activeKey="2" />
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-50px', marginBottom: '10px' }}>
              <p>
                * <span style={{ color: 'red' }}>1LV</span>의 파트너 기준으로 먼저 보이게 되며,
                <span style={{ color: 'blue', fontSize: '14px' }}> &nbsp; +</span> 버튼을 클릭 시 본인 및 하위 유저를
                확인 하실 수 있습니다.
              </p>
              <p>
                * 공배팅은 통계에서 <span style={{ color: 'red' }}>제외되며</span>, 라이브 같은 경우, 타이는&nbsp;
                <span style={{ color: 'red' }}>롤링/콤프/루징액</span>에 반영되지 않습니다.
              </p>
              <p>
                * 파트너 본인 행의 롤링/루징액은 <span style={{ color: 'red' }}>본인 / 하부</span>로 나뉘어져 있으며,
                본인으로부터 발생한 정산금과 하부로부터 발생한 정산금을 나눠서 확인할 수 있습니다.
              </p>
            </div>
          </SearchCardTitleWrap>
          {/* 검색 기능 */}
          <SearchContainer horizontal>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <EdgeSearchButton
                  isAll={false}
                  edgeName={selectedEdgeName}
                  onClickHandler={e => {
                    setSelectedEdgeName(e.target.id)
                  }}
                />
                <AuthoritySearchButtonV2
                  userAuthority={userAuthority}
                  onClickHandler={e => {
                    setUserAuthority(e.target.id)
                  }}
                />
                <MiniGameTypeSelectWrapper>
                  <Button
                    type="button"
                    size="sm"
                    variant="outline-secondary"
                    active={!settlementSelect}
                    onClick={() => {
                      onClickSettlementSelectTypeHandler(null)
                    }}
                  >
                    전체
                  </Button>
                  {Object.entries(SettlementSelectEnums).map(([key, value]) => {
                    return (
                      <Button
                        type="button"
                        size="sm"
                        variant="outline-secondary"
                        active={key === settlementSelect}
                        onClick={() => {
                          onClickSettlementSelectTypeHandler(key)
                        }}
                      >
                        {value}
                      </Button>
                    )
                  })}
                </MiniGameTypeSelectWrapper>
              </div>
              <div style={{ display: 'flex', marginTop: '-5px' }}>
                <TableColumnSearchButtonBox>
                  <Button
                    size="sm"
                    active={isActiveAssetInfo}
                    variant="outline-secondary"
                    onClick={e => {
                      setIsActiveAssetInfo(prev => !prev)
                    }}
                  >
                    입출 정산통계
                  </Button>
                  <Button
                    size="sm"
                    active={isActiveLiveInfo}
                    variant="outline-secondary"
                    onClick={e => {
                      setIsActiveLiveInfo(prev => !prev)
                    }}
                  >
                    라이브 정산통계
                  </Button>
                  <Button
                    id="MEMBER"
                    size="sm"
                    active={isActiveSlotInfo}
                    variant="outline-secondary"
                    onClick={e => {
                      setIsActiveSlotInfo(prev => !prev)
                    }}
                  >
                    슬롯 정산통계
                  </Button>
                  <Button
                    id="MEMBER"
                    size="sm"
                    active={isActiveHoldemInfo}
                    variant="outline-secondary"
                    onClick={e => {
                      setIsActiveHoldemInfo(prev => !prev)
                    }}
                  >
                    홀덤 정산통계
                  </Button>
                  <Button
                    id="PARTNER"
                    size="sm"
                    active={isActiveMiniGameInfo}
                    variant="outline-secondary"
                    onClick={e => {
                      setIsActiveMiniGameInfo(prev => !prev)
                    }}
                  >
                    미니 정산통계
                  </Button>
                  <Button
                    id="PARTNER"
                    size="sm"
                    active={isActiveSportsInfo}
                    variant="outline-secondary"
                    onClick={e => {
                      setIsActiveSportsInfo(prev => !prev)
                    }}
                  >
                    스포츠 정산통계
                  </Button>
                  <Button
                    id="PARTNER"
                    size="sm"
                    active={isActiveVirtualGameInfo}
                    variant="outline-secondary"
                    onClick={e => {
                      setIsActiveVirtualGameInfo(prev => !prev)
                    }}
                  >
                    가상 정산통계
                  </Button>
                </TableColumnSearchButtonBox>
                <SearchGroup style={{ marginLeft: '10px' }}>
                  <SearchGroupLabel>유저 ID/명</SearchGroupLabel>
                  <SearchGroupField>
                    <SearchInputWrap>
                      <input
                        name="searchUserId"
                        type="text"
                        placeholder="입력해주세요"
                        value={searchUserId}
                        onChange={e => onSearchUserIdHandler(e)}
                      />
                      <input style={{ display: 'none' }} type="text" />
                    </SearchInputWrap>
                  </SearchGroupField>
                </SearchGroup>
                <SearchGroup>
                  <SearchGroupLabel>시작일</SearchGroupLabel>
                  <SearchGroupField>
                    <SearchDatePickerWrap style={{ zIndex: '103' }}>
                      <DatePicker
                        selected={searchStartDate}
                        onChange={date => onSearchStartDateChangeHandler(date)}
                        dateFormat="yyyy-MM-dd HH:mm:00"
                        dropDownMode="select"
                        popperPlacement="bottom-start"
                        placeholderText="시작일"
                        locale={ko}
                        isClearable
                      />
                    </SearchDatePickerWrap>
                  </SearchGroupField>
                </SearchGroup>
                <SearchGroup>
                  <SearchGroupLabel>종료일</SearchGroupLabel>
                  <SearchGroupField>
                    <SearchDatePickerWrap style={{ zIndex: '103' }}>
                      <DatePicker
                        selected={searchEndDate}
                        onChange={date => onSearchEndDateChangeHandler(date)}
                        dateFormat="yyyy-MM-dd HH:mm:00"
                        dropDownMode="select"
                        popperPlacement="bottom-start"
                        placeholderText="종료일"
                        locale={ko}
                        isClearable
                      />
                    </SearchDatePickerWrap>
                  </SearchGroupField>
                </SearchGroup>
                <SearchButtonWrap>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => {
                      onSearchClickHandler()
                    }}
                  >
                    검색하기
                  </Button>
                </SearchButtonWrap>
              </div>
            </div>
          </SearchContainer>
          {apiLoading ? (
            <Loading />
          ) : (
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th rowSpan={2} style={{ borderLeft: 'solid 1px black' }} scope="col">
                      유저ID(명)
                    </th>
                    <th scope="col" rowSpan={2}>
                      상위파트너ID(명)
                    </th>
                    <th scope="col" rowSpan={2}>
                      사이트명
                    </th>
                    <th scope="col" rowSpan={2}>
                      정산방식
                    </th>
                    <th scope="col" rowSpan={2}>
                      직영/하부(명)
                    </th>
                    <th scope="col" rowSpan={2}>
                      가입자수(명)
                    </th>
                    <th scope="col" style={{ borderBottom: 'none' }}>
                      현재 자산
                    </th>
                    <th scope="col" colSpan={getColSpan()} style={{ borderBottom: 'none' }}>
                      정산%(롤링/루징)
                    </th>
                    {isActiveAssetInfo && (
                      <CustomTh scope="col" rowSpan={2} colorText="isActiveMoneyInfo">
                        총 입금액
                        <CustomHr />
                        총 출금액
                        <CustomHr />
                        총 입금-출금
                        <CustomHr />
                        머니 증감액
                        <CustomHr />
                        포인트 증감액
                        <CustomHr />
                        루징[지급]
                      </CustomTh>
                    )}
                    {isActiveLiveInfo && (
                      <CustomTh scope="col" rowSpan={2} colorText="isActiveLiveInfo">
                        라이브 베팅(타이)
                        <CustomHr />
                        라이브 당첨(타이)
                        <CustomHr />
                        라이브 베팅-당첨
                        <CustomHr />
                        콤프[지급]
                        <CustomHr />
                        롤링[지급]
                        <CustomHr />
                        루징[지급]
                      </CustomTh>
                    )}
                    {isActiveSlotInfo && (
                      <CustomTh scope="col" rowSpan={2} colorText="isActiveSlotInfo">
                        슬롯 베팅
                        <CustomHr />
                        슬롯 당첨
                        <CustomHr />
                        슬롯 베팅-당첨
                        <CustomHr />
                        콤프[지급]
                        <CustomHr />
                        롤링[지급]
                        <CustomHr />
                        루징[지급]
                      </CustomTh>
                    )}

                    {isActiveHoldemInfo && (
                      <CustomTh scope="col" rowSpan={2} colorText="isActiveHoldemInfo">
                        홀덤 입금
                        <CustomHr />
                        홀덤 출금
                        <CustomHr />
                        총 입금-출금
                        <CustomHr />
                        홀덤 증감액
                      </CustomTh>
                    )}

                    {isActiveHoldemInfo && (
                      <CustomTh scope="col" rowSpan={2} colorText="isActiveHoldemInfo">
                        홀덤 베팅
                        <CustomHr />
                        홀덤 당첨
                        <CustomHr />
                        홀덤 베팅-당첨
                        <CustomHr />
                        콤프[지급]
                        <CustomHr />
                        낙첨[지급]
                        <CustomHr />
                        롤링[지급]
                      </CustomTh>
                    )}

                    {isActiveMiniGameInfo && (
                      <CustomTh
                        scope="col"
                        colSpan={3}
                        style={{ borderBottom: 'none' }}
                        colorText="isActiveMiniGameInfo"
                      >
                        미니게임
                      </CustomTh>
                    )}

                    {isActiveSportsInfo && (
                      <CustomTh scope="col" colSpan={7} style={{ borderBottom: 'none' }} colorText="isActiveSportsInfo">
                        스포츠
                      </CustomTh>
                    )}

                    {isActiveVirtualGameInfo && (
                      <CustomTh
                        scope="col"
                        colSpan={6}
                        style={{ borderBottom: 'none' }}
                        colorText="isActiveVirtualGameInfo"
                      >
                        가상게임
                      </CustomTh>
                    )}

                    {/* {isActiveDefaultInfo && (
                      <th style={{ borderBottom: 'none' }} scope="col" colSpan={6}>
                        현재 자산
                      </th>
                    )} */}

                    {/* {isActiveRollingInfo && (
                      <RollingTh style={{ borderBottom: 'none' }} scope="col" colSpan={12}>
                        롤링 요율(%)
                      </RollingTh>
                    )}

                    {isActiveLosingInfo && (
                      <LosingTh style={{ borderBottom: 'none' }} scope="col" colSpan={13}>
                        루징 요율(%)
                      </LosingTh>
                    )} */}
                  </tr>
                  <tr>
                    <th scope="col">
                      지갑머니
                      <CustomHr style={{ background: '#fff' }} />
                      카지노머니
                      <CustomHr style={{ background: '#fff' }} />
                      홀덤머니
                      <CustomHr style={{ background: '#fff' }} />
                      롤링액
                      <CustomHr style={{ background: '#fff' }} />
                      루징액
                    </th>
                    <th scope="col">입금-출금</th>
                    <th scope="col">
                      라이브
                      <CustomHr style={{ background: '#fff' }} />
                      슬롯
                      <CustomHr style={{ background: '#fff' }} />
                      홀덤(롤링)
                    </th>
                    {isActiveMiniGameInfo && (
                      <th scope="col">
                        미니(일반)
                        <CustomHr style={{ background: '#fff' }} />
                        미니(조합)
                      </th>
                    )}
                    {isActiveSportsInfo && (
                      <th scope="col">
                        스포츠(단폴)
                        <CustomHr style={{ background: '#fff' }} />
                        스포츠(2폴)
                        <CustomHr style={{ background: '#fff' }} />
                        스포츠(3폴)
                        <CustomHr style={{ background: '#fff' }} />
                        스포츠(4폴)
                        <CustomHr style={{ background: '#fff' }} />
                        스포츠(5폴)
                        <CustomHr style={{ background: '#fff' }} />
                        스포츠(다폴)
                      </th>
                    )}

                    {isActiveVirtualGameInfo && (
                      <th scope="col">
                        가상축구
                        <CustomHr style={{ background: '#fff' }} />
                        가상야구
                        <CustomHr style={{ background: '#fff' }} />
                        가상농구
                        <CustomHr style={{ background: '#fff' }} />
                        가상경마
                        <CustomHr style={{ background: '#fff' }} />
                        개경주
                      </th>
                    )}

                    {isActiveMiniGameInfo && (
                      <>
                        <CustomTh scope="col" colorText="isActiveMiniGameInfo">
                          일반 베팅
                          <CustomHr />
                          일반 당첨
                          <CustomHr />
                          일반 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveMiniGameInfo">
                          조합 베팅
                          <CustomHr />
                          조합 당첨
                          <CustomHr />
                          조합 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveMiniGameInfo">
                          콤프[지급]
                          <CustomHr />
                          낙첨[지급]
                        </CustomTh>
                      </>
                    )}
                    {isActiveSportsInfo && (
                      <>
                        <CustomTh scope="col" colorText="isActiveSportsInfo">
                          단폴 베팅
                          <CustomHr />
                          단폴 당첨
                          <CustomHr />
                          단폴 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveSportsInfo">
                          2폴 베팅
                          <CustomHr />
                          2폴 당첨
                          <CustomHr />
                          2폴 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveSportsInfo">
                          3폴 베팅
                          <CustomHr />
                          3폴 당첨
                          <CustomHr />
                          3폴 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveSportsInfo">
                          4폴 베팅
                          <CustomHr />
                          4폴 당첨
                          <CustomHr />
                          4폴 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveSportsInfo">
                          5폴 베팅
                          <CustomHr />
                          5폴 당첨
                          <CustomHr />
                          5폴 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveSportsInfo">
                          다폴 베팅
                          <CustomHr />
                          다폴 당첨
                          <CustomHr />
                          다폴 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveSportsInfo">
                          콤프[지급]
                          <CustomHr />
                          낙첨[지급]
                        </CustomTh>
                      </>
                    )}
                    {isActiveVirtualGameInfo && (
                      <>
                        <CustomTh scope="col" colorText="isActiveVirtualGameInfo">
                          축구 베팅
                          <CustomHr />
                          축구 당첨
                          <CustomHr />
                          축구 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveVirtualGameInfo">
                          야구 베팅
                          <CustomHr />
                          야구 당첨
                          <CustomHr />
                          야구 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveVirtualGameInfo">
                          농구 베팅
                          <CustomHr />
                          농구 당첨
                          <CustomHr />
                          농구 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveVirtualGameInfo">
                          경마 베팅
                          <CustomHr />
                          경마 당첨
                          <CustomHr />
                          경마 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveVirtualGameInfo">
                          개경주 베팅
                          <CustomHr />
                          개경주 당첨
                          <CustomHr />
                          개경주 베팅-당첨
                          <CustomHr />
                          롤링[지급]
                          <CustomHr />
                          루징[지급]
                        </CustomTh>
                        <CustomTh scope="col" colorText="isActiveVirtualGameInfo">
                          콤프[지급]
                          <CustomHr />
                          낙첨[지급]
                        </CustomTh>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {userSettlementList?.map((user, index) => (
                    <DisplayUserComponent
                      key={user.userKey}
                      scrollUserKey={scrollUserKey}
                      user={user}
                      toggleIsOpened={handleToggleIsOpened}
                      isActiveAssetInfo={isActiveAssetInfo}
                      isActiveLiveInfo={isActiveLiveInfo}
                      isActiveSlotInfo={isActiveSlotInfo}
                      isActiveHoldemInfo={isActiveHoldemInfo}
                      isActiveMiniGameInfo={isActiveMiniGameInfo}
                      isActiveSportsInfo={isActiveSportsInfo}
                      isActiveVirtualGameInfo={isActiveVirtualGameInfo}
                      setModalShow={setModalShow}
                      setModalUserId={setModalUserId}
                      setWhatModalShow={setWhatModalShow}
                    />
                  ))}
                </tbody>
              </table>

              {userSettlementList.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
            </TableWrap>
          )}
        </CardBody>
        {/* <CustomModal show={isModalShow} onHide={onModalClickHandler} $header>
          <CustomModalHeader>
            <CustomModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={onModalClickHandler}
            />
            {
              {
                INFO: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저정보</CustomModalTitle>
                  </>
                ),
                ASSET: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 자산 증감</CustomModalTitle>
                  </>
                ),
                ROLLING: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 롤링정보 수정</CustomModalTitle>
                  </>
                ),
                LOSING: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 루징정보 수정</CustomModalTitle>
                  </>
                ),
              }[whatModalShow]
            }
          </CustomModalHeader>
          <CustomModalBody>
            {
              {
                ASSET: (
                  <>
                    <MemberHistoryMoneyIncDec memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
                ROLLING: (
                  <>
                    <MemberRollingInfoPage memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
                LOSING: (
                  <>
                    <MemberLosingInfoPage memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
              }[whatModalShow]
            }
          </CustomModalBody>
        </CustomModal> */}
      </Card>
    </>
  )
}

export default SettlementList

const getColor = colorText => {
  switch (true) {
    case colorText === 'isActiveLiveInfo':
      return '#246e1f'
    case colorText === 'isActiveSlotInfo':
      return '#b58343'
    case colorText === 'isActiveHoldemInfo':
      return '#2b3c8f'
    case colorText === 'isActiveSportsInfo':
      return 'rgba(101, 17, 158,1)'
    case colorText === 'isActiveMiniGameInfo':
      return 'rgba(128, 130, 18,1)'
    case colorText === 'isActiveMoneyInfo':
      return '#000000'
    case colorText === 'isActiveVirtualGameInfo':
      return '#ff600a'
    default:
      return 'lightgrey'
  }
}

const CustomSpan = styled.span`
  color: #2663a3;
  text-decoration: ${props => (props.isBlock === 'BLOCK' ? 'line-through' : 'underline')};
  cursor: pointer;
  padding: 5px 2px;
  font-weight: 500;
  font-size: 15px;
`

const CustomTh = styled.th`
  background: ${props => getColor(props.colorText)} !important;
  color: ${props => (props.textColor === 'profit' ? 'yellow' : '#fff')} !important;
  font-weight: 500 !important;
`

const CustomHr = styled.div`
  margin-top: 0.1em;
  margin-bottom: 0.1em;
  height: 2px;
  background: lightgrey;
`

const CustomModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 1250px !important;
  }
`

const CustomButton = styled.button`
  cursor: pointer;
  border: none;
  color: #fff;
`

const CustomTr = styled.tr`
  &:hover {
    background: ${props => (props.depth === 0 ? '#fff2d3' : '#ebebeb')} !important;
  }
`

const RollingTh = styled.th`
  background: #fcb65b !important;
`

const LosingTh = styled.th`
  background: #aab6f5 !important;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  height: 680px;
  ${scrollbarStyles};
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }
  thead {
    border-collapse: collapse !important;
    position: sticky;
    z-index: 102;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  tbody {
    padding-top: 10px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
    }
  }
`

const PlusSquare = styled(AiOutlinePlusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  cursor: pointer;
  margin: 0 2px;
`

const MinusSquare = styled(AiOutlineMinusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  cursor: pointer;
  margin: 0 2px;
`
const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`

const TableColumnSearchButtonBox = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  button {
    height: 30px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding-left: 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`

const NoDataBox = styled.div`
  width: 100%;
  min-height: 150px;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`

const MiniGameTypeSelectWrapper = styled.div`
  padding: 3px 0;
  margin-left: 15px;
  display: flex;
  gap: 5px;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding-left: 10px;
    color: #646777;
    background: #fafbfe;

    // &:focus {
    //   background: grey;
    //   color: #fff;
    //   border-color: #4ce1b6;
    //   outline: none;
    // }
    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
