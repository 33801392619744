import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  searchDetailSportsTeamForLeague,
  searchSportsTeamForLeague,
  updateSportTeamName,
} from '../../../utils/api/sportsManage/sportsInfo'
import SportManagementTabs from '../SportManagementTabs'
import LeagueTeamManagementTabs from './LeagueTeamManagementTabs'
import TeamManagementTabs from './TeamManagementTabs'

const TeamManagementList = () => {
  const [league, setLeague] = useState([])
  const location = useLocation()
  const [sportsType, setSportsType] = useState(new URLSearchParams(location.search).get('type'))

  const [params, setParams] = useState({
    sportsType,
    sportsTeamName: null,
  })

  useEffect(() => {
    const type = new URLSearchParams(location.search).get('type')
    setSportsType(type)

    setParams(prev => ({
      ...prev,
      sportsType: type,
    }))
  }, [location])

  const [leagueNum, setLeagueNum] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)

  // 경기 목록 조회
  const fetchSearchSportsTeam = async () => {
    await searchSportsTeamForLeague(params).then(res => {
      setLeague(res.data.leagueContent)
      const leagueContents = res.data.leagueContent
      if (leagueContents.length > 0) {
        const firstLeagueNum = leagueContents[0].leagueNum
        setLeagueNum(firstLeagueNum)
        setSelectedRow(firstLeagueNum)
      }
    })
  }

  useEffect(() => {
    fetchSearchSportsTeam()
  }, [sportsType])

  // 팀명
  const [searchTeamName, setSearchTeamName] = useState('')

  const onSearchTeamNameHandler = e => {
    setSearchTeamName(e.target.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      sportsTeamName: !searchTeamName ? null : searchTeamName.trim(),
    }))
  }

  const onSearchTeamHandler = selectNum => {
    setSelectedRow(selectNum)
    setLeagueNum(selectNum)
  }

  const [newName, setNewName] = useState(null)
  const [loading, setLoading] = useState(false)
  const onChangeTeamHandler = (teamName, teamNum) => {
    if (loading) return
    if (newName) {
      if (window.confirm(`'${teamName}' 팀명을 '${newName}'으로 변경 하시겠습니까?`)) {
        const body = {
          newTeamName: newName,
          teamNum,
        }
        setLoading(true)
        updateSportTeamName(body)
          .then(res => {
            alert('변경이 완료됐습니다.')
            setLoading(false)
            window.location.reload()
          })
          .catch(error => {
            alert('변경이 실패하였습니다.')
            setLoading(false)
            window.location.reload()
          })
      }
    }
  }

  const [detaillTeamList, setDetailTeamList] = useState([])
  // 팀 목록 조회
  const fetchSearchDatailSportsTeam = async () => {
    await searchDetailSportsTeamForLeague({ leagueNum }).then(res => {
      setDetailTeamList(res.data.selectTeamContent)
    })
  }
  useEffect(() => {
    if (leagueNum) {
      fetchSearchDatailSportsTeam()
    }
  }, [leagueNum])
  // Tab key
  const [activeKey, setActiveKey] = useState('5')
  const [subActiveKey, setSubActiveKey] = useState('2')

  return (
    <Card>
      <CardBody>
        <SportManagementTabs activeKey={activeKey} />
        <LeagueTeamManagementTabs activeKey={subActiveKey} />
        <TeamManagementTabs activeKey={sportsType} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 팀명을 변경 시, <span style={{ color: 'red', fontWeight: '500' }}>변경된 팀명</span>으로 회원들에게
              보여집니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        {/* <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>팀 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchTeamName"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTeamName}
                  onChange={e => onSearchTeamNameHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer> */}
        <CustomFormContainer>
          {/* 노출 리그 목록 */}
          <CustomFirstGroup>
            <CustomTitleWrap>
              <CustomTitle>리그 목록</CustomTitle>
              <CustomThWrap>
                <span style={{ width: '20%' }}>리그번호</span>
                <span style={{ width: '80%' }}>리그명</span>
              </CustomThWrap>
            </CustomTitleWrap>

            <CustomContentWrap>
              {league.map(item => (
                <CustomHangWrap
                  key={item.leagueNum}
                  isSelected={selectedRow === item.leagueNum}
                  onClick={() => {
                    onSearchTeamHandler(item.leagueNum)
                  }}
                >
                  <CustomLeagueNum>{item.leagueNum}</CustomLeagueNum>
                  <CustomLeagueName>{item.leagueName}</CustomLeagueName>
                </CustomHangWrap>
              ))}
            </CustomContentWrap>
          </CustomFirstGroup>
          {/* 팀 목록 */}
          <CustomDivGroup>
            <CustomTitleWrap>
              <CustomTitle>리그 팀 목록</CustomTitle>
              <CustomThWrap>
                <span style={{ width: '10%' }}>팀번호</span>
                <span style={{ width: '15%' }}>국가</span>
                <span style={{ width: '40%' }}>팀명</span>
                <span style={{ width: '35%' }}>변경팀명</span>
                <span style={{ width: '5%' }}>&nbsp;</span>
              </CustomThWrap>
            </CustomTitleWrap>
            <CustomContentWrap>
              {detaillTeamList.map(item => (
                <CustomHangWrap>
                  <CustomLeagueSecondNum>{item.teamNum}</CustomLeagueSecondNum>
                  <CustomTeam>
                    <NationalImg src={`${process.env.PUBLIC_URL}/img/national/${item.cc}.png`} />
                    <span>{item.ccKr}</span>
                  </CustomTeam>
                  <CustomTeam1Name>{item.teamName}</CustomTeam1Name>
                  <CustomTeam2Name>
                    <input
                      name="searchMemberId"
                      style={{ height: '25px' }}
                      type="text"
                      placeholder="변경시 입력해주세요"
                      // value={searchMemberId}
                      onChange={e => {
                        setNewName(e.target.value)
                      }}
                    />
                  </CustomTeam2Name>
                  <CustomLeagueShow>
                    <button
                      style={{ border: 'none', height: '25px', background: 'green', color: 'white' }}
                      type="button"
                      onClick={() => {
                        onChangeTeamHandler(item.teamName, item.teamNum)
                      }}
                    >
                      변경
                    </button>
                  </CustomLeagueShow>
                </CustomHangWrap>
              ))}
            </CustomContentWrap>
          </CustomDivGroup>
        </CustomFormContainer>
      </CardBody>
    </Card>
  )
}

export default TeamManagementList

const NationalImg = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 5px;
`

const CustomHangWrap = styled.div`
  width: 100%;
  display: flex;
  border-bottom: solid 1px #e6e7eb;
  &:hover {
    background: rgba(140, 140, 143, 0.5);
    cursor: pointer;
  }
  background: ${props => (props.isSelected ? 'rgba(140, 140, 143, 0.5)' : 'none')};
`
const CustomLeagueSecondNum = styled.div`
  width: 10%;
  text-align: center;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`

const CustomLeagueNum = styled.div`
  width: 20%;
  text-align: center;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`

const CustomTeam = styled.div`
  width: 15%;
  text-align: center;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  display: flex;
  gap: 10px;
`

const CustomTeam1Name = styled.div`
  width: 40%;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`

const CustomTeam2Name = styled.div`
  width: 30%;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`

const CustomLeagueName = styled.div`
  width: 80%;
  font-weight: 600;
  color: #6b7280;
  height: 30px !important;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
`

const CustomLeagueShow = styled.div`
  width: 8%;
  text-align: center;
  height: 30px !important;
  justify-content: center;
  align-items: center;
  padding-top: 3px;
  padding-right: 10px;
`

const CustomTitleWrap = styled.div`
  width: 100%;
  border-bottom: solid 1px black;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 60px;
`

const CustomTitle = styled.div`
  width: 100%;
  color: blue;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  justify-content: center;
  align-items: center;
`

const CustomFirstGroup = styled.div`
  width: 30% !important;
  border: solid 2px black;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CustomDivGroup = styled.div`
  width: 70% !important;
  border: solid 2px black;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const CustomThWrap = styled.div`
  width: 100%;
  display: flex;
  font-weight: 500;
  background: #eee;
  font-size: 15px;
  border-bottom: solid 2px black;
`

const CustomContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll !important;
  height: 650px;
`

const CustomFormContainer = styled.div`
  width: 100%;
  display: flex;
  // grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;
  max-height: 1000px;
`
