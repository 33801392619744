import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { SportMarketType } from '../../../enums/Sport/SportMarketEnums'
import {
  SettlemenResulttStatusEnumsSelectOptionsV2,
  SettlementStatusEnumsRadioOptions,
  SportSettlementStatusV2Enums,
  sportResultStatusEnums,
} from '../../../enums/SystemManagement/sportEnums'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import {
  createSportsSettlementManualV2,
  createSportsSettlementWaitingV2,
  getSportsSettlementV2,
  updateSportsSettlementTypeV2,
} from '../../../utils/api/sport/sportSettlementApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import SportDetailBettingLog from '../../BettingManagement/SportBettingLogV2/SportDetailBettingLog'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import SettlementRadio from '../../SportManagement/SettlementManagement/SettlementRadio'
import SportManagementTabsV2 from '../SportManagementTabsV2'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const SportsSettlementManagementV2 = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })

  // const [settlementFlag, setSettlementFlag] = useState(
  //   new URLSearchParams(location?.search).get('isSettlement') || null,
  // )

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)
  const authority = decodeAccessToken()?.adminAuthority

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
    memberId: null,
    settlementFlag: null,
    userAuthority: null,
    edgeName: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page: 0,
      userAuthority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [totalElement, setTotalElement] = useState(0)
  const [sportsBettingLogList, setSportsBettingLogList] = useState([])
  const [settlementType, setSettlementType] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchSearchSportsMarket = async () => {
    await getSportsSettlementV2(bettingLogparams)
      .then(res => {
        const newSportsBettingLogList = res.data.sportsBettingLogList.map(item => ({
          ...item,
          isVisible: false,
        }))
        setSportsBettingLogList(newSportsBettingLogList)
        setTotalElement(res.data.totalElements)
        setSettlementType(res.data.settlementType)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchSportsMarket()
  }, [bettingLogparams])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 특정 배팅로그 펼치기
  const onClickSportsBettingLogHandler = sportsBettingLogKey => {
    setSportsBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        if (item.sportsBettingLogKey === sportsBettingLogKey) {
          return { ...item, isVisible: !item.isVisible } // isVisible 토글
        }
        return item // 변경 없이 그대로 반환
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  const [checkRadio, setCheckRadio] = useState([])
  const [settleMentInfo, setSettleMentInfo] = useState({})

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState(null)

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)

  const searchGameResultOption = SettlemenResulttStatusEnumsSelectOptionsV2()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      memberId: !searchMemberId ? null : searchMemberId.trim(),
      edgeName: !searchEdgeName ? null : searchEdgeName,
      settlementFlag: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  const [writeLoading, setWriteLoading] = useState(false)
  // 정산하기 버튼

  const [apiLoading, setApiLoading] = useState(false)

  const onSettlementHandler = (memberId, bettingLogKey) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return

    if (window.confirm(`${memberId}님의 배팅을 정산하시겠습니까?\n확인을 누르시면 당첨금이 회원에게 지급됩니다.`)) {
      setWriteLoading(true)
      const body = {
        sportsBettingLogKey: [bettingLogKey],
      }
      createSportsSettlementManualV2(body)
        .then(res => {
          dispatch(fetchHeaderAlarmInfoAction())
          alert('수동정산이 완료됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
        .catch(error => {
          const { errorCode, message } = error.response.data

          switch (errorCode) {
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('수동정산이 실패했습니다. 잠시 후 다시 시도해주세요.')
          }
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onSettlementHandleAll = (memberId, bettingLogKey) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (checkRadio.length === 0) {
      alert('정산 할 내역들을 선택해주세요.')
      return
    }

    if (window.confirm(`스포츠 배팅을 정산하시겠습니까?\n확인을 누르시면 당첨금이 회원에게 지급됩니다.`)) {
      setWriteLoading(true)
      const body = {
        sportsBettingLogKey: checkRadio,
      }
      createSportsSettlementManualV2(body)
        .then(res => {
          dispatch(fetchHeaderAlarmInfoAction())
          alert('정산처리가 완료됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
        .catch(error => {
          alert('정산처리가 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onSettlementWaitingHandler = (memberId, bettingLogKey) => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (checkRadio.length === 0) {
      alert('대기를 할 정산내역들을 선택해주세요.')
      return
    }

    if (window.confirm(`스포츠 배팅정산들을 대기 처리하시겠습니까?\n확인을 누르시면 더이상 알림이 울리지 않습니다.`)) {
      setWriteLoading(true)
      const body = {
        sportsBettingLogKey: checkRadio,
      }
      createSportsSettlementWaitingV2(body)
        .then(res => {
          dispatch(fetchHeaderAlarmInfoAction())
          alert('대기처리가 완료됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
        .catch(error => {
          alert('대기처리가 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
          setCheckRadio([])
        })
    } else {
      setWriteLoading(false)
    }
  }

  const onSubmit = e => {
    if (writeLoading) return
    if (window.confirm('정산방식을 변경 하시겠습니까?')) {
      const body = {
        sportsSettlementType: e.settlementStatus,
      }
      setWriteLoading(true)
      updateSportsSettlementTypeV2(body)
        .then(res => {
          alert('정산방식이 수정됐습니다.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
        .catch(error => {
          alert('수정이 실패했습니다. 잠시 후 다시 시도해주세요.')
          fetchSearchSportsMarket()
          setWriteLoading(false)
        })
    }
  }

  const onSettlementStatus = customerStatus => {
    switch (customerStatus) {
      case 'COMPLETE':
        return (
          <button type="button" style={{ background: 'blue', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatusV2Enums[customerStatus]}
          </button>
        )
      case 'WAITING':
        return (
          <button type="button" style={{ background: 'orange', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatusV2Enums[customerStatus]}
          </button>
        )
      case 'ING':
        return (
          <button type="button" style={{ background: 'green', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatusV2Enums[customerStatus]}
          </button>
        )
      default:
        return (
          <button type="button" style={{ background: 'black', border: 'none', color: '#fff', fontWeight: '500' }}>
            {SportSettlementStatusV2Enums[customerStatus]}
          </button>
        )
    }
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('3')
  return (
    <Card>
      <CardBody>
        <SportManagementTabsV2 activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당회원의 자세한 배팅내역을 볼 수 있습니다.</p>
            <p>
              * 해당 페이지는 <span style={{ color: 'blue', fontWeight: '600' }}>당첨된 회원</span>들의 내역이 나오며,
              수동으로 정산처리를 할 수 있습니다.
            </p>
            <p>* 자동 정산을 끄면, 수동으로 정산을 처리하셔야 합니다.</p>
          </div>
        </SearchCardTitleWrap>

        <SearchContainer horizontal>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>정산여부</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="정산여부"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ModifyWrap>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup style={{ width: '330px', marginRight: '40px' }}>
                  <FormGroupLabel>
                    정산 <span style={{ color: 'red', fontWeight: '500' }}>수동/자동 여부</span>
                  </FormGroupLabel>
                  {SettlementStatusEnumsRadioOptions().map(item => (
                    <FormGroupField
                      key={`index_${item.label}`}
                      style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                    >
                      <Field
                        name="settlementStatus"
                        component={renderRadioButtonField}
                        label={item.label}
                        radioValue={item.radioValue}
                        initialValue={settlementType}
                      />
                    </FormGroupField>
                  ))}
                </FormGroup>

                <>
                  {
                    {
                      SUPER: (
                        <FormButtonToolbar style={{ marginTop: '-80px' }}>
                          <Button size="sm" variant="danger" type="submit">
                            수정하기
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            style={{ backgroundColor: 'lightGreen', color: 'white', marginLeft: '200px' }}
                            onClick={() => {
                              setCheckRadio(sportsBettingLogList.map(value => value.sportsBettingLogKey))
                            }}
                          >
                            전체선택
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            style={{ backgroundColor: 'green', color: 'white' }}
                            onClick={() => {
                              onSettlementWaitingHandler()
                            }}
                          >
                            정산대기
                          </Button>
                          <Button
                            size="sm"
                            variant="outline-secondary"
                            style={{ backgroundColor: 'blue', color: 'white' }}
                            onClick={() => {
                              onSettlementHandleAll()
                            }}
                          >
                            정산승인
                          </Button>
                        </FormButtonToolbar>
                      ),
                    }[authority]
                  }
                </>
              </FormContainer>
            )}
          </Form>
        </ModifyWrap>
        {writeLoading && <Loading />}
        <SportsGameListTableWrap>
          <table>
            <thead>
              <tr>
                <th>-</th>
                <th>번호</th>
                <th>사이트명</th>
                <th>배팅일시</th>
                <th>결과처리일시</th>
                <th>상위파트너(이름)</th>
                <th>유저ID(이름)</th>
                <th>타입</th>
                <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액</th>
                <th>폴더</th>
                <th>배당</th>
                <th style={{ backgroundColor: 'green', color: '#29ff29' }}>최종 당첨금</th>
                <th>결과</th>
                <th>정산상태</th>
                <th>수동정산</th>
              </tr>
            </thead>
            <tbody>
              {sportsBettingLogList.map(sportsBettingLog => {
                return (
                  <>
                    <tr
                      key={sportsBettingLog.sportsBettingLogKey}
                      onClick={e => {
                        onClickSportsBettingLogHandler(sportsBettingLog.sportsBettingLogKey)
                      }}
                    >
                      <td>
                        <SettlementRadio
                          rowInfo={sportsBettingLog}
                          checkRadio={checkRadio}
                          setCheckRadio={setCheckRadio}
                        />
                      </td>
                      <td>{sportsBettingLog.num}</td>
                      <td>{sportsBettingLog.edgeName}</td>
                      <td>{convertToKstSportsEventDateTime(sportsBettingLog.bettingAt)}</td>
                      <td>{convertToKstSportsEventDateTime(sportsBettingLog.resultExecuteAt)}</td>
                      <td>
                        {sportsBettingLog.topUserId ? (
                          <>
                            {sportsBettingLog.topUserId} ({sportsBettingLog.topUserName})
                          </>
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        <span
                          onClick={() => {
                            window.open(`/user-info/${sportsBettingLog.userId}`, '_blank')
                          }}
                          style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                        >
                          {sportsBettingLog.userId} ({sportsBettingLog.userName})
                        </span>
                        <span style={{ marginLeft: '5px' }}>
                          <CustomAuthorityButton authority={sportsBettingLog.userAuthority}>
                            {NoteAuthorityEnums[sportsBettingLog.userAuthority]}
                          </CustomAuthorityButton>
                        </span>
                        <span>
                          <span>
                            <MemberDepositInfo memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberCoupon memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberNote memberId={sportsBettingLog.userId} />
                          </span>
                          <span>
                            <MemberMoneyIncDecMini memberId={sportsBettingLog.userId} />
                          </span>
                        </span>
                      </td>
                      <CustomTd sportMarketType={sportsBettingLog.sportMarketType}>
                        {SportMarketType[sportsBettingLog.sportMarketType]}
                      </CustomTd>
                      <td style={{ backgroundColor: '#77c779', color: '#fff' }}>
                        {sportsBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                      </td>

                      <td>{sportsBettingLog.folderSize} 폴더</td>
                      <td>
                        {(() => {
                          const {
                            realReduceBettingPerForSingleFolder,
                            realReduceBettingPerForCombination,
                            totalOddValue,
                            originTotalOddValue,
                          } = sportsBettingLog
                          const reduceBettingValue =
                            realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                          if (reduceBettingValue === null) {
                            return <span>{totalOddValue}</span>
                          }

                          return (
                              <TooltipWrapper text="적용배당 / (원본배당 - 조정배당)" place="bottom">
                                {Number(reduceBettingValue) < 0 ? (
                                    <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                      {reduceBettingValue?.toFixed(2)})
                                  </span>
                                ) : (
                                    <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                      {reduceBettingValue?.toFixed(2)})
                                  </span>
                                )}
                              </TooltipWrapper>
                          )
                        })()}
                      </td>

                      <td style={{ backgroundColor: '#77c779', color: '#00ff06' }}>
                        <span style={{ color: '#b8001f', fontWeight: '600' }}>
                          {sportsBettingLog.bettingResultAmount.toString().replace(commonReg2, ',')}
                        </span>
                      </td>
                      <td>
                        <CustomButton type="button" bettingResult={sportsBettingLog.sportsResultStatus}>
                          {sportResultStatusEnums[sportsBettingLog.sportsResultStatus]}
                        </CustomButton>
                      </td>
                      <td>{onSettlementStatus(sportsBettingLog.settlementType)}</td>
                      <td>
                        {sportsBettingLog.settlementType !== 'COMPLETE' && (
                          <button
                            type="button"
                            style={{ background: 'green', color: 'white', border: 'none' }}
                            onClick={() => {
                              onSettlementHandler(sportsBettingLog.userId, sportsBettingLog.sportsBettingLogKey)
                            }}
                          >
                            수동정산
                          </button>
                        )}
                      </td>
                    </tr>
                    {sportsBettingLog.isVisible && (
                      <SportDetailBettingLog
                        sportsDetailBettingLogList={sportsBettingLog.sportsDetailBettingLogList}
                        bonsuFolder={{
                          bonusFolderNum: sportsBettingLog.bonusFolderNum,
                          bonusFolderPer: sportsBettingLog.bonusFolderPer,
                        }}
                      />
                    )}
                  </>
                )
              })}
            </tbody>
          </table>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}

export default SportsSettlementManagementV2

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const getColor2 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomTd = styled.td`
  color: #000;
  font-weight: 600;
  background: ${props => getColor2(props.sportMarketType)} !important;
`

const ModifyWrap = styled.div`
  width: 100%;
  display: flex;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`

const SportsGameFolderEdgeNameBox = styled.div`
  width: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`

const SportsGameFolderAuthorityNameBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`

const SportsGameFolderScoreBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
`

const SportsGameFolderResultBox = styled.div`
  width: 11%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
  border-right: 1px solid black;
`

const SportsGameFolderManualResultBox = styled.div`
  width: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid black;
`
const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: green;
  font-weight: 600;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 12%;
  display: flex;
  border-right: 1px solid black;
`

const SportsGamePartnerBox = styled.div`
  width: 11%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  width: 10%;
  display: flex;
  border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  color: black;
  height: 36px;
  border-left: solid 1px black;
`
