import styled from 'styled-components'
import { Popover } from 'react-bootstrap'
import { colorAccent, colorFieldsBorder } from '@/utils/palette'
import { marginRight, marginLeft, paddingLeft } from '@/utils/directions'

export const ColorPickerColor = styled.p`
  margin-right: auto;
  margin-left: 0;
  margin-top: auto;
  margin-bottom: auto;
  direction: ltr;
  padding-left: 10px;
`

export const ColorPickerButton = styled.button`
  height: 32px;
  width: 100%;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  background: transparent;
  padding: 0;
  border: 1px solid ${props => (props.active ? colorAccent : '#000')};
`

export const ColorPickerColorView = styled.div`
  height: 30px;
  width: 50px;
  border-left: 1px solid #000;
`

export const ColorPickerPopover = styled(Popover)`
  width: auto;

  .block-picker {
    & > div:first-child {
      display: none;
    }
  }

  .block-picker,
  .chrome-picker,
  .sketch-picker {
    box-shadow: none !important;
  }
`
