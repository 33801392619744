import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa'
import Switch from 'react-switch'
import styled from 'styled-components'
import { BetSettlementStatus, BetStatus, SportMarketType } from '../../../enums/Sport/SportMarketEnums'
import { MoneyIncDecAuthorityEnums } from '../../../enums/Log/moneyIncDecEnums'
import { convertToKstDateTimeSimple } from '../../../utils/dateTime'
import SportsDetailBettingInfoV2 from './SportsDetailBettingInfoV2'
import { updateSportsDetailBettingLogV2 } from '../../../utils/api/sport/sportBettingLogApi'
import { decodeAccessToken } from '../../../utils/token'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const areEqual = (prevProps, nextProps) => {
  return prevProps.fixtureInfo === nextProps.fixtureInfo && isEqual(prevProps.fixtureInfo, nextProps.fixtureInfo) // deepEqual은 깊은 비교를 수행하는 함수를 의미
}

export const SportFixtureTabeItemBettingInfo = React.memo(({ fixtureInfo, item }) => {
  const [isMemberModalShow, setMemberModalShow] = useState(false)
  const onClickHandler = () => {
    setMemberModalShow(prevState => !prevState)
  }

  const [isBettingUserId, setBettingUserId] = useState(null)
  const [isBettingLogKey, setBettingLogKey] = useState(null)
  const onAddHandler = (bettingUserId, bettingLogKey) => {
    setBettingUserId(bettingUserId)
    setBettingLogKey(bettingLogKey)
  }

  const [newSettlementType, setNewSettlementType] = useState(null)

  const [adminChanges, setAdminChanges] = useState(
    item.detailInfo.reduce((acc, log) => {
      acc[log.sportsDetailBettingLogKey] = log.adminChange || false
      return acc
    }, {}),
  )

  const handleCheckboxChange = sportsDetailBettingLogKey => {
    setAdminChanges(prev => ({
      ...prev,
      [sportsDetailBettingLogKey]: !prev[sportsDetailBettingLogKey],
    }))
  }

  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const onSubmit2 = sportsDetailBettingLogKey => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (!newSettlementType) {
      alert('진행중으로는 결과변경이 불가합니다.')
      return
    }
    if (
      window.confirm('해당 경기의 결과를 변경하시겠습니까? \n해당 회원의 최종적인 결과는 1분이내 자동으로 반영됩니다.')
    ) {
      const body = {
        sportsDetailBettingLogKey,
        betSettlementType: newSettlementType,
        adminChange: adminChanges[sportsDetailBettingLogKey] || false,
      }
      setWriteLoading(true)

      updateSportsDetailBettingLogV2(body)
        .then(res => {
          setWriteLoading(false)
          alert('스포츠 결과가 변경됐습니다.')
        })
        .catch(error => {
          setWriteLoading(false)
          alert('스포츠 결과가 변경이 실패했습니다.')
        })
    }
  }

  const getBetLineBox = bet => {
    const pattern = /^-?\d+\.\d+ \(\d+-\d+\)$/
    const match = bet.baseLine?.match(pattern)

    // 핸디일때
    if (match) {
      const innerPattern = /\((\d+)-(\d+)\)/
      const scoreMatch = bet.line.match(innerPattern)

      const homeScore = Number.isNaN(Number(scoreMatch[1])) ? 0 : Number(scoreMatch[1])
      const awayScore = Number.isNaN(Number(scoreMatch[2])) ? 0 : Number(scoreMatch[2])

      // 공식 : 베이스라인 기준점 - 홈 스코어 + 어웨이 스코어
      let finalLine

      // 원정팀 선택시
      if (bet.betName === '1') {
        finalLine = bet.lineNum - homeScore + awayScore
      } else {
        finalLine = bet.lineNum + homeScore - awayScore
      }

      return (
        <td>
          {bet.lineNum !== null ? (
            <>
              <span>{bet.line}</span>
              <span style={{ color: 'red' }}>&nbsp;&nbsp;( {finalLine.toFixed(1)} )</span>
            </>
          ) : (
            <span>-</span>
          )}
        </td>
      )
    }

    return <td>{bet.lineNum !== null ? <span>{bet.lineNum.toFixed(1)}</span> : <span>-</span>}</td>
  }

  return (
    <SportDetailBettingLogWrap>
      {isMemberModalShow && (
        <SportsDetailBettingInfoV2
          isModalShow={isMemberModalShow}
          onClickHandler={onClickHandler}
          bettingUserId={isBettingUserId}
          bettingLogKey={isBettingLogKey}
        />
      )}
      <td colSpan={15} style={{ padding: '10px' }}>
        {item.bettingInfoTrShow && (
          <table>
            <thead>
              <tr style={{ background: '#a6a6a6' }}>
                <th colSpan={1}>자세히</th>
                <th colSpan={1}>베팅시간</th>
                <th colSpan={1}>사이트명</th>
                <th colSpan={2}>유저ID(명) - 권한</th>
                <th colSpan={2}>상위파트너ID(명)</th>
                <th colSpan={1}>타입</th>
                <th colSpan={2}>배팅</th>
                {/* <th colSpan={1}>기준점</th> */}
                <th colSpan={1}>
                  <TooltipWrapper
                    text="(홈) 기준점 - 홈스코어 + 어웨이스코어"
                    textnd="(원정) 기준점 + 홈스코어 - 어웨이스코어"
                    textrd="ex. -0.5 (0-1) : (-0.5)-0+1 = 0.5"
                    place="bottom"
                  >
                    <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>기준점</span>
                  </TooltipWrapper>
                </th>
                <th colSpan={1}>배당</th>
                <th colSpan={1}>점수</th>
                <th colSpan={1}>상태</th>
                <th colSpan={1}>결과</th>
                <th colSpan={1}>고정</th>
                <th colSpan={1}>수정</th>
              </tr>
            </thead>
            <tbody>
              {item.detailInfo?.map(bettingInfo => (
                <tr
                  style={{ background: '#ededed' }}
                  onClick={() => {
                    onAddHandler(bettingInfo.userId, bettingInfo.sportsBettingLogKey)
                    onClickHandler()
                  }}
                >
                  <td
                    style={{
                      color: '#000',
                    }}
                  >
                    <FaPlusCircle
                      style={{ color: 'green' }}
                      onClick={e => {
                        // e.preventDefault()
                        // onAddHandler(bettingInfo.userId, bettingInfo.sportsBettingLogKey)
                        // onClickHandler()
                      }}
                    />
                  </td>
                  <td
                    style={{
                      color: '#000',
                    }}
                  >
                    {convertToKstDateTimeSimple(bettingInfo.bettingAt)}
                  </td>
                  <td colSpan={1}>{bettingInfo.edgeName}</td>
                  <td colSpan={2} style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}>
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open(`/user-info/${bettingInfo.userId}`, '_blank')
                      }}
                    >
                      {bettingInfo.userId}({bettingInfo.userName}) -{' '}
                      <CustomAuthorityButton authority={bettingInfo.userAuthority}>
                        {MoneyIncDecAuthorityEnums[bettingInfo.userAuthority]}
                      </CustomAuthorityButton>
                    </div>
                  </td>
                  <td colSpan={2}>
                    {bettingInfo.topPartnerId}({bettingInfo.topPartnerName}){`- [${bettingInfo.topPartnerLevel}LV]`}
                  </td>
                  <td>{SportMarketType[bettingInfo.sportMarketType]}</td>
                  <StyledTd
                    colSpan={2}
                    isHighlighted4
                    style={{
                      color: 'green',
                    }}
                  >
                    {bettingInfo.betNameKr}
                  </StyledTd>
                  {getBetLineBox(bettingInfo)}
                  <td>{bettingInfo.priceNum}</td>
                  <td> - </td>
                  <td>{BetStatus[bettingInfo.betStatus]}</td>
                  <Customtd betSettlementType={bettingInfo.betSettlementType}>
                    {bettingInfo.betSettlementType === 'CANCELLED' ? (
                      <span style={{ color: 'red', fontWeight: '500' }}>취소</span>
                    ) : (
                      <select
                        style={{ padding: '0px 3px', height: '25px' }}
                        onClick={e => {
                          e.stopPropagation() // Prevents the event from bubbling up to the <tr> element
                        }}
                        onChange={e => {
                          e.stopPropagation() // Prevents the event from bubbling up to the <tr> element
                          setNewSettlementType(e.target.value)
                        }}
                      >
                        <option value="NOT_SETTLED" selected={bettingInfo.betSettlementType === 'NOT_SETTLED'}>
                          진행중
                        </option>
                        <option value="WINNER" selected={bettingInfo.betSettlementType === 'WINNER'}>
                          적중
                        </option>
                        <option value="LOSER" selected={bettingInfo.betSettlementType === 'LOSER'}>
                          미적중
                        </option>
                        <option value="REFUND" selected={bettingInfo.betSettlementType === 'REFUND'}>
                          적특
                        </option>
                      </select>
                    )}
                  </Customtd>
                  <td>
                    <input
                      style={{ width: '17px', height: '17' }}
                      type="checkbox"
                      checked={adminChanges[bettingInfo.sportsDetailBettingLogKey]}
                      onClick={e => {
                        e.stopPropagation() // Prevents the event from bubbling up to the <tr> element
                      }}
                      onChange={e => {
                        e.stopPropagation()
                        handleCheckboxChange(bettingInfo.sportsDetailBettingLogKey)
                      }}
                    />
                  </td>
                  <td
                    style={{
                      color: '#000',
                    }}
                  >
                    {bettingInfo.betSettlementType === 'CANCELLED' ? (
                      '-'
                    ) : (
                      <button
                        style={{ border: 'none', background: 'red', color: '#fff' }}
                        type="button"
                        onClick={e => {
                          e.stopPropagation()
                          onSubmit2(bettingInfo.sportsDetailBettingLogKey)
                        }}
                      >
                        수정
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </td>
    </SportDetailBettingLogWrap>
  )
}, areEqual)

SportFixtureTabeItemBettingInfo.propTypes = {
  fixtureInfo: PropTypes.shape().isRequired,
  item: PropTypes.shape().isRequired,
}

const getColor = betSettlementType => {
  switch (true) {
    case betSettlementType === 'WINNER':
      return 'blue'
    case betSettlementType === 'CANCELLED':
      return 'red'
    case betSettlementType === 'LOSER':
      return 'red'
    case betSettlementType === 'REFUND':
      return 'green'
    case betSettlementType === 'CANCEL':
      return 'red'
    default:
      return 'green'
  }
}

const Customtd = styled.td`
  color: ${props => getColor(props.betSettlementType)} !important;
  font-weight: 900;
`

const CustomAuthorityButton = styled.button`
  color: white;
  background-color: ${props => (props.authority === 'MEMBER' ? 'orange' : '#7da832')};
  border: none;
`

const StyledTd = styled.td`
  background: rgb(209, 213, 219);

  ${props =>
    props.isHighlighted4 &&
    `
background: rgba(120, 179, 66, 0.2);
`}

  ${props =>
    props.isHighlighted3 &&
    `
background: rgba(120, 179, 66, 0.5);
`}

  ${props =>
    props.isHighlighted &&
    `
  background: rgb(252, 247, 164);
  `}

  ${props =>
    props.isHighlighted2 &&
    `
  background: rgb(252, 247, 164,0.2);
  `}
`

const SportDetailBettingLogWrap = styled.tr`
  border: 1px solid #888;

  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #a6a6a6 !important;

      &:first-child {
        border-top: 1px solid #888;
      }

      &:hover {
        border: none !important;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: #fff !important;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      background: #ededed;

      &:hover {
        cursor: pointer;
        border: 2px solid green !important;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const SearchWrap = styled.div`
  width: 100%;
  border: 1px solid #222733;
  border-radius: 3px;
  padding: 8px 8px;
  margin-top: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
`

const SearchWrapItem = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  align-items: center;

  input {
    border: 1px solid #222733;
    height: 30px;
    line-height: 28px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 7px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }

  select {
    min-width: 70px;
    display: inline-block;
    height: 30px;
    line-height: 28px;
    padding: 0 7px;
    border: 1px solid #222733;
    vertical-align: middle;
    border-radius: 3px;

    &:focus,
    &:active,
    &active:focus {
      outline: none;
      box-shadow: none !important;
      border: solid 2px #edae07;
    }
  }
`

const SearchButton = styled(Button)``

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportMenuWrap = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
`

const SportMenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 3px solid black;
  `};
`

const SportFixtureTableWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  margin-top: 15px;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  thead {
    tr {
      width: 100%;
      background: #f0f0f0;

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: 600;
        font-size: 12px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        ${props =>
          props.isHighlighted &&
          `
        background: yellow;
        `}
        padding: 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }

        svg {
          cursor: pointer;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
`

const SportFixtureLeagueWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;

  img {
    width: 16px;
    height: 16px;

    &:not(:first-child) {
      margin-left: 15px;
    }
  }

  span {
    font-size: 14px;
    margin-left: 5px;
    text-align: center;
  }
`

const SportsEventResultBtn = styled(Button)`
  background: #f59a0a;
  color: white;
  border: none;
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
