import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { borderRight } from '@/utils/directions'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorText } from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import ReactHtmlParser from 'react-html-parser'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import styled from 'styled-components'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import { SearchCardTitleWrap } from '../../../shared/components/Card'
import { updateOnetoOne } from '../../../utils/api/operation/operationApi'
import { searchMacroSelect } from '../../../utils/api/systemManage/systemManageApi'
import { convertFromServer } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import '../customStyle.css'

function OnetoOneInfoForm({ onetoOneInfo, fetchSearchOnetoOneList, onClickHandler }) {
  const dispatch = useDispatch()
  const authority = decodeAccessToken()?.adminAuthority

  const [questionContent, setQuestionContent] = useState('')
  const [answerContent, setAnswerContent] = useState('')

  useEffect(() => {
    setQuestionContent(convertFromServer(onetoOneInfo.questionContent))
  }, [onetoOneInfo.questionContent])

  useEffect(() => {
    setAnswerContent(onetoOneInfo.answerContent)
  }, [onetoOneInfo.answerContent])

  const [answerContentOption, setAnswerContentOption] = useState([])
  const [answerContentOptionValue, setAnswerContentOptionValue] = useState(null)
  const [selectedOnetoOneKey, setSelectedOnetoOneKey] = useState('')

  const onAnswerContentSelectHandler = option => {
    if (option === null) {
      setAnswerContentOptionValue(option)
      setAnswerContent('')
      return
    }
    setAnswerContentOptionValue(option)
    setAnswerContent(option.oneToOneContent)
  }

  // macro 1:1 조회
  const macroSelectParams = {
    userId: onetoOneInfo.userId,
    searchType: 'ONETOONE',
  }

  useEffect(() => {
    searchMacroSelect(macroSelectParams).then(res => {
      res.data.content.map(item =>
        setAnswerContentOption(prev => [
          ...prev,
          {
            value: item.macroTitle,
            label: item.macroTitle,
            oneToOneContent: item.macroContent,
          },
        ]),
      )
    })
  }, [])

  // 답변 내용 작성 핸들러
  const onAnswerContentChangeHandler = editorState => {
    setAnswerContent(editorState)
  }

  const onSubmit = e => {
    if (!answerContent) {
      alert('답변 매크로 선택 또는 답변을 직접 기입해주세요.')
      return
    }
    const body = {
      customerStatus: 'COMPLETE',
      oneToOneKeyOne: onetoOneInfo.oneToOneKey,
      answerContent,
    }
    updateOnetoOne(body)
      .then(res => {
        dispatch(fetchHeaderAlarmInfoAction())
        alert('답변이 성공적으로 보내졌습니다.')
        onClickHandler()
        fetchSearchOnetoOneList()
        dispatch(fetchHeaderAlarmInfoAction())
      })
      .catch(error => {
        alert('답변 보내기가 실패했습니다. 잠시 후 다시 시도해주세요.')
      })
  }

  const onConfirmHandler = () => {
    onClickHandler()
    fetchSearchOnetoOneList()
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        questionContent: ReactHtmlParser(onetoOneInfo.questionContent),
        questionTitle: onetoOneInfo?.questionTitle,
        userId: onetoOneInfo?.userId,
      }}
    >
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          <SearchCardTitleWrap style={{ marginBottom: '30px' }}>
            <p>
              * 문의답변 <span style={{ fontWeight: 'bolder', color: 'blue' }}>매크로 기능</span>을 이용하시거나, 직접
              답변내용을 적으실 수 있습니다.{' '}
            </p>
          </SearchCardTitleWrap>
          <FormGroup>
            <FormGroupLabel>작성자</FormGroupLabel>
            <FormGroupField>
              <Field name="userId" component="input" type="text" defaultValue={onetoOneInfo.userId} disabled />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>문의 제목</FormGroupLabel>
            <FormGroupField>
              <Field
                name="questionTitle"
                component="input"
                type="text"
                defaultValue={onetoOneInfo.questionTitle}
                disabled
              />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>문의 내용</FormGroupLabel>
            <CustomFormGroupField style={{ flexDirection: 'column' }}>
              {ReactHtmlParser(onetoOneInfo.questionContent)}
            </CustomFormGroupField>
          </FormGroup>

          <FormGroup>
            <FormGroupLabel>메크로 답변</FormGroupLabel>
            <FormGroupField>
              <Field name="selectedNote">
                {({ input, meta }) => (
                  <FormInputWrap>
                    <OneToOneSelect
                      name="answerContentList"
                      value={answerContentOptionValue}
                      onChange={option => {
                        onAnswerContentSelectHandler(option)
                      }}
                      options={answerContentOption}
                      isClearable
                      placeholder="문의 답변을 선택하세요"
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </FormInputWrap>
                )}
              </Field>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>답변 내용</FormGroupLabel>
            <CustomFormGroupField2>
              <ReactQuill
                value={answerContent}
                onChange={option => {
                  setAnswerContent(option) // questionContent 업데이트
                }}
                theme="snow"
                modules={{
                  toolbar: [
                    [{ header: '1' }, { header: '2' }, { font: [] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                  ],
                }}
                // onEditorStateChange={editorState => onAnswerContentChangeHandler(editorState)}
                // readOnly={onetoOneInfo.answerStatusType}
              />
              {/* <span style={{ padding: '10px' }}>{ReactHtmlParser(onetoOneInfo.answerContent)}</span> */}
            </CustomFormGroupField2>
          </FormGroup>

          <FormButtonToolbar style={{ marginTop: '0px', marginRight: '70px' }}>
            {onetoOneInfo.answerStatusType ? (
              <>
                <Button variant="danger" type="submit">
                  수정하기
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    onConfirmHandler()
                  }}
                >
                  확인
                </Button>
              </>
            ) : (
              <>
                {
                  {
                    SUPER: (
                      <Button variant="primary" type="submit">
                        답변하기
                      </Button>
                    ),
                  }[authority]
                }
              </>
            )}
          </FormButtonToolbar>
        </FormContainer>
      )}
    </Form>
  )
}

export default OnetoOneInfoForm

OnetoOneInfoForm.propTypes = {
  onetoOneInfo: PropTypes.shape({
    oneToOneKey: PropTypes.string,
    authority: PropTypes.string,
    userId: PropTypes.string,
    questionTitle: PropTypes.string,
    registrationDate: PropTypes.string,
    questionContent: PropTypes.string,
    answerContent: PropTypes.string,
    answerStatus: PropTypes.string,
    answerStatusType: PropTypes.bool,
  }).isRequired,
  fetchSearchOnetoOneList: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}

export const CustomFormGroupField = styled(FormGroupField)`
  border: solid 1px #000;
  height: 150px;
  width: 600px !important;
  padding: 0px !important;
  overflow: auto;
  margin-left: 20px !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    opacity: 0.3;
  }
`

export const CustomFormGroupField2 = styled(FormGroupField)`
  border: solid 1px #000;
  height: 250px;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    opacity: 0.3;
  }
  width: 600px !important;
  padding: 0px !important;
  margin-left: 20px !important;
`
// 1대1 문의 Select
export const OneToOneSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  // editor 때문에 추가함
  z-index: 101;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: red;

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

// endregion
