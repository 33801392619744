import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ko from 'date-fns/locale/ko'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from 'react-icons/ai'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  MemberCasinoLevelEnum,
  MemberDepositLevelEnum,
  MemberFriendCompLevelEnum,
  MemberHoldemLevelEnum,
  MemberMiniGameLevelEnum,
  MemberSportsLevelEnum,
  MemberVirtualGameLevelEnum,
} from '../../../enums/MemberManage/MemberInfoEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { PartnerLosingType } from '../../../enums/Statistics/partnerLosingStatistics'
import {
  fetchSettlementStatisticsBasic,
  fetchSettlementStatisticsSpecify,
  handleToggle,
  resetSettlementData,
} from '../../../redux/SettlementInfoSlice'
import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  CustomStyledModal,
} from '../../../shared/components/CustomModal'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import Loading from '../../../shared/components/Loading'
import { convertToKst } from '../../../utils/dateTime'
import { scrollbarStyles } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberLosingInfoPage from '../../MemberManagement/MemberList/MemberInfo/MemberLosingInfo/MemberLosingInfoPage'
import MemberHistoryMoneyIncDec from '../../MemberManagement/MemberList/MemberInfo/MemberMoneyIncDec/MemberHistoryMoneyIncDec'
import MemberRollingInfoPage from '../../MemberManagement/MemberList/MemberInfo/MemberRollingInfo/MemberRollingInfoPage'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import StatisticsTabs from '../StatisticsTabs'

const getUserAuthority = userAuthority => {
  return (
    <CustomAuthorityButton authority={userAuthority} disabled>
      {NoteAuthorityEnums[userAuthority]}
    </CustomAuthorityButton>
  )
}

const getMoneyAmount = moneyAmount => {
  if (!moneyAmount) return <span>0</span>
  if (moneyAmount > 1000000) {
    return <span style={{ color: 'red', fontWeight: '500' }}>{moneyAmount.toString().replace(commonReg2, ',')}</span>
  }
  return <span style={{ color: 'black', fontWeight: '500' }}>{moneyAmount.toString().replace(commonReg2, ',')}</span>
}

const getSettlementType = settlementType => {
  switch (settlementType) {
    case 'LOSING_SETTLEMENT_TYPE1':
      return <span style={{ color: 'orange', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE2':
      return <span style={{ color: 'purple', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE3':
      return <span style={{ color: '#B92EFA', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE4':
      return <span style={{ color: '#D79209', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE5':
      return <span style={{ color: 'brown', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE6':
      return <span style={{ color: '#A8D420', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE7':
      return <span style={{ color: '#634BC6', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    case 'LOSING_SETTLEMENT_TYPE8':
      return <span style={{ color: '#f28c0f', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
    default:
      return <span style={{ color: '#black', fontWeight: '900' }}>{PartnerLosingType[settlementType]}</span>
  }
}

const DisplayUserComponent = ({
  user,
  selectedUserId,
  depth = 0,
  fetchSettlementStatisticsBasic,
  toggleIsOpened,
  isActiveDefaultInfo,
  isActiveRollingInfo,
  isActiveLosingInfo,
  isActiveBettingInfo,
  setModalShow,
  setWhatModalShow,
  setModalUserId,
}) => {
  const userRef = useRef(null) // 사용자 요소에 대한 참조
  const handleIconClick = key => {
    toggleIsOpened(key)
  }
  useEffect(() => {
    // 사용자 요소가 마운트된 후에만 실행되도록 설정
    if (user.userId === selectedUserId && user.isOpened && userRef.current) {
      // 사용자 요소가 화면에 표시되도록 스크롤 이동
      userRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [user.isOpened])
  return (
    <>
      <CustomTr ref={userRef} style={{ background: depth === 0 ? '#fbf7ea' : 'inherit' }} depth={depth}>
        <MainTd style={{ paddingLeft: `${depth * 20 + 20}px`, textAlign: 'left', borderLeft: 'solid 1px black' }}>
          {depth > 0 && `\u2514 `}
          {user?.subUsersCount > 0 && (
            <span
              onClick={() => {
                handleIconClick(user.userId)
              }}
              style={{ cursor: 'pointer' }}
            >
              {user.isOpened ? <MinusSquare /> : <PlusSquare />}
            </span>
          )}
          <span
            onClick={() => {
              window.open(`/user-info/${user.userId}`, '_blank')
            }}
            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
          >
            {user.userId} ({user.userName}) {user.userAuthority === 'PARTNER' && `- [${user.partnerLevel}LV]`}
          </span>
          <span>
            <MemberDepositInfo memberId={user.userId} />
          </span>
          <span>
            <MemberCoupon memberId={user.userId} />
          </span>
          <span>
            <MemberNote memberId={user.userId} />
          </span>
        </MainTd>
        <MainTd>{user.edgeName}</MainTd>
        <MainTd>{getUserAuthority(user.userAuthority)}</MainTd>
        <td>{user.userAuthority === 'PARTNER' ? getSettlementType(user.losingSettlementType) : '-'}</td>
        {isActiveDefaultInfo && (
          <>
            <MainTd>
              <CustomButton
                style={{ background: 'gray' }}
                onClick={() => {
                  setModalShow(true)
                  setWhatModalShow('ASSET')
                  setModalUserId(user.userId)
                }}
              >
                증감
              </CustomButton>
            </MainTd>
            <MainTd>{getMoneyAmount(user.moneyAmount)}</MainTd>
            <MainTd>{getMoneyAmount(user.casinoMoneyAmount)}</MainTd>
            <MainTd>{getMoneyAmount(user.holdemMoneyAmount)}</MainTd>
            <MainTd>{getMoneyAmount(user.rollingPointAmount)}</MainTd>
            <MainTd>{getMoneyAmount(user.losingPointAmount)}</MainTd>
            <MainTd>{MemberDepositLevelEnum[user.depositLevelName]}</MainTd>
            <MainTd>{MemberCasinoLevelEnum[user.casinoSlotLevelName]}</MainTd>
            <MainTd>{MemberCasinoLevelEnum[user.casinoLiveLevelName]}</MainTd>
            <MainTd>{MemberMiniGameLevelEnum[user.miniGameLevelName]}</MainTd>
            <MainTd>{MemberSportsLevelEnum[user.sportsGameLevelName]}</MainTd>
            <MainTd>{MemberVirtualGameLevelEnum[user.virtualGameLevelName]}</MainTd>
            <MainTd>{MemberHoldemLevelEnum[user.holdemLevelName]}</MainTd>
            <MainTd>{MemberFriendCompLevelEnum[user.friendCompLevelName]}</MainTd>
          </>
        )}
        {isActiveBettingInfo && (
          <>
            {/* 슬롯 베팅-당첨  */}
            <td>
              {user.casinoSlotBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.casinoSlotWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 라이브 베팅-당첨  */}
            <td>
              {user.casinoLiveBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.casinoLiveWinningAmount?.toString().replace(commonReg2, ',')}
            </td>

            {/* 미니일반 베팅-당첨  */}
            <td>
              {user.miniGameSingleBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.miniGameSingleWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 미니조합 베팅-당첨  */}
            <td>
              {user.miniGameCombinationBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.miniGameCombinationWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 단폴 베팅-당첨  */}
            <td>
              {user.sportsOneFolderBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsOneFolderWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 2폴 베팅-당첨  */}
            <td>
              {user.sportsTwoFolderBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsTwoFolderWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 3폴 베팅-당첨  */}
            <td>
              {user.sportsThreeFolderBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsThreeFolderWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 4폴 베팅-당첨  */}
            <td>
              {user.sportsFourFolderBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsFourFolderWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 5폴 베팅-당첨  */}
            <td>
              {user.sportsFiveFolderBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsFiveFolderWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 다폴 베팅-당첨  */}
            <td>
              {user.sportsMultiFolderBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsMultiFolderWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 가상축구 베팅-당첨  */}
            <td>
              {user.virtualSoccerGameBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualSoccerGameWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 가상야구 베팅-당첨  */}
            <td>
              {user.virtualBaseballGameBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualBaseballGameWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 가상농구 베팅-당첨  */}
            <td>
              {user.virtualBasketballGameBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualBasketballGameWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 가상경마 베팅-당첨  */}
            <td>
              {user.virtualHorseGameBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualHorseGameWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 개경주 베팅-당첨  */}
            <td>
              {user.virtualGreyhoundsGameBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualGreyhoundsGameWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
            <td>
              {user.holdemBettingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.holdemWinningAmount?.toString().replace(commonReg2, ',')}
            </td>
          </>
        )}
        {isActiveRollingInfo && (
          <>
            {/* 롤링 요율 */}
            {/* 라이브 / 슬롯 / 미니일반 / 미니조합/ 스포츠 단폴 / 스포츠 2폴/ 스포츠 3폴/ 스포츠 4폴/ 스포츠 5폴/ 스포츠 다폴 */}
            <td>
              <CustomButton
                style={{ background: 'orange' }}
                onClick={() => {
                  setModalShow(true)
                  setWhatModalShow('ROLLING')
                  setModalUserId(user.userId)
                }}
              >
                설정
              </CustomButton>
              <br />
              {user.casinoSlotRollingPer} / {user.casinoLiveRollingPer}/ {user.miniGameSingleRollingPer}/{' '}
              {user.miniGameCombinationRollingPer}/ {user.sportsOneFolderRollingPer}/ {user.sportsTwoFolderRollingPer}/{' '}
              {user.sportsThreeFolderRollingPer}/ {user.sportsFourFolderRollingPer}/ {user.sportsFiveFolderRollingPer}/{' '}
              {user.sportsMultiFolderRollingPer}/ {user.virtualSoccerGameRollingPer}/{' '}
              {user.virtualBaseballGameRollingPer}/ {user.virtualBasketballGameRollingPer}/{' '}
              {user.virtualHorseGameRollingPer}/ {user.virtualGreyhoundsGameRollingPer}/ {user.holdemRollingPer}
            </td>
            {/* 슬롯 / 라이브 롤링액  */}
            <td>
              {user.casinoSlotTotalRollingAmount?.toString().replace(commonReg2, ',')}
              <BrDiv /> {user.casinoLiveTotalRollingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 미니게임 일반 / 미니게임 조합 롤링액 */}
            <td>
              {user.miniGameSingleTotalRollingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.miniGameCombinationTotalRollingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 단폴 / 스포츠 2폴 롤링액 */}
            <td>
              {user.sportsOneFolderTotalRollingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsTwoFolderTotalRollingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 3폴 / 스포츠 4폴 롤링액 */}
            <td>
              {user.sportsThreeFolderTotalRollingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsFourFolderTotalRollingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 5폴 / 스포츠 디폴 롤링액 */}
            <td>
              {user.sportsFiveFolderTotalRollingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsMultiFolderTotalRollingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 가상축구/야구 롤링액 */}
            <td>
              {user.virtualSoccerGameTotalRollingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualBaseballGameTotalRollingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 가상농구/경마 롤링액 */}
            <td>
              {user.virtualBasketballGameTotalRollingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualHorseGameTotalRollingAmount?.toString().replace(commonReg2, ',')}
            </td>
            <td>{user.virtualGreyhoundsGameTotalRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <td>{user.holdemTotalRollingAmount?.toString().replace(commonReg2, ',')}</td>
          </>
        )}
        {isActiveLosingInfo && (
          <>
            {/* 루징 요율 */}
            {/* 라이브 / 슬롯 / 미니일반 / 미니조합/ 스포츠 단폴 / 스포츠 2폴/ 스포츠 3폴/ 스포츠 4폴/ 스포츠 5폴/ 스포츠 다폴 / 입금-출금 */}
            <td>
              {user.userAuthority !== 'MEMBER' && (
                <CustomButton
                  style={{ background: '#7d91f5' }}
                  onClick={() => {
                    setModalShow(true)
                    setWhatModalShow('LOSING')
                    setModalUserId(user.userId)
                  }}
                >
                  설정
                </CustomButton>
              )}
              <br />
              {user.depositMinusWithdrawalLosingPer} /{user.casinoSlotBettingMinusWinningLosingPer} /
              {user.casinoLiveBettingMinusWinningLosingPer} / {user.miniGameSingleBettingMinusWinningLosingPer}/{' '}
              {user.miniGameCombinationBettingMinusWinningLosingPer}/ {user.sportsOneFolderBettingMinusWinningLosingPer}
              / {user.sportsTwoFolderBettingMinusWinningLosingPer}/ {user.sportsThreeFolderBettingMinusWinningLosingPer}
              / {user.sportsFourFolderBettingMinusWinningLosingPer}/ {user.sportsFiveFolderBettingMinusWinningLosingPer}
              / {user.sportsMultiFolderBettingMinusWinningLosingPer}/{' '}
              {user.virtualSoccerGameBettingMinusWinningLosingPer}/{' '}
              {user.virtualBaseballGameBettingMinusWinningLosingPer}/{' '}
              {user.virtualBasketballGameBettingMinusWinningLosingPer}/{' '}
              {user.virtualHorseGameBettingMinusWinningLosingPer}/{' '}
              {user.virtualGreyhoundsGameBettingMinusWinningLosingPer}
            </td>
            {/* 입금-출금 루징액 */}
            <td>{user.totalLosingAmount?.toString().replace(commonReg2, ',')}</td>
            {/* 라이브 / 슬롯 (배팅-당첨) 루징액 */}
            <td>
              {user.casinoSlotTotalLosingAmount?.toString().replace(commonReg2, ',')}
              <BrDiv /> {user.casinoLiveTotalLosingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 미니게임 일반 / 미니게임 조합 (배팅-당첨) 루징액 */}
            <td>
              {user.miniGameSingleTotalLosingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.miniGameCombinationTotalLosingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 단폴 / 스포츠 2폴 (배팅-당첨) 루징액 */}
            <td>
              {user.sportsOneFolderTotalLosingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsTwoFolderTotalLosingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 3폴 / 스포츠 4폴 (배팅-당첨) 루징액 */}
            <td>
              {user.sportsThreeFolderTotalLosingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsFourFolderTotalLosingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 5폴 / 스포츠 디폴 (배팅-당첨) 루징액 */}
            <td>
              {user.sportsFiveFolderTotalLosingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.sportsMultiFolderTotalLosingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 단폴 / 스포츠 2폴 (배팅-당첨) 루징액 */}
            <td>
              {user.virtualSoccerGameTotalLosingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualBaseballGameTotalLosingAmount?.toString().replace(commonReg2, ',')}
            </td>
            {/* 스포츠 3폴 / 스포츠 4폴 (배팅-당첨) 루징액 */}
            <td>
              {user.virtualBasketballGameTotalLosingAmount?.toString().replace(commonReg2, ',')} <BrDiv />{' '}
              {user.virtualHorseGameTotalLosingAmount?.toString().replace(commonReg2, ',')}
            </td>
            <td>{user.virtualGreyhoundsGameTotalLosingAmount?.toString().replace(commonReg2, ',')}</td>
          </>
        )}
      </CustomTr>
      {user.isOpened && user.subUsersCount > 0 ? (
        <>
          {user.subUserInfos?.map((subUser, idx) => (
            <DisplayUserComponent
              key={subUser.userKey}
              selectedUserId={selectedUserId}
              user={subUser}
              depth={depth + 1}
              toggleIsOpened={toggleIsOpened}
              isActiveDefaultInfo={isActiveDefaultInfo}
              isActiveRollingInfo={isActiveRollingInfo}
              isActiveBettingInfo={isActiveBettingInfo}
              isActiveLosingInfo={isActiveLosingInfo}
              setModalShow={setModalShow}
              setModalUserId={setModalUserId}
              setWhatModalShow={setWhatModalShow}
            />
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

DisplayUserComponent.propTypes = {
  user: PropTypes.shape(),
  selectedUserId: PropTypes.string,
  depth: PropTypes.number,
  fetchSettlementStatisticsBasic: PropTypes.func,
  toggleIsOpened: PropTypes.func,
  isActiveDefaultInfo: PropTypes.bool,
  isActiveRollingInfo: PropTypes.bool,
  isActiveLosingInfo: PropTypes.bool,
  isActiveBettingInfo: PropTypes.bool,
  setModalShow: PropTypes.func,
  setModalUserId: PropTypes.func,
  setWhatModalShow: PropTypes.func,
}

DisplayUserComponent.defaultProps = {
  user: {},
  selectedUserId: '',
  depth: 0,
  fetchSettlementStatisticsBasic: () => {},
  toggleIsOpened: () => {},
  isActiveDefaultInfo: true,
  isActiveRollingInfo: false,
  isActiveLosingInfo: false,
  isActiveBettingInfo: false,
  setModalShow: () => {},
  setModalUserId: () => {},
  setWhatModalShow: () => {},
}

const SettlementStatistics = () => {
  const dispatch = useDispatch()

  const { edgeNames, userSettlementList, apiLoading, selectedUserId } = useSelector(state => {
    const { headerInfo, userInfo } = state

    return {
      edgeNames: headerInfo.edgeNames,
      userSettlementList: userInfo.userSettlementList,
      apiLoading: userInfo.apiLoading,
      selectedUserId: userInfo.selectedUserId,
    }
  })
  const [selectedEdgeName, setSelectedEdgeName] = useState()

  useEffect(() => {
    if (selectedEdgeName) return
    setSelectedEdgeName(edgeNames.length > 0 ? edgeNames[0] : '')
  }, [edgeNames, selectedEdgeName])
  /**
   * 파트너 조회
   */
  const today = new Date()
  const endDate = new Date()
  const startDate = new Date()

  // endDate 설정
  endDate.setDate(today.getDate() + 1)
  endDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정

  // startDate 설정
  startDate.setMonth(endDate.getMonth() - 1)
  startDate.setHours(0, 0, 0, 0) // 시간, 분, 초, 밀리초를 0으로 설정

  const [params, setParams] = useState({
    edgeName: selectedEdgeName,
    userId: null,
    topUserId: null,
    startDate: convertToKst(startDate),
    endDate: convertToKst(endDate),
  })

  const handleToggleIsOpened = userId => {
    dispatch(handleToggle({ userId }))
  }

  useEffect(() => {
    if (!selectedUserId) return
    setParams(prev => ({
      ...prev,
      topUserId: selectedUserId,
    }))
  }, [selectedUserId])

  useEffect(() => {
    if (!selectedEdgeName) return
    const fetchData = async () => {
      try {
        const res = await dispatch(fetchSettlementStatisticsBasic(params)).unwrap()
        // .unwrap()는 createAsyncThunk에 의해 반환된 Promise가 reject될 경우 에러를 throw하게 만듭니다.
        const userIdList = res.content.map(item => item.userId).join(',')
        const newParms = {
          userIdList,
          startDate: params.startDate,
          endDate: params.endDate,
        }
        dispatch(fetchSettlementStatisticsSpecify(newParms))
      } catch (error) {
        console.error('Failed to fetch data:', error)
      }
    }

    fetchData()
  }, [params])

  // 아이디, 권한, 상태, 등록일
  // 아이디
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate)
  const [searchEndDate, setSearchEndDate] = useState(endDate)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  useEffect(() => {
    if (!selectedEdgeName) return
    dispatch(resetSettlementData())
    setParams(prev => ({
      ...prev,
      topUserId: null,
      edgeName: selectedEdgeName,
    }))
  }, [selectedEdgeName])

  const adjustTimes = date => {
    if (!date) return
    const adjustedDate = new Date(date)
    adjustedDate.setHours(0, 0, 0) // 초만 59로 조정
    return adjustedDate
  }
  // 검색하기 버튼
  const onSearchClickHandler = () => {
    dispatch(resetSettlementData())
    const adjustedStartDate = adjustTimes(searchStartDate)
    const adjustedEndDate = adjustTimes(searchEndDate)
    setParams(prev => ({
      ...prev,
      topUserId: null,
      userId: !searchUserId ? null : searchUserId.trim(),
      startDate: !searchStartDate ? null : convertToKst(adjustedStartDate),
      endDate: !searchEndDate ? null : convertToKst(adjustedEndDate),
    }))
  }

  // 기본정보
  const [isActiveDefaultInfo, setIsActiveDefaultInfo] = useState(true)
  // 롤링 요율
  const [isActiveRollingInfo, setIsActiveRollingInfo] = useState(true)
  // 루징 요율
  const [isActiveLosingInfo, setIsActiveLosingInfo] = useState(true)
  // 배팅/당첨
  const [isActiveBettingInfo, setIsActiveBettingInfo] = useState(true)
  const [isModalUserId, setModalUserId] = useState('')
  const [isModalShow, setModalShow] = useState(false)
  const onModalClickHandler = () => {
    setModalShow(prev => !prev)
    dispatch(resetSettlementData())
    const adjustedStartDate = adjustTimes(searchStartDate)
    const adjustedEndDate = adjustTimes(searchEndDate)
    setParams(prev => ({
      ...prev,
      topUserId: null,
      userId: !searchUserId ? null : searchUserId.trim(),
      startDate: !searchStartDate ? null : convertToKst(adjustedStartDate),
      endDate: !searchEndDate ? null : convertToKst(adjustedEndDate),
    }))
  }

  const [whatModalShow, setWhatModalShow] = useState(false)
  const onWhatModalClickHandler = what => {
    setWhatModalShow(what)
  }
  return (
    <>
      <Card>
        <CardBody style={{ paddingBottom: '0px' }}>
          <StatisticsTabs activeKey="1" />
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-50px', marginBottom: '10px' }}>
              <p>
                * 전체를 확인하시려면 <span style={{ color: 'red' }}>날짜검색</span>을 이용하시면 됩니다. (
                <span style={{ color: 'red' }}>보유자산</span>만은 현 시점입니다.){' '}
              </p>
              <p>
                * 라이브 배팅액/당첨액은{' '}
                <span style={{ fontWeight: 'bolder', color: 'red' }}>타이포함, 공배팅 제외</span> 한 금액입니다.
              </p>
            </div>
          </SearchCardTitleWrap>

          {/* 검색 기능 */}
          <SearchContainer horizontal>
            <EdgeSearchButton
              isAll={false}
              edgeName={selectedEdgeName}
              onClickHandler={e => {
                setSelectedEdgeName(e.target.id)
              }}
            />
            <TableColumnSearchButtonBox>
              <Button
                size="sm"
                active={isActiveDefaultInfo}
                variant="outline-secondary"
                onClick={e => {
                  setIsActiveDefaultInfo(prev => !prev)
                }}
              >
                기본정보
              </Button>
              <Button
                id="PARTNER"
                size="sm"
                active={isActiveBettingInfo}
                variant="outline-secondary"
                onClick={e => {
                  setIsActiveBettingInfo(prev => !prev)
                }}
              >
                베팅정보
              </Button>
              <Button
                id="MEMBER"
                size="sm"
                active={isActiveRollingInfo}
                variant="outline-secondary"
                onClick={e => {
                  setIsActiveRollingInfo(prev => !prev)
                }}
              >
                롤링정보
              </Button>
              <Button
                id="PARTNER"
                size="sm"
                active={isActiveLosingInfo}
                variant="outline-secondary"
                onClick={e => {
                  setIsActiveLosingInfo(prev => !prev)
                }}
              >
                루징정보
              </Button>
            </TableColumnSearchButtonBox>
            <SearchGroup>
              <SearchGroupLabel>유저 ID/명</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchUserId"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchUserId}
                    onChange={e => onSearchUserIdHandler(e)}
                  />
                  <input style={{ display: 'none' }} type="text" />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm:00"
                    dropDownMode="select"
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap style={{ marginLeft: '5px' }}>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>

          {apiLoading ? (
            <Loading />
          ) : (
            <TableWrap>
              <table>
                <thead>
                  <tr>
                    <th style={{ borderLeft: 'solid 1px black' }} scope="col" rowSpan={3}>
                      유저ID(명)
                    </th>
                    <th scope="col" rowSpan={3}>
                      사이트명
                    </th>
                    <th scope="col" rowSpan={3}>
                      권한
                    </th>
                    <th scope="col" rowSpan={3}>
                      정산방식
                    </th>
                    {isActiveDefaultInfo && (
                      <th style={{ borderBottom: 'none' }} scope="col" colSpan={14}>
                        기본 정보
                      </th>
                    )}
                    {isActiveBettingInfo && (
                      <BettingTh style={{ borderBottom: 'none' }} scope="col" colSpan={16}>
                        베팅 / 당첨
                      </BettingTh>
                    )}
                    {isActiveRollingInfo && (
                      <RollingTh style={{ borderBottom: 'none' }} scope="col" colSpan={10}>
                        롤링
                      </RollingTh>
                    )}

                    {isActiveLosingInfo && (
                      <LosingTh style={{ borderBottom: 'none' }} scope="col" colSpan={11}>
                        루징
                      </LosingTh>
                    )}
                  </tr>
                  <tr>
                    {isActiveDefaultInfo && (
                      <>
                        <th scope="col" rowSpan={2}>
                          자산 증감
                        </th>
                        <th scope="col" rowSpan={2}>
                          지갑 머니
                        </th>
                        <th scope="col" rowSpan={2}>
                          카지노 머니
                        </th>
                        <th scope="col" rowSpan={2}>
                          홀덤 머니
                        </th>
                        <th scope="col" rowSpan={2}>
                          롤링 포인트
                        </th>
                        <th scope="col" rowSpan={2}>
                          루징 포인트
                        </th>
                        <th style={{ borderBottom: 'none' }} scope="col" colSpan={8}>
                          레벨
                        </th>
                      </>
                    )}
                    {isActiveBettingInfo && (
                      <>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          슬롯 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          라이브 베팅
                        </BettingTh>

                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          미니단폴 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          미니조합 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠단폴 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠2폴 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠3폴 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠4폴 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠5폴 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠다폴 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          가상축구 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          가상야구 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          가상농구 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          가상경마 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          개경주 베팅
                        </BettingTh>
                        <BettingTh style={{ borderBottom: 'none' }} scope="col">
                          홀덤 베팅
                        </BettingTh>
                      </>
                    )}
                    {isActiveRollingInfo && (
                      <>
                        <RollingTh scope="col" rowSpan={2}>
                          롤링요율
                        </RollingTh>
                        <RollingTh style={{ borderBottom: 'none' }} scope="col">
                          슬롯
                        </RollingTh>
                        <RollingTh style={{ borderBottom: 'none' }} scope="col">
                          미니 일반
                        </RollingTh>
                        <RollingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠 단폴
                        </RollingTh>
                        <RollingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠 3폴
                        </RollingTh>
                        <RollingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠 5폴
                        </RollingTh>
                        <RollingTh style={{ borderBottom: 'none' }} scope="col">
                          가상축구
                        </RollingTh>
                        <RollingTh style={{ borderBottom: 'none' }} scope="col">
                          가상 농구
                        </RollingTh>
                        <RollingTh scope="col" rowSpan={2}>
                          개경주
                        </RollingTh>
                        <RollingTh scope="col" rowSpan={2}>
                          홀덤
                        </RollingTh>
                      </>
                    )}
                    {isActiveLosingInfo && (
                      <>
                        <LosingTh scope="col" rowSpan={2}>
                          루징요율
                        </LosingTh>
                        <LosingTh scope="col" rowSpan={2}>
                          입금-출금
                        </LosingTh>
                        <LosingTh style={{ borderBottom: 'none' }} scope="col">
                          슬롯
                        </LosingTh>
                        <LosingTh style={{ borderBottom: 'none' }} scope="col">
                          미니 일반
                        </LosingTh>
                        <LosingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠 단폴
                        </LosingTh>
                        <LosingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠 3폴
                        </LosingTh>
                        <LosingTh style={{ borderBottom: 'none' }} scope="col">
                          스포츠 5폴
                        </LosingTh>
                        <LosingTh style={{ borderBottom: 'none' }} scope="col">
                          가상축구
                        </LosingTh>
                        <LosingTh style={{ borderBottom: 'none' }} scope="col">
                          가상농구
                        </LosingTh>
                        <LosingTh scope="col" rowSpan={2}>
                          개경주
                        </LosingTh>
                      </>
                    )}
                  </tr>
                  <tr>
                    {isActiveDefaultInfo && (
                      <>
                        <th scope="col">입금</th>
                        <th scope="col">슬롯</th>
                        <th scope="col">라이브</th>
                        <th scope="col">미니</th>
                        <th scope="col">스포츠</th>
                        <th scope="col">가상</th>
                        <th scope="col">홀덤</th>
                        <th scope="col">추천인</th>
                      </>
                    )}
                    {isActiveBettingInfo && (
                      <>
                        <BettingTh scope="col">슬롯 당첨</BettingTh>
                        <BettingTh scope="col">라이브 당첨</BettingTh>

                        <BettingTh scope="col">미니단폴 당첨</BettingTh>
                        <BettingTh scope="col">미니조합 당첨</BettingTh>
                        <BettingTh scope="col">스포츠단폴 당첨</BettingTh>
                        <BettingTh scope="col">스포츠2폴 당첨</BettingTh>
                        <BettingTh scope="col">스포츠3폴 당첨</BettingTh>
                        <BettingTh scope="col">스포츠4폴 당첨</BettingTh>
                        <BettingTh scope="col">스포츠5폴 당첨</BettingTh>
                        <BettingTh scope="col">스포츠다폴 당첨</BettingTh>
                        <BettingTh scope="col">가상축구 당첨</BettingTh>
                        <BettingTh scope="col">가상야구 당첨</BettingTh>
                        <BettingTh scope="col">가상농구 당첨</BettingTh>
                        <BettingTh scope="col">가상경마 당첨</BettingTh>
                        <BettingTh scope="col">개경주 당첨</BettingTh>
                        <BettingTh scope="col">홀덤 당첨</BettingTh>
                      </>
                    )}
                    {isActiveRollingInfo && (
                      <>
                        <RollingTh scope="col">라이브</RollingTh>
                        <RollingTh scope="col">미니 조합</RollingTh>
                        <RollingTh scope="col">스포츠 2폴</RollingTh>
                        <RollingTh scope="col">스포츠 4폴</RollingTh>
                        <RollingTh scope="col">스포츠 다폴</RollingTh>
                        <RollingTh scope="col">가상 야구</RollingTh>
                        <RollingTh scope="col">가상 경마</RollingTh>
                      </>
                    )}
                    {isActiveLosingInfo && (
                      <>
                        <LosingTh scope="col">라이브</LosingTh>
                        <LosingTh scope="col">미니 조합</LosingTh>
                        <LosingTh scope="col">스포츠 2폴</LosingTh>
                        <LosingTh scope="col">스포츠 4폴</LosingTh>
                        <LosingTh scope="col">스포츠 다폴</LosingTh>
                        <LosingTh scope="col">가상 야구</LosingTh>
                        <LosingTh scope="col">가상 경마</LosingTh>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {userSettlementList?.map((user, index) => (
                    <DisplayUserComponent
                      key={user.userKey}
                      selectedUserId={selectedUserId}
                      user={user}
                      toggleIsOpened={handleToggleIsOpened}
                      isActiveDefaultInfo={isActiveDefaultInfo}
                      isActiveRollingInfo={isActiveRollingInfo}
                      isActiveLosingInfo={isActiveLosingInfo}
                      isActiveBettingInfo={isActiveBettingInfo}
                      setModalShow={setModalShow}
                      setModalUserId={setModalUserId}
                      setWhatModalShow={setWhatModalShow}
                    />
                  ))}
                </tbody>
              </table>

              {userSettlementList.length === 0 && <NoDataBox>데이터가 없습니다.</NoDataBox>}
            </TableWrap>
          )}
        </CardBody>
        <CustomModal show={isModalShow} onHide={onModalClickHandler} $header>
          <CustomModalHeader>
            <CustomModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={onModalClickHandler}
            />
            {
              {
                ASSET: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 자산 증감</CustomModalTitle>
                  </>
                ),
                ROLLING: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 롤링정보 수정</CustomModalTitle>
                  </>
                ),
                LOSING: (
                  <>
                    <CustomModalTitle>{isModalUserId} 유저 루징정보 수정</CustomModalTitle>
                  </>
                ),
              }[whatModalShow]
            }
          </CustomModalHeader>
          <CustomModalBody>
            {
              {
                ASSET: (
                  <>
                    <MemberHistoryMoneyIncDec memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
                ROLLING: (
                  <>
                    <MemberRollingInfoPage memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
                LOSING: (
                  <>
                    <MemberLosingInfoPage memberId={isModalUserId} onClickHandler={onModalClickHandler} />
                  </>
                ),
              }[whatModalShow]
            }
          </CustomModalBody>
        </CustomModal>
      </Card>
    </>
  )
}

export default SettlementStatistics

const CustomModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 1250px !important;
  }
`

const CustomButton = styled.button`
  cursor: pointer;
  border: none;
  color: #fff;
`

const CustomTr = styled.tr`
  &:hover {
    background: ${props => (props.depth === 0 ? '#fff2d3' : '#ebebeb')} !important;
  }
  border-left: solid 1px black !important;
`

const MainTd = styled.td`
  font-weight: 600 !important;
  font-size: 13px;
`

const BrDiv = styled.div`
  border-top: 1px solid #ddd;
  margin: 0px 3px;
`

const BettingTh = styled.th`
  background: #dfa7f5 !important;
`

const RollingTh = styled.th`
  background: #fcb65b !important;
`

const LosingTh = styled.th`
  background: #aab6f5 !important;
`
const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  ${scrollbarStyles};
  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: separate; /* Don't collapse */
    border-spacing: 0;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    white-space: nowrap;
  }

  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
    // z-index: 1; /* z-index 추가 */
  }

  thead tr {
    border: none;
  }

  thead tr th {
    border: none;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
    // &:first-child {
    //   border-left: solid 1px red;
    // }
  }

  tbody {
    padding-top: 10px;
    // height: 300px;
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
    border: none;
  }

  tbody tr td {
    border: none;
    border-bottom: solid 1px black;
    border-right: solid 1px black;
  }

  th {
    background-color: lightgray;
    padding: 2px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 300;
    color: black;
    font-size: 12px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      // background: transparent;
    }
  }
`

const PlusSquare = styled(AiOutlinePlusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  cursor: pointer;
  margin: 0 2px;
`

const MinusSquare = styled(AiOutlineMinusSquare)`
  color: blue;
  width: 25px;
  height: auto;
  cursor: pointer;
  margin: 0 2px;
`
const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`

const TableColumnSearchButtonBox = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;
  margin-left: 20px;
  button {
    height: 30px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding-left: 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`

const NoDataBox = styled.div`
  width: 100% !important;
  min-height: 150px;
  // border: 1px solid #888;
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 20px;
  line-height: 25px;
  font-weight: bold;
`
