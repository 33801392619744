import React, { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { MemberPointDepositReceiverTypeEnums } from '../../../enums/MemberHistory/MemberPointDepositEnums'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { MemberLosingTypeEnum } from '../../../enums/Statistics/memberLosingEnums'
import { LosingBettingTypeEnums, RollingBettingTypeEnums } from '../../../enums/PartnerHistory/RollingSettlementEnums'
import MiniGameBettingInfo from '../UserBettingModal/MiniGameBettingInfo'
import SportBettingInfo from '../UserBettingModal/SportBettingInfo'
import CasinoBettingInfo from '../UserBettingModal/CasinoBettingInfo'
import MoneyDepositWithdrawalInfo from '../UserBettingModal/MoneyDepositWithdrawalInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import HoldemBettingInfo from '../UserBettingModal/HoldemBettingInfo'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import SportBettingInfoV2 from '../UserBettingModal/SportBettingInfoV2'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'
import VirtualGameBettingInfo from '../UserBettingModal/VirtualGameBettingInfo'
import WildGamesBettingInfo from '../UserBettingModal/WildGamesBettingInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const PartnerLosingPointDepositListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '루징정산 방식',
      accessor: 'losingSettlementType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ color: 'red' }}>{MemberLosingTypeEnum[original.losingSettlementType]}</span>
      },
    },
    {
      Header: '유저 ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.memberId}({original.memberName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.memberId} />
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
    },
    {
      Header: '루징 발생 유저',
      accessor: 'executeUserId',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.receiverType.includes('MINI')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.executeUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.executeUserId}
              </span>
              <MiniGameBettingInfo
                bettingUserId={original.executeUserId}
                bettingLogKey={original.executeLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        if (original.receiverType.includes('SPORTS')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.executeUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.executeUserId}
              </span>
              <SportBettingInfoV2
                bettingUserId={original.executeUserId}
                bettingLogKey={original.executeLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        if (original.receiverType.includes('CASINO')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.executeUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.executeUserId}
              </span>
              <CasinoBettingInfo
                bettingUserId={original.executeUserId}
                bettingLogKey={original.executeLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        if (original.receiverType.includes('HOLDEM')) {
          if (original.agencyName === 'WILD_GAMES') {
            return (
              <span>
                <span
                  onClick={() => {
                    window.open(`/user-info/${original.executeUserId}`, '_blank')
                  }}
                  style={{
                    color: '#2663a3',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    width: 'maxContent',
                  }}
                >
                  {original.executeUserId}
                </span>
                <WildGamesBettingInfo
                  bettingUserId={original.executeUserId}
                  bettingLogKey={original.executeLogKey}
                  receiverType={original.receiverType}
                />
              </span>
            )
          }

          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.executeUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.executeUserId}
              </span>
              <HoldemBettingInfo
                bettingUserId={original.executeUserId}
                bettingLogKey={original.executeLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        if (original.receiverType.includes('VIRTUAL_GAME')) {
          return (
            <span>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.executeUserId}`, '_blank')
                }}
                style={{
                  color: '#2663a3',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  width: 'maxContent',
                }}
              >
                {original.executeUserId}
              </span>
              <VirtualGameBettingInfo
                bettingUserId={original.executeUserId}
                bettingLogKey={original.executeLogKey}
                receiverType={original.receiverType}
              />
            </span>
          )
        }
        return (
          <span>
            <span
              onClick={() => {
                window.open(`/user-info/${original.executeUserId}`, '_blank')
              }}
              style={{
                color: '#2663a3',
                textDecoration: 'underline',
                cursor: 'pointer',
                width: 'maxContent',
              }}
            >
              {original.executeUserId}
            </span>
            <MoneyDepositWithdrawalInfo
              bettingUserId={original.executeUserId}
              bettingLogKey={original.executeLogKey}
              receiverType={original.receiverType}
            />
          </span>
        )
      },
    },
    {
      Header: '계산 루징%',
      accessor: 'losingPer',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ color: 'black', fontWeight: '600' }}>{original.losingPer}%</span>
      },
    },
    {
      Header: '루징 타입',
      accessor: 'rollingBettingType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'blue', fontWeight: '500' }}>
            {LosingBettingTypeEnums[original.rollingBettingType]}
          </span>
        )
      },
    },
    {
      Header: '구분',
      accessor: 'receiverType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MemberPointDepositReceiverTypeEnums[original.receiverType]
      },
    },
    {
      Header: '변경전',
      accessor: 'beforePoint',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.beforePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '루징포인트 입금액',
      accessor: 'changePointDeposit',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.againSettlementType) {
          return (
            <TooltipWrapper text="재정산" place="bottom">
              <span style={{ color: '#0022fc', fontWeight: '600' }}>
                {original.changePoint.toString().replace(commonReg2, ',')}
              </span>
            </TooltipWrapper>
          )
        }
        return original.changePoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '변경후',
      accessor: 'afterPoint',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.afterPoint.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default PartnerLosingPointDepositListData
