import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  CustomDepositStyledModal,
} from '@/shared/components/CustomModal'
import { FaPlus } from 'react-icons/fa'
import Tooltip from '@/shared/components/Tooltip'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { NoteButton, CustomBtnTableWrap } from '../../../../shared/components/TableElements'
import RegistrationDownUserForm from './RegistrationDownUserForm'

function RegistrationDownUser({ userInfo }) {
  const [isMemberNoteModalShow, setMemberNoteModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberNoteModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <NoteButton role={Button} onClick={() => onClickHandler()}>
        <Tooltip text="하부(+)" place="bottom">
          <button
            type="button"
            style={{ border: 'none', width: 'maxContent', color: '#fff', background: '#6ca0f5', marginLeft: '5px' }}
          >
            하부(+)
          </button>
        </Tooltip>
      </NoteButton>
      <CustomDepositStyledModal show={isMemberNoteModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>{userInfo.userId}의 하부 유저생성 </CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <RegistrationDownUserForm userInfo={userInfo} onClickHandler={onClickHandler} />
        </CustomModalBody>
      </CustomDepositStyledModal>
    </CustomBtnTableWrap>
  )
}

export default RegistrationDownUser

RegistrationDownUser.propTypes = {
  userInfo: PropTypes.shape({
    userId: PropTypes.string,
    userName: PropTypes.string,
    partnerLevel: PropTypes.string,
    edgeName: PropTypes.string,
  }),
}

RegistrationDownUser.defaultProps = {
  userInfo: null,
}

const PlusIcon = styled(FaPlus)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
