import jwtDecode from 'jwt-decode'

// AAT_DM : Admin Access Token Tyson Demo
export function setAccessToken(accessToken) {
  localStorage.setItem('AAT_TD', accessToken)
}

export const getAccessToken = () => {
  return localStorage.getItem('AAT_TD')
}

export const deleteAccessToken = () => {
  localStorage.removeItem('AAT_TD')
}

export const decodeAccessToken = () => {
  const accessToken = getAccessToken()

  if (!accessToken) return null

  return jwtDecode(accessToken)
}
