import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import Switch from 'react-switch'
import styled from 'styled-components'
import { MemberResterBankSelect } from '../../../../shared/components/CustomSelect'
import Loading from '../../../../shared/components/Loading'
import { searchNtryMiniGameManagement, updateNtryMiniGame } from '../../../../utils/api/systemManage/miniGameManageApi'
import { decodeAccessToken } from '../../../../utils/token'
import { priceNumPattern } from '../../../../utils/sportUtills'

const BePickPowerBall = ({ miniGameType }) => {
  const authority = decodeAccessToken()?.adminAuthority

  const [loading, setLoading] = useState(false)

  const [isBettingOption, setBettingOption] = useState(0)
  // 마감시간
  const [isEndTime, setEndTime] = useState(0)
  const [bettingPerModel, setBettingPerModel] = useState({})

  // 노출 여부
  const [isShowOptionValue, setIsShowOptionValue] = useState(null)
  const isShowOption = [
    {
      value: true,
      label: '정상',
    },
    {
      value: false,
      label: '점검',
    },
  ]

  const fetchMiniGameManagement = () => {
    setLoading(true)

    searchNtryMiniGameManagement({
      miniGameType,
    })
      .then(res => {
        const { show, endTime, bettingOption, bettingPerModel } = res.data

        setIsShowOptionValue({
          value: show,
          label: show ? '정상' : '점검',
        })

        setEndTime(endTime)
        setBettingOption(bettingOption)
        setBettingPerModel(JSON.parse(bettingPerModel))
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onChangeStatusHandler = option => {
    setIsShowOptionValue(option)
  }

  useEffect(() => {
    fetchMiniGameManagement()
  }, [miniGameType])

  const onSubmit = e => {
    if (loading) return

    if (window.confirm('설정을 변경 하시겠습니까? ')) {
      const body = {
        miniGameType,
        show: isShowOptionValue.value,
        endTime: isEndTime,
        bettingOption: isBettingOption,
        bettingPerModel: JSON.stringify(bettingPerModel),
      }

      setLoading(true)

      updateNtryMiniGame(body)
        .then(res => {
          alert('설정이 변경됐습니다.')
        })
        .catch(error => {
          alert('변경 실패했습니다. 다시 시도해주세요.')
        })
        .finally(() => {
          setLoading(false)
          fetchMiniGameManagement()
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <Form
        onSubmit={onSubmit}
        initialValues={{
          bettingPerModel,
          isBettingOption,
          isEndTime,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <MiniGameForm horizontal onSubmit={handleSubmit}>
            <MiniGameFormInnerBox>
              <table>
                <tr>
                  <th scope="row">상태</th>
                  <td>
                    <Field name="isShowOptionValue">
                      {({ input, meta }) => (
                        <MemberResterBankSelect
                          {...input}
                          value={isShowOptionValue}
                          onChange={option => {
                            onChangeStatusHandler(option)
                          }}
                          options={isShowOption}
                          className="react-select"
                          classNamePrefix="react-select"
                        />
                      )}
                    </Field>
                  </td>

                  <th scope="row">동일라운드 배팅횟수</th>
                  <td>
                    <Field name="isBettingOption">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={isBettingOption}
                          onChange={e => {
                            setBettingOption(e.target.value)
                          }}
                        />
                      )}
                    </Field>
                  </td>

                  <th scope="row">마감 시간 (초)</th>
                  <td>
                    <Field name="isEndTime">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={isEndTime}
                          onChange={e => {
                            setEndTime(e.target.value)
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">파워볼 홀짝</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedPowerOddEven: !bettingPerModel?.usedPowerOddEven ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedPowerOddEven ?? false}
                    />
                  </td>
                  <th scope="row">파워볼 홀</th>
                  <td>
                    <Field name="powerOddPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerOddPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerOddPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 짝</th>
                  <td>
                    <Field name="powerEvenPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerEvenPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerEvenPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">파워볼 언더오버</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedPowerUnderOver: !bettingPerModel?.usedPowerUnderOver ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedPowerUnderOver ?? false}
                    />
                  </td>
                  <th scope="row">파워볼 언더</th>
                  <td>
                    <Field name="powerUnderPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerUnderPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerUnderPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 오버</th>
                  <td>
                    <Field name="powerOverPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerOverPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerOverPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">일반볼 홀짝</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedNormalOddEven: !bettingPerModel?.usedNormalOddEven ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedNormalOddEven ?? false}
                    />
                  </td>
                  <th scope="row">일반볼 홀</th>
                  <td>
                    <Field name="normalOddPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalOddPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalOddPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 짝</th>
                  <td>
                    <Field name="normalEvenPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalEvenPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalEvenPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">일반볼 언더오버</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedNormalUnderOver: !bettingPerModel?.usedNormalUnderOver ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedNormalUnderOver ?? false}
                    />
                  </td>
                  <th scope="row">일반볼 언더</th>
                  <td>
                    <Field name="normalUnderPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalUnderPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalUnderPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 오버</th>
                  <td>
                    <Field name="normalOverPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalOverPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalOverPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">일반볼 구간(1)</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedNormalLms: !bettingPerModel?.usedNormalLms ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedNormalLms ?? false}
                    />
                  </td>
                  <th scope="row">일반볼 대</th>
                  <td>
                    <Field name="normalLargePer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalLargePer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalLargePer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 중</th>
                  <td>
                    <Field name="normalMediumPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalMediumPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalMediumPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 소</th>
                  <td>
                    <Field name="normalSmallPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalSmallPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalSmallPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">일반볼 구간(2)</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedNormalSection: !bettingPerModel?.usedNormalSection ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedNormalSection ?? false}
                    />
                  </td>
                  <th scope="row">일반볼 구간A</th>
                  <td>
                    <Field name="normalSectionAPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalSectionAPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalSectionAPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 구간B</th>
                  <td>
                    <Field name="normalSectionBPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalSectionBPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalSectionBPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 구간C</th>
                  <td>
                    <Field name="normalSectionCPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalSectionCPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalSectionCPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">일반볼 구간D</th>
                  <td>
                    <Field name="normalSectionDPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalSectionDPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalSectionDPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 구간E</th>
                  <td>
                    <Field name="normalSectionEPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalSectionEPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalSectionEPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 구간F</th>
                  <td>
                    <Field name="normalSectionFPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalSectionFPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalSectionFPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">파워볼 구간</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedPowerSection: !bettingPerModel?.usedPowerSection ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedPowerSection ?? false}
                    />
                  </td>
                  <th scope="row">파워볼 구간A</th>
                  <td>
                    <Field name="powerSectionAPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerSectionAPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerSectionAPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 구간B</th>
                  <td>
                    <Field name="powerSectionBPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerSectionBPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerSectionBPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">파워볼 구간C</th>
                  <td>
                    <Field name="powerSectionCPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerSectionCPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerSectionCPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 구간D</th>
                  <td>
                    <Field name="powerSectionDPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerSectionDPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerSectionDPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">파워볼 숫자</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedPowerNum: !bettingPerModel?.usedPowerNum ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedPowerNum ?? false}
                    />
                  </td>
                  <th scope="row">파워볼 0</th>
                  <td>
                    <Field name="powerNum0Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum0Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum0Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 1</th>
                  <td>
                    <Field name="powerNum1Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum1Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum1Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">파워볼 2</th>
                  <td>
                    <Field name="powerNum2Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum2Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum2Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 3</th>
                  <td>
                    <Field name="powerNum3Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum3Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum3Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">파워볼 4</th>
                  <td>
                    <Field name="powerNum4Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum4Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum4Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 5</th>
                  <td>
                    <Field name="powerNum5Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum5Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum5Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">파워볼 6</th>
                  <td>
                    <Field name="powerNum6Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum6Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum6Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 7</th>
                  <td>
                    <Field name="powerNum7Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum7Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum7Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">파워볼 8</th>
                  <td>
                    <Field name="powerNum8Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum8Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum8Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 9</th>
                  <td>
                    <Field name="powerNum9Per">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerNum9Per ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerNum9Per: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">파워볼 조합</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedPowerCombination: !bettingPerModel?.usedPowerCombination ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedPowerCombination ?? false}
                    />
                  </td>
                  <th scope="row">파워볼 홀+언더</th>
                  <td>
                    <Field name="powerOddUnderPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerOddUnderPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerOddUnderPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 홀+오버</th>
                  <td>
                    <Field name="powerOddOverPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerOddOverPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerOddOverPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">파워볼 짝+언더</th>
                  <td>
                    <Field name="powerEvenUnderPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerEvenUnderPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerEvenUnderPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 짝+오버</th>
                  <td>
                    <Field name="powerEvenOverPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerEvenOverPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerEvenOverPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">일반볼 조합</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedNormalCombination: !bettingPerModel?.usedNormalCombination ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedNormalCombination ?? false}
                    />
                  </td>
                  <th scope="row">일반볼 홀+언더</th>
                  <td>
                    <Field name="normalOddUnderPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalOddUnderPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalOddUnderPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 홀+오버</th>
                  <td>
                    <Field name="normalOddOverPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalOddOverPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalOddOverPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">일반볼 짝+언더</th>
                  <td>
                    <Field name="normalEvenUnderPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalEvenUnderPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalEvenUnderPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 짝+오버</th>
                  <td>
                    <Field name="normalEvenOverPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalEvenOverPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalEvenOverPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">파워볼+일반볼 홀짝조합</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedPowerNormalOddEven: !bettingPerModel?.usedPowerNormalOddEven ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedPowerNormalOddEven ?? false}
                    />
                  </td>
                  <th scope="row">파워볼 홀 + 일반볼 홀</th>
                  <td>
                    <Field name="powerOddNormalOddPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerOddNormalOddPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerOddNormalOddPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 홀 + 일반볼 짝</th>
                  <td>
                    <Field name="powerOddNormalEvenPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerOddNormalEvenPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerOddNormalEvenPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">파워볼 짝 + 일반볼 홀</th>
                  <td>
                    <Field name="powerEvenNormalOddPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerEvenNormalOddPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerEvenNormalOddPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">파워볼 짝 + 일반볼 짝</th>
                  <td>
                    <Field name="powerEvenNormalEvenPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.powerEvenNormalEvenPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                powerEvenNormalEvenPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row">일반볼 홀짝+대중소 조합</th>
                  <td>
                    <CustomSwitch
                      onChange={() => {
                        setBettingPerModel(prev => ({
                          ...prev,
                          usedNormalOddLms: !bettingPerModel?.usedNormalOddLms ?? false,
                        }))
                      }}
                      checked={bettingPerModel?.usedNormalOddLms ?? false}
                    />
                  </td>
                  <th scope="row">일반볼 홀+대</th>
                  <td>
                    <Field name="normalOddLargePer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalOddLargePer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalOddLargePer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 홀+중</th>
                  <td>
                    <Field name="normalOddMediumPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalOddMediumPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalOddMediumPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 홀+소</th>
                  <td>
                    <Field name="normalOddSmallPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalOddSmallPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalOddSmallPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <th scope="row" />
                  <td />
                  <th scope="row">일반볼 짝+대</th>
                  <td>
                    <Field name="normalEvenLargePer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalEvenLargePer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalEvenLargePer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 짝+중</th>
                  <td>
                    <Field name="normalEvenMediumPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalEvenMediumPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalEvenMediumPer: inputValue,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                  <th scope="row">일반볼 짝+소</th>
                  <td>
                    <Field name="normalEvenSmallPer">
                      {({ input, meta }) => (
                        <input
                          {...input}
                          type="text"
                          value={bettingPerModel?.normalEvenSmallPer ?? 0}
                          onChange={e => {
                            const inputValue = e.target.value

                            if (priceNumPattern.test(inputValue)) {
                              setBettingPerModel(prev => ({
                                ...prev,
                                normalEvenSmallPer: e.target.value,
                              }))
                            }
                          }}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
              </table>
            </MiniGameFormInnerBox>
            {authority === 'SUPER' && (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Button size="lg" variant="outline-secondary" type="submit">
                  변경하기
                </Button>
              </div>
            )}
          </MiniGameForm>
        )}
      </Form>
    </>
  )
}

export default BePickPowerBall

BePickPowerBall.propTypes = {
  miniGameType: PropTypes.string.isRequired,
}

const MiniGameForm = styled.form`
  display: flex;
  flex-direction: column;

  padding: 20px;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 32px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid #000;
    color: #646777;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const MiniGameFormInnerBox = styled.div`
  width: 100%;

  table {
    white-space: nowrap;
    border-collapse: collapse;
    border-spacing: 0;

    th {
      text-align: right;
      padding-right: 15px;
    }

    td {
      padding: 10px;
    }
  }
`

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`
