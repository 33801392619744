import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import {
  ManualSportIdTypeEnumsSelectOptions,
  SportIdTypeEnums,
  SportIdTypeEnumsSelectOptions,
  SportSearchMarketTypeEnumsSelectOptions,
  SportTypeEnums,
  SportTypeEnumsSelectOptions,
  SportsMarketTypeEnumsSelectOptions,
} from '../../../enums/SystemManagement/sportEnums'
import { Button } from '../../../shared/components/Button'
import { MemberResterBankSelect } from '../../../shared/components/CustomSelect'
import Error from '../../../shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '../../../shared/components/form/FormElements'
import { DatePickerWrap } from '../../../shared/components/form/date-pickers/DatePickerElements'
import {
  createNewSportsEventOrMarketByManual,
  createNewSportsEventOrMarketByManualV2,
  searchSportsEventListForManual,
  searchSportsEventListForManualV2,
  searchSportsMarketListForManual,
  searchSportsMarketListForManualV2,
} from '../../../utils/api/sportsManage/manualSportRegister'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import { manualSportsRegisterValidate } from '../../../utils/validate/sport/sportSettingManage'
import Loading from '../../../shared/components/Loading'

const ManualTypeEnums = {
  NEW_EVENT: '수동 경기등록',
  NEW_MARKET: '수동 마켓등록',
}

const ManualTypeEnumsEnumsRadioOptions = () => {
  return Object.entries(ManualTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'manualType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'manualType',
          label: value,
          radioValue: key,
        },
  )
}

const ManualMarketList = [
  {
    sportsType: '야구',
    eventType: '프리매치',
    marketName: '첫 볼넷, 첫 홈런, 첫 삼진, 첫 안타, 첫 도루, 1이닝 득점',
    oddName: '홈승(HOME) / 원정승(AWAY)',
  },
  // {
  //   sportsType: '아이스 하키',
  //   marketName: '첫 득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  {
    sportsType: '농구',
    eventType: '프리매치',
    marketName: '첫 자유투, 첫 3점슛, 첫 득점, 선 5득점, 선 7득점, 선 10득점, 선 15득점, 선 20득점',
    oddName: '홈승 / 원정승',
  },
  // {
  //   sportsType: '농구',
  //   marketName: '첫 자유투',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: '농구',
  //   marketName: '첫 득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: '농구',
  //   marketName: '선 5득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: '농구',
  //   marketName: '선 7득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: '농구',
  //   marketName: '선 10득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: '농구',
  //   marketName: '선 15득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: '농구',
  //   marketName: '선 20득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  {
    sportsType: '배구',
    eventType: '프리매치',
    marketName: '선 5득점, 선 7득점, 선 10득점',
    oddName: '홈승 / 원정승',
  },
  // {
  //   sportsType: '배구',
  //   marketName: '선 7득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: '배구',
  //   marketName: '선 10득점',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  {
    sportsType: 'E-SPORTS',
    eventType: '프리매치+인플레이',
    marketName: '첫 킬, 첫 용, 첫 바론, 첫 타워, 킬 핸디, 킬 언오버, 경기시간, 승패',
    oddName: '홈승 / 원정승',
  },
  // {
  //   sportsType: 'E-SPORTS',
  //   marketName: '첫 용',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: 'E-SPORTS',
  //   marketName: '첫 바론',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: 'E-SPORTS',
  //   marketName: '첫 타워',
  //   oddName: '홈승(HOME) / 원정승(AWAY)',
  // },
  // {
  //   sportsType: 'E-SPORTS',
  //   marketName: '1세트 경기시간',
  //   oddName: '언더(UNDER) / 오버(OVER)',
  // },
  {
    sportsType: '기타',
    marketName: '승패',
    oddName: '홈승 / 원정승',
  },
]

const ManualSportsManagementV2 = () => {
  const [marketId, setMarketId] = useState('')
  const [fixtureId, setFixtureId] = useState('')
  const [locationId, setLocationId] = useState('')
  const [leagueId, setLeagueId] = useState('')
  const [homeId, setHomeId] = useState('')
  const [awayId, setAwayId] = useState('')

  const [locationName, setLocationName] = useState('')
  const [leagueName, setLeagueName] = useState('')
  const [homeName, setHomeName] = useState('')
  const [awayName, setAwayName] = useState('')
  const [homeOddValue, setHomeOddValue] = useState('')
  const [awayOddValue, setAwayOddValue] = useState('')
  const [pointValue, setPointValue] = useState('')
  const [startAt, setStartAt] = useState(null)

  const [sportsEventList, setSportsEventList] = useState([])

  const [sportsEventListOptionValue, setSportsEventListOptionValue] = useState(null)
  const [sportsEventListOptions, setSportsEventListOptions] = useState([])

  const onSportsEventListOptionsChangeHandler = option => {
    if (option == null) {
      setLocationId('')
      setLeagueId('')
      setAwayId('')
      setHomeId('')
      setLocationName('')
      setLeagueName('')
      setHomeName('')
      setAwayName('')
      setStartAt(null)
      setSportsEventListOptionValue(option)
    } else {
      setSportsEventListOptionValue(option)
      setLocationId(option.locationId)
      setLeagueId(option.leagueId)
      setAwayId(option.awayId)
      setHomeId(option.homeId)
      setLocationName(option.locationName)
      setLeagueName(option.leagueName)
      setHomeName(option.homeName)
      setAwayName(option.awayName)
      setStartAt(new Date(option.startAt))
    }
  }
  // 수동 마켓 리스트
  const [manualSportsMarketOptionValue, setManualSportsMarketOptionValue] = useState(null)
  const [manualSportsMarketOptions, setManualSportsMarketOptions] = useState()

  const onManualSportsMarketChangeHandler = option => {
    if (option == null) {
      setMarketId(null)
      setManualSportsMarketOptionValue(null)
    }
    setMarketId(option.marketId)
    setManualSportsMarketOptionValue(option)
  }
  const [isLoading, setLoading] = useState(false)

  const [sportsMarketTypeOptionValue, setSportsMarketTypeOptionValue] = useState(null)
  const sportsMarketTypeOptions = SportSearchMarketTypeEnumsSelectOptions()

  const [sportsTypeOptionValue, setSportsTypeOptionValue] = useState(null)
  const sportsTypeOptions = ManualSportIdTypeEnumsSelectOptions()

  const onSportsMarketTypeChangeHandler = option => {
    if (option == null) {
      // 경기 목록 초기화
      setSportsMarketTypeOptionValue(null)
    }
    setSportsMarketTypeOptionValue(option)
  }

  const onSportsTypeChangeHandler = option => {
    if (option == null) {
      // 경기 목록 초기화
      setSportsEventListOptionValue(null)
      setSportsEventListOptions([])
      setManualSportsMarketOptions([])
      setManualSportsMarketOptionValue(null)
    }
    setSportsEventListOptionValue(null)
    setSportsTypeOptionValue(option)
  }
  const [manualType, setManualType] = useState('NEW_MARKET')

  const onChangeManualTypeHandler = type => {
    setManualType(type)
    setSportsEventListOptionValue(null)
    setSportsEventListOptions([])
    setLocationName('')
    setLeagueName('')
    setHomeName('')
    setAwayName('')
    setHomeOddValue('')
    setAwayOddValue('')
    setPointValue('')
    // 경기시간 리셋
    setStartAt(null)
    // 마켓 리셋
    setManualSportsMarketOptionValue(null)
  }

  // 기존 경기에 마켓 등록시 경기 리스트 조회

  useEffect(() => {
    if (manualType === 'NEW_EVENT') return

    if (!sportsTypeOptionValue) {
      return
    }
    setLoading(true)
    searchSportsEventListForManualV2(sportsTypeOptionValue.value)
      .then(res => {
        setSportsEventListOptions(
          res.data.content?.map(item => ({
            value: item.fixtureId,
            label: `[ ${SportIdTypeEnums[item.sportId]} - ${item.leagueName} ] - ${item.homeName} vs ${item.awayName}`,
            fixtureId: item.fixtureId,
            sportId: item.sportId,
            locationId: item.locationId,
            leagueName: item.leagueName,
            leagueId: item.leagueId,
            locationName: item.locationName,
            homeId: item.homeId,
            homeName: item.homeName,
            awayId: item.awayId,
            awayName: item.awayName,
            startAt: item.startAt,
          })),
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [manualType, sportsTypeOptionValue])

  useEffect(() => {
    if (!sportsTypeOptionValue) {
      return
    }
    setLoading(true)
    searchSportsMarketListForManualV2(sportsTypeOptionValue.value)
      .then(res => {
        setManualSportsMarketOptions(
          res.data.content.map(item => ({
            value: item.marketKey,
            label: `${SportIdTypeEnums[item.sportId]} - ${item.marketNameKr}`,
            marketId: item.marketId,
          })),
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [sportsTypeOptionValue])

  useEffect(() => {
    setLoading(true)

    setLoading(false)
  }, [sportsEventList])

  const authority = decodeAccessToken()?.adminAuthority
  const onSubmit = e => {
    if (isLoading) return
    if (authority === 'NORMAL') return
    setLoading(true)
    const body = {
      manualType: 'NEW_MARKET', // 수동 경기는 추후
      sportId: sportsTypeOptionValue.value,
      sportName: SportIdTypeEnums[sportsTypeOptionValue.value],
      marketKey: manualSportsMarketOptionValue.value,
      fixtureId: sportsEventListOptionValue.value,
      preMatchOrInPlay: sportsMarketTypeOptionValue.value,
      marketId,
      locationName,
      locationId,
      leagueName,
      leagueId,
      homeName,
      homeId,
      awayId,
      awayName,
      homeOddValue,
      awayOddValue,
      line: pointValue,
      startAt: convertToKst(startAt),
    }

    createNewSportsEventOrMarketByManualV2(body)
      .then(res => {
        alert('수동마켓 등록이 성공했습니다.\n등록된 마켓은 대기 상태이며, 재확인 후 발매상태로 전환하여 사용바랍니다.')
        window.location.reload()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        if (errorCode === 'SPORTS-2017') {
          alert(`${message}`)
        } else if (errorCode === 'SPORTS-2018') {
          alert(`${message}`)
        } else {
          alert('등록 실패했습니다.')
        }
        window.location.reload()
      })
    setLoading(false)
  }

  return (
    <>
      <SearchCardTitleWrap>
        <div style={{ marginBottom: '10px' }}>
          <p>* 해당 페이지는 수동으로 기존 경기에 마켓을 등록할 수 있습니다.</p>
        </div>
      </SearchCardTitleWrap>
      <div style={{ width: '100%', display: 'flex', gap: '10px' }}>
        <div style={{ width: '40%' }}>
          <ManualSportsHeaderBox>수동 등록 가능한 마켓 리스트</ManualSportsHeaderBox>
          <TableWrap>
            <table>
              <thead>
                <tr
                  style={{
                    background: 'lightgrey',
                  }}
                >
                  <th style={{ width: '35%', borderRight: '1px solid black' }}>종목</th>
                  <th style={{ width: '100%', borderRight: '1px solid black' }}>타입</th>
                  <th style={{ width: '100%', borderRight: '1px solid black', padding: '10px' }}>마켓명</th>
                  <th style={{ width: '60%' }}>배당명</th>
                </tr>
              </thead>
              <tbody>
                {ManualMarketList.map(item => (
                  <tr>
                    <TableBodyWrap>
                      <TableBodySportsTypeWrap>{item.sportsType}</TableBodySportsTypeWrap>
                      <TableBodySportsMarketWrap>{item.eventType}</TableBodySportsMarketWrap>
                      <TableBodySportsMarketWrap style={{ padding: '10px' }}>
                        {item.marketName}
                      </TableBodySportsMarketWrap>
                      <TableBodySportsMarketOddNameWrap>{item.oddName}</TableBodySportsMarketOddNameWrap>
                    </TableBodyWrap>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableWrap>
          {/* <ManualSportsHeaderBox>수동 등록 예시</ManualSportsHeaderBox> */}
        </div>

        <div style={{ width: '60%' }}>
          {isLoading && <Loading />}
          <ManualSportsHeaderBox>수동 마켓 등록하기</ManualSportsHeaderBox>
          <ManualSportsFormWrap>
            <Form
              onSubmit={onSubmit}
              validate={manualSportsRegisterValidate}
              initialValues={{
                sportsMarketTypeOptionValue,
                sportsEventListOptionValue,
                sportsTypeOptionValue,
                manualType,
                locationName,
                leagueName,
                homeName,
                awayName,
                homeOddValue,
                awayOddValue,
                pointValue,
                startAt,
                manualSportsMarketOptionValue,
              }}
            >
              {({ handleSubmit, form: { reset } }) => (
                <FormContainer horizontal onSubmit={handleSubmit}>
                  <FormGroup>
                    <FormGroupLabel>종목</FormGroupLabel>
                    <FormGroupField>
                      <Field name="sportsTypeOptionValue">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <MemberResterBankSelect
                              {...input}
                              value={sportsTypeOptionValue}
                              onChange={option => {
                                onSportsTypeChangeHandler(option)
                              }}
                              options={sportsTypeOptions}
                              placeholder="종목을 선택하세요"
                              className="react-select"
                              classNamePrefix="react-select"
                              // isClearable
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  {/* <FormGroup>
                      <FormGroupLabel>유형 선택</FormGroupLabel>
                      <FormGroupField>
                        {ManualTypeEnumsEnumsRadioOptions().map(item => (
                          <FormGroupField
                            key={`index_${item.label}`}
                            style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                          >
                            <Field
                              name={`${item.name}`}
                              component={renderRadioButtonField}
                              label={item.label}
                              radioValue={item.radioValue}
                              initialValue={manualType}
                              onCustomChange={e => {
                                onChangeManualTypeHandler(e)
                              }}
                            />
                          </FormGroupField>
                        ))}
                      </FormGroupField>
                    </FormGroup> */}
                  {manualType === 'NEW_MARKET' && (
                    <FormGroup>
                      <FormGroupLabel>경기선택</FormGroupLabel>
                      <FormGroupField>
                        <Field name="sportsEventListOptionValue">
                          {({ input, meta }) => (
                            <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                              <MemberResterBankSelect
                                {...input}
                                value={sportsEventListOptionValue}
                                onChange={option => {
                                  console.log('option', option)
                                  onSportsEventListOptionsChangeHandler(option)
                                }}
                                options={sportsEventListOptions}
                                placeholder="경기를 선택하세요"
                                className="react-select"
                                classNamePrefix="react-select"
                                // isClearable
                              />
                              {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                            </FormInputWrap>
                          )}
                        </Field>
                      </FormGroupField>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <FormGroupLabel>경기시간</FormGroupLabel>
                    <FormGroupField>
                      <Field name="startAt">
                        {({ input, meta }) => (
                          <CustomDatePickerWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <DatePicker
                              selected={startAt && new Date(startAt)}
                              onChange={date => {
                                setStartAt(date)
                              }}
                              dateFormat="yyyy-MM-dd HH:mm"
                              dropDownMode="select"
                              timeFormat="HH:mm"
                              showTimeInput
                              isClearable={manualType !== 'NEW_MARKET'}
                              locale={ko}
                              disabled={manualType === 'NEW_MARKET'}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </CustomDatePickerWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>국가명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="locationName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={locationName}
                              disabled={manualType === 'NEW_MARKET'}
                              onChange={e => {
                                setLocationName(e.target.value)
                              }}
                              style={{ border: '1px solid black' }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>리그명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="leagueName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={leagueName}
                              disabled={manualType === 'NEW_MARKET'}
                              onChange={e => {
                                setLeagueName(e.target.value)
                              }}
                              style={{ border: '1px solid black' }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>홈팀명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="homeName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={homeName}
                              disabled={manualType === 'NEW_MARKET'}
                              onChange={e => {
                                setHomeName(e.target.value)
                              }}
                              style={{ border: '1px solid black' }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>원정명</FormGroupLabel>
                    <FormGroupField>
                      <Field name="awayName">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={awayName}
                              disabled={manualType === 'NEW_MARKET'}
                              onChange={e => {
                                setAwayName(e.target.value)
                              }}
                              style={{ border: '1px solid black' }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>프리매치/인플레이</FormGroupLabel>
                    <FormGroupField>
                      <Field name="sportsMarketTypeOptionValue">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', width: '180px' }}>
                            <MemberResterBankSelect
                              {...input}
                              value={sportsMarketTypeOptionValue}
                              onChange={option => {
                                onSportsMarketTypeChangeHandler(option)
                              }}
                              options={sportsMarketTypeOptions}
                              placeholder="프리매치/인플레이"
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                    <FormGroupDescription style={{ color: 'red', fontSize: '11px' }}>
                      * 2세트 부터의 E-SPORTS 마켓은 인플레이로 등록해주세요.
                    </FormGroupDescription>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>마켓선택</FormGroupLabel>
                    <FormGroupField>
                      <Field name="manualSportsMarketOptionValue">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start', width: '180px' }}>
                            <MemberResterBankSelect
                              {...input}
                              value={manualSportsMarketOptionValue}
                              onChange={option => {
                                onManualSportsMarketChangeHandler(option)
                              }}
                              options={manualSportsMarketOptions}
                              placeholder="마켓을 선택하세요"
                              className="react-select"
                              classNamePrefix="react-select"
                              isClearable
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                    <FormGroupDescription style={{ color: 'black', fontSize: '11px' }}>
                      * 종목에 맞는 마켓을 선택하세요
                    </FormGroupDescription>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>홈/언더 배당</FormGroupLabel>
                    <FormGroupField>
                      <Field name="homeOddValue">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={homeOddValue}
                              onChange={e => {
                                const inputValue = e.target.value

                                // 정규식: 숫자와 소수점만 허용
                                if (/^\d*(\.\d{0,2})?$/.test(inputValue)) {
                                  setHomeOddValue(inputValue)
                                }
                              }}
                              style={{ border: '1px solid black' }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                    <FormGroupDescription style={{ color: 'black', fontSize: '11px' }}>
                      * 숫자, 소수점 2자리까지만 입력이 가능합니다.
                    </FormGroupDescription>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>원정/오버 배당</FormGroupLabel>
                    <FormGroupField>
                      <Field name="awayOddValue">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={awayOddValue}
                              onChange={e => {
                                const inputValue = e.target.value

                                // 정규식: 숫자와 소수점만 허용
                                if (/^\d*(\.\d{0,2})?$/.test(inputValue)) {
                                  setAwayOddValue(inputValue)
                                }
                              }}
                              style={{ border: '1px solid black' }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} mt="3" />}
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                    <FormGroupDescription style={{ color: 'black', fontSize: '11px' }}>
                      * 숫자, 소수점 2자리까지만 입력이 가능합니다.
                    </FormGroupDescription>
                  </FormGroup>
                  <FormGroup>
                    <FormGroupLabel>기준점</FormGroupLabel>
                    <FormGroupField>
                      <Field name="pointValue">
                        {({ input, meta }) => (
                          <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                            <input
                              {...input}
                              type="text"
                              value={pointValue}
                              onChange={e => {
                                const inputValue = e.target.value
                                setPointValue(inputValue)
                                // 정규식: 숫자와 소수점만 허용
                                // if (/^\d*(\.\d{0,2})?$/.test(inputValue)) {

                                // }
                              }}
                              style={{ border: '1px solid black' }}
                            />
                          </FormInputWrap>
                        )}
                      </Field>
                    </FormGroupField>
                    <FormGroupDescription style={{ color: 'black', fontSize: '11px' }}>
                      * 기준점이 필요할때만 입력하세요 <br />* 숫자, 소수점 2자리까지만 입력이 가능합니다.
                    </FormGroupDescription>
                  </FormGroup>

                  {decodeAccessToken()?.adminAuthority === 'SUPER' && (
                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">
                        등록하기
                      </Button>
                    </FormButtonToolbar>
                  )}
                </FormContainer>
              )}
            </Form>
          </ManualSportsFormWrap>
        </div>
      </div>
    </>
  )
}

export default ManualSportsManagementV2

const TableWrap = styled.div`
  width: 100%;

  table {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    thead {
      border-top: 1px solid black;
      border-left: 1px solid black;
      border-right: 1px solid black;
      tr {
        th {
          padding: 4px 0;
          border-bottom: 1px solid grey;
          white-space: nowrap;
          color: black;
          font-weight: 600;
          font-size: 15px;
          text-align: center;
        }
      }
    }

    tbody {
      border-bottom: 1px solid black;
      border-left: 1px solid black;
      border-right: 1px solid black;

      tr {
        &:not(:last-child) {
          border-bottom: 1px solid black;
        }

        &:hover {
          cursor: pointer;
          border: 2px solid red;
        }
      }
    }

    tr {
      width: 100%;
      display: flex;
    }
  }
`

const TableBodyWrap = styled.div`
  width: 100%;
  display: flex;
  background: #f3f4f6;
  color: black;
  min-height: 30px;
`

const TableBodySportsTypeWrap = styled.div`
  width: 35%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-right: 1px solid black;
`

const TableBodySportsMarketWrap = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-right: 1px solid black;
`

const TableBodySportsMarketOddNameWrap = styled.div`
  width: 60%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
`

const ManualSportsHeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
  border-top: 2px solid black;
`

const ManualSportsFormWrap = styled.div`
  width: 100%:
`

const CustomDatePickerWrap = styled(DatePickerWrap)`
  .react-datepicker__input-container {
    border: 1px solid black;
  }
`
