import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../../../shared/components/Button'
import MemberHistoryAttendance from './MemberAttendance/MemberHistoryAttendance'
import MemberHistoryLogin from './MemberLogin/MemberHistoryLogin'
import MemberHistoryMoney from './MemberMoneyHistory/MemberHistoryMoney'
import MemberHistoryMoneyIncDec from './MemberMoneyIncDec/MemberHistoryMoneyIncDec'
import MemberHistoryOneToOne from './MemberOneToOne/MemberHistoryOneToOne'
import MemberHistoryPoint from './MemberPointHistory/MemberHistoryPoint'
import MemberHistoryBetting from './MemberBettingLog/MemberHistoryBetting'
import MemberStatisticsBetting from './MemberStatistics/MemberStatisticsBetting'
import MemberRollingInfoPage from './MemberRollingInfo/MemberRollingInfoPage'
import MemberLosingInfoPage from './MemberLosingInfo/MemberLosingInfoPage'
import MemberCasinoVendorInfo from './MemberCasinoVendorInfo/MemberCasinoVendorInfo'

const MemberInfoTabs = ({ memberId }) => {
  const [menuTitle, setMenuTitle] = useState('moneyHistory')

  const getComponent = mt => {
    switch (mt) {
      case 'moneyHistory':
        return <MemberHistoryMoney memberId={memberId} />
      case 'rollingInfo':
        return <MemberRollingInfoPage memberId={memberId} />
      case 'losingInfo':
        return <MemberLosingInfoPage memberId={memberId} />
      case 'bettingStatistics':
        return <MemberStatisticsBetting memberId={memberId} />
      case 'bettingLog':
        return <MemberHistoryBetting memberId={memberId} />
      case 'moneyIncDec':
        return <MemberHistoryMoneyIncDec memberId={memberId} />
      case 'pointHistory':
        return <MemberHistoryPoint memberId={memberId} />
      case 'oneToOneHistory':
        return <MemberHistoryOneToOne memberId={memberId} />
      case 'attendanceHistory':
        return <MemberHistoryAttendance memberId={memberId} />
      case 'loginHistory':
        return <MemberHistoryLogin memberId={memberId} />
      case 'casinoVendorInfo':
        return <MemberCasinoVendorInfo memberId={memberId} />
      default:
        return <></>
    }
  }

  return (
    <MemberHistoryWrap>
      <MemberHistoryMenuWrap>
        <MemberHistoryMenuBtn
          variant="secondary"
          type="button"
          size="sm"
          name="moneyHistory"
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          입출금 내역
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          variant="success"
          type="button"
          size="sm"
          name="casinoVendorInfo"
          style={{ backgroundColor: 'MediumVioletRed' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          카지노 업체설정
        </MemberHistoryMenuBtn>
        {/* <MemberHistoryMenuBtn
          type="button"
          size="sm"
          name="rollingInfo"
          style={{ backgroundColor: 'navy' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          롤링 설정
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          type="button"
          size="sm"
          name="losingInfo"
          style={{ backgroundColor: '#c663ff' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          루징 설정
        </MemberHistoryMenuBtn> */}
        <MemberHistoryMenuBtn
          variant="success"
          type="button"
          size="sm"
          name="bettingStatistics"
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          배팅 통계
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          variant="danger"
          type="button"
          size="sm"
          name="bettingLog"
          style={{ backgroundColor: 'orange' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          배팅 로그
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          variant="warning"
          type="button"
          size="sm"
          name="moneyIncDec"
          style={{ backgroundColor: 'blue' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          자산 증감/내역
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          variant="warning"
          type="button"
          size="sm"
          name="pointHistory"
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          포인트 입금&전환 내역
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          variant="danger"
          type="button"
          size="sm"
          name="oneToOneHistory"
          style={{ backgroundColor: 'pink' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          문의 내역
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          type="button"
          size="sm"
          name="attendanceHistory"
          style={{ backgroundColor: 'grey' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          출석 내역
        </MemberHistoryMenuBtn>
        <MemberHistoryMenuBtn
          type="button"
          size="sm"
          name="loginHistory"
          style={{ backgroundColor: 'green' }}
          onClick={e => {
            setMenuTitle(e.target.name)
          }}
        >
          로그인 기록
        </MemberHistoryMenuBtn>
      </MemberHistoryMenuWrap>

      {menuTitle && getComponent(menuTitle)}
    </MemberHistoryWrap>
  )
}

export default MemberInfoTabs

MemberInfoTabs.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const MemberHistoryWrap = styled.div`
  width: 100%;
  min-height: 400px;
`

const MemberHistoryMenuWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

const MemberHistoryMenuBtn = styled(Button)`
  margin-bottom: 10px;
  border: none;
`
