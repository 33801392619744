import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { OneToOneAnswerStatusEnums, OneToOneAuthoritySelectOptions } from '../../../enums/Operation/OneToOneEnums'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import { deleteOnetoOneList, searchOnetoOneList, updateOnetoOne } from '../../../utils/api/operation/operationApi'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import OperationTabs from '../OperationTabs'
import OnetoOneInfoModal from './OnetoOneInfoModal'
import OnetoOneListData from './OnetoOneListData'

const OnetoOneList = () => {
  // 문의 내용 자세히 모달
  const dispatch = useDispatch()
  const authority = decodeAccessToken()?.adminAuthority
  const [isOnetoOneInfoModalShow, setOnetoOneInfoModalShow] = useState(false)
  const [userAuthority, setUserAuthority] = useState(null)
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(100)
  /**
   1:1문의 조회
   */
  const [oneToOneparams, setOneToOneParams] = useState({
    page,
    size,
    edgeName: null,
    userId: null,
    authority: null,
    answerStatus: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchSearchOnetoOneList = async () => {
    if (loading) return

    setLoading(true)
    await searchOnetoOneList(oneToOneparams)
      .then(res => {
        setContent(res.data.content)
        setTotalElement(res.data.totalElement)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSearchOnetoOneList()
  }, [oneToOneparams])

  const [checkRadio, setCheckRadio] = useState([])
  const [onetoOneInfo, setOnetoOneInfo] = useState({})

  const onTitleClickRowHandler = () => {
    window.scrollTo(10, 0)
    setOnetoOneInfoModalShow(true)
  }

  const [onetoOneListData, setOnetoOneListData] = useState(
    OnetoOneListData(content, fetchSearchOnetoOneList, checkRadio, setCheckRadio, onTitleClickRowHandler),
  )
  useEffect(() => {
    setOnetoOneListData(
      OnetoOneListData(content, fetchSearchOnetoOneList, checkRadio, setCheckRadio, onTitleClickRowHandler),
    )
  }, [content, checkRadio])

  const [rows, setData] = useState(onetoOneListData.tableRowsData)
  useEffect(() => {
    setData(onetoOneListData.tableRowsData)
  }, [onetoOneListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setOnetoOneInfo(rowValue.original)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 답변
  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const [searchStatusToggle, setSearchStatusToggle] = useState(false)

  const onChangeSearchStatusHandler = status => {
    setSearchStatusOptionValue(status)
    setSearchStatusToggle(prev => !prev)
  }

  useEffect(() => {
    setOneToOneParams(prev => ({
      ...prev,
      page: 0,
      answerStatus: searchStatusOptionValue,
    }))
  }, [searchStatusToggle])

  // 권한
  const [searchAuthority, setSearchAuthority] = useState('')

  const [searchAuthorityOptionValue, setSearchAuthorityOptionValue] = useState(null)
  const searchAuthorityOption = OneToOneAuthoritySelectOptions()

  const onSelectAuthorityOptionHandler = option => {
    if (option === null) {
      setSearchAuthorityOptionValue(null)
      setSearchAuthority(null)
      return
    }
    setSearchAuthorityOptionValue(option)
    setSearchAuthority(option.value)
  }

  // 작성자 ID
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 문의일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const resetSearchValue = () => {
    setSearchEdgeName('')
    setSearchEdgeNameOptionValue(null)
    setSearchUserId('')
    setSearchStartDate(null)
    setSearchEndDate(null)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setOneToOneParams(prev => ({
      ...prev,
      page: 0,
      userId: !searchUserId ? null : searchUserId.trim(),
      edgeName: !searchEdgeName ? null : searchEdgeName,
      answerStatus: !searchStatusOptionValue ? null : searchStatusOptionValue,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  // Tab Key
  const [activeKey, setActiveKey] = useState('3')
  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 1:1문의를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
    }
  }

  // 삭제하기 버튼
  const onWaitingHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('대기 할 1:1문의를 선택해주세요.')
      return
    }

    if (window.confirm('정말 대기로 하시겠습니까?')) {
      setDeleteLoading(true)
      updateOnetoOne({
        oneToOneKeyList: checkRadio,
        customerStatus: 'WAITING',
      })
        .then(res => {
          dispatch(fetchHeaderAlarmInfoAction())
          setDeleteLoading(false)
          alert('대기가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('대기가 실패했습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
    }
  }

  const deleteAllHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return

    if (window.confirm('1:1문의 전체를 정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteOnetoOneList({
        oneToOneKeyList: [],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchOnetoOneList()
        })
    }
  }

  const reloadOnoToOneHandler = () => {
    if (loading) return
    setLoading(true)
    resetSearchValue()
    setOneToOneParams({
      page: 0,
      size,
      edgeName: null,
      userId: null,
      authority: null,
      answerStatus: null,
      startDate: null,
      endDate: null,
    })
    setLoading(false)
  }

  return (
    <Card>
      <CardBody>
        <OperationTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 문의내용을 자세히 볼 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <div style={{ display: 'flex', gap: '10px' }}>
          <AuthoritySearchButton
            userAuthority={userAuthority}
            onClickHandler={e => {
              setUserAuthority(e.target.id)
            }}
          />
          <SearchStatusWrap>
            <Button
              size="sm"
              active={searchStatusOptionValue === null}
              variant="outline-secondary"
              onClick={e => {
                onChangeSearchStatusHandler(null)
              }}
            >
              전체
            </Button>
            {Object.keys(OneToOneAnswerStatusEnums).map(status => {
              return (
                <Button
                  id={status}
                  size="sm"
                  active={searchStatusOptionValue === status}
                  variant="outline-secondary"
                  onClick={e => {
                    onChangeSearchStatusHandler(status)
                  }}
                >
                  {OneToOneAnswerStatusEnums[status]}
                </Button>
              )
            })}
          </SearchStatusWrap>
        </div>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searcUserId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchUserId}
                  onChange={e => onSearchUserIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          {deleteLoading || loading ? (
            <>
              <Loading />
            </>
          ) : (
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'lightGreen' }}
                variant="outline-secondary"
                onClick={() => {
                  setCheckRadio(content.map(value => value.oneToOneKey))
                }}
              >
                전체선택
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'green', fontWeight: '500', color: 'white' }}
                variant="outline-secondary"
                onClick={() => {
                  onWaitingHandler()
                }}
              >
                답변대기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'pink', fontWeight: '500', color: '#000' }}
                variant="outline-secondary"
                onClick={() => {
                  reloadOnoToOneHandler()
                }}
              >
                새로고침
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: '#ff4f87', color: '#fff' }}
                variant="outline-secondary"
                onClick={() => {
                  onDeleteHandler()
                }}
              >
                삭제하기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'black', fontWeight: '600', color: 'red', marginLeft: '325px' }}
                variant="outline-secondary"
                onClick={() => {
                  deleteAllHandler()
                }}
              >
                전체삭제
              </Button>
            </SearchButtonWrap>
          )}
        </SearchContainer>

        <ReactTableBase columns={onetoOneListData.tableHeaderData} data={rows} tableConfig={tableConfig} />

        {/* 문의 내용 자세히 보기 모달 */}
        {isOnetoOneInfoModalShow && (
          <OnetoOneInfoModal
            onetoOneInfo={{
              authority: onetoOneInfo?.authority,
              oneToOneKey: onetoOneInfo?.oneToOneKey,
              userId: onetoOneInfo?.userId,
              questionTitle: onetoOneInfo?.questionTitle,
              registrationDate: onetoOneInfo?.registrationDate,
              questionContent: onetoOneInfo?.questionContent,
              answerContent: onetoOneInfo?.answerContent,
              answerStatus: onetoOneInfo?.answerStatus,
              answerStatusType: OneToOneAnswerStatusEnums[onetoOneInfo?.answerStatus] === '완료',
            }}
            fetchSearchOnetoOneList={fetchSearchOnetoOneList}
            isOnetoOneInfoModalShow={isOnetoOneInfoModalShow}
            setOnetoOneInfoModalShow={setOnetoOneInfoModalShow}
          />
        )}
      </CardBody>
    </Card>
  )
}

export default OnetoOneList

OnetoOneList.propTypes = {}

OnetoOneList.defaultProps = {}

const SearchStatusWrap = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0;

  border-left: 3px solid grey;
  padding-left: 15px;

  button {
    height: 40px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    padding: 0 10px;
    color: #646777;
    background: #fafbfe;

    &:active {
      background: grey;
      color: #fff;
      border-color: #4ce1b6;
      outline: none;
    }
  }
`
