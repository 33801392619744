import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { getCallHistory, getCallUsers } from '../../../utils/api/systemManage/callManageApi'
import CasinoManagementTabs from '../../CasinoManagement/CasinoManagementTabs'
import CallHistoryData from './CallHistoryData'
import CallUserRecoveryPerModal from './CallUserRecoveryPerModal'
import CallUsersData from './CallUsersData'
import CallUsersModal from './CallUsersModal'

const CallManageMent = () => {
  const [memberInfo, setMemberInfo] = useState({})

  // 콜 조작 모달
  const [callModalShow, setCallModalShow] = useState(false)
  const onCallModalShowHandler = () => {
    setCallModalShow(false)
  }

  // 환수 조작 모달
  const [recoveryPerModalShow, setrecoveryPerModalShow] = useState(false)
  const onRecoveryPerModalShowHandler = () => {
    setrecoveryPerModalShow(false)
  }

  /**
   * 접속중인 유저 리스트
   */
  const [callUsersPage, setCallUsersPage] = useState(0)
  const [callUsersSize, setCallUsersSize] = useState(10)

  const [totalElement, setTotalElement] = useState(0)
  const [callUsersContent, setCallUsersContent] = useState([])

  const fetchGetCallUsers = () => {
    getCallUsers().then(res => {
      setTotalElement(res.data.totalElement)
      setCallUsersContent(res.data.content)
    })
  }

  const [callUsersData, setCallUsersData] = useState(
    CallUsersData(callUsersContent, setMemberInfo, setCallModalShow, setrecoveryPerModalShow),
  )

  useEffect(() => {
    setCallUsersData(CallUsersData(callUsersContent, setMemberInfo, setCallModalShow, setrecoveryPerModalShow))
  }, [callUsersContent])

  const [callUsersRows, setCallUsersRows] = useState(callUsersData.tableRowsData)
  useEffect(() => {
    setCallUsersRows(callUsersData.tableRowsData)
  }, [callUsersData])

  const callUsersTableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: false,
    // page
    _pageIndex: callUsersPage,
    // size
    _pageSize: callUsersSize,
    // total element
    // totalElement,
    // setPageIndex
    _setPageIndex: setCallUsersPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  /**
   * 콜 이력 리스트
   */

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const [callHistoryParams, setCallHistoryParams] = useState({
    memberId: null,
    memberName: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setCallHistoryParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [callHistoryTotalElement, setCallHistoryTotalElement] = useState(0)
  const [callHistoryContent, setCallHistoryContent] = useState([])

  const fetchGetCallHistory = () => {
    getCallHistory(callHistoryParams).then(res => {
      setCallHistoryTotalElement(res.data.totalElement)
      setCallHistoryContent(res.data.content)
    })
  }

  useEffect(() => {
    fetchGetCallHistory()
  }, [callHistoryParams])

  const [callHistoryData, setCallHistoryData] = useState(CallHistoryData(callHistoryContent))

  useEffect(() => {
    setCallHistoryData(CallHistoryData(callHistoryContent))
  }, [callHistoryContent])

  const [callHistoryRows, setCallHistoryRows] = useState(callHistoryData.tableRowsData)
  useEffect(() => {
    setCallHistoryRows(callHistoryData.tableRowsData)
  }, [callHistoryData])

  const callHistoryTableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement: callHistoryTotalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // tab 키
  const [activeKey, setActiveKey] = useState('7')

  useEffect(() => {
    fetchGetCallUsers()
    fetchGetCallHistory()

    const intervalId = setInterval(() => {
      fetchGetCallUsers()
      fetchGetCallHistory()
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <>
      {callModalShow && (
        <CallUsersModal
          memberInfo={memberInfo}
          callModalShow={callModalShow}
          onCallModalShowHandler={onCallModalShowHandler}
          fetchGetCallUsers={fetchGetCallUsers}
        />
      )}
      {recoveryPerModalShow && (
        <CallUserRecoveryPerModal
          memberInfo={memberInfo}
          recoveryPerModalShow={recoveryPerModalShow}
          onRecoveryPerModalShowHandler={onRecoveryPerModalShowHandler}
          fetchGetCallUsers={fetchGetCallUsers}
        />
      )}
      <Card>
        <CardBody>
          <CasinoManagementTabs activeKey={activeKey} />
          <SearchCardTitleWrap>
            <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
              <p>
                * <span style={{ color: 'red' }}>콜 조작</span>은 접속중인 유저에게만 적용할 수 있습니다.
              </p>
              <p>
                * <span style={{ color: 'red' }}>환수 조작</span>은 접속중인 유저에게 적용 가능하며, 회원관리 메뉴에서도
                적용 가능합니다.
              </p>
              <p>
                * <span style={{ color: 'red' }}>콜 조작</span>시 계산되는 금액의 소수점자리는 버림처리합니다.
              </p>
              <p>
                * <span style={{ color: 'red' }}>5초 간격</span>으로 데이터를 불러오기때문에, 이력은 5초 전후로
                갱신됩니다. <br />* <span style={{ color: 'red' }}>콜 이력</span>의 상태가 진행중인 이력은 상황에 따라
                계속 남아있을 수 있습니다.
              </p>
            </div>
          </SearchCardTitleWrap>

          <CallManageMentWrap>
            <CallManageMentContentFirstWrap>
              <CallManageMentContentHeader>게임중인 유저 내역 ({totalElement})</CallManageMentContentHeader>
              <ReactTableBase
                columns={callUsersData.tableHeaderData}
                data={callUsersRows}
                tableConfig={callUsersTableConfig}
              />
            </CallManageMentContentFirstWrap>
            <CallManageMentContentSecondWrap>
              <CallManageMentContentHeader>콜 이력</CallManageMentContentHeader>
              {/* <SearchContainer horizontal>
              <SearchGroup>
                <SearchGroupLabel>게임사명</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      type="text"
                      placeholder="입력해주세요"
                      value={searchVendorName}
                      onChange={e => onSearchVendorNameHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>

              <SearchButtonWrap>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    onSearchClickHandler()
                  }}
                >
                  검색하기
                </Button>
              </SearchButtonWrap>
            </SearchContainer> */}

              <ReactTableBase
                columns={callHistoryData.tableHeaderData}
                data={callHistoryRows}
                tableConfig={callHistoryTableConfig}
              />
            </CallManageMentContentSecondWrap>
          </CallManageMentWrap>
        </CardBody>
      </Card>
    </>
  )
}

export default CallManageMent

const CallManageMentWrap = styled.div`
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`

const CallManageMentContentHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 50px;
  font-weight: 500;
  padding-left: 8px;
`

const CallManageMentContentFirstWrap = styled.div`
  width: 100%;
`

const CallManageMentContentSecondWrap = styled.div`
  width: 100%;
  min-height: 500px;
`
