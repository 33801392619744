import {
  CouponCustomStyledModal,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
} from '@/shared/components/CustomModal'
import Tooltip from '@/shared/components/Tooltip'
import styled from 'styled-components'
import { RiCoupon2Line } from 'react-icons/ri'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { CouponButton, CustomBtnTableWrap } from '../../../../shared/components/TableElements'
import { updateMemberLogOut } from '../../../../utils/api/memberManage/memberManageApi'

function MemberLogOut({ memberId, fetchSearchMember }) {
  const [depositLoading, setDepositLoading] = useState(false)
  const onClickHandler = () => {
    if (depositLoading) return
    if (window.confirm(`${memberId}회원을 강제 로그아웃 하시겠습니까?`)) {
      updateMemberLogOut({
        memberId,
      })
        .then(res => {
          setDepositLoading(false)
          fetchSearchMember()
          alert(`${memberId}님이 강제로그아웃 됐습니다.`)
        })
        .catch(error => {
          setDepositLoading(false)
          fetchSearchMember()
          alert(`${memberId}님이 강제로그아웃 실패 했습니다.`)
        })
    }
  }

  // const onClickHandler = () => {
  //   setMemberCouponModalShow(prevState => !prevState)
  // }

  return (
    <CustomBtnTableWrap>
      <button type="button" style={{ border: 'none', background: '#b0584a', color: 'white' }} onClick={onClickHandler}>
        로그아웃
      </button>
      {/* <CouponCustomStyledModal show={isMemberCouponModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>쿠폰 발행 - {memberId}</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <MemberCouponForm memberId={memberId} onClickHandler={onClickHandler} />
        </CustomModalBody>
      </CouponCustomStyledModal> */}
    </CustomBtnTableWrap>
  )
}

export default MemberLogOut

MemberLogOut.propTypes = {
  memberId: PropTypes.string.isRequired,
  fetchSearchMember: PropTypes.func.isRequired,
}

const CouponIcon = styled(RiCoupon2Line)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: #ff4500;
`
