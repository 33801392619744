import {
  CouponCustomStyledModal,
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
} from '@/shared/components/CustomModal'
import Tooltip from '@/shared/components/Tooltip'
import styled from 'styled-components'
import { RiCoupon2Line } from 'react-icons/ri'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { CouponButton, CustomBtnTableWrap } from '../../../../shared/components/TableElements'
import MemberCouponForm from './MemberCouponForm'

function MemberCoupon({ memberId }) {
  const [isMemberCouponModalShow, setMemberCouponModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberCouponModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <CouponButton role={Button} onClick={() => onClickHandler()}>
        <Tooltip text="쿠폰" place="bottom">
          <span>
            <CouponIcon />
          </span>
        </Tooltip>
      </CouponButton>
      <CouponCustomStyledModal show={isMemberCouponModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>쿠폰 발행 - {memberId}</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <MemberCouponForm memberId={memberId} onClickHandler={onClickHandler} />
        </CustomModalBody>
      </CouponCustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default MemberCoupon

MemberCoupon.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const CouponIcon = styled(RiCoupon2Line)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: #ff4500;
`
