import { Card, CardBody, CustomAuthorityButton, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import { ExportToCsv } from 'export-to-csv'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { SportMarketType } from '../../../enums/Sport/SportMarketEnums'
import {
  BettingResultStatusEnumsSelectOptions,
  BettingResultStatusEnumsSelectOptions2,
  sportResultStatusEnums,
} from '../../../enums/SystemManagement/sportEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import Loading from '../../../shared/components/Loading'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'
import { downloadMemberSportsList } from '../../../utils/api/memberManage/memberManageApi'
import {
  cancelSportsBettingLogV2,
  deleteSportsBettingLogV2,
  searchSportsBettingLogV2,
} from '../../../utils/api/sport/sportBettingLogApi'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import BettingManagementTabs from '../BettingManagementTabs'
import SportDetailBettingLog from './SportDetailBettingLog'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const SportBettingLogListV2 = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  const { EdgeNameSelect, authority } = useSelector(state => {
    const { headerInfo, initInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
      authority: initInfo.authority,
    }
  })

  const [userId, setUserId] = useState(null)
  const [userAuthority, setUserAuthority] = useState(null)
  const [resultStatus, setResultStatus] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  /**
   배팅로그 조회
   */
  const [params, setParams] = useState(null)

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      authority: userAuthority,
      page,
      size,
    }))
  }, [page, size, userAuthority])

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
  }

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [sportsBettingLogList, setSportsBettingLogList] = useState([])

  const fetchSportsBettingLog = async () => {
    setLoading(true)
    await searchSportsBettingLogV2(params)
      .then(res => {
        const newSportsBettingLogList = res.data.sportsBettingLogList.map(item => ({
          ...item,
          isVisible: false,
        }))
        setSportsBettingLogList(newSportsBettingLogList)
        setTotalElement(res.data.totalElements)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!params) return
    fetchSportsBettingLog()
  }, [params])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 회원 아이디
  const [searchMemberId, setSearchMemberId] = useState(userId || '')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 아이디
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState(resultStatus || '')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(
    resultStatus ? { value: 'ING', label: '진행중' } : null,
  )
  const searchGameResultOption = BettingResultStatusEnumsSelectOptions2()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(startDate || null)
  const [searchEndDate, setSearchEndDate] = useState(endDate || null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [searchTeamName, setSearchTeamName] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      userId: !searchMemberId ? null : searchMemberId.trim(),
      topUserId: !searchTopPartnerId ? null : searchTopPartnerId,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      sportsResultStatus: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      teamName: !searchTeamName ? null : searchTeamName,
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
    }))
  }

  const onClickClearSearchHandler = () => {
    setSearchMemberId('')
    setSearchGameResult(null)
    setSearchGameResultOptionValue(null)
    setSearchEdgeName(null)
    setSearchEdgeNameOptionValue(null)
    setSearchStartDate(null)
    setSearchEndDate(null)
    setSearchTeamName('')
    setParams(prev => ({
      ...prev,
      memberId: null,
      edgeName: null,
      topPartnerId: null,
      sportsResultStatus: null,
      startDate: null,
      endDate: null,
      teamName: null,
      startAmount: null,
      endAmount: null,
      page: 0,
      size,
    }))
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('2')

  const columnMapping = {
    num: '번호',
    edgeName: '사이트명',
    topPartnerName: '상위 파트너',
    authority: '권한',
    bettingTime: '배팅시간',
    memberId: '유저ID',
    memberName: '유저명',
    bettingAmount: '배팅액',
    bettingBeforeAmount: '배팅전잔액',
    bettingAfterAmount: '배팅후잔액',
    bettingExpectedAmount: '예상당첨',
    bettingResultAmount: '결과액',
    folderSize: '폴더수',
    totalOddValue: '총배당',
    resultStatus: '결과',
  }
  const [downloadLoading, setDownloadLoading] = useState(false)
  const downloadMemberListHandler = () => {
    if (downloadLoading) return
    if (
      window.confirm(
        '스포츠 배팅내역 DB를 csv파일로 다운 받으시겠습니까?\n 기간설정은 필수 사항이며, 유저ID/명 검색은 선택사항입니다.',
      )
    ) {
      if (searchStartDate === null || searchEndDate === null) {
        alert('기간 설정은 필수 입니다.')
        return
      }
      if (searchStartDate > searchEndDate) {
        alert('기간을 다시 확인해주세요.')
        return
      }
      // if (searchEndDate - searchStartDate > 604800000) {
      //   alert('두 날짜 간의 차이는 1주일 이하여야 합니다.')
      //   return
      // }

      setDownloadLoading(true)
      downloadMemberSportsList({
        memberId: !searchMemberId ? null : searchMemberId.trim(),
        topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
        startDate: convertToKst(searchStartDate),
        endDate: convertToKst(searchEndDate),
      })
        .then(res => {
          const memberDatabaseList = res.data.content
          const filename = '스포츠 배팅 DB'

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
            filename,
          }
          const csvExporter = new ExportToCsv(options)

          // 컬럼 이름을 한글로 변경하고 CSV 생성
          const mappedMemberList = memberDatabaseList.map(item => {
            const mappedItem = {}
            Object.keys(item).forEach(key => {
              const koreanColumnName = columnMapping[key]
              mappedItem[koreanColumnName] = item[key]
            })
            return mappedItem
          })

          csvExporter.generateCsv(mappedMemberList)
          setDownloadLoading(false)
        })
        .catch(error => {
          setDownloadLoading(false)
          alert('조건에 맞는 내역이 없습니다.')
        })
    }
  }

  const [selectedSportsBettingLog, setSelectedSportsBettingLog] = useState({})

  // 체크박스
  const onCheckSportsBettingLogHandler = sportsBettingLog => {
    setSelectedSportsBettingLog(prev => {
      // 이미 키가 존재하는지 확인
      if (prev[sportsBettingLog.sportsBettingLogKey]) {
        // 키가 존재하면 해당 키를 제거
        const { [sportsBettingLog.sportsBettingLogKey]: value, ...rest } = prev
        return rest
      }
      // 키가 존재하지 않으면 새로운 키 추가
      return { ...prev, [sportsBettingLog.sportsBettingLogKey]: sportsBettingLog }
    })
  }

  // 전체 선택
  const onClickAllSportsBettingLogHandler = () => {
    if (Object.keys(selectedSportsBettingLog).length !== 0) {
      setSelectedSportsBettingLog({})
    } else {
      const temp = sportsBettingLogList.reduce((obj, current, index) => {
        obj[current.sportsBettingLogKey] = current
        return obj
      }, {})

      setSelectedSportsBettingLog(temp)
    }
  }

  // 특정 배팅로그 펼치기
  const onClickSportsBettingLogHandler = sportsBettingLogKey => {
    setSportsBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        if (item.sportsBettingLogKey === sportsBettingLogKey) {
          return { ...item, isVisible: !item.isVisible } // isVisible 토글
        }
        return item // 변경 없이 그대로 반환
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  // 전체 배팅로그 펼치기 / 접기
  const onClickAllSportsBettingLogCollapseHandler = collapse => {
    setSportsBettingLogList(prev => {
      // 맵을 사용하여 각 아이템을 순회하고 조건에 맞는 아이템을 수정
      const updatedList = prev.map(item => {
        return {
          ...item,
          isVisible: collapse,
        }
      })

      return updatedList // 새로운 배열로 상태 업데이트
    })
  }

  const [apiLoading, setApiLoading] = useState(false)

  // 배팅내역 삭제
  const onClickSportsBettingLogDeleteHandler = () => {
    if (apiLoading) return
    if (authority === 'NORMAL') return
    if (Object.keys(selectedSportsBettingLog).length === 0) {
      alert('삭제(비노출) 할 내역들을 선택해주세요.')
      return
    }

    if (Object.values(selectedSportsBettingLog).findIndex(item => item.sportsResultStatus === 'ING') !== -1) {
      alert('진행중인 배팅 건은 삭제(비노출) 할 수 없습니다.')
      return
    }

    if (
      window.confirm(
        `스포츠 배팅내역을 삭제(비노출) 하시겠습니까?\n삭제(비노출) 시 유저에게 해당 배팅내역은 보이지 않습니다.`,
      )
    ) {
      setApiLoading(true)
      const body = {
        sportsBettingLogKeyList: Object.keys(selectedSportsBettingLog),
        allDelete: false,
      }
      deleteSportsBettingLogV2(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
        })
        .finally(() => {
          setApiLoading(false)
          setSelectedSportsBettingLog({})
          fetchSportsBettingLog()
        })
    }
  }

  const onClickSportsBettingLogDeleteHandlerAll = () => {
    if (apiLoading) return
    if (authority === 'NORMAL') return

    if (
      window.confirm(
        `스포츠 배팅내역을 전체삭제(비노출) 하시겠습니까?\n삭제(비노출) 시 유저에게 해당 배팅내역은 보이지 않습니다.`,
      )
    ) {
      setApiLoading(true)
      const body = {
        sportsBettingLogKeyList: [],
        allDelete: true,
      }
      deleteSportsBettingLogV2(body)
        .then(res => {
          alert('삭제(비노출)처리가 완료됐습니다.')
        })
        .catch(error => {
          alert('삭제(비노출) 실패했습니다. 잠시 후 다시 시도해주세요.')
        })
        .finally(() => {
          setApiLoading(false)
          fetchSportsBettingLog()
        })
    }
  }

  // 배팅 내역 취소
  const onClickSportsBettingLogCancelHandler = () => {
    if (apiLoading) return
    if (authority === 'NORMAL') return
    if (Object.keys(selectedSportsBettingLog).length === 0) {
      alert('취소 할 내역들을 선택해주세요.')
      return
    }

    if (Object.values(selectedSportsBettingLog).findIndex(item => item.sportsResultStatus !== 'ING') !== -1) {
      alert('진행중인 배팅에 대해서만 취소할 수 있습니다.')
      return
    }
    if (window.confirm(`스포츠 배팅내역을 취소 하시겠습니까?`)) {
      setApiLoading(true)

      const body = {
        sportsBettingLogKeyList: Object.keys(selectedSportsBettingLog),
      }

      cancelSportsBettingLogV2(body)
        .then(res => {
          alert('취소 처리 되었습니다.')
        })
        .catch(error => {
          const { errorCode, message } = error.response.data
          switch (errorCode) {
            case 'ASSET-7001':
              alert('잠시후 다시 시도해주세요.')
              break
            default:
              alert('취소 처리 실패했습니다.')
          }
        })
        .finally(() => {
          setApiLoading(false)
          setSelectedSportsBettingLog({})
          fetchSportsBettingLog()
        })
    }
  }

  return (
    <Card>
      <CardBody>
        {(loading || apiLoading) && <Loading />}
        <BettingManagementTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당 유저의 자세한 배팅내역을 볼 수 있습니다.</p>
            <p>
              * <span style={{ fontWeight: 'bolder', color: 'red' }}>상위파트너로 검색</span>시, 해당 파트너 포함,
              직영/하부 회원&파트너들의 내역을 가져옵니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchMemberId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상위파트너 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTopPartnerId}
                  onChange={e => onSearchTopPartnerIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>(홈/원정)팀 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchTeamName"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchTeamName}
                  onChange={e => setSearchTeamName(e.target.value)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이상)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchStartAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchStartAmount}
                  onChange={e => onSearchStartAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>당첨액(이하)</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchEndAmount"
                  type="text"
                  placeholder="숫자만"
                  value={searchEndAmount}
                  onChange={e => onSearchEndAmountHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
        </SearchContainer>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              style={{ background: 'blue', color: 'white' }}
              variant="outline-secondary"
              onClick={() => {
                onClickClearSearchHandler()
              }}
            >
              검색어 초기화
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'green', color: 'white' }}
              onClick={e => {
                downloadMemberListHandler()
              }}
            >
              배팅내역 DB다운
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'orange', color: 'white' }}
              onClick={() => {
                onClickAllSportsBettingLogHandler()
              }}
            >
              전체선택
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'grey', color: 'white' }}
              onClick={() => {
                onClickSportsBettingLogDeleteHandler()
              }}
            >
              삭제 (비노출)
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={() => {
                onClickSportsBettingLogDeleteHandlerAll()
              }}
            >
              전체삭제
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={() => {
                onClickSportsBettingLogCancelHandler()
              }}
            >
              배팅취소
            </Button>
          </SearchButtonWrap>

          <SearchButtonWrap style={{ marginLeft: 'auto' }}>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'grey', color: 'white' }}
              onClick={() => {
                onClickAllSportsBettingLogCollapseHandler(true)
              }}
            >
              전체 펼치기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ backgroundColor: 'grey', color: 'white' }}
              onClick={() => {
                onClickAllSportsBettingLogCollapseHandler(false)
              }}
            >
              전체 접기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>

        <SportBettingLogContentWrap>
          <table>
            <thead>
              <tr>
                <th> - </th>
                <th>번호</th>
                <th>사이트명</th>
                <th>배팅일시</th>
                <th>상위파트너 (이름)</th>
                <th>유저 (이름)</th>
                <th>배팅전 머니</th>
                <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액</th>
                <th>배팅후 머니</th>
                <th>타입</th>
                <th>폴더</th>
                <th>배당</th>
                <th>예상/최종 당첨금</th>
                <th>결과</th>
                <th>결과 처리시간</th>
                <th>노출여부</th>
              </tr>
            </thead>
            <tbody>
              {sportsBettingLogList.length !== 0 &&
                sportsBettingLogList.map(sportsBettingLog => {
                  return (
                    <>
                      <tr
                        key={sportsBettingLog.sportsBettingLogKey}
                        onClick={e => {
                          onClickSportsBettingLogHandler(sportsBettingLog.sportsBettingLogKey)
                        }}
                      >
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <input
                              type="checkbox"
                              value=""
                              checked={sportsBettingLog.sportsBettingLogKey in selectedSportsBettingLog}
                              onClick={e => {
                                e.stopPropagation()
                                onCheckSportsBettingLogHandler(sportsBettingLog)
                              }}
                            />
                          </div>
                        </td>
                        <td>{sportsBettingLog.num}</td>
                        <td>{sportsBettingLog.edgeName}</td>
                        <td>{convertToKstSportsEventDateTime(sportsBettingLog.bettingAt)}</td>
                        <td>
                          {sportsBettingLog.topUserId ? (
                            <>
                              {sportsBettingLog.topUserId} ({sportsBettingLog.topUserName})
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              window.open(`/user-info/${sportsBettingLog.userId}`, '_blank')
                            }}
                            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {sportsBettingLog.userId} ({sportsBettingLog.userName})
                          </span>
                          <span style={{ marginLeft: '5px' }}>
                            <CustomAuthorityButton authority={sportsBettingLog.userAuthority}>
                              {NoteAuthorityEnums[sportsBettingLog.userAuthority]}
                            </CustomAuthorityButton>
                          </span>
                          <span>
                            <span>
                              <MemberDepositInfo memberId={sportsBettingLog.userId} />
                            </span>
                            <span>
                              <MemberCoupon memberId={sportsBettingLog.userId} />
                            </span>
                            <span>
                              <MemberNote memberId={sportsBettingLog.userId} />
                            </span>
                            <span>
                              <MemberMoneyIncDecMini memberId={sportsBettingLog.userId} />
                            </span>
                          </span>
                        </td>
                        <td>{sportsBettingLog.bettingBeforeAmount.toString().replace(commonReg2, ',')}</td>
                        <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                          {sportsBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                        </td>
                        <td>{sportsBettingLog.bettingAfterAmount.toString().replace(commonReg2, ',')}</td>
                        <CustomTd sportMarketType={sportsBettingLog.sportMarketType}>
                          {SportMarketType[sportsBettingLog.sportMarketType]}
                        </CustomTd>
                        <td>{sportsBettingLog.folderSize} 폴더</td>
                        <td>
                          {(() => {
                            const {
                              realReduceBettingPerForSingleFolder,
                              realReduceBettingPerForCombination,
                              totalOddValue,
                              originTotalOddValue,
                            } = sportsBettingLog
                            const reduceBettingValue =
                              realReduceBettingPerForSingleFolder ?? realReduceBettingPerForCombination

                            if (reduceBettingValue === null) {
                              return <span>{totalOddValue}</span>
                            }

                            return (
                              <TooltipWrapper text="적용배당 / (원본배당 - 조정배당)" place="bottom">
                                {Number(reduceBettingValue) < 0 ? (
                                  <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)}{' '}
                                    {reduceBettingValue?.toFixed(2)})
                                  </span>
                                ) : (
                                  <span style={{ color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>
                                    ({totalOddValue?.toFixed(2)} / {originTotalOddValue?.toFixed(2)} +{' '}
                                    {reduceBettingValue?.toFixed(2)})
                                  </span>
                                )}
                              </TooltipWrapper>
                            )
                          })()}
                        </td>

                        <td>
                          <span style={{ color: 'blue' }}>
                            {sportsBettingLog.bettingExpectedAmount.toString().replace(commonReg2, ',')}
                          </span>
                          <span style={{ margin: '0 5px' }}>/</span>
                          <span style={{ color: 'red' }}>
                            {sportsBettingLog.bettingResultAmount.toString().replace(commonReg2, ',')}
                          </span>
                        </td>
                        <td>
                          <CustomButton type="button" bettingResult={sportsBettingLog.sportsResultStatus}>
                            {sportResultStatusEnums[sportsBettingLog.sportsResultStatus]}
                          </CustomButton>
                        </td>
                        <td>{convertToKstSportsEventDateTime(sportsBettingLog.resultExecuteAt)}</td>
                        <td>
                          {sportsBettingLog.showFlag ? <span>노출</span> : <span style={{ color: 'red' }}>비노출</span>}
                        </td>
                      </tr>
                      {sportsBettingLog.isVisible && (
                        <SportDetailBettingLog
                          sportsDetailBettingLogList={sportsBettingLog.sportsDetailBettingLogList}
                          bonsuFolder={{
                            bonusFolderNum: sportsBettingLog.bonusFolderNum,
                            bonusFolderPer: sportsBettingLog.bonusFolderPer,
                          }}
                        />
                      )}
                    </>
                  )
                })}
              {sportsBettingLogList.length === 0 && (
                <tr>
                  <td colSpan={16}>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: '#000',
                        fontSize: '15px',
                        padding: '20px 0px',
                      }}
                    >
                      최근 게시물이 없습니다.
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportBettingLogContentWrap>
      </CardBody>
    </Card>
  )
}

export default SportBettingLogListV2

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const getColor2 = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomTd = styled.td`
  color: #000;
  font-weight: 600;
  background: ${props => getColor2(props.sportMarketType)} !important;
`
const SportBettingLogContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const CustomTh = styled.th`
  background: green !important;
`

const CustomTh2 = styled.th`
  background: rgb(0, 104, 86) !important;
`

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead`
  th {
    font-size: 12px;
  }
`

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
  font-size: 13px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  cursor: pointer;
  &:hover {
    border: solid 2px red;
  }
  background: lightgray;
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`
const SportsGameFolderEdgeBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderNumBox = styled.div`
  width: 4%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameDeleteBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFoldeShowBox = styled.div`
  width: 3%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 1);
  color: ${props => (props.showStatus ? 'blue' : 'red')} !important;
`

const SportsGameFolderScoreBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderResultBox = styled.div`
  width: 10%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-weight: 500;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  border-right: 1px solid rgba(0, 0, 0, 1);
`
const SportsGameFolderContentCriteria = styled.div`
  font-weight: 500;
  color: green;
  width: 9%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgba(0, 128, 0, 1) !important;
  opacity: 0.6;
  color: white !important;
`

const SportsGameFolderContentCriteria2 = styled.div`
  font-weight: 500;
  color: green;
  width: 7.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 1);
  border-right: 1px solid rgba(0, 0, 0, 1);
  background: rgb(0, 104, 86, 0.6) !important;
  color: white !important;
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 12%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGamePartnerBox = styled.div`
  width: 11%;
  display: flex;
  border-right: 1px solid rgba(0, 0, 0, 1);
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameMoneyContent = styled.div`
  width: 9%;
  display: flex;
  // border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentTime = styled.div`
  width: 6%;
  display: flex;
  border-right: solid 1px black;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  height: 46px;
  border-left: solid 1px black;
`
