import React from 'react'
import { convertToKstDate } from '../../../utils/dateTime'
import { PartnerStatusEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import PartnerApproveRegistrationRadio from './PartnerApproveRegistrationRadio'
import { CustomTableWrap } from '../../../shared/components/TableElements'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import { PartnerLosingType } from '../../../enums/Statistics/partnerLosingStatistics'
// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const PartnerApplyListData = (content, setRegistrationApprove, fetchSearchPartner) => {
  const columns = [
    {
      Header: '승인',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <PartnerApproveRegistrationRadio rowPartnerInfo={original} setRegistrationApprove={setRegistrationApprove} />
        )
      },
      width: 50,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: '상위 파트너ID(명)',
      accessor: 'topPartnerId',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div style={{ width: '100%' }}>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.topPartnerId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.topPartnerId}({original.topPartnerName})
              </span>
              <span>
                <MemberCoupon memberId={original.topPartnerId} />
              </span>
              <span>
                <MemberNote memberId={original.topPartnerId} />
              </span>
            </span>
          </div>
        )
      },
    },
    {
      Header: '상위 파트너LV',
      accessor: 'topPartnerLevel',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ color: 'blue', fontWeight: '600' }}>{`${original.topPartnerLevel}LV`}</span>
      },
    },
    {
      Header: '하위 파트너 ID(명)',
      accessor: 'downPartnerId',
      width: 115,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div style={{ width: '100%' }}>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.downPartnerId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.downPartnerId}({original.downPartnerName})
              </span>
            </span>
          </div>
        )
      },
    },
    {
      Header: '하위 파트너LV',
      accessor: 'downPartnerLevel',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ color: 'red', fontWeight: '600' }}>{`${original.downPartnerLevel}LV`}</span>
      },
    },
    {
      Header: '파트너 정산방식',
      accessor: 'downPartnerSettlementType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.settlementType) {
          default:
            return (
              <span style={{ color: 'red', fontWeight: '900' }}>
                {PartnerLosingType[original.downPartnerSettlementType]}
              </span>
            )
        }
      },
    },
    {
      Header: '신청일',
      accessor: 'registrationDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 150,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDate(original.registrationDate)
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 150,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDate(original.executeDate)
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'NORMAL':
            return <span style={{ color: 'blue', fontWeight: '900' }}>{PartnerStatusEnums[original.status]}</span>
          case 'WAITING':
            return <span style={{ color: 'orange', fontWeight: '900' }}>{PartnerStatusEnums[original.status]}</span>
          case 'CANCEL':
            return <span style={{ color: 'red', fontWeight: '900' }}>{PartnerStatusEnums[original.status]}</span>
          case 'APPLY':
            return <span style={{ color: 'green', fontWeight: '900' }}>{PartnerStatusEnums[original.status]}</span>
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>{PartnerStatusEnums[original.status]}</span>
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default PartnerApplyListData
