import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setActiveSportMarketTypeMenu } from '../../../redux/SportMarketTypeInfoSlice'

const MarketTypeMenu = () => {
  const dispatch = useDispatch()

  const { activeMarketBookmakerMenu } = useSelector(state => {
    const { bookMakerInfo } = state

    return {
      activeMarketBookmakerMenu: bookMakerInfo.activeMarketBookmakerMenu,
    }
  })

  // Redux store에서 필요한 상태를 가져옵니다.
  const { sportMarketTypeMenu, activeSportMarketTypeMenu } = useSelector(state => state.sportMarketTypeInfo)

  const activeMarketBookmakerMenuRef = useRef(activeMarketBookmakerMenu)

  // 메뉴 클릭 핸들러
  const onClickSportMarketTypeHandler = useCallback(
    sportMarketTypeId => {
      if (
        activeMarketBookmakerMenuRef.current !== 8 &&
        activeMarketBookmakerMenuRef.current !== 999 &&
        sportMarketTypeId === 'LIVE'
      ) {
        alert('현재 [ 라이브 ]는 [ Bet365 또는 STM ]만 지원합니다.')
      }

      dispatch(setActiveSportMarketTypeMenu(sportMarketTypeId))
    },
    [dispatch],
  )

  useEffect(() => {
    activeMarketBookmakerMenuRef.current = activeMarketBookmakerMenu
    dispatch(setActiveSportMarketTypeMenu('DOMESTIC'))
  }, [activeMarketBookmakerMenu, dispatch])

  return (
    <MenuBoxWrap>
      {sportMarketTypeMenu.map(sportMarketTypeMenu => {
        return (
          <MenuTitleBox
            key={sportMarketTypeMenu.sportMarketTypeId}
            active={activeSportMarketTypeMenu === sportMarketTypeMenu.sportMarketTypeId}
            onClick={() => {
              onClickSportMarketTypeHandler(sportMarketTypeMenu.sportMarketTypeId)
            }}
          >
            {sportMarketTypeMenu.sportMarketTypeName}
          </MenuTitleBox>
        )
      })}
      <span style={{ display: 'flex', gap: '5px', marginLeft: '20px' }}>
        <span>
          <CustomSpan sportMarketType="DOMESTIC">&nbsp;</CustomSpan>: 국내형
        </span>
        <span>
          <CustomSpan sportMarketType="OVERSEAS">&nbsp;</CustomSpan>: 해외형
        </span>
        <span>
          <CustomSpan sportMarketType="SPECIAL">&nbsp;</CustomSpan>: 스페셜
        </span>
        <span>
          <CustomSpan sportMarketType="LIVE">&nbsp;</CustomSpan>: 라이브
        </span>
      </span>
    </MenuBoxWrap>
  )
}

export default React.memo(MarketTypeMenu)

const getColor = sportMarketType => {
  switch (sportMarketType) {
    case 'DOMESTIC':
      return '#fcfc9a'
    case 'SPECIAL':
      return '#b8ffa6'
    case 'OVERSEAS':
      return '#f8a4fc'
    case 'LIVE':
      return '#fad2a5'
    default:
      return 'lightgrey'
  }
}

const CustomSpan = styled.span`
  width: 40px;
  display: inline-block;
  background: ${props => getColor(props.sportMarketType)} !important;
`

const MenuBoxWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 5px 0;
`

const MenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 1px solid black;
  `};
`
