import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'

const MemberStatisticsBettingSlotCasino = ({ slotCasino }) => {
  const getResultAmount = (num1, num2) => {
    const resultAmount = Number(num1) - Number(num2)

    if (resultAmount > 0) {
      return (
        <span style={{ color: 'blue', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
      )
    }
    if (resultAmount < 0) {
      return <span style={{ color: 'red', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
    }
    return <span style={{ color: 'black', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
  }
  return (
    <MemberInfoWrap>
      <table>
        <tbody>
          <tr>
            <th>오늘 배팅액</th>
            <td>{slotCasino.dailySlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 당첨액</th>
            <td>{slotCasino.dailySlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 롤링액(본인)</th>
            <td>{slotCasino.dailySlotCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>오늘 차액(배팅-당첨)</th>
            <td>{getResultAmount(slotCasino.dailySlotCasinoBettingAmount, slotCasino.dailySlotCasinoWinningAmount)}</td>
          </tr>
          <tr>
            <th>이번달 배팅액</th>
            <td>{slotCasino.monthSlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 당첨액</th>
            <td>{slotCasino.monthSlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 롤링액(본인)</th>
            <td>{slotCasino.monthSlotCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>이번달 차액(배팅-당첨)</th>
            <td>{getResultAmount(slotCasino.monthSlotCasinoBettingAmount, slotCasino.monthSlotCasinoWinningAmount)}</td>
          </tr>
          <tr>
            <th>기간내 배팅액</th>
            <td>{slotCasino.dateSlotCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 당첨액</th>
            <td>{slotCasino.dateSlotCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 롤링액(본인)</th>
            <td>{slotCasino.dateSlotCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>기간내 차액(배팅-당첨)</th>
            <td>{getResultAmount(slotCasino.dateSlotCasinoBettingAmount, slotCasino.dateSlotCasinoWinningAmount)}</td>
          </tr>
        </tbody>
      </table>
    </MemberInfoWrap>
  )
}

export default MemberStatisticsBettingSlotCasino

MemberStatisticsBettingSlotCasino.propTypes = {
  slotCasino: PropTypes.shape({
    dailySlotCasinoBettingAmount: PropTypes.string,
    dailySlotCasinoWinningAmount: PropTypes.string,
    dailySlotCasinoRollingAmount: PropTypes.string,
    dailySlotCasinoProfitAmount: PropTypes.string,
    monthSlotCasinoBettingAmount: PropTypes.string,
    monthSlotCasinoWinningAmount: PropTypes.string,
    monthSlotCasinoRollingAmount: PropTypes.string,
    monthSlotCasinoProfitAmount: PropTypes.string,
    dateSlotCasinoBettingAmount: PropTypes.string,
    dateSlotCasinoWinningAmount: PropTypes.string,
    dateSlotCasinoRollingAmount: PropTypes.string,
    dateSlotCasinoProfitAmount: PropTypes.string,
  }).isRequired,
}

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 150px;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: grey !important;
    padding: 10px 4px;
    border: 1px solid black;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
