import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 카지노 라이브 레벨 조회
export async function searchCasinoLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/casino-live',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 카지노 라이브 레벨 수정
export async function updateCasinoLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/casino-live',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 카지노 슬롯 레벨 조회
export async function searchCasinoSlotLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/casino-slot',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 추천인콤프 레벨 조회
export async function searchFriendCompLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/friend-comp-level',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 입금 레벨 수정
export async function updateFriendCompLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/friend-comp-level',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 카지노 라이브 레벨 수정
export async function updateCasinoSlotLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/casino-slot',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 미니게임 레벨 조회
export async function searchMiniGameLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/mini-game',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 입금 레벨 조회
export async function searchDepositLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/deposit',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 입금 레벨 수정
export async function updateDepositLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/deposit',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 미니게임 레벨 수정
export async function updateMiniGameLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/mini-game',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 레벨 조회
export async function searchSportsGameLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/sports-game',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 레벨 수정
export async function updateSportsGameLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/sports-game',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

export async function searchLevelList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 레벨 조회
export async function searchHoldemLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/holdem',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 홀덤 레벨 수정
export async function updateHoldemLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/holdem',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}

// 가상게임 레벨 조회
export async function searchAdminVirtualGameLevel(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/virtual-game',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 가상게임 레벨 수정
export async function updateAdminVirtualGameLevel(body) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/level/virtual-game',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })
  return response
}
