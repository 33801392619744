import ReactTableBase from '@/shared/components/table/ReactTableBase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MemberSportsDetailBettingHistoryListData from './MemberSportsDetailBettingHistoryListData'

const MemberSportsDetailBettingHistory = ({ content }) => {
  const [bettingLogListData, setBettingLogListData] = useState(MemberSportsDetailBettingHistoryListData(content))
  useEffect(() => {
    setBettingLogListData(MemberSportsDetailBettingHistoryListData(content))
  }, [content])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  return (
    <SportsGameListHiddenWrap>
      <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} />
    </SportsGameListHiddenWrap>
  )
}

export default MemberSportsDetailBettingHistory

MemberSportsDetailBettingHistory.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape()),
}

MemberSportsDetailBettingHistory.defaultProps = {
  content: [],
}

const SportsGameListHiddenWrap = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`
