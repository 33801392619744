import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button, Modal as BootstrapModal } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { ButtonToolbar } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { SearchCardTitleWrap } from '@/shared/components/Card'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import { bankStatusEnumsRadioOptions } from '../../../enums/SystemManagement/bankEnums'
import { decodeAccessToken } from '../../../utils/token'
import { updateBank } from '../../../utils/api/memberManage/memberManageApi'

const BankModifyModal = ({
  bankKey,
  bankName,
  bankOrderNum,
  bankStatus,
  isBankModifyModalShow,
  setIsBankModifyModalShow,
  fetchAdminSearchBankList,
}) => {
  const authority = decodeAccessToken()?.adminAuthority

  const onClickHandler = () => {
    setIsBankModifyModalShow(prevState => !prevState)
    fetchAdminSearchBankList()
  }

  const [newBankOrderNum, setNewBankOrderNum] = useState(bankOrderNum)

  const onSubmit = e => {
    const body = {
      bankKey,
      bankOrderNum: newBankOrderNum,
      bankStatus: e.bankStatus,
    }

    updateBank(body)
      .then(res => {
        alert('수정되었습니다.')
        onClickHandler()
      })
      .catch(err => {
        alert('수정 실패했습니다.')
        onClickHandler()
      })
  }

  return (
    <StyledModal show={isBankModifyModalShow} onHide={onClickHandler} $header>
      <ModalHeader>
        <ModalCloseButton className="lnr lnr-cross" aria-label="close-btn" type="button" onClick={onClickHandler} />
        <ModalTitle>은행 순서 변경</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>* 순서는 낮은 수가 제일 먼저 보입니다.</p>
          </div>
        </SearchCardTitleWrap>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer
              horizontal
              onSubmit={handleSubmit}
              initialValue={{
                bankName,
                newBankOrderNum,
                bankStatus,
              }}
            >
              <FormGroup>
                <FormGroupLabel>은행명</FormGroupLabel>
                <FormGroupField>
                  <Field name="bankName">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={bankName}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>순서</FormGroupLabel>
                <FormGroupField>
                  <Field name="newBankOrderNum">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={newBankOrderNum}
                          onChange={e => {
                            const { value } = e.target
                            const onlyNumber = value.replace(/[^0-9]/g, '')

                            setNewBankOrderNum(onlyNumber)
                            // input.onChange(e)
                          }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>은행 상태</FormGroupLabel>
                {bankStatusEnumsRadioOptions().map(item => (
                  <FormGroupField
                    key={`index_${item.label}`}
                    style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                  >
                    <Field
                      name={`${item.name}`}
                      component={renderRadioButtonField}
                      label={item.label}
                      radioValue={item.radioValue}
                      initialValue={bankStatus}
                    />
                  </FormGroupField>
                ))}
              </FormGroup>
              {
                {
                  SUPER: (
                    <FormButtonToolbar>
                      <Button variant="primary" type="submit">
                        수정하기
                      </Button>
                    </FormButtonToolbar>
                  ),
                }[authority]
              }
            </FormContainer>
          )}
        </Form>
      </ModalBody>
    </StyledModal>
  )
}

export default BankModifyModal

BankModifyModal.propTypes = {
  bankKey: PropTypes.string,
  bankName: PropTypes.string,
  bankOrderNum: PropTypes.number,
  bankStatus: PropTypes.string,
  isBankModifyModalShow: PropTypes.bool.isRequired,
  setIsBankModifyModalShow: PropTypes.func.isRequired,
  fetchAdminSearchBankList: PropTypes.func.isRequired,
}

BankModifyModal.defaultProps = {
  bankKey: '',
  bankName: '',
  bankOrderNum: '',
  bankStatus: '',
}

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 800px !important;
  }

  .modal-content {
    height: max-content;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
