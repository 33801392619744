import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../../shared/components/Tabs'

const VirtualGameManagementTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/management/system/virtual-game-list')
                }}
              >
                가상게임 관리
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/management/system/virtual-game')
                }}
              >
                가상게임 설정
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/management/system/virtual-game/soccer')
                }}
              >
                가상축구 팀
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/management/system/virtual-game/basketball')
                }}
              >
                가상농구 팀
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/management/system/virtual-game/baseball')
                }}
              >
                가상야구 팀
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default VirtualGameManagementTabs

VirtualGameManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
