import axios from '../base/axios'

// 와일드게임즈 게임 리스트 조회
export async function searchWildGamesGameList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/game-list',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 게임 수정
export async function updateWildGamesGameInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/game-info',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅내역 조회
export async function searchWildGamesBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅 내역 삭제
export async function deleteWildGamesBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/betting-log/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅 내역 db 다운
export async function adminDownloadWildGamesBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/download/wild-games/betting-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 디테일 배팅 내역 조회
export async function searchWildGamesDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/betting-log-detail',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅 세부 내역 삭제
export async function deleteWildGamesDetailBettingLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/betting-log-detail/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅 세부 내역 db 다운
export async function adminDownloadWildGamesDetailBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/download/wild-games/betting-log-detail',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 디테일 배팅 내역 조회
export async function searchWildGamesGameEventLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/event-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅 세부 내역 삭제
export async function deleteWildGamesGameEventLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/wild-games/event-log/delete',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅 세부 내역 db 다운
export async function adminDownloadWildGamesGameEventLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/download/wild-games/event-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
