export const bannerSizeEnums = {
  DEMO4_WEB_LEFT: 'PC 왼쪽 베너: 615*265 ',
  DEMO4_WEB_RIGHT: 'PC 오른쪽 배너 615*265',
  DEMO4_MOBLIE: '모바일 배너 696*300',
  DEMO8_ALL_WEB_MOBILE: 'PC&모바일 베너: 1400*500 ',
}

export const bannerStatusEnums = {
  SHOW: '노출',
  HIDE: '숨김',
}

export const bannerTargetEnums = {
  ALL: 'PC&모바일',
  WEB: 'PC',
  MOBILE: '모바일',
}

export const bannerPositionEnums = {
  LEFT: '왼쪽',
  RIGHT: '오른쪽',
  CENTER: '중앙',
}

export const bannerClickEnums = {
  true: '허용',
  false: '비허용',
}

export const bannerMoreClickEnums = {
  true: '허용',
  false: '비허용',
}

export const bannerTypeEnums = {
  NOTICE: '공지사항',
  BETTING_RULE: '배팅규정',
  EVENT: '이벤트',
}

export const bannerStatusEnumsSelectOptions = () => {
  return Object.entries(bannerStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bannerTargetEnumsSelectOptions = () => {
  return Object.entries(bannerTargetEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bannerPositionEnumsSelectOptions = () => {
  return Object.entries(bannerPositionEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bannerClickEnumsSelectOptions = () => {
  return Object.entries(bannerClickEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bannerTypeEnumsSelectOptions = () => {
  return Object.entries(bannerTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const bannerStatusEnumsRadioOptions = () => {
  return Object.entries(bannerStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bannerStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bannerStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const bannerTargetEnumsRadioOptions = () => {
  return Object.entries(bannerTargetEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bannerTarget',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bannerTarget',
          label: value,
          radioValue: key,
        },
  )
}

export const bannerPositionEnumsRadioOptions = () => {
  return Object.entries(bannerPositionEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bannerPosition',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bannerPosition',
          label: value,
          radioValue: key,
        },
  )
}

export const bannerClickEnumsRadioOptions = () => {
  return Object.entries(bannerClickEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bannerClick',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bannerClick',
          label: value,
          radioValue: key,
        },
  )
}

export const bannerMoreClickEnumsRadioOptions = () => {
  return Object.entries(bannerMoreClickEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'moreClickShow',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'moreClickShow',
          label: value,
          radioValue: key,
        },
  )
}

export const bannerTypeEnumsRadioOptions = () => {
  return Object.entries(bannerTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'bannerType',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'bannerType',
          label: value,
          radioValue: key,
        },
  )
}
