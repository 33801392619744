import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { searchMemberInfo, updateMemberRecommendedCode } from '@/utils/api/memberManage/memberManageApi'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../../../shared/components/Loading'
import { UserAuthorityEnums } from '../../../../../enums/MemberManage/MemberInfoEnums'
import { decodeAccessToken } from '../../../../../utils/token'

function RecommendModalModifyForm({ memberId, onModalShowHandler, fetchSearchMember }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [infoLoading, setInfoLoading] = useState(false)

  const [memberInfo, setMemberInfo] = useState({})
  const [newRecommendedCode, setNewRecommendedCode] = useState(null)
  const getMemberInfo = async () => {
    await searchMemberInfo({
      memberId,
    }).then(res => {
      setMemberInfo(res.data.content)
    })
  }

  useEffect(() => {
    if (memberId === null) return
    getMemberInfo()
  }, [memberId])

  const onSubmit = e => {
    if (infoLoading) return
    if (!window.confirm(`[${memberInfo?.memberName}] 유저의 추천코드를 수정하시겠습니까?`)) {
      return
    }

    if (memberInfo.status === 'CANCEL' || memberInfo.status === 'WAITING' || memberInfo.status === 'APPLY') {
      alert('유저의 상태가 신청,대기,취소 인 경우에는 회원정보를 변경할 수 없습니다.')
      return
    }

    const body = {
      memberKey: memberInfo.memberKey,
      newRecommendedCode: !e.newRecommendedCode ? null : e.newRecommendedCode.trim(),
    }
    setInfoLoading(true)

    updateMemberRecommendedCode(body)
      .then(res => {
        setInfoLoading(false)
        onModalShowHandler()
        alert('유저의 추천코드 정보가 수정되었습니다.')
        fetchSearchMember()
      })
      .catch(error => {
        setInfoLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'SIGN-1005') {
          alert(`${message}`)
        } else {
          alert('유저의 추천코드 수정이 실패했습니다.')
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        memberId: `${memberInfo?.memberId}(${memberInfo?.memberName})`,
        memberName: memberInfo?.memberName,
        recommendedCode: memberInfo?.recommendedCode,
        newRecommendedCode,
        userAuthority: memberInfo?.userAuthority,
      }}
    >
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          {infoLoading ? (
            <Loading />
          ) : (
            <>
              <FormGroup>
                <FormGroupLabel>유저ID(명)</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>권한</FormGroupLabel>
                <FormGroupField>
                  <Field name="userAuthority">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input {...input} type="text" value={UserAuthorityEnums[input.value]} disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>현재 추천코드</FormGroupLabel>
                <FormGroupField>
                  <Field name="recommendedCode">
                    {({ input, meta }) => (
                      <FormInputWrap style={{ border: 'solid 1px #f2f4f7', backgroundColor: '#F2F4F7' }}>
                        <input {...input} type="text" value={input.value} disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>변경 추천코드</FormGroupLabel>
                <FormGroupField>
                  <Field name="newRecommendedCode">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewRecommendedCode(e.target.value)
                          }}
                          placeholder="없앨경우, 빈칸으로 놔두고 변경하기 클릭해주세요."
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
                <FormGroupDescription style={{ marginTop: '10px' }}>
                  * 유저 권한이 파트너일 경우, <span style={{ color: 'blue', fontWeight: '600' }}>추천코드</span>로
                  가입한 회원은
                  <br /> 해당 유저의 피추천인이 아니라, 직영회원이 됩니다.
                </FormGroupDescription>
              </FormGroup>
              {
                {
                  SUPER: (
                    <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                      <CustomButton variant="primary" type="submit">
                        변경하기
                      </CustomButton>
                    </FormButtonToolbar>
                  ),
                }[authority]
              }
            </>
          )}
        </FormContainer>
      )}
    </Form>
  )
}

export default RecommendModalModifyForm

RecommendModalModifyForm.propTypes = {
  fetchSearchMember: PropTypes.func.isRequired,
  onModalShowHandler: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`
