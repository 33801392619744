import { Button, ButtonToolbar } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { borderRight, flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorFieldsBorder,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import { updateRecoveryPer } from '../../../utils/api/systemManage/callManageApi'
import { callManageRecoverPerValidate } from '../../../utils/validate/systemManagement/callManage'

function CallUserRecoveryPerModal({
  memberInfo,
  recoveryPerModalShow,
  onRecoveryPerModalShowHandler,
  fetchGetCallUsers,
}) {
  const [memberId, setMemberId] = useState(memberInfo?.memberId)
  const [memberName, setMemberName] = useState(memberInfo?.memberName)
  const [vendorName, setVendorName] = useState(memberInfo?.vendorName)
  const [gameTitle, setGameTitle] = useState(memberInfo?.gameTitle)
  const [currentRtp, setCurrentRtp] = useState(memberInfo?.targetRtp)
  const [afterRtp, setAfterRtp] = useState(0)

  const [apiLoading, setApiLoading] = useState(false)

  const onSubmit = e => {
    if (apiLoading) return
    setApiLoading(true)

    const body = {
      vendorName,
      memberId,
      recoveryPer: e.afterRtp || 0,
    }

    if (window.confirm(`${memberId} 에게 환수율 ${e.afterRtp}을 적용하시겠습니까? `)) {
      updateRecoveryPer(body)
        .then(res => {
          alert(`${memberId} 의 환수율 조정이 되었습니다.`)
          fetchGetCallUsers()
          onRecoveryPerModalShowHandler()
          setApiLoading(false)
        })
        .catch(error => {
          alert(`${memberId} 의 환수율 조정이 실패하였습니다. 다시 시도해주세요.`)
          setApiLoading(false)
        })
    } else {
      setApiLoading(false)
    }
  }

  return (
    <StyledModal show={recoveryPerModalShow} onHide={onRecoveryPerModalShowHandler} $header>
      <ModalHeader>
        <ModalCloseButton
          className="lnr lnr-cross"
          aria-label="close-btn"
          type="button"
          onClick={onRecoveryPerModalShowHandler}
        />
        <ModalTitle>유저 환수율 조정</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form
          onSubmit={onSubmit}
          initialValues={{
            memberId,
            memberName,
            vendorName,
            gameTitle,
            currentRtp,
            afterRtp,
          }}
          validate={callManageRecoverPerValidate}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel>아이디</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>회원명</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberName">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>게임사</FormGroupLabel>
                <FormGroupField>
                  <Field name="vendorName">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>게임명</FormGroupLabel>
                <FormGroupField>
                  <Field name="gameTitle">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>현재 환수율</FormGroupLabel>
                <FormGroupField>
                  <Field name="currentRtp">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>변경할 환수율</FormGroupLabel>
                <FormGroupField>
                  <Field name="afterRtp">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                        />
                        {meta.error && <Error error={meta.error} />}
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                <CustomButton variant="primary" type="submit">
                  변경하기
                </CustomButton>
              </FormButtonToolbar>
            </FormContainer>
          )}
        </Form>
      </ModalBody>
    </StyledModal>
  )
}

CallUserRecoveryPerModal.propTypes = {
  memberInfo: PropTypes.shape({
    memberId: PropTypes.string,
    memberName: PropTypes.string,
    vendorName: PropTypes.string,
    gameTitle: PropTypes.string,
    gameId: PropTypes.string,
    bettingAmount: PropTypes.string,
    targetRtp: PropTypes.string,
  }).isRequired,
  recoveryPerModalShow: PropTypes.bool.isRequired,
  onRecoveryPerModalShowHandler: PropTypes.func.isRequired,
  fetchGetCallUsers: PropTypes.func.isRequired,
}

export default CallUserRecoveryPerModal

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 385px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.$header &&
    `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion

export const EventServiceStatusSelect = styled(Select)`
  width: 120%;
  height: 40px;
  font-size: 12px;
  margin-left: 30px;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
