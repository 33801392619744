import { Button } from '@/shared/components/Button'
import { CustomDatePickerWrap } from '@/shared/components/CustomDatePicker'
import { MemberCouponSelect } from '@/shared/components/CustomSelect'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import Error from '@/shared/components/form/Error'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Form, Field } from 'react-final-form'
import { searchCouponSelectList, publishMemberCoupon } from '@/utils/api/operation/operationApi'
import { convertToKst } from '@/utils/dateTime'
import Loading from '../../../../shared/components/Loading'
import { decodeAccessToken } from '../../../../utils/token'
import { memberCouponValidate } from '../../../../utils/validate/memberManage/couponValidate'

function MemberCouponForm({ memberId, onClickHandler }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [couponLoading, setCouponLoading] = useState(false)
  const [couponMoney, setCouponMoney] = useState('')
  const [couponTitle, setCouponTitle] = useState('')

  const [couponExpireDate, setCouponExpireDate] = useState(null)
  const onCouponExpireDateChange = date => {
    setCouponExpireDate(date)
  }

  const onSubmit = e => {
    if (couponLoading) return
    if (couponExpireDate !== null && couponExpireDate < new Date()) {
      alert('쿠폰만료일을 오늘 날짜 이후로 선택해주세요.')
      return
    }

    const body = {
      userId: memberId,
      couponTitle: !couponTitle ? null : couponTitle,
      couponMoney,
      couponExpireDate: !couponExpireDate ? null : couponExpireDate,
    }
    setCouponLoading(true)
    publishMemberCoupon(body)
      .then(res => {
        setCouponLoading(false)
        onClickHandler()
        alert('쿠폰이 발행되었습니다.')
        window.location.href = '/management/log/couponpay'
      })
      .catch(error => {
        setCouponLoading(false)
        alert('쿠폰 발행에 실패했습니다.')
      })
  }

  return (
    <>
      {couponLoading ? (
        <Loading />
      ) : (
        <>
          <div style={{ marginTop: '-15px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>쿠폰명</span> 입력 시, 해당 쿠폰명으로 유저에게 보이게 됩니다.
            </p>
            <p>
              * <span style={{ color: 'red' }}>만료일</span>를 입력하지 않으면 무기한으로 설정됩니다.
            </p>
          </div>
          <Form onSubmit={onSubmit} validate={memberCouponValidate} initialValues={{ couponMoney }}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>아이디</FormGroupLabel>
                  <FormGroupField>
                    <input name="memberId" type="text" value={memberId} disabled />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>쿠폰명</FormGroupLabel>
                  <FormGroupField>
                    <Field name="couponTitle" type="text" placeholder="쿠폰명을 입력하세요">
                      {({ input, meta }) => (
                        <FormInputWrap>
                          <input
                            {...input}
                            type="text"
                            value={couponTitle}
                            onChange={e => {
                              input.onChange(e)
                              setCouponTitle(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>금액(원)</FormGroupLabel>
                  <FormGroupField>
                    <Field name="couponMoney">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={couponMoney}
                            onChange={e => {
                              input.onChange(e)
                              setCouponMoney(e.target.value)
                            }}
                            placeholder="숫자만 입력하세요"
                          />
                          {meta.error && meta.touched && <Error error={meta.error} />}
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>만료일</FormGroupLabel>
                  <FormGroupField>
                    <CustomDatePickerWrap>
                      <DatePicker
                        selected={couponExpireDate}
                        onChange={date => onCouponExpireDateChange(date)}
                        dateFormat="yyyy-MM-dd HH:mm:00"
                        dropDownMode="select"
                        popperPlacement="bottom-start"
                        placeholderText="만료일"
                        isClearable
                      />
                    </CustomDatePickerWrap>
                  </FormGroupField>
                </FormGroup>
                {
                  {
                    SUPER: (
                      <FormButtonToolbar>
                        <Button variant="primary" type="submit">
                          발행하기
                        </Button>
                      </FormButtonToolbar>
                    ),
                  }[authority]
                }
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default MemberCouponForm

MemberCouponForm.propTypes = {
  memberId: PropTypes.string.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}
