import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import { SportsMarketTypeEnums } from '../../../../enums/SystemManagement/sportEnums'
import { CustomSelect } from '../../../../shared/components/CustomSelect'
import Loading from '../../../../shared/components/Loading'
import { updateSportsMarketCombinationPer } from '../../../../utils/api/sport/sportSettingApi'
import { decodeAccessToken } from '../../../../utils/token'

function MarketCombinationModifyModalForm({ marketCombintationInfo, onModalShowHandler, fetchSportsMixed }) {
  const [infoLoading, setInfoLoading] = useState(false)
  const [newMarketCombinationPer, setNewMarketCombinationPer] = useState(0)

  useEffect(() => {
    if (!marketCombintationInfo) return

    setNewMarketCombinationPer(marketCombintationInfo.marketCombinationPer)
  }, [marketCombintationInfo])

  const newSubmit = e => {}
  const [writeLoading, setWriteLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority

  const onSubmit = e => {
    if (writeLoading) return
    if (authority === 'NORMAL') return
    if (!newMarketCombinationPer && !Number(newMarketCombinationPer)) {
      alert('조합 배당조정 값을 다시 확인해주세요.')
      return
    }
    if (window.confirm('해당 조합의 배당조정을 변경 하시겠습니까?')) {
      setWriteLoading(true)
      const body = {
        marketCombinationKey: marketCombintationInfo.marketCombinationKey,
        marketCombinationPer: !newMarketCombinationPer ? 0.0 : Number(newMarketCombinationPer),
      }
      updateSportsMarketCombinationPer(body)
        .then(res => {
          alert('조합 배당조정이 변경됐습니다.')
          setWriteLoading(false)
          onModalShowHandler()
          // fetchSportsMixed()
        })
        .catch(error => {
          alert('조합 배당조정이 실패했습니다.')
          setWriteLoading(false)
        })
    }
  }

  const handleChange = e => {
    const newValue = e.target.value
    // 숫자와 소수점 2자리까지, 음수 포함 허용하는 정규식
    const regex = /^-?\d*\.?\d{0,2}$/

    // 입력 값이 정규식에 맞는지 확인
    if (regex.test(newValue)) {
      setNewMarketCombinationPer(newValue)
    }
  }
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        marketCombinationPer: newMarketCombinationPer,
      }}
    >
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          {infoLoading ? (
            <Loading />
          ) : (
            <>
              <FormGroup>
                <FormGroupLabel>종목</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input {...input} type="text" value={marketCombintationInfo.sportName} disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>타입</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={SportsMarketTypeEnums[marketCombintationInfo.sportMarketType]}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>조합명(ID)</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input {...input} type="text" value={marketCombintationInfo.marketCombinationIds} disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>조합명(이름)</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input {...input} type="text" value={marketCombintationInfo.marketCombinationNames} disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>배당조정(±)</FormGroupLabel>
                <FormGroupField>
                  <Field name="marketCombinationPer">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={newMarketCombinationPer}
                          onChange={e => {
                            handleChange(e)
                            input.onChange(e) // react-final-form의 input.onChange 호출
                          }}
                          placeholder="0"
                          style={{ width: '100%', height: '40px' }}
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                <CustomButton variant="primary" type="submit">
                  변경하기
                </CustomButton>
              </FormButtonToolbar>
            </>
          )}
        </FormContainer>
      )}
    </Form>
  )
}

export default MarketCombinationModifyModalForm

MarketCombinationModifyModalForm.propTypes = {
  onModalShowHandler: PropTypes.func.isRequired,
  fetchSportsMixed: PropTypes.func.isRequired,
  marketCombintationInfo: PropTypes.shape({
    marketCombinationKey: PropTypes.string.isRequired,
    marketCombinationIds: PropTypes.string.isRequired,
    marketCombinationNames: PropTypes.string.isRequired,
    marketCombinationPer: PropTypes.number.isRequired,
    sportMarketType: PropTypes.string.isRequired,
    sportName: PropTypes.string.isRequired,
  }).isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`

export const MemberResterBankSelect = styled(CustomSelect)`
  height: 35px;
  .react-select__menu {
    z-index: 101;
  }

  .react-select__menu-list {
    overflow: auto;
    border: 1px solid #3c3c3c;
  }

  .react-select__clear-indicator {
    color: #605f7b;
  }

  .react-select__control {
    background-color: ${props => props.isDisabled && 'lightgrey'} !important;
    // border: 1px solid #3c3c3c;

    &.react-select__control--is-focused,
    &:hover {
      border-color: #3c3c3c !important;
    }
  }
`
