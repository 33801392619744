import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import MemberInfoUpdate from './MemberInfoUpdate'
import MemberInfoTabs from './MemberInfoTabs'

const MemberInfoPage = () => {
  const { memberId } = useParams()
  return (
    <MemberInfoPageWrap>
      <MemberInfoPageTitle>
        <h2>[{memberId}] 유저 상세 정보</h2>
      </MemberInfoPageTitle>
      <MemberInfoUpdate memberId={memberId} />
      <MemberInfoTabs memberId={memberId} />
    </MemberInfoPageWrap>
  )
}

export default MemberInfoPage

const MemberInfoPageWrap = styled.div`
  background: white;
  width: 100%;
  min-width: 1400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`

const MemberInfoPageTitle = styled.div`
  width: 100%;
  border-bottom: 2px solid #f2f4f7;

  h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    color: #000;
    margin-bottom: 10px;
  }

  p {
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
    margin-bottom: 5px;
  }
`
