export const SportSumBettingTypeEnums = {
  ALL_USE: '모두 적용',
  NO_USE: '원배당 적용',
  REVERSE_USE: '역배당만 적용',
  INVERSE_USE: '정배당만 적용',
}

export const SportSumBettingTypeSelectOptions = () => {
  return Object.entries(SportSumBettingTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportSumBettingTypeEnumsV2 = {
  GLOBAL_SETTING: '전역설정',
  NO_USE: '원배당 적용',
  ALL_USE: '모두 적용',
  REVERSE_USE: '역배당만 적용',
  INVERSE_USE: '정배당만 적용',
}

export const SportSumBettingTypeV2SelectOptions = () => {
  return Object.entries(SportSumBettingTypeEnumsV2).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportBettingPerStatusEnums = {
  NO_SHOW: '강제대기',
  FORCE_APPLY: '강제발매',
}

export const SportBettingPerStatusEnumsSelectOptions = () => {
  return Object.entries(SportBettingPerStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportMaxLineTypeEnumsV2 = {
  NO_LIMIT: '제한 없음',
  MAIN_LINE_ONLY: '메인 기준점',
  THREE_LINES: '3개 라인',
  FIVE_LINES: '5개 라인',
  SEVEN_LINES: '7개 라인',
  NINE_LINES: '9개 라인',
  ELEVEN_LINES: '11개 라인',
  THIRTEEN_LINES: '13개 라인',
  FIFTEEN_LINES: '15개 라인',
  SEVENTEEN_LINES: '17개 라인',
  NINETEEN_LINES: '19개 라인',
}

export const SportMaxLineTypeEnums = {
  NO_LIMIT: '제한 없음',
  MAIN_LINE_ONLY: '메인 기준점',
  THREE_LINES: '3개 라인',
  FIVE_LINES: '5개 라인',
  SEVEN_LINES: '7개 라인',
  NINE_LINES: '9개 라인',
  ELEVEN_LINES: '11개 라인',
  THIRTEEN_LINES: '13개 라인',
  FIFTEEN_LINES: '15개 라인',
  SEVENTEEN_LINES: '17개 라인',
  NINETEEN_LINES: '19개 라인',
}

export const SportMaxLineTypeEnumsSelectOptions = () => {
  return Object.entries(SportMaxLineTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportMaxLineTypeEnumsV2SelectOptions = () => {
  return Object.entries(SportMaxLineTypeEnumsV2).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportSearchMarketTypeEnums = {
  PREMATCH: '프리매치',
  INPLAY: '인플레이',
}

export const SportSearchMarketTypeEnumsSelectOptions = () => {
  return Object.entries(SportSearchMarketTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportSearchMarketTypeEnumsRadioOptions = () => {
  return Object.entries(SportSearchMarketTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'SportSearchMarketTypeEnums',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'SportSearchMarketTypeEnums',
          label: value,
          radioValue: key,
        },
  )
}

export const SearchLsportsMarketTypeEnums = {
  ALL_MARKET: '전체마켓',
  OPEN_MARKET: '발매 마켓만',
  WAITING_MARKET: '대기 마켓만',
}

export const SearchLsportsMarketTypeEnumsRadioOptions = () => {
  return Object.entries(SearchLsportsMarketTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'SportSearchMarketTypeEnums',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'SportSearchMarketTypeEnums',
          label: value,
          radioValue: key,
        },
  )
}

export const SearchLsportsManualTypeEnums = {
  AUTO: '발매(자동)',
  MANUAL: '발매(수동)',
}

export const SearchLsportsManualTypeEnumsRadioOptions = () => {
  return Object.entries(SearchLsportsManualTypeEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'SportSearchManualTypeEnums',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'SportSearchManualTypeEnums',
          label: value,
          radioValue: key,
        },
  )
}

export const SportUnderOverSumBettingStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const SportUnderOverSumBettingStatusEnumsRadioOptions = () => {
  return Object.entries(SportUnderOverSumBettingStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'underOverSumBettingStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'underOverSumBettingStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const SportBetLineShowTypeEnums = {
  ALL_USE: '모두 사용',
  ZUM_FIVE: '.5만 사용',
  ZUM_ZERO: '.0만 사용',
}

export const SportBetLineShowTypeSelectOptions = () => {
  return Object.entries(SportBetLineShowTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportBetLineShowTypeEnumsV2 = {
  ALL_USE: '모두 사용',
  ZUM_FIVE: '.5만 사용',
  ZUM_ZERO: '.0만 사용',
  GLOBAL_SETTING: '전역설정',
}

export const SportBetLineShowTypeSelectOptionsV2 = () => {
  return Object.entries(SportBetLineShowTypeEnumsV2).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

// export const SportUnderOverSumBettingStatusEnumsSelectOptions = () => {
//   return Object.entries(SportUnderOverZumFiveStatusEnums).map(([key, value]) => ({
//     value: key,
//     label: value,
//   }))
// }

export const SportUnderOverZumFiveStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const SportUnderOverZumFiveStatusEnumsSelectOptions = () => {
  return Object.entries(SportUnderOverZumFiveStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportHandiCapSumBettingStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const SportHandiCapSumBettingStatusEnumsRadioOptions = () => {
  return Object.entries(SportHandiCapSumBettingStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'SportUnderOverSumBettingStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'SportUnderOverSumBettingStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const SportHandiCapSumBettingStatusEnumsSelectOptions = () => {
  return Object.entries(SportHandiCapSumBettingStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportHandiCapZumFiveStatusEnums = {
  USE: '사용',
  NOUSE: '미사용',
}

export const SportHandiCapZumFiveStatusEnumsSelectOptions = () => {
  return Object.entries(SportHandiCapZumFiveStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const sportResultStatusEnums = {
  WIN: '적중',
  DRAW: '적특',
  LOSE: '미적중',
  ING: '진행중',
  CANCEL: '취소',
}

export const BettingResultStatusEnumsSelectOptions = () => {
  return Object.entries(sportResultStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportsRealTimeResultStatusEnums = {
  LOSE_EXPECT: '미적중 예정',
  ING: '진행중',
}

export const sportResultStatusEnumsV2 = {
  WIN: '적중',
  DRAW: '적특',
  LOSE: '미적중',
  LOSE_EXPECT: '미적중 예정',
  ING: '진행중',
  CANCEL: '취소',
}

export const BettingResultStatusEnumsSelectOptions2 = () => {
  return Object.entries(sportResultStatusEnumsV2).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

/**
 * 개별 수동 결과 처리 해줄때 사용하는 것
 */
export const ManualSportResultStatusEnums = {
  WIN: '적중',
  DRAW: '적특',
  LOSE: '미적중',
}

export const ManualSportResultStatusEnumsSelectOptions = () => {
  return Object.entries(ManualSportResultStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportSettlementStatus2Enums = {
  APPLY: '신청',
  WAITING: '대기',
  COMPLETE: '완료',
}

export const SportSettlementStatusV2Enums = {
  ING: '신청',
  WAITING: '대기',
  COMPLETE: '완료',
}

export const sportSettlementStatusEnums = {
  true: '완료',
  false: '미완료',
}

export const sportOnlyOneFolderEnums = {
  [true]: '허용',
  [false]: '제재',
}

export const SportsOnlyOneRadioOptions = () => {
  return Object.entries(sportOnlyOneFolderEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'sportsOnlyOneFolder',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'sportsOnlyOneFolder',
          label: value,
          radioValue: key,
        },
  )
}

export const SettlemenResulttStatusEnumsSelectOptions = () => {
  return Object.entries(sportSettlementStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const sportSettlementStatusEnumsV2 = {
  ING: '신청',
  WAITING: '대기',
  COMPLETE: '완료',
}

export const SettlemenResulttStatusEnumsSelectOptionsV2 = () => {
  return Object.entries(sportSettlementStatusEnumsV2).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const settlementAutoStatusEnums = {
  AUTO: '자동',
  MANUAL: '수동',
}

export const SettlementStatusEnumsSelectOptions = () => {
  return Object.entries(settlementAutoStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SettlementStatusEnumsRadioOptions = () => {
  return Object.entries(settlementAutoStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'settlementStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'settlementStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const SportsMarketTypeEnums = {
  DOMESTIC: '국내형',
  OVERSEAS: '해외형',
  SPECIAL: '스페셜',
  LIVE: '라이브',
}

export const SportTypeEnums = {
  SOCCER: '축구',
  AMERICAN_FOOTBALL: '미식축구',
  BOXING: '복싱/UFC',
  TENNIS: '테니스',
  BASEBALL: '야구',
  ICE_HOCKEY: '아이스하키',
  BASKETBALL: '농구',
  HANDBALL: '핸드볼',
  VOLLEYBALL: '배구',
  TABLE_TENNIS: '탁구',
  E_SPORTS: 'E-SPORTS',
  ETC: '기타',
}

export const SportTypeEnumsV2 = {
  SOCCER: '축구',
  AMERICAN_FOOTBALL: '미식축구',
  BOXING: '복싱/UFC',
  TENNIS: '테니스',
  BASEBALL: '야구',
  ICE_HOCKEY: '아이스하키',
  BASKETBALL: '농구',
  HANDBALL: '핸드볼',
  VOLLEYBALL: '배구',
  TABLE_TENNIS: '탁구',
  E_SPORTS: 'E-SPORTS',
  ETC: '기타',
}

export const SportSecondTypeEnums = {
  SOCCER: '축구',
  AMERICAN_FOOTBALL: '미식축구',
  BOXING: '복싱/UFC',
  TENNIS: '테니스',
  BASEBALL: '야구',
  ICE_HOCKEY: '아이스하키',
  BASKETBALL: '농구',
  HANDBALL: '핸드볼',
  VOLLEYBALL: '배구',
  TABLE_TENNIS: '탁구',
  E_SPORTS: 'E-SPORTS',
}

export const SportThirdTypeEnums = {
  SOCCER: '축구',
  AMERICAN_FOOTBALL: '미식축구',
  BOXING: '복싱/UFC',
  TENNIS: '테니스',
  BASEBALL: '야구',
  ICE_HOCKEY: '아이스하키',
  BASKETBALL: '농구',
  HANDBALL: '핸드볼',
  VOLLEYBALL: '배구',
  TABLE_TENNIS: '탁구',
  E_SPORTS: 'E-SPORTS',
}

export const BookmakerIdTypeEnums = {
  8: 'Bet365',
  145: '1XBet',
  7: 'Tipico',
  41: 'BetFairExchange',
  131: 'BetFairSportsbook',
  74: 'MarathonBet',
  13: 'BWin',
  4: 'Pinnacle',
  999: 'STM',
}

export const SportIdTypeEnums = {
  6046: '축구',
  48242: '농구',
  154914: '야구',
  35232: '아이스하키',
  154830: '배구',
  54094: '테니스',
  35709: '핸드볼',
  687890: 'E - 스포츠',
  131506: '미식축구',
  154919: '복싱',
  265917: '탁구',
}

export const ManualSportIdTypeEnums = {
  48242: '농구',
  154914: '야구',
  154830: '배구',
  687890: 'E - 스포츠',
}

export const SportIdTypeEnumsSelectOptions = () => {
  return Object.entries(SportIdTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const ManualSportIdTypeEnumsSelectOptions = () => {
  return Object.entries(ManualSportIdTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportsMarketTypeEnumsSelectOptions = () => {
  return Object.entries(SportsMarketTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportTypeEnumsSelectOptions = () => {
  return Object.entries(SportTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportSecondTypeEnumsSelectOptions = () => {
  return Object.entries(SportSecondTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SportThirdypeEnumsSelectOptions = () => {
  return Object.entries(SportThirdTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const MarketTypeEnums = {
  SOCCER_WIN_DRAW_LOSE: '승무패',
  SOCCER_DOUBLE_CHANCE: '승무패(더블찬스)',
  SOCCER_ALL_SCORE: '양팀모두득점',
  SOCCER_WIN_LOSE: '승패',
  SOCCER_EXACT_SCORE: '정확한 스코어',
  SOCCER_HALF_FULL: '하프타임/풀타임 승무패',
  SOCCER_WIN_DRAW_LOSE_ALL_SCORE: '승무패/양팀모두득점',
  SOCCER_GOAL_ODD_EVEN: '골 홀/짝',
  SOCCER_FIRST_HALF_ALL_SCORE: '전반전 양팀모두득점',
  SOCCER_SECOND_HALF_ALL_SCORE: '후반전 양팀모두득점',
  SOCCER_SECOND_HALF_UNDER_OVER: '후반전 언더오버',
  SOCCER_WIN_DRAW_LOSE_UNDER_OVER: '승무패/언더오버',
  SOCCER_UNDER_OVER_ALL_SCORE: '언더오버/양팀모두득점',
  SOCCER_HOME_UNDER_OVER: '홈/언더오버',
  SOCCER_AWAY_UNDER_OVER: '원정/언더오버',
  SOCCER_HALF_WIN_DRAW_LOSE: '전반전 승무패',
  SOCCER_HALF_DOUBLE_CHANCE: '전반전 더블찬스',
  SOCCER_HALF_EXACT_SCORE: '전반전 정확한스코어',
  SOCCER_EXACT_SCORE_ASIAN: '정확한 골',
  SOCCER_FIRST_HALF_EXACT_SCORE: '전반전 정확한 골',
  SOCCER_SECOND_HALF_EXACT_SCORE: '후반전 정확한 골',
  SOCCER_FIRST_HALF_GOAL_ODD_EVEN: '전반전 골 홀짝',
  SOCCER_SECOND_HALF_GOAL_ODD_EVEN: '후반전 골 홀짝',
  SOCCER_SECOND_WIN_DRAW_LOSE: '후반전 승무패',
  SOCCER_FIRST_HALF_WIN_DRAW_LOSE_UNDER_OVER: '전반전 승무패/언더오버',
  SOCCER_FIRST_HALF_WIN_DRAW_LOSE_ALL_SCORE: '전반전 승무패/양팀모두득점',
  SOCCER_FIRST_SCORE_TEAM: '첫 득점 팀',
  SOCCER_LAST_SCORE_TEAM: '마지막 득점 팀',
  SOCCER_SCORE_SUCCESS_TEAM: '득점 성공팀',
  SOCCER_CUSTOM_HANDICAP: '핸디캡',
  SOCCER_CUSTOM_GOAL_UNDER_OVER: '언더오버',
  SOCCER_CUSTOM_FIRST_HALF_HANDICAP: '전반전 핸디캡',
  SOCCER_CUSTOM_FIRST_HALF_UNDER_OVER: '전반전 언더오버',
  SOCCER_CUSTOM_HANDICAP_WIN_DRAW_LOSE: '핸디캡 승무패',
  FOOTBALL_WIN_LOSE: '승패',
  FOOTBALL_HANDICAP: '핸디캡',
  FOOTBALL_UNDER_OVER: '언더오버',
  FOOTBALL_FIRST_HALF_WIN_LOSE: '전반전 승패',
  FOOTBALL_FIRST_HALF_HANDICAP: '전반전 핸디캡',
  FOOTBALL_FIRST_HALF_UNDER_OVER: '전반전 언더오버',
  FOOTBALL_FIRST_QUARTER_WIN_LOSE: '1쿼터 승패',
  FOOTBALL_FIRST_QUARTER_HANDICAP: '1쿼터 핸디캡',
  FOOTBALL_FIRST_QUARTER_UNDER_OVER: '1쿼터 언더오버',
  BOXING_UFC_WIN_LOSE: '	승패	',
  TENNIS_WIN_LOSE: '승패',
  TENNIS_HANDICAP: '핸디캡',
  TENNIS_EXACT_SET_SCORE: '정확한 세트 결과',
  TENNIS_FIRST_SET_WIN_LOSE: '1세트 승패',
  TENNIS_FIRST_SET_EXACT_SCORE: '1세트 정확한 스코어',
  TENNIS_FIRST_SET_UNDER_OVER: '1세트 언더오버',
  TENNIS_UNDER_OVER: '언더오버',
  BASEBALL_WIN_LOSE: '승패',
  BASEBALL_SCORE_ODD_EVEN: '점수 홀짝',
  BASEBALL_FIRST_INNING_WIN_DRAW_LOSE: '1이닝 승무패',
  BASEBALL_FIRST_INNING_HOME_SCORE: '1이닝 홈 득점',
  BASEBALL_FIRST_INNING_AWAY_SCORE: '1이닝 원정 득점',
  BASEBALL_CUSTOM_HANDICAP: '핸디캡',
  BASEBALL_CUSTOM_UNDER_OVER: '언더오버',
  BASEBALL_CUSTOM_THIRD_INNING_HANDICAP: '3이닝 합계 핸디캡',
  BASEBALL_CUSTOM_THIRD_INNING_UNDER_OVER: '3이닝 합계 언더오버',
  BASEBALL_CUSTOM_FIFTH_INNING_HANDICAP: '5이닝 합계 핸디캡',
  BASEBALL_CUSTOM_FIFTH_INNING_UNDER_OVER: '5이닝 합계 언더오버',
  BASEBALL_CUSTOM_SEVENTH_INNING_HANDICAP: '7이닝 합계 핸디캡',
  BASEBALL_CUSTOM_SEVENTH_INNING_UNDER_OVER: '7이닝 합계 언더오버',
  BASEBALL_SPECIAL_SECOND_INNING_WIN_DRAW_LOSE: '2이닝 승무패',
  BASEBALL_SPECIAL_THIRD_INNING_WIN_DRAW_LOSE: '3이닝 승무패',
  BASEBALL_SPECIAL_FOURTH_INNING_WIN_DRAW_LOSE: '4이닝 승무패',
  BASEBALL_SPECIAL_FIFTH_INNING_WIN_DRAW_LOSE: '5이닝 승무패',
  BASEBALL_SPECIAL_SIXTH_INNING_WIN_DRAW_LOSE: '6이닝 승무패',
  BASEBALL_SPECIAL_SEVENTH_INNING_WIN_DRAW_LOSE: '7이닝 승무패',
  BASEBALL_SPECIAL_EIGHTH_INNING_WIN_DRAW_LOSE: '8이닝 승무패',
  BASEBALL_SPECIAL_NINTH_INNING_WIN_DRAW_LOSE: '9이닝 승무패',
  BASEBALL_SPECIAL_SECOND_INNING_UNDER_OVER: '2이닝 언더오버',
  BASEBALL_SPECIAL_THIRD_INNING_UNDER_OVER: '3이닝 언더오버',
  BASEBALL_SPECIAL_FOURTH_INNING_UNDER_OVER: '4이닝 언더오버',
  BASEBALL_SPECIAL_FIFTH_INNING_UNDER_OVER: '5이닝 언더오버',
  BASEBALL_SPECIAL_SIXTH_INNING_UNDER_OVER: '6이닝 언더오버',
  BASEBALL_SPECIAL_SEVENTH_INNING_UNDER_OVER: '7이닝 언더오버',
  BASEBALL_SPECIAL_EIGHTH_INNING_UNDER_OVER: '8이닝 언더오버',
  BASEBALL_SPECIAL_NINTH_INNING_UNDER_OVER: '9이닝 언더오버',
  BASEBALL_SPECIAL_SECOND_INNING_SCORE: '2이닝 득점',
  BASEBALL_SPECIAL_THIRD_INNING_SCORE: '3이닝 득점',
  BASEBALL_SPECIAL_FOURTH_INNING_SCORE: '4이닝 득점',
  BASEBALL_SPECIAL_FIFTH_INNING_SCORE: '5이닝 득점',
  BASEBALL_SPECIAL_SIXTH_INNING_SCORE: '6이닝 득점',
  BASEBALL_SPECIAL_SEVENTH_INNING_SCORE: '7이닝 득점',
  BASEBALL_SPECIAL_EIGHTH_INNING_SCORE: '8이닝 득점',
  BASEBALL_SPECIAL_NINTH_INNING_SCORE: '9이닝 득점',
  ICE_HOCKEY_WIN_LOSE: '승패',
  ICE_HOCKEY_WIN_DRAW_LOSE: '승무패',
  ICE_HOCKEY_HANDICAP_3WAY: '핸디캡 3-Way',
  ICE_HOCKEY_UNDER_OVER_3WAY: '언더오버 3-Way',
  ICE_HOCKEY_DOUBLE_CHANCE: '더블찬스',
  ICE_HOCKEY_EXACT_SCORE: '정확한스코어',
  ICE_HOCKEY_SCORE_ODD_EVEN: '홀짝',
  ICE_HOCKEY_FIRST_PERIOD_WIN_LOSE: '1피리어드 승패',
  ICE_HOCKEY_FIRST_PERIOD_WIN_DRAW_LOSE: '1피리어드 승무패',
  ICE_HOCKEY_FIRST_PERIOD_DOUBLE_CHANCE: '1피리어드 더블찬스',
  ICE_HOCKEY_CUSTOM_HANDICAP: '핸디캡',
  ICE_HOCKEY_CUSTOM_UNDER_OVER: '언더오버',
  ICE_HOCKEY_CUSTOM_FIRST_PERIOD_HANDICAP: '1피리어드 핸디캡',
  ICE_HOCKEY_CUSTOM_FIRST_PERIOD_UNDER_OVER: '1피리어드 언더오버',
  ICE_HOCKEY_SPECIAL_SECOND_PERIOD_WIN_DRAW_LOSE: '2피리어드 승무패',
  ICE_HOCKEY_SPECIAL_THIRD_PERIOD_WIN_DRAW_LOSE: '3피리어드 승무패',
  ICE_HOCKEY_SPECIAL_SECOND_PERIOD_UNDER_OVER: '2피리어드 언더오버',
  ICE_HOCKEY_SPECIAL_THIRD_PERIOD_UNDER_OVER: '3피리어드 언더오버',
  ICE_HOCKEY_SPECIAL_SECOND_PERIOD_HANDICAP: '2피리어드 핸디캡',
  ICE_HOCKEY_SPECIAL_THIRD_PERIOD_HANDICAP: '3피리어드 핸디캡',
  BASKETBALL_WIN_LOSE: '승패',
  BASKETBALL_FIRST_HALF_WIN_LOSE: '전반전 승패',
  BASKETBALL_FIRST_HALF_HANDICAP: '전반전 핸디캡',
  BASKETBALL_FIRST_HALF_UNDER_OVER: '전반전 언더오버',
  BASKETBALL_FIRST_QUARTER_WIN_LOSE: '1쿼터 승패',
  BASKETBALL_FIRST_QUARTER_HANDICAP: '1쿼터 핸디캡',
  BASKETBALL_FIRST_QUARTER_UNDER_OVER: '1쿼터 언더오버',
  BASKETBALL_FIRST_QUARTER_WIN_DRAW_LOSE: '1쿼터 승무패',
  BASKETBALL_SCORE_RANGE_3_WAY: '총 점수 범위(3_WAY)',
  BASKETBALL_SCORE_RANGE_8_WAY: '총 점수 범위(8_WAY)',
  BASKETBALL_CUSTOM_HANDICAP: '핸디캡',
  BASKETBALL_CUSTOM_UNDER_OVER: '언더오버',
  BASKETBALL_SPECIAL_SECOND_QUARTER_WIN_LOSE: '2쿼터 승패',
  BASKETBALL_SPECIAL_THIRD_QUARTER_WIN_LOSE: '3쿼터 승패',
  BASKETBALL_SPECIAL_FOURTH_QUARTER_WIN_LOSE: '4쿼터 승패',
  BASKETBALL_SPECIAL_SECOND_QUARTER_WIN_DRAW_LOSE: '2쿼터 승무패',
  BASKETBALL_SPECIAL_THIRD_QUARTER_WIN_DRAW_LOSE: '3쿼터 승무패',
  BASKETBALL_SPECIAL_FOURTH_QUARTER_WIN_DRAW_LOSE: '4쿼터 승무패',
  BASKETBALL_SPECIAL_SECOND_QUARTER_HANDICAP: '2쿼터 핸디캡',
  BASKETBALL_SPECIAL_THIRD_QUARTER_HANDICAP: '3쿼터 핸디캡',
  BASKETBALL_SPECIAL_FOURTH_QUARTER_HANDICAP: '4쿼터 핸디캡',
  BASKETBALL_SPECIAL_SECOND_QUARTER_UNDER_OVER: '2쿼터 언더오버',
  BASKETBALL_SPECIAL_THIRD_QUARTER_UNDER_OVER: '3쿼터 언더오버',
  BASKETBALL_SPECIAL_FOURTH_QUARTER_UNDER_OVER: '4쿼터 언더오버',
  HANDBALL_WIN_DRAW_LOSE: '승무패',
  HANDBALL_HANDICAP: '핸디캡',
  HANDBALL_UNDER_OVER: '언더오버',
  VOLLEYBALL_WIN_LOSE: '승패',
  VOLLEYBALL_HANDICAP: '핸디캡',
  VOLLEYBALL_UNDER_OVER: '언더오버',
  VOLLEYBALL_ODD_EVEN: '홀짝',
  VOLLEYBALL_EXACT_SET_SCORE: '정확한 세트스코어',
  VOLLEYBALL_HOME_UNDER_OVER: '홈_언더오버',
  VOLLEYBALL_AWAY_UNDER_OVER: '원정_언더오버',
  VOLLEYBALL_FIRST_SET_WIN_LOSE: '1세트 승패',
  VOLLEYBALL_FIRST_SET_HANDICAP: '1세트 핸디캡',
  VOLLEYBALL_FIRST_SET_UNDER_OVER: '1세트 언더오버',
  VOLLEYBALL_FIRST_SET_ODD_EVEN: '1세트 홀짝',
  VOLLEYBALL_FIRST_SET_EXACT_SCORE: '1세트 정확한스코어',
  VOLLEYBALL_HANDICAP_ALL_SCORE: '총 점수 핸디캡',
  VOLLEYBALL_SPECIAL_SECOND_SET_WIN_LOSE: '2세트 승패',
  VOLLEYBALL_SPECIAL_THIRD_SET_WIN_LOSE: '3세트 승패',
  VOLLEYBALL_SPECIAL_FOURTH_SET_WIN_LOSE: '4세트 승패',
  VOLLEYBALL_SPECIAL_FIFTH_SET_WIN_LOSE: '5세트 승패',
  VOLLEYBALL_SPECIAL_SECOND_SET_HANDICAP: '2세트 핸디캡',
  VOLLEYBALL_SPECIAL_THIRD_SET_HANDICAP: '3세트 핸디캡',
  VOLLEYBALL_SPECIAL_FOURTH_SET_HANDICAP: '4세트 핸디캡',
  VOLLEYBALL_SPECIAL_FIFTH_SET_HANDICAP: '5세트 핸디캡',
  VOLLEYBALL_SPECIAL_SECOND_SET_UNDER_OVER: '2세트 언더오버',
  VOLLEYBALL_SPECIAL_THIRD_SET_UNDER_OVER: '3세트 언더오버',
  VOLLEYBALL_SPECIAL_FOURTH_SET_UNDER_OVER: '4세트 언더오버',
  VOLLEYBALL_SPECIAL_FIFTH_SET_UNDER_OVER: '5세트 언더오버',
  TABLETENNIS_WIN_LOSE: '승패',
  ESPORTS_WIN_LOSE: '승패',
  ESPORTS_HANDICAP: '핸디캡',
  ESPORTS_UNDER_OVER: '언더오버',
  ESPORTS_FIRST_SET_FIRST_DRAGON: '1세트 첫용',
  ESPORTS_FIRST_SET_FIRST_BARON: '1세트 첫바론',
  ESPORTS_FIRST_SET_FIRST_TOWER: '1세트 첫타워',
  ESPORTS_FIRST_SET_KILL_HANDICAP: '1세트 킬 핸디캡',
  ESPORTS_FIRST_SET_KILL_UNDER_OVER: '1세트 킬 언더오버',
  ESPORTS_FIRST_SET_WIN_LOSE: '1세트 승패',
  ESPORTS_SPECIAL_SECOND_SET_WIN_LOSE: '2세트 승패',
  ESPORTS_SPECIAL_THIRD_SET_WIN_LOSE: '3세트 승패',
  ESPORTS_SPECIAL_FOURTH_SET_WIN_LOSE: '4세트 승패',
  ESPORTS_SPECIAL_FIFTH_SET_WIN_LOSE: '5세트 승패',
  ESPORTS_SPECIAL_SECOND_SET_KILL_HANDICAP: '2세트 킬 핸디캡',
  ESPORTS_SPECIAL_THIRD_SET_KILL_HANDICAP: '3세트 킬 핸디캡',
  ESPORTS_SPECIAL_FOURTH_SET_KILL_HANDICAP: '4세트 킬 핸디캡',
  ESPORTS_SPECIAL_FIFTH_SET_KILL_HANDICAP: '5세트 킬 핸디캡',
  ESPORTS_SPECIAL_SECOND_SET_KILL_UNDER_OVER: '2세트 킬 언더오버',
  ESPORTS_SPECIAL_THIRD_SET_KILL_UNDER_OVER: '3세트 킬 언더오버',
  ESPORTS_SPECIAL_FOURTH_SET_KILL_UNDER_OVER: '4세트 킬 언더오버',
  ESPORTS_SPECIAL_FIFTH_SET_KILL_UNDER_OVER: '5세트 킬 언더오버',

  ETC_BASEBALL_FIRST_FOUR_BALL: '첫 볼넷',
  ETC_BASEBALL_FIRST_HOME_RUN: '첫 홈런',
  ETC_BASEBALL_FIRST_HIT: '첫 안타',
  ETC_BASEBALL_FIRST_INNING_SCORE: '1이닝 득점',
  ETC_ICE_HOCKEY_FIRST_SCORE: '첫 득점',
  ETC_BASKETBALL_FIRST_THREE_SCORE: '첫 3점',
  ETC_BASKETBALL_FIRST_FREE_THROW: '첫 자유투',
  ETC_BASKETBALL_FIRST_SCORE: '첫 득점',
  ETC_BASKETBALL_FIRST_FIVE_SCORE: '선 5득점',
  ETC_BASKETBALL_FIRST_SEVEN_SCORE: '선 7득점',
  ETC_BASKETBALL_FIRST_TEN_SCORE: '선 10득점',
  ETC_BASKETBALL_FIRST_FIFTEEN_SCORE: '선 15득점',
  ETC_BASKETBALL_FIRST_TWENTY_SCORE: '선 20득점',
  ETC_VOLLEYBALL_FIRST_FIVE_SCORE: '선 5득점',
  ETC_VOLLEYBALL_FIRST_SEVEN_SCORE: '선 7득점',
  ETC_VOLLEYBALL_FIRST_TEN_SCORE: '선 10득점',
  ETC_ESPORTS_FIRST_SET_FIRST_KILL: '첫 킬',
  ETC_ESPORTS_FIRST_SET_FIRST_DRAGON: '첫 용',
  ETC_ESPORTS_FIRST_SET_FIRST_BARON: '첫 바론',
  ETC_ESPORTS_FIRST_SET_FIRST_TOWER: '첫 타워',
  ETC_ESPORTS_FIRST_SET_TIME: '1세트 경기시간',
  ETC_WIN_LOSE: '승패',
}

export const eventStatusEnums = {
  경기종료: '경기종료',
  경기취소: '경기취소',
  경기지연: '경기지연',
  경기전: '경기전',
  경기중: '경기중',
}

export const EventStatusEnumsSelectOptions = () => {
  return Object.entries(eventStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const SoccerScoreEnums = {
  1: '전반 획득점수',
  2: '후반 획득점수',
  et: '연장 획득점수',
  pen: '승부차기 점수',
  f_g: '첫 득점(0,1)',
  l_g: '마지막 득점(0,1)',
}

export const FootballScoreEnums = {
  1: '1쿼터 획득점수',
  2: '2쿼터 획득점수',
  3: '3쿼터 획득점수',
  4: '4쿼터 획득점수',
  et: '연장 획득점수',
}

export const TennisScoreEnums = {
  '1_set': '1세트 획득점수',
  '2_set': '2세트 획득점수',
  '3_set': '3세트 획득점수',
  '4_set': '4세트 획득점수',
  '5_set': '5세트 획득점수',
}

export const BoxingScoreEnums = {
  score: '승패(1,0)',
}

export const BaseballScoreEnums = {
  1: '1이닝 획득점수',
  2: '2이닝 획득점수',
  3: '3이닝 획득점수',
  4: '4이닝 획득점수',
  5: '5이닝 획득점수',
  6: '6이닝 획득점수',
  7: '7이닝 획득점수',
  8: '8이닝 획득점수',
  9: '9이닝 획득점수',
  et: '연장 획득점수',
}

export const IceHockeyScoreEnums = {
  1: '1PR 획득점수',
  2: '2PR 획득점수',
  3: '3PR 획득점수',
  et: '연장 획득점수',
  f_g: '첫 득점(0,1)',
  l_g: '마지막 득점(0,1)',
}

export const BasektBallScoreEnums = {
  1: '1쿼터 획득점수',
  2: '2쿼터 획득점수',
  3: '3쿼터 획득점수',
  4: '4쿼터 획득점수',
  et: '연장 획득점수',
}

export const HandBallScoreEnums = {
  1: '전반 획득점수',
  2: '후반 획득점수',
  3: '연장 획득점수',
}

export const VolleyBallScoreEnums = {
  1: '1세트 획득점수',
  2: '2세트 획득점수',
  3: '3세트 획득점수',
  4: '4세트 획득점수',
  5: '5세트 획득점수',
}

export const TableTennisScoreEnums = {
  '1_set': '1세트 획득점수',
  '2_set': '2세트 획득점수',
  '3_set': '3세트 획득점수',
  '4_set': '4세트 획득점수',
  '5_set': '5세트 획득점수',
  '6_set': '6세트 획득점수',
  '7_set': '7세트 획득점수',
}

export const ESportsScoreEnums = {
  1: '1세트 승패(0,1)',
  2: '2세트 승패(0,1)',
  3: '3세트 승패(0,1)',
  4: '4세트 승패(0,1)',
  5: '5세트 승패(0,1)',

  k1: '1세트 킬수',
  k2: '2세트 킬수',
  k3: '3세트 킬수',
  k4: '4세트 킬수',
  k5: '5세트 킬수',

  f_k1: '1세트 첫 킬(0,1)',
  f_t1: '1세트 첫 타워(0,1)',
  f_b1: '1세트 첫 바론(0,1)',
  f_d1: '1세트 첫 용(0,1)',
  t_1: '1세트 언오버',
}

export const EtcScoreEnums = {
  score: '승패(0,1)',
}

export const getScoreEnums = sportsType => {
  switch (sportsType) {
    case 'SOCCER':
      return SoccerScoreEnums
    case 'BASEBALL':
      return BaseballScoreEnums
    case 'BASKETBALL':
      return BasektBallScoreEnums
    case 'VOLLEYBALL':
      return VolleyBallScoreEnums
    case 'AMERICAN_FOOTBALL':
      return FootballScoreEnums
    case 'ICE_HOCKEY':
      return IceHockeyScoreEnums
    case 'BOXING':
      return BoxingScoreEnums
    case 'HANDBALL':
      return HandBallScoreEnums
    case 'TABLE_TENNIS':
      return TableTennisScoreEnums
    case 'TENNIS':
      return TennisScoreEnums
    case 'E_SPORTS':
      return ESportsScoreEnums
    case 'ETC':
      return EtcScoreEnums
    default:
      return null
  }
}
