import axios from '../base/axios'

// 홀덤 정보 조회
export async function searchHoldemInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/holdem/info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 홀덤 정보 변경
export async function updateHoldemInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/holdem/info',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
