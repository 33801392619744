import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import { useSelector } from 'react-redux'
import { DepoWithdrawTypeEnumsSelectOptions } from '../../../enums/Log/moneyIncDecEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import ReactTableBase from '../../../shared/components/table/ReactTableBase'
import { searchMemberMoneyLog } from '../../../utils/api/memberManage/memberLogApi'
import { convertToKst } from '../../../utils/dateTime'
import LogPageTabs from '../LogPageTabs'
import MoneyDepoWithdrawListData from './MoneyDepoWithdrawListData'

const MoneyDepoWithdrawList = () => {
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)
  /**
   머니입출금로그 조회
   */
  const [params, setParams] = useState({
    userId: null,
    startDate: null,
    endDate: null,
    edgeName: null,
    userAuthority: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      userAuthority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [memberMoneyLogContent, setContent] = useState([])
  const [memberMoneyLogTotalElement, setTotalElement] = useState(0)
  const fetchSearchMemberMoneyLog = async () => {
    await searchMemberMoneyLog(params).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchSearchMemberMoneyLog()
  }, [params])

  const [moneyDepoWithdrawListData, setMoneyDepoWithdrawListData] = useState(
    MoneyDepoWithdrawListData(memberMoneyLogContent),
  )
  useEffect(() => {
    setMoneyDepoWithdrawListData(MoneyDepoWithdrawListData(memberMoneyLogContent))
  }, [memberMoneyLogContent])

  const [rows, setData] = useState(moneyDepoWithdrawListData.tableRowsData)
  useEffect(() => {
    setData(moneyDepoWithdrawListData.tableRowsData)
  }, [moneyDepoWithdrawListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement: memberMoneyLogTotalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 회원ID
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  const [searchMoneyType, setSearchMoneyType] = useState('')

  const [searchMoneyTypeOptionValue, setSearchMoneyTypeOptionValue] = useState(null)
  const searchMoneyTypeOption = DepoWithdrawTypeEnumsSelectOptions()

  const onSelectMoneyTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyTypeOptionValue(null)
      setSearchMoneyType(null)
      return
    }
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyType(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      moneyType: !searchMoneyType ? null : searchMoneyType,
      userId: !searchUserId ? null : searchUserId.trim(),
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  // Tab Key
  const [activeKey, setActiveKey] = useState('5')
  return (
    <Card>
      <CardBody>
        <LogPageTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * 해당 페이지에서는 <span style={{ color: 'red' }}>승인된</span> 입출금 내역만 확인 할 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchUserId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchUserId}
                  onChange={e => onSearchUserIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종류</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchMoneyType"
                onChange={option => {
                  onSelectMoneyTypeOptionHandler(option)
                }}
                placeholder="종류"
                value={searchMoneyTypeOptionValue}
                options={searchMoneyTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={moneyDepoWithdrawListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
    </Card>
  )
}

export default MoneyDepoWithdrawList
