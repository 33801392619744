import { Card, CardBody } from '@/shared/components/Card'
import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { EditorState } from 'draft-js'
import { Field, Form } from 'react-final-form'
import { Editor } from 'react-draft-wysiwyg'
import PropTypes from 'prop-types'
import showResults from '../../../utils/showResults'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import SystemManagementTabs from '../SystemManagementTabs'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import { convertFromFront, convertFromServer } from '../../../utils/editor'
import { searchDepositRule, updateDepositRule } from '../../../utils/api/systemManage/depositWithdrawalRuleManageApi'
import Loading from '../../../shared/components/Loading'
import { decodeAccessToken } from '../../../utils/token'

const DepositRuleForm = ({ edgeName }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [depositRuleContent, setDepositRuleContent] = useState(EditorState.createEmpty())

  const fetchSearchDepositRule = async () => {
    await searchDepositRule().then(res => {
      setDepositRuleContent(convertFromServer(res.data.depositRuleContent))
    })
  }

  useEffect(() => {
    fetchSearchDepositRule()
  }, [])

  const onChangeDepositRuleContent = depositData => {
    setDepositRuleContent(depositData)
  }

  const [depositRuleApiLoading, setDepositRuleApiLoading] = useState(false)

  const onDepositRuleSubmit = e => {
    if (depositRuleApiLoading) return

    setDepositRuleApiLoading(true)

    const body = {
      depositRuleContent: convertFromFront(depositRuleContent),
    }

    if (window.confirm('입금신청 규정을 수정하시겠습니까?')) {
      updateDepositRule(body)
        .then(res => {
          alert('수정되었습니다.')
          fetchSearchDepositRule()
          setDepositRuleApiLoading(false)
        })
        .catch(error => {
          alert('수정 실패했습니다.')
          setDepositRuleApiLoading(false)
        })
    }
  }

  return (
    <DepositRuleBox>
      <HeaderBox>입금신청 규정 작성하는곳</HeaderBox>
      {depositRuleApiLoading && <Loading />}
      <Form
        onSubmit={onDepositRuleSubmit}
        initialValues={{
          depositRuleContent,
        }}
      >
        {({ handleSubmit, form: { reset } }) => (
          <FormContainer onSubmit={handleSubmit}>
            <EditoryBox>
              <Editor
                editorState={depositRuleContent}
                onEditorStateChange={editorState => onChangeDepositRuleContent(editorState)}
                wrapperClassName="wrapper"
                editorClassName="editor"
                toolbarClassName="toolbar"
              />
            </EditoryBox>
            {
              {
                SUPER: (
                  <ButtonBox
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <button type="submit" style={{ background: 'grey', width: '120px', padding: '10px' }}>
                      수정하기
                    </button>
                  </ButtonBox>
                ),
              }[authority]
            }
          </FormContainer>
        )}
      </Form>
    </DepositRuleBox>
  )
}

export default DepositRuleForm

DepositRuleForm.propTypes = {
  edgeName: PropTypes.string.isRequired,
}

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 10px;
  border-top: 2px solid black;
`

const ButtonBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  button {
    width: 70px;
    textalign: center;
    color: white;
    fontweight: 900;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    outline: none;
  }
`

const DepositRuleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const EditoryBox = styled.div`
  .editor {
    width: 100% !important;
    min-height: 550px !important;
  }
`
