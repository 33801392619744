import { Button } from '@/shared/components/Button'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { SearchDatePickerWrap } from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import { EditorState } from 'draft-js'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import {
  BoardStatusEnumsRadioOptions,
  BoardTargetEnumsRadioOptions,
  BoardTypeEnumsRaidoOptions,
} from '../../../enums/Operation/boardEnums'
import { updateBoardList } from '../../../utils/api/operation/operationApi'
import { convertFromFront, convertFromServer } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import './Board.css'

function BoardModifyForm({
  boardKey,
  edgeName,
  boardTitle,
  imgUrl,
  type,
  boardContent,
  target,
  status,
  registrationDate,
  fetchSearchBoardList,
  onClickHandler,
}) {
  const [content, setContent] = useState(EditorState.createEmpty())
  const contentChange = editorState => {
    setContent(editorState)
  }

  const authority = decodeAccessToken()?.adminAuthority
  // 등록일 시작
  const [searchRegistrationDate, setSearchRegistrationDate] = useState(new Date(registrationDate))
  const onSearchRegistrationDateChangeHandler = date => {
    setSearchRegistrationDate(date)
  }

  useEffect(() => {
    console.log('boardContent :: ', boardContent)
    setContent(convertFromServer(boardContent))
  }, [boardContent])

  const onSubmit = e => {
    if (!e.boardTitle || !content.getCurrentContent().hasText()) {
      alert('모두 다 기입 후 수정버튼을 클릭해주세요.')
      return
    }

    const body = {
      boardKey,
      title: e.boardTitle,
      type: e.boardType,
      status: e.boardStatus,
      newImgUrl: !e.newImgUrl ? null : e.newImgUrl.trim(),
      registrationDate: searchRegistrationDate,
      boardContent: convertFromFront(content),
    }

    updateBoardList(body)
      .then(res => {
        alert('게시판 수정이 완료됐습니다.')
        onClickHandler()
        fetchSearchBoardList()
        return res
      })
      .catch(error => {
        alert('게시판 수정이 실패했습니다, 잠시 후 다시 시도해주세요.')
        return error
      })
  }
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>사이트명</FormGroupLabel>
            <FormGroupField>
              <Field name="edgeName" component="input" type="text" disabled defaultValue={edgeName} />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>제목</FormGroupLabel>
            <FormGroupField>
              <Field name="boardTitle" component="input" type="text" defaultValue={boardTitle} />
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>구분</FormGroupLabel>
            {BoardTypeEnumsRaidoOptions().map(item => (
              <FormGroupField
                key={`index_${item.label}`}
                style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
              >
                <Field
                  name="boardType"
                  component={renderRadioButtonField}
                  label={item.label}
                  radioValue={item.radioValue}
                  initialValue={type}
                />
              </FormGroupField>
            ))}
          </FormGroup>
          {type === 'EVENT' && (
            <>
              <FormGroup>
                <FormGroupLabel>기존 이미지</FormGroupLabel>
                <FormGroupField>
                  {/* <Field name="imgUrl" component="input" type="text" defaultValue={imgUrl} /> */}
                  {imgUrl && (
                    <img src={imgUrl} style={{ width: '30%', height: '30%', objectFit: 'cover' }} alt="이미지" />
                  )}
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>새 이미지URL</FormGroupLabel>
                <FormGroupField style={{ flexDirection: 'column', alignItems: 'start' }}>
                  <Field name="newImgUrl" component="input" type="text" placeholder="변경시에만 입력하세요" />
                  <div style={{ color: 'red' }}>이미지 크기는 348px * 192px 로 올리셔야합니다.</div>
                </FormGroupField>
              </FormGroup>
            </>
          )}
          <FormGroup>
            <FormGroupLabel>노출</FormGroupLabel>
            {BoardStatusEnumsRadioOptions().map(item => (
              <FormGroupField
                key={`index_${item.label}`}
                style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
              >
                <Field
                  name="boardStatus"
                  component={renderRadioButtonField}
                  label={item.label}
                  radioValue={item.radioValue}
                  initialValue={status}
                />
              </FormGroupField>
            ))}
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>등록일</FormGroupLabel>
            <FormGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchRegistrationDate}
                  onChange={date => onSearchRegistrationDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd"
                  dropDownMode="select"
                  popperPlacement="bottom-start"
                  placeholderText="클릭하세요"
                  isClearable
                  locale={ko}
                />
              </SearchDatePickerWrap>
            </FormGroupField>
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>내용</FormGroupLabel>
            <Editor
              // editorState={boardContent.type === undefined ? content : boardContent}
              editorState={content}
              onEditorStateChange={editorState => contentChange(editorState)}
              wrapperClassName="wrapper"
              editorClassName="editor"
              toolbarClassName="toolbar"
            />
          </FormGroup>
          {
            {
              SUPER: (
                <FormButtonToolbar>
                  <Button variant="primary" type="submit">
                    수정하기
                  </Button>
                </FormButtonToolbar>
              ),
            }[authority]
          }
        </FormContainer>
      )}
    </Form>
  )
}
export default BoardModifyForm

BoardModifyForm.propTypes = {
  boardKey: PropTypes.string.isRequired,
  edgeName: PropTypes.string.isRequired,
  boardTitle: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  boardContent: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  registrationDate: PropTypes.string.isRequired,
  fetchSearchBoardList: PropTypes.func.isRequired,
  onClickHandler: PropTypes.func.isRequired,
}
