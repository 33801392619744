// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

import { convertToKstDateTime } from '../../../../utils/dateTime'

const MemberIpLogListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '아이디',
      accessor: 'memberId',
      disableGlobalFilter: true,
    },
    {
      Header: '이름',
      accessor: 'memberName',
      disableGlobalFilter: true,
    },
    {
      Header: 'IP',
      accessor: 'loginIp',
      disableGlobalFilter: true,
    },
    {
      Header: '로그인 주소',
      accessor: 'loginUrl',
      disableGlobalFilter: true,
    },
    {
      Header: '로그인 일',
      accessor: 'loginDate',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.loginDate)
      },
    },
    {
      Header: '디바이스',
      accessor: 'userDevice',
      disableGlobalFilter: true,
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberIpLogListData
