import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import SportsMixedManagement from './SportsMixedManagement'
import SportManagementTabsV2 from '../SportManagementTabsV2'
import { SportIdTypeEnums } from '../../../enums/SystemManagement/sportEnums'
import { setBookMakerMenu } from '../../../redux/BookMakerInfoSlice'
import MarketBookmakerMenu from './MarketBookmakerMenu'
import MarketSportMenu from './MarketSportMenu'
import MarketTypeMenu from './MarketTypeMenu'

const MarketAndMixedManage = () => {
  return (
    <Card>
      <CardBody>
        <SportManagementTabsV2 activeKey="8" />
        {/* 북메이커 메뉴 */}
        <MarketBookmakerMenu />

        {/* 종목 메뉴 */}
        <MarketSportMenu />
        <MarketTypeMenu />

        <BettingSettingWrap>
          {/* {activeSportMenu && activeBookMakerMenu && (
            <SportsMixedManagement sportId={activeSportMenu} bookmakerId={activeBookMakerMenu} />
          )} */}
          <SportsMixedManagement />
        </BettingSettingWrap>
      </CardBody>
    </Card>
  )
}

export default MarketAndMixedManage

const BettingSettingWrap = styled.div`
  width: 100%;
  border-top: solid 2px black;
`
const SportMenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 3px solid black;
  `};
`

const AuthoritySearchButtonBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 5px 0;
`
