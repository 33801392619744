import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const MemberHistoryTabs = ({ activeKey }) => {
  const history = useHistory()
  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/user/history/moneydepositV2')
                }}
              >
                머니 입금내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="7"
                onClick={e => {
                  history.push('/user/history/moneywithdrawalV2')
                }}
              >
                머니 출금내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/user/history/moneyexchange-rate')
                }}
              >
                환수율 내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/user/history/moneyexchange')
                }}
              >
                머니 이동내역
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="6"
                onClick={e => {
                  history.push('/user/history/attendance')
                }}
              >
                출석 내역
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default MemberHistoryTabs

MemberHistoryTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
