import { Card, CardBody } from '@/shared/components/Card'
import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../../shared/components/Tabs'

const LiveGameInfoManagementTabs = ({ activeKey }) => {
  const history = useHistory()

  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="BASEBALL"
                onClick={e => {
                  history.push('/management/sport/live-bettinglist?type=BASEBALL')
                }}
              >
                야구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="BASKETBALL"
                onClick={e => {
                  history.push('/management/sport/live-bettinglist?type=BASKETBALL')
                }}
              >
                농구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="VOLLEYBALL"
                onClick={e => {
                  history.push('/management/sport/live-bettinglist?type=VOLLEYBALL')
                }}
              >
                배구
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="ICE_HOCKEY"
                onClick={e => {
                  history.push('/management/sport/live-bettinglist?type=ICE_HOCKEY')
                }}
              >
                하키
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="E_SPORTS"
                onClick={e => {
                  history.push('/management/sport/live-bettinglist?type=E_SPORTS')
                }}
              >
                E-SPORT
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}

export default LiveGameInfoManagementTabs

LiveGameInfoManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
