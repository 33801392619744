import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 회원 머니이동내역 조회 시작
export async function searchMemberMoneyChangeLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/money-change-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 머니이동내역 삭제
export async function deleteMoneyChangeLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/money-change-log',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberMoneyChangeLog(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMemberMoneyChangeLog() {
    await searchMemberMoneyChangeLog(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyChangeLog()
  }, [params])

  return { totalElement, content, fetchSearchMemberMoneyChangeLog }
}
