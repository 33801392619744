import ReactTableBase from '@/shared/components/table/ReactTableBase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import RealTimeSportBettingLogListData from './RealTimeSportBettingLogListData'

const RealTimeSportBettingHiddenContent = ({ content, fetchSearchSportsMarket }) => {
  const [bettingLogListData, setBettingLogListData] = useState(
    RealTimeSportBettingLogListData(content, fetchSearchSportsMarket),
  )
  useEffect(() => {
    setBettingLogListData(RealTimeSportBettingLogListData(content, fetchSearchSportsMarket))
  }, [content])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  return (
    <SportsGameListHiddenWrap>
      <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} />
    </SportsGameListHiddenWrap>
  )
}

export default RealTimeSportBettingHiddenContent

RealTimeSportBettingHiddenContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape()),
  fetchSearchSportsMarket: PropTypes.func,
}

RealTimeSportBettingHiddenContent.defaultProps = {
  content: [],
  fetchSearchSportsMarket: null,
}

const SportsGameListHiddenWrap = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`
