import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default function ApproveWithdrawalRadio({ rowMemberInfo, setCheckWithdrawalApprove }) {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        setCheckWithdrawalApprove(rowMemberInfo)
        break
      default:
        break
    }
  }

  return <CustomInput type="radio" name="chech" onClick={e => approveHandler(e)} />
}

ApproveWithdrawalRadio.propTypes = {
  setCheckWithdrawalApprove: PropTypes.func.isRequired,
  rowMemberInfo: PropTypes.shape({
    memberId: PropTypes.string,
    moneyWithdrawalKey: PropTypes.string,
    memberName: PropTypes.string,
    type: PropTypes.string,
    applyMoney: PropTypes.string,
    applyDate: PropTypes.string,
    executeDate: PropTypes.string,
    bonsa: PropTypes.string,
    chongpan: PropTypes.string,
    maejang: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
}

const CustomInput = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
