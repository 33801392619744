import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import styled from 'styled-components'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  useSearchDuplicationIp,
  updateRestrictionIp,
  updateDuplicationIp,
} from '../../../../utils/api/logManage/logApi'
import { decodeAccessToken } from '../../../../utils/token'
import DuplicationListData from './DuplicationListData'
import CustomPagination from '../../../../shared/components/customPagination/CustomPagination'
import { SearchCardTitleWrap } from '../../../../shared/components/Card'

const DuplicationIpHistory = () => {
  const authority = decodeAccessToken()?.adminAuthority

  /**
   * 회원의 머니 증감 이력 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [moneyIncDecParams, setMoneyIncDecParams] = useState({
    loginIp: null,
    page,
    size,
  })

  const { totalElement, content, fetchSearchDuplicationIp, excludeIpList } = useSearchDuplicationIp(moneyIncDecParams)

  const [duplicationListData, setDuplicationListData] = useState(DuplicationListData(content))

  useEffect(() => {
    setDuplicationListData(DuplicationListData(content))
  }, [content])

  const [duplicationIpRows, setDuplicationIpRows] = useState(duplicationListData.tableRowsData)

  useEffect(() => {
    setDuplicationIpRows(duplicationListData.tableRowsData)
  }, [duplicationListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  useEffect(() => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 아이디
  const [searchLoginIp, setSearchLoginIp] = useState('')

  const onSearchLoginIpHandler = e => {
    setSearchLoginIp(e.target.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page: 0,
      loginIp: !searchLoginIp ? null : searchLoginIp,
    }))
  }

  const [infoLoading, setInfoLoading] = useState(false)
  // 검색하기 버튼
  const onRestrictionClickHandler = restrictionIp => {
    if (infoLoading) return
    if (window.confirm(`${restrictionIp} IP를 차단하시겠습니까?`)) {
      const body = {
        restrictionIp,
      }
      setInfoLoading(true)
      updateRestrictionIp(body)
        .then(res => {
          setInfoLoading(false)
          alert(`${restrictionIp} 차단이 성공했습니다.`)
          fetchSearchDuplicationIp()
        })
        .catch(error => {
          setInfoLoading(false)
          alert(`${restrictionIp} 차단이 실패했습니다.`)
          fetchSearchDuplicationIp()
        })
    }
    // setMoneyIncDecParams(prev => ({
    //   ...prev,
    //   loginIp: !searchLoginIp ? null : searchLoginIp,
    // }))
  }

  const onRemoveHandler = restrictionIp => {
    if (infoLoading) return
    if (window.confirm(`${restrictionIp} IP를 제외시키겠습니까?\n제외 시, 더이상 중복 IP에 나타나지 않습니다.`)) {
      const body = {
        restrictionIp,
      }
      setInfoLoading(true)
      updateDuplicationIp(body)
        .then(res => {
          setInfoLoading(false)
          alert(`${restrictionIp} IP 제외가 성공했습니다.`)
          fetchSearchDuplicationIp()
        })
        .catch(error => {
          setInfoLoading(false)
          alert(`${restrictionIp} IP 제외가 실패했습니다.`)
          fetchSearchDuplicationIp()
        })
    }
    // setMoneyIncDecParams(prev => ({
    //   ...prev,
    //   loginIp: !searchLoginIp ? null : searchLoginIp,
    // }))
  }

  return (
    <>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
          <p>* 해당 IP를 제외 시킬 경우 더이상 중복IP에 나오지 않습니다.</p>
        </div>
      </SearchCardTitleWrap>
      <div style={{ marginBottom: '10px' }}>
        <p>
          현재 제외IP : <span style={{ color: 'black', fontWeight: '500' }}>{excludeIpList}</span>
        </p>
      </div>
      <SearchContainer horizontal>
        <SearchGroup style={{ marginLeft: '20px' }}>
          <SearchGroupLabel>로그인 IP :</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchValue"
                type="text"
                placeholder="공백없이 입력"
                value={searchLoginIp}
                onChange={e => onSearchLoginIpHandler(e)}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap style={{ marginLeft: '20px' }}>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <SportsGameListTableWrap>
        <SportsGameListTable>
          <SportsGameListTableHead>
            <SportsGameListTableTr>
              <th style={{ width: '5%' }}>번호</th>
              <th style={{ width: '15%' }}>로그인 IP</th>
              <th style={{ width: '70%' }}>유저 목록</th>
              <th style={{ width: '5%' }}>차단</th>
              <th style={{ width: '5%' }}>제외</th>
            </SportsGameListTableTr>
          </SportsGameListTableHead>
          {content.map((item, index) => (
            <SportsGameListTableBody key={item.loginIp}>
              <SportsGameListTableTr>
                <SportsGameFolderContentBox>
                  <SportsGameFolderNumBox2>{item.num}</SportsGameFolderNumBox2>
                  <SportsGameFolderNumBox>
                    <span style={{ width: '100%', wordBreak: 'break-word' }}>{item.loginIp}</span>
                  </SportsGameFolderNumBox>
                  <SportsGameFolderContentTime>{item.userIdNameAuthorityList}</SportsGameFolderContentTime>
                  <SportsGameFolderNumBox2>
                    <button
                      onClick={() => onRestrictionClickHandler(item.loginIp)}
                      type="button"
                      style={{ border: 'none', background: 'red', color: '#fff' }}
                    >
                      차단
                    </button>
                  </SportsGameFolderNumBox2>
                  <SportsGameFolderNumBox2>
                    <button
                      onClick={() => onRemoveHandler(item.loginIp)}
                      type="button"
                      style={{ border: 'none', background: 'blue', color: '#fff' }}
                    >
                      제외
                    </button>
                  </SportsGameFolderNumBox2>
                </SportsGameFolderContentBox>
              </SportsGameListTableTr>
            </SportsGameListTableBody>
          ))}
          {content.length === 0 && <div style={{ color: 'black' }}>중복 이력이 없습니다.</div>}
        </SportsGameListTable>
        <PaginationWrap>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </PaginationWrap>
      </SportsGameListTableWrap>
    </>
  )
}

export default DuplicationIpHistory

DuplicationIpHistory.propTypes = {}

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  height: 25px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`
const HeaderWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  &:hover {
    border: solid 1px red;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox2 = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderNumBox = styled.div`
  width: 15%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderScoreBox = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResultBox = styled.div`
  width: 13%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResult2Box = styled.div`
  width: 8%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentHomeBox = styled.div`
  width: 15%;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGamePartnerBox = styled.div`
  width: 15%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  width: 100px;
  display: flex;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  color: white;
  width: 70%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  padding: 5px 0px;
  // height: 36px;
`
