import { createSlice } from '@reduxjs/toolkit'

const InPlayBookMakerInfoSlice = createSlice({
  name: 'bookMakerInfo',
  initialState: {
    bookMakerMenu: [],
    activeInPlayMarketBookmakerMenu: -1,
  },
  reducers: {
    setInPlayBookMakerMenu: (state, action) => {
      const bookMakerList = action.payload

      const filteredBookMakerList = bookMakerList.filter(item => item.bookmakerId === 8 || item.bookmakerId === 999)

      const firstBookmaker = filteredBookMakerList[0] || []

      state.bookMakerMenu = filteredBookMakerList.map(item => {
        return {
          bookmakerId: item.bookmakerId,
          bookmakerName: item.bookmakerName,
        }
      })

      state.activeInPlayMarketBookmakerMenu = firstBookmaker?.bookmakerId || -1
    },

    setActiveInPlayMarketBookmakerMenu: (state, action) => {
      state.activeInPlayMarketBookmakerMenu = action.payload
    },
  },
  extraReducers: builder => {},
})

export const { setInPlayBookMakerMenu, setActiveInPlayMarketBookmakerMenu } = InPlayBookMakerInfoSlice.actions

export default InPlayBookMakerInfoSlice.reducer
