import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../shared/components/Tabs'

const LogPageTabs = ({ activeKey }) => {
  const history = useHistory()

  return (
    <BorderedBottomTabs>
      <Tab.Container defaultActiveKey={activeKey}>
        <TabsWrap>
          <Nav className="nav-tabs">
            <NavItem>
              <NavLink
                eventKey="3"
                onClick={e => {
                  history.push('/management/log/money-change-log')
                }}
              >
                통합머니 로그
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="5"
                onClick={e => {
                  history.push('/management/log/moneydepowithdraw')
                }}
              >
                입출금 로그
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="2"
                onClick={e => {
                  history.push('/management/log/moneyinc')
                }}
              >
                머니증감 로그
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="4"
                onClick={e => {
                  history.push('/management/log/couponpay')
                }}
              >
                쿠폰지급 로그
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="1"
                onClick={e => {
                  history.push('/management/log/login')
                }}
              >
                로그인 로그
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                eventKey="6"
                onClick={e => {
                  history.push('/management/log/admin')
                }}
              >
                시스템 로그
              </NavLink>
            </NavItem>
          </Nav>
        </TabsWrap>
      </Tab.Container>
    </BorderedBottomTabs>
  )
}
export default LogPageTabs

LogPageTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
