import {
  AssetIncDecMethodTypeEnumsSelectOptions,
  MemberMoneyTypeEnumsSelectOptions,
  MoneyIncDecTypeEnumsSelectOptions,
} from '@/enums/MemberManage/MemberMoneyIncDecEnums'
import { MemberMoneyIncDecCard } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { convertToKst } from '@/utils/dateTime'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import Loading from '../../../../shared/components/Loading'
import {
  updateMemberAssetIncDecInfo,
  useSearchAssetIncDec,
  useSearchMemberAssetInfo,
} from '../../../../utils/api/logManage/logApi'
import { decodeAccessToken } from '../../../../utils/token'
import MemberMoneyIncDecListData from '../../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecListData'

const MemberMoneyIncDecLisForData = ({ memberId }) => {
  const [moneyIncDecLoading, setMoneyIncDecLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority
  // 매장일때...

  /**
   * 회원의 머니 증감 이력 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [moneyIncDecParams, setMoneyIncDecParams] = useState({
    userId: memberId,
    isExact: true,
    assetType: null,
    incDecType: null,
    startDate: null,
    endDate: null,
    page,
    size,
  })

  useEffect(() => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchAssetIncDec } = useSearchAssetIncDec(moneyIncDecParams)

  const [memberMoneyIncDecListData, setMemberMoneyIncDecListData] = useState(MemberMoneyIncDecListData(content))

  useEffect(() => {
    setMemberMoneyIncDecListData(MemberMoneyIncDecListData(content))
  }, [content])

  const [memberMoneyIncDecRows, setMemberMoneyIncDecRows] = useState(memberMoneyIncDecListData.tableRowsData)

  useEffect(() => {
    setMemberMoneyIncDecRows(memberMoneyIncDecListData.tableRowsData)
  }, [memberMoneyIncDecListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 종류
  const [searchMoneyIncDecType, setSearchMoneyIncDecType] = useState('')

  const [searchMoneyIncDecTypeOptionValue, setSearchMoneyIncDecTypeOptionValue] = useState(null)
  const searchMoneyIncDecTypeOption = MoneyIncDecTypeEnumsSelectOptions()

  const onSelectMoneyIncDecTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyIncDecTypeOptionValue(null)
      setSearchMoneyIncDecType(null)
      return
    }
    setSearchMoneyIncDecTypeOptionValue(option)
    setSearchMoneyIncDecType(option.value)
  }

  // 구분 - 포인트 , 머니 , 콤프
  const [searchMoneyType, setSearchMoneyType] = useState('')

  const [searchMoneyTypeOptionValue, setSearchMoneyTypeOptionValue] = useState(null)
  const searchMoneyTypeOption = MemberMoneyTypeEnumsSelectOptions()

  const onSelectTypeOptionHandler = option => {
    if (option === null) {
      setSearchMoneyTypeOptionValue(null)
      setSearchMoneyType(null)
      return
    }
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyTypeOptionValue(option)
    setSearchMoneyType(option.value)
  }

  // 처리일시 시작 + 끝
  const [searchMoneyIncDecStartDate, setSearchMoneyIncDecStartDate] = useState(null)
  const [searchMoneyIncDecEndDate, setSearchMoneyIncDecEndDate] = useState(null)

  const onSearchMoneyIncDecStartDateChangeHandler = date => {
    setSearchMoneyIncDecStartDate(date)
  }
  const onSearchMoneyIncDecEndDateChangeHandler = date => {
    setSearchMoneyIncDecEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMoneyIncDecParams(prev => ({
      ...prev,
      page: 0,
      assetType: !searchMoneyType ? null : searchMoneyType,
      incDecType: !searchMoneyIncDecType ? null : searchMoneyIncDecType,
      startDate: !searchMoneyIncDecStartDate ? null : convertToKst(searchMoneyIncDecStartDate),
      endDate: !searchMoneyIncDecEndDate ? null : convertToKst(searchMoneyIncDecEndDate),
    }))
  }

  /**
   * 회원 머니증감 정보 조회
   * partnerId 있으면 매장 보유머니도 같이 조회해야 함
   */
  const [memberMoneyIncDecInfoParams, setMemberMoneyIncDecInfoParams] = useState({
    memberId,
  })

  const [userMoneyAmount, setUserMoneyAmount] = useState('0')
  const [userPointAmount, setUserPointAmount] = useState('0')

  const { memberMoneyIncDecInfoContent, fetchSearchMemberAssetInfo } =
    useSearchMemberAssetInfo(memberMoneyIncDecInfoParams)

  useEffect(() => {
    setUserMoneyAmount(memberMoneyIncDecInfoContent?.holdingMoney)
    setUserPointAmount(memberMoneyIncDecInfoContent?.holdingMemberPoint)
  }, [memberMoneyIncDecInfoContent])

  // 증차감 선택
  const [moneyIncDecType, setMoneyIncDecType] = useState('')

  const moneyIncDecTypeOption = AssetIncDecMethodTypeEnumsSelectOptions()

  // 머니/콤프 선택
  const [assetType, setAssetType] = useState('')

  const assetTypeOption = MemberMoneyTypeEnumsSelectOptions()

  // 증차감 금액
  const [moneyIncDecAmount, setMoneyIncDecAmount] = useState('')

  // 증차감 메모
  const [moneyIncDecMemo, setMoneyIncDecMemo] = useState('')

  // 적용하기
  const onSubmit = e => {
    if (moneyIncDecLoading) return

    const body = {
      memberId,
      assetType: e.assetType.value,
      incDecType: e.moneyIncDecType.value,
      assetIncDecAmount: e.moneyIncDecAmount,
      memo: e.moneyIncDecMemo,
    }
    setMoneyIncDecLoading(true)
    updateMemberAssetIncDecInfo(body)
      .then(res => {
        setMoneyIncDecLoading(false)
        alert('적용 되었습니다.')
        fetchSearchMemberAssetInfo()
        fetchSearchAssetIncDec()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          default:
            alert('적용 실패하였습니다.')
        }
        setMoneyIncDecLoading(false)
      })
  }

  return (
    <>
      {moneyIncDecLoading ? (
        <Loading />
      ) : (
        <>
          <Row>
            <Col md={12} lg={12}>
              <MemberMoneyIncDecCard>
                {/* 검색 기능 */}
                <SearchContainer horizontal>
                  <SearchGroup>
                    <SearchGroupLabel>종류</SearchGroupLabel>
                    <SearchGroupField>
                      <SearchSelect
                        name="searchMoneyType"
                        onChange={option => {
                          onSelectMoneyIncDecTypeOptionHandler(option)
                        }}
                        value={searchMoneyIncDecTypeOptionValue}
                        options={searchMoneyIncDecTypeOption}
                        placeholder="종류"
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                      />
                    </SearchGroupField>
                  </SearchGroup>
                  <SearchGroup>
                    <SearchGroupLabel>구분</SearchGroupLabel>
                    <SearchGroupField>
                      <SearchSelect
                        name="searchMoneyType"
                        onChange={option => {
                          onSelectTypeOptionHandler(option)
                        }}
                        value={searchMoneyTypeOptionValue}
                        options={searchMoneyTypeOption}
                        placeholder="구분"
                        className="react-select"
                        classNamePrefix="react-select"
                        isClearable
                      />
                    </SearchGroupField>
                  </SearchGroup>
                  <SearchGroup>
                    <SearchGroupLabel>시작일</SearchGroupLabel>
                    <SearchGroupField>
                      <SearchDatePickerWrap>
                        <DatePicker
                          selected={searchMoneyIncDecStartDate}
                          onChange={date => onSearchMoneyIncDecStartDateChangeHandler(date)}
                          dateFormat="yyyy-MM-dd"
                          dropDownMode="select"
                          popperPlacement="bottom-start"
                          placeholderText="시작일"
                          isClearable
                        />
                      </SearchDatePickerWrap>
                    </SearchGroupField>
                  </SearchGroup>
                  <SearchGroup>
                    <SearchGroupLabel>종료일</SearchGroupLabel>
                    <SearchGroupField>
                      <SearchDatePickerWrap>
                        <DatePicker
                          selected={searchMoneyIncDecEndDate}
                          onChange={date => onSearchMoneyIncDecEndDateChangeHandler(date)}
                          dateFormat="yyyy-MM-dd"
                          dropDownMode="select"
                          popperPlacement="bottom-start"
                          placeholderText="종료일"
                          isClearable
                        />
                      </SearchDatePickerWrap>
                    </SearchGroupField>
                  </SearchGroup>
                  <SearchButtonWrap>
                    <Button
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => {
                        onSearchClickHandler()
                      }}
                    >
                      검색하기
                    </Button>
                  </SearchButtonWrap>
                </SearchContainer>
                <ReactTableBase
                  key="common"
                  columns={memberMoneyIncDecListData.tableHeaderData}
                  data={memberMoneyIncDecRows}
                  tableConfig={tableConfig}
                />
              </MemberMoneyIncDecCard>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

MemberMoneyIncDecLisForData.propTypes = {
  memberId: PropTypes.string.isRequired,
}

export default MemberMoneyIncDecLisForData
