import { Button } from '@/shared/components/Button'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import { borderRight } from '@/utils/directions'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import { ko } from 'date-fns/esm/locale'
import TimePicker from 'rc-time-picker'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import styled from 'styled-components'
import {
  AttendanceEventEnumsRaidoOptions,
  PayBackEventEnumsRaidoOptions,
} from '../../../enums/SystemManagement/siteEnums'
import { CustomDatePickerWrap } from '../../../shared/components/CustomDatePicker'
import Loading from '../../../shared/components/Loading'
import { searchEventManage, updateEventManage } from '../../../utils/api/systemManage/systemManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { eventManagementValidate } from '../../../utils/validate/systemManagement/systemManage'
// import validate from '../validate'

const EventMangeMent = () => {
  /**
   시스템설정 조회
   */
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)

  const [content, setContent] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [minimumAttendanceAmount, setMinimumAttendanceAmount] = useState('0')
  const [thirdAttendanceBonus, setThirdAttendanceBonus] = useState('0')
  const [sixthAttendanceBonus, setSixthAttendanceBonus] = useState('0')
  const [attendanceEventStatus, setAttendanceEventStatus] = useState('USE')
  const [payBackEventStatus, setPayBackEventStatus] = useState('USE')
  const [payBackEventCriteria, setPayBackEventCriteria] = useState('0')
  const [payBackEventMaxAmount, setPayBackEventMaxAmount] = useState('0')

  const fetchSearchEventManage = async () => {
    await searchEventManage({}).then(res => {
      setContent(res.data.content)
      setStartDate(new Date(res.data.content.payBackStartAt))
      setEndDate(new Date(res.data.content.payBackEndAt))
    })
  }

  useEffect(() => {
    fetchSearchEventManage()
  }, [])

  const [eventManageData, setEventManageData] = useState(content)

  useEffect(() => {
    setMinimumAttendanceAmount(content.minimumAttendanceAmount)
    setThirdAttendanceBonus(content.thirdAttendanceBonus)
    setSixthAttendanceBonus(content.sixthAttendanceBonus)
    setAttendanceEventStatus(content.attendanceEventStatus)
    setPayBackEventStatus(content.payBackEventStatus)
    setPayBackEventCriteria(content.payBackEventCriteria)
    setEventManageData(content)
  }, [content])

  const onSubmit = e => {
    if (writeLoading) return
    if (e.payBackEventStatus === 'USE' && (startDate === null || endDate === null)) {
      alert('페이백 이벤트 사용 시, 날짜를 지정해주세요.')
      return
    }

    if (e.payBackEventStatus === 'USE' && endDate <= startDate) {
      alert('페이백 이벤트 사용 시, \n페이백 시작일이 종료일보다 클 수 없습니다.')
      return
    }

    if (window.confirm('이벤트 설정을 변경 하시겠습니까?')) {
      const body = {
        minimumAttendanceAmount: minimumAttendanceAmount?.toString().replaceAll(',', ''),
        thirdAttendanceBonus: thirdAttendanceBonus?.toString().replaceAll(',', ''),
        sixthAttendanceBonus: sixthAttendanceBonus?.toString().replaceAll(',', ''),
        attendanceEventStatus,
        payBackEventStatus,
        payBackEventCriteria: payBackEventCriteria?.toString().replaceAll(',', ''),
        payBackStartAt: startDate,
        payBackEndAt: endDate,
      }

      setWriteLoading(true)
      updateEventManage(body)
        .then(res => {
          setWriteLoading(false)
          alert('이벤트 설정이 변경됐습니다.')
          fetchSearchEventManage()
        })
        .catch(error => {
          setWriteLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'EVENT-1003') {
            alert(`${message}`)
          } else if (errorCode === 'EVENT-1005') {
            alert(`${message}`)
          } else {
            alert('이벤트 설정이 변경이 실패했습니다.')
          }
          window.location.reload()
        })
    }
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <SearchCardTitleWrap>
          <div>
            <p>
              * 출석 이벤트 미 사용 시 회원은 &nbsp;
              <span style={{ fontWeight: 'bolder', color: 'red' }}>출석체크가</span> 불가합니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <CardBody style={{ padding: '0px' }}>
          {writeLoading ? (
            <Loading />
          ) : (
            <>
              <Form
                onSubmit={onSubmit}
                validate={eventManagementValidate}
                initialValues={{
                  minimumAttendanceAmount,
                  thirdAttendanceBonus,
                  sixthAttendanceBonus,
                  attendanceEventStatus,
                  payBackEventStatus,
                  payBackEventCriteria,
                  payBackStartAt: startDate,
                  payBackEndAt: endDate,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <FormContainer horizontal onSubmit={handleSubmit}>
                    <CustomFormGroup>
                      <CustomFormGroupLabel style={{ fontWeight: '500', color: 'red' }}>
                        출석이벤트 사용여부
                      </CustomFormGroupLabel>
                      {AttendanceEventEnumsRaidoOptions().map(item => (
                        <CustomFormGroupField2
                          key={`index_${item.label}`}
                          style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                        >
                          <Field
                            name={`${item.name}`}
                            onCustomChange={e => {
                              setAttendanceEventStatus(e)
                            }}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={item.initialValue}
                          />
                        </CustomFormGroupField2>
                      ))}
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        <span style={{ fontWeight: '500', color: 'red' }}>출석이벤트 세부내용</span> <br />
                        (조건액, 3일차보너스액, 6일차 보너스액)
                      </CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="minimumAttendanceAmount">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    setMinimumAttendanceAmount(e.target.value)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                        <Field name="thirdAttendanceBonus">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    setThirdAttendanceBonus(e.target.value)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                        <Field name="sixthAttendanceBonus">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    setSixthAttendanceBonus(e.target.value)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <SearchCardTitleWrap style={{ marginTop: '-10px' }}>
                      <div>
                        <p>
                          * 페이백 이벤트 사용 시,{' '}
                          <span style={{ fontWeight: 'bolder', color: 'blue' }}>종료일이 되는 순간 정산이 시작</span>
                          되며, &nbsp;
                          <span style={{ fontWeight: 'bolder', color: 'blue' }}>정산이 끝나면</span> 다시{' '}
                          <span style={{ fontWeight: 'bolder', color: 'blue' }}>미사용</span>으로 바뀝니다.
                        </p>
                        <p>
                          * 페이백 이벤트 <span style={{ fontWeight: 'bolder', color: 'blue' }}>기간 변경</span> 시에만
                          새로운 <span style={{ fontWeight: 'bolder', color: 'blue' }}>트리거가 다시 발생</span>됩니다.
                        </p>
                        <p>
                          * 페이백의 <span style={{ fontWeight: 'bolder', color: 'blue' }}>%, 최대액</span>은&nbsp;
                          <span style={{ fontWeight: 'bolder', color: 'blue' }}>레벨관리- 입금레벨</span>에서 설정하실
                          수 있습니다.
                        </p>
                      </div>
                    </SearchCardTitleWrap>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        <span style={{ fontWeight: '500', color: 'blue' }}>페이백이벤트 사용여부</span>
                      </CustomFormGroupLabel>
                      {PayBackEventEnumsRaidoOptions().map(item => (
                        <CustomFormGroupField2
                          key={`index_${item.label}`}
                          style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                        >
                          <Field
                            name={`${item.name}`}
                            onCustomChange={e => {
                              setPayBackEventStatus(e)
                            }}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={item.initialValue}
                          />
                        </CustomFormGroupField2>
                      ))}
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        <span style={{ fontWeight: '500', color: 'blue' }}>페이백 지급조건</span> <br />
                        (ex. 입금횟수가 X회 이상)
                      </CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="payBackEventCriteria">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    setPayBackEventCriteria(e.target.value)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>회</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel style={{ fontWeight: '500', color: 'blue' }}>시작일</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="payBackStartAt">
                          {({ input, meta }) => {
                            return (
                              <>
                                <CustomDatePickerWrap>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    timeFormat="HH:mm"
                                    showTimeSelect
                                    dropDownMode="select"
                                    popperPlacement="bottom-start"
                                    placeholderText="시작일을 선택하세요."
                                    isClearable
                                    locale={ko}
                                  />
                                  {meta.touched && meta.error && <Error error={meta.error} top />}
                                </CustomDatePickerWrap>
                              </>
                            )
                          }}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel style={{ fontWeight: '500', color: 'blue' }}>종료일</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="payBackEndAt">
                          {({ input, meta }) => (
                            <CustomDatePickerWrap>
                              <DatePicker
                                selected={endDate}
                                onChange={date => setEndDate(date)}
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeFormat="HH:mm"
                                showTimeSelect
                                dropDownMode="select"
                                popperPlacement="bottom-start"
                                placeholderText="종료일을 선택하세요."
                                isClearable
                                locale={ko}
                              />
                              {meta.touched && meta.error && <Error error={meta.error} top />}
                            </CustomDatePickerWrap>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    {
                      {
                        SUPER: (
                          <FormButtonToolbar className="registrationFooter">
                            <Button variant="primary" type="submit">
                              등록
                            </Button>
                          </FormButtonToolbar>
                        ),
                      }[authority]
                    }
                  </FormContainer>
                )}
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

EventMangeMent.propTypes = {}

export default EventMangeMent

const CustomFormGroup = styled(FormGroup)`
  border-bottom: dotted 1px black;
  padding-bottom: 10px;
  width: 100%;
`

const CustomFormGroupLabel = styled(FormGroupLabel)`
  width: 30% !important;
  font-weight: bolder;
  color: black;
  // border: solid 1px red;
  text-align: center;
`
const CustomFormGroupField2 = styled(FormGroupField)`
  width: 30% !important;
  // border: solid 1px red;
  input {
    border: solid 1px black;
  }
`

const CustomFormGroupField = styled(FormGroupField)`
  width: 70% !important;
  // border: solid 1px red;
  input {
    border: solid 1px black;
  }
`

// 회원 쿠폰 Select
export const MemberCouponSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
// endregion

const CustomTimePicker = styled(TimePicker)`
  & .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  & .rc-time-picker-clear,
  & .rc-time-picker-clear-icon:after {
    font-size: 15px;
  }

  & .rc-time-picker-panel-select,
  & .rc-time-picker-input,
  & .rc-time-picker-panel-input {
    font-family: 'Consolas', sans-serif;
    font-size: 16px;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`
