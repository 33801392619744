import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { publishToOneMemberNote } from '@/utils/api/operation/operationApi'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Field, Form } from 'react-final-form'
import 'react-quill/dist/quill.snow.css' // Quill의 스타일 시트
import styled from 'styled-components'
import { decodeAccessToken } from '../../../utils/token'
import Loading from '../../../shared/components/Loading'
import { updateMemberMoneyDepositModify } from '../../../utils/api/memberHistory/memberHistoryApi'

function DepositMemoForm({ memberId, memo, moneyDepositKey, onClickHandler, fetchSearchMemberMoneyDeposit }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [noteLoading, setNoteLoading] = useState(false)
  const [newMemo, setNewMemo] = useState(memo || '')

  const onSubmit = e => {
    if (noteLoading) return

    const body = {
      moneyDepositKey,
      memo: !newMemo ? null : newMemo,
    }

    setNoteLoading(true)
    updateMemberMoneyDepositModify(body)
      .then(res => {
        setNoteLoading(false)
        alert('메모 수정이 완료되었습니다.')
        onClickHandler()
        window.location.reload()
      })
      .catch(error => {
        const { errorCode, message } = error.response.data

        switch (errorCode) {
          case 'ASSET-7001':
            alert('잠시후 다시 시도해주세요.')
            break
          case 'MONEY-2003':
            alert('해당 입금건에 대해서 처리중이거나, 처리된 상태입니다. 확인 후 다시 시도해주세요.')
            break
          default:
            alert('메모 수정이 실패했습니다. 다시 시도해주세요.')
        }
        setNoteLoading(false)
      })
  }

  return (
    <>
      {noteLoading ? (
        <Loading />
      ) : (
        <>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              newMemo,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>메모</FormGroupLabel>
                  <FormGroupField>
                    <Field name="newMemo">
                      {({ input, meta }) => (
                        <>
                          <FormInputWrap>
                            <input
                              {...input}
                              style={{ border: 'solid 1px black' }}
                              type="text"
                              value={newMemo}
                              onChange={e => {
                                setNewMemo(e.target.value)
                              }}
                            />
                            {meta.touched && meta.error && <Error error={meta.error} top />}
                          </FormInputWrap>
                        </>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar style={{ marginLeft: '0px' }}>
                  {
                    {
                      SUPER: (
                        <>
                          <Button variant="primary" type="submit">
                            메모 수정하기
                          </Button>
                        </>
                      ),
                    }[authority]
                  }
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </>
      )}
    </>
  )
}

export default DepositMemoForm

DepositMemoForm.propTypes = {
  memberId: PropTypes.string,
  moneyDepositKey: PropTypes.string,
  memo: PropTypes.string,
  onClickHandler: PropTypes.func.isRequired,
  fetchSearchMemberMoneyDeposit: PropTypes.func.isRequired,
}

DepositMemoForm.defaultProps = {
  memberId: null,
  moneyDepositKey: null,
  memo: null,
}

export const CustomFormGroupField = styled(FormGroupField)`
  // border: solid 1px #000;
  height: 270px;
  width: 695px !important;
  padding: 0px !important;
  overflow: auto;
  &::-webkit-scrollbar {
    height: 1px;
    width: 1px;
    opacity: 0.3;
  }
  margin-left: 20px !important;
  // padding-left: 5px !important;
  padding-top: 5px !important;
`
