import { Button } from '@/shared/components/Button'
import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  CustomStyledModal,
  BlackMemberCustomStyledModal,
} from '@/shared/components/CustomModal'
import styled from 'styled-components'
import { FaSearch } from 'react-icons/fa'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
} from '@/shared/components/form/FormElements'
import FormField from '@/shared/components/form/FormField'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Field, Form } from 'react-final-form'

function MemberBlackInfoModal({ blackMemberInfo }) {
  const [issuspectInfoModalShow, setIssuspectInfoModalShow] = useState(false)

  const toggle = () => {
    setIssuspectInfoModalShow(prevState => !prevState)
  }

  const onSubmit = () => {
    setIssuspectInfoModalShow(prevState => !prevState)
  }

  return (
    <div>
      <span
        onClick={() => {
          window.open(`/user-info/${blackMemberInfo.memberId}`, '_blank')
        }}
        style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
      >
        {blackMemberInfo.memberId}({blackMemberInfo.memberName})
      </span>

      <BlackMemberCustomStyledModal show={issuspectInfoModalShow} onHide={toggle} $header>
        <CustomModalHeader>
          <CustomModalCloseButton className="lnr lnr-cross" aria-label="close-btn" type="button" onClick={toggle} />
          <CustomModalTitle>블랙 유저 정보</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <FormGroup>
                  <FormGroupLabel>아이디</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="suspectId"
                      component={FormField}
                      type="text"
                      defaultValue={blackMemberInfo.memberId}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>비밀번호</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="password"
                      component={FormField}
                      type="text"
                      defaultValue={blackMemberInfo.memberPassword}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>이름</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="memberName"
                      component={FormField}
                      type="text"
                      defaultValue={blackMemberInfo.memberName}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>연락처</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="memberPhone"
                      component={FormField}
                      type="text"
                      defaultValue={blackMemberInfo.phoneNum}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>예금주</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="accountHolder"
                      component={FormField}
                      type="text"
                      defaultValue={blackMemberInfo.memberName}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>은행</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="memberBank"
                      component={FormField}
                      type="text"
                      defaultValue={blackMemberInfo.bankName}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>계좌번호</FormGroupLabel>
                  <FormGroupField>
                    <Field
                      name="memberAccountNum"
                      component={FormField}
                      type="text"
                      defaultValue={blackMemberInfo.accountNum}
                      disabled
                    />
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar style={{ marginLeft: '-10px' }}>
                  <Button variant="secondary" type="button" onClick={toggle}>
                    확인
                  </Button>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </CustomModalBody>
      </BlackMemberCustomStyledModal>
    </div>
  )
}

MemberBlackInfoModal.propTypes = {
  blackMemberInfo: PropTypes.shape({
    num: PropTypes.number,
    damageType: PropTypes.string,
    memberId: PropTypes.string,
    memberPassword: PropTypes.string,
    memberName: PropTypes.string,
    damageAmount: PropTypes.string,
    phoneNum: PropTypes.string,
    bankName: PropTypes.string,
    accountNum: PropTypes.string,
    accountHolder: PropTypes.string,
    damageContent: PropTypes.string,
    damageDate: PropTypes.string,
  }).isRequired,
}

export default MemberBlackInfoModal

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
