import { Button } from '@/shared/components/Button'
import {
  CustomFormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { saveAs } from 'file-saver'
import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as XLSX from 'xlsx'
import { Card, CardBody, SearchCardTitleWrap } from '../../../shared/components/Card'
import Loading from '../../../shared/components/Loading'
import { postUserRegistrationExcel } from '../../../utils/api/excel/excelApi'
import { decodeAccessToken } from '../../../utils/token'
import MemberManageMentTabs from '../MemberManageMentTabs'

const ExcelUserRegistrationPage = () => {
  const history = useHistory()
  const [registrationLoading, setRegistrationLoading] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const authority = decodeAccessToken()?.adminAuthority
  const [selectedFile, setSelectedFile] = useState(null) // 파일 상태 추가

  const downloadMemberListHandler = () => {
    if (downloadLoading) return

    if (window.confirm('유저 등록양식을 다운 받으시겠습니까?')) {
      setDownloadLoading(true)

      // 데이터 생성
      const sheetData = [
        [
          '아이디(*필수)',
          '이름(*필수)',
          '닉네임(*필수)',
          '비밀번호(*필수)',
          '전화번호(- 없이 입력)',
          '생년월일(ex) 901211)',
          '은행명(은행관리에 있는 은행명 이름그대로)',
          '계좌번호(- 없이 입력)',
          '예금주(*필수)',
          '권한(*필수 회원 or 파트너)',
          '상위 유저 ID(*필수)',
          '메모',
        ], // 두 번째 행 (설명)
        ['u_id', 'u_name', 'n_name', 'u_pwd', 'u_pm', 'u_bd', 'b_n', 'a_num', 'a_her', 'u_ay', 't_uid', 'memo'], // 첫 번째 행 (헤더)
      ]

      // 워크시트 생성
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData)

      // 열 너비 자동 조정
      const columnWidths = sheetData[0].map(col => ({ wch: col.length + 5 })) // 셀 길이에 5를 더한 값으로 열 너비 설정
      worksheet['!cols'] = columnWidths

      // 워크북 생성
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, '유저 등록양식')

      // 파일 저장
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const file = new Blob([excelBuffer], { type: 'application/octet-stream' })
      saveAs(file, '유저등록_DB양식.xlsx')

      setDownloadLoading(false)
    }
  }

  const onSubmit = e => {
    if (registrationLoading) return

    if (!selectedFile) {
      alert('엑셀 파일을 선택해주세요.')
      return
    }

    if (window.confirm('유저를 등록 하시겠습니까?')) {
      setRegistrationLoading(true)

      const fileReader = new FileReader()
      fileReader.onload = event => {
        const arrayBuffer = event.target.result
        const workbook = XLSX.read(arrayBuffer, { type: 'array' })

        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) // 2D 배열 형태로 가져옴

        // 첫 번째 행 제거
        const filteredData = jsonData.slice(1)

        if (filteredData.length === 0) {
          alert('유효한 데이터가 없습니다.')
          setRegistrationLoading(false)
          return
        }

        // 새로운 워크북 생성 및 데이터 작성
        const newWorksheet = XLSX.utils.aoa_to_sheet(filteredData) // AOA 배열로 워크시트 생성
        const newWorkbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'FilteredData')

        // 워크북을 Blob으로 변환
        const excelBuffer = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' })
        const file = new Blob([excelBuffer], { type: 'application/octet-stream' })

        // FormData 생성 및 파일 추가
        const formData = new FormData()
        formData.append('file', new File([file], 'filtered_data.xlsx'))

        postUserRegistrationExcel(formData)
          .then(res => {
            setRegistrationLoading(false)
            alert('유저 등록이 성공적으로 완료되었습니다.')
            window.location.reload()
          })
          .catch(error => {
            setRegistrationLoading(false)
            const { errorCode, message } = error.response?.data || {}
            if (errorCode === 'EXCEL-1001') {
              alert(`${message}`)
            } else if (errorCode === 'EXCEL-1002') {
              alert(`${message}`)
            } else {
              alert('유저 등록이 실패했습니다.')
            }
          })
      }

      fileReader.readAsArrayBuffer(selectedFile)
    }
  }

  return (
    <>
      {downloadLoading || registrationLoading ? (
        <Loading />
      ) : (
        <>
          <Card>
            <CardBody>
              <MemberManageMentTabs activeKey="4" />
              <MiniGameTypeSelectWrapper>
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active
                  onClick={() => {
                    history.push('/user/management/registration')
                  }}
                >
                  유저 등록(개별)
                </Button>
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    history.push('/user/management/registration-excel')
                  }}
                >
                  유저 등록(엑셀)
                </Button>
              </MiniGameTypeSelectWrapper>
              <Col md={12} lg={6} xl={6}>
                <SearchCardTitleWrap>
                  <div style={{ marginTop: '20px', marginBottom: '10px' }}>
                    <p>
                      * 반드시 <span style={{ color: 'blue' }}>양식을 다운로드</span>후 파일을 넣어주시면 됩니다.
                    </p>
                    <p>
                      * <span style={{ color: 'red' }}>상위유저ID</span>가 있는 경우, 먼저 상위유저를 등록해주시면
                      됩니다.
                    </p>
                  </div>
                </SearchCardTitleWrap>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  style={{ background: 'green', color: 'white' }}
                  onClick={e => {
                    downloadMemberListHandler()
                  }}
                >
                  유저등록 DB양식 다운
                </Button>
                <Form onSubmit={onSubmit}>
                  {({ handleSubmit }) => (
                    <FormContainer horizontal onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormGroupLabel>EXCEL 파일첨부(XLSX)</FormGroupLabel>
                        <FormGroupField>
                          <Field name="userRegistrationExcel">
                            {({ input, meta }) => (
                              <FormInputWrap>
                                <input
                                  type="file"
                                  accept=".xlsx" // XLSX 파일만 허용
                                  style={{ border: 'solid 1px black' }}
                                  onChange={e => {
                                    const file = e.target.files[0]

                                    if (!file) {
                                      alert('선택된 파일이 없습니다.')
                                      setSelectedFile(null)
                                      return
                                    }

                                    const fileExtension = file.name.split('.').pop().toLowerCase()
                                    if (fileExtension !== 'xlsx') {
                                      alert('XLSX 파일만 업로드 가능합니다.')
                                      setSelectedFile(null)
                                      return
                                    }

                                    setSelectedFile(file) // 파일 상태 설정
                                  }}
                                />
                              </FormInputWrap>
                            )}
                          </Field>
                        </FormGroupField>
                      </FormGroup>

                      <CustomFormButtonToolbar>
                        {authority === 'SUPER' && (
                          <Button variant="primary" type="submit">
                            등록하기
                          </Button>
                        )}
                      </CustomFormButtonToolbar>
                    </FormContainer>
                  )}
                </Form>
              </Col>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default ExcelUserRegistrationPage

const MiniGameTypeSelectWrapper = styled.div`
  padding: 10px 0;
  margin-bottom: -35px;

  display: flex;
  gap: 5px;
`
