import { Button } from '@/shared/components/Button'
import { Card, CardBody, MyInfoCardSubhead, MyInfoCardTitle, MyInfoCardTitleWrap } from '@/shared/components/Card'
import {
  FormButtonToolbar,
  FormContainer,
  FormFieldButton,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import EyeIcon from 'mdi-react/EyeIcon'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import { searchUserInfo, updateUserInfo } from '../../utils/api/authManage/authManageApi'
import { decodeAccessToken } from '../../utils/token'

function MyInfo() {
  const authority = decodeAccessToken()?.adminAuthority
  const [passwordShown, setPasswordShown] = useState(false)

  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState(null)
  const [userName, setUserName] = useState('')
  const [recommendedCode, setRecommendedCode] = useState(null)

  const [isProcess, setIsProcess] = useState(false)

  useEffect(() => {
    searchUserInfo()
      .then(res => {
        setUserId(res.data.userId)
        setUserName(res.data.userName)
        setRecommendedCode(res.data.recommendedCode)
      })
      .catch(error => {})
  }, [])

  const onSubmit = e => {
    if (isProcess) return

    setIsProcess(true)

    const body = {
      newPassword: password,
    }
    updateUserInfo(body)
      .then(res => {
        alert('정보 수정 완료')
        setIsProcess(false)
        window.location.reload()
      })
      .catch(error => {
        setIsProcess(false)
        alert('정보 수정에 실패했습니다. 잠시후 다시 시도해주세요.')
      })
  }

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col md={7}>
          <Card>
            <CardBody>
              <MyInfoCardTitleWrap>
                <MyInfoCardTitle>나의 정보</MyInfoCardTitle>
                <MyInfoCardSubhead>* 정보를 수정해 주세요.</MyInfoCardSubhead>
              </MyInfoCardTitleWrap>
              <Form
                onSubmit={onSubmit}
                initialValues={{
                  userId,
                  userName,
                  recommendedCode,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <FormContainer horizontal onSubmit={handleSubmit}>
                    <FormGroup>
                      <FormGroupLabel>아이디</FormGroupLabel>
                      <FormGroupField>
                        <Field name="userId">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={input.value}
                                onChange={e => {
                                  input.onChange(e)
                                }}
                                disabled
                              />
                            </FormInputWrap>
                          )}
                        </Field>
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>비밀번호 (변경시 입력)</FormGroupLabel>
                      <FormGroupField>
                        <Field name="password">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type={passwordShown ? 'text' : 'password'}
                                  inputMode="latin"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                    setPassword(e.target.value)
                                  }}
                                  placeholder="변경시 비밀번호를 입력하세요."
                                />
                              </FormInputWrap>
                              <FormFieldButton
                                active={passwordShown}
                                type="button"
                                onClick={() => setPasswordShown(shown => !shown)}
                              >
                                <EyeIcon />
                              </FormFieldButton>
                            </>
                          )}
                        </Field>
                      </FormGroupField>
                    </FormGroup>
                    <FormGroup>
                      <FormGroupLabel>이름</FormGroupLabel>
                      <FormGroupField>
                        <Field name="userName">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={input.value}
                                onChange={e => {
                                  input.onChange(e)
                                }}
                                disabled
                              />
                            </FormInputWrap>
                          )}
                        </Field>
                      </FormGroupField>
                    </FormGroup>
                    {
                      {
                        SUPER: (
                          <FormButtonToolbar>
                            <Button variant="primary" type="submit">
                              수정하기
                            </Button>
                          </FormButtonToolbar>
                        ),
                      }[authority]
                    }
                  </FormContainer>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default MyInfo
