import { createSlice } from '@reduxjs/toolkit'

const BookMakerInfoSlice = createSlice({
  name: 'bookMakerInfo',
  initialState: {
    bookMakerMenu: [],
    activeMarketBookmakerMenu: -1,
  },
  reducers: {
    setBookMakerMenu: (state, action) => {
      const bookMakerList = action.payload

      const firstBookmaker = bookMakerList[0] || []

      state.bookMakerMenu = bookMakerList.map(item => {
        return {
          bookmakerId: item.bookmakerId,
          bookmakerName: item.bookmakerName,
        }
      })

      state.activeMarketBookmakerMenu = firstBookmaker?.bookmakerId || -1
    },

    setActiveMarketBookmakerMenu: (state, action) => {
      state.activeMarketBookmakerMenu = action.payload
    },
  },
  extraReducers: builder => {},
})

export const { setBookMakerMenu, setActiveMarketBookmakerMenu } = BookMakerInfoSlice.actions

export default BookMakerInfoSlice.reducer
