import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  CustomStyledModal,
} from '@/shared/components/CustomModal'
import Tooltip from '@/shared/components/Tooltip'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { LuArrowUpDown } from 'react-icons/lu'
import styled from 'styled-components'
import { CustomBtnTableWrap } from '../../../../shared/components/TableElements'
import MemberMoneyIncDecList from './MemberMoneyIncDecList'

function MemberMoneyIncDecMini({ memberId }) {
  const [isMemberMoneyIncDecModalShow, setMemberMoneyIncDecModalShow] = useState(false)

  const onClickHandler = () => {
    setMemberMoneyIncDecModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <NoteButton role={Button} onClick={() => onClickHandler()}>
        <Tooltip text="증감" place="bottom">
          <span>
            <LuArrowUpDown style={{ color: 'blue' }} />
          </span>
        </Tooltip>
      </NoteButton>

      <CustomStyledModal show={isMemberMoneyIncDecModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>자산증감</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <MemberMoneyIncDecList memberId={memberId} />
        </CustomModalBody>
      </CustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default MemberMoneyIncDecMini

MemberMoneyIncDecMini.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const NoteButton = styled.span`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  span {
    svg {
      display: inline-block !important;
    }
    img {
      display: inline-block !important;
    }
  }

  &:hover {
    cursor: pointer;
  }
`
