import React from 'react'
import styled from 'styled-components'
import { DepoWithdrawTypeEnums, MoneyIncDecAuthorityEnums } from '../../../enums/Log/moneyIncDecEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { colorLightText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'

const MoneyDepositWithdrawalInfoData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '유저ID',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'black', fontWeight: '900' }}>{`${original.memberId}(${original.memberName})`}</span>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {MoneyIncDecAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '종류',
      accessor: 'moneyLogType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.moneyLogType) {
          case 'DEPOSIT':
            return (
              <span style={{ color: 'blue', fontWeight: '900' }}>{DepoWithdrawTypeEnums[original.moneyLogType]}</span>
            )
          case 'WITHDRAWAL':
            return (
              <span style={{ color: 'red', fontWeight: '900' }}>{DepoWithdrawTypeEnums[original.moneyLogType]}</span>
            )
          default:
            return (
              <span style={{ color: 'black', fontWeight: '900' }}>{DepoWithdrawTypeEnums[original.moneyLogType]}</span>
            )
        }
      },
    },
    {
      Header: '입출금액',
      accessor: 'changeAmountlog',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.changeAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '처리일',
      accessor: 'executeDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstDateTime(original.executeDate)
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MoneyDepositWithdrawalInfoData

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'green'
    default:
      return colorLightText
  }
}

const BettingResultWrap = styled.div`
  width: 100%;
  font-weight: 700;
  color: ${props => getColor(props.bettingResult)};
`
