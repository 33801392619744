import { ButtonToolbar, Button } from '@/shared/components/Button'
import { flexFlow, left, paddingLeft, paddingRight, right } from '@/utils/directions'
import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
} from '@/utils/palette'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import styled from 'styled-components'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { Field, Form } from 'react-final-form'
import Error from '@/shared/components/form/Error'
import { SportTypeEnums } from '../../../enums/SystemManagement/sportEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import { processManualSportsResultForManual } from '../../../utils/api/sportsManage/manualSportRegister'
import Loading from '../../../shared/components/Loading'

const ManualSportsResultTeamEnums = {
  HOME_WIN: '홈팀승/언더',
  AWAY_WIN: '원정승/오버',
  DRAW: '적특',
}

const ManualSportsResultTeamEnumsRadioOptions = () => {
  return Object.entries(ManualSportsResultTeamEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'manualSportsResultTeam',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'manualSportsResultTeam',
          label: value,
          radioValue: key,
        },
  )
}

function ManualSportsResultModal({ isManualSportsResultModalShow, onCloseManualSportsResultModal, manualSportsInfo }) {
  const authority = decodeAccessToken()?.adminAuthority

  const [manualSportsResultTeam, setManualSportsResultTeam] = useState('HOME_WIN')

  const onChangeManualSportsResultTeamHandler = type => {
    setManualSportsResultTeam(type)
  }

  const [apiLoading, setApiLoading] = useState(false)

  const onSubmit = e => {
    if (apiLoading) return

    setApiLoading(true)

    const body = {
      manualSportsEventKey: manualSportsInfo.manualSportsEventKey,
      sportsType: manualSportsInfo.sportsType,
      sportsEventId: manualSportsInfo.sportsEventId,
      marketId: manualSportsInfo.marketId,
      leagueName: manualSportsInfo.leagueName,
      homeName: manualSportsInfo.homeName,
      awayName: manualSportsInfo.awayName,
      resultTeam: manualSportsResultTeam,
    }

    processManualSportsResultForManual(body)
      .then(res => {
        setApiLoading(false)
        onCloseManualSportsResultModal()
      })
      .catch(error => {
        alert('결과 처리에 실패했습니다.')
        setApiLoading(false)
      })
  }

  return (
    <>
      {apiLoading && <Loading />}
      <StyledModal show={isManualSportsResultModalShow} onHide={onCloseManualSportsResultModal} header>
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onCloseManualSportsResultModal}
          />
          <ModalTitle>경기 결과처리</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              manualSportsResultTeam,
              manualSportsInfo,
            }}
          >
            {({ handleSubmit, form: { reset } }) => (
              <FormContainer horizontal onSubmit={handleSubmit}>
                <div style={{ marginBottom: '30px' }}>
                  <p style={{ color: 'red' }}>* 경기 결과 처리하면 이후 수정은 불가합니다.</p>
                </div>
                <FormGroup>
                  <FormGroupLabel>국가</FormGroupLabel>
                  <FormGroupField>
                    <Field name="ccKr">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={manualSportsInfo.ccKr}
                            disabled
                            style={{ border: '1px solid black' }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>리그</FormGroupLabel>
                  <FormGroupField>
                    <Field name="leagueName">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={manualSportsInfo.leagueName}
                            disabled
                            style={{ border: '1px solid black' }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>홈팀</FormGroupLabel>
                  <FormGroupField>
                    <Field name="homeName">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={manualSportsInfo.homeName}
                            disabled
                            style={{ border: '1px solid black' }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>원정팀</FormGroupLabel>
                  <FormGroupField>
                    <Field name="awayName">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={manualSportsInfo.awayName}
                            disabled
                            style={{ border: '1px solid black' }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>종목</FormGroupLabel>
                  <FormGroupField>
                    <Field name="sportsType">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={SportTypeEnums[manualSportsInfo.sportsType]}
                            disabled
                            style={{ border: '1px solid black' }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>마켓</FormGroupLabel>
                  <FormGroupField>
                    <Field name="marketName">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={manualSportsInfo.marketName}
                            disabled
                            style={{ border: '1px solid black' }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>경기시간</FormGroupLabel>
                  <FormGroupField>
                    <Field name="startAt">
                      {({ input, meta }) => (
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={convertToKstDateTime(manualSportsInfo.startAt)}
                            disabled
                            style={{ border: '1px solid black' }}
                          />
                        </FormInputWrap>
                      )}
                    </Field>
                  </FormGroupField>
                </FormGroup>
                <FormGroup>
                  <FormGroupLabel>결과 선택</FormGroupLabel>
                  <FormGroupField>
                    {ManualSportsResultTeamEnumsRadioOptions().map(item => (
                      <FormGroupField
                        key={`index_${item.label}`}
                        style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                      >
                        <Field
                          name={`${item.name}`}
                          component={renderRadioButtonField}
                          label={item.label}
                          radioValue={item.radioValue}
                          initialValue={manualSportsResultTeam}
                          onCustomChange={e => {
                            onChangeManualSportsResultTeamHandler(e)
                          }}
                        />
                      </FormGroupField>
                    ))}
                  </FormGroupField>
                </FormGroup>
                <FormButtonToolbar style={{ marginLeft: '-10px' }}>
                  <>
                    {authority === 'SUPER' && (
                      <Button variant="primary" type="submit">
                        결과 처리
                      </Button>
                    )}
                  </>
                </FormButtonToolbar>
              </FormContainer>
            )}
          </Form>
        </ModalBody>
      </StyledModal>
    </>
  )
}

export default ManualSportsResultModal

ManualSportsResultModal.propTypes = {
  isManualSportsResultModalShow: PropTypes.bool,
  onCloseManualSportsResultModal: PropTypes.func,
  manualSportsInfo: PropTypes.shape(),
}

ManualSportsResultModal.defaultProps = {
  isManualSportsResultModalShow: false,
  onCloseManualSportsResultModal: () => {},
  manualSportsInfo: {},
}

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }

  flex-flow: ${flexFlow} !important;
`

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`

const ModalHeader = styled.div``
const ModalBody = styled.div``

const getColor = color => {
  switch (color) {
    case 'primary':
      return colorBlue
    case 'success':
      return colorAccent
    case 'warning':
      return colorYellow
    case 'danger':
      return colorRed

    default:
      return colorText
  }
}

const StyledModal = styled(BootstrapModal)`
  .modal-dialog {
    max-width: 1000px !important;
  }

  .modal-content {
    height: max-content;
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }

  ${ModalTitleIcon} {
    color: ${colorBlue};
  }

  ${props =>
    props.colored &&
    `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${colorBlue};
    }
  `}

  ${props =>
    props.header &&
    `
    
    .modal-dialog {
      max-width: 430px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${colorBlue};
    }
  `}
`

// endregion
