import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaStar } from 'react-icons/fa'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import styled from 'styled-components'
import { SportIdTypeEnums } from '../../../enums/SystemManagement/sportEnums'
import Loading from '../../../shared/components/Loading'
import {
  searchSportLeagueList,
  updateSportLeagueImportant,
  updateSportLeagueMultipleStatus,
  updateSportLeagueStatus,
  updateSportLeagueStatusAll,
} from '../../../utils/api/sport/sportLeagueApi'
import { decodeAccessToken } from '../../../utils/token'
import LeagueModifyModal from './LeagueModifyModal'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'

const LeaguePage = ({ locationId, locationNameKo, setLeagueId, setLeagueNameKo, setLeagueSportId }) => {
  const [totalElement, setTotalElement] = useState(0)
  const [leagueList, setLeagueList] = useState([])
  const [tempLeagueList, setTempLeagueList] = useState([])

  const authority = decodeAccessToken()?.adminAuthority
  const [leagueInfo, setLeagueInfo] = useState({})
  const [leagueModifyModalShow, setLeagueModifyModalShow] = useState(false)
  const onCloseLeagueModifyModalShowHandler = () => {
    setLeagueModifyModalShow(false)
  }

  const VisibleTypeEnums = {
    ALL: '전체',
    VISIBLE: '노출리그만',
    NONVISIBLE: '숨김리그만',
  }

  const visibilityValues = {
    VISIBLE: true,
    NONVISIBLE: false,
    ALL: null,
  }

  // 팀명
  const [searchLeagueName, setSearchLeagueName] = useState('')
  const [selectedVisibility, setSelectedVisibility] = useState('ALL')
  const [selectedSportId, setSelectedSportId] = useState(6046)

  const onSearchLeagueNameHandler = e => {
    setSearchLeagueName(e.target.value)
  }

  const onVisibilityChange = e => {
    setSelectedVisibility(e.target.value)
  }

  const onSportIdChange = e => {
    setSelectedSportId(Number(e.target.value))
    setLeagueSportId(Number(e.target.value))
  }

  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    locationId,
    leagueName: null,
    visible: null,
    sportId: 6046,
    page,
    size: 100,
  })

  const onSearchClickHandler = () => {
    const visible = visibilityValues[selectedVisibility]

    setPage(0)

    setParams(prev => ({
      ...prev,
      leagueName: searchLeagueName,
      visible,
      sportId: selectedSportId,
      page: 0,
    }))
  }

  const [loading, setLoading] = useState(false)

  const fetchSearchSportLeagueList = () => {
    if (loading) return
    setLoading(true)
    searchSportLeagueList(params).then(res => {
      setTotalElement(res.data.totalElement)
      setLeagueList(res.data.leagueList)
      setTempLeagueList(res.data.leagueList) // Initial copy for temporary changes

      setLoading(false)
    })
  }

  useEffect(() => {
    if (!locationId) return

    setPage(0)

    setParams(prev => ({
      ...prev,
      locationId,
      page: 0,
    }))
    // setSearchLeagueName(locationId)
  }, [locationId])

  useEffect(() => {
    if (!params.locationId) return
    fetchSearchSportLeagueList()
  }, [params])

  const onCheckboxChange = (leagueId, visibleType, value) => {
    setTempLeagueList(prevState =>
      prevState.map(league => (league.leagueId === leagueId ? { ...league, [visibleType]: value } : league)),
    )
  }

  const [changeLoading, setChangeLoading] = useState(false)
  const findLeagueById = leagueId => {
    return leagueList.find(league => league.leagueId === leagueId)
  }

  const applyChanges = () => {
    if (changeLoading) return
    setChangeLoading(true)

    const changedLeagues = tempLeagueList
      .filter(league => {
        const original = findLeagueById(league.leagueId)
        return (
          league.domesticVisible !== original.domesticVisible ||
          league.overseasVisible !== original.overseasVisible ||
          league.specialVisible !== original.specialVisible ||
          league.liveVisible !== original.liveVisible ||
          league.importantMark !== original.importantMark
        )
      })
      .map(league => {
        const original = findLeagueById(league.leagueId)
        return {
          leagueId: league.leagueId,
          domesticVisible: league.domesticVisible,
          overseasVisible: league.overseasVisible,
          specialVisible: league.specialVisible,
          liveVisible: league.liveVisible,
          importantMark: league.importantMark,
        }
      })

    if (changedLeagues.length === 0) {
      alert('변경된 항목이 없습니다.')
      setChangeLoading(false)
      return
    }

    const body = {
      locationId,
      sportId: selectedSportId,
      leagueChanges: changedLeagues,
    }

    setChangeLoading(true)
    updateSportLeagueMultipleStatus(body)
      .then(res => {
        alert('변경이 완료됐습니다.')
        setChangeLoading(false)
        fetchSearchSportLeagueList()
      })
      .catch(error => {
        alert('변경이 실패하였습니다.')
        setChangeLoading(false)
        fetchSearchSportLeagueList()
      })
  }

  const onEventHideAndShowHandlerAll = visible => {
    if (authority === 'NORMAL') return
    if (!locationId) {
      alert('국가를 먼저 선택하세요.')
      return
    }
    if (changeLoading) return

    let confirmationMessage

    if (visible) {
      confirmationMessage = `${SportIdTypeEnums[selectedSportId]} - ${locationNameKo}의 모든 리그를 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${SportIdTypeEnums[selectedSportId]} - ${locationNameKo}의 모든 리그를 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        locationId,
        sportId: selectedSportId,
        visible,
        isAll: true,
        // 아래 2개 가라데이터
        whatVisible: 'domestic',
        leagueId: 1,
      }
      setChangeLoading(true)
      updateSportLeagueStatus(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
    }
  }

  const onEventHideAndShowHandler = (leagueId, leagueNameKo, visible, whatVisible) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return
    let typeDescription = ''
    switch (whatVisible) {
      case 'domesticVisible':
        typeDescription = '국내형'
        break
      case 'overseasVisible':
        typeDescription = '해외형'
        break
      case 'specialVisible':
        typeDescription = '스페셜'
        break
      case 'liveVisible':
        typeDescription = '라이브'
        break
      default:
        typeDescription = '' // Default case if needed
    }

    let confirmationMessage

    if (visible) {
      confirmationMessage = `${typeDescription}에서 ${leagueNameKo} 리그를 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${typeDescription}에서 ${leagueNameKo} 리그를 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        leagueId,
        visible,
        whatVisible,
      }
      setChangeLoading(true)
      updateSportLeagueStatus(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
    }
  }

  const onImportantHandler = (leagueId, leagueNameKo, importantMark) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return

    let confirmationMessage

    if (importantMark) {
      confirmationMessage = `${leagueNameKo} 인기리그로 하시겠습니까?`
    } else {
      confirmationMessage = `${leagueNameKo} 비인기리그로 하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        leagueId,
        importantMark,
      }
      setChangeLoading(true)
      updateSportLeagueImportant(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
    }
  }

  const onAllHideAndShowHandler = (leagueId, leagueNameKo, visible) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return

    let confirmationMessage

    if (visible) {
      confirmationMessage = ` ${leagueNameKo} 리그를 전부 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${leagueNameKo} 리그를 전부 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        leagueId,
        visible,
      }
      setChangeLoading(true)
      updateSportLeagueStatusAll(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportLeagueList()
        })
    }
  }

  const onCheckboxAllChange = (leagueId, value) => {
    setTempLeagueList(prevState =>
      prevState.map(league =>
        league.leagueId === leagueId
          ? {
              ...league,
              domesticVisible: value,
              overseasVisible: value,
              specialVisible: value,
              liveVisible: value,
            }
          : league,
      ),
    )
  }

  const onImportantMarkChange = (leagueId, value) => {
    setTempLeagueList(prevState =>
      prevState.map(league => (league.leagueId === leagueId ? { ...league, importantMark: value } : league)),
    )
  }

  const tableWrapRef = useRef(null)

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 100))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (tableWrapRef.current) {
      tableWrapRef.current.scrollTop = 0
    }

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))
  }

  return (
    <>
      {(loading || changeLoading) && <Loading />}
      <LocationPageBox>
        <HeaderBox>
          리그 관리
          <div style={{ display: 'flex', marginLeft: '30px', marginTop: '2px' }}>
            <div style={{ fontSize: '15px', fontWeight: '300', marginRight: '3px' }}>
              <input
                name="searchTeamName"
                type="text"
                placeholder="리그명/코드"
                value={searchLeagueName}
                onChange={e => onSearchLeagueNameHandler(e)}
              />
            </div>
            <select onChange={onSportIdChange} value={selectedSportId} style={{ fontSize: '13px' }}>
              {Object.entries(SportIdTypeEnums).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>
            <select onChange={onVisibilityChange} value={selectedVisibility} style={{ fontSize: '13px' }}>
              {Object.entries(VisibleTypeEnums).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>

            <CustomButton
              style={{ marginLeft: '15px', background: '#dedede', color: '#000' }}
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색
            </CustomButton>
            <button
              type="button"
              style={{
                width: 'max-content',
                background: 'red',
                border: 'none',
                color: '#fff',
                fontSize: '12px',
                marginLeft: '10px',
              }}
              onClick={() => {
                onEventHideAndShowHandlerAll(false)
              }}
            >
              리그 전체숨김
            </button>
            <button
              type="button"
              style={{
                width: 'max-content',
                background: 'blue',
                border: 'none',
                color: '#fff',
                fontSize: '12px',
                marginLeft: '5px',
              }}
              onClick={() => {
                onEventHideAndShowHandlerAll(true)
              }}
            >
              리그 전체노출
            </button>
            <button
              type="button"
              style={{
                // width: 'max-content',
                background: '#2196f3',
                border: 'none',
                color: '#fff',
                fontSize: '12px',
                marginLeft: '5px',
                position: 'absolute',
                right: '30px',
                width: '77px',
                height: '30px',
              }}
              onClick={() => {
                applyChanges()
              }}
            >
              일괄수정
            </button>
          </div>
        </HeaderBox>
        <TableWrap ref={tableWrapRef}>
          <table>
            <thead>
              <tr style={{ borderTop: '1px solid black' }}>
                <th>종목</th>
                <th>국가명</th>
                <th>리그 코드</th>
                <th>리그명</th>
                <th>
                  노출 여부
                  <br />
                  <span style={{ fontSize: '11px' }}>(국내형,해외형,스페셜,라이브)</span>
                </th>
                <th>인기리그 여부</th>
                <th>정렬 순서</th>
                <th>-</th>
              </tr>
            </thead>
            {tempLeagueList?.length !== 0 && (
              <tbody>
                {tempLeagueList?.map(league => {
                  return (
                    <tr>
                      <td>
                        <img
                          style={{ width: '20px', height: '20px', marginRight: '5px' }}
                          src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${league.sportId}.png`}
                          alt={SportIdTypeEnums[league.sportId]}
                        />
                        {league.sportName}
                      </td>
                      <td>
                        {league.locationImageUrl && (
                          <img
                            style={{ width: '30px', height: '25px', marginRight: '5px' }}
                            src={league.locationImageUrl}
                            alt=""
                          />
                        )}
                        {league.locationNameKo || '-'}
                      </td>
                      <td>{league.leagueId}</td>
                      <td style={{ background: 'rgb(251, 247, 234)' }}>
                        {league.leagueImageUrl && (
                          <img
                            style={{ width: '30px', height: '25px', marginRight: '5px' }}
                            src={league.leagueImageUrl}
                            alt=""
                          />
                        )}

                        {league.leagueNameKo || '-'}
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <label
                              htmlFor={`${league.leagueId}isAll`}
                              style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                            >
                              <input
                                type="checkbox"
                                id={`${league.leagueId}isAll`}
                                style={{ fontSize: '14px' }}
                                checked={
                                  league.domesticVisible ||
                                  league.overseasVisible ||
                                  league.specialVisible ||
                                  league.liveVisible
                                }
                                onChange={e => onCheckboxAllChange(league.leagueId, e.target.checked)}
                              />
                              전체
                            </label>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', gap: '5px', height: '20px', justifyContent: 'center' }}>
                              <label
                                htmlFor={`${league.leagueId}domesticVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${league.leagueId}domesticVisible`}
                                  checked={league.domesticVisible}
                                  onChange={e => onCheckboxChange(league.leagueId, 'domesticVisible', e.target.checked)}
                                />
                                국내형
                              </label>
                              <label
                                htmlFor={`${league.leagueId}overseasVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${league.leagueId}overseasVisible`}
                                  checked={league.overseasVisible}
                                  onChange={e => onCheckboxChange(league.leagueId, 'overseasVisible', e.target.checked)}
                                />
                                해외형
                              </label>
                            </div>
                            <div style={{ display: 'flex', gap: '5px', height: '20px', justifyContent: 'center' }}>
                              <label
                                htmlFor={`${league.leagueId}specialVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${league.leagueId}specialVisible`}
                                  checked={league.specialVisible}
                                  onChange={e => onCheckboxChange(league.leagueId, 'specialVisible', e.target.checked)}
                                />
                                스페셜
                              </label>
                              <label
                                htmlFor={`${league.leagueId}liveVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${league.leagueId}liveVisible`}
                                  checked={league.liveVisible}
                                  onChange={e => onCheckboxChange(league.leagueId, 'liveVisible', e.target.checked)}
                                />
                                라이브
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>
                      {/* <td>
                        <div style={{ display: 'flex', gap: '5px' }}>
                          <div style={{ display: 'flex', gap: '7px', flexDirection: 'column' }}>
                            <FiEyeOff
                              style={{ color: 'red', border: 'none', cursor: 'pointer' }}
                              onClick={() => {
                                onAllHideAndShowHandler(league.leagueId, league.leagueNameKo, false)
                              }}
                            />
                            <FiEye
                              style={{ color: 'blue', border: 'none', cursor: 'pointer' }}
                              onClick={() => {
                                onAllHideAndShowHandler(league.leagueId, league.leagueNameKo, true)
                              }}
                            />
                          </div>
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              gap: '7px',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {
                              {
                                true: (
                                  <FiEye
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        false,
                                        'domesticVisible',
                                      )
                                    }}
                                  />
                                ),
                                false: (
                                  <FiEyeOff
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        true,
                                        'domesticVisible',
                                      )
                                    }}
                                  />
                                ),
                              }[league.domesticVisible]
                            }
                            {
                              {
                                true: (
                                  <FiEye
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        false,
                                        'overseasVisible',
                                      )
                                    }}
                                  />
                                ),
                                false: (
                                  <FiEyeOff
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        true,
                                        'overseasVisible',
                                      )
                                    }}
                                  />
                                ),
                              }[league.overseasVisible]
                            }
                            {
                              {
                                true: (
                                  <FiEye
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        false,
                                        'specialVisible',
                                      )
                                    }}
                                  />
                                ),
                                false: (
                                  <FiEyeOff
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        true,
                                        'specialVisible',
                                      )
                                    }}
                                  />
                                ),
                              }[league.specialVisible]
                            }
                            {
                              {
                                true: (
                                  <FiEye
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        false,
                                        'liveVisible',
                                      )
                                    }}
                                  />
                                ),
                                false: (
                                  <FiEyeOff
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      onEventHideAndShowHandler(
                                        league.leagueId,
                                        league.leagueNameKo,
                                        true,
                                        'liveVisible',
                                      )
                                    }}
                                  />
                                ),
                              }[league.liveVisible]
                            }
                          </div>
                        </div>
                      </td> */}
                      <td>
                        {league.importantMark ? (
                          <FaStar
                            style={{ cursor: 'pointer', color: 'orange' }}
                            onClick={() => onImportantMarkChange(league.leagueId, false)}
                          />
                        ) : (
                          <FaStar
                            style={{ cursor: 'pointer' }}
                            onClick={() => onImportantMarkChange(league.leagueId, true)}
                          />
                        )}
                      </td>
                      <td>{league.leagueSortNum}</td>
                      <td>
                        <CustomButton
                          variant="outline-secondary"
                          style={{ marginRight: '5px', background: '#2196f3', color: '#fff' }}
                          onClick={() => {
                            setLeagueInfo(league)
                            setLeagueModifyModalShow(true)
                          }}
                        >
                          수정
                        </CustomButton>
                        <CustomButton
                          variant="outline-secondary"
                          style={{ marginRight: '5px', background: '#47ce3a', color: '#fff' }}
                          onClick={() => {
                            setLeagueId(league.leagueId)
                            setLeagueNameKo(league.leagueNameKo)
                          }}
                        >
                          팀정보
                        </CustomButton>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            )}
          </table>
        </TableWrap>
        {totalElement > 0 && (
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        )}
      </LocationPageBox>
      {leagueModifyModalShow && (
        <LeagueModifyModal
          leagueInfo={leagueInfo}
          leagueModifyModalShow={leagueModifyModalShow}
          onCloseLeagueModifyModalShowHandler={onCloseLeagueModifyModalShowHandler}
          fetchSearchSportLeagueList={fetchSearchSportLeagueList}
        />
      )}
    </>
  )
}

export default LeaguePage

LeaguePage.propTypes = {
  locationId: PropTypes.string.isRequired,
  locationNameKo: PropTypes.string.isRequired,
  setLeagueId: PropTypes.func.isRequired,
  setLeagueNameKo: PropTypes.func.isRequired,
  setLeagueSportId: PropTypes.func.isRequired,
}

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LocationPageBox = styled.div`
  width: 100%;
  padding: 5px;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 5px;
  border-top: 2px solid black;
  display: flex;
  position: relative;
  p {
    font-size: 11px;
    color: red;
  }
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  max-height: 520px;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
`

const CustomButton = styled(Button)`
  width: 50px;
  font-size: 12px;
  padding: 5px;
`
