import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import { FaStar } from 'react-icons/fa'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import styled from 'styled-components'
import { SportIdTypeEnums } from '../../../enums/SystemManagement/sportEnums'
import Loading from '../../../shared/components/Loading'
import {
  searchSportTeamList,
  updateSportTeamImportant,
  updateSportTeamStatus,
  updateSportTeamMultipleStatus,
  updateSportTeamStatusAll,
} from '../../../utils/api/sport/sportTeamApi'
import { decodeAccessToken } from '../../../utils/token'
import TeamModifyModal from './TeamModifyModal'
import CustomPagination from '../../../shared/components/customPagination/CustomPagination'

const TeamPage = ({ leagueId, leagueSportId, locationNameKo, leagueNameKo }) => {
  const [totalElement, setTotalElement] = useState(0)
  const [teamList, setTeamList] = useState([])
  const [tempTeamList, setTempTeamList] = useState([])
  const authority = decodeAccessToken()?.adminAuthority
  const [teamInfo, setTeamInfo] = useState({})
  const [teamModifyModalShow, setTeamModifyModalShow] = useState(false)
  const onCloseTeamModifyModalShowHandler = () => {
    setTeamModifyModalShow(false)
  }

  const VisibleTypeEnums = {
    ALL: '전체',
    VISIBLE: '노출팀만',
    NONVISIBLE: '숨김팀만',
  }

  const visibilityValues = {
    VISIBLE: true,
    NONVISIBLE: false,
    ALL: null,
  }

  // 팀명
  const [searchTeamName, setSearchTeamName] = useState('')
  const [selectedVisibility, setSelectedVisibility] = useState('ALL')
  const [selectedSportId, setSelectedSportId] = useState(null)

  const onSearchTeamNameHandler = e => {
    setSearchTeamName(e.target.value)
  }

  const onVisibilityChange = e => {
    setSelectedVisibility(e.target.value)
  }

  const onSportIdChange = e => {
    setSelectedSportId(e.target.value === 'default' ? null : Number(e.target.value))
  }

  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    leagueId,
    teamName: null,
    visible: null,
    page,
    size: 100,
  })

  const onSearchClickHandler = () => {
    const visible = visibilityValues[selectedVisibility]

    setPage(0)

    setParams(prev => ({
      ...prev,
      teamName: !searchTeamName ? null : searchTeamName.trim(),
      visible,
      page: 0,
    }))
  }

  const [loading, setLoading] = useState(false)

  const fetchSearchSportTeamList = () => {
    if (loading) return
    setLoading(true)
    searchSportTeamList(params).then(res => {
      setTotalElement(res.data.totalElement)
      setTeamList(res.data.teamList)
      setTempTeamList(res.data.teamList)
      setLoading(false)
    })
  }

  useEffect(() => {
    if (!leagueId) return

    setPage(0)

    setParams(prev => ({
      ...prev,
      leagueId,
      page: 0,
    }))
    // setSearchTeamName(leagueId)
  }, [leagueId])

  useEffect(() => {
    if (!params.leagueId) return
    fetchSearchSportTeamList()
  }, [params])

  const onCheckboxChange = (teamId, visibleType, value) => {
    setTempTeamList(prevState =>
      prevState.map(team => (team.teamId === teamId ? { ...team, [visibleType]: value } : team)),
    )
  }

  const [changeLoading, setChangeLoading] = useState(false)

  const findTeamById = teamId => {
    return teamList.find(team => team.teamId === teamId)
  }

  const applyChanges = () => {
    if (changeLoading) return
    setChangeLoading(true)

    const changedLeagues = tempTeamList
      .filter(team => {
        const original = findTeamById(team.teamId)
        return (
          team.domesticVisible !== original.domesticVisible ||
          team.overseasVisible !== original.overseasVisible ||
          team.specialVisible !== original.specialVisible ||
          team.liveVisible !== original.liveVisible ||
          team.importantMark !== original.importantMark
        )
      })
      .map(team => {
        const original = findTeamById(team.teamId)
        return {
          teamId: team.teamId,
          domesticVisible: team.domesticVisible,
          overseasVisible: team.overseasVisible,
          specialVisible: team.specialVisible,
          liveVisible: team.liveVisible,
          importantMark: team.importantMark,
        }
      })

    if (changedLeagues.length === 0) {
      alert('변경된 항목이 없습니다.')
      setChangeLoading(false)
      return
    }

    const body = {
      locationId: locationNameKo,
      sportId: leagueSportId,
      leagueId,
      teamChanges: changedLeagues,
    }

    setChangeLoading(true)
    updateSportTeamMultipleStatus(body)
      .then(res => {
        alert('변경이 완료됐습니다.')
        setChangeLoading(false)
        fetchSearchSportTeamList()
      })
      .catch(error => {
        alert('변경이 실패하였습니다.')
        setChangeLoading(false)
        fetchSearchSportTeamList()
      })
  }

  const onEventHideAndShowHandlerAll = visible => {
    if (authority === 'NORMAL') return
    if (!leagueId) {
      alert('리그를 먼저 선택하세요.')
      return
    }
    if (changeLoading) return

    let confirmationMessage

    if (visible) {
      confirmationMessage = `${SportIdTypeEnums[leagueSportId]} - ${locationNameKo} - ${leagueNameKo}의 모든 팀을 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${SportIdTypeEnums[leagueSportId]} - ${locationNameKo} - ${leagueNameKo}의 모든 팀을 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        leagueId,
        visible,
        isAll: true,
        // 아래 2개 가라데이터
        teamId: '1',
        whatVisible: 'domestic',
      }
      setChangeLoading(true)
      updateSportTeamStatus(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
    }
  }

  const onEventHideAndShowHandler = (teamId, teamNameKo, visible, whatVisible) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return
    let typeDescription = ''
    switch (whatVisible) {
      case 'domesticVisible':
        typeDescription = '국내형'
        break
      case 'overseasVisible':
        typeDescription = '해외형'
        break
      case 'specialVisible':
        typeDescription = '스페셜'
        break
      case 'liveVisible':
        typeDescription = '라이브'
        break
      default:
        typeDescription = '' // Default case if needed
    }

    let confirmationMessage

    if (visible) {
      confirmationMessage = `${typeDescription}에서 ${teamNameKo} 팀을 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${typeDescription}에서 ${teamNameKo} 팀을 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        teamId,
        visible,
        whatVisible,
      }
      setChangeLoading(true)
      updateSportTeamStatus(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
    }
  }

  const onImportantHandler = (teamId, teamNameKo, importantMark) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return
    let confirmationMessage

    if (importantMark) {
      confirmationMessage = `${teamNameKo} 인기팀으로 하시겠습니까?`
    } else {
      confirmationMessage = `${teamNameKo} 비인기팀으로 하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        teamId,
        importantMark,
      }
      setChangeLoading(true)
      updateSportTeamImportant(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
    }
  }

  const onAllHideAndShowHandler = (teamId, teamNameKo, visible) => {
    if (authority === 'NORMAL') return
    if (changeLoading) return

    let confirmationMessage

    if (visible) {
      confirmationMessage = ` ${teamNameKo} 팀을 전부 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${teamNameKo} 팀을 전부 비노출 처리하시겠습니까?`
    }
    if (window.confirm(confirmationMessage)) {
      const body = {
        teamId,
        visible,
      }
      setChangeLoading(true)
      updateSportTeamStatusAll(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setChangeLoading(false)
          fetchSearchSportTeamList()
        })
    }
  }

  const onCheckboxAllChange = (teamId, value) => {
    setTempTeamList(prevState =>
      prevState.map(team =>
        team.teamId === teamId
          ? {
              ...team,
              domesticVisible: value,
              overseasVisible: value,
              specialVisible: value,
              liveVisible: value,
            }
          : team,
      ),
    )
  }

  const onImportantMarkChange = (teamId, value) => {
    setTempTeamList(prevState =>
      prevState.map(team => (team.teamId === teamId ? { ...team, importantMark: value } : team)),
    )
  }

  const tableWrapRef = useRef(null)

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 100))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    if (tableWrapRef.current) {
      tableWrapRef.current.scrollTop = 0
    }

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))
  }

  return (
    <>
      {(loading || changeLoading) && <Loading />}
      <LocationPageBox>
        <HeaderBox>
          팀 관리
          <div style={{ display: 'flex', marginLeft: '30px', marginTop: '2px' }}>
            <div style={{ fontSize: '15px', fontWeight: '300', marginRight: '3px' }}>
              <input
                name="searchTeamName"
                type="text"
                placeholder="팀명/코드"
                value={searchTeamName}
                onChange={e => onSearchTeamNameHandler(e)}
              />
            </div>
            <select onChange={onVisibilityChange} value={selectedVisibility} style={{ fontSize: '13px' }}>
              {Object.entries(VisibleTypeEnums).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </select>

            <CustomButton
              style={{ marginLeft: '15px', background: '#dedede', color: '#000' }}
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색
            </CustomButton>
            <button
              type="button"
              style={{
                width: 'max-content',
                background: 'red',
                border: 'none',
                color: '#fff',
                fontSize: '12px',
                marginLeft: '10px',
              }}
              onClick={() => {
                onEventHideAndShowHandlerAll(false)
              }}
            >
              팀 전체숨김
            </button>
            <button
              type="button"
              style={{
                width: 'max-content',
                background: 'blue',
                border: 'none',
                color: '#fff',
                fontSize: '12px',
                marginLeft: '5px',
              }}
              onClick={() => {
                onEventHideAndShowHandlerAll(true)
              }}
            >
              팀 전체노출
            </button>
            <button
              type="button"
              style={{
                // width: 'max-content',
                background: '#2196f3',
                border: 'none',
                color: '#fff',
                fontSize: '12px',
                marginLeft: '5px',
                position: 'absolute',
                right: '30px',
                width: '77px',
                height: '30px',
              }}
              onClick={() => {
                applyChanges()
              }}
            >
              일괄수정
            </button>
          </div>
        </HeaderBox>
        <TableWrap ref={tableWrapRef}>
          <table>
            <thead>
              <tr style={{ borderTop: '1px solid black' }}>
                <th>종목</th>
                <th>국가명</th>
                <th>리그명</th>
                <th>팀 코드</th>
                <th>팀명</th>
                <th>
                  노출 여부
                  <br />
                  <span style={{ fontSize: '11px' }}>(국내,해외,스페셜,라이브)</span>
                </th>
                <th>인기팀 여부</th>
                <th>정렬 순서</th>
                <th>-</th>
              </tr>
            </thead>
            {tempTeamList?.length !== 0 && (
              <tbody>
                {tempTeamList?.map(team => {
                  return (
                    <tr>
                      <td>
                        <img
                          style={{ width: '20px', height: '20px', marginRight: '5px' }}
                          src={`${process.env.PUBLIC_URL}/img/sports/sportsType/${team.sportId}.png`}
                          alt={SportIdTypeEnums[team.sportId]}
                        />
                        {team.sportName}
                      </td>

                      <td>
                        {team.locationImageUrl && (
                          <img
                            style={{ width: '30px', height: '25px', marginRight: '5px' }}
                            src={team.locationImageUrl}
                            alt=""
                          />
                        )}
                        {team.locationNameKo || '-'}
                      </td>
                      <td>
                        {team.leagueImageUrl && (
                          <img
                            style={{ width: '30px', height: '25px', marginRight: '5px' }}
                            src={team.leagueImageUrl}
                            alt=""
                          />
                        )}
                        {team.leagueNameKo || '-'}
                      </td>
                      <td>{team.teamId}</td>
                      <td style={{ background: 'rgb(251, 247, 234)' }}>
                        {team.teamImageUrl && (
                          <img
                            style={{ width: '30px', height: '25px', marginRight: '5px' }}
                            src={team.teamImageUrl}
                            alt=""
                          />
                        )}
                        {team.teamNameKo || '-'}
                      </td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <label
                              htmlFor={`${team.teamId}isAll`}
                              style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                            >
                              <input
                                type="checkbox"
                                id={`${team.teamId}isAll`}
                                checked={
                                  team.domesticVisible ||
                                  team.overseasVisible ||
                                  team.specialVisible ||
                                  team.liveVisible
                                }
                                onChange={e => onCheckboxAllChange(team.teamId, e.target.checked)}
                              />
                              전체
                            </label>
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', gap: '5px', height: '20px', justifyContent: 'center' }}>
                              <label
                                htmlFor={`${team.teamId}domesticVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${team.teamId}domesticVisible`}
                                  checked={team.domesticVisible}
                                  onChange={e => onCheckboxChange(team.teamId, 'domesticVisible', e.target.checked)}
                                />
                                국내형
                              </label>
                              <label
                                htmlFor={`${team.teamId}overseasVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${team.teamId}overseasVisible`}
                                  checked={team.overseasVisible}
                                  onChange={e => onCheckboxChange(team.teamId, 'overseasVisible', e.target.checked)}
                                />
                                해외형
                              </label>
                            </div>
                            <div style={{ display: 'flex', gap: '5px', height: '20px', justifyContent: 'center' }}>
                              <label
                                htmlFor={`${team.teamId}specialVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${team.teamId}specialVisible`}
                                  checked={team.specialVisible}
                                  onChange={e => onCheckboxChange(team.teamId, 'specialVisible', e.target.checked)}
                                />
                                스페셜
                              </label>
                              <label
                                htmlFor={`${team.teamId}liveVisible`}
                                style={{ height: '10px', cursor: 'pointer', fontSize: '14px' }}
                              >
                                <input
                                  type="checkbox"
                                  id={`${team.teamId}liveVisible`}
                                  checked={team.liveVisible}
                                  onChange={e => onCheckboxChange(team.teamId, 'liveVisible', e.target.checked)}
                                />
                                라이브
                              </label>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        {team.importantMark ? (
                          <FaStar
                            style={{ cursor: 'pointer', color: 'orange' }}
                            onClick={() => onImportantMarkChange(team.teamId, false)}
                          />
                        ) : (
                          <FaStar
                            style={{ cursor: 'pointer' }}
                            onClick={() => onImportantMarkChange(team.teamId, true)}
                          />
                        )}
                      </td>
                      <td>{team.teamSortNum}</td>
                      <td>
                        <CustomButton
                          variant="outline-secondary"
                          style={{ marginRight: '5px', background: '#2196f3', color: '#fff' }}
                          onClick={() => {
                            setTeamInfo(team)
                            setTeamModifyModalShow(true)
                          }}
                        >
                          수정
                        </CustomButton>
                        {/* <CustomButton
                          variant="outline-secondary"
                          style={{ marginRight: '5px', background: '#47ce3a', color: '#fff' }}
                          onClick={() => {
                            setTeamInfo(team)
                            setTeamModifyModalShow(true)
                          }}
                        >
                          팀정보
                        </CustomButton> */}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            )}
          </table>
        </TableWrap>
        {totalElement > 0 && (
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        )}
      </LocationPageBox>
      {teamModifyModalShow && (
        <TeamModifyModal
          teamInfo={teamInfo}
          teamModifyModalShow={teamModifyModalShow}
          onCloseTeamModifyModalShowHandler={onCloseTeamModifyModalShowHandler}
          fetchSearchSportTeamList={fetchSearchSportTeamList}
        />
      )}
    </>
  )
}

export default TeamPage

TeamPage.propTypes = {
  leagueId: PropTypes.string.isRequired,
  leagueSportId: PropTypes.string.isRequired,
  locationNameKo: PropTypes.string.isRequired,
  leagueNameKo: PropTypes.string.isRequired,
}

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LocationPageBox = styled.div`
  width: 100%;
  padding: 5px;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 5px;
  border-top: 2px solid black;
  display: flex;
  position: relative;
  p {
    font-size: 11px;
    color: red;
  }
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;
  max-height: 520px;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  thead {
    border-collapse: collapse !important;
    position: sticky;
    top: 0;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
`

const CustomButton = styled(Button)`
  width: 50px;
  font-size: 12px;
  padding: 5px;
`
