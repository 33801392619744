export const BettingSorteTypeEnums = {
  ALL: '전체',
  LIVE: '라이브 내역',
  SLOT: '슬롯 내역',
  LIVE_SPORT: 'BTI 스포츠 내역',
}

export const gameTypeEnums = {
  SLOT: '슬롯',
  LIVE: '라이브',
  MINI_GAME: '미니게임',
  SPORTS_GAME: '스포츠',
  HOLDEM: '홀덤',
}

export const GameTypeEnumsSelectOptions = () => {
  return Object.entries(gameTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const gameStatusEnums = {
  SHOW: '노출',
  HIDE: '숨김',
}

export const GameStatusEnumsSelectOptions = () => {
  return Object.entries(gameStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const GameStatusEnumsRadioOptions = () => {
  return Object.entries(gameStatusEnums).map(([key, value], i) =>
    i === 0
      ? {
          name: 'gameStatus',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'gameStatus',
          label: value,
          radioValue: key,
        },
  )
}

export const gameCarouselTypeEnums = {
  SLOT: '슬롯',
  LIVE: '라이브',
}

export const VendorIsInspectionStatus = {
  false: '정상',
  true: '점검',
}

export const VendorInspectionEnumsRadioOptions = () => {
  return Object.entries(VendorIsInspectionStatus).map(([key, value], i) =>
    i === 0
      ? {
          name: 'vendorIsInspection',
          label: value,
          radioValue: key,
          initialValue: key,
        }
      : {
          name: 'vendorIsInspection',
          label: value,
          radioValue: key,
        },
  )
}
