import { Card, CardBody } from '@/shared/components/Card'
import React, { useState } from 'react'
import styled from 'styled-components'
import SystemManagementTabs from '../SystemManagementTabs'
import CasinoLevelForm from './CasinoLevelForm'
import CasinoSlotLevelForm from './CasinoSlotLevelForm'
import DepositBonusManagement from './DepositBonusManagement'
import DepositBonusManagementV2 from './DepositBonusManagementV2'
import DepositLevelForm from './DepositLevelForm'
import DepositLevelFormV2 from './DepositLevelFormV2'
import FriendLevelDateForm from './FriendLevelDateForm'
import FriendLevelForm from './FriendLevelForm'
import HoldemLevelForm from './HoldemLevelForm'
import MiniGameLevelForm from './MiniGameLevelForm'
import SportsGameLevelForm from './SportsGameLevelForm'
import VirtualGameLevelPage from './VirtualGame/VirtualGameLevelPage'

const LevelManagement = () => {
  const [activeMenu, setActivemenu] = useState('DEPOSIT_V2')

  const [selectedLevel, setSelectedLevel] = useState('ONE')

  const onClickActiveMenuHandler = title => {
    setActivemenu(title)
    setSelectedLevel('ONE')
  }

  const getContentComponent = () => {
    switch (activeMenu) {
      // case 'DEPOSIT':
      //   return (
      //     <div style={{ width: '100%', display: 'flex' }}>
      //       <div style={{ width: '50%' }}>
      //         <DepositLevelForm selectedLevel={selectedLevel} />
      //       </div>
      //       <div style={{ width: '50%' }}>
      //         <DepositBonusManagement selectedLevel={selectedLevel} />
      //       </div>
      //     </div>
      //   )
      case 'DEPOSIT_V2':
        return (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%' }}>
              <DepositLevelFormV2 selectedLevel={selectedLevel} />
            </div>
            <div style={{ width: '100%' }}>
              <DepositBonusManagementV2 selectedLevel={selectedLevel} />
            </div>
          </div>
        )
      case 'CASINO':
        return (
          <div style={{ width: '100%', display: 'flex' }}>
            <CasinoLevelForm selectedLevel={selectedLevel} />
            <CasinoSlotLevelForm selectedLevel={selectedLevel} />
          </div>
        )
      case 'FRIEND':
        return (
          <div style={{ width: '100%', display: 'flex' }}>
            <FriendLevelForm selectedLevel={selectedLevel} />
            <FriendLevelDateForm />
          </div>
        )

      case 'MINIGAME':
        return <MiniGameLevelForm selectedLevel={selectedLevel} />
      case 'SPORTS':
        return <SportsGameLevelForm selectedLevel={selectedLevel} />
      case 'HOLDEM':
        return <HoldemLevelForm selectedLevel={selectedLevel} />
      case 'VIRTUAL_GAME':
        return <VirtualGameLevelPage selectedLevel={selectedLevel} />
      default:
        return <></>
    }
  }

  return (
    <Card>
      <CardBody>
        <SystemManagementTabs activeKey="8" />
        <LevelWrap>
          <LevelTitleBox
            active={activeMenu === 'DEPOSIT_V2'}
            onClick={() => {
              onClickActiveMenuHandler('DEPOSIT_V2')
            }}
          >
            입금레벨
          </LevelTitleBox>
          <LevelTitleBox
            active={activeMenu === 'FRIEND'}
            onClick={() => {
              onClickActiveMenuHandler('FRIEND')
            }}
          >
            추천인 레벨
          </LevelTitleBox>
          <LevelTitleBox
            active={activeMenu === 'CASINO'}
            onClick={() => {
              onClickActiveMenuHandler('CASINO')
            }}
          >
            카지노 / 슬롯레벨
          </LevelTitleBox>
          <LevelTitleBox
            active={activeMenu === 'MINIGAME'}
            onClick={() => {
              onClickActiveMenuHandler('MINIGAME')
            }}
          >
            미니게임 레벨
          </LevelTitleBox>
          <LevelTitleBox
            active={activeMenu === 'SPORTS'}
            onClick={() => {
              onClickActiveMenuHandler('SPORTS')
            }}
          >
            스포츠 레벨
          </LevelTitleBox>
          <LevelTitleBox
            active={activeMenu === 'HOLDEM'}
            onClick={() => {
              onClickActiveMenuHandler('HOLDEM')
            }}
          >
            홀덤 레벨
          </LevelTitleBox>
          <LevelTitleBox
            active={activeMenu === 'VIRTUAL_GAME'}
            onClick={() => {
              onClickActiveMenuHandler('VIRTUAL_GAME')
            }}
          >
            가상게임 레벨
          </LevelTitleBox>
        </LevelWrap>
        <LevelContentWrap>
          <ContentItemWrap>
            <ContentLevelItemBox
              active={selectedLevel === 'ONE'}
              onClick={() => {
                setSelectedLevel('ONE')
              }}
            >
              1 레벨
            </ContentLevelItemBox>
            <ContentLevelItemBox
              active={selectedLevel === 'TWO'}
              onClick={() => {
                setSelectedLevel('TWO')
              }}
            >
              2 레벨
            </ContentLevelItemBox>
            <ContentLevelItemBox
              active={selectedLevel === 'THREE'}
              onClick={() => {
                setSelectedLevel('THREE')
              }}
            >
              3 레벨
            </ContentLevelItemBox>
            <ContentLevelItemBox
              active={selectedLevel === 'FOUR'}
              onClick={() => {
                setSelectedLevel('FOUR')
              }}
            >
              4 레벨
            </ContentLevelItemBox>
            <ContentLevelItemBox
              active={selectedLevel === 'FIVE'}
              onClick={() => {
                setSelectedLevel('FIVE')
              }}
            >
              5 레벨
            </ContentLevelItemBox>
            <ContentLevelItemBox
              active={selectedLevel === 'VIP1'}
              onClick={() => {
                setSelectedLevel('VIP1')
              }}
            >
              VIP1 레벨
            </ContentLevelItemBox>
            <ContentLevelItemBox
              active={selectedLevel === 'VIP2'}
              onClick={() => {
                setSelectedLevel('VIP2')
              }}
            >
              VIP2 레벨
            </ContentLevelItemBox>
            <ContentLevelItemBox
              active={selectedLevel === 'VIP3'}
              onClick={() => {
                setSelectedLevel('VIP3')
              }}
            >
              VIP3 레벨
            </ContentLevelItemBox>
          </ContentItemWrap>
          <ContentLevelInnerWrap>{getContentComponent()}</ContentLevelInnerWrap>
        </LevelContentWrap>

        {/* <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '50%', padding: '10px' }}>
            <LevelBoxWrap>
              <LevelBoxHeader>입금 레벨</LevelBoxHeader>
              <p>
                * <span style={{ color: 'red' }}>입금레벨</span>은 순서대로 신규첫충%, 스포츠 첫충%, 스포츠 매충%,
                카지노 첫충%, 카지노 매충%, 1회 최대액, 1일 최대액을 설정할 수 있습니다.
              </p>
              <DepositLevelForm />
            </LevelBoxWrap>
            <LevelBoxWrap>
              <LevelBoxHeader>카지노 라이브 레벨</LevelBoxHeader>
              <p>
                * <span style={{ color: 'red' }}>카지노 라이브레벨</span>은 라이브 콤프비율를 설정할 수 있습니다.
              </p>
              <CasinoLevelForm />
            </LevelBoxWrap>
            <LevelBoxWrap>
              <LevelBoxHeader>카지노 슬롯 레벨</LevelBoxHeader>
              <p>
                * <span style={{ color: 'red' }}>카지노 슬롯레벨</span>은 슬롯 콤프비율를 설정할 수 있습니다.
              </p>
              <CasinoSlotLevelForm />
            </LevelBoxWrap>
            <LevelBoxWrap>
              <LevelBoxHeader>미니게임 레벨</LevelBoxHeader>
              <p>
                *<span style={{ color: 'red' }}>미니게임 레벨</span>은 낙첨비율을 설정할 수 있습니다.
              </p>
              <MiniGameLevelForm />
            </LevelBoxWrap>
            <LevelBoxWrap>
              <LevelBoxHeader>스포츠게임 레벨</LevelBoxHeader>
              <p>
                * <span style={{ color: 'red' }}>스포츠게임 레벨</span>은 낙첨비율을 설정할 수 있습니다.
              </p>
              <SportsGameLevelForm />
            </LevelBoxWrap>
          </div>
          <div style={{ width: '50%' }}>
            <DepositBonusManagement />
          </div>
        </div> */}
      </CardBody>
    </Card>
  )
}

export default LevelManagement

const LevelWrap = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
`

const LevelTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 3px solid black;
  `};
`

const LevelContentWrap = styled.div`
  width: 100%;
  min-height: 500px;
  padding-top: 10px;
  border: 1px solid grey;
`

const ContentItemWrap = styled.div`
  width: 100%;
  display: flex;
  padding-left: 5px;
`

const ContentLevelItemBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 20px;
  font-size: 13px;
  font-weight: 500;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 2px solid black;
  `};
`

const ContentLevelInnerWrap = styled.div`
  width: 100%;
  min-height: 500px;
  padding: 10px;
  border-top: 1px solid grey;
`

const LevelBoxWrap = styled.div`
  width: 100%;
  min-height: 300px;

  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    margin-top: 15px;
  }

  p {
    margin-bottom: 10px;
  }
`

const LevelBoxHeader = styled.div`
  width: 100%;
  font-size: 23px;
  line-height: 30px;
  margin-bottom: 10px;
`
