import { Checkbox } from '@mui/material'
import PropTypes from 'prop-types'
import Switch from 'react-switch' // Import the Switch component
import { FiEye, FiEyeOff, FiChevronDown, FiChevronUp } from 'react-icons/fi'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Button } from 'react-bootstrap'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'
import { SportMarketType, getBetSettlementColor, getBetStatusColor } from '../../../enums/Sport/SportMarketEnums'
import Loading from '../../../shared/components/Loading'
import {
  searchSportFixtureMarketList,
  updateSportFixtureMarketBetList,
  updateSportFixtureMarketVisible,
} from '../../../utils/api/sport/sportFixtureApi'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { formatNumber, priceNumPattern } from '../../../utils/sportUtills'
import {
  BookmakerIdTypeEnums,
  SearchLsportsManualTypeEnumsRadioOptions,
  SearchLsportsMarketTypeEnumsRadioOptions,
} from '../../../enums/SystemManagement/sportEnums'
import { FixtureStatus } from '../../../enums/Sport/SportFixtureEnums'
import TooltipWrapper from '../../../shared/components/TooltipWrapper'

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.bookmakerId === nextProps.bookmakerId &&
    prevProps.eventMarketKey === nextProps.eventMarketKey &&
    isEqual(prevProps.bets, nextProps.bets)
  ) // deepEqual은 깊은 비교를 수행하는 함수를 의미
}

const preprocessBets = bets => {
  const countMap = {}
  const firstOccurrenceMap = {}

  bets.forEach((bet, index) => {
    if (countMap[bet.baseLineNum]) {
      countMap[bet.baseLineNum] += 1 // '++' 대신 '+=' 사용
    } else {
      countMap[bet.baseLineNum] = 1
      firstOccurrenceMap[bet.baseLineNum] = index
    }
  })

  return { countMap, firstOccurrenceMap }
}

const SportFixtureMarketBoxContentItem = React.memo(({ bookmakerId, eventMarketKey, bets, onChangeBetInfoHandler }) => {
  const { countMap, firstOccurrenceMap } = preprocessBets(bets)
  const [selectedValues, setSelectedValues] = useState({})

  useEffect(() => {
    const initialSelectedValues = bets.reduce((acc, bet) => {
      acc[bet.eventBetKey] = bet.settlementType
      return acc
    }, {})
    setSelectedValues(initialSelectedValues)
  }, [bets])

  const handleSelectChange = (eventBetKey, newValue) => {
    const bet = bets.find(bet => bet.eventBetKey === eventBetKey)
    if (bet.betStatus !== 'SETTLED') {
      alert('최종 정산을 하실 경우, 상태 컬럼의 값을 정산으로 바꾸어야 합니다.')
      return
    }

    setSelectedValues(prev => {
      const updatedValues = { ...prev, [eventBetKey]: newValue }
      const data = { settlementType: newValue || null }

      // 상태가 업데이트된 후에 onChangeBetInfoHandler 호출
      onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)

      return updatedValues
    })
  }
  return (
    <>
      {bets.map((bet, index) => {
        const isFirstOccurrence = index === firstOccurrenceMap[bet.baseLineNum]
        return (
          <tr key={bet.eventBetKey}>
            <td>
              {bet.betId}
              {bet.betStatus === 'SUSPENDED' && <SuspendedButton>정지</SuspendedButton>}
            </td>
            <td>
              {bet.newBetName || bet.betName}
              {/* [ {bet.betName} ] */}
            </td>
            {isFirstOccurrence && (
              <td rowSpan={countMap[bet.baseLineNum]}>
                {formatNumber(bet.baseLineNum) || formatNumber(bet.baseLine) || '-'}
              </td>
            )}
            <td>{formatNumber(bet.lineNum) || formatNumber(bet.line) || '-'}</td>
            {/* <th style={{ background: 'rgb(211, 211, 211, 0.8)' }}>원본 배당</th>
                <th style={{ background: 'rgb(120, 179, 66, 0.5)' }}>국내형</th>
                <th style={{ background: 'rgb(222, 163, 47, 0.5)' }}>해외형</th>
                <th style={{ background: 'rgb(44, 65, 171, 0.5)' }}>스페셜</th> */}
            <td style={{ background: 'rgb(211, 211, 211, 0.8)' }}>{formatNumber(bet.priceNum)}</td>
            <td style={{ background: 'rgb(252, 252, 154, 0.3)' }}>
              <input
                type="text"
                value={bet.domesticPrice}
                onChange={e => {
                  const inputValue = e.target.value

                  if (priceNumPattern.test(inputValue)) {
                    const data = {
                      domesticPrice: inputValue,
                    }

                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }
                }}
              />
              &nbsp;&nbsp;
              <VisibleCheckbox
                checked={bet.fixedDomesticBetPrice}
                onChange={e => {
                  const data = {
                    fixedDomesticBetPrice: e.target.checked,
                  }
                  onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                }}
              />
            </td>
            <td style={{ background: 'rgb(248, 164, 252, 0.3)' }}>
              <input
                type="text"
                value={bet.overseasPrice}
                onChange={e => {
                  const inputValue = e.target.value

                  if (priceNumPattern.test(inputValue)) {
                    const data = {
                      overseasPrice: inputValue,
                    }

                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }
                }}
              />
              &nbsp;&nbsp;
              <VisibleCheckbox
                checked={bet.fixedOverseasBetPrice}
                onChange={e => {
                  const data = {
                    fixedOverseasBetPrice: e.target.checked,
                  }
                  onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                }}
              />
            </td>
            <td style={{ background: 'rgb(184, 255, 166, 0.5)' }}>
              <input
                type="text"
                value={bet.specialPrice}
                onChange={e => {
                  const inputValue = e.target.value

                  if (priceNumPattern.test(inputValue)) {
                    const data = {
                      specialPrice: inputValue,
                    }

                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }
                }}
              />
              &nbsp;&nbsp;
              <VisibleCheckbox
                checked={bet.fixedSpecialBetPrice}
                onChange={e => {
                  const data = {
                    fixedSpecialBetPrice: e.target.checked,
                  }
                  onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                }}
              />
            </td>
            <td>
              {bet?.manualBet ? (
                <span style={{ color: 'red' }}>수동</span>
              ) : (
                <span style={{ color: 'blue' }}>자동</span>
              )}
            </td>
            {isFirstOccurrence && (
              <td style={{ background: 'rgb(252, 252, 154, 0.3)' }} rowSpan={countMap[bet.baseLineNum]}>
                <CustomSwitch
                  onChange={e => {
                    if (bet.betStatus === 'SETTLED') {
                      alert('정산된 건은 변경할 수 없습니다.')
                      return
                    }
                    const data = {
                      domesticShowStatus: e, // 스위치의 현재 상태를 올바르게 설정
                      baseLineNum: bet.baseLineNum, // 이 필드가 여러 베팅 행 업데이트에 필요
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data, true) // 여러 행 업데이트
                  }}
                  checked={bet.domesticShowStatus}
                />
              </td>
            )}
            {isFirstOccurrence && (
              <td style={{ background: 'rgb(248, 164, 252, 0.3)' }} rowSpan={countMap[bet.baseLineNum]}>
                <CustomSwitch
                  onChange={e => {
                    if (bet.betStatus === 'SETTLED') {
                      alert('정산된 건은 변경할 수 없습니다.')
                      return
                    }
                    const data = {
                      overseasShowStatus: e, // 스위치의 현재 상태를 올바르게 설정
                      baseLineNum: bet.baseLineNum, // 이 필드가 여러 베팅 행 업데이트에 필요
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data, true) // 여러 행 업데이트
                  }}
                  checked={bet.overseasShowStatus}
                />
              </td>
            )}
            {isFirstOccurrence && (
              <td style={{ background: 'rgb(184, 255, 166, 0.5)' }} rowSpan={countMap[bet.baseLineNum]}>
                <CustomSwitch
                  onChange={e => {
                    if (bet.betStatus === 'SETTLED') {
                      alert('정산된 건은 변경할 수 없습니다.')
                      return
                    }
                    const data = {
                      specialShowStatus: e, // 스위치의 현재 상태를 올바르게 설정
                      baseLineNum: bet.baseLineNum, // 이 필드가 여러 베팅 행 업데이트에 필요
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data, true) // 여러 행 업데이트
                  }}
                  checked={bet.specialShowStatus}
                />
              </td>
            )}

            <MarketBoxContentTableTD betStatus={bet.betStatus}>
              <select
                onChange={e => {
                  const data = {
                    betStatus: e.target.value,
                  }
                  if (bet.betStatus === 'SETTLED') {
                    alert('정산된 건은 상태를 변경하실 수 없습니다.')
                  } else {
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }
                }}
              >
                <option value="OPEN" selected={bet.betStatus === 'OPEN'}>
                  정상
                </option>
                <option value="SUSPENDED" selected={bet.betStatus === 'SUSPENDED'}>
                  중지
                </option>
                <option value="SETTLED" selected={bet.betStatus === 'SETTLED'}>
                  정산
                </option>
              </select>
            </MarketBoxContentTableTD>
            <td>
              <VisibleCheckbox
                checked={bet.fixedBetStatus}
                onChange={e => {
                  const data = {
                    fixedBetStatus: e.target.checked,
                  }
                  onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                }}
              />
            </td>
            <MarketBoxContentTableTD settlementType={bet.settlementType}>
              <select
                value={selectedValues[bet.eventBetKey] || ''}
                onChange={e => handleSelectChange(bet.eventBetKey, e.target.value)}
              >
                <option value="">-</option>
                <option value="WINNER">적중</option>
                <option value="LOSER">미적중</option>
                <option value="REFUND">적특</option>
              </select>
            </MarketBoxContentTableTD>
            <td>
              <VisibleCheckbox
                checked={bet.fixedBetSettlement}
                onChange={e => {
                  if (
                    selectedValues[bet.eventBetKey] !== 'WINNER' &&
                    selectedValues[bet.eventBetKey] !== 'LOSER' &&
                    selectedValues[bet.eventBetKey] !== 'REFUND'
                  ) {
                    alert('결과값을 먼저 선택하세요.')
                  } else {
                    const data = {
                      fixedBetSettlement: e.target.checked,
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }
                }}
              />
            </td>
          </tr>
        )
      })}
    </>
  )
}, areEqual)

SportFixtureMarketBoxContentItem.propTypes = {
  bookmakerId: PropTypes.string.isRequired,
  eventMarketKey: PropTypes.string.isRequired,
  bets: PropTypes.arrayOf().isRequired,
  onChangeBetInfoHandler: PropTypes.func.isRequired,
}

const CustomTd = styled.td`
color: ${props => (props.manualBet ? `red` : `blue`)}';
`

const SportFixtureMarketBoxContentItemSingle = React.memo(
  ({ bookmakerId, eventMarketKey, bets, onChangeBetInfoHandler }) => {
    const [selectedValues, setSelectedValues] = useState({})

    useEffect(() => {
      const initialSelectedValues = bets.reduce((acc, bet) => {
        acc[bet.eventBetKey] = bet.settlementType
        return acc
      }, {})
      setSelectedValues(initialSelectedValues)
    }, [bets])

    const handleSelectChange = (eventBetKey, newValue) => {
      const bet = bets.find(bet => bet.eventBetKey === eventBetKey)
      if (bet.betStatus !== 'SETTLED') {
        alert('최종 정산을 하실 경우, 상태 컬럼의 값을 정산으로 바꾸어야 합니다.')
        return
      }

      setSelectedValues(prev => {
        const updatedValues = { ...prev, [eventBetKey]: newValue }
        const data = { settlementType: newValue || null }

        // 상태가 업데이트된 후에 onChangeBetInfoHandler 호출
        onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)

        return updatedValues
      })
    }
    return (
      <>
        {bets.map((bet, index) => {
          return (
            <tr key={bet.eventBetKey}>
              <td>
                {bet.betId}
                {bet.betStatus === 'SUSPENDED' && <SuspendedButton>정지</SuspendedButton>}
              </td>
              <td>{bet.newBetName || bet.betName}</td>
              <td>{formatNumber(bet.baseLineNum) || formatNumber(bet.baseLine) || '-'}</td>
              <td>{formatNumber(bet.lineNum) || formatNumber(bet.line) || '-'}</td>

              <td style={{ background: 'rgb(211, 211, 211, 0.8)' }}>{formatNumber(bet.priceNum)}</td>
              <td style={{ background: 'rgb(252, 252, 154, 0.3)' }}>
                <input
                  type="text"
                  value={bet.domesticPrice}
                  onChange={e => {
                    const inputValue = e.target.value

                    if (priceNumPattern.test(inputValue)) {
                      const data = {
                        domesticPrice: inputValue,
                      }

                      onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                    }
                  }}
                />
                &nbsp;&nbsp;
                <VisibleCheckbox
                  checked={bet.fixedDomesticBetPrice}
                  onChange={e => {
                    const data = {
                      fixedDomesticBetPrice: e.target.checked,
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }}
                />
              </td>
              <td style={{ background: 'rgb(248, 164, 252, 0.3)' }}>
                <input
                  type="text"
                  value={bet.overseasPrice}
                  onChange={e => {
                    const inputValue = e.target.value

                    if (priceNumPattern.test(inputValue)) {
                      const data = {
                        overseasPrice: inputValue,
                      }

                      onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                    }
                  }}
                />
                &nbsp;&nbsp;
                <VisibleCheckbox
                  checked={bet.fixedOverseasBetPrice}
                  onChange={e => {
                    const data = {
                      fixedOverseasBetPrice: e.target.checked,
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }}
                />
              </td>
              <td style={{ background: 'rgb(184, 255, 166, 0.5)' }}>
                <input
                  type="text"
                  value={bet.specialPrice}
                  onChange={e => {
                    const inputValue = e.target.value

                    if (priceNumPattern.test(inputValue)) {
                      const data = {
                        specialPrice: inputValue,
                      }

                      onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                    }
                  }}
                />
                &nbsp;&nbsp;
                <VisibleCheckbox
                  checked={bet.fixedSpecialBetPrice}
                  onChange={e => {
                    const data = {
                      fixedSpecialBetPrice: e.target.checked,
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }}
                />
              </td>
              <td>{bet.manualBet ? '수동' : '자동'}</td>
              <td style={{ background: 'rgb(252, 252, 154, 0.3)' }}>
                <CustomSwitch
                  onChange={e => {
                    if (bet.betStatus === 'SETTLED') {
                      alert('정산된 건은 변경할 수 없습니다.')
                      return
                    }
                    const data = {
                      domesticShowStatus: e, // 스위치의 현재 상태를 올바르게 설정
                      baseLineNum: bet.baseLineNum, // 이 필드가 여러 베팅 행 업데이트에 필요
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data) // 여러 행 업데이트
                  }}
                  checked={bet.domesticShowStatus}
                />
              </td>
              <td style={{ background: 'rgb(248, 164, 252, 0.3)' }}>
                <CustomSwitch
                  onChange={e => {
                    if (bet.betStatus === 'SETTLED') {
                      alert('정산된 건은 변경할 수 없습니다.')
                      return
                    }
                    const data = {
                      overseasShowStatus: e, // 스위치의 현재 상태를 올바르게 설정
                      baseLineNum: bet.baseLineNum, // 이 필드가 여러 베팅 행 업데이트에 필요
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data) // 여러 행 업데이트
                  }}
                  checked={bet.overseasShowStatus}
                />
              </td>
              <td style={{ background: 'rgb(184, 255, 166, 0.5)' }}>
                <CustomSwitch
                  onChange={e => {
                    if (bet.betStatus === 'SETTLED') {
                      alert('정산된 건은 변경할 수 없습니다.')
                      return
                    }
                    const data = {
                      specialShowStatus: e, // 스위치의 현재 상태를 올바르게 설정
                      baseLineNum: bet.baseLineNum, // 이 필드가 여러 베팅 행 업데이트에 필요
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data) // 여러 행 업데이트
                  }}
                  checked={bet.specialShowStatus}
                />
              </td>
              <MarketBoxContentTableTD betStatus={bet.betStatus}>
                <select
                  onChange={e => {
                    const data = {
                      betStatus: e.target.value,
                    }
                    if (bet.betStatus === 'SETTLED') {
                      alert('정산된 건은 상태를 변경하실 수 없습니다.')
                    } else {
                      onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                    }
                  }}
                >
                  <option value="OPEN" selected={bet.betStatus === 'OPEN'}>
                    정상
                  </option>
                  <option value="SUSPENDED" selected={bet.betStatus === 'SUSPENDED'}>
                    중지
                  </option>
                  <option value="SETTLED" selected={bet.betStatus === 'SETTLED'}>
                    정산
                  </option>
                </select>
              </MarketBoxContentTableTD>
              <td>
                <VisibleCheckbox
                  checked={bet.fixedBetStatus}
                  onChange={e => {
                    const data = {
                      fixedBetStatus: e.target.checked,
                    }
                    onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                  }}
                />
              </td>
              <MarketBoxContentTableTD settlementType={bet.settlementType}>
                <select
                  value={selectedValues[bet.eventBetKey] || ''}
                  onChange={e => handleSelectChange(bet.eventBetKey, e.target.value)}
                >
                  <option value="">-</option>
                  <option value="WINNER">적중</option>
                  <option value="LOSER">미적중</option>
                  <option value="REFUND">적특</option>
                </select>
              </MarketBoxContentTableTD>
              <td>
                <VisibleCheckbox
                  checked={bet.fixedBetSettlement}
                  onChange={e => {
                    if (
                      selectedValues[bet.eventBetKey] !== 'WINNER' &&
                      selectedValues[bet.eventBetKey] !== 'LOSER' &&
                      selectedValues[bet.eventBetKey] !== 'REFUND'
                    ) {
                      alert('결과값을 먼저 선택하세요.')
                    } else {
                      const data = {
                        fixedBetSettlement: e.target.checked,
                      }
                      onChangeBetInfoHandler(bookmakerId, eventMarketKey, bet.eventBetKey, data)
                    }
                  }}
                />
              </td>
            </tr>
          )
        })}
      </>
    )
  },
  areEqual,
)

SportFixtureMarketBoxContentItemSingle.propTypes = {
  bookmakerId: PropTypes.string.isRequired,
  eventMarketKey: PropTypes.string.isRequired,
  bets: PropTypes.arrayOf().isRequired,
  onChangeBetInfoHandler: PropTypes.func.isRequired,
}

const SportFixtureMarketBoxItem = React.memo(
  ({ market, onClickFixtureMarketVisibleHandler, onClickBetInfoUpdateHandler, onChangeBetInfoHandler }) => {
    const [isContentVisible, setIsContentVisible] = useState(false) // 초기 상태는 펼침 상태로 설정
    return (
      <MarketBox key={market.eventMarketKey}>
        <MarketBoxHeader style={{ cursor: 'pointer' }} onClick={() => setIsContentVisible(!isContentVisible)}>
          <span style={{ color: '#f5f5f5' }}>
            {market.newMarketName}{' '}
            <span style={{ color: '#e6e6e6' }}>
              [ {market.marketName} - <span style={{ color: 'orange' }}>{market.marketId}</span> ]
            </span>{' '}
            ({' '}
            {
              {
                true: (
                  <span
                    style={{ color: 'rgb(252, 252, 154, 1)' }}
                    onClick={e => {
                      e.stopPropagation()
                      onClickFixtureMarketVisibleHandler(
                        market.eventBookmakerKey,
                        !market.domesticMarketVisible,
                        'DOMESTIC',
                      )
                    }}
                  >
                    국내형:{' '}
                    <FiEye
                      style={{ cursor: 'pointer', width: '20px', height: '20px', color: '#42fc89' }}
                      onClick={e => {
                        e.stopPropagation()
                        onClickFixtureMarketVisibleHandler(
                          market.eventBookmakerKey,
                          !market.domesticMarketVisible,
                          'DOMESTIC',
                        )
                      }}
                    />
                  </span>
                ),
                false: (
                  <span
                    style={{ color: 'rgb(252, 252, 154, 1)' }}
                    onClick={e => {
                      e.stopPropagation()
                      onClickFixtureMarketVisibleHandler(
                        market.eventBookmakerKey,
                        !market.domesticMarketVisible,
                        'DOMESTIC',
                      )
                    }}
                  >
                    국내형:{' '}
                    <FiEyeOff
                      style={{ cursor: 'pointer', width: '20px', height: '20px', color: '#ff4275' }}
                      onClick={e => {
                        e.stopPropagation()
                        onClickFixtureMarketVisibleHandler(
                          market.eventBookmakerKey,
                          !market.domesticMarketVisible,
                          'DOMESTIC',
                        )
                      }}
                    />
                  </span>
                ),
              }[market.domesticMarketVisible]
            }
            {
              {
                true: (
                  <span
                    style={{ color: 'rgb(248, 164, 252, 1)' }}
                    onClick={e => {
                      e.stopPropagation()
                      onClickFixtureMarketVisibleHandler(
                        market.eventBookmakerKey,
                        !market.overseasMarketVisible,
                        'OVERSEAS',
                      )
                    }}
                  >
                    {' '}
                    &nbsp; 해외형:{' '}
                    <FiEye
                      style={{ cursor: 'pointer', width: '20px', height: '20px', color: '#42fc89' }}
                      onClick={e => {
                        e.stopPropagation()
                        onClickFixtureMarketVisibleHandler(
                          market.eventBookmakerKey,
                          !market.overseasMarketVisible,
                          'OVERSEAS',
                        )
                      }}
                    />
                  </span>
                ),
                false: (
                  <span
                    style={{ color: 'rgb(248, 164, 252, 1)' }}
                    onClick={e => {
                      e.stopPropagation()
                      onClickFixtureMarketVisibleHandler(
                        market.eventBookmakerKey,
                        !market.overseasMarketVisible,
                        'OVERSEAS',
                      )
                    }}
                  >
                    {' '}
                    &nbsp; 해외형:{' '}
                    <FiEyeOff
                      style={{ cursor: 'pointer', width: '20px', height: '20px', color: '#ff4275' }}
                      onClick={e => {
                        e.stopPropagation()
                        onClickFixtureMarketVisibleHandler(
                          market.eventBookmakerKey,
                          !market.overseasMarketVisible,
                          'OVERSEAS',
                        )
                      }}
                    />
                  </span>
                ),
              }[market.overseasMarketVisible]
            }
            {
              {
                true: (
                  <span
                    style={{ color: 'rgb(184, 255, 166)' }}
                    onClick={e => {
                      e.stopPropagation()
                      onClickFixtureMarketVisibleHandler(
                        market.eventBookmakerKey,
                        !market.specialMarketVisible,
                        'SPECIAL',
                      )
                    }}
                  >
                    {' '}
                    &nbsp; 스페셜:{' '}
                    <FiEye
                      style={{ cursor: 'pointer', width: '20px', height: '20px', color: '#42fc89' }}
                      onClick={e => {
                        e.stopPropagation()
                        onClickFixtureMarketVisibleHandler(
                          market.eventBookmakerKey,
                          !market.specialMarketVisible,
                          'SPECIAL',
                        )
                      }}
                    />
                  </span>
                ),
                false: (
                  <span
                    style={{ color: 'rgb(184, 255, 166)' }}
                    onClick={e => {
                      e.stopPropagation()
                      onClickFixtureMarketVisibleHandler(
                        market.eventBookmakerKey,
                        !market.specialMarketVisible,
                        'SPECIAL',
                      )
                    }}
                  >
                    {' '}
                    &nbsp; 스페셜:{' '}
                    <FiEyeOff
                      style={{ cursor: 'pointer', width: '20px', height: '20px', color: '#ff4275' }}
                      onClick={e => {
                        e.stopPropagation()
                        onClickFixtureMarketVisibleHandler(
                          market.eventBookmakerKey,
                          !market.specialMarketVisible,
                          'SPECIAL',
                        )
                      }}
                    />
                  </span>
                ),
              }[market.specialMarketVisible]
            }{' '}
            )
          </span>
          <MarketFunctionBox>
            {/* <MarketFunctionButton>초기화</MarketFunctionButton> */}

            {/* <MarketFunctionButton
              variant="secondary"
              onClick={() => {
                // setBetInfo(bet)
                // setSportFixtureMarketBetModifyModalShow(true)
              }}
            >
              배당추가
            </MarketFunctionButton> */}
            {isContentVisible ? (
              <FiChevronUp
                style={{ cursor: 'pointer', color: '#42fc89' }}
                onClick={() => setIsContentVisible(!isContentVisible)}
                size={20}
              />
            ) : (
              <FiChevronDown
                style={{ cursor: 'pointer', color: 'red' }}
                onClick={() => setIsContentVisible(!isContentVisible)}
                size={20}
              />
            )}
            <MarketFunctionButton
              style={{ background: 'orange', border: 'solid 1px orange', zIndex: '300' }}
              variant="secondary"
              onClick={e => {
                e.stopPropagation() // 이벤트 버블링을 중단
                onClickBetInfoUpdateHandler(market.eventMarketKey)
              }}
            >
              수정
            </MarketFunctionButton>
          </MarketFunctionBox>
        </MarketBoxHeader>
        <MarketBoxContent>
          <table>
            <thead>
              <tr>
                <th rowSpan={2}>ID</th>
                <th rowSpan={2}>이름</th>
                <th rowSpan={2}>기준점</th>
                <th rowSpan={2}>라인</th>
                <th colSpan={4}>배당 / 배당고정</th>
                <th rowSpan={2}>발매 타입</th>
                <th colSpan={3}>대기/발매</th>
                <th rowSpan={2}>상태</th>
                <th rowSpan={2}>상태 고정</th>
                <th rowSpan={2}>결과</th>
                <th rowSpan={2}>
                  <TooltipWrapper
                    text="결과를 수동으로 변경하고, 해당 경기를 간 회원들의 결과를 고정시킬때 사용"
                    place="bottom"
                  >
                    <span
                      onClick={() => {
                        alert('결과를 수동으로 변경하고, 해당 경기를 간 회원들의 결과를 고정시킬때 사용')
                      }}
                      style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                      결과 고정
                    </span>
                  </TooltipWrapper>
                </th>
              </tr>
              <tr>
                <th style={{ background: 'rgb(211, 211, 211, 0.8)' }}>원본 배당</th>
                <th style={{ background: '#fcfc9a' }}>국내형</th>
                <th style={{ background: '#f8a4fc' }}>해외형</th>
                <th style={{ background: '#b8ffa6' }}>스페셜</th>
                <th style={{ background: '#fcfc9a' }}>국내형</th>
                <th style={{ background: '#f8a4fc' }}>해외형</th>
                <th style={{ background: '#b8ffa6' }}>스페셜</th>
              </tr>
            </thead>
            {isContentVisible ? (
              <tbody>
                {market.betLines?.map(betLine => {
                  const singleComponentTypes = [0, 1, 26, 27, 28, 29, 30, 37, 38, 39, 40, 43]
                  const useSingleComponent = singleComponentTypes.includes(market.marketFamilyType)
                  const ComponentToShow = useSingleComponent
                    ? SportFixtureMarketBoxContentItemSingle
                    : SportFixtureMarketBoxContentItem

                  return (
                    <ComponentToShow
                      bookmakerId={market.bookmakerId}
                      eventMarketKey={market.eventMarketKey}
                      bets={betLine.bets}
                      onChangeBetInfoHandler={onChangeBetInfoHandler}
                    />
                  )
                })}
              </tbody>
            ) : (
              <tbody>
                <tr onClick={() => setIsContentVisible(!isContentVisible)}>
                  <td colSpan="18" style={{ textAlign: 'center' }}>
                    배당을 확인하시려면, 위 화살표 아이콘을 눌러주세요.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </MarketBoxContent>
      </MarketBox>
    )
  },
)

SportFixtureMarketBoxItem.propTypes = {
  market: PropTypes.shape().isRequired,
  onClickFixtureMarketVisibleHandler: PropTypes.func.isRequired,
  onClickBetInfoUpdateHandler: PropTypes.func.isRequired,
  onChangeBetInfoHandler: PropTypes.func.isRequired,
}

const ManualSportFixtureMarketOffcanvas = ({
  fixtureId,
  usingBookmakerId,
  isShow,
  closeHandler,
  statusId,
  statusKr,
}) => {
  const dispatch = useDispatch()

  const [marketLoading, setMarketLoading] = useState(false)
  const [marketFixtureId, setMarketFixtureId] = useState(-1)
  const [marketFixtureInfo, setMarketFixtureInfo] = useState({})
  const [marketInfos, setMarketInfos] = useState({})
  const [waitingType, setWaitingType] = useState('OPEN_MARKET')
  const [manualBetType, setManualBetType] = useState('MANUAL')
  const [selectedBookmakerId, setSelectedBookmakerId] = useState(-1)
  useEffect(() => {
    setSelectedBookmakerId(usingBookmakerId)
  }, [])
  const { bookMakerMenu } = useSelector(state => {
    const { bookMakerInfo } = state

    return {
      bookMakerMenu: bookMakerInfo.bookMakerMenu,
    }
  })
  const [apiLoading, setApiLoading] = useState(false)

  const [fixtureMarketSearchParams, setFixtureMarketSearchParams] = useState(null)

  const onSelectMarketTypeOptionHandler = option => {
    setWaitingType(option)
    // setFixtureMarketSearchParams(prev => ({
    //   ...prev,
    //   waitingType,
    // }))
  }

  const onSelectManualTypeOptionHandler = option => {
    setManualBetType(option)
    // setFixtureMarketSearchParams(prev => ({
    //   ...prev,
    //   waitingType,
    // }))
  }

  useEffect(() => {
    if (fixtureId === -1) return
    if (selectedBookmakerId === -1) return

    setFixtureMarketSearchParams({
      fixtureId,
      fixtureType: 'PRE_MATCH',
      bookmakerId: selectedBookmakerId,
      waitingType,
      manualBetType,
    })
  }, [dispatch, fixtureId, selectedBookmakerId, waitingType, manualBetType])

  const fetchSportFixtureMarketList = async () => {
    setMarketLoading(true)
    await searchSportFixtureMarketList(fixtureMarketSearchParams)
      .then(res => {
        const { fixtureId, fixture, marketBookmakers } = res.data
        setMarketFixtureId(fixtureId)
        setMarketFixtureInfo(fixture)
        setMarketInfos(
          marketBookmakers.reduce((acc, item) => {
            const key = item.bookmakerId
            if (!acc[key]) {
              acc[key] = {}
            }

            acc[key] = item.eventMarketList
            return acc
          }, {}),
        )

        setMarketLoading(false)
      })
      .catch(error => {
        setMarketLoading(false)
      })
  }

  useEffect(() => {
    if (!fixtureMarketSearchParams) return
    if (!fixtureMarketSearchParams.bookmakerId) return
    fetchSportFixtureMarketList()
  }, [fixtureMarketSearchParams])

  // useEffect(() => {
  //   if (bookMakerMenu.length === 0) return
  //   setSelectedBookmakerId(bookMakerMenu[0]?.bookmakerId)
  // }, [bookMakerMenu])

  // 마켓 노출 여부 수정
  const onClickFixtureMarketVisibleHandler = useCallback((eventBookmakerKey, fixtureMarketVisible, sportMarketType) => {
    if (apiLoading) return

    if (
      window.confirm(
        `해당 마켓을 ${SportMarketType[sportMarketType]}에서 ${
          fixtureMarketVisible ? '노출' : '비노출'
        } 처리하시겠습니까?`,
      )
    ) {
      setApiLoading(true)
      const body = {
        sportMarketType,
        fixtureMarketVisible,
      }
      updateSportFixtureMarketVisible(eventBookmakerKey, body)
        .then(res => {
          const { bookmakerId, marketId, visible } = res.data
          alert('마켓 노출여부가 수정 됐습니다.')
          setApiLoading(false)
          setMarketInfos(prev => {
            // bookmakerId에 해당하는 마켓 정보를 찾습니다.
            const marketIndex = prev[bookmakerId]?.findIndex(market => market.marketId === marketId) ?? -1

            // 해당 마켓 정보가 없다면 이전 상태를 그대로 반환합니다.
            if (marketIndex === -1) return prev

            // 불변성을 유지하면서 해당 마켓의 visible 값을 업데이트합니다.
            const updatedMarkets = [...prev[bookmakerId]] // 해당 bookmakerId의 마켓 배열을 복사합니다.

            const visibilityField =
              {
                DOMESTIC: 'domesticMarketVisible',
                OVERSEAS: 'overseasMarketVisible',
                SPECIAL: 'specialMarketVisible',
                LIVE: 'liveMarketVisible',
              }[sportMarketType] || 'fixtureMarketVisible' // Default to 'fixtureMarketVisible' if type is undefined

            updatedMarkets[marketIndex] = {
              ...updatedMarkets[marketIndex],
              [visibilityField]: visible,
            }

            return {
              ...prev,
              [bookmakerId]: updatedMarkets,
            }
          })
        })
        .catch(error => {
          alert('마켓 노출여부 수정 실패했습니다.')
          setApiLoading(false)
        })
    }
  }, [])

  const [updatedMarketBetList, setUpdatedMarketBetList] = useState({})
  const refTest = useRef(updatedMarketBetList)
  useEffect(() => {
    refTest.current = updatedMarketBetList
  }, [updatedMarketBetList])

  const onChangeBetInfoHandler = useCallback(
    (bookmakerId, eventMarketKey, eventBetKey, data, updateMultiple = false) => {
      let resultList = { ...refTest.current }
      setMarketInfos(prev => {
        const updatedInfos = { ...prev }
        const marketInfoList = updatedInfos[bookmakerId] || []
        const marketIndex = marketInfoList.findIndex(market => market.eventMarketKey === eventMarketKey)
        if (marketIndex !== -1) {
          const eventBetLines = [...marketInfoList[marketIndex].betLines]
          const betLineIndex = eventBetLines.findIndex(line => line.bets.some(bet => bet.eventBetKey === eventBetKey))

          if (betLineIndex !== -1) {
            const bets = [...eventBetLines[betLineIndex].bets]
            const betIndex = bets.findIndex(bet => bet.eventBetKey === eventBetKey)

            if (betIndex !== -1) {
              // Update the specific bet with new data, keeping all other properties unchanged
              const updatedBet = { ...bets[betIndex], ...data }
              bets[betIndex] = updatedBet
              if (resultList[eventMarketKey]) {
                if (resultList[eventMarketKey][eventBetKey]) {
                  resultList[eventMarketKey][eventBetKey] = {
                    ...resultList[eventMarketKey][eventBetKey],
                    ...bets[betIndex],
                  }
                } else {
                  resultList[eventMarketKey] = {
                    ...resultList[eventMarketKey],
                    [eventBetKey]: bets[betIndex],
                  }
                }
              } else {
                resultList = {
                  ...resultList,
                  [eventMarketKey]: {
                    [eventBetKey]: bets[betIndex],
                  },
                }
              }

              if (updateMultiple) {
                bets.forEach((bet, idx) => {
                  if (bet.baseLineNum === updatedBet.baseLineNum && idx !== betIndex) {
                    bets[idx] = { ...bets[idx], ...data }
                    const betEventBetKeyKey = bets[idx].eventBetKey
                    if (resultList[eventMarketKey]) {
                      if (resultList[eventMarketKey][betEventBetKeyKey]) {
                        resultList[eventMarketKey][betEventBetKeyKey] = {
                          ...resultList[eventMarketKey][betEventBetKeyKey],
                          ...bets[idx],
                        }
                      } else {
                        resultList[eventMarketKey] = {
                          ...resultList[eventMarketKey],
                          [betEventBetKeyKey]: bets[idx],
                        }
                      }
                    } else {
                      resultList = {
                        ...resultList,
                        [eventMarketKey]: {
                          [betEventBetKeyKey]: bets[idx],
                        },
                      }
                    }
                  }
                })
              }

              eventBetLines[betLineIndex].bets = bets
              marketInfoList[marketIndex].betLines = eventBetLines
              updatedInfos[bookmakerId] = marketInfoList
            }
          }
        }
        setUpdatedMarketBetList(resultList)
        return updatedInfos
      })
    },
    [],
  )

  const onClickBetInfoUpdateHandler = useCallback(
    eventMarketKey => {
      if (apiLoading) return
      setApiLoading(true)
      let updatedEventBetList
      if (!eventMarketKey) {
        updatedEventBetList = Object.values(updatedMarketBetList).flatMap(eventMarket =>
          Object.values(eventMarket).map(eventBet => {
            return eventBet
          }),
        )
      } else {
        const marketBets = updatedMarketBetList[eventMarketKey]
        // console.log('marketBets', marketBets)
        if (!marketBets || Object.keys(marketBets).length === 0) {
          setApiLoading(false)
          alert('변경건이 없습니다.')
          return
        }
        // console.log('marketBets', marketBets)
        // 서버 요청을 위한 베팅 리스트 구성
        updatedEventBetList = Object.values(marketBets).map(bet => ({
          eventBetKey: bet.eventBetKey,
          domesticPrice: bet.domesticPrice,
          overseasPrice: bet.overseasPrice,
          specialPrice: bet.specialPrice,
          betStatus: bet.betStatus, // 기본값 'OPEN', 실제로는 적절한 상태가 있어야 함
          fixedBetStatus: bet.fixedBetStatus,

          settlementType: bet.settlementType, // 기본값 'NONE', 실제로는 적절한 상태가 있어야 함
          fixedDomesticBetPrice: bet.fixedDomesticBetPrice,
          fixedOverseasBetPrice: bet.fixedOverseasBetPrice,
          fixedSpecialBetPrice: bet.fixedSpecialBetPrice,
          domesticShowStatus: bet.domesticShowStatus,
          overseasShowStatus: bet.overseasShowStatus,
          specialShowStatus: bet.specialShowStatus,
          fixedBetSettlement: bet.fixedBetSettlement,
        }))
        if (updatedEventBetList.length === 0) {
          setApiLoading(false)
          return
        }
      }
      const body = {
        updatedEventBetList,
      }
      updateSportFixtureMarketBetList(body)
        .then(res => {
          setApiLoading(false)
          fetchSportFixtureMarketList()
          // if (eventMarketKey) {
          //   setMarketInfos(prev => {
          //     // selectedBookmakerId에 해당하는 marketInfos 배열을 복사
          //     const marketInfosCopy = [...prev[selectedBookmakerId]]

          //     // 해당 배열 내에서 eventMarketKey를 기준으로 marketInfo 찾기
          //     const updatedMarketInfos = marketInfosCopy.map(marketInfo => {
          //       if (marketInfo.eventMarketKey === eventMarketKey) {
          //         // eventMarketKey가 일치하는 marketInfo의 betLines 업데이트
          //         const updatedBetLines = marketInfo.betLines.map(line => {
          //           // line 내의 bets 중 eventBetKey를 기준으로 베팅 찾기
          //           const updatedBets = line.bets.map(bet => {
          //             const betUpdate = res.data.betLines.find(update => update.eventBetKey === bet.eventBetKey)
          //             return betUpdate
          //               ? {
          //                   ...bet,
          //                   domesticPrice: betUpdate.domesticPrice,
          //                   overseasPrice: betUpdate.overseasPrice,
          //                   specialPrice: betUpdate.specialPrice,
          //                   livePrice: betUpdate.livePrice,
          //                   betStatus: betUpdate.betStatus,
          //                   fixedBetStatus: betUpdate.fixedBetStatus,
          //                   settlementType: betUpdate.settlementType,
          //                   fixedBetPrice: betUpdate.fixedBetPrice,
          //                   showStatus: betUpdate.showStatus,
          //                 }
          //               : bet
          //           })
          //           return { ...line, bets: updatedBets }
          //         })
          //         return { ...marketInfo, betLines: updatedBetLines }
          //       }
          //       return marketInfo // eventMarketKey가 일치하지 않는 경우 원본 marketInfo 반환
          //     })

          //     // 전체 prev 상태 업데이트
          //     return { ...prev, [selectedBookmakerId]: updatedMarketInfos }
          //   })
          // } else {
          //   fetchSportFixtureMarketList()
          // }
          alert('변경이 완료 됐습니다.')
        })
        .catch(error => {
          alert('수정 실패했습니다. 다시 시도해주세요.')
          setApiLoading(false)
        })
    },
    [apiLoading, updatedMarketBetList],
  )

  return (
    <>
      <CustomOffcanvas show={isShow} onHide={closeHandler} placement="end" scroll={false} backdrop>
        {(marketLoading || apiLoading) && <Loading />}
        <CustomOffcanvas.Header closeButton>
          <CustomOffcanvas.Title>
            <span style={{ fontWeight: '900' }}>프리매치</span> 마켓관리 -{' '}
            <span style={{ color: 'orange' }}>
              {marketFixtureInfo?.homeName} vs {marketFixtureInfo?.awayName}
            </span>
          </CustomOffcanvas.Title>
        </CustomOffcanvas.Header>
        <CustomOffcanvas.Body>
          <FixtureInfoBox>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>사용중인 북메이커&종목</FixtureInfoLabel>
              <FixtureInfoValue>
                {/* {<span style={{ color: 'red' }}>{BookmakerIdTypeEnums[usingBookmakerId]} -</span>} &nbsp; */}
                {<span style={{ color: 'red' }}>BET365 -</span>} &nbsp;
                {marketFixtureInfo?.sportName}
              </FixtureInfoValue>
            </FixtureInfoBoxItem>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>경기 번호</FixtureInfoLabel>
              <FixtureInfoValue>{fixtureId}</FixtureInfoValue>
            </FixtureInfoBoxItem>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>홈팀(국가코드/리그코드/팀코드)</FixtureInfoLabel>
              <FixtureInfoValue>
                {marketFixtureInfo.homeLocaionId}/{marketFixtureInfo.homeLeagueId}/{marketFixtureInfo.homeId}
              </FixtureInfoValue>
            </FixtureInfoBoxItem>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>원정팀(국가코드/리그코드/팀코드)</FixtureInfoLabel>
              <FixtureInfoValue>
                {marketFixtureInfo.awayLocaionId}/{marketFixtureInfo.awayLeagueId}/{marketFixtureInfo.awayId}
              </FixtureInfoValue>
            </FixtureInfoBoxItem>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>국가</FixtureInfoLabel>
              <FixtureInfoValue>
                {marketFixtureInfo?.locationName}{' '}
                {marketFixtureInfo.locationVisible ? '[ 노출 ]' : <span style={{ color: 'blue' }}>[ 비노출 ]</span>}
              </FixtureInfoValue>
            </FixtureInfoBoxItem>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>리그 (국내형/해외형/스페셜)</FixtureInfoLabel>
              <FixtureInfoValue>
                {marketFixtureInfo?.leagueName} (
                {marketFixtureInfo.leagueDomesticVisible ? ' 노출 ' : <span style={{ color: 'blue' }}> 비노출 </span>} /
                {marketFixtureInfo.leagueOverseasVisible ? ' 노출 ' : <span style={{ color: 'blue' }}> 비노출 </span>} /
                {marketFixtureInfo.leagueSpecialVisible ? ' 노출 ' : <span style={{ color: 'blue' }}> 비노출 </span>})
              </FixtureInfoValue>
            </FixtureInfoBoxItem>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>경기 시간</FixtureInfoLabel>
              <FixtureInfoValue>{convertToKstSportsEventDateTime(marketFixtureInfo?.startDate)}</FixtureInfoValue>
            </FixtureInfoBoxItem>
            <FixtureInfoBoxItem>
              <FixtureInfoLabel>경기 상태</FixtureInfoLabel>
              <FixtureInfoValue>
                <span style={{ color: 'blue' }}>{FixtureStatus[statusKr]}</span> - ({statusId})
              </FixtureInfoValue>
            </FixtureInfoBoxItem>
          </FixtureInfoBox>

          <FixtureMarketBox>
            <BookmakerWrap>
              {bookMakerMenu.map(bookmaker => {
                return (
                  <BookmakerBox
                    active={selectedBookmakerId === bookmaker.bookmakerId}
                    onClick={() => {
                      setSelectedBookmakerId(bookmaker.bookmakerId)
                    }}
                  >
                    {bookmaker.bookmakerName}
                  </BookmakerBox>
                )
              })}
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', gap: '10px' }}>
                {SearchLsportsMarketTypeEnumsRadioOptions().map(item => (
                  <div
                    key={item.radioValue} // 고유한 radioValue를 키로 사용
                    style={{ display: 'flex', width: 'max-content' }}
                  >
                    <label htmlFor={`radio-${item.radioValue}`}>{item.label}</label>
                    <input
                      id={`radio-${item.radioValue}`} // 고유한 radioValue를 id로 사용
                      style={{ marginTop: '-10px' }}
                      type="radio"
                      name={item.name}
                      value={item.radioValue}
                      checked={waitingType === item.radioValue}
                      onChange={e => onSelectMarketTypeOptionHandler(e.target.value)}
                    />
                  </div>
                ))}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px', gap: '10px' }}>
                {SearchLsportsManualTypeEnumsRadioOptions().map(item => (
                  <div
                    key={item.radioValue} // 고유한 radioValue를 키로 사용
                    style={{ display: 'flex', width: 'max-content' }}
                  >
                    <label htmlFor={`radio-${item.radioValue}`}>{item.label}</label>
                    <input
                      id={`radio-${item.radioValue}`} // 고유한 radioValue를 id로 사용
                      style={{ marginTop: '-10px' }}
                      type="radio"
                      name={item.name}
                      value={item.radioValue}
                      checked={manualBetType === item.radioValue}
                      onChange={e => onSelectManualTypeOptionHandler(e.target.value)}
                    />
                  </div>
                ))}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                <MarketFunctionButton
                  variant="danger"
                  onClick={() => {
                    onClickBetInfoUpdateHandler()
                  }}
                  style={{ padding: '5px', fontSize: '13px' }}
                >
                  일괄 수정
                </MarketFunctionButton>
              </div>
            </BookmakerWrap>

            {marketInfos?.[selectedBookmakerId] ? (
              <>
                {marketInfos?.[selectedBookmakerId].map(market => {
                  return (
                    <SportFixtureMarketBoxItem
                      key={`${market.eventMarketKey}-marketBoxItem`}
                      market={market}
                      onClickFixtureMarketVisibleHandler={onClickFixtureMarketVisibleHandler}
                      onClickBetInfoUpdateHandler={onClickBetInfoUpdateHandler}
                      onChangeBetInfoHandler={onChangeBetInfoHandler}
                    />
                  )
                })}
              </>
            ) : (
              <div>현재 제공중인 마켓이 없습니다.</div>
            )}
          </FixtureMarketBox>
        </CustomOffcanvas.Body>
      </CustomOffcanvas>
    </>
  )
}

export default ManualSportFixtureMarketOffcanvas

ManualSportFixtureMarketOffcanvas.propTypes = {
  fixtureId: PropTypes.number.isRequired,
  usingBookmakerId: PropTypes.number.isRequired,
  isShow: PropTypes.bool,
  closeHandler: PropTypes.func,
  statusId: PropTypes.string.isRequired,
  statusKr: PropTypes.string.isRequired,
}

ManualSportFixtureMarketOffcanvas.defaultProps = {
  isShow: false,
  closeHandler: () => {},
}

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`

const SuspendedButton = styled.button`
  width: max-content;
  color: #fff;
  background: red;
  border: none;
  margin-left: 5px;
`

const CustomOffcanvas = styled(Offcanvas)`
  &.offcanvas.offcanvas-end {
    width: 80%;
  }
`

const FixtureInfoBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: lightgray;
`

const FixtureInfoBoxItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #888;
`

const FixtureInfoLabel = styled.span`
  width: 20%;
  padding-left: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: black;
  border-right: 2px solid #888;
`

const FixtureInfoValue = styled.span`
  width: 80%;
  padding-left: 10px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  color: black;
`

const FixtureMarketBox = styled.div`
  width: 100%;
  margin-top: 15px;
`

const MarketBox = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const MarketBoxHeader = styled.div`
  width: 100%;
  padding: 2px 10px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  // background-color: lightgrey;
  border: 1px solid black;
  background: rgb(55, 48, 163, 0.7);
`

const MarketBoxContentTableTD = styled.td`
  select {
    width: 70px;
    display: inline-block;
    height: 25px;
    line-height: 28px;
    padding: 0px 6px;
    border: 1px solid #ccc;
    background: #fff;
    vertical-align: middle;
    border-radius: 3px;
    ${props =>
      props.settlementType &&
      `
      color: ${getBetSettlementColor(props.settlementType)}
      `}

    ${props =>
      props.betStatus &&
      `
    color: ${getBetStatusColor(props.betStatus)}
    
    `};
  }
`

const MarketBoxContent = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }

  thead {
    tr {
      width: 100%;
      background: #f0f0f0;

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: 600;
        font-size: 12px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;

      &:hover {
        border: 2px solid red;
      }

      td {
        padding: 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`

const BookmakerWrap = styled.div`
  width: 100%;
  border-bottom: 1px solid grey;
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
`

const BookmakerBox = styled.div`
  width: max-content;
  padding: 5px;
  font-size: 14px;
  font-weight; 600;
  border: 1px solid grey;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background: lightgrey;
    `}
`

const MarketFunctionBox = styled.div`
  width: max-content;
  padding: 0 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-left: auto;
`

const MarketFunctionButton = styled(Button)`
  width: max-content;
  font-size: 13px;
  padding: 3px;
`

const BetFunctionBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

const BetFunctionButton = styled(Button)`
  width: max-content;
  font-size: 12px;
  padding: 0 4px;
`

const VisibleCheckbox = styled(Checkbox)`
  width: 10px;
  height: 10px;
`
