import { Card, CardBody } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { MiniGameTypeEnumsSelectOptions, MiniGameVendor } from '../../../enums/Log/miniGameBettingEnums'
import Loading from '../../../shared/components/Loading'
import { searchMiniGameResult } from '../../../utils/api/logManage/logApi'
import { convertToKst } from '../../../utils/dateTime'
import MiniGameManagementTabs from '../../MiniGameManagement/MiniGameManagementTabs'
import MiniGameResultListData from './MiniGameResultListData'

const MiniGameResultList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(18)

  /**
   * 미니게임 결과 조회
   */
  const [miniGameResultParams, setMiniGameResultParams] = useState(null)

  useEffect(() => {
    setMiniGameResultParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const [loading, setLoading] = useState(false)

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  const fetchSearchMiniGameResult = async () => {
    setLoading(true)
    await searchMiniGameResult(miniGameResultParams)
      .then(res => {
        setTotalElement(res.data.totalElement)
        setContent(res.data.content)
      })
      .catch(error => {
        // console.log();
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (!miniGameResultParams) return

    fetchSearchMiniGameResult()
  }, [miniGameResultParams])

  const [miniGameResultListData, setMiniGameResultListData] = useState(MiniGameResultListData(content))
  useEffect(() => {
    setMiniGameResultListData(MiniGameResultListData(content))
  }, [content])

  const [rows, setData] = useState(miniGameResultListData.tableRowsData)
  useEffect(() => {
    setData(miniGameResultListData.tableRowsData)
  }, [miniGameResultListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 게임종류
  const [searchGameType, setSearchGameType] = useState('')

  const [searchGameTypeOptionValue, setSearchGameTypeOptionValue] = useState(null)
  const searchGameTypeOption = MiniGameTypeEnumsSelectOptions()

  const onSelectGameTypeOptionHandler = option => {
    if (option === null) {
      setSearchGameTypeOptionValue(null)
      setSearchGameType('')
      return
    }

    setSearchGameTypeOptionValue(option)
    setSearchGameType(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  const [searchGameRound, setSearchGameRound] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setMiniGameResultParams(prev => ({
      ...prev,
      miniGameType: !searchGameType ? null : searchGameType,
      round: !searchGameRound ? null : searchGameRound,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  const [miniGameVendor, setMiniGameVendor] = useState(null)

  // 미니게임 종목 선택
  const onClickMiniGameTypeHandler = type => {
    setMiniGameVendor(type)

    setMiniGameResultParams(prev => ({
      ...prev,
      page: 0,
      miniGameVendor: type || null,
    }))
  }

  // Tab key
  const [activeKey, setActiveKey] = useState('8')
  return (
    <>
      {loading && <Loading />}
      <Card>
        <CardBody>
          <MiniGameManagementTabs activeKey={activeKey} />
          {/* 검색 기능 */}
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>게임종류</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchGameType"
                  placeholder="종류"
                  onChange={option => {
                    onSelectGameTypeOptionHandler(option)
                  }}
                  value={searchGameTypeOptionValue}
                  options={searchGameTypeOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>회차</SearchGroupLabel>
              <SearchGroupField>
                <SearchInputWrap>
                  <input
                    name="searchGameRound"
                    type="text"
                    placeholder="입력해주세요"
                    value={searchGameRound}
                    onChange={e => setSearchGameRound(e.target.value)}
                  />
                </SearchInputWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>시작일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchStartDate}
                    onChange={date => onSearchStartDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="시작일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchGroup>
              <SearchGroupLabel>종료일</SearchGroupLabel>
              <SearchGroupField>
                <SearchDatePickerWrap>
                  <DatePicker
                    selected={searchEndDate}
                    onChange={date => onSearchEndDateChangeHandler(date)}
                    dateFormat="yyyy-MM-dd HH:mm"
                    dropDownMode="select"
                    timeFormat="HH:mm"
                    showTimeInput
                    popperPlacement="bottom-start"
                    placeholderText="종료일"
                    locale={ko}
                    isClearable
                  />
                </SearchDatePickerWrap>
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>
          <MiniGameTypeSelectWrapper>
            <Button
              type="button"
              size="sm"
              variant="outline-secondary"
              active={!miniGameVendor}
              onClick={() => {
                onClickMiniGameTypeHandler(null)
              }}
            >
              전체
            </Button>
            {Object.entries(MiniGameVendor).map(([key, value]) => {
              return (
                <Button
                  type="button"
                  size="sm"
                  variant="outline-secondary"
                  active={key === miniGameVendor}
                  onClick={() => {
                    onClickMiniGameTypeHandler(key)
                  }}
                >
                  {value}
                </Button>
              )
            })}
          </MiniGameTypeSelectWrapper>
          <ReactTableBase columns={miniGameResultListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </CardBody>
      </Card>
    </>
  )
}

export default MiniGameResultList

const MiniGameTypeSelectWrapper = styled.div`
  width: 100%;
  padding: 3px 0;
  margin-bottom: 5px;

  display: flex;
  gap: 5px;
`
