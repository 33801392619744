import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { searchMemberInfo, cretateMemberRecommendUser } from '@/utils/api/memberManage/memberManageApi'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../../../shared/components/Loading'
import { UserAuthorityEnums } from '../../../../../enums/MemberManage/MemberInfoEnums'
import { decodeAccessToken } from '../../../../../utils/token'

function RecommendUserRegistrationForm({ memberId, onModalShowHandler, fetchSearchMember }) {
  const authority = decodeAccessToken()?.adminAuthority
  const [infoLoading, setInfoLoading] = useState(false)

  const [memberInfo, setMemberInfo] = useState({})
  const [newRecommendedCode, setNewRecommendedCode] = useState(null)
  const getMemberInfo = async () => {
    await searchMemberInfo({
      memberId,
    }).then(res => {
      setMemberInfo(res.data.content)
    })
  }

  useEffect(() => {
    if (memberId === null) return
    getMemberInfo()
  }, [memberId])

  const onSubmit = e => {
    if (infoLoading) return
    if (!window.confirm(`[${memberInfo?.memberName}] 유저의 피추천인을 등록하시겠습니까?`)) {
      return
    }

    if (memberInfo.status === 'CANCEL' || memberInfo.status === 'WAITING' || memberInfo.status === 'APPLY') {
      alert('유저의 상태가 신청,대기,취소 인 경우에는 회원정보를 변경할 수 없습니다.')
      return
    }
    if (!e.newRecommendedCode) {
      alert('피추천인을 올바르게 입력하세요.')
      return
    }
    const body = {
      memberKey: memberInfo.memberKey,
      newRecommendUser: !e.newRecommendedCode ? null : e.newRecommendedCode.trim(),
    }
    setInfoLoading(true)

    cretateMemberRecommendUser(body)
      .then(res => {
        setInfoLoading(false)
        onModalShowHandler()
        alert('유저의 피추천인 등록이 성공했습니다.')
        fetchSearchMember()
      })
      .catch(error => {
        setInfoLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'MEMBER-1015') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1016') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1017') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1018') {
          alert(`${message}`)
        } else {
          alert('유저의 피추천인 등록이 실패했습니다.')
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        memberId: `${memberInfo?.memberId}(${memberInfo?.memberName})`,
        memberName: memberInfo?.memberName,
        newRecommendedCode,
        userAuthority: memberInfo?.userAuthority,
      }}
    >
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          {infoLoading ? (
            <Loading />
          ) : (
            <>
              <FormGroup>
                <FormGroupLabel>유저ID(명)</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>권한</FormGroupLabel>
                <FormGroupField>
                  <Field name="userAuthority">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input {...input} type="text" value={UserAuthorityEnums[input.value]} disabled />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>피추천인 ID</FormGroupLabel>
                <FormGroupField>
                  <Field name="newRecommendedCode">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            setNewRecommendedCode(e.target.value)
                          }}
                          placeholder="피추천인 ID를 입력하세요."
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
                <FormGroupDescription style={{ marginTop: '10px', fontSize: '13px' }}>
                  * 추천인,피추천인의 권한은 모두 <span style={{ color: 'red', fontWeight: '600' }}>회원</span>이야만
                  가능합니다.
                </FormGroupDescription>
              </FormGroup>
              {
                {
                  SUPER: (
                    <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                      {memberInfo?.userAuthority === 'MEMBER' && (
                        <CustomButton variant="primary" type="submit">
                          등록하기
                        </CustomButton>
                      )}
                    </FormButtonToolbar>
                  ),
                }[authority]
              }
            </>
          )}
        </FormContainer>
      )}
    </Form>
  )
}

export default RecommendUserRegistrationForm

RecommendUserRegistrationForm.propTypes = {
  fetchSearchMember: PropTypes.func.isRequired,
  onModalShowHandler: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`
