import { all, call, put, takeLatest } from 'redux-saga/effects'
import { SocketIOEventName } from '../../enums/WebSocket/SocketIOEventName'
import { SocketIORequestMessageType } from '../../enums/WebSocket/SocketIORequestMessageType'
import { emitPromise, socket } from '../../utils/socket'
import { setBookMakerMenu } from '../BookMakerInfoSlice'
import { setInPlayBookMakerMenu } from '../InPlayBookMakerInfoSlice'
import { setSportInPlayMenu } from '../SportInPlayInfoSlice'
import { setSportMenu } from '../SportInfoSlice'
import {
  fetchBasicInfoAction,
  fetchHeaderAlarmInfoAction,
  fetchHeaderInfoAction,
  fetchPotInfoAction,
  setAlarmInfo,
  setDomainInfo,
  setEdgeInfo,
  setHeaderInfo,
  setPotInfo,
} from '../headerInfoSlice'
import { searchPotAmount } from '../../utils/api/header/headerApi'

function* handleFetchHeaderInfo() {
  try {
    const payload = {
      type: SocketIORequestMessageType.HEADER_INFO_INIT_AD,
      payload: '',
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (res.status) {
      yield put(setHeaderInfo(res.data))
    }
  } catch (error) {
    // console.log();
  }
}

function* watchFetchHeaderInfo() {
  yield takeLatest(fetchHeaderInfoAction, handleFetchHeaderInfo)
}

function* handleFetchHeaderAlarmInfo() {
  try {
    const payload = {
      type: SocketIORequestMessageType.HEADER_ALARM_INIT_AD,
      payload: '',
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (res.status) {
      yield put(setAlarmInfo(res.data))
    }
  } catch (error) {
    // console.log();
  }
}

function* watchFetchHeaderAlarmInfo() {
  yield takeLatest(fetchHeaderAlarmInfoAction, handleFetchHeaderAlarmInfo)
}

function* handleFetchBasicInfo() {
  try {
    const payload = {
      type: SocketIORequestMessageType.BASIC_INFO_INIT_AD,
      payload: '',
    }

    const res = yield call(emitPromise, socket, SocketIOEventName.FIND, payload)

    if (res.status) {
      yield put(setDomainInfo(res.data.domainList))
      yield put(setEdgeInfo(res.data.edgeList))
      yield put(setSportMenu(res.data.sportList))
      yield put(setSportInPlayMenu(res.data.sportList))
      yield put(setBookMakerMenu(res.data.bookMakerList))
      yield put(setInPlayBookMakerMenu(res.data.bookMakerList))
    }
  } catch (error) {
    // console.log();
  }
}

function* watchFetchBasicInfo() {
  yield takeLatest(fetchBasicInfoAction, handleFetchBasicInfo)
}

function* handleFetchPotInfo(action) {
  try {
    const res = yield call(searchPotAmount, action.payload)

    yield put(setPotInfo(res.data))
  } catch (error) {
    // console.log();
  }
}

function* watchFetchPotInfo() {
  yield takeLatest(fetchPotInfoAction, handleFetchPotInfo)
}

export default function* commonSaga() {
  yield all([watchFetchHeaderInfo(), watchFetchHeaderAlarmInfo(), watchFetchBasicInfo(), watchFetchPotInfo()])
}
