import { commonReg1, commonReg3, coomonReg13 } from '../commonValidate'
import { serviceStatusEnums } from '../../../enums/SystemManagement/siteEnums'

export const systemManagementValidate = values => {
  const errors = {}
  if (!values.minimumDeposit || !commonReg3.test(values.minimumDeposit?.toString().replaceAll(',', ''))) {
    errors.minimumDeposit = '최대 50,000원까지 정수만 입력가능합니다.'
  }
  if (!values.minimumWithdrawal || !commonReg3.test(values.minimumWithdrawal?.toString().replaceAll(',', ''))) {
    errors.minimumWithdrawal = '최대 50,000원까지 정수만 입력가능합니다.'
  }

  if (values.serviceStatus.value === 'INSPECTION') {
    if (!values.inspectionStartDate || !values.inspectionEndDate) {
      errors.serviceStatus = '일정을 올바르게 선택해주세요.'
    }
  }

  if (!coomonReg13.test(values.surpriseMaximumAmount?.toString().replaceAll(',', ''))) {
    errors.surpriseMaximumAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.withdrawlTerm)) {
    errors.withdrawlTerm = '정수만 가능'
  }
  if (values.reloadCount && !coomonReg13.test(values.reloadCount?.toString().replaceAll(',', ''))) {
    errors.reloadCount = '정수만 가능'
  }
  if (values.reloadTime && !coomonReg13.test(values.reloadTime?.toString().replaceAll(',', ''))) {
    errors.reloadTime = '정수만 가능'
  }
  if (!values.welcomeInfo) {
    errors.welcomeInfo = '공백은 불가합니다.'
  }

  return errors
}

export const defaultManagementValidate = values => {
  const errors = {}
  if (!coomonReg13.test(values.miniGameMaxBetting?.toString().replaceAll(',', ''))) {
    errors.miniGameMaxBetting = '정수만 가능'
  }
  if (!coomonReg13.test(values.miniGameMinBetting?.toString().replaceAll(',', ''))) {
    errors.miniGameMinBetting = '정수만 가능'
  }
  if (!coomonReg13.test(values.miniGameMaxWinning?.toString().replaceAll(',', ''))) {
    errors.miniGameMaxWinning = '정수만 가능'
  }

  return errors
}

export const eventManagementValidate = values => {
  const errors = {}
  if (!coomonReg13.test(values.minimumAttendanceAmount?.toString().replaceAll(',', ''))) {
    errors.minimumAttendanceAmount = '정수만 가능'
  }
  if (!coomonReg13.test(values.thirdAttendanceBonus?.toString().replaceAll(',', ''))) {
    errors.thirdAttendanceBonus = '정수만 가능'
  }
  if (!coomonReg13.test(values.sixthAttendanceBonus?.toString().replaceAll(',', ''))) {
    errors.sixthAttendanceBonus = '정수만 가능'
  }
  if (!coomonReg13.test(values.payBackEventCriteria?.toString().replaceAll(',', ''))) {
    errors.payBackEventCriteria = '정수만 가능'
  }
  if (values.payBackEventStatus === 'USE' && !values.payBackStartAt) {
    errors.payBackStartAt = '페이백 사용시,날짜를 선택하세요'
  }

  if (values.payBackEventStatus === 'USE' && !values.payBackEndAt) {
    errors.payBackEndAt = '페이백 사용시,날짜를 선택하세요'
  }

  return errors
}
