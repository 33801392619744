import axios from '../base/axios'

/**
 * 에이전트 환수율 조회
 * */
export async function searchSlotCityAgentRTP(params) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/agent/rtp',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 에이전트 환수율 변경
 * */
export async function updateSlotCityAgentRTP(body) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/agent/rtp',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 현재 진행중인 게임 조회
 * - 현재 시간 기준 30분 전
 * */
export async function searchSlotCityOnlineGameList(params) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/game/online-games',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 게임 세션 종료
 *  - 진행중인 모든 게임 세션을 종료
 * */
export async function closeSlotCityGameSession(body) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/game/kick',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 보너스 콜 설정값 조회
 *  - 콜 가능 최소 금액
 * */
export async function searchSlotCityBonusCallConfig(params) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/game/call-config',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 보너스 콜 적용
 * */
export async function startSlotCityBonusCall(body) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/game/call-start',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 보너스 콜 취소
 * */
export async function cancelSlotCityBonusCall(body) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/game/call-cancel',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 보너스 콜 내역 조회
 * - 초기 상태 제외하고 보여주기
 * */
export async function searchSlotCityBonusCallHistory(params) {
  const response = await axios({
    url: '/tyson/admin/v1/casino/slot-city/game/call-history',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
