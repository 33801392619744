import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../../shared/components/Button'
import { searchSlotCityAgentRTP, updateSlotCityAgentRTP } from '../../../utils/api/casino/slotCityApi'
import Loading from '../../../shared/components/Loading'

const SlotCityAgentRTPUpdate = () => {
  const [loading, setLoading] = useState(false)
  const [agentRTP, setAgentRTP] = useState('')

  const fetchSlotCityAgentRTP = async () => {
    await searchSlotCityAgentRTP().then(res => {
      setAgentRTP(res.data.winRatio)
    })
  }

  useEffect(() => {
    fetchSlotCityAgentRTP()
  }, [])

  const onSubmit = () => {
    if (loading) return

    setLoading(true)

    const body = {
      winRatio: agentRTP,
    }

    if (window.confirm('업체 환수율을 변경하시겠습니까?')) {
      updateSlotCityAgentRTP(body)
        .then(res => {
          alert('수정되었습니다.')
          fetchSlotCityAgentRTP()
        })
        .catch(error => {
          alert('실패했습니다.')
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <>
      {loading && <Loading />}
      <SlotCityAgentRTPUpdateWrap>
        <div className="title">업체 환수율 : </div>

        <div className="rtp-box">
          <input
            type="text"
            value={agentRTP}
            onChange={e => {
              const onlyNumber = e.target.value.replace(/[^0-9]/g, '')
              setAgentRTP(onlyNumber)
            }}
          />

          <Button
            variant="primary"
            size="sm"
            type="button"
            onClick={() => {
              onSubmit()
            }}
          >
            수정
          </Button>
        </div>
      </SlotCityAgentRTPUpdateWrap>
    </>
  )
}

export default SlotCityAgentRTPUpdate

const SlotCityAgentRTPUpdateWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;

  .title {
    font-size: 24px;
    font-weight: 500;
    color: #000;
    vertical-align: middle;
  }

  .rtp-box {
    padding: 0 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    input {
      width: 100px;
      outline: none;
      padding: 2px 5px;
      text-align: end;
      float: right;
    }

    button {
      margin-left: 10px;
      margin-bottom: 0;
      padding: 3px 10px;
    }
  }
`
