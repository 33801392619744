import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupDescription,
  FormGroupField,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { searchMemberInfo, updateMemberRollingInfo } from '@/utils/api/memberManage/memberManageApi'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import Loading from '../../../../shared/components/Loading'
import { memberRollingInfoValidate } from '../../../../utils/validate/memberManage/memberInfoValidate'

function MemberRollingModifyForm({ memberId, onModalShowHandler, fetchSearchMember }) {
  const [infoLoading, setInfoLoading] = useState(false)

  // 롤링 슬롯, 라이브 비율
  const [lineSlotRollingPer, setLineSlotRollingPer] = useState('')
  const [lineLiveRollingPer, setLineLiveRollingPer] = useState('')
  const [topPartnerRollingPer, setTopPartnerRollingPer] = useState('')

  // 매장, 총판, 부본사, 본사 변수
  const [partnerLine, setPartnerLine] = useState('')

  const [memberInfo, setMemberInfo] = useState({})

  const getMemberInfo = async () => {
    await searchMemberInfo({
      memberId,
    }).then(res => {
      setMemberInfo(res.data.content)
      setLineSlotRollingPer(res.data.content.lineSlotRollingPer)
      setLineLiveRollingPer(res.data.content.lineLiveRollingPer)
      setPartnerLine(res.data.content.partnerLine)
      setTopPartnerRollingPer(res.data.content.topPartnerRollingPer)
    })
  }

  useEffect(() => {
    if (memberId === null) return
    getMemberInfo()
  }, [memberId])

  const onSubmit = e => {
    if (infoLoading) return
    if (!window.confirm(`[${memberInfo?.memberName}] 회원의 롤링 비율 정보를 수정하시겠습니까?`)) {
      return
    }

    if (memberInfo.status === 'CANCEL' || memberInfo.status === 'WAITING' || memberInfo.status === 'APPLY') {
      alert('회원의 상태가 신청,대기,취소 인 경우에는 회원정보를 변경할 수 없습니다.')
      return
    }

    const body = {
      memberKey: memberInfo.memberKey,
      lineLiveRollingPer: e.lineLiveRollingPer,
      lineSlotRollingPer: e.lineSlotRollingPer,
    }
    setInfoLoading(true)

    updateMemberRollingInfo(body)
      .then(res => {
        setInfoLoading(false)
        onModalShowHandler()
        alert('회원 롤링 비율 정보가 수정되었습니다.')
        fetchSearchMember()
      })
      .catch(error => {
        setInfoLoading(false)
        const { errorCode, message } = error.response.data
        if (errorCode === 'MEMBER-1007') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1008') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1009') {
          alert(`${message}`)
        } else if (errorCode === 'MEMBER-1010') {
          alert(`${message}`)
        } else {
          alert('회원 정보 수정이 실패했습니다. 잠시 후 다시 시도해주세요.')
        }
      })
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={memberRollingInfoValidate}
      initialValues={{
        memberId: `${memberInfo?.memberId}(${memberInfo?.memberName})`,
        memberName: memberInfo?.memberName,
        lineSlotRollingPer,
        lineLiveRollingPer,
        topPartnerRollingPer,
        partnerLine,
      }}
    >
      {({ handleSubmit, form: { reset } }) => (
        <FormContainer horizontal onSubmit={handleSubmit}>
          {infoLoading ? (
            <Loading />
          ) : (
            <>
              <FormGroup>
                <FormGroupLabel>회원ID(명)</FormGroupLabel>
                <FormGroupField>
                  <Field name="memberId">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>파트너 라인</FormGroupLabel>
                <FormGroupField>
                  <Field name="partnerLine">
                    {({ input, meta }) => (
                      <FormInputWrap
                        style={{ border: 'solid 1px #f2f4f7', paddingLeft: '5px', backgroundColor: '#F2F4F7' }}
                      >
                        <div>{partnerLine} </div>
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>직영 파트너 롤링비율%(SLOT/LIVE)</FormGroupLabel>
                <FormGroupField>
                  <Field name="topPartnerRollingPer">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          style={{ textAlign: 'center' }}
                          value={topPartnerRollingPer}
                          disabled
                        />
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>롤링비율%(SLOT/LIVE)</FormGroupLabel>
                <FormGroupField>
                  <Field name="lineSlotRollingPer">
                    {({ input, meta }) => (
                      <FormInputWrap style={{ borderRight: 'solid 1px' }}>
                        <input
                          {...input}
                          type="text"
                          style={{ textAlign: 'center' }}
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                        />
                        {meta.error && <Error error={meta.error} />}
                      </FormInputWrap>
                    )}
                  </Field>
                  <Field name="lineLiveRollingPer">
                    {({ input, meta }) => (
                      <FormInputWrap>
                        <input
                          {...input}
                          type="text"
                          style={{ textAlign: 'center' }}
                          value={input.value}
                          onChange={e => {
                            input.onChange(e)
                          }}
                        />
                        {meta.error && <Error error={meta.error} />}
                      </FormInputWrap>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormButtonToolbar style={{ marginLeft: '5px', marginTop: '-10px' }}>
                <CustomButton variant="primary" type="submit">
                  변경하기
                </CustomButton>
              </FormButtonToolbar>
            </>
          )}
        </FormContainer>
      )}
    </Form>
  )
}

export default MemberRollingModifyForm

MemberRollingModifyForm.propTypes = {
  fetchSearchMember: PropTypes.func.isRequired,
  onModalShowHandler: PropTypes.func.isRequired,
  memberId: PropTypes.string.isRequired,
}

const CustomButton = styled(Button)`
  font-size: 12px;
  padding: 10px;
`
