/**
 * 스포츠 관련 Utill 함수
 */

const scorePattern = /^\d+:\d+$/

const lineNumPattern = /^-?\d+(\.\d+)?$/

// 정규식: 숫자와 소수점만 허용
export const priceNumPattern = /^\d*(\.\d{0,2})?$/

/**
 * 소수점 2자리까지 변환하여 문자열로 반환
 * @param {Number} number
 * @param {Number} fixedNum
 * @returns {String}
 */
export const formatNumber = (number, fixedNum = 2) => {
  if (number === null || number === undefined) return

  if (number === 0) {
    return '0'
  }

  if (lineNumPattern.test(number)) return Number(number).toFixed(fixedNum)

  return number
}
