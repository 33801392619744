import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { setActiveMarketBookmakerMenu } from '../../../redux/BookMakerInfoSlice'

const MarketBookmakerMenu = () => {
  const dispatch = useDispatch()

  const { bookMakerMenu, activeMarketBookmakerMenu } = useSelector(state => {
    const { bookMakerInfo } = state

    return {
      bookMakerMenu: bookMakerInfo.bookMakerMenu,
      activeMarketBookmakerMenu: bookMakerInfo.activeMarketBookmakerMenu,
    }
  })

  const onClickBookMakerMenuHandler = useCallback(
    bookmakerId => {
      dispatch(setActiveMarketBookmakerMenu(bookmakerId))
    },
    [dispatch],
  )

  return (
    <MenuBoxWrap>
      {bookMakerMenu.map(bookMakerMenu => {
        return (
          <MenuTitleBox
            key={bookMakerMenu.bookmakerId}
            active={activeMarketBookmakerMenu === bookMakerMenu.bookmakerId}
            onClick={() => {
              onClickBookMakerMenuHandler(bookMakerMenu.bookmakerId)
            }}
          >
            {bookMakerMenu.bookmakerName}
          </MenuTitleBox>
        )
      })}
    </MenuBoxWrap>
  )
}

export default React.memo(MarketBookmakerMenu)

const MenuBoxWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0px;
  padding: 5px 0;
`

const MenuTitleBox = styled.div`
  width: max-content;
  height: auto;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-radius: 5px;
  color: black;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid grey;
  }

  &:hover {
    background-color: lightgrey;
  }

  ${props =>
    props.active &&
    `
    background-color: lightgrey;
    border-bottom: 1px solid black;
  `};
`
