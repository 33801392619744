import React from 'react'
import { Badge } from 'react-bootstrap'
import styled from 'styled-components'
import {
  MiniGameBettingResultEnums,
  MiniGameBettingTypeEnums,
  MiniGameTypeEnums,
} from '../../../enums/Log/miniGameBettingEnums'
import { convertToKstMiniGameDateTime } from '../../../utils/dateTime'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { CustomAuthorityButton } from '../../../shared/components/Card'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const RealTimeMiniBettingLogListData = content => {
  const columns = [
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span>{original?.edgeName}</span>
            {original?.isNew && (
              <NewBadge bg="custom">
                <span>N</span>
              </NewBadge>
            )}
          </>
        )
      },
    },
    {
      Header: '배팅시간',
      accessor: 'bettingTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstMiniGameDateTime(original?.bettingTime)
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '유저 ID(명)',
      accessor: 'memberId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span style={{ color: 'black', fontWeight: '900' }}>{original.memberId}</span>
      },
    },
    {
      Header: '게임종류',
      accessor: 'miniGameType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MiniGameTypeEnums[original?.miniGameType]
      },
    },
    {
      Header: '배팅종목',
      accessor: 'miniGameBettingType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return MiniGameBettingTypeEnums[original?.miniGameBettingType]
      },
    },
    {
      Header: '라운드',
      accessor: 'miniGameRound',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배당',
      accessor: 'miniGameBettingPer',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ color: 'red', fontWeight: '900' }}>{original?.miniGameBettingPer}</span>
          </>
        )
      },
    },
    {
      Header: '배팅액',
      accessor: 'bettingAmount',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.bettingAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '결과',
      accessor: 'miniGameBettingResult',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original?.miniGameBettingResult) {
          case 'ING':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'green',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original?.miniGameBettingResult]}
              </button>
            )
          case 'WIN':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'blue',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original?.miniGameBettingResult]}
              </button>
            )
          case 'LOSE':
            return (
              <button
                type="button"
                style={{
                  width: '60px',
                  height: '25px',
                  background: 'red',
                  color: 'white',
                  fontWeight: '900',
                  border: 'none',
                }}
              >
                {MiniGameBettingResultEnums[original?.miniGameBettingResult]}
              </button>
            )
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>-</span>
        }
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default RealTimeMiniBettingLogListData

const NewBadge = styled(Badge)`
  width: 20px;
  height: 14px;
  background-color: #ff4861;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;
  margin-left: 5px;

  span {
    width: 20px;
    text-align: center;
  }
`
