import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  ResultCustomStyledModal,
} from '@/shared/components/CustomModal'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { NoteButton, PartnerNoteButton } from '../../../../shared/components/TableElements'
import ResultModifyForm from './ResultModifyForm'

function ResultModifyModal({
  memberId,
  sportsDetailBettingLogKey,
  sportsBettingLogKey,
  resultStatus,
  sportsGameName,
  fetchSearchSportsMarket,
}) {
  const [isModalShow, setModalShow] = useState(false)

  const onClickHandler = () => {
    setModalShow(prevState => !prevState)
  }

  return (
    <div>
      {resultStatus === 'CANCEL' ? (
        ' - '
      ) : (
        <PartnerNoteButton role={Button} onClick={() => onClickHandler()}>
          수정
        </PartnerNoteButton>
      )}

      {isModalShow && (
        <ResultCustomStyledModal show={isModalShow} onHide={onClickHandler} $header>
          <CustomModalHeader>
            <CustomModalCloseButton
              className="lnr lnr-cross"
              aria-label="close-btn"
              type="button"
              onClick={onClickHandler}
            />
            <CustomModalTitle style={{ color: 'white', fontWeight: '600' }}>
              {memberId}회원의 --- {sportsGameName} 결과 수정
            </CustomModalTitle>
          </CustomModalHeader>
          <CustomModalBody>
            <ResultModifyForm
              sportsDetailBettingLogKey={sportsDetailBettingLogKey}
              sportsBettingLogKey={sportsBettingLogKey}
              resultStatus={resultStatus}
              onClickHandler={onClickHandler}
              fetchSearchSportsMarket={fetchSearchSportsMarket}
            />
          </CustomModalBody>
        </ResultCustomStyledModal>
      )}
    </div>
  )
}

export default ResultModifyModal

ResultModifyModal.propTypes = {
  memberId: PropTypes.string.isRequired,
  resultStatus: PropTypes.string.isRequired,
  sportsGameName: PropTypes.string.isRequired,
  sportsDetailBettingLogKey: PropTypes.string.isRequired,
  sportsBettingLogKey: PropTypes.string.isRequired,
  fetchSearchSportsMarket: PropTypes.func,
}

ResultModifyModal.defaultProps = {
  fetchSearchSportsMarket: () => {},
}
