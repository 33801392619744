import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default function LosingApproveRegistrationRadio({ rowPartnerInfo, setRegistrationApprove }) {
  function approveHandler(e) {
    switch (e.target.checked) {
      case true:
        setRegistrationApprove(rowPartnerInfo)
        break
      default:
        break
    }
  }

  return <CustomInput type="radio" name="chech" onClick={e => approveHandler(e)} />
}

LosingApproveRegistrationRadio.propTypes = {
  setRegistrationApprove: PropTypes.func.isRequired,
  rowPartnerInfo: PropTypes.shape({
    userPointExchangeKey: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string,
    pointType: PropTypes.string,
    status: PropTypes.string,
    userPointExchangeAuto: PropTypes.string,
  }).isRequired,
}

const CustomInput = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
