import { Card, CouponCardBody } from '@/shared/components/Card'
import React from 'react'
import { Col, Nav, Tab } from 'react-bootstrap'
import { BorderedBottomTabs, NavItem, NavLink, TabsWrap } from '../../../shared/components/Tabs'
// import BonsaRegistration from './Bonsa/BonsaRegistration'
// import BooBonsaRegistration from './BooBonsa/BooBonsaRegistration'
// import ChongpanRegistration from './Chongpan/ChongpanRegistration'
// import MaejangRegistration from './Maejang/MaejangRegistration'

import CouponCreateForm from './CouponCreateForm'
import CouponCreateList from './CouponCreateList'
import CouponCreateListData from './CouponCreateListData'

const Tabs = () => {
  const couponCreateListData = CouponCreateListData()
  return (
    <Tab.Container defaultActiveKey="1">
      <TabsWrap>
        <Nav className="nav-tabs">
          <NavItem>
            <NavLink eventKey="1">쿠폰 생성</NavLink>
          </NavItem>
          <NavItem>
            <NavLink eventKey="2">쿠폰 조회</NavLink>
          </NavItem>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="1">
            <CouponCreateForm />
          </Tab.Pane>
          <Tab.Pane eventKey="2">
            <CouponCreateList />
          </Tab.Pane>
        </Tab.Content>
      </TabsWrap>
    </Tab.Container>
  )
}

const CouponTabs = () => (
  <Col md={12} lg={12} xl={12}>
    <Card>
      <CouponCardBody>
        <BorderedBottomTabs>
          <Tabs />
        </BorderedBottomTabs>
      </CouponCardBody>
    </Card>
  </Col>
)

export default CouponTabs
