import { useEffect, useState } from 'react'
import axios from '../base/axios'

export async function searchFriendCompHistory(params) {
  const response = await axios({
    url: '/tyson/admin/v1/manage/friend-comp',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function updateFriendCompHistory(body) {
  const response = await axios({
    url: '/tyson/admin/v1/manage/friend-comp',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function deleteFriendCompHistory(body) {
  const response = await axios({
    url: '/tyson/admin/v1/manage/friend-comp',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
