import { Button } from '@/shared/components/Button'
import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import Error from '@/shared/components/form/Error'
import Switch from 'react-switch' // Import the Switch component
import {
  FormButtonToolbar,
  FormContainer,
  FormGroup,
  FormGroupField,
  FormGroupIcon,
  FormGroupLabel,
  FormInputWrap,
} from '@/shared/components/form/FormElements'
import { ko } from 'date-fns/esm/locale'
import TimePicker from 'rc-time-picker'
import moment from 'moment'
import PropTypes from 'prop-types'
import { borderRight } from '@/utils/directions'
import { colorAccent, colorBackground, colorBlue, colorFieldsBorder, colorIcon, colorText } from '@/utils/palette'
import { EditorState } from 'draft-js'
import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { Field, Form } from 'react-final-form'
import Select from 'react-select'
import renderRadioButtonField from '@/shared/components/form/RadioButton'
import styled from 'styled-components'
import { ServiceStatusEnumsSelectOptions, serviceStatusEnums } from '../../../enums/SystemManagement/siteEnums'
import { CustomDatePickerWrap } from '../../../shared/components/CustomDatePicker'
import Loading from '../../../shared/components/Loading'
import {
  searchSystemManage,
  updateSportsMenuManage,
  updateSystemManage,
  useSearchSystemManage,
} from '../../../utils/api/systemManage/systemManageApi'
import { convertToKstDateTimePicker } from '../../../utils/dateTime'
import { convertFromFront, convertFromServer } from '../../../utils/editor'
import { decodeAccessToken } from '../../../utils/token'
import { MoneyDepositAutoEnumsRadioOptions } from '../../../enums/Operation/popupEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import { systemManagementValidate } from '../../../utils/validate/systemManagement/systemManage'
// import validate from '../validate'

const SyetemMangeMent = ({ edgeName }) => {
  /**
   시스템설정 조회
   */
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)

  const [content, setContent] = useState([])

  const fetchSearchSystemManage = async () => {
    await searchSystemManage({
      edgeName,
    }).then(res => {
      setContent(res.data.content)
    })
  }

  useEffect(() => {
    if (!edgeName) return
    fetchSearchSystemManage()
  }, [edgeName])

  const [systemManageData, setSystemManageData] = useState(content)

  // 서비스 상태
  const [serviceStatus, setServiceStatus] = useState(`${content.serviceStatusValue}`)
  const [serviceStatusValue, setServiceStatusValue] = useState(null)

  // 서비스 value 선택
  const [inspectionFlag, setInspectionFlag] = useState(false)
  const [inspectionReason, setInspectionReason] = useState(EditorState.createEmpty())

  const contentChange = editorState => {
    setInspectionReason(editorState)
  }

  const serviceStatusOption = ServiceStatusEnumsSelectOptions()

  const onChangeServiceStatusSelectHandler = option => {
    if (option.value === 'INSPECTION') {
      setInspectionFlag(true)
    } else {
      setInspectionFlag(false)
    }
  }

  const [preventDepositStartDate, setPreventDepositStartDate] = useState(null)

  const onChangePreventDepositStartDate = date => {
    setPreventDepositStartDate(date)
  }

  const [preventDepositEndDate, setPreventDepositEndDate] = useState(null)

  const onChangePreventDepositEndDate = date => {
    setPreventDepositEndDate(date)
  }

  const [preventWithdrawalStartDate, setPreventWithdrawalStartDate] = useState(null)

  const onChangePreventWithdrawalStartDate = date => {
    setPreventWithdrawalStartDate(date)
  }

  const [preventWithdrawalEndDate, setPreventWithdrawalEndDate] = useState(null)

  const onChangePreventWithdrawalEndDate = date => {
    setPreventWithdrawalEndDate(date)
  }

  const [domesticSportsUsing, setDomesticSportsUsing] = useState(false)
  const [overseasSportsUsing, setOverseasSportsUsing] = useState(false)
  const [specialSportsUsing, setSpecialSportsUsing] = useState(false)
  const [liveSportsUsing, setLiveSportsUsing] = useState(false)

  useEffect(() => {
    setSystemManageData(content)
    setInspectionReason(convertFromServer(content.inspectionReason))
    setServiceStatusValue({
      value: `${content.serviceStatusValue}`,
      label: serviceStatusEnums[content.serviceStatusValue],
    })
    setDomesticSportsUsing(content.domesticSportsUsing)
    setOverseasSportsUsing(content.overseasSportsUsing)
    setSpecialSportsUsing(content.specialSportsUsing)
    setLiveSportsUsing(content.liveSportsUsing)
    setInspectionFlag(content.serviceStatusValue === 'INSPECTION')
    setPreventDepositStartDate(moment(content.preventDepositStartDate || '00:00', 'HH:mm'))
    setPreventDepositEndDate(moment(content.preventDepositEndDate || '00:00', 'HH:mm'))
    setPreventWithdrawalStartDate(moment(content.preventWithdrawalStartDate || '00:00', 'HH:mm'))
    setPreventWithdrawalEndDate(moment(content.preventWithdrawalEndDate || '00:00', 'HH:mm'))
    // setInspectionReason(content.inspectionReason)
  }, [content])

  const onSubmit = e => {
    if (writeLoading) return

    // if (e.serviceStatus.value === 'INSPECTION' && !inspectionReason.getCurrentContent().hasText()) {
    //   alert(' 점검사유는 필수사항입니다.')
    //   return
    // }

    if (window.confirm('시스템 설정을 변경 하시겠습니까?')) {
      const body = {
        edgeName,
        minimumDeposit: e.minimumDeposit?.toString().replaceAll(',', ''),
        minimumWithdrawal: e.minimumWithdrawal.toString().replaceAll(',', ''),
        withdrawlTerm: e.withdrawlTerm.toString().replaceAll(',', ''),
        surpriseMaximumAmount: e.surpriseMaximumAmount.toString().replaceAll(',', ''),
        serviceStatus: e.serviceStatus.value,
        inspectionStartDate: inspectionFlag ? e.inspectionStartDate : null,
        inspectionEndDate: inspectionFlag ? e.inspectionEndDate : null,
        inspectionReason: inspectionFlag ? convertFromFront(inspectionReason) : null,
        moneyDepositAuto: e.moneyDepositAuto,
        welcomeInfo: e.welcomeInfo,
        reloadCount: e.reloadCount,
        reloadTime: e.reloadTime,
        preventDepositStartDate: preventDepositStartDate
          ? moment(preventDepositStartDate).tz('Asia/Seoul').format('HH:mm')
          : '00:00',
        preventDepositEndDate: preventDepositEndDate
          ? moment(preventDepositEndDate).tz('Asia/Seoul').format('HH:mm')
          : '00:00',
        preventWithdrawalStartDate: preventWithdrawalStartDate
          ? moment(preventWithdrawalStartDate).tz('Asia/Seoul').format('HH:mm')
          : '00:00',
        preventWithdrawalEndDate: preventWithdrawalEndDate
          ? moment(preventWithdrawalEndDate).tz('Asia/Seoul').format('HH:mm')
          : '00:00',
      }
      setWriteLoading(true)
      updateSystemManage(body)
        .then(res => {
          setWriteLoading(false)
          alert('시스템 설정이 변경됐습니다.')
          fetchSearchSystemManage()
        })
        .catch(error => {
          setWriteLoading(false)
          alert('시스템 설정이 변경이 실패했습니다.')
          fetchSearchSystemManage()
        })
    }
  }

  const onEventHideAndShowHandler = (visible, sportMarketType) => {
    if (authority === 'NORMAL') return
    if (writeLoading) return
    let typeDescription = ''
    switch (sportMarketType) {
      case 'DOMESTIC':
        typeDescription = '국내형'
        break
      case 'OVERSEAS':
        typeDescription = '해외형'
        break
      case 'SPECIAL':
        typeDescription = '스페셜'
        break
      case 'LIVE':
        typeDescription = '라이브'
        break
      default:
        typeDescription = '' // Default case if needed
    }

    let confirmationMessage

    if (visible) {
      confirmationMessage = `${typeDescription}을 노출 처리하시겠습니까?`
    } else {
      confirmationMessage = `${typeDescription}을 비노출 처리하시겠습니까?`
    }

    if (window.confirm(confirmationMessage)) {
      const body = {
        edgeName,
        sportsUsed: visible,
        sportMarketType,
      }
      setWriteLoading(true)
      updateSportsMenuManage(body)
        .then(res => {
          alert('변경이 완료됐습니다.')
          setWriteLoading(false)
          fetchSearchSystemManage()
        })
        .catch(error => {
          alert('변경이 실패하였습니다.')
          setWriteLoading(false)
          fetchSearchSystemManage()
        })
    }
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody style={{ padding: '0px' }}>
          <SearchCardTitleWrap>
            <div>
              <p>
                * 최소 충환전 금액은 <span style={{ fontWeight: 'bolder', color: 'red' }}>회원</span>에게만 적용이
                됩니다.
              </p>
              <p>
                * 서비스 상태를 <span style={{ fontWeight: 'bolder', color: 'red' }}>긴급 점검</span>으로 변경 시&nbsp;
                <span style={{ fontWeight: 'bolder', color: 'red' }}>최상단 팝업</span>
                으로 가장 먼저 나옵니다.
              </p>
            </div>
          </SearchCardTitleWrap>

          {writeLoading ? (
            <Loading />
          ) : (
            <>
              <Form
                onSubmit={onSubmit}
                validate={systemManagementValidate}
                initialValues={{
                  minimumDeposit: systemManageData?.minimumDeposit?.replace(commonReg2, ','),
                  withdrawlTerm: systemManageData?.withdrawlTerm,
                  minimumWithdrawal: systemManageData?.minimumWithdrawal?.replace(commonReg2, ','),
                  serviceStatus: serviceStatusValue || {},
                  inspectionStartDate: convertToKstDateTimePicker(systemManageData?.inspectionStartDate),
                  inspectionEndDate: convertToKstDateTimePicker(systemManageData?.inspectionEndDate),
                  inspectionReason: systemManageData?.inspectionReason,
                  tawkInfo: systemManageData.tawkInfo,
                  welcomeInfo: systemManageData.welcomeInfo,
                  edgeName,
                  moneyDepositAuto: systemManageData?.moneyDepositAuto ? 'true' : 'false',
                  surpriseMaximumAmount: systemManageData?.surpriseMaximumAmount?.replace(commonReg2, ','),
                  reloadCount: systemManageData?.reloadCount,
                  reloadTime: systemManageData?.reloadTime,
                  preventDepositStartDate,
                  preventDepositEndDate,
                  preventWithdrawalStartDate,
                  preventWithdrawalEndDate,
                }}
              >
                {({ handleSubmit, form: { reset } }) => (
                  <FormContainer horizontal onSubmit={handleSubmit}>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>사이트명</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="edgeName">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input {...input} type="text" value={edgeName} disabled />
                              </FormInputWrap>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>최소 입금금액(원)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="minimumDeposit">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>최소 출금금액(원)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="minimumWithdrawal">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>출금 간격시간(분)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="withdrawlTerm">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>분</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>최대 돌발보너스액(원)</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="surpriseMaximumAmount">
                          {({ input, meta }) => (
                            <>
                              <FormInputWrap>
                                <input
                                  {...input}
                                  type="text"
                                  value={input.value}
                                  onChange={e => {
                                    input.onChange(e)
                                  }}
                                />
                                {meta.error && <Error error={meta.error} top />}
                              </FormInputWrap>
                              <FormGroupIcon>원</FormGroupIcon>
                            </>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>입금 자동승인</CustomFormGroupLabel>
                      {MoneyDepositAutoEnumsRadioOptions().map(item => (
                        <CustomFormGroupField2
                          key={`index_${item.label}`}
                          style={{ display: 'inline-block', width: 'max-content', margin: '0px' }}
                        >
                          <Field
                            name={`${item.name}`}
                            component={renderRadioButtonField}
                            label={item.label}
                            radioValue={item.radioValue}
                            initialValue={item.initialValue}
                          />
                        </CustomFormGroupField2>
                      ))}
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>입금 금지시간</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="preventDepositStartDate">
                          {({ input, meta }) => {
                            return (
                              <>
                                <CustomTimePicker
                                  value={preventDepositStartDate}
                                  onChange={date => {
                                    onChangePreventDepositStartDate(date)
                                  }}
                                  hideDisabledOptions
                                  minuteStep={5}
                                  showSecond={false}
                                  use12Hours={false}
                                  placeholder="시작시간"
                                />
                              </>
                            )
                          }}
                        </Field>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>~</div>
                        <Field name="preventDepositEndDate">
                          {({ input, meta }) => {
                            return (
                              <>
                                <CustomTimePicker
                                  value={preventDepositEndDate}
                                  onChange={date => {
                                    onChangePreventDepositEndDate(date)
                                  }}
                                  hideDisabledOptions
                                  minuteStep={5}
                                  showSecond={false}
                                  use12Hours={false}
                                  placeholder="끝시간"
                                />
                              </>
                            )
                          }}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>출금 금지시간</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="preventWithdrawalStartDate">
                          {({ input, meta }) => {
                            return (
                              <>
                                <CustomTimePicker
                                  value={preventWithdrawalStartDate}
                                  onChange={date => {
                                    onChangePreventWithdrawalStartDate(date)
                                  }}
                                  hideDisabledOptions
                                  minuteStep={5}
                                  showSecond={false}
                                  use12Hours={false}
                                  placeholder="시작시간"
                                />
                              </>
                            )
                          }}
                        </Field>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '0 10px' }}>~</div>
                        <Field name="preventWithdrawalEndDate">
                          {({ input, meta }) => {
                            return (
                              <>
                                <CustomTimePicker
                                  value={preventWithdrawalEndDate}
                                  onChange={date => {
                                    onChangePreventWithdrawalEndDate(date)
                                  }}
                                  hideDisabledOptions
                                  minuteStep={5}
                                  showSecond={false}
                                  use12Hours={false}
                                  placeholder="끝시간"
                                />
                              </>
                            )
                          }}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>서비스 상태</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="serviceStatus">
                          {({ input, meta }) => (
                            <div style={{ width: '100%' }}>
                              <MemberCouponSelect
                                {...input}
                                value={input.value}
                                onChange={option => {
                                  input.onChange(option)
                                  onChangeServiceStatusSelectHandler(option)
                                }}
                                options={serviceStatusOption}
                                placeholder="서비스상태"
                                className="react-select"
                                classNamePrefix="react-select"
                              />
                              {meta.touched && meta.error && <Error error={meta.error} />}
                            </div>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>

                    {inspectionFlag ? (
                      <>
                        <CustomFormGroup>
                          <CustomFormGroupLabel>점검시작 일정</CustomFormGroupLabel>
                          <CustomFormGroupField>
                            <Field name="inspectionStartDate">
                              {({ input, meta }) => {
                                return (
                                  <>
                                    <CustomDatePickerWrap>
                                      <DatePicker
                                        selected={input.value ? new Date(input.value) : null}
                                        onChange={date => input.onChange(date)}
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        timeFormat="HH:mm"
                                        showTimeSelect
                                        dropDownMode="select"
                                        popperPlacement="bottom-start"
                                        placeholderText="점검시작일을 선택하세요."
                                        isClearable
                                      />
                                      {meta.touched && meta.error && <Error error={meta.error} top />}
                                    </CustomDatePickerWrap>
                                  </>
                                )
                              }}
                            </Field>
                          </CustomFormGroupField>
                        </CustomFormGroup>
                        <CustomFormGroup>
                          <CustomFormGroupLabel>점검종료 일정</CustomFormGroupLabel>
                          <CustomFormGroupField>
                            <Field name="inspectionEndDate">
                              {({ input, meta }) => (
                                <CustomDatePickerWrap>
                                  <DatePicker
                                    selected={input.value ? new Date(input.value) : null}
                                    onChange={date => input.onChange(date)}
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    timeFormat="HH:mm"
                                    showTimeSelect
                                    dropDownMode="select"
                                    popperPlacement="bottom-start"
                                    placeholderText="점검종료일을 선택하세요."
                                    isClearable
                                  />
                                  {meta.touched && meta.error && <Error error={meta.error} top />}
                                </CustomDatePickerWrap>
                              )}
                            </Field>
                          </CustomFormGroupField>
                        </CustomFormGroup>
                        {/* <FormGroup>
                          <FormGroupLabel>점검사유</FormGroupLabel>
                          <FormGroupField>
                            <Editor
                              editorState={inspectionReason}
                              onEditorStateChange={editorState => contentChange(editorState)}
                              wrapperClassName="wrapper"
                              editorClassName="editor"
                              toolbarClassName="toolbar"
                              placeholder="입력하신 내용은 팝업에 그대로 보여집니다."
                            />
                          </FormGroupField>
                        </FormGroup> */}
                      </>
                    ) : (
                      <></>
                    )}
                    <CustomFormGroup>
                      <CustomFormGroupLabel>스포츠 현시여부</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span>국내형</span>
                          <CustomSwitch
                            onChange={() => onEventHideAndShowHandler(!domesticSportsUsing, 'DOMESTIC')}
                            checked={domesticSportsUsing}
                          />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span>해외형</span>
                          <CustomSwitch
                            onChange={() => onEventHideAndShowHandler(!overseasSportsUsing, 'OVERSEAS')}
                            checked={overseasSportsUsing}
                          />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span>스페셜</span>
                          <CustomSwitch
                            onChange={() => onEventHideAndShowHandler(!specialSportsUsing, 'SPECIAL')}
                            checked={specialSportsUsing}
                          />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <span>인플레이</span>
                          <CustomSwitch
                            onChange={() => onEventHideAndShowHandler(!liveSportsUsing, 'LIVE')}
                            checked={liveSportsUsing}
                          />
                        </div>
                      </CustomFormGroupField>
                    </CustomFormGroup>

                    <CustomFormGroup>
                      <CustomFormGroupLabel>TAWK 채팅정보</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="tawkInfo">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={input.value}
                                disabled
                                onChange={e => {
                                  input.onChange(e)
                                }}
                              />
                              {meta.error && <Error error={meta.error} top />}
                            </FormInputWrap>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>환영 문구</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="welcomeInfo">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={input.value}
                                onChange={e => {
                                  input.onChange(e)
                                }}
                              />
                              {meta.error && <Error error={meta.error} top />}
                            </FormInputWrap>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>새로고침 횟수</CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="reloadCount">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={input.value}
                                onChange={e => {
                                  input.onChange(e)
                                }}
                              />
                              {meta.error && <Error error={meta.error} top />}
                            </FormInputWrap>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    <CustomFormGroup>
                      <CustomFormGroupLabel>
                        새로고침 시간 <br /> (밀리초 1s - 1000ms)
                      </CustomFormGroupLabel>
                      <CustomFormGroupField>
                        <Field name="reloadTime">
                          {({ input, meta }) => (
                            <FormInputWrap>
                              <input
                                {...input}
                                type="text"
                                value={input.value}
                                onChange={e => {
                                  input.onChange(e)
                                }}
                              />
                              {meta.error && <Error error={meta.error} top />}
                            </FormInputWrap>
                          )}
                        </Field>
                      </CustomFormGroupField>
                    </CustomFormGroup>
                    {
                      {
                        SUPER: (
                          <FormButtonToolbar className="registrationFooter">
                            <Button variant="primary" type="submit">
                              등록
                            </Button>
                          </FormButtonToolbar>
                        ),
                      }[authority]
                    }
                  </FormContainer>
                )}
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  )
}

SyetemMangeMent.propTypes = {
  edgeName: PropTypes.string.isRequired,
}

export default SyetemMangeMent

const CustomSwitch = styled(Switch)`
  transform: scale(0.6); /* Adjust the scale factor as needed */

  .react-switch-bg {
    background-color: ${props => (props.checked ? 'blue !important' : 'lightgray !important')};
    width: 20px; /* Adjust the width */
    height: 10px; /* Adjust the height */
    border-radius: 5px; /* Adjust the border radius */
  }

  .react-switch-handle {
    width: 8px; /* Adjust the handle width */
    height: 8px; /* Adjust the handle height */
    top: 1px; /* Adjust the handle position */
  }
  .react-switch-bg.react-switch-bg-checked {
    background-color: skyblue;
  }
`

const CustomFormGroup = styled(FormGroup)`
  border-bottom: dotted 1px black;
  padding-bottom: 10px;
`

const CustomFormGroupLabel = styled(FormGroupLabel)`
  width: 30% !important;
  font-weight: bolder;
  color: black;
  // border: solid 1px red;
  text-align: center;
`
const CustomFormGroupField2 = styled(FormGroupField)`
  width: 35% !important;
  // border: solid 1px red;
  input {
    border: solid 1px black;
  }
`

const CustomFormGroupField = styled(FormGroupField)`
  width: 70% !important;
  // border: solid 1px red;
  input {
    border: solid 1px black;
  }
`

// 회원 쿠폰 Select
export const MemberCouponSelect = styled(Select)`
  width: 100%;
  height: 40px;
  font-size: 12px;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    overflow: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`
// endregion

const CustomTimePicker = styled(TimePicker)`
  & .rc-time-picker-panel-select-option-selected {
    background-color: #edeffe;
    font-weight: normal;
  }

  & .rc-time-picker-clear,
  & .rc-time-picker-clear-icon:after {
    font-size: 15px;
  }

  & .rc-time-picker-panel-select,
  & .rc-time-picker-input,
  & .rc-time-picker-panel-input {
    font-family: 'Consolas', sans-serif;
    font-size: 16px;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`
