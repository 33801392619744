import { commonReg7 } from '../commonValidate'

export const memberCouponValidate = values => {
  const errors = {}

  // 연락처 = 번호만
  if (!values.couponMoney || commonReg7.test(values.couponMoney)) {
    errors.couponMoney = '숫자만 입력 가능합니다.'
  }

  return errors
}
