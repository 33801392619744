import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 정산 수동/자동 업데이트
export async function createSportsSettlementManual(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/settlement',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 정산 수동/자동 업데이트
export async function createSportsSettlementManualV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/settlement/settlement',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 정산 수동/자동 업데이트
export async function createSportsSettlementWaiting(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/settlement/waiting',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 정산 수동/자동 업데이트
export async function createSportsSettlementWaitingV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/settlement/waiting',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 정산 수동/자동 업데이트
export async function updateSportsSettlementType(body) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/settlement',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 정산 수동/자동 업데이트
export async function updateSportsSettlementTypeV2(body) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/settlement/modify',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// select용 조회
export async function getSportsSettlementV2(params) {
  const response = await axios({
    url: '/tyson/admin/v2/sport/settlement',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// select용 조회
export async function getSportsSettlement(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/settlement',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useGetSportsSettlement(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  async function fetchSportsSettlement() {
    await getSportsSettlement(params).then(response => {
      setContent(response.data.content)
      setTotalElement(response.data.totalElement)
    })
  }

  useEffect(() => {
    fetchSportsSettlement()
  }, [params])

  return { content, totalElement, fetchSportsSettlement }
}

// select용 조회
export async function getSportsDetailSettlement(params) {
  const response = await axios({
    url: '/tyson/admin/v1/management/sport/settlement/detail',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useGetSportsDetailSettlement(params) {
  const [content, setContent] = useState([])
  async function fetchSportsDetailSettlement() {
    await getSportsDetailSettlement(params).then(response => {
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSportsDetailSettlement()
  }, [params])

  return { content, fetchSportsDetailSettlement }
}
