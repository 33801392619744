import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { ko } from 'date-fns/esm/locale'
import { Card, CardBody, SearchCardTitleWrap, SearchCardSubhead } from '@/shared/components/Card'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import {
  SearchGroupLabel,
  SearchGroup,
  SearchContainer,
  SearchInputWrap,
  SearchButtonWrap,
  SearchGroupField,
  SearchSelect,
  SearchDatePickerWrap,
} from '@/shared/components/Search'
import { useSelector } from 'react-redux'
import OperationTabs from '../OperationTabs'
import NoteListData from './NoteListData'
import { convertToKst } from '../../../utils/dateTime'
import { searchNoteList, deleteNoteList } from '../../../utils/api/operation/operationApi'
import { NoteAuthoritySelectOptions, NoteReadStatusSelectOptions } from '../../../enums/Operation/NoteEnums'
import NoteInfoModal from './NoteInfoModal'
import Loading from '../../../shared/components/Loading'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import { decodeAccessToken } from '../../../utils/token'

const NoteList = () => {
  const authority = decodeAccessToken()?.adminAuthority
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  // 쪽지 자세히 보기 모달
  const [isNoteInfoModalShow, setNoteInfoModalShow] = useState(false)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(100)
  const [userAuthority, setUserAuthority] = useState(null)

  /**
   쪽지 조회
   */
  const [noteParams, setNoteParams] = useState({
    page,
    size,
    edgeName: null,
    userId: null,
    authority: null,
    status: null,
    startDate: null,
    endDate: null,
  })

  useEffect(() => {
    setNoteParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setNoteParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchNoteList = async () => {
    await searchNoteList(noteParams).then(res => {
      setContent(res.data.content)
      setTotalElement(res.data.totalElement)
    })
  }
  useEffect(() => {
    fetchSearchNoteList()
  }, [noteParams])

  const [noteInfo, setNoteInfo] = useState({})
  const onTitleClickRowHandler = () => {
    window.scrollTo(10, 0)
    setNoteInfoModalShow(true)
  }

  const [checkRadio, setCheckRadio] = useState([])

  const [noteListData, setNoteListData] = useState(
    NoteListData(content, fetchSearchNoteList, checkRadio, setCheckRadio, onTitleClickRowHandler),
  )
  useEffect(() => {
    setNoteListData(NoteListData(content, fetchSearchNoteList, checkRadio, setCheckRadio, onTitleClickRowHandler))
  }, [content, checkRadio])

  const [rows, setData] = useState(noteListData.tableRowsData)
  useEffect(() => {
    setData(noteListData.tableRowsData)
  }, [noteListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setNoteInfo(rowValue.original)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // 권한
  const [searchAuthority, setSearchAuthority] = useState('')

  const [searchAuthorityOptionValue, setSearchAuthorityOptionValue] = useState(null)
  const searchAuthorityOption = NoteAuthoritySelectOptions()

  const onSelectAuthorityOptionHandler = option => {
    if (option === null) {
      setSearchAuthorityOptionValue(null)
      setSearchAuthority(null)
      return
    }
    setSearchAuthorityOptionValue(option)
    setSearchAuthority(option.value)
  }

  // 작성자 ID
  const [searchUserId, setSearchUserId] = useState('')

  const onSearchUserIdHandler = e => {
    setSearchUserId(e.target.value)
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = NoteReadStatusSelectOptions()

  const onSelectStatusOptionHandler = option => {
    if (option === null) {
      setSearchStatusOptionValue(null)
      setSearchStatus(null)
      return
    }
    setSearchStatusOptionValue(option)
    setSearchStatus(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setNoteParams(prev => ({
      ...prev,
      page: 0,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      userId: !searchUserId ? null : searchUserId.trim(),
      status: !searchStatus ? null : searchStatus,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }
  // Tab Key
  const [activeKey, setActiveKey] = useState('4')

  const [deleteLoading, setDeleteLoading] = useState(false)
  // 삭제하기 버튼
  const onDeleteHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return
    if (checkRadio.length === 0) {
      alert('삭제 할 쪽지를 선택해주세요.')
      return
    }

    if (window.confirm('정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteNoteList({
        noteKeyList: checkRadio,
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
    }
  }

  // 삭제하기 버튼
  const deleteAllHandler = () => {
    if (authority === 'NORMAL') return
    if (deleteLoading) return

    if (window.confirm('쪽지 전체를 정말 삭제하시겠습니까?')) {
      setDeleteLoading(true)
      deleteNoteList({
        noteKeyList: [],
      })
        .then(res => {
          setDeleteLoading(false)
          alert('삭제가 완료 되었습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
        .catch(error => {
          setDeleteLoading(false)
          alert('삭제가 실패했습니다.')
          setCheckRadio([])
          fetchSearchNoteList()
        })
    }
  }

  return (
    <Card>
      <CardBody>
        <OperationTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 쪽지를 자세히 볼 수 있습니다.
            </p>
          </div>
        </SearchCardTitleWrap>
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchUserId"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchUserId}
                  onChange={e => onSearchUserIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상태</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchStatus"
                placeholder="상태"
                onChange={option => {
                  onSelectStatusOptionHandler(option)
                }}
                value={searchStatusOptionValue}
                options={searchStatusOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          {deleteLoading ? (
            <>
              <Loading />
            </>
          ) : (
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'lightGreen' }}
                variant="outline-secondary"
                onClick={() => {
                  setCheckRadio(content.map(value => value.noteKey))
                }}
              >
                전체선택
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: '#ff4f87', color: '#fff' }}
                variant="outline-secondary"
                onClick={() => {
                  onDeleteHandler()
                }}
              >
                삭제하기
              </Button>
              <Button
                size="sm"
                style={{ backgroundColor: 'black', fontWeight: '600', color: 'red', marginLeft: '315px' }}
                variant="outline-secondary"
                onClick={() => {
                  deleteAllHandler()
                }}
              >
                전체삭제
              </Button>
            </SearchButtonWrap>
          )}
        </SearchContainer>
        <ReactTableBase columns={noteListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        {/* 쪽지 자세히 보기 모달 */}
        <NoteInfoModal
          fetchSearchNoteList={fetchSearchNoteList}
          noteTitle={noteInfo?.noteTitle}
          sendContent={noteInfo?.sendContent}
          userId={noteInfo?.userId}
          isNoteInfoModalShow={isNoteInfoModalShow}
          setNoteInfoModalShow={setNoteInfoModalShow}
        />
      </CardBody>
    </Card>
  )
}

export default NoteList
