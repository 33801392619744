import { useEffect, useState } from 'react'
import axios from '../base/axios'

// 미니게임 실시간현황판 레디스 제거 API
export async function deleteRealTimeBettingRedis(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/realtime/betting-redis',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 세부 배팅통계 조회 API
export async function searchMemberBettingStatistics(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/betting/statistics',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅통계 조회 API
export async function searchMemberRollingInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/rolling-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 배팅통계 조회 API
export async function searchMemberLosingInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/losing-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 루징 정보 수정하기
 */
export async function updateMemberLosingInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/losing-info',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 롤링 정보 수정하기
 */
export async function updateMemberRollingInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/rolling-info',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 스포츠 배팅로그 조회 API
export async function searchSportsBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/betting/sports',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 스포츠 배팅로그 조회 API
export async function adminCancelSportsBettingLog(sportsBettingLogKey) {
  const response = await axios({
    url: `/tyson/admin/v1/log/management/betting/sports/cancel/${sportsBettingLogKey}`,
    method: 'PUT',
  }).then(res => {
    return res
  })

  return response
}

// 배팅로그 조회 API
export async function searchBetting(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/betting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchBetting(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchBetting() {
    await searchBetting(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchBetting()
  }, [params])

  return { totalElement, content, fetchSearchBetting }
}

// 실시간 현황판 - 카지노 배팅로그 조회 API
export async function searchRealTimeCasinoBetting(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/realtime/casino',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}
// 실시간 현황판 - 스포츠 배팅로그 조회 API
export async function searchRealTimeSportsBetting(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/realtime/sports',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 실시간 현황판 - 스포츠 배팅로그 조회 API
export async function searchRealTimeSportsBettingV2(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/realtime/sports/v2',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 실시간 현황판 - 가상게임 배팅로그 조회 API
export async function searchRealTimeVirtualGameBetting(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/realtime/virtual-game',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 실시간 현황판 - 미니게임 배팅로그 조회 API
export async function searchRealTimeMiniGameBetting(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/realtime/minigame',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchRealTimeMiniGameBetting(params) {
  const [miniGameTotalElement, setMiniGameTotalElement] = useState(0)
  const [miniGameContent, setMiniGameContent] = useState([])

  async function fetchSearchRealTimeMiniGameBetting() {
    await searchRealTimeMiniGameBetting(params).then(response => {
      setMiniGameTotalElement(response.data.totalElement)
      setMiniGameContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchRealTimeMiniGameBetting()
  }, [params])

  return { miniGameTotalElement, miniGameContent, fetchSearchRealTimeMiniGameBetting }
}

// 입출금로그 배팅키로 조회
export async function searchMoneyDepoWithdrawalByBettingLogKey(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/depo-withdrawal-bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅로그 배팅키로 조회
export async function searchSportsBettingByBettingLogKey(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/sports-bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅로그 배팅키로 조회
export async function searchSportsBettingByBettingLogKeyV2(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/sports-bettinginfo/v2',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 스포츠 배팅로그 배팅키로 조회
export async function searchVirtualGameBettingByBettingLogKey(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/virtual-game-bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })
  return response
}

// 미니게임 배팅로그 배팅키로 조회
export async function searchMiniGameBettingByBettingLogKey(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/mini-game-bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 카지노 배팅로그 배팅키로 조회
export async function searchCasinoBettingByBettingLogKey(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/casino-bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 레볼루션 배팅로그 배팅키로 조회
export async function searchHoldemBettingByBettingLogKey(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/holdem-bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 와일드게임즈 배팅로그 배팅키로 조회
export async function adminSearchWildGamesBettingInfoByBettingLogKey(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/history/point-deposit/wild-games-bettinginfo',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 미니게임 배팅로그 조회 API
export async function updateMiniGameBetting(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/minigame-betting',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 미니게임 배팅로그 조회 API
export async function searchMiniGameBetting(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/minigame-betting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMiniGameBetting(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMiniGameBetting() {
    await searchMiniGameBetting(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMiniGameBetting()
  }, [params])

  return { totalElement, content, fetchSearchMiniGameBetting }
}

/**
 * 미니게임 결과
 */
export async function searchMiniGameResult(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/result/minigame',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMiniGameResult(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchMiniGameResult() {
    await searchMiniGameResult(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMiniGameResult()
  }, [params])

  return { totalElement, content, fetchSearchMiniGameResult }
}

// 홀덤 배팅로그 조회 API
export async function searchHoldemBetting(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/holdem-betting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export async function downloadAssetIncDec(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/inc-dec-download',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

// 머니증감로그 조회 API
export async function searchAssetIncDec(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/inc-dec',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchAssetIncDec(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchAssetIncDec() {
    await searchAssetIncDec(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchAssetIncDec()
  }, [params])

  return { totalElement, content, fetchSearchAssetIncDec }
}

/**
 *  머니증감 정보 조회
 */
export async function searchMemberMoneyIncDecInfo(params) {
  const response = await axios({
    url: '/tyson/v1/asset/money-incdec-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberMoneyIncDecInfo(params) {
  const [memberMoneyIncDecInfoContent, setMemberMoneyIncDecInfoContent] = useState({})

  async function fetchSearchMemberMoneyIncDecInfo() {
    await searchMemberMoneyIncDecInfo(params).then(response => {
      setMemberMoneyIncDecInfoContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberMoneyIncDecInfo()
  }, [params])

  return { memberMoneyIncDecInfoContent, fetchSearchMemberMoneyIncDecInfo }
}

/**
 * 회원 머니증감 시키기
 */
export async function updateMemberAssetIncDecInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/asset-incdec',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 파트너 머니증감 시키기
 */
export async function updatePartnerAssetIncDecInfo(body) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/partner/asset-incdec',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 머니증감 시 - 회원 자산 조회
 */
export async function searchMemberAssetInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/member/money-incdec-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchMemberAssetInfo(params) {
  const [memberMoneyIncDecInfoContent, setMemberMoneyIncDecInfoContent] = useState([])

  async function fetchSearchMemberAssetInfo() {
    await searchMemberAssetInfo(params).then(response => {
      setMemberMoneyIncDecInfoContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchMemberAssetInfo()
  }, [params])

  return { memberMoneyIncDecInfoContent, fetchSearchMemberAssetInfo }
}

/**
 * 머니증감 시 - 파트너 자산 조회
 */
export async function searchPartnerAssetInfo(params) {
  const response = await axios({
    url: '/tyson/admin/v1/asset/partner/money-incdec-info',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchPartnerAssetInfo(params) {
  const [partnerMoneyIncDecInfoContent, setPartnerMoneyIncDecInfoContent] = useState([])

  async function fetchSearchPartnerAssetInfo() {
    await searchPartnerAssetInfo(params).then(response => {
      setPartnerMoneyIncDecInfoContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchPartnerAssetInfo()
  }, [params])

  return { partnerMoneyIncDecInfoContent, fetchSearchPartnerAssetInfo }
}

// 시스템 로그 조회
export async function searchSystemLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/system/management/log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchSystemLog(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchSystemLog() {
    await searchSystemLog(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchSystemLog()
  }, [params])

  return { totalElement, content, fetchSearchSystemLog }
}

// 시스템 로그 생성 API
export async function createSystemLog(body) {
  const response = await axios({
    url: '/tyson/admin/v1/operation/management/system',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 쿠폰지급 로그 조회
export async function searchCouponPayLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/coupon-payment-log',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchCouponPayLog(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchCouponPayLog() {
    await searchCouponPayLog(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchCouponPayLog()
  }, [params])

  return { totalElement, content, fetchSearchCouponPayLog }
}

// 머니증감로그 조회 API
export async function searchDuplicationIp(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/check/duplication',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchDuplicationIp(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const [excludeIpList, setExcludeIpList] = useState('')

  async function fetchSearchDuplicationIp() {
    await searchDuplicationIp(params).then(response => {
      setContent(response.data.content)
      setTotalElement(response.data.totalElement)
      setExcludeIpList(response.data.excludeIpList)
    })
  }

  useEffect(() => {
    fetchSearchDuplicationIp()
  }, [params])

  return { totalElement, content, excludeIpList, fetchSearchDuplicationIp }
}

export async function updateRestrictionIp(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/restriction',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

export async function updateDuplicationIp(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/duplication',
    method: 'POST',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

// 미니게임 배팅로그 조회 API
export async function searchRestrictionIP(params) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/restriction',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

export function useSearchRestrictionIP(params) {
  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])

  async function fetchSearchRestrictionIP() {
    await searchRestrictionIP(params).then(response => {
      setTotalElement(response.data.totalElement)
      setContent(response.data.content)
    })
  }

  useEffect(() => {
    fetchSearchRestrictionIP()
  }, [params])

  return { totalElement, content, fetchSearchRestrictionIP }
}

export async function deleteRestrictionIp(body) {
  const response = await axios({
    url: '/tyson/admin/v1/member/management/restriction',
    method: 'DELETE',
    data: body,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 토큰 게임
 */
export async function searchTokenGameBettingLog(params) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/tokengame-betting',
    method: 'GET',
    params,
  }).then(res => {
    return res
  })

  return response
}

/**
 * 토큰게임 - 배팅 결과 수정
 */

export async function updateTokenGameBettingResult(body) {
  const response = await axios({
    url: '/tyson/admin/v1/log/management/tokengame-betting/betResult',
    method: 'PUT',
    data: body,
  }).then(res => {
    return res
  })

  return response
}
