import { Card, CardBody, SearchCardTitleWrap, CustomAuthorityButton } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import {
  BettingResultStatusEnumsSelectOptions,
  BettingResultStatusEnumsSelectOptions2,
  sportResultStatusEnums,
} from '../../../../../enums/SystemManagement/sportEnums'
import CustomPagination from '../../../../../shared/components/customPagination/CustomPagination'
import { convertToKst, convertToKstSportsEventDateTime } from '../../../../../utils/dateTime'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import SportsDetailBettingContent from './MemberSportsDetailBettingHistory'
import { searchSportsBettingLog } from '../../../../../utils/api/logManage/logApi'
import { NoteAuthorityEnums } from '../../../../../enums/Operation/NoteEnums'

const MemberSportsBettingHistory = ({ memberId }) => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  /**
   배팅로그 조회
   */
  const [bettingLogparams, setBettingLogParams] = useState({
    page,
    size,
    memberId,
    isExact: true,
  })

  useEffect(() => {
    setBettingLogParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const handlePageChange = pageNumber => {
    setPage(pageNumber)
  }

  const [totalElement, setTotalElement] = useState(0)
  const [content, setContent] = useState([])
  const fetchSearchSportsMarket = async () => {
    await searchSportsBettingLog(bettingLogparams).then(res => {
      const newContent = res.data.content.map(item => ({
        ...item,
        isVisible: false,
      }))
      setContent(newContent)
      setTotalElement(res.data.totalElement)
    })
  }

  useEffect(() => {
    fetchSearchSportsMarket()
  }, [bettingLogparams])

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / size))
  }, [totalElement, size])

  const onPageChange = pageNumber => {
    setPage(pageNumber)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = BettingResultStatusEnumsSelectOptions2()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setBettingLogParams(prev => ({
      ...prev,
      sportsResultStatus: !searchGameResult ? null : searchGameResult,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      page: 0,
    }))
  }

  // 검색하기 버튼
  const onSearchBettingHistoryHandler = (num, bettingLogKey) => {
    const newContent = [...content]
    const hang = newContent.find(item => item.sportsBettingLogKey === bettingLogKey)
    if (hang) {
      hang.isVisible = !hang.isVisible
      setContent(newContent)
    }
  }

  return (
    <Card>
      <CardBody style={{ padding: '0px' }}>
        {/* 검색 기능 */}
        <SearchCardTitleWrap>
          <div style={{ marginBottom: '10px' }}>
            <p>* 행을 클릭하면, 해당 회원의 자세한 배팅내역을 볼 수 있습니다.</p>
          </div>
        </SearchCardTitleWrap>
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>게임결과</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchGameResult"
                placeholder="결과"
                onChange={option => {
                  onSelectGameResultOptionHandler(option)
                }}
                value={searchGameResultOptionValue}
                options={searchGameResultOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchStartDate}
                  onChange={date => onSearchStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchEndDate}
                  onChange={date => onSearchEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  showTimeInput
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  locale={ko}
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <SportsGameListTableWrap>
          <SportsGameListTable>
            <SportsGameListTableHead>
              <SportsGameListTableTr>
                <th style={{ width: '5%' }}>번호</th>
                <th style={{ width: '8%' }}>배팅일시</th>
                <th style={{ width: '13%' }}>유저ID(명) - 권한</th>
                <th style={{ width: '13%' }}>상위파트너명</th>
                <th style={{ width: '10%' }}>배팅전 머니</th>
                <th style={{ width: '11%', color: 'red' }}>배팅액</th>
                <th style={{ width: '10%' }}>배팅후 머니</th>
                <th style={{ width: '5%' }}>폴더</th>
                <th style={{ width: '5%' }}>배당</th>
                <th style={{ width: '13%' }}>예상/최종 당첨금</th>
                <th style={{ width: '7%' }}>결과</th>
              </SportsGameListTableTr>
            </SportsGameListTableHead>
            {content.map((item, index) => (
              <SportsGameListTableBody key={item.sportsBettingLogKey}>
                <SportsGameListTableTr>
                  <SportsGameFolderContentBox>
                    <SportsGameFolderNumBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.num}
                    </SportsGameFolderNumBox>
                    <SportsGameFolderContentTime
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {convertToKstSportsEventDateTime(item.bettingTime)}
                    </SportsGameFolderContentTime>
                    <SportsGameFolderContentHomeBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentHomeName>
                        {item.memberId} - &nbsp;
                        <CustomAuthorityButton authority={item.authority}>
                          {NoteAuthorityEnums[item.authority]}
                        </CustomAuthorityButton>
                      </SportsGameFolderContentHomeName>
                    </SportsGameFolderContentHomeBox>
                    <SportsGamePartnerBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentHomeName>{item.topPartnerName}</SportsGameFolderContentHomeName>
                    </SportsGamePartnerBox>
                    <SportsGameFolderContentCriteria
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingBeforeAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderContentCriteria>
                    <SportsGameFolderContentCriteria2
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderContentCriteria2>
                    <SportsGameFolderContentCriteria
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingAfterAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderContentCriteria>
                    <SportsGameFolderContentAwayBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <SportsGameFolderContentAwayPer>{item.folderSize}폴더</SportsGameFolderContentAwayPer>
                    </SportsGameFolderContentAwayBox>
                    <SportsGameFolderScoreBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.totalOddValue}
                    </SportsGameFolderScoreBox>
                    <SportsGameFolderResultBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      {item.bettingExpectedAmount.toString().replace(commonReg2, ',')} &nbsp; / &nbsp;
                      {item.bettingResultAmount.toString().replace(commonReg2, ',')}
                    </SportsGameFolderResultBox>
                    <SportsGameFolderManualResultBox
                      onClick={() => {
                        onSearchBettingHistoryHandler(item.num, item.sportsBettingLogKey)
                      }}
                    >
                      <CustomButton type="button" bettingResult={item.resultStatus}>
                        {sportResultStatusEnums[item.resultStatus]}
                      </CustomButton>
                    </SportsGameFolderManualResultBox>
                  </SportsGameFolderContentBox>
                </SportsGameListTableTr>
                {item.isVisible && (
                  <SportsGameListTableTr>
                    <SportsDetailBettingContent content={item.sportsDetailList} />
                  </SportsGameListTableTr>
                )}
              </SportsGameListTableBody>
            ))}
          </SportsGameListTable>
          <PaginationWrap>
            <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
          </PaginationWrap>
        </SportsGameListTableWrap>
      </CardBody>
    </Card>
  )
}

export default MemberSportsBettingHistory

MemberSportsBettingHistory.propTypes = {
  memberId: PropTypes.string.isRequired,
}
const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const SportsGameListTableWrap = styled.div`
  width: 100%;

  th {
    padding: 4px 4px;
    border: 1px solid #888;
    white-space: nowrap;
    color: black;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
  }
`

const SportsGameListTableHead = styled.thead``

const SportsGameListTableBody = styled.tbody`
  gap: 10px;
`

const SportsGameListTableTr = styled.tr`
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: solid 1px gray;
  background: lightgrey;
  &:hover {
    border: solid 2px red;
    cursor: pointer;
  }
`

const SportsGameListTable = styled.table`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SportsGameFolderContentAwayBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderNumBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderScoreBox = styled.div`
  width: 5%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderResultBox = styled.div`
  width: 13%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: red;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderManualResultBox = styled.div`
  width: 7%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentCriteria2 = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGameFolderContentCriteria = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: yellow;
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`
const SportsGameFolderContentHomeBox = styled.div`
  width: 13%;
  display: flex;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
`

const SportsGamePartnerBox = styled.div`
  width: 13%;
  display: flex;
`

const SportsGameFolderContentHomeName = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentAwayPer = styled.div`
  display: flex;
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`

const SportsGameFolderContentTime = styled.div`
  font-size: 13px;
  color: white;
  width: 8%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameAuthorityBox = styled.div`
  font-size: 13px;
  color: white;
  width: 5%;
  display: flex;
  border-right: solid 1px gray;
  text-align: center;
  align-items: center;
  font-weight: 500;
  justify-content: center;
`

const SportsGameFolderContentBox = styled.div`
  width: 100%;
  display: flex;
  background: #161616;
  height: 36px;
`
