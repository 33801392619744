import ReactTableBase from '@/shared/components/table/ReactTableBase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import SportsDetailBettingLogListData from './SportsDetailBettingLogListData'

const SportsDetailBettingContent = ({ content, fetchSearchSportsMarket }) => {
  const [bettingLogListData, setBettingLogListData] = useState(SportsDetailBettingLogListData(content))
  useEffect(() => {
    setBettingLogListData(SportsDetailBettingLogListData(content, fetchSearchSportsMarket))
  }, [content])

  const [rows, setData] = useState(bettingLogListData.tableRowsData)
  useEffect(() => {
    setData(bettingLogListData.tableRowsData)
  }, [bettingLogListData])

  return (
    <SportsGameListHiddenWrap>
      <ReactTableBase columns={bettingLogListData.tableHeaderData} data={rows} />
    </SportsGameListHiddenWrap>
  )
}

export default SportsDetailBettingContent

SportsDetailBettingContent.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape()),
  fetchSearchSportsMarket: PropTypes.func,
}

SportsDetailBettingContent.defaultProps = {
  content: [],
  fetchSearchSportsMarket: () => {},
}

const CustomReactTableBase = styled(ReactTableBase)`
  background: red !important;
  table {
    thead th {
      background: black !important;
      color: #fff !important;
    }
  }
`

const SportsGameListHiddenWrap = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  th {
    background: black !important;
    color: #fff !important;
  }
  tbody {
    tr {
      background: #fbf7ea !important;
    }
  }
`
