import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'

const MemberStatisticsBettingVirtualGame = ({ virtualGame }) => {
  const getResultAmount = (num1, num2) => {
    const resultAmount = Number(num1) - Number(num2)

    if (resultAmount > 0) {
      return (
        <span style={{ color: 'blue', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
      )
    }
    if (resultAmount < 0) {
      return <span style={{ color: 'red', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
    }
    return <span style={{ color: 'black', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
  }
  return (
    <MemberInfoWrap>
      <table>
        <tbody>
          <tr>
            <th>오늘 배팅액</th>
            <td>{virtualGame.dailyVirtualGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 당첨액</th>
            <td>{virtualGame.dailyVirtualGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 롤링액(본인)</th>
            <td>{virtualGame.dailyVirtualGameRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>오늘 차액(배팅-당첨)</th>
            <td>
              {getResultAmount(virtualGame.dailyVirtualGameBettingAmount, virtualGame.dailyVirtualGameWinningAmount)}
            </td>
          </tr>
          <tr>
            <th>이번달 배팅액</th>
            <td>{virtualGame.monthVirtualGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 당첨액</th>
            <td>{virtualGame.monthVirtualGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 롤링액(본인)</th>
            <td>{virtualGame.monthVirtualGameRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>이번달 차액(배팅-당첨)</th>
            <td>
              {getResultAmount(virtualGame.monthVirtualGameBettingAmount, virtualGame.monthVirtualGameRollingAmount)}
            </td>
          </tr>
          <tr>
            <th>기간내 배팅액</th>
            <td>{virtualGame.dateVirtualGameBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 당첨액</th>
            <td>{virtualGame.dateVirtualGameWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 롤링액(본인)</th>
            <td>{virtualGame.dateVirtualGameRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>기간내 차액(배팅-당첨)</th>
            <td>
              {getResultAmount(virtualGame.dateVirtualGameBettingAmount, virtualGame.dateVirtualGameWinningAmount)}
            </td>
          </tr>
        </tbody>
      </table>
    </MemberInfoWrap>
  )
}

export default MemberStatisticsBettingVirtualGame

MemberStatisticsBettingVirtualGame.propTypes = {
  virtualGame: PropTypes.shape({
    dailyVirtualGameBettingAmount: PropTypes.string,
    dailyVirtualGameWinningAmount: PropTypes.string,
    dailyVirtualGameRollingAmount: PropTypes.string,
    dailyVirtualGameProfitAmount: PropTypes.string,
    monthVirtualGameBettingAmount: PropTypes.string,
    monthVirtualGameWinningAmount: PropTypes.string,
    monthVirtualGameRollingAmount: PropTypes.string,
    monthVirtualGameProfitAmount: PropTypes.string,
    dateVirtualGameBettingAmount: PropTypes.string,
    dateVirtualGameWinningAmount: PropTypes.string,
    dateVirtualGameRollingAmount: PropTypes.string,
    dateVirtualGameProfitAmount: PropTypes.string,
  }).isRequired,
}

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 150px;
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: grey !important;
    padding: 10px 4px;
    border: 1px solid black;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
