import { borderLeft, borderRight, left, marginLeft, marginRight, paddingLeft } from '@/utils/directions'
import {
  colorAccent,
  colorAccentHover,
  colorBackground,
  colorBlue,
  colorBorder,
  colorDustyWhite,
  colorFieldsBorder,
  colorHover,
  colorIcon,
  colorLightAccent,
  colorText,
  colorWhite,
  scrollbarStyles,
} from '@/utils/palette'
import Select from 'react-select'
import styled from 'styled-components'
import 'react-datepicker/dist/react-datepicker.css'
import 'rc-time-picker/assets/index.css'

/** =======================================================
 * 검색 관련 styled Component
 ======================================================= */

export const SearchGroup = styled.div`
  margin: 5px 5px 0 5px;
  // width: 150px;
  position: relative;
`

export const SearchGroupLabel = styled.span`
  margin-bottom: 2px;
  font-weight: 500;
  display: inline-block;
  color: ${colorText};

  span {
    color: ${colorDustyWhite};
  }
`

export const SearchGroupField = styled.div`
  width: 100%;
  display: flex;
  // margin: auto;
`

export const SearchContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: ${left};
  // ${marginRight}: 16px;
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 2px solid ${colorFieldsBorder};

  textarea {
    min-height: 85px;
  }

  ${props =>
    props.horizontal &&
    `

    ${SearchGroup} {
      display: flex;
      flex-wrap: wrap;
    }

    ${SearchGroupField} {
      // width: calc(100% - 80px);
      width: 135px;
      // ${paddingLeft(props)}: 10px;
      // ${marginLeft(props)}: 80px;
    }

    ${SearchGroupLabel} {
      max-height: 32px;
      line-height: 18px;
      margin: auto 10px auto 3px;

      & + ${SearchGroupField} {
        ${marginLeft(props)}: 0;
      }
    }

  `}
`

export const SearchSelect = styled(Select)`
  width: 100%;
  height: 40px;
  margin-right: 5px;
  font-size: 12px;
  border: solid 1px black;
  .react-select__control {
    height: 32px;
    border-radius: 0 !important;
    transition: all 0.3s;
    border: 1px solid ${colorFieldsBorder};
    background-color: ${colorBackground};

    &.react-select__control--is-focused,
    &:hover {
      border-color: ${colorAccent} !important;
      box-shadow: none;
      background: transparent;
    }
  }

  .react-select__input {
    height: 30px;
    color: ${colorText};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator,
  .react-select__clear-indicator {
    cursor: pointer;
    color: ${colorIcon};

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .react-select__multi-value {
    background-color: transparent;
    border: 1px solid ${colorBlue};

    .react-select__multi-value__label {
      padding: 3px 6px;
      ${borderRight}: 1px solid ${colorBlue};
      color: ${colorText};
    }
  }

  .react-select__multi-value__remove {
    opacity: 0.8;
    transition: 0.3s;
    cursor: pointer;
    color: ${colorText};
  }

  .react-select__multi-value__label,
  .react-select__multi-value__remove {
    background: ${colorBackground};
  }

  .react-select__single-value {
    color: ${colorText};
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .react-select__menu-list {
    top: calc(100% + 1px);
    border-radius: 0;
    box-shadow: none;
    font-size: 12px;
    // overflow: hidden;
    overflow-y: auto;
    background: ${colorBackground};
    border: 1px solid ${colorFieldsBorder};
  }

  @keyframes open {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 200px;
    }
  }

  .react-select__placeholder {
    font-size: 12px;
    margin-top: -2px;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;

    & > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

export const SearchInputWrap = styled.div`
  display: flex;
  width: 100%;
  // ${marginRight}: 16px;

  input {
    height: 38px;
    transition: all 0.3s;
    border: 1px solid black;
    width: 100%;
    // ${paddingLeft}: 10px;
    color: ${colorText};

    &:focus {
      border-color: ${colorAccent};
      outline: none;
    }

    &::-webkit-input-placeholder {
      color: ${colorIcon};
    }
    &::-moz-placeholder {
      color: ${colorIcon};
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: ${colorIcon};
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: ${colorIcon};
    }
  }
`

export const SearchButtonWrap = styled.div`
  display: flex;
  margin: 4px 5px 0 5px;
  // ${marginRight}: 16px;

  button {
    height: 39px;
    transition: all 0.3s;
    border: 1px solid #dee0e3;
    ${paddingLeft}: 10px;
    color: ${colorText};
    background: ${colorHover};
    margin-right: 10px;

    &:focus {
      border-color: ${colorAccent};
      outline: none;
    }
`

// DatePicker
export const SearchDatePickerWrap = styled.div`
  border-radius: 4px;
  color: #646777;
  outline: none;
  font-size: 12px;
  height: 38px;
  transition: border 0.3s;
  background: transparent;

  input {
    width: 140px;
    height: 38px;
    border: 1px solid black;
  }

  &[disabled] {
    background-color: #d8dae2;
    color: #b1b2bb;
  }

  .react-datepicker {
    display: block !important;
  }

  .react-datepicker__input-time-container {
    background: ${colorBackground};
    padding: 10px 15px;
    margin: 0;
    color: ${colorText};
  }

  & > div,
  .react-datepicker-wrapper,
  .rc-time-picker,
  .react-datepicker__input-container {
    width: 100%;
    // height: 38px;
  }

  .react-datepicker__time-list {
    padding: 0;
    overflow-x: hidden;

    ${scrollbarStyles};
  }

  .react-datepicker__time-list-item {
    width: 50px;
  }

  .react-datepicker__time {
    overflow: hidden;
    border-radius: 0 !important;
  }

  .date-picker__svg {
    width: 14px;
    height: 14px;
    margin: auto 10px;
    fill: ${colorText};
  }

  .react-datepicker__header {
    background-color: ${colorAccent};
    border-color: ${colorAccent};
    border-radius: 0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: ${colorWhite};
  }

  .react-datepicker__navigation {
    top: 11px;
  }

  .react-datepicker__navigation--next {
    right: 2px;

    border-left-color: ${colorWhite};

    &:hover {
      border-left-color: ${colorWhite};
    }
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colorWhite};

    &:hover {
      border-right-color: ${colorWhite};
    }
  }

  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent};
    transition: all 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle {
    border-bottom-color: ${colorAccent};
    margin-top: -7px;
  }

  .react-datepicker {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border: none;
    display: inline-flex;
  }

  .react-datepicker__time-container {
    ${borderLeft}: 1px solid ${colorBorder};
    width: 85px !important;
  }

  .react-datepicker__time-box {
    width: 85px !important;
    border-radius: 0 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-range,
  .react-datepicker__time-list-item--selected {
    background-color: ${colorAccentHover} !important;
  }

  .react-datepicker__month-container,
  .react-datepicker__time-list {
    background-color: ${colorBackground} !important;
  }

  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorAccent} !important;
    color: ${colorWhite} !important;
  }

  .react-datepicker__day--weekend,
  .react-datepicker__day,
  .react-datepicker__time-list {
    color: ${colorText};
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: ${colorHover} !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: ${colorLightAccent};
    border-radius: 50%;

    &:hover {
      border-radius: 50%;
    }

    &:hover {
      background-color: ${colorAccentHover} !important;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: ${colorAccent};
  }

  .react-datepicker__month-text {
    color: ${colorText};
    transition: 0.3s;

    &:hover {
      background-color: ${colorHover};
    }
  }

  .react-datepicker__month-text--keyboard-selected {
    background-color: ${colorAccent};
    border-radius: 0.3rem;
    transition: 0.3s;

    &:hover {
      background-color: ${colorAccentHover};
    }
  }

  .react-datepicker-time__input {
    outline-color: ${colorAccent};

    &::-webkit-calendar-picker-indicator {
      filter: ${props => (props.theme.mode === 'dark' ? 'invert(1)' : 'none')};
    }
  }

  .react-datepicker-popper {
    z-index: 100;
  }

  .react-datepicker__close-icon:after {
    background-color: ${colorAccent};
  }
`

export const MoneyIncDecInputWrap = styled(SearchInputWrap)`
  width: 130px;
  input {
    border: solid 1px black;
  }
`
/** =========================================================
 *  회원 조회 - 머니로그 Search 커스텀 - START
 *  ========================================================= */

export const MemberMoneyLogSearchContainer = styled(SearchContainer)`
  ${props =>
    props.horizontal &&
    `

  ${SearchGroupField} {
    width: 120px;
  }
`}
`
/** =========================================================
 *  회원 조회 - 머니로그 Search 커스텀 - START
 *  ========================================================= */

/** =========================================================
 *  회원 조회 - 배팅로그 Search 커스텀 - START
 *  ========================================================= */

export const MemberBettingLogSearchContainer = styled(SearchContainer)`
  ${props =>
    props.horizontal &&
    `

 ${SearchGroupField} {
   width: 120px;
 }
`}
`
/** =========================================================
 *  회원 조회 - 배팅로그 Search 커스텀 - START
 *  ========================================================= */
