import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'
import { colorGray } from '@/utils/palette'

const TooltipWrapper = ({ dir, text, textnd, textrd, children, ...other }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  return (
    <CustomDiv onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <OverlayTrigger
        show={showTooltip}
        overlay={
          <StyledTooltip dir={dir}>
            {text}
            {textnd && (
              <>
                <br /> {textnd}
              </>
            )}
            {textrd && (
              <>
                <br /> {textrd}
              </>
            )}
          </StyledTooltip>
        }
        {...other}
      >
        {children}
      </OverlayTrigger>
    </CustomDiv>
  )
}

TooltipWrapper.propTypes = {
  dir: PropTypes.string,
  text: PropTypes.string.isRequired,
  textnd: PropTypes.string,
  textrd: PropTypes.string,
  children: PropTypes.node.isRequired,
}

TooltipWrapper.defaultProps = {
  dir: 'ltr',
  textnd: '',
  textrd: '',
}

export default TooltipWrapper

// region STYLES

const CustomDiv = styled.div`
  position: relative;
  display: inline-block;
`

const StyledTooltip = styled(BootstrapTooltip)`
  z-index: 1046;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  background-color: ${colorGray};
  font-size: 12px;
  line-height: 14px;
  padding: 5px 15px;
  min-width: 50px;
  max-width: 550px !important;
  min-height: 30px;

  .tooltip-inner {
    max-width: 100% !important;
  }

  &.bs-tooltip-end .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='end'] .tooltip-arrow::before {
    border-right-color: ${colorGray};
  }

  &.bs-tooltip-top .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='top'] .tooltip-arrow::before {
    border-top-color: ${colorGray};
  }

  &.bs-tooltip-bottom .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='bottom'] .tooltip-arrow::before {
    border-bottom-color: ${colorGray};
  }

  &.bs-tooltip-start .tooltip-arrow::before,
  &.bs-tooltip-auto[x-placement^='start'] .tooltip-arrow::before {
    border-left-color: ${colorGray};
  }
`

// endregion
