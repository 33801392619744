import PropTypes from 'prop-types'
import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BorderedBottomTabs, NavItem, NavLink, TabPaneInnerWrap, TabsWrap } from '../../../shared/components/Tabs'
import { Card, CardBody } from '../../../shared/components/Card'
import SportManagementTabs from '../SportManagementTabs'
import ManualSportsManagement from './ManualSportsManagement'
import ManualSportsList from './ManualSportsList'

const ManualSportsManagementTabs = ({ activeKey }) => {
  return (
    <Card>
      <CardBody>
        <SportManagementTabs activeKey="9" />
        <BorderedBottomTabs>
          <Tab.Container defaultActiveKey="1">
            <TabsWrap>
              <Nav className="nav-tabs">
                <NavItem>
                  <NavLink eventKey="1">수동 마켓 조회</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink eventKey="2">수동 마켓 등록</NavLink>
                </NavItem>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <TabPaneInnerWrap style={{ marginTop: '0px' }}>
                    <ManualSportsList />
                  </TabPaneInnerWrap>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <TabPaneInnerWrap style={{ marginTop: '0px' }}>
                    <ManualSportsManagement />
                  </TabPaneInnerWrap>
                </Tab.Pane>
              </Tab.Content>
            </TabsWrap>
          </Tab.Container>
        </BorderedBottomTabs>
      </CardBody>
    </Card>
  )
}

export default ManualSportsManagementTabs

ManualSportsManagementTabs.propTypes = {
  activeKey: PropTypes.string.isRequired,
}
