export const MoneyChangeLogSortEnums = {
  DEPOSIT: '입금',
  WITHDRAWAL: '출금',
  POINT_EXCHANGE: '포인트 전환(롤링/콤프)',
  LOSING_POINT_EXCHANGE: '루징포인트 전환',
  CASINO_MONEY_EXCHANGE: '카지노머니 전환',
  BETTING: '배팅',
  BETTING_CANCEL: '배팅취소',
  BETTING_WIN: '당첨',
  BETTING_DRAW: '적특',
  BETTING_AGAIN_SETTLEMENT: '재정산',
  ADMIN_INC: '관리자 지급',
  ADMIN_DEC: '관리자 회수',
  PARTNER_INC: '상부 지급',
  PARTNER_DEC: '상부 회수',
  DOWN_USER_INC: '하부 지급',
  DOWN_USER_DEC: '하부 회수',
  COUNPON_USE: '쿠폰 사용',
  RESET: '초기화',
}

export const MoneyChangeLogAssetTypeEnums = {
  NORMAL_MONEY: '일반머니',
  HOLDEM_MONEY: '홀덤머니',
}

export const MoneyChangeLogSortEnumsSelectOptions = () => {
  return Object.entries(MoneyChangeLogSortEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
