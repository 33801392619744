import PropTypes from 'prop-types'
import React from 'react'
import MemberHistoryBettingCasino from './MemberHistoryBettingCasino'
import MemberHistoryBettingMini from './MemberHistoryBettingMini'
import MemberSportsBettingHistory from './MemberSportsBettingHistory'
import MemberHistoryBettingHoldem from './MemberHistoryBettingHoldem'
import MemberSportsBettingHistoryV2 from './MemberSportsBettingHistoryV2'
import MemberVirtualGameBettingHistory from './MemberVirtualGameBettingHistory'
import MemberHistoryBettingHoldemDetail from './MemberHistoryBettingHoldemDetail'
import MemberHistoryBettingHoldemBuyInOut from './MemberHistoryBettingHoldemBuyInOut'
import MemberWildGamesBettingHistory from './MemberWildGamesBettingHistory'
import MemberWildGamesDetailBettingHistory from './MemberWildGamesDetailBettingHistory'
import MemberWildGamesGameEventHistory from './MemberWildGamesGameEventHistory'

const MemberHistoryBetting = ({ memberId }) => {
  return (
    <>
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>라이브&슬롯 배팅로그</h3>
      <MemberHistoryBettingCasino memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>스포츠 배팅로그</h3>
      <MemberSportsBettingHistoryV2 memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>미니게임 배팅로그</h3>
      <MemberHistoryBettingMini memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>가상게임 배팅로그</h3>
      <MemberVirtualGameBettingHistory memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>레볼루션 배팅로그</h3>
      <MemberHistoryBettingHoldem memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>레볼루션 배팅 세부로그</h3>
      <MemberHistoryBettingHoldemDetail memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>레볼루션 바이인/아웃 로그</h3>
      <MemberHistoryBettingHoldemBuyInOut memberId={memberId} />

      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>와일드게임즈 배팅로그</h3>
      <MemberWildGamesBettingHistory memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>와일드게임즈 배팅 세부로그</h3>
      <MemberWildGamesDetailBettingHistory memberId={memberId} />
      <h3 style={{ margin: '15px 0', fontWeight: '500' }}>와일드게임즈 게임 이벤트 로그</h3>
      <MemberWildGamesGameEventHistory memberId={memberId} />
    </>
  )
}

export default MemberHistoryBetting

MemberHistoryBetting.propTypes = {
  memberId: PropTypes.string.isRequired,
}
