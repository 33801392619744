import { Button } from '@/shared/components/Button'
import Error from '@/shared/components/form/Error'
import React, { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FormButtonToolbar } from '../../../shared/components/form/FormElements'
import Loading from '../../../shared/components/Loading'
import {
  searchFriendCompLevel,
  searchMiniGameLevel,
  updateFriendCompLevel,
  updateMiniGameLevel,
} from '../../../utils/api/systemManage/levelManageApi'
import { decodeAccessToken } from '../../../utils/token'
import { friendComplevelValidate, miniGamelevelValidate } from '../../../utils/validate/systemManagement/levelManage'
import { colorAdditional, colorFieldsBorder } from '../../../utils/palette'

const FriendLevelForm = ({ selectedLevel }) => {
  const authority = decodeAccessToken()?.adminAuthority
  const [writeLoading, setWriteLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [friendCompLevelName, setFriendCompLevelName] = useState('')
  const [friendCompMaxAmount, setFriendCompMaxAmount] = useState('0')
  const [liveCasinoFriendBenefitBettingPer, setLiveCasinoFriendBenefitBettingPer] = useState('0')
  const [slotCasinoFriendBenefitBettingPer, setSlotCasinoFriendBenefitBettingPer] = useState('0')
  const [miniGameFriendBenefitBettingPer, setMiniGameFriendBenefitBettingPer] = useState('0')
  const [miniGameFriendBenefitLossPer, setMiniGameFriendBenefitLossPer] = useState('0')
  const [sportsFriendBenefitBettingPer, setSportsFriendBenefitBettingPer] = useState('0')
  const [sportsFriendBenefitLossPer, setSportsFriendBenefitLossPer] = useState('0')
  const [holdemFriendBenefitBettingPer, setHoldemFriendBenefitBettingPer] = useState('0')
  const [holdemFriendBenefitLossPer, setHoldemFriendBenefitLossPer] = useState('0')
  const [virtualGameFriendBenefitBettingPer, setVirtualGameFriendBenefitBettingPer] = useState('0')
  const [virtualGameFriendBenefitLossPer, setVirtualGameFriendBenefitLossPer] = useState('0')

  const fetchFriendCompLevel = () => {
    if (loading) return

    setLoading(true)

    searchFriendCompLevel({
      friendCompLevel: selectedLevel,
    })
      .then(res => {
        const {
          friendCompLevelName,
          friendCompMaxAmount,
          liveCasinoFriendBenefitBettingPer,
          slotCasinoFriendBenefitBettingPer,
          miniGameFriendBenefitBettingPer,
          miniGameFriendBenefitLossPer,
          sportsFriendBenefitBettingPer,
          sportsFriendBenefitLossPer,
          holdemFriendBenefitBettingPer,
          holdemFriendBenefitLossPer,
          virtualGameFriendBenefitBettingPer,
          virtualGameFriendBenefitLossPer,
        } = res.data
        setFriendCompLevelName(friendCompLevelName)
        setFriendCompMaxAmount(friendCompMaxAmount)
        setLiveCasinoFriendBenefitBettingPer(liveCasinoFriendBenefitBettingPer)
        setSlotCasinoFriendBenefitBettingPer(slotCasinoFriendBenefitBettingPer)
        setMiniGameFriendBenefitBettingPer(miniGameFriendBenefitBettingPer)
        setMiniGameFriendBenefitLossPer(miniGameFriendBenefitLossPer)
        setSportsFriendBenefitBettingPer(sportsFriendBenefitBettingPer)
        setSportsFriendBenefitLossPer(sportsFriendBenefitLossPer)
        setHoldemFriendBenefitBettingPer(holdemFriendBenefitBettingPer)
        setHoldemFriendBenefitLossPer(holdemFriendBenefitLossPer)
        setVirtualGameFriendBenefitBettingPer(virtualGameFriendBenefitBettingPer)
        setVirtualGameFriendBenefitLossPer(virtualGameFriendBenefitLossPer)
        setLoading(false)
      })
      .catch(error => {
        setFriendCompLevelName('')
        setFriendCompMaxAmount('0')
        setLiveCasinoFriendBenefitBettingPer('0')
        setSlotCasinoFriendBenefitBettingPer('0')
        setMiniGameFriendBenefitBettingPer('0')
        setMiniGameFriendBenefitLossPer('0')
        setSportsFriendBenefitBettingPer('0')
        setSportsFriendBenefitLossPer('0')
        setHoldemFriendBenefitBettingPer('0')
        setHoldemFriendBenefitLossPer('0')
        setVirtualGameFriendBenefitBettingPer('0')
        setVirtualGameFriendBenefitLossPer('0')
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchFriendCompLevel()
  }, [selectedLevel])

  const onSubmit = e => {
    if (writeLoading) return

    if (window.confirm('추천인 콤프 레벨 설정을 변경 하시겠습니까? ')) {
      const body = {
        friendCompLevelName,
        friendCompMaxAmount,
        liveCasinoFriendBenefitBettingPer,
        slotCasinoFriendBenefitBettingPer,
        miniGameFriendBenefitBettingPer,
        miniGameFriendBenefitLossPer,
        sportsFriendBenefitBettingPer,
        sportsFriendBenefitLossPer,
        holdemFriendBenefitBettingPer,
        holdemFriendBenefitLossPer,
        virtualGameFriendBenefitBettingPer,
        virtualGameFriendBenefitLossPer,
      }

      setWriteLoading(true)

      updateFriendCompLevel(body)
        .then(res => {
          setWriteLoading(false)
          alert('추천인 콤프 레벨 설정이 변경됐습니다.')
          fetchFriendCompLevel()
        })
        .catch(error => {
          setWriteLoading(false)
          const { errorCode, message } = error.response.data
          if (errorCode === 'EVENT-1004') {
            alert(`${message}`)
          } else {
            alert('추천인 콤프 레벨 설정 변경을 실패하였습니다. 다시 시도해주세요.')
          }
        })
    }
  }
  return (
    <>
      {(loading || writeLoading) && <Loading />}
      <div style={{ width: '50%', padding: '0 10px', display: 'flex', flexDirection: 'column' }}>
        <HeaderBox>
          추천인
          <span style={{ fontSize: '13px', color: 'red' }}>
            &nbsp; (회원 본인의 추천코드를 통해 들어온 피추천인들로 부터 받는 헤택.)
          </span>
          <br />
          <span style={{ fontSize: '13px', color: 'blue' }}>파트너는 추천인 레벨로 혜택을 받을 수 없습니다.</span>
        </HeaderBox>
        <Form
          onSubmit={onSubmit}
          validate={friendComplevelValidate}
          initialValues={{
            friendCompLevelName,
            friendCompMaxAmount,
            liveCasinoFriendBenefitBettingPer,
            slotCasinoFriendBenefitBettingPer,
            miniGameFriendBenefitBettingPer,
            miniGameFriendBenefitLossPer,
            sportsFriendBenefitBettingPer,
            sportsFriendBenefitLossPer,
            holdemFriendBenefitBettingPer,
            holdemFriendBenefitLossPer,
            virtualGameFriendBenefitBettingPer,
            virtualGameFriendBenefitLossPer,
          }}
        >
          {({ handleSubmit, form: { reset } }) => (
            <FormContainer horizontal onSubmit={handleSubmit}>
              <FormGroup>
                <FormGroupLabel>추천인 1명당 받는 최대액</FormGroupLabel>
                <FormGroupField>
                  <Field name="friendCompMaxAmount">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={friendCompMaxAmount}
                            onChange={e => {
                              setFriendCompMaxAmount(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>원</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(1)
                  <br /> 라이브 배팅액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="liveCasinoFriendBenefitBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={liveCasinoFriendBenefitBettingPer}
                            onChange={e => {
                              setLiveCasinoFriendBenefitBettingPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(2)
                  <br /> 슬롯 배팅액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="slotCasinoFriendBenefitBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={slotCasinoFriendBenefitBettingPer}
                            onChange={e => {
                              setSlotCasinoFriendBenefitBettingPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(3)
                  <br /> 스포츠 배팅액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsFriendBenefitBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={sportsFriendBenefitBettingPer}
                            onChange={e => {
                              setSportsFriendBenefitBettingPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(4)
                  <br /> 스포츠 낙첨액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="sportsFriendBenefitLossPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={sportsFriendBenefitLossPer}
                            onChange={e => {
                              setSportsFriendBenefitLossPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(5)
                  <br /> 미니게임 배팅액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="miniGameFriendBenefitBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={miniGameFriendBenefitBettingPer}
                            onChange={e => {
                              setMiniGameFriendBenefitBettingPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(6)
                  <br /> 미니게임 낙첨액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="miniGameFriendBenefitLossPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={miniGameFriendBenefitLossPer}
                            onChange={e => {
                              setMiniGameFriendBenefitLossPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>

              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(9)
                  <br /> 가상 배팅액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="virtualGameFriendBenefitBettingPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={virtualGameFriendBenefitBettingPer}
                            onChange={e => {
                              setVirtualGameFriendBenefitBettingPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              <FormGroup>
                <FormGroupLabel>
                  추천인 혜택(10)
                  <br /> 가상 낙첨액 기준(%)
                </FormGroupLabel>
                <FormGroupField>
                  <Field name="virtualGameFriendBenefitLossPer">
                    {({ input, meta }) => (
                      <>
                        <FormInputWrap style={{ flexDirection: 'column', alignItems: 'start' }}>
                          <input
                            {...input}
                            type="text"
                            value={virtualGameFriendBenefitLossPer}
                            onChange={e => {
                              setVirtualGameFriendBenefitLossPer(e.target.value)
                            }}
                          />
                          {meta.error && <Error error={meta.error} />}
                        </FormInputWrap>
                        <FormGroupIcon>%</FormGroupIcon>
                      </>
                    )}
                  </Field>
                </FormGroupField>
              </FormGroup>
              {authority === 'SUPER' && (
                <FormButtonToolbar className="registrationFooter">
                  <Button variant="primary" type="submit">
                    변경하기
                  </Button>
                </FormButtonToolbar>
              )}
            </FormContainer>
          )}
        </Form>
      </div>
    </>
  )
}

export default FriendLevelForm

FriendLevelForm.propTypes = {
  selectedLevel: PropTypes.string.isRequired,
}

const FormContainer = styled.form`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  input,
  textarea {
    width: 100%;
    padding: 5px 10px;
    font-size: 12px;
    height: 34px;
    transition: border 0.3s;
    background: transparent;
    border: 1px solid black;
    color: black;

    &::-webkit-input-placeholder {
      color: #999999;
    }
    &::-moz-placeholder {
      color: #999999;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: #999999;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: #999999;
    }

    &[disabled] {
      background: #f2f4f7;

      &:focus,
      &:active {
        border-color: #eff1f5;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: #4ce1b6;
    }
  }
`

const FormGroup = styled.div`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
`

const FormGroupLabel = styled.div`
  width: 30%;
  height: 100%;
  padding: 3px 10px;
  color: black;
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: lightgrey;
  border: 1px solid black;
  border-right: none;
`

const FormGroupField = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const FormGroupIcon = styled.div`
  padding: 6px;
  min-width: 40px;
  height: 34px;
  background: ${colorFieldsBorder};
  border: 1px solid black;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${colorAdditional};
    width: 18px;
    height: 18px;
    transition: all 0.3s;
  }
`

const FormInputWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const HeaderBox = styled.div`
  background: #fff79e;
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 20px;
  border-top: 2px solid black;

  p {
    font-size: 11px;
    color: red;
  }
`
