export const systemLogTypeEnums = {
  MEMBERINFO: '회원정보 변경',
  BETTINGPER: '배당 변경',
  COUPONMEMBER: '회원쿠폰',
  COUPONPARTNER: '파트너쿠폰',
  MEMBERASSET: '회원자산 변경',
  PARTNERASSET: '파트너자산 변경',
  EVENTMANAGE: '이벤트 변경',
  LEVELNANAGE: '레벨 비율변경',
  SPORTSMEMBERRESULT: '회원 스포츠 결과 변경',
  SPORTSINSERTRESULT: '스포츠 점수 수동입력',
  SPORTSBONUSSETTING: '스포츠 보너스변경',
  MONEYDEPOSIT: '입금',
  MONEYWITHDRAWAL: '출금',
  SITEMANAGE: '사이트 관리',
  CASINOMANAGE: '카지노 관리',
  LOSINGSETTLEMENT: '루징 정산',
  SPORTS_SETTING: '스포츠 설정',
  SPORTS_SETTLEMENT: '스포츠 정산',
  SPORT_INFO: '스포츠 종목 정보',
  LOCATION_INFO: '스포츠 국가 정보',
  LEAGUE_INFO: '스포츠 리그 정보',
  MARKET_INFO: '스포츠 마켓 정보',
  MARKET_TAB_INFO: '스포츠 마켓 탭 정보',
  MARKET_COMBINATION_INFO: '스포츠 마켓 조합 정보',
  EVENT_FIXTURE: '스포츠 경기',
  EVENT_MARKET: '스포츠 경기 마켓',
  EVENT_MARKET_BET: '스포츠 경기 마켓 배당',
  SPORT_BETTING_PER_SETTING: '스포츠 배당 설정 정보',
  SPORT_BETTING_LOG: '스포츠 배팅로그',
  SPORT_AGAIN_SETTLEMENT: '스포츠 재정산',
  TEAM_INFO: '팀 정보',
  MINI_GAME_INFO: '미니게임 정보',
  MINI_GAME_BETTING_LOG: '미니게임 배팅로그',
  VIRTURAL_GAME_SPORT_INFO: '가상게임 종목정보',
  VIRTURAL_GAME_LEAUGE_INFO: '가상게임 리그정보',
  VIRTURAL_GAME_TEAM_INFO: '가상게임 리그정보',
  VIRTURAL_GAME_BETTING_LOG: '가상게임 배팅로그',
  VIRTURAL_GAME_FIXTURE_INFO: '가상게임 경기정보',
  VIRTURAL_GAME_MARKET_INFO: '가상게임 마켓정보',
  HOLDEM_INFO: '홀덤 정보',
  REVOLUTION_HOLDEM_BETTING_LOG: '레볼루션 홀덤 배팅 로그',
  REVOLUTION_HOLDEM_DETAIL_BETTING_LOG: '레볼루션 홀덤 배팅 세부로그',
  REVOLUTION_HOLDEM_BUY_IN_OUT_LOG: '레볼루션 홀덤 바이인/아웃 로그',
  WILD_GAMES_GAME_INFO: '와일드게임즈 게임 정보',
  CASINO_FAIL: '카지노 실패로그',
  CASINO_SLOT_CITY: '슬롯시티',
}

export const SystemLogTypeEnumsEnumsSelectOptions = () => {
  return Object.entries(systemLogTypeEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}
