import { Card, CardBody } from '@/shared/components/Card'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  CustomStyledModal,
  FriendLinkCustomStyledModal,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { MemberPointDepositReceiverTypeEnumsSelectOptions } from '../../../enums/MemberHistory/MemberPointDepositEnums'
import AuthoritySearchButton from '../../../shared/components/AuthoritySearchButton'
import { useSearchMemberAttendanceHistory } from '../../../utils/api/memberHistory/memberHistoryApi'
import { convertToKst } from '../../../utils/dateTime'
import LogPageTabs from '../../Log/LogPageTabs'
import MemberLoginHistoryListData from './MemberLoginHistoryListData'
import { useSearchSignInList } from '../../../utils/api/authManage/authManageApi'
import DuplicationIpHistory from '../../MemberManagement/MemberList/Duplication/DuplicationIpHistory'

const MemberLoginHistoryList = () => {
  /**
    포인트 입금내역 조회
   */
  const { EdgeNameSelect } = useSelector(state => {
    const { headerInfo } = state

    return {
      EdgeNameSelect: headerInfo.edgeNameSelect,
    }
  })
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [userAuthority, setUserAuthority] = useState(null)

  const [params, setParams] = useState({
    memberId: null,
    authority: null,
    edgeName: null,
    page,
    size,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      authority: !userAuthority ? null : userAuthority,
    }))
  }, [userAuthority])

  const { totalElement, content, fetchSearchSignInList } = useSearchSignInList(params)

  const [attendanceListData, setAttendanceListData] = useState(MemberLoginHistoryListData(content))

  useEffect(() => {
    setAttendanceListData(MemberLoginHistoryListData(content))
  }, [content])

  const [rows, setData] = useState(attendanceListData.tableRowsData)
  useEffect(() => {
    setData(attendanceListData.tableRowsData)
  }, [attendanceListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  // 사이트명
  const [searchEdgeName, setSearchEdgeName] = useState('')

  const [searchEdgeNameOptionValue, setSearchEdgeNameOptionValue] = useState(null)
  const searchEdgeNameOption = EdgeNameSelect

  const onSelectEdgeNameOptionHandler = option => {
    if (option === null) {
      setSearchEdgeNameOptionValue(null)
      setSearchEdgeName(null)
      return
    }
    setSearchEdgeNameOptionValue(option)
    setSearchEdgeName(option.label)
  }

  // IP
  const [searchLoginIp, setSearchLoginIp] = useState('')

  const onSearchLoginIpHandler = e => {
    setSearchLoginIp(e.target.value)
  }

  // 로그인일시 시작 + 끝
  const [searchLoginStartDate, setSearchLoginStartDate] = useState(null)
  const [searchLoginEndDate, setSearchLoginEndDate] = useState(null)

  const onSearchLoginStartDateChangeHandler = date => {
    setSearchLoginStartDate(date)
  }
  const onSearchLoginEndDateChangeHandler = date => {
    setSearchLoginEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      loginIp: !searchLoginIp ? null : searchLoginIp,
      edgeName: !searchEdgeName ? null : searchEdgeName,
      memberId: !searchMemberId ? null : searchMemberId,
      startDate: !searchLoginStartDate ? null : convertToKst(searchLoginStartDate),
      endDate: !searchLoginEndDate ? null : convertToKst(searchLoginEndDate),
    }))
  }

  const [isModalShow, setModalShow] = useState(false)
  const onModalClickHandler = () => {
    setModalShow(prev => !prev)
  }

  const [activeKey, setActiveKey] = useState('1')
  return (
    <Card>
      <CardBody>
        <LogPageTabs activeKey={activeKey} />
        {/* 검색 기능 */}
        <AuthoritySearchButton
          userAuthority={userAuthority}
          onClickHandler={e => {
            setUserAuthority(e.target.id)
          }}
        />
        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>사이트</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchEdgeName"
                onChange={option => {
                  onSelectEdgeNameOptionHandler(option)
                }}
                placeholder="전체"
                value={searchEdgeNameOptionValue}
                options={searchEdgeNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>유저 ID / 명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="입력해주세요"
                  value={searchMemberId}
                  onChange={e => onSearchMemberIdHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>로그인 IP</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  name="searchValue"
                  type="text"
                  placeholder="검색 입력해주세요"
                  value={searchLoginIp}
                  onChange={e => onSearchLoginIpHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>시작일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchLoginStartDate}
                  onChange={date => onSearchLoginStartDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  popperPlacement="bottom-start"
                  placeholderText="시작일"
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>종료일</SearchGroupLabel>
            <SearchGroupField>
              <SearchDatePickerWrap>
                <DatePicker
                  selected={searchLoginEndDate}
                  onChange={date => onSearchLoginEndDateChangeHandler(date)}
                  dateFormat="yyyy-MM-dd HH:mm"
                  dropDownMode="select"
                  timeFormat="HH:mm"
                  popperPlacement="bottom-start"
                  placeholderText="종료일"
                  isClearable
                />
              </SearchDatePickerWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
            <Button
              size="sm"
              variant="outline-secondary"
              style={{ background: 'red', color: 'white' }}
              onClick={e => {
                setModalShow(true)
              }}
            >
              중복IP 확인
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={attendanceListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>
      <CustomModal show={isModalShow} onHide={onModalClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onModalClickHandler}
          />
          <CustomModalTitle>유저 중복IP 확인</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <DuplicationIpHistory onClickHandler={onModalClickHandler} />
        </CustomModalBody>
      </CustomModal>
    </Card>
  )
}

export default MemberLoginHistoryList

const CustomModal = styled(CustomStyledModal)`
  .modal-dialog {
    max-width: 1250px !important;
  }
`
