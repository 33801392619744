import React from 'react'
import styled from 'styled-components'
import { BetSettlementStatus } from '../../../enums/Sport/SportMarketEnums'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const SportsDetailBettingInfoListData = (content, fetchSearchSportsMarket) => {
  const columns = [
    {
      Header: '경기시간',
      accessor: 'sportsStartTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstSportsEventDateTime(original.startDate)
      },
    },
    {
      Header: '종목',
      accessor: 'sportName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '국가 & 리그',
      accessor: 'nationalAndLeague',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span>
            {original.locationName}-{original.leagueName}
          </span>
        )
      },
    },
    {
      Header: '경기명',
      accessor: 'sportsGameName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span>
            {original.homeName} vs {original.awayName}
          </span>
        )
      },
    },
    {
      Header: '마켓명',
      accessor: 'marketName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '선택항목',
      accessor: 'betNameKr',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '기준점',
      accessor: 'lineNum',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <span>{original.lineNum || '-'}</span>
      },
    },
    {
      Header: '배당',
      accessor: 'priceNum',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '결과',
      accessor: 'betSettlementType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <BettingResultWrap betSettlementType={original.betSettlementType}>
            {BetSettlementStatus[original.betSettlementType]}
          </BettingResultWrap>
        )
      },
    },
    // {
    //   Header: '결과 수정',
    //   accessor: 'manualResult',
    //   disableGlobalFilter: true,
    //   width: 65,
    //   Cell: r => {
    //     const { row } = r
    //     const { original } = row
    //     return (
    //       <ResultModifyModal
    //         memberId={original.memberId}
    //         sportsDetailBettingLogKey={original.sportsDetailBettingLogKey}
    //         sportsBettingLogKey={original.sportsBettingLogKey}
    //         resultStatus={original.resultStatus}
    //         sportsGameName={original.sportsGameName}
    //         fetchSearchSportsMarket={fetchSearchSportsMarket}
    //       />
    //     )
    //   },
    // },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default SportsDetailBettingInfoListData

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WINNER':
      return 'blue'
    case bettingResult === 'LOSER':
      return 'red'
    case bettingResult === 'REFUND':
      return 'grey'
    case bettingResult === 'CANCELLED':
      return 'DarkViolet'
    case bettingResult === 'MANUAL_CANCELLED':
      return 'DarkViolet'

    case bettingResult === 'NOT_SETTLED':
      return 'lightgrey'
    default:
      return 'green'
  }
}

const BettingResultWrap = styled.button`
  width: 80%;
  font-weight: 700;
  color: white;
  border: none;
  background-color: ${props => getColor(props.betSettlementType)};
`
