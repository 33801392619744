import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { searchPartner } from '../utils/api/partnerManage/partnerManageApi'

export const fetchSearchPartner = createAsyncThunk('partnerInfo/fetchSearchPartner', async params => {
  const res = await searchPartner(params)
  return res.data
})

function toggleIsOpened(userId, data) {
  return data.map(item => {
    const newItem = { ...item } // 얕은 복사로 새 객체 생성

    if (item.userId === userId) {
      newItem.isOpened = !item.isOpened
    }

    if (item.subUserInfos && item.subUserInfos.length > 0) {
      newItem.subUserInfos = toggleIsOpened(userId, item.subUserInfos)
    }

    return newItem
  })
}

function insertOrUpdateSubUserInfo(userList, newData) {
  let isInsertedOrUpdated = false

  userList.forEach(user => {
    if (isInsertedOrUpdated) return // forEach 내에서도 'break'는 사용할 수 없기 때문에 이런 방식으로 처리합니다.

    if (user.userId === newData.topUserId) {
      // 해당 topUserId를 가진 user의 subUserInfos에 newData를 추가하거나 갱신합니다.
      if (user.subUserInfos) {
        const existingIndex = user.subUserInfos.findIndex(subUser => subUser.userId === newData.userId)

        if (existingIndex !== -1) {
          // 존재하면 갱신
          user.subUserInfos[existingIndex] = newData
        } else {
          // 존재하지 않으면 추가
          user.subUserInfos.push(newData)
        }
      } else {
        user.subUserInfos = [newData]
      }

      isInsertedOrUpdated = true
    } else if (user.subUserInfos && user.subUserInfos.length > 0) {
      isInsertedOrUpdated = insertOrUpdateSubUserInfo(user.subUserInfos, newData)
    }
  })

  // 만약 값이 추가되거나 갱신되지 않았을 경우, userList에 새로운 데이터를 추가합니다.
  if (!isInsertedOrUpdated) {
    userList.push(newData)
    isInsertedOrUpdated = true
  }

  return isInsertedOrUpdated
}

const partnerInfoSlice = createSlice({
  name: 'partnerInfo',
  initialState: {
    partnerManageList: [],
    apiLoading: false,
    selectedUserId: '',
    searchParams: {
      edgeName: null,
      userId: null,
      topUserId: null,
    },
  },
  reducers: {
    resetPartnerList: (state, action) => {
      state.partnerManageList = []
      state.selectedUserId = ''
    },
    handleToggle: (state, action) => {
      const { userId } = action.payload
      state.selectedUserId = userId

      state.searchParams = {
        ...state.searchParams,
        topUserId: userId,
      }

      state.partnerManageList = toggleIsOpened(userId, state.partnerManageList)
    },
    onClickEdgeSelectHandler: (state, action) => {
      state.partnerManageList = []

      state.searchParams = {
        ...state.searchParams,
        topUserId: null,
        edgeName: action.payload,
      }
    },
    onClickSearchHandler: (state, action) => {
      state.partnerManageList = []

      state.searchParams = {
        ...state.searchParams,
        topUserId: null,
        userId: action.payload,
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSearchPartner.pending, (state, action) => {
      state.apiLoading = true // 요청 시작 시 로딩 상태를 true로 설정
    })
    builder.addCase(fetchSearchPartner.fulfilled, (state, action) => {
      const { content } = action.payload

      content.forEach(item => {
        if (state.partnerManageList.length > 0) {
          if (item.topUserId) {
            insertOrUpdateSubUserInfo(state.partnerManageList, item)
          } else {
            state.partnerManageList.push(item)
          }
        } else {
          state.partnerManageList.push(item)
        }
      })
      state.apiLoading = false
    })
  },
})

export const { handleToggle, resetPartnerList, onClickEdgeSelectHandler, onClickSearchHandler } =
  partnerInfoSlice.actions

export default partnerInfoSlice.reducer
