import { colorBackgroundBody } from '@/utils/palette'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import isLogin from '../../enums/authority'
import BettingLogList from '../BettingManagement/CasinoBettingLog/BettingLogList'
import MiniGameBettingLogList from '../BettingManagement/MiniGameBettingLog/MiniGameBettingLogList'
import RealTimeBettingLogList from '../BettingManagement/RealTimeBettingLog/RealTimeBettingLogList'
import RevolutionBettingLogDetailPage from '../BettingManagement/RevolutionBetting/RevolutionBettingLogDetailPage'
import RevolutionBettingLogPage from '../BettingManagement/RevolutionBetting/RevolutionBettingLogPage'
import RevolutionBuyInOutPage from '../BettingManagement/RevolutionBetting/RevolutionBuyInOutPage'
import SportBettingLogList from '../BettingManagement/SportBettingLog/SportBettingLogList'
import SportBettingLogListV2 from '../BettingManagement/SportBettingLogV2/SportBettingLogListV2'
import TokenGameBettingLogPage from '../BettingManagement/TokenGameBettingLog/TokenGameBettingLogPage'
import VirtualGameBettingLogList from '../BettingManagement/VirtualGameBettingLog/VirtualGameBettingLogList'
import ZeroBettingList from '../BettingManagement/ZeroBettingLog/ZeroBettingList'
import DashBoardListPage from '../Dashboard/DashBoardListPage'
import FriendCompList from '../FriendCompHistory/FriendCompList'
import Layout from '../Layout/index'
import CouponPayLogList from '../Log/CouponPayLog/CouponPayLogList'
import MiniGameResultList from '../Log/MiniGameResult/MiniGameResultList'
import MoneyIncDecList from '../Log/MoneyIncDec/MoneyIncDecList'
import SystemLogList from '../Log/SystemLog/SystemLogList'
import MoneyDepoWithdrawList from '../Log/moneyDepoWithdrawLog/MoneyDepoWithdrawList'
import LogIn from '../LogIn/Login'
import MemberAttendanceList from '../MemberHistory/AttendanceHistory/MemberAttendanceList'
import DepositList from '../MemberHistory/DepositHistory/DepositList'
import MoneyExchangeList from '../MemberHistory/MoneyExchangeHistory/MoneyExchangeList'
import RestrictionIpList from '../MemberHistory/RestrictionIpHistory/RestrictionIpList'
import WithdrawalList from '../MemberHistory/WithdrawalHistory/WithdrawalList'
import MemberBadList from '../MemberManagement/MemberBadList/MemberBadList'
import MemberBlackList from '../MemberManagement/MemberBlackList/MemberBlackList'
import MemberInfoPage from '../MemberManagement/MemberList/MemberInfo/MemberInfoPage'
import MemberList from '../MemberManagement/MemberList/MemberList'
import PartnerList from '../MemberManagement/PartnerList/PartnerList'
import UserRegistrationPage from '../MemberManagement/UserRegistraion/UserRegistrationPage'
import MyInfo from '../MyInfo/MyInfo'
import BoardList from '../Operation/Board/BoardList'
import CouponList from '../Operation/Coupon/CouponList'
import NoteList from '../Operation/Note/NoteList'
import OnetoOneList from '../Operation/OnetoOne/OnetoOneList'
import PopupList from '../Operation/Popup/PopupList'
import PartnerApplyList from '../PartnerManagement/PartnerApplyHistory/PartnerApplyList'
import PayBackList from '../PaybackHistory/PayBackList'
import SportsEventList from '../SportManagement/GameInfoManagement/SportsEventList/SportsEventList'
import LeagueManagementList from '../SportManagement/LeagueManagement/LeagueManagementList'
import TeamManagementList from '../SportManagement/LeagueManagement/TeamManagementList'
import LiveSportsEventList from '../SportManagement/LiveGameInfoManagement/LiveSportsEventList/LiveSportsEventList'
import ManualSportsManagementTabs from '../SportManagement/ManualSportsManagement/ManualSportsManagementTabs'
import ManualSportsManagementTabsV2 from '../SportManagement/ManualSportsManagement/ManualSportsManagementTabsV2'
import SettlementManagement from '../SportManagement/SettlementManagement/SettlementManagement'
import UnprocessedSportsEventList from '../SportManagement/UnprocessedSports/UnprocessedSportsEventList'
import BetSettingManagement from '../SportManagementV2/betSettingManagement/BetSettingManagement'
import SportFixtureManagePage from '../SportManagementV2/fixtureManagement/SportFixtureManagePage'
import SportLiveFixtureManagePage from '../SportManagementV2/fixtureManagement/SportLiveFixtureManagePage'
import ManualSportFixtureManagePage from '../SportManagementV2/manualFixtureManagement/ManualSportFixtureManagePage'
import ManualSportLiveFixtureManagePage from '../SportManagementV2/manualFixtureManagement/ManualSportLiveFixtureManagePage'
import MarketAndMixedManage from '../SportManagementV2/marketAndMixedManagement/MarketAndMixedManage'
import SportsSettlementManagementV2 from '../SportManagementV2/settlementManagement/SportsSettlementManagementV2'
import SportLocationManagePage from '../SportManagementV2/sportManagement/SportLocationManagePage'
import SportSettingManagement from '../SportManagementV2/sportSettingManagement/SportSettingManagement'
import PartnerLosingPointDepositList from '../Statistics/LosingSettlementHistory/PartnerLosingPointDepositList'
import MemberPointExchangeList from '../Statistics/PointExchangeHistory/MemberPointExchangeList'
import MemberRollingPointDepositList from '../Statistics/RollingSettlementHistory/MemberRollingPointDepositList'
import SettlementStatistics from '../Statistics/SettlementStatistics/SettlementStatistics'
import SettlementList from '../Statistics/SettlementStatisticsV2/SettlementList'
import BankList from '../SystemManagement/BankManagement/BankList'
import CallManageMent from '../SystemManagement/CallManagement/CallManagement'
import DepositWithdrawalRuleManagement from '../SystemManagement/DepositWithdrawalRuleManagement/DepositWithdrawalRuleManagement'
import GameList from '../SystemManagement/GameManagement/GameList'
import LevelManagement from '../SystemManagement/LevelManagement/LevelManagement'
import MacroList from '../SystemManagement/MacroManagement/MacroList'
import BoscoreManagement from '../SystemManagement/MiniGameManagement/Boscore/BoscoreManagement'
import GoPickManagement from '../SystemManagement/MiniGameManagement/Gopick/GoPickManagement'
import NamedManagement from '../SystemManagement/MiniGameManagement/Named/NamedManagement'
import NtryManagement from '../SystemManagement/MiniGameManagement/Ntry/NtryManagement'
import SuremanManagement from '../SystemManagement/MiniGameManagement/Sureman/SuremanManagement'
import TokenGameManagement from '../SystemManagement/MiniGameManagement/TokenGame/TokenGameManagement'
import SiteManageList from '../SystemManagement/SiteManagement/SiteManageList'
import UrlList from '../SystemManagement/UrlManagement/UrlList'
import VirtualBaseballManagement from '../SystemManagement/VirtualGameManagement/VirtualBaseballManagement'
import VirtualBasketballManagement from '../SystemManagement/VirtualGameManagement/VirtualBasketballManagement'
import VirtualGameFixtureList from '../SystemManagement/VirtualGameManagement/VirtualGameFixtureList'
import VirtualGameManagement from '../SystemManagement/VirtualGameManagement/VirtualGameManagement'
import VirtualSoccerManagement from '../SystemManagement/VirtualGameManagement/VirtualSoccerManagement'
import MainWrapper from './MainWrapper'
import RevolutionHoldemManagePage from '../RevolutionHoldem/RevolutionHoldemManagePage'
import WildHoldemManagePage from '../WildGames/WildGamesManagePage'
import WildGamesBettingLogPage from '../BettingManagement/WildGamesBetting/WildGamesBettingLogPage'
import WildGamesBettingLogDetailPage from '../BettingManagement/WildGamesBetting/WildGamesBettingLogDetailPage'
import WildGamesGameEventLogPage from '../BettingManagement/WildGamesBetting/WildGamesGameEventLogPage'
import BTIBettingLogList from '../BettingManagement/CasinoBettingLog/BTIBettingLogList'
import CasinoBettingLogList from '../BettingManagement/CasinoBettingLog/CasinoBettingLogList'
import SlotBettingLogList from '../BettingManagement/CasinoBettingLog/SlotBettingLogList'
import MemberLoginHistoryList from '../MemberHistory/LoginHistory/MemberLoginHistoryList'
import MoneyExchangeRateList from '../MemberHistory/MoneyExchangeRateHistory/MoneyExchangeRateList'
import AllCasinoBettingLogList from '../BettingManagement/CasinoBettingLog/AllCasinoBettingLogList'
import CrownManagement from '../SystemManagement/MiniGameManagement/Crown/CrownManagement'
import MgmManagement from '../SystemManagement/MiniGameManagement/Mgm/MgmManagement'
import LotusManagement from '../SystemManagement/MiniGameManagement/Lotus/LotusManagement'
import DepositListV2 from '../MemberHistory/DepositHistory/DepositListV2'
import WithdrawalListV2 from '../MemberHistory/WithdrawalHistory/WithdrawalListV2'
import SlotCityCallManagement from '../CasinoManagement/SlotCity/SlotCityCallManagement'
import DonghangManagement from '../SystemManagement/MiniGameManagement/Donghang/DonghangManagement'
import BePickManagement from '../SystemManagement/MiniGameManagement/Bepick/BePickManagement'
import NextManagement from '../SystemManagement/MiniGameManagement/Next/NextManagement'
import BannerList from '../SystemManagement/BannerManagement/BannerList'
import ExcelUserRegistrationPage from '../MemberManagement/UserRegistraion/ExcelUserRegistrationPage'
import MoneyChangeLogList from '../Log/MoneyChangeLog/MoneyChangeLogList'

/**
 * react-router Docs
 * @see : https://v5.reactrouter.com/web/guides/quick-start
 */

/**
 * 로그인 하지 않은 사용자에게 공개된 URL 관리
 * @props : restricted - 로그인 사용자가 접근하지 말아야하는 URL 제한 : 로그인 페이지
 * @wee : 로그인 한 상태에서 로그인 URL 접근하면 회원 조회 페이지로 redirect
 */
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route {...rest} render={props => (isLogin() && restricted ? <Redirect to="/" /> : <Component {...props} />)} />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  restricted: PropTypes.bool.isRequired,
}

/**
 * 로그인한 사용자에게 공개된 URL 관리
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return isLogin() ? <Component {...props} /> : <Redirect to="/login" />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
}

const Router = () => {
  const { hideSideBar } = useSelector(state => {
    const { headerInfo } = state
    return {
      hideSideBar: headerInfo.hideSideBar,
    }
  })
  return (
    <MainWrapper>
      <main>
        <Switch>
          <PublicRoute restricted component={LogIn} path="/login" />
          <PrivateRoute component={RealTimeBettingLogList} path="/management/log/real-time-betting" exact />
          <PrivateRoute component={MemberInfoPage} path="/user-info/:memberId" exact />
          <div style={{ minWidth: '1900px' }}>
            <Layout />
            <ContainerWrap hideSideBar={hideSideBar}>
              {/* 메인 페이지 */}
              <PrivateRoute component={DashBoardListPage} path="/" exact />
              {/* MY 페이지 */}
              <PrivateRoute component={MyInfo} path="/myinfo" exact />
              {/* 대시보드 */}
              <PrivateRoute component={DashBoardListPage} path="/statistics" exact />
              {/* 회원 조회 */}
              <PrivateRoute component={MemberList} path="/user/management/member" exact />
              {/* 파트너 조회 */}
              <PrivateRoute component={PartnerList} path="/user/management/partner" exact />
              {/* 유저 등록 */}
              <PrivateRoute component={UserRegistrationPage} path="/user/management/registration" exact />
              {/* 유저 등록-엑셀 */}
              <PrivateRoute
                component={ExcelUserRegistrationPage}
                path="/user/management/registration-excelexcel"
                exact
              />
              {/* 블랙리스트 조회 */}
              <PrivateRoute component={MemberBlackList} path="/user/management/black" exact />
              {/* Ip차단 조회 */}
              <PrivateRoute component={RestrictionIpList} path="/user/management/black/ip" exact />
              {/* 악성유저 조회 */}
              <PrivateRoute component={MemberBadList} path="/user/management/bad" exact />
              {/* 머니 입금내역 */}
              <PrivateRoute component={DepositList} path="/user/history/moneydeposit" exact />
              <PrivateRoute component={DepositListV2} path="/user/history/moneydepositV2" exact />
              {/* 머니 출금내역 */}
              <PrivateRoute component={WithdrawalList} path="/user/history/moneywithdrawal" exact />
              <PrivateRoute component={WithdrawalListV2} path="/user/history/moneywithdrawalV2" exact />
              {/* 환수율 내역 */}
              <PrivateRoute component={MoneyExchangeRateList} path="/user/history/moneyexchange-rate" exact />
              {/* 머니 이동내역 */}
              <PrivateRoute component={MoneyExchangeList} path="/user/history/moneyexchange" exact />
              {/* 롤링 포인트 입금내역(회원+파트너용) */}
              <PrivateRoute component={MemberRollingPointDepositList} path="/user/history/pointdeposit" exact />
              {/* 루징 포인트 입금내역(파트너용) */}
              <PrivateRoute component={PartnerLosingPointDepositList} path="/user/history/losing-pointdeposit" exact />
              {/* 포인트 전환내역 */}
              <PrivateRoute component={MemberPointExchangeList} path="/user/history/pointexchange" exact />
              {/* 포인트 전환내역 */}
              <PrivateRoute component={MemberAttendanceList} path="/user/history/attendance" exact />
              <PrivateRoute component={PartnerApplyList} path="/partner/management/apply" exact />
              {/* =============== 페이백 관리 ===============  */}
              {/* 페이백 내역 */}
              <PrivateRoute component={PayBackList} path="/user/manage/payback" exact />
              <PrivateRoute component={FriendCompList} path="/user/manage/friend-comp" exact />
              {/* ========================================== */}
              {/* =============== 통계 ===============  */}
              {/* 업체별 롤링 통계 */}
              <PrivateRoute component={SettlementStatistics} path="/statistics/rolling" exact />
              <PrivateRoute component={SettlementList} path="/statistics/rolling2" exact />
              {/* 업체별 루징 통계
              <PrivateRoute component={PartnerLosingStatistics} path="/statistics/losing" exact /> */}
              {/* ========================================== */}
              {/* =============== 운영 관리 ===============  */}
              {/* 게시판관리 */}
              <PrivateRoute component={BoardList} path="/management/operation/board" exact />
              {/* 팝업관리 */}
              <PrivateRoute component={PopupList} path="/management/operation/popup" exact />
              {/* 1:1 문의 */}
              <PrivateRoute component={OnetoOneList} path="/management/operation/onetoone" exact />
              {/* 쪽지관리 */}
              <PrivateRoute component={NoteList} path="/management/operation/note" exact />
              {/* 쿠폰관리 */}
              <PrivateRoute component={CouponList} path="/management/operation/coupon" exact />
              {/* ========================================== */}
              {/* =============== 배팅내역 관리 ===============  */}
              {/* 실시간 배팅로그 */}
              {/* <PrivateRoute component={RealTimeBettingLogList} path="/management/log/real-time-betting" exact /> */}
              {/* 스포츠 배팅로그 */}
              <PrivateRoute component={SportBettingLogList} path="/management/log/sport-betting" exact />
              <PrivateRoute component={SportBettingLogListV2} path="/management/log/sport-bettingv2" exact />
              {/* 카지노 배팅로그 */}
              <PrivateRoute component={AllCasinoBettingLogList} path="/management/log/betting" exact />
              <PrivateRoute component={CasinoBettingLogList} path="/management/log/betting/casino" exact />
              <PrivateRoute component={SlotBettingLogList} path="/management/log/betting/slot" exact />
              <PrivateRoute component={BTIBettingLogList} path="/management/log/betting/bti" exact />
              {/* 공배팅로그 */}
              <PrivateRoute component={ZeroBettingList} path="/management/log/zero-betting" exact />
              {/* 미니게임 배팅로그 */}
              <PrivateRoute component={MiniGameBettingLogList} path="/management/log/minigame-betting" exact />
              <PrivateRoute component={VirtualGameBettingLogList} path="/management/log/virtualgame-betting" exact />
              <PrivateRoute component={TokenGameBettingLogPage} path="/management/log/tokengame-betting" exact />

              <PrivateRoute component={RevolutionBettingLogPage} path="/management/log/revolution-betting" exact />
              <PrivateRoute
                component={RevolutionBettingLogDetailPage}
                path="/management/log/revolution-betting-detail"
                exact
              />
              <PrivateRoute component={RevolutionBuyInOutPage} path="/management/log/revolution-buyinout" exact />

              <PrivateRoute component={WildGamesBettingLogPage} path="/management/log/wild-games/betting" exact />
              <PrivateRoute
                component={WildGamesBettingLogDetailPage}
                path="/management/log/wild-games/betting-detail"
                exact
              />
              <PrivateRoute component={WildGamesGameEventLogPage} path="/management/log/wild-games/game-event" exact />

              {/* =============== 로그 관리 ===============  */}
              {/* 미니게임 결과 */}
              <PrivateRoute component={MiniGameResultList} path="/management/log/minigame-result" exact />
              {/* 입출금로그 */}
              <PrivateRoute component={MoneyChangeLogList} path="/management/log/money-change-log" exact />
              {/* 입출금로그 */}
              <PrivateRoute component={MoneyDepoWithdrawList} path="/management/log/moneydepowithdraw" exact />
              {/* 머니증차감로그 */}
              <PrivateRoute component={MoneyIncDecList} path="/management/log/moneyinc" exact />
              {/* 머니증차감로그 */}
              <PrivateRoute component={SystemLogList} path="/management/log/admin" exact />
              {/* 쿠폰지급로그 */}
              <PrivateRoute component={CouponPayLogList} path="/management/log/couponpay" exact />
              {/* 로그인 로그 */}
              <PrivateRoute component={MemberLoginHistoryList} path="/management/log/login" exact />
              {/* ========================================== */}
              {/* =============== 시스템관리 ===============  */}
              {/* URL 관리 */}
              <PrivateRoute component={UrlList} path="/management/system/clienturl" exact />
              {/* 배너 관리 */}
              <PrivateRoute component={BannerList} path="/management/system/banner" exact />
              {/* 은행 관리 */}
              <PrivateRoute component={BankList} path="/management/system/bank" exact />
              {/* 사이트 관리 */}
              <PrivateRoute component={SiteManageList} path="/management/system/site" exact />
              {/* 게임사 관리 */}
              <PrivateRoute component={GameList} path="/management/system/gamecompany" exact />
              {/* 골드링크 슬롯 콜 관리 */}
              <PrivateRoute component={CallManageMent} path="/management/system/callmanage/gold-link" exact />
              {/* 슬롯시티 콜 관리 */}
              <PrivateRoute component={SlotCityCallManagement} path="/management/system/callmanage/slot-city" exact />
              {/* 매크로 관리 */}
              <PrivateRoute component={MacroList} path="/management/system/macro" exact />
              {/* 레벨 관리 */}
              <PrivateRoute component={LevelManagement} path="/management/system/level" exact />

              {/* 미니 게임사 관리 */}
              <PrivateRoute component={NtryManagement} path="/management/system/minigame-ntry" exact />
              <PrivateRoute component={BoscoreManagement} path="/management/system/minigame-boscore" exact />
              <PrivateRoute component={NamedManagement} path="/management/system/minigame-named" exact />
              <PrivateRoute component={SuremanManagement} path="/management/system/minigame-sureman" exact />
              <PrivateRoute component={GoPickManagement} path="/management/system/minigame-gopick" exact />
              <PrivateRoute component={CrownManagement} path="/management/system/minigame-crown" exact />
              <PrivateRoute component={MgmManagement} path="/management/system/minigame-mgm" exact />
              <PrivateRoute component={LotusManagement} path="/management/system/minigame-lotus" exact />
              <PrivateRoute component={TokenGameManagement} path="/management/system/minigame-token" exact />
              <PrivateRoute component={NtryManagement} path="/management/system/minigame-pbg" exact />
              <PrivateRoute component={NextManagement} path="/management/system/minigame-next" exact />
              <PrivateRoute component={NtryManagement} path="/management/system/minigame-spoluv" exact />
              <PrivateRoute component={NtryManagement} path="/management/system/minigame-split" exact />
              <PrivateRoute component={NtryManagement} path="/management/system/minigame-playscore" exact />
              <PrivateRoute component={DonghangManagement} path="/management/system/minigame-donghang" exact />
              <PrivateRoute component={BePickManagement} path="/management/system/minigame-bepick" exact />

              {/* 스포츠 정산 관리 */}
              <PrivateRoute component={SportsSettlementManagementV2} path="/management/sport/settlements" exact />
              <PrivateRoute component={SettlementManagement} path="/management/sport/settlement" exact />
              {/* 스포츠 조합 관리 */}
              <PrivateRoute component={MarketAndMixedManage} path="/management/sport/setting" exact />
              {/* 스포츠 게임정보관리 */}
              <PrivateRoute component={SportsEventList} path="/management/sport/bettinglist" exact />
              {/* 라이브 스포츠 게임정보관리 */}
              <PrivateRoute component={LiveSportsEventList} path="/management/sport/live-bettinglist" exact />
              {/* 미처리된 경기 */}
              <PrivateRoute component={UnprocessedSportsEventList} path="/management/sport/unprocessed" exact />
              {/* 스포츠 리그 관리  */}
              <PrivateRoute component={LeagueManagementList} path="/management/sport/league/manage" exact />
              {/* 스포츠 팀 관리  */}
              <PrivateRoute component={TeamManagementList} path="/management/sport/team/manage" exact />
              {/* 스포츠 배팅설정 관리  */}
              <PrivateRoute component={BetSettingManagement} path="/management/sport/betting/manage" exact />
              {/* 스포츠 수동 경기/마켓 설정  */}
              <PrivateRoute component={ManualSportsManagementTabs} path="/management/sport/manual/manage" exact />
              {/* 충전보너스 관리 */}
              <PrivateRoute component={DepositWithdrawalRuleManagement} path="/management/system/deposit-bonus" exact />
              {/* 가상게임 관리 */}

              <PrivateRoute component={VirtualGameFixtureList} path="/management/system/virtual-game-list" exact />
              <PrivateRoute component={VirtualGameManagement} path="/management/system/virtual-game" exact />
              <PrivateRoute
                component={VirtualBaseballManagement}
                path="/management/system/virtual-game/baseball"
                exact
              />
              <PrivateRoute
                component={VirtualBasketballManagement}
                path="/management/system/virtual-game/basketball"
                exact
              />
              <PrivateRoute component={VirtualSoccerManagement} path="/management/system/virtual-game/soccer" exact />
              {/* ========================================== */}
              {/* 스포츠 종목 관리  */}
              <PrivateRoute component={SportFixtureManagePage} path="/sport/fixture" exact />
              <PrivateRoute component={ManualSportFixtureManagePage} path="/sport/fixture/manual" exact />
              <PrivateRoute component={SportLiveFixtureManagePage} path="/sport/live-fixture" exact />
              <PrivateRoute component={ManualSportLiveFixtureManagePage} path="/sport/live-fixture/manual" exact />
              <PrivateRoute component={ManualSportsManagementTabsV2} path="/sport/manual" exact />
              <PrivateRoute component={SportFixtureManagePage} path="/sport/settlement" exact />
              <PrivateRoute component={SportFixtureManagePage} path="/sport/betting" exact />
              <PrivateRoute component={SportSettingManagement} path="/sport/basic" exact />
              <PrivateRoute component={SportLocationManagePage} path="/sport/sport-location" exact />
              <PrivateRoute component={SportFixtureManagePage} path="/sport/market" exact />
              <PrivateRoute component={SportFixtureManagePage} path="/sport/location" exact />
              <PrivateRoute component={SportFixtureManagePage} path="/sport/league" exact />
              <PrivateRoute component={SportFixtureManagePage} path="/sport/team" exact />

              <PrivateRoute component={RevolutionHoldemManagePage} path="/holdem/revolution" exact />
              <PrivateRoute component={WildHoldemManagePage} path="/holdem/wild-games" exact />
            </ContainerWrap>
          </div>
        </Switch>
      </main>
    </MainWrapper>
  )
}

export default Router

// region STYLES

const ContainerWrap = styled.div`
  padding-top: 90px;
  margin-left: -40px;
  min-height: 100vh;
  transition: padding-left 0.3s;
  padding-left: ${props => (props.hideSideBar ? `30px` : `250px`)};
  background: ${colorBackgroundBody};
`

// endregion
