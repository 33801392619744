import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import { SearchCardTitleWrap } from '../../../../../shared/components/Card'

const MemberStatisticsBettingLiveCasino = ({ liveCasino }) => {
  const getResultAmount = (num1, num2) => {
    const resultAmount = Number(num1) - Number(num2)

    if (resultAmount > 0) {
      return (
        <span style={{ color: 'blue', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
      )
    }
    if (resultAmount < 0) {
      return <span style={{ color: 'red', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
    }
    return <span style={{ color: 'black', fontWeight: '500' }}>{resultAmount.toString().replace(commonReg2, ',')}</span>
  }
  return (
    <MemberInfoWrap>
      <SearchCardTitleWrap>
        <div style={{ marginTop: '-10px', marginBottom: '10px' }}>
          <p>* 배팅액/당첨액은 &apos;타이&apos; 제외한 액입니다.</p>
          <p>
            * 배팅액/당첨액은 <span style={{ color: 'red' }}>&apos;공배팅&apos; 포함</span>한 액입니다.
          </p>
        </div>
      </SearchCardTitleWrap>
      <table>
        <tbody>
          <tr>
            <th>오늘 배팅액</th>
            <td>{liveCasino.dailyLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 당첨액</th>
            <td>{liveCasino.dailyLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>오늘 롤링액(본인)</th>
            <td>{liveCasino.dailyLiveCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>오늘 차액(배팅-당첨)</th>
            <td>{getResultAmount(liveCasino.dailyLiveCasinoBettingAmount, liveCasino.dailyLiveCasinoWinningAmount)}</td>
          </tr>
          <tr>
            <th>이번달 배팅액</th>
            <td>{liveCasino.monthLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 당첨액</th>
            <td>{liveCasino.monthLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>이번달 롤링액(본인)</th>
            <td>{liveCasino.monthLiveCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>이번달 차액(배팅-당첨)</th>
            <td>{getResultAmount(liveCasino.monthLiveCasinoBettingAmount, liveCasino.monthLiveCasinoWinningAmount)}</td>
          </tr>
          <tr>
            <th>기간내 배팅액</th>
            <td>{liveCasino.dateLiveCasinoBettingAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 당첨액</th>
            <td>{liveCasino.dateLiveCasinoWinningAmount?.toString().replace(commonReg2, ',')}</td>
            <th>기간내 롤링액(본인)</th>
            <td>{liveCasino.dateLiveCasinoRollingAmount?.toString().replace(commonReg2, ',')}</td>
            <th style={{ color: '#f6ff00' }}>기간내 차액(배팅-당첨)</th>
            <td>{getResultAmount(liveCasino.dateLiveCasinoBettingAmount, liveCasino.dateLiveCasinoWinningAmount)}</td>
          </tr>
        </tbody>
      </table>
    </MemberInfoWrap>
  )
}

export default MemberStatisticsBettingLiveCasino

MemberStatisticsBettingLiveCasino.propTypes = {
  liveCasino: PropTypes.shape({
    dailyLiveCasinoBettingAmount: PropTypes.string,
    dailyLiveCasinoWinningAmount: PropTypes.string,
    dailyLiveCasinoRollingAmount: PropTypes.string,
    dailyLiveCasinoProfitAmount: PropTypes.string,
    monthLiveCasinoBettingAmount: PropTypes.string,
    monthLiveCasinoWinningAmount: PropTypes.string,
    monthLiveCasinoRollingAmount: PropTypes.string,
    monthLiveCasinoProfitAmount: PropTypes.string,
    dateLiveCasinoBettingAmount: PropTypes.string,
    dateLiveCasinoRollingAmount: PropTypes.string,
    dateLiveCasinoWinningAmount: PropTypes.string,
    dateLiveCasinoProfitAmount: PropTypes.string,
  }).isRequired,
}

const MemberInfoWrap = styled.div`
  width: 100%;
  min-height: 150px;
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: grey !important;
    padding: 10px 4px;
    border: 1px solid black;
    white-space: nowrap;
    color: #fff;
    font-weight: 600;
    font-size: 14px;
  }

  td {
    padding: 5px 3px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
