export const blackMemberRegisterValidate = values => {
  const errors = {}
  if (!values.memberId) {
    errors.memberId = '필수 입력 항목입니다.'
  }
  if (!values.damageType) {
    errors.damageType = '필수 선택 항목입니다.'
  }
  if (!values.damageContent) {
    errors.damageContent = '필수 입력 항목입니다.'
  }

  return errors
}

export const IpRegisterValidate = values => {
  const errors = {}
  if (!values.memo) {
    errors.memo = '필수 입력 항목입니다.'
  }
  if (!values.restrictionIp) {
    errors.restrictionIp = '필수 선택 항목입니다.'
  }

  return errors
}
