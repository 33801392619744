export const OneToOneAuthorityEnums = {
  PARTNER: '파트너',
  MEMBER: '회원',
}

export const OneToOneAuthoritySelectOptions = () => {
  return Object.entries(OneToOneAuthorityEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const OneToOneAnswerStatusEnums = {
  COMPLETE: '확인',
  INCOMPLETE: '미확인',
}

export const OneToOneAnswerStatusSelectOptions = () => {
  return Object.entries(OneToOneAnswerStatusEnums).map(([key, value]) => ({
    value: key,
    label: value,
  }))
}

export const OneToOneAnswerStatus2Enums = {
  COMPLETE: '완료',
  APPLY: '접수',
  WAITING: '대기',
}
