import { useEffect, useState } from 'react'
import axios from '../base/axios'

export async function searchUserCasinoVendorInfo(userId) {
  const response = await axios({
    url: `/tyson/admin/v1/member/management/casino-vendor-info/${userId}`,
    method: 'GET',
  }).then(res => {
    return res
  })

  return response
}

export async function updateUserCasinoVendorInfo(userId, body) {
  const response = await axios({
    url: `/tyson/admin/v1/member/management/casino-vendor-info/${userId}`,
    method: 'PUT',
    data: body,
  })

  return response
}
