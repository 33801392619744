import { Card, CardBody, SearchCardSubhead, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { CouponTypeEnumsSelectOptions } from '../../../enums/Operation/couponEnums'
import { useSearchCouponList } from '../../../utils/api/operation/operationApi'
import CouponCreateListData from './CouponCreateListData'

const CouponCreateList = () => {
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  /**
   쿠폰 조회
   */
  const [couponCreateListparams, setCouponCreateListParams] = useState({
    page,
    size,
    couponType: null,
  })

  useEffect(() => {
    setCouponCreateListParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchCouponList } = useSearchCouponList(couponCreateListparams)
  const [couponCreateListData, setCouponCreateListData] = useState(CouponCreateListData(content))
  useEffect(() => {
    setCouponCreateListData(CouponCreateListData(content))
  }, [content])

  const [rows, setData] = useState(couponCreateListData.tableRowsData)
  useEffect(() => {
    setData(couponCreateListData.tableRowsData)
  }, [couponCreateListData])

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 쿠폰타입
  const [searchCouponType, setSearchCouponType] = useState('')

  const [searchCouponTypeOptionValue, setSearchCouponTypeOptionValue] = useState(null)
  const searchCouponTypeOption = CouponTypeEnumsSelectOptions()

  const onSelectCouponTypeOptionHandler = option => {
    if (option === null) {
      setSearchCouponTypeOptionValue(null)
      setSearchCouponType(null)
      return
    }
    setSearchCouponTypeOptionValue(option)
    setSearchCouponType(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setCouponCreateListParams(prev => ({
      ...prev,
      page: 0,
      couponType: !searchCouponType ? null : searchCouponType,
    }))
  }

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          {/* 검색 기능 */}
          <SearchCardTitleWrap>
            <SearchCardSubhead>* 생성된 쿠폰 목록을 확인할 수 있습니다.</SearchCardSubhead>
          </SearchCardTitleWrap>
          <SearchContainer horizontal>
            <SearchGroup>
              <SearchGroupLabel>쿠폰타입</SearchGroupLabel>
              <SearchGroupField>
                <SearchSelect
                  name="searchCouponType"
                  placeholder="쿠폰타입"
                  onChange={option => {
                    onSelectCouponTypeOptionHandler(option)
                  }}
                  value={searchCouponTypeOptionValue}
                  options={searchCouponTypeOption}
                  className="react-select"
                  classNamePrefix="react-select"
                  isClearable
                />
              </SearchGroupField>
            </SearchGroup>
            <SearchButtonWrap>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => {
                  onSearchClickHandler()
                }}
              >
                검색하기
              </Button>
            </SearchButtonWrap>
          </SearchContainer>
          <ReactTableBase columns={couponCreateListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
        </CardBody>
      </Card>
    </Col>
  )
}

export default CouponCreateList
