import {
  CustomModalBody,
  CustomModalCloseButton,
  CustomModalHeader,
  CustomModalTitle,
  NoteCustomStyledModal,
} from '@/shared/components/CustomModal'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import { MemberFriendCompLevelEnum } from '../../enums/MemberManage/MemberInfoEnums'
import { CustomBtnTableWrap } from '../../shared/components/TableElements'
import { convertToKstDateTimeSimple } from '../../utils/dateTime'
import { commonReg2 } from '../../utils/validate/commonValidate'

const FriendCompDetailInfo = ({ friendCompInfo }) => {
  const [isMemberNoteModalShow, setMemberNoteModalShow] = useState(false)
  const [apiFlag, setApiFlag] = useState(false)
  const onClickHandler = () => {
    setMemberNoteModalShow(prevState => !prevState)
  }

  return (
    <CustomBtnTableWrap>
      <CustomButton role={Button} onClick={() => onClickHandler()}>
        보기
      </CustomButton>
      <CustomStyledModal show={isMemberNoteModalShow} onHide={onClickHandler} $header>
        <CustomModalHeader>
          <CustomModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={onClickHandler}
          />
          <CustomModalTitle>세부정보 - {friendCompInfo?.friendUserId} 유저</CustomModalTitle>
        </CustomModalHeader>
        <CustomModalBody>
          <div style={{ fontSize: '14px' }}>
            <span style={{ color: 'black', fontWeight: '700' }}>
              정산일: {convertToKstDateTimeSimple(friendCompInfo?.settlementStartAt)} ~{' '}
              {convertToKstDateTimeSimple(friendCompInfo?.settlementEndAt)}
            </span>
          </div>
          <div style={{ padding: '10px 0', fontSize: '16px' }}>
            <span style={{ color: 'black', fontWeight: '500' }}>
              총 지인 콤프액: {friendCompInfo?.totalFriendCompAmount.toString().replace(commonReg2, ',')} 원
            </span>
            <span style={{ color: 'blue', fontWeight: '500', marginLeft: '10px' }}>
              최대 지급 가능액: {friendCompInfo?.friendCompMaxAmount.toString().replace(commonReg2, ',')} 원
            </span>
            <span style={{ color: 'red', fontWeight: '700', marginLeft: '10px' }}>
              최종 지급액: {friendCompInfo?.finalFriendCompAmount.toString().replace(commonReg2, ',')} 원
            </span>
          </div>
          <TableWrap>
            <table>
              <thead>
                <tr>
                  <th>추천인 아이디 (이름)</th>
                  <th>추천인 레벨</th>
                  <th>최대 지급가능액</th>
                  <th>라이브 배팅 비율</th>
                  <th>슬롯 배팅 비율</th>
                  <th>스포츠 배팅 비율</th>
                  <th>스포츠 낙첨 비율</th>
                  <th>미니게임 배팅 비율</th>
                  <th>미니게임 낙첨 비율</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {friendCompInfo?.friendUserId}({friendCompInfo?.friendUserName})
                  </td>
                  <td>{MemberFriendCompLevelEnum[friendCompInfo?.friendCompLevelName]}</td>
                  <td>{friendCompInfo?.friendCompMaxAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.liveCasinoFriendBenefitBettingPer} %</td>
                  <td>{friendCompInfo?.slotCasinoFriendBenefitBettingPer} %</td>
                  <td>{friendCompInfo?.sportsFriendBenefitBettingPer} %</td>
                  <td>{friendCompInfo?.sportsFriendBenefitLossPer} %</td>
                  <td>{friendCompInfo?.miniGameFriendBenefitBettingPer} %</td>
                  <td>{friendCompInfo?.miniGameFriendBenefitLossPer} %</td>
                </tr>
              </tbody>
            </table>
            <table style={{ marginTop: '20px' }}>
              <thead>
                <tr>
                  <th>피추천인 아이디 (이름)</th>
                  <th>
                    라이브 배팅액 <hr style={{ color: '#000', opacity: '1', margin: '2px' }} /> 콤프액{' '}
                  </th>
                  <th>
                    슬롯 배팅액 <hr style={{ color: '#000', opacity: '1', margin: '2px' }} /> 콤프액{' '}
                  </th>
                  <th>
                    스포츠 배팅액 <hr style={{ color: '#000', opacity: '1', margin: '2px' }} /> 콤프액 (배팅){' '}
                  </th>
                  <th>
                    스포츠 낙첨액 <hr style={{ color: '#000', opacity: '1', margin: '2px' }} /> 콤프액 (낙첨){' '}
                  </th>
                  <th>
                    미니게임 배팅액 <hr style={{ color: '#000', opacity: '1', margin: '2px' }} /> 콤프액 (배팅){' '}
                  </th>
                  <th>
                    미니게임 낙첨액 <hr style={{ color: '#000', opacity: '1', margin: '2px' }} /> 콤프액 (낙첨){' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={2}>
                    {friendCompInfo?.userId}({friendCompInfo?.userName})
                  </td>
                  <td>{friendCompInfo?.liveCasinoTotalBettingAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.slotTotalBettingAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.sportsTotalBettingAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.sportsLossBettingAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.miniGameTotalBettingAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.miniGameLossBettingAmount?.toString().replace(commonReg2, ',')}</td>
                </tr>
                <tr>
                  <td>{friendCompInfo?.liveCasinoCompAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.slotCompAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.sportsCompAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.sportsLossCompAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.miniGameCompAmount?.toString().replace(commonReg2, ',')}</td>
                  <td>{friendCompInfo?.miniGameLossCompAmount?.toString().replace(commonReg2, ',')}</td>
                </tr>
              </tbody>
            </table>
          </TableWrap>
        </CustomModalBody>
      </CustomStyledModal>
    </CustomBtnTableWrap>
  )
}

export default FriendCompDetailInfo

FriendCompDetailInfo.propTypes = {
  friendCompInfo: PropTypes.shape().isRequired,
}

const CustomStyledModal = styled(NoteCustomStyledModal)`
  .modal-dialog {
    max-width: 1100px !important;
  }
`

const CustomButton = styled.button`
  background-color: orange;
  color: white;
  border: none;
`

const TableWrap = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    min-width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  th {
    background-color: lightgray;
    padding: 5px;
    border: 1px solid black;
    color: black;
    font-weight: bolder;
    text-align: center !important;
    font-size: 12px;
    vertical-align: middle;
  }

  td {
    padding: 5px;
    height: 22px;
    border: 1px solid #888;
    font-weight: 500;
    font-size: 13px;

    textarea {
      width: 100%;
      padding: 5px 10px;
      font-size: 12px;
      min-height: 80px;
      transition: border 0.3s;
      background: transparent;
    }
  }
`
