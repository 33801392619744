import PropTypes from 'prop-types'
import Tooltip from '@/shared/components/Tooltip'
import React from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import { NoteAuthorityEnums, NoteReadStatusEnums } from '../../../enums/Operation/NoteEnums'
import { convertToKstDateTime } from '../../../utils/dateTime'
import NoteRadio from './NoteRadio'
import MemberCoupon from '../../MemberManagement/MemberList/MemberCoupon/MemberCoupon'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberNote from '../../MemberManagement/MemberList/MemberNote/MemberNote'
import MemberMoneyIncDecMini from '../../MemberManagement/MemberList/MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const NoteListData = (content, fetchSearchNoteLis, checkRadio, setCheckRadio, onTitleClickRowHandler) => {
  const columns = [
    {
      Header: '선택',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <NoteRadio rowInfo={original} setCheckRadio={setCheckRadio} checkRadio={checkRadio} />
      },
      width: 30,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.num}
          </div>
        )
      },
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.edgeName}
          </div>
        )
      },
    },

    {
      Header: '유저 ID(명)',
      accessor: 'userId',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div style={{ width: '100%' }}>
            <span style={{ fontWeight: '500' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.userId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                {original.userId}({original.userName})
              </span>
              <span>
                <MemberDepositInfo memberId={original.userId} />
              </span>
              <span>
                <MemberCoupon memberId={original.userId} />
              </span>
              <span>
                <MemberNote memberId={original.userId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.userId} />
              </span>
            </span>
          </div>
        )
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton
            authority={original.authority}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '제목',
      accessor: 'noteTitle',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {original.noteTitle}
          </div>
        )
      },
    },
    {
      Header: '보낸일',
      accessor: 'sendDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {convertToKstDateTime(original.sendDate)}
          </div>
        )
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <div
            style={{ width: '100%' }}
            onClick={() => {
              onTitleClickRowHandler()
            }}
          >
            {NoteReadStatusEnums[original.status]}
          </div>
        )
      },
    },
  ]
  return { tableHeaderData: columns, tableRowsData: content }
}

export default NoteListData

NoteListData.propTypes = {
  fetchSearchNoteList: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  sendContent: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
}

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
