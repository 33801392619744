import Tooltip from '@/shared/components/Tooltip'
import React from 'react'
import { FaSearch } from 'react-icons/fa'
import styled from 'styled-components'
import {
  MemberCasinoLevelEnum,
  MemberDepositLevelEnum,
  MemberMiniGameLevelEnum,
  MemberFriendCompLevelEnum,
  MemberSportsLevelEnum,
  MemberStatusEnums,
  MemberHoldemLevelEnum,
  MemberVirtualGameLevelEnum,
} from '../../../enums/MemberManage/MemberInfoEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import MemberApproveRegistrationRadio from './MemberApproveRegistrationRadio'
import MemberCoupon from './MemberCoupon/MemberCoupon'
import MemberNote from './MemberNote/MemberNote'
import MemberLogOut from './MemberLogOut/MemberLogOut'
import { NoteAuthorityEnums } from '../../../enums/Operation/NoteEnums'
import { PartnerLosingType } from '../../../enums/Statistics/partnerLosingStatistics'
import { CustomAuthorityButton } from '../../../shared/components/Card'
import MemberMoneyIncDecMini from './MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberDepositInfo from '../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberRefresh from './MemberRefresh/MemberRefresh'
import { convertToKstSportsEventDateTime } from '../../../utils/dateTime'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o
const checkNumber = checkMoney => {
  return Math.sign(checkMoney)
}

const MemberListData = (
  content,
  setRegistrationApprove,
  fetchSearchMember,
  setModalShow,
  setWhatModalShow,
  setModalUserId,
) => {
  const columns = [
    {
      Header: '승인',
      accessor: 'approveCheck',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <MemberApproveRegistrationRadio rowMemberInfo={original} setRegistrationApprove={setRegistrationApprove} />
        )
      },
      width: 50,
    },
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: '사이트명',
      accessor: 'edgeName',
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: '최근 로그인IP',
      accessor: 'lastloginIp',
      disableGlobalFilter: true,
      width: 50,
    },
    {
      Header: '가입일',
      accessor: 'createAt',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row

        return <div>{convertToKstSportsEventDateTime(original.signUpDate)}</div>
      },
    },
    {
      Header: '권한',
      accessor: 'authority',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomAuthorityButton authority={original.authority}>
            {NoteAuthorityEnums[original.authority]}
          </CustomAuthorityButton>
        )
      },
    },
    {
      Header: '상태',
      accessor: 'status',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.status) {
          case 'NORMAL':
            return <span style={{ color: 'blue', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'WAITING':
            return <span style={{ color: 'orange', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'CANCEL':
            return <span style={{ color: 'red', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'BLOCK':
            return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          case 'APPLY':
            return <span style={{ color: 'green', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
          default:
            return <span style={{ color: 'black', fontWeight: '900' }}>{MemberStatusEnums[original.status]}</span>
        }
      },
    },
    {
      Header: '테스트유저',
      accessor: 'tester',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row

        if (original.tester) {
          return <span style={{ color: 'red', fontWeight: '900' }}>O</span>
        }
        return <span>-</span>
      },
    },
    {
      Header: '유저 ID / 이름 / 닉네임 ',
      accessor: 'memberId',
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <>
            <span style={{ fontWeight: '500', fontSize: '15px' }}>
              <span
                onClick={() => {
                  window.open(`/user-info/${original.memberId}`, '_blank')
                }}
                style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
              >
                (&nbsp;{original.memberId} / {original.memberName}/ {original.nickName}&nbsp;)
              </span>
              <span>
                <MemberDepositInfo memberId={original.memberId} />
              </span>
              <span>
                <MemberCoupon memberId={original.memberId} />
              </span>
              <span>
                <MemberNote memberId={original.memberId} />
              </span>
              <span>
                <MemberMoneyIncDecMini memberId={original.memberId} />
              </span>
            </span>
          </>
        )
      },
      width: 115,
    },
    {
      Header: '색상',
      accessor: 'userColor',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <div style={{ width: '100%', height: '10px', backgroundColor: `${original.userColor || '#fff'}` }} />
      },
    },
    {
      Header: '지인 ID',
      accessor: 'friendId',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.friendId || '-'
      },
    },
    {
      Header: '직영 파트너명',
      accessor: 'topPartnerName',
      disableGlobalFilter: true,
    },
    {
      Header: '직영파트너 정산방식',
      accessor: 'settlementType',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (original.settlementType) {
          case 'LOSING_SETTLEMENT_TYPE1':
            return (
              <span style={{ color: 'orange', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          case 'LOSING_SETTLEMENT_TYPE2':
            return (
              <span style={{ color: 'purple', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          case 'LOSING_SETTLEMENT_TYPE3':
            return (
              <span style={{ color: '#B92EFA', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          case 'LOSING_SETTLEMENT_TYPE4':
            return (
              <span style={{ color: '#D79209', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          case 'LOSING_SETTLEMENT_TYPE5':
            return (
              <span style={{ color: 'brown', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          case 'LOSING_SETTLEMENT_TYPE6':
            return (
              <span style={{ color: '#A8D420', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          case 'LOSING_SETTLEMENT_TYPE7':
            return (
              <span style={{ color: '#634BC6', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          case 'LOSING_SETTLEMENT_TYPE8':
            return (
              <span style={{ color: '#f28c0f', fontWeight: '900' }}>{PartnerLosingType[original.settlementType]}</span>
            )
          default:
            return <span style={{ color: '#black', fontWeight: '900' }}>-</span>
        }
      },
    },
    {
      Header: '지갑 머니',
      accessor: 'moneyAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.moneyAmount > 1000000) {
          return (
            <span style={{ color: 'red', fontWeight: '500' }}>
              {original.moneyAmount.toString().replace(commonReg2, ',')}
            </span>
          )
        }
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.moneyAmount.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '홀덤 머니',
      accessor: 'holdemMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.holdemMoney > 1000000) {
          return (
            <span style={{ color: 'red', fontWeight: '500' }}>
              {original.holdemMoney.toString().replace(commonReg2, ',')}
            </span>
          )
        }
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.holdemMoney.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '카지노 머니',
      accessor: 'gameMoney',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.gameMoney > 1000000) {
          return (
            <span style={{ color: 'red', fontWeight: '500' }}>
              {original.gameMoney.toString().replace(commonReg2, ',')}
            </span>
          )
        }
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.gameMoney.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '포인트',
      accessor: 'pointAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.pointAmount.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },
    {
      Header: '루징포인트',
      accessor: 'losingAmountss',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <span style={{ color: 'black', fontWeight: '500' }}>
            {original.losingAmount.toString().replace(commonReg2, ',')}
          </span>
        )
      },
    },

    {
      Header: '레벨 (입금 / LIVE/ SLOT/ MINI/ 스포츠/ 추천인/ 홀덤/ 가상)',
      accessor: 'level',
      disableGlobalFilter: true,
      width: 70,
      Cell: r => {
        const { row } = r
        const { original } = row
        return `${MemberDepositLevelEnum[original.depositLevel]} / ${
          MemberCasinoLevelEnum[original.casinoLiveLevel]
        } / ${MemberCasinoLevelEnum[original.casinoSlotLevel]} / ${MemberMiniGameLevelEnum[original.miniGameLevel]} / ${
          MemberSportsLevelEnum[original.sportsGameLevel]
        }/ ${MemberFriendCompLevelEnum[original.friendCompLevel]}
        / ${MemberHoldemLevelEnum[original.holdemLevel]} / ${MemberVirtualGameLevelEnum[original.virtualGameLevelName]}`
      },
    },
    {
      Header: '증감',
      accessor: 'moneyincdec',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomButton
            style={{ background: 'gray' }}
            onClick={() => {
              setModalShow(true)
              setWhatModalShow('ASSET')
              setModalUserId(original.memberId)
            }}
          >
            증감
          </CustomButton>
        )
      },
    },
    {
      Header: '롤링',
      accessor: 'rollings',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 100,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <CustomButton
            style={{ background: 'purple' }}
            onClick={() => {
              setModalShow(true)
              setWhatModalShow('ROLLING')
              setModalUserId(original.memberId)
            }}
          >
            설정
          </CustomButton>
        )
      },
    },
    {
      Header: '총 입금',
      accessor: 'totalDepositAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalDepositAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 출금',
      accessor: 'totalWithdrawalAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row

        return original.totalWithdrawalAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 입금-총 출금',
      accessor: 'totalMoneyProfitAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (checkNumber(original.totalMoneyProfitAmount)) {
          case 1: {
            return (
              <span style={{ color: 'blue', fontWeight: '600' }}>
                {original.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          case -1: {
            return (
              <span style={{ color: 'red', fontWeight: '600' }}>
                {original.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          default:
            return (
              <span style={{ color: 'black', fontWeight: '600' }}>
                {original.totalMoneyProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
        }
      },
    },
    {
      Header: '총 배팅',
      accessor: 'totalBettingAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalBettingAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 당첨',
      accessor: 'totalWinningAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.totalWinningAmount?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '총 배팅-총 당첨',
      accessor: 'totalBettingProfitAmount',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        switch (checkNumber(original.totalBettingProfitAmount)) {
          case 1: {
            return (
              <span style={{ color: 'blue', fontWeight: '600' }}>
                {original.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          case -1: {
            return (
              <span style={{ color: 'red', fontWeight: '600' }}>
                {original.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
          }
          default:
            return (
              <span style={{ color: 'black', fontWeight: '600' }}>
                {original.totalBettingProfitAmount?.toString().replace(commonReg2, ',')}
              </span>
            )
        }
      },
    },
    {
      Header: '카지노 접속현황',
      accessor: 'currentAccessGame',
      disableGlobalFilter: true,
      width: 50,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.currentAccessGame) {
          return <span style={{ color: 'red' }}>접속중</span>
        }
        return <span style={{ color: 'blue' }}>미접속</span>
      },
    },
    {
      Header: '카지노 접속게임',
      accessor: 'lastCasinoAgency',
      disableGlobalFilter: true,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.lastCasinoAgency) {
          return (
            <span>
              [{original.lastCasinoAgency}] {original.lastCasinoVendor} - {original.lastCasinoGame}
            </span>
          )
        }
        return <span>-</span>
      },
    },
    {
      Header: '로그아웃',
      accessor: 'loginNow',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.loginNow) {
          return <MemberLogOut memberId={original.memberId} fetchSearchMember={fetchSearchMember} />
        }
        return <>-</>
      },
    },
    {
      Header: '새로고침',
      accessor: 'refresh',
      disableGlobalFilter: true,
      disableSortBy: true,
      width: 110,
      Cell: r => {
        const { row } = r
        const { original } = row
        if (original.loginNow) {
          return <MemberRefresh userId={original.memberId} fetchSearchMember={fetchSearchMember} />
        }
        return <>-</>
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default MemberListData

const SearchIcon = styled(FaSearch)`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  color: blue;
  &:hover {
    cursor: pointer;
  }
`
const CustomButton = styled.button`
  cursor: pointer;
  border: none;
  color: #fff;
`
