import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import { updatePartnerRegistrationStatus, useSearchPartnerApply } from '@/utils/api/memberManage/memberManageApi'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useDispatch } from 'react-redux'
import { PartnerStatusEnums } from '../../../enums/MemberManage/MemberInfoEnums'
import { fetchHeaderAlarmInfoAction } from '../../../redux/headerInfoSlice'
import EdgeSearchButton from '../../../shared/components/EdgeSearchButton'
import Loading from '../../../shared/components/Loading'
import { convertToKst } from '../../../utils/dateTime'
import { decodeAccessToken } from '../../../utils/token'
import MemberManageMentTabs from '../../MemberManagement/MemberManageMentTabs'
import PartnerApplyListData from './PartnerApplyListData'

const PartnerApplyList = () => {
  const dispatch = useDispatch()
  const [partnerStatusLoading, setPartnerStatusLoading] = useState(false)

  const authority = decodeAccessToken()?.adminAuthority

  /**
   * 회원 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)
  const [selectedEdgeName, setSelectedEdgeName] = useState(null)
  const [params, setParams] = useState({
    page,
    size,
    edgeName: null,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: selectedEdgeName,
    }))
  }, [selectedEdgeName])

  const { totalElement, content, fetchSearchPartner } = useSearchPartnerApply(params)

  // 가입 승인 버튼
  const [registrationApprove, setRegistrationApprove] = useState('')
  const [partnerApplyListData, setPartnerApplyListData] = useState(
    PartnerApplyListData(content, setRegistrationApprove, fetchSearchPartner),
  )

  useEffect(() => {
    setPartnerApplyListData(PartnerApplyListData(content, setRegistrationApprove, fetchSearchPartner))
  }, [content])

  const [rows, setData] = useState(partnerApplyListData.tableRowsData)

  useEffect(() => {
    setData(partnerApplyListData.tableRowsData)
  }, [partnerApplyListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디
  const [searchPartnerId, setSearchPartnerId] = useState('')

  const onSearchPartnerIdHandler = e => {
    setSearchPartnerId(e.target.value)
  }
  // 직영파트너
  const [searchTopPartnerId, setSearchTopPartnerId] = useState('')

  const onSearchTopPartnerIdHandler = e => {
    setSearchTopPartnerId(e.target.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }
  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      edgeName: selectedEdgeName,
      topPartnerId: !searchTopPartnerId ? null : searchTopPartnerId,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
    }))
  }

  // 대기 버튼
  const onWaitingRegistrationHandler = () => {
    if (partnerStatusLoading) return
    switch (PartnerStatusEnums[registrationApprove.status]) {
      case '신청':
        if (
          window.confirm(
            `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName}) 파트너를 가입 대기처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerRegistrationStatus({
            partnerApplyKey: registrationApprove.partnerApplyKey,
            status: 'WAITING',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입이 대기 처리 되었습니다.`,
              )
              setRegistrationApprove('')
              fetchSearchPartner()
            })
            .catch(error => {
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입 대기 처리가 실패했습니다.`,
              )
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('파트너를 먼저 선택해주세요')
        break
      default:
        alert('해당 파트너는 신청 상태가 아니므로 대기 처리가 불가합니다.')
        fetchSearchPartner()
        break
    }
  }

  // 승인 버튼
  const onApproveRegistrationHandler = () => {
    if (partnerStatusLoading) return
    switch (PartnerStatusEnums[registrationApprove.status]) {
      case '대기':
        if (
          window.confirm(
            `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName}) 파트너를 가입 승인처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerRegistrationStatus({
            partnerApplyKey: registrationApprove.partnerApplyKey,
            status: 'NORMAL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입이 승인 처리 되었습니다.`,
              )
              setRegistrationApprove('')
              fetchSearchPartner()
            })
            .catch(error => {
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입 처리가 실패했습니다.`,
              )
              setRegistrationApprove('')
            })
        }
        break
      case '신청':
        if (
          window.confirm(
            `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName}) 파트너를 가입 승인처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerRegistrationStatus({
            partnerApplyKey: registrationApprove.partnerApplyKey,
            status: 'NORMAL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입이 승인 처리 되었습니다.`,
              )
              setRegistrationApprove('')
              fetchSearchPartner()
            })
            .catch(error => {
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입 처리가 실패했습니다.`,
              )
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('파트너를 먼저 선택해주세요')
        break
      default:
        alert('해당 파트너는 신청 및 대기상태가 아니므로 승인처리가 불가합니다.')
        fetchSearchPartner()
        break
    }
  }

  // 취소 버튼
  const onCancelRegistrationHandler = () => {
    if (partnerStatusLoading) return
    switch (PartnerStatusEnums[registrationApprove.status]) {
      case '신청':
        if (
          window.confirm(
            `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName}) 회원을 가입 취소처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerRegistrationStatus({
            partnerApplyKey: registrationApprove.partnerApplyKey,
            status: 'CANCEL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
              fetchSearchPartner()
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입이 취소 처리 되었습니다.`,
              )
            })
            .catch(error => {
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입 처리가 실패했습니다.`,
              )
              setRegistrationApprove('')
            })
        }
        break
      case '대기':
        if (
          window.confirm(
            `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName}) 파트너를 가입 취소처리 하시겠습니까?`,
          )
        ) {
          setPartnerStatusLoading(true)
          updatePartnerRegistrationStatus({
            partnerApplyKey: registrationApprove.partnerApplyKey,
            status: 'CANCEL',
          })
            .then(res => {
              dispatch(fetchHeaderAlarmInfoAction())
              setPartnerStatusLoading(false)
              setRegistrationApprove('')
              fetchSearchPartner()
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입이 취소 처리 되었습니다.`,
              )
            })
            .catch(error => {
              setPartnerStatusLoading(false)
              alert(
                `${registrationApprove.downPartnerId}(${registrationApprove.downPartnerName})의 가입 처리가 실패했습니다.`,
              )
              setRegistrationApprove('')
            })
        }
        break
      case undefined:
        alert('파트너를 먼저 선택해주세요')
        break
      default:
        alert('해당 건은 신청 및 대기상태가 아니므로 취소처리가 불가합니다.')
        fetchSearchPartner()
        break
    }
  }

  const [activeKey, setActiveKey] = useState('5')

  return (
    <>
      {partnerStatusLoading ? (
        <Loading />
      ) : (
        <Card>
          <CardBody>
            <MemberManageMentTabs activeKey={activeKey} />
            <SearchCardTitleWrap>
              <div style={{ marginTop: '-40px', marginBottom: '10px' }}>
                <p>
                  * 해당 페이지는 <span style={{ color: 'blue', fontWeight: '900' }}>상위 파트너</span>가{' '}
                  <span style={{ color: 'red', fontWeight: '900' }}>하위 파트너</span> 등록 시, 승인 여부를 결정해주는
                  페이지 입니다.
                </p>
              </div>
            </SearchCardTitleWrap>
            <EdgeSearchButton
              edgeName={selectedEdgeName}
              onClickHandler={e => {
                setSelectedEdgeName(e.target.id || null)
              }}
            />
            {/* 검색 기능 */}
            <SearchContainer horizontal>
              <SearchGroup>
                <SearchGroupLabel>상위 파트너ID</SearchGroupLabel>
                <SearchGroupField>
                  <SearchInputWrap>
                    <input
                      name="searchValue"
                      type="text"
                      placeholder="검색 입력해주세요"
                      value={searchTopPartnerId}
                      onChange={e => onSearchTopPartnerIdHandler(e)}
                    />
                  </SearchInputWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>시작일</SearchGroupLabel>
                <SearchGroupField>
                  <SearchDatePickerWrap>
                    <DatePicker
                      selected={searchStartDate}
                      onChange={date => onSearchStartDateChangeHandler(date)}
                      dateFormat="yyyy-MM-dd"
                      dropDownMode="select"
                      popperPlacement="bottom-start"
                      placeholderText="시작일"
                      isClearable
                    />
                  </SearchDatePickerWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchGroup>
                <SearchGroupLabel>종료일</SearchGroupLabel>
                <SearchGroupField>
                  <SearchDatePickerWrap>
                    <DatePicker
                      selected={searchEndDate}
                      onChange={date => onSearchEndDateChangeHandler(date)}
                      dateFormat="yyyy-MM-dd"
                      dropDownMode="select"
                      popperPlacement="bottom-start"
                      placeholderText="종료일"
                      isClearable
                    />
                  </SearchDatePickerWrap>
                </SearchGroupField>
              </SearchGroup>
              <SearchButtonWrap>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => {
                    onSearchClickHandler()
                  }}
                >
                  검색하기
                </Button>
              </SearchButtonWrap>
            </SearchContainer>
            <ReactTableBase columns={partnerApplyListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
            {
              {
                SUPER: (
                  <>
                    <SearchContainer horizontal>
                      <SearchButtonWrap>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'green', color: 'white' }}
                          onClick={() => {
                            onWaitingRegistrationHandler()
                          }}
                        >
                          가입 대기
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'blue', color: 'white' }}
                          onClick={() => {
                            onApproveRegistrationHandler()
                          }}
                        >
                          가입 승인
                        </Button>
                        <Button
                          size="sm"
                          variant="outline-secondary"
                          style={{ backgroundColor: 'red', color: 'white' }}
                          onClick={() => {
                            onCancelRegistrationHandler()
                          }}
                        >
                          가입 취소
                        </Button>
                      </SearchButtonWrap>
                    </SearchContainer>
                  </>
                ),
              }[authority]
            }
          </CardBody>
        </Card>
      )}
    </>
  )
}

export default PartnerApplyList
