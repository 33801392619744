import { Card, CardBody } from '@/shared/components/Card'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EdgeSearchButton from '../../shared/components/EdgeSearchButton'
import DailyAssetStatisticsList from './DailyAssetStatisticsList/DailyAssetStatisticsList'
import DailyBettingStatisticsList from './DailyBettingStatistics/DailyBettingStatisticsList'
import StatisticsTabs2 from './DashBoardTabs'
import TotalAccumulateList from './TotalAccumulateList/TotalAccumulateList'

const DashBoardListPage = () => {
  const { edgeNames } = useSelector(state => {
    const { headerInfo } = state

    return {
      edgeNames: headerInfo.edgeNames,
    }
  })

  const [selectedEdgeName, setSelectedEdgeName] = useState()

  useEffect(() => {
    if (selectedEdgeName) return
    setSelectedEdgeName(edgeNames.length > 0 ? edgeNames[3] : '')
  }, [edgeNames, selectedEdgeName])

  // tab key
  const [activeKey, setActiveKey] = useState('1')

  return (
    <Card>
      <CardBody>
        <StatisticsTabs2 activeKey={activeKey} />
        <EdgeSearchButton
          isAll={false}
          edgeName={selectedEdgeName}
          onClickHandler={e => {
            setSelectedEdgeName(e.target.id)
          }}
        />
        <>
          <TotalAccumulateList edgeName={selectedEdgeName} />
          <DailyAssetStatisticsList edgeName={selectedEdgeName} />
          <DailyBettingStatisticsList edgeName={selectedEdgeName} />
        </>
      </CardBody>
    </Card>
  )
}

export default DashBoardListPage
