import io from 'socket.io-client'

// "undefined" means the URL will be computed from the `window.location` object
// const URL = 'ws://localhost:9992'
const URL = 'wss://demo1.xn--9g4bv6ai7g8slcyd.com'

// eslint-disable-next-line no-useless-escape
const clientHost = window.location.host.replace(/^(?:[^.]+\.)?([^\.]+\.[^\.]+)$/, '$1')

export const socket = io(URL, {
  path: '/socket.io',
  transports: ['websocket'],
  timestampParam: 'time',
  timestampRequests: true,
  cors: {
    origin: '*',
  },
  autoConnect: false,
})

/**
 *
 * @param {Socket} soc
 * @param {SocketIOEventName} event
 * @param {Any} data
 * @returns
 */
export const emitPromise = (soc, event, data) => {
  return new Promise((resolve, reject) => {
    soc.emit(event, data, response => {
      if (response.error) {
        reject(response.error)
      } else {
        resolve(response)
      }
    })
  })
}
