import { CustomAuthorityButton } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import { ko } from 'date-fns/esm/locale'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { HoldemResultStatusEnums } from '../../../../../enums/Holdem/HoldemBettingEnums'
import { HoldemBettingResultEnumsSelectOptions } from '../../../../../enums/Log/bettingEnums'
import { NoteAuthorityEnums } from '../../../../../enums/Operation/NoteEnums'
import CustomPagination from '../../../../../shared/components/customPagination/CustomPagination'
import { searchRevolutionHoldemBettingLog } from '../../../../../utils/api/holdem/revolutionHoldemApi'
import { convertToKst, convertToKstDateTime } from '../../../../../utils/dateTime'
import { commonReg2 } from '../../../../../utils/validate/commonValidate'
import MemberDepositInfo from '../../../../MemberHistory/DepositHistory/MemberDepositInfo/MemberDepositInfo'
import MemberCoupon from '../../MemberCoupon/MemberCoupon'
import MemberMoneyIncDecMini from '../../MemberMoneyIncDec/MemberMoneyIncDecMini'
import MemberNote from '../../MemberNote/MemberNote'

const MemberHistoryBettingHoldem = ({ memberId }) => {
  const [page, setPage] = useState(0)

  const [params, setParams] = useState({
    userId: memberId,
    page: 0,
    size: 5,
    isExact: true,
  })

  const [loading, setLoading] = useState(false)
  const [totalElement, setTotalElement] = useState(0)
  const [holdemBettingLogList, setHoldemBettingLogList] = useState([])

  const fetchRevolutionHoldemBettingLog = async () => {
    setLoading(true)

    await searchRevolutionHoldemBettingLog(params)
      .then(res => {
        setTotalElement(res.data.totalElements)
        setHoldemBettingLogList(res.data.holdemBettingLogList)
      })
      .catch(error => {
        // console.log('dd')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchRevolutionHoldemBettingLog()
  }, [params])

  // 배팅액 시작
  const [searchStartAmount, setSearchStartAmount] = useState(null)

  const onSearchStartAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchStartAmount(numericValue)
  }

  // 배팅액 끝
  const [searchEndAmount, setSearchEndAmount] = useState(null)

  const onSearchEndAmountHandler = e => {
    const numericValue = e.target.value.replace(/[^0-9]/g, '')
    setSearchEndAmount(numericValue)
  }

  // 게임결과
  const [searchGameResult, setSearchGameResult] = useState('')

  const [searchGameResultOptionValue, setSearchGameResultOptionValue] = useState(null)
  const searchGameResultOption = HoldemBettingResultEnumsSelectOptions()

  const onSelectGameResultOptionHandler = option => {
    if (option === null) {
      setSearchGameResultOptionValue(null)
      setSearchGameResult('')
      return
    }

    setSearchGameResultOptionValue(option)
    setSearchGameResult(option.value)
  }

  // 피해 발생일 시작 + 끝
  const [searchStartDate, setSearchStartDate] = useState(null)
  const [searchEndDate, setSearchEndDate] = useState(null)

  const onSearchStartDateChangeHandler = date => {
    setSearchStartDate(date)
  }
  const onSearchEndDateChangeHandler = date => {
    setSearchEndDate(date)
  }

  // 배팅번호
  const [searchBettingNum, setSearchBettingNum] = useState('')

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      startDate: !searchStartDate ? null : convertToKst(searchStartDate),
      endDate: !searchEndDate ? null : convertToKst(searchEndDate),
      startAmount: !searchStartAmount ? null : searchStartAmount,
      endAmount: !searchEndAmount ? null : searchEndAmount,
      bettingResult: !searchGameResult ? null : searchGameResult,
      bettingNum: !searchBettingNum ? null : searchBettingNum,
      page: 0,
    }))
  }

  /**
   * 페이징처리
   */
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    setTotalPages(Math.ceil(totalElement / 5))
  }, [totalElement])

  const onPageChange = pageNumber => {
    setPage(pageNumber)

    setParams(prev => ({
      ...prev,
      page: pageNumber,
    }))
  }

  return (
    <>
      {/* <SearchCardTitleWrap>
        <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
          <p>* 배팅 후 잔액, 당첨금은 결과가 나온 후 표기됩니다.</p>
        </div>
      </SearchCardTitleWrap> */}
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>배팅번호</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchBettingNum"
                type="text"
                placeholder="입력해주세요"
                value={searchBettingNum}
                onChange={e => {
                  setSearchBettingNum(e.target.value)
                }}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>게임결과</SearchGroupLabel>
          <SearchGroupField>
            <SearchSelect
              name="searchGameResult"
              placeholder="게임결과"
              onChange={option => {
                onSelectGameResultOptionHandler(option)
              }}
              value={searchGameResultOptionValue}
              options={searchGameResultOption}
              className="react-select"
              classNamePrefix="react-select"
              isClearable
            />
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>당첨액(이상)</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchStartAmount"
                type="text"
                placeholder="숫자만"
                value={searchStartAmount}
                onChange={e => onSearchStartAmountHandler(e)}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>당첨액(이하)</SearchGroupLabel>
          <SearchGroupField>
            <SearchInputWrap>
              <input
                name="searchEndAmount"
                type="text"
                placeholder="숫자만"
                value={searchEndAmount}
                onChange={e => onSearchEndAmountHandler(e)}
              />
            </SearchInputWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchStartDate}
                onChange={date => onSearchStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="시작일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchEndDate}
                onChange={date => onSearchEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd HH:mm"
                dropDownMode="select"
                timeFormat="HH:mm"
                showTimeInput
                popperPlacement="bottom-start"
                placeholderText="종료일"
                locale={ko}
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <SportBettingLogContentWrap>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>사이트명</th>
              <th>처리일시</th>
              <th>상위파트너 (이름)</th>
              <th>유저 (이름)</th>
              <th>배팅번호</th>
              <th>타입</th>
              <th>방번호</th>
              <th>블라인드(SB/BB)</th>
              <th>총 핸즈수</th>
              <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액 (P)</th>
              <th>당첨액 (P)</th>
              <th>환율</th>
              <th style={{ backgroundColor: 'green', color: '#fff' }}>배팅액 (KRW)</th>
              <th>당첨액 (KRW)</th>
              <th>결과</th>
              <th>노출여부</th>
            </tr>
          </thead>
          <tbody>
            {holdemBettingLogList.length === 0 ? (
              <tr>
                <td colSpan={16}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center',
                      color: '#000',
                      fontSize: '15px',
                      padding: '20px 0px',
                    }}
                  >
                    최근 게시물이 없습니다.
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {holdemBettingLogList.map(holdemBettingLog => {
                  return (
                    <>
                      <tr key={holdemBettingLog.holdemBettingLogKey}>
                        <td>{holdemBettingLog.num}</td>
                        <td>{holdemBettingLog.edgeName}</td>

                        <td>{convertToKstDateTime(holdemBettingLog.bettingAt)}</td>
                        <td>
                          {holdemBettingLog.topUserId ? (
                            <>
                              {holdemBettingLog.topUserId} ({holdemBettingLog.topUserName})
                            </>
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>
                          <span
                            onClick={() => {
                              window.open(`/user-info/${holdemBettingLog.uid}`, '_blank')
                            }}
                            style={{ color: '#2663a3', textDecoration: 'underline', cursor: 'pointer' }}
                          >
                            {holdemBettingLog.uid} ({holdemBettingLog.userName})
                          </span>
                          <span style={{ marginLeft: '5px' }}>
                            <CustomAuthorityButton authority={holdemBettingLog.userAuthority}>
                              {NoteAuthorityEnums[holdemBettingLog.userAuthority]}
                            </CustomAuthorityButton>
                          </span>
                          <span>
                            <span>
                              <MemberDepositInfo memberId={holdemBettingLog.uid} />
                            </span>
                            <span>
                              <MemberCoupon memberId={holdemBettingLog.uid} />
                            </span>
                            <span>
                              <MemberNote memberId={holdemBettingLog.uid} />
                            </span>
                            <span>
                              <MemberMoneyIncDecMini memberId={holdemBettingLog.uid} />
                            </span>
                          </span>
                        </td>
                        <td>{holdemBettingLog.logId}</td>
                        <td>
                          {holdemBettingLog.groupType ? HoldemResultStatusEnums[holdemBettingLog.groupType] : '-'}
                        </td>
                        <td>{holdemBettingLog.roomId}</td>
                        <td>{holdemBettingLog.blind || '-'}</td>
                        <td>{holdemBettingLog.gameNumber}</td>
                        <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                          {holdemBettingLog.bettingAmount.toString().replace(commonReg2, ',')}
                        </td>
                        <td>{holdemBettingLog.winningAmount.toString().replace(commonReg2, ',')}</td>
                        <td>{holdemBettingLog.krw.toString().replace(commonReg2, ',')}</td>
                        <td style={{ backgroundColor: '#58c759', color: '#fff' }}>
                          {holdemBettingLog.fiatBettingAmount.toString().replace(commonReg2, ',')}
                        </td>
                        <td>{holdemBettingLog.fiatWinningAmount.toString().replace(commonReg2, ',')}</td>

                        <td>
                          <CustomButton type="button" bettingResult={holdemBettingLog.bettingResult}>
                            {HoldemResultStatusEnums[holdemBettingLog.bettingResult]}
                          </CustomButton>
                        </td>
                        <td>
                          {holdemBettingLog.showFlag ? <span>노출</span> : <span style={{ color: 'red' }}>비노출</span>}
                        </td>
                      </tr>
                    </>
                  )
                })}
              </>
            )}
          </tbody>
        </table>
        <PaginationWrap>
          <CustomPagination currentPage={page} totalPages={totalPages} onPageChange={onPageChange} />
        </PaginationWrap>
      </SportBettingLogContentWrap>
    </>
  )
}

export default MemberHistoryBettingHoldem

MemberHistoryBettingHoldem.propTypes = {
  memberId: PropTypes.string.isRequired,
}

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const CustomButton = styled.button`
  width: 70px;
  background-color: ${props => getColor(props.bettingResult)};
  color: white;
  border: none;
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`

const MiniGameTypeSelectWrapper = styled.div`
  padding: 10px 0;
  // margin-bottom: 5px;

  display: flex;
  gap: 5px;
`

const SportBettingLogContentWrap = styled.div`
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
  }

  thead {
    tr {
      width: 100%;
      background: #d3d3d3;

      &:first-child {
        border-top: 1px solid #888;
      }

      th {
        min-width: max-content;
        padding: 4px;
        color: black;

        font-weight: bold;
        font-size: 13px;
        text-align: center;
        white-space: nowrap;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }
      }
    }
  }

  tbody {
    tr {
      width: 100%;
      font-size: 12px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
        border: 2px solid red;
      }

      td {
        padding: 6px 3px;

        border-right: 1px solid #888;
        border-bottom: 1px solid #888;
        &:first-child {
          border-left: 1px solid #888;
        }

        input {
          max-width: 60px;
        }
      }
    }
  }
`
