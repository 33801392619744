import React, { useMemo, useState } from 'react'
import { convertToKstDate } from '../../../utils/dateTime'
import { couponStatusEnums, couponTypeEnums } from '../../../enums/Operation/couponEnums'
import { commonReg2 } from '../../../utils/validate/commonValidate'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const CouponListData = content => {
  const columns = [
    {
      Header: '번호',
      accessor: 'num',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '쿠폰 번호',
      accessor: 'couponUseKey',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.couponUseKey.substring(0, 8)
      },
    },
    {
      Header: '사용유저',
      accessor: 'userId',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '쿠폰명',
      accessor: 'couponTitle',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '쿠폰타입',
      accessor: 'couponType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return couponTypeEnums[original.couponType]
      },
    },
    {
      Header: '금액(원)/비율(%)',
      accessor: 'couponPrice',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.couponPrice?.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '발행일',
      accessor: 'publicationDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{convertToKstDate(original.publicationDate)}</>
      },
    },
    // {
    //   Header: '만료일',
    //   accessor: 'expirationDate',
    //   disableGlobalFilter: true,
    //   width: 65,
    //   Cell: r => {
    //     const { row } = r
    //     const { original } = row
    //     return <>{convertToKstDate(original.expirationDate)}</>
    //   },
    // },
    {
      Header: '사용일',
      accessor: 'useDate',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return <>{convertToKstDate(original.useDate)}</>
      },
    },
    {
      Header: '상태',
      accessor: 'couponStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return couponStatusEnums[original.couponStatus]
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default CouponListData
