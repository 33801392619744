import React from 'react'
import styled from 'styled-components'
import { BettingResultEnums } from '../../../enums/Log/bettingEnums'
import { convertToKstDateTime, convertToKstSportsEventDateTime } from '../../../utils/dateTime'
import { colorLightText } from '../../../utils/palette'
import { commonReg2 } from '../../../utils/validate/commonValidate'
import {
  SportTypeEnums,
  sportResultStatusEnums,
  sportSettlementStatusEnums,
} from '../../../enums/SystemManagement/sportEnums'
import ResultModifyModal from '../RealTimeBettingLog/ModifyResult/ResultModifyModal'

// @See https://stackoverflow.com/questions/66715289/add-edit-button-to-react-table-that-opens-up-modal-to-edit-row-attributes-that-o

const SportsDetailBettingLogListData = (content, fetchSearchSportsMarket) => {
  const columns = [
    {
      Header: '경기시간',
      accessor: 'sportsStartTime',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return convertToKstSportsEventDateTime(original.sportsStartTime)
      },
    },
    {
      Header: '종목',
      accessor: 'sportsType',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return SportTypeEnums[original.sportsType]
      },
    },
    {
      Header: '국가 & 리그',
      accessor: 'nationalAndLeague',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '경기명',
      accessor: 'sportsGameName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '마켓명',
      accessor: 'marketName',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '선택항목',
      accessor: 'whatBetting',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '배당',
      accessor: 'oddValue',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return original.oddValue.toString().replace(commonReg2, ',')
      },
    },
    {
      Header: '경기점수',
      accessor: 'resultScore',
      disableGlobalFilter: true,
      width: 65,
    },
    {
      Header: '결과',
      accessor: 'resultStatus',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <BettingResultWrap bettingResult={original.resultStatus}>
            {sportResultStatusEnums[original.resultStatus]}
          </BettingResultWrap>
        )
      },
    },
    {
      Header: '결과 수정',
      accessor: 'manualResult',
      disableGlobalFilter: true,
      width: 65,
      Cell: r => {
        const { row } = r
        const { original } = row
        return (
          <ResultModifyModal
            memberId={original.memberId}
            sportsDetailBettingLogKey={original.sportsDetailBettingLogKey}
            sportsBettingLogKey={original.sportsBettingLogKey}
            resultStatus={original.resultStatus}
            sportsGameName={original.sportsGameName}
            fetchSearchSportsMarket={fetchSearchSportsMarket}
          />
        )
      },
    },
  ]

  return { tableHeaderData: columns, tableRowsData: content }
}

export default SportsDetailBettingLogListData

const getColor = bettingResult => {
  switch (true) {
    case bettingResult === 'WIN':
      return 'blue'
    case bettingResult === 'LOSE':
      return 'red'
    case bettingResult === 'DRAW':
      return 'grey'
    case bettingResult === 'CANCEL':
      return 'DarkViolet'
    default:
      return 'green'
  }
}

const BettingResultWrap = styled.button`
  width: 80%;
  font-weight: 700;
  color: white;
  border: none;
  background-color: ${props => getColor(props.bettingResult)};
`
