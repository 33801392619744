import { Card, CardBody, SearchCardTitleWrap } from '@/shared/components/Card'
import {
  SearchButtonWrap,
  SearchContainer,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
  SearchInputWrap,
  SearchSelect,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { GameStatusEnumsSelectOptions, GameTypeEnumsSelectOptions } from '../../../enums/SystemManagement/gameEnums'
import { useSearchVendors } from '../../../utils/api/systemManage/systemManageApi'
import SystemManagementTabs from '../SystemManagementTabs'
import GameListData from './GameListData'
import GameModifyModal from './GameModifyModal'
import CasinoManagementTabs from '../../CasinoManagement/CasinoManagementTabs'
import { CasinoVendorEnumsEnumsSelectOptions } from '../../../enums/SystemManagement/casinoEnums'

const GameList = () => {
  // 게임사 처리 모달
  const [isGameModifyModalShow, setGameModifyModalShow] = useState(false)

  const [page, setPage] = useState(0)
  const [size, setSize] = useState(15)

  /**
   게임사관리 조회
   */
  const [gameListparams, setGameListParams] = useState({
    page,
    size,
  })

  useEffect(() => {
    setGameListParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchVendors } = useSearchVendors(gameListparams)

  const [gameListData, setGameListData] = useState(GameListData(content))
  useEffect(() => {
    setGameListData(GameListData(content))
  }, [content])

  const [rows, setData] = useState(gameListData.tableRowsData)
  useEffect(() => {
    setData(gameListData.tableRowsData)
  }, [gameListData])

  const [vendorInfo, setVendorInfo] = useState({})
  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {
    setVendorInfo(rowValue.original)
    setGameModifyModalShow(true)
  }

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: false,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    onClickRowHandler,
  }

  const [searchAgencyName, setSearchAgencyName] = useState('')
  const [searchAgencyNameOptionValue, setSearchAgencyNameOptionValue] = useState(null)
  const searchAgencyNameOption = CasinoVendorEnumsEnumsSelectOptions()

  const onSelectAgencyNameOptionHandler = option => {
    if (option == null) {
      setSearchAgencyNameOptionValue(null)
      setSearchAgencyName('')
      return
    }
    setSearchAgencyNameOptionValue(option)
    setSearchAgencyName(option.value)
  }

  // 게임사
  const [searchVendorName, setSearchVendorName] = useState('')

  const onSearchVendorNameHandler = e => {
    setSearchVendorName(e.target.value)
  }

  // 상태
  const [searchStatus, setSearchStatus] = useState('')

  const [searchStatusOptionValue, setSearchStatusOptionValue] = useState(null)
  const searchStatusOption = GameStatusEnumsSelectOptions()

  const onSelectStatusOptionHandler = option => {
    if (option == null) {
      setSearchStatusOptionValue(null)
      setSearchStatus('')
      return
    }
    setSearchStatusOptionValue(option)
    setSearchStatus(option.value)
  }

  // 타입
  const [searchVendorType, setSearchVendorType] = useState('')
  const [searchVendorTypeOptionValue, setSearchVendorTypeOptionValue] = useState(null)
  const searchVendorTypeOption = GameTypeEnumsSelectOptions()

  const onSelectVendorTypeOptionHandler = option => {
    if (option == null) {
      setSearchVendorTypeOptionValue(null)
      setSearchVendorType('')
      return
    }
    setSearchVendorTypeOptionValue(option)
    setSearchVendorType(option.value)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setGameListParams(prev => ({
      ...prev,
      page: 0,
      agencyName: !searchAgencyName ? null : searchAgencyName.trim(),
      vendorName: !searchVendorName ? null : searchVendorName.trim(),
      status: !searchStatus ? null : searchStatus.trim(),
      vendorType: !searchVendorType ? null : searchVendorType,
    }))
  }
  // tab 키
  const [activeKey, setActiveKey] = useState('2')

  return (
    <Card>
      <CardBody>
        <CasinoManagementTabs activeKey={activeKey} />
        <SearchCardTitleWrap>
          <div style={{ marginTop: '-20px', marginBottom: '10px' }}>
            <p>
              * <span style={{ color: 'red' }}>행</span>을 클릭하면 해당 게임사를 수정할 수 있습니다.
            </p>
            <p>
              * <span style={{ color: 'red' }}>순서</span>는 번호가 낮을수록 먼저 보이게 됩니다.
            </p>
          </div>
        </SearchCardTitleWrap>

        <SearchContainer horizontal>
          <SearchGroup>
            <SearchGroupLabel>업체</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchAgencyName"
                onChange={option => {
                  onSelectAgencyNameOptionHandler(option)
                }}
                value={searchAgencyNameOptionValue}
                placeholder="업체"
                options={searchAgencyNameOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>게임사명</SearchGroupLabel>
            <SearchGroupField>
              <SearchInputWrap>
                <input
                  type="text"
                  placeholder="입력해주세요"
                  value={searchVendorName}
                  onChange={e => onSearchVendorNameHandler(e)}
                />
              </SearchInputWrap>
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>상태</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchVendorName"
                onChange={option => {
                  onSelectStatusOptionHandler(option)
                }}
                value={searchStatusOptionValue}
                placeholder="상태"
                options={searchStatusOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchGroup>
            <SearchGroupLabel>타입</SearchGroupLabel>
            <SearchGroupField>
              <SearchSelect
                name="searchVendorType"
                onChange={option => {
                  onSelectVendorTypeOptionHandler(option)
                }}
                value={searchVendorTypeOptionValue}
                placeholder="타입"
                options={searchVendorTypeOption}
                className="react-select"
                classNamePrefix="react-select"
                isClearable
              />
            </SearchGroupField>
          </SearchGroup>
          <SearchButtonWrap>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                onSearchClickHandler()
              }}
            >
              검색하기
            </Button>
          </SearchButtonWrap>
        </SearchContainer>
        <ReactTableBase columns={gameListData.tableHeaderData} data={rows} tableConfig={tableConfig} />
      </CardBody>

      {/* 게임사 수정 모달 */}
      {isGameModifyModalShow && (
        <GameModifyModal
          vendorKey={vendorInfo?.vendorKey}
          vendorNameKo={vendorInfo?.vendorNameKo}
          agencyName={vendorInfo?.agencyName}
          vendorName={vendorInfo?.vendorName}
          status={vendorInfo?.status}
          vendorImg={vendorInfo?.vendorImg}
          vendorOrderNum={vendorInfo?.vendorOrderNum?.toString()}
          memo={vendorInfo?.memo}
          vendorIsInspection={vendorInfo?.vendorIsInspection?.toString()}
          fetchSearchVendors={fetchSearchVendors}
          isGameModifyModalShow={isGameModifyModalShow}
          setGameModifyModalShow={setGameModifyModalShow}
        />
      )}
    </Card>
  )
}

export default GameList
