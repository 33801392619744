import {
  SearchButtonWrap,
  SearchContainer,
  SearchDatePickerWrap,
  SearchGroup,
  SearchGroupField,
  SearchGroupLabel,
} from '@/shared/components/Search'
import ReactTableBase from '@/shared/components/table/ReactTableBase'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import { useSearchUserPointExchange } from '../../../../../utils/api/partnerHistory/partnerHistoryApi'
import { convertToKst } from '../../../../../utils/dateTime'
import MemberPointExchangeListData from '../../../../Statistics/PointExchangeHistory/MemberPointExchangeListData'

const MemberHistoryPointExchange = ({ memberId }) => {
  /**
   * 포인트 전환내역 조회
   */
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(5)

  const [params, setParams] = useState({
    userId: memberId,
    startDate: null,
    endDate: null,
    page,
    size,
    isExact: true,
  })

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      page,
      size,
    }))
  }, [page, size])

  const { totalElement, content, fetchSearchMemberPointExchange } = useSearchUserPointExchange(params)

  const [pointExchangeListData, setPointExchangeListData] = useState(MemberPointExchangeListData(content))

  useEffect(() => {
    setPointExchangeListData(MemberPointExchangeListData(content))
  }, [content])

  const [rows, setData] = useState(pointExchangeListData.tableRowsData)

  useEffect(() => {
    setData(pointExchangeListData.tableRowsData)
  }, [pointExchangeListData])

  // row 클릭 이벤트 핸들러
  const onClickRowHandler = rowValue => {}

  const tableConfig = {
    // 테이블 col size
    isResizable: false,
    // 테이블 col 정렬 여부
    isSortable: true,
    // 페이지네이션
    withPagination: true,
    // page
    _pageIndex: page,
    // size
    _pageSize: size,
    // total element
    totalElement,
    // setPageIndex
    _setPageIndex: setPage,
    // row 클릭 핸들러
    // onClickRowHandler,
  }

  // 아이디 , 수신, 구분 , 상태 , 처리일

  // 아이디
  const [searchMemberId, setSearchMemberId] = useState('')

  const onSearchMemberIdHandler = e => {
    setSearchMemberId(e.target.value)
  }

  const [searchExecuteStartDate, setSearchExecuteStartDate] = useState(null)
  const [searchExecuteEndDate, setSearchExecuteEndDate] = useState(null)

  const onSearchExecuteStartDateChangeHandler = date => {
    setSearchExecuteStartDate(date)
  }
  const onSearchExecuteEndDateChangeHandler = date => {
    setSearchExecuteEndDate(date)
  }

  // 검색하기 버튼
  const onSearchClickHandler = () => {
    setParams(prev => ({
      ...prev,
      page: 0,
      userId: memberId,
      startDate: !searchExecuteStartDate ? null : convertToKst(searchExecuteStartDate),
      endDate: !searchExecuteEndDate ? null : convertToKst(searchExecuteEndDate),
    }))
  }
  return (
    <>
      <SearchContainer horizontal>
        <SearchGroup>
          <SearchGroupLabel>시작일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteStartDate}
                onChange={date => onSearchExecuteStartDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="시작일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchGroup>
          <SearchGroupLabel>종료일</SearchGroupLabel>
          <SearchGroupField>
            <SearchDatePickerWrap>
              <DatePicker
                selected={searchExecuteEndDate}
                onChange={date => onSearchExecuteEndDateChangeHandler(date)}
                dateFormat="yyyy-MM-dd"
                dropDownMode="select"
                popperPlacement="bottom-start"
                placeholderText="종료일"
                isClearable
              />
            </SearchDatePickerWrap>
          </SearchGroupField>
        </SearchGroup>
        <SearchButtonWrap>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={() => {
              onSearchClickHandler()
            }}
          >
            검색하기
          </Button>
        </SearchButtonWrap>
      </SearchContainer>
      <ReactTableBase
        key="common"
        columns={pointExchangeListData.tableHeaderData}
        data={rows}
        tableConfig={tableConfig}
      />
    </>
  )
}

export default MemberHistoryPointExchange

MemberHistoryPointExchange.propTypes = {
  memberId: PropTypes.string.isRequired,
}
